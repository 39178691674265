import React from 'react';

const ShortInfoItem = (props) => {
    const {isLoading, label, value} = props;

    return (
        <>
            {isLoading ? '...' : (
                value && (
                    <div className={`title-blue text-weight-300 margin-bottom-0`}>
                        {label}
                        {' '}
                        <span className='text-weight-600'>
                            {value}
                        </span>
                    </div>
                )
            )}
        </>
    );
};

export default ShortInfoItem;

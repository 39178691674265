import React, {useContext, useEffect, useState} from 'react';
import KycForm from "./KYCForm";
import {compose} from "redux";
import {connect} from "react-redux";
import useForm2 from "../../../../hooks/useForm2";
import {handleErrorMessage, setMessage} from "../../../Messages/actions";
import Api from "../../../../services/api";
import {Prompt, withRouter} from "react-router-dom";
import {getLoggedUser} from "../../../User/actions";
import {useTranslation} from "react-i18next";
import isDateValid from "../../../../helpers/idValidDate";
import useBeforeUnload from "../../../../hooks/useBeforeUnload";
import {ReactComponent as Logo} from "../../../../assets/logoInvesthub.svg";
import VolveSnackbar from "../../../VolveSnackbar";
import Box from "@material-ui/core/Box";
import {AbilityContext} from "../../../../Context/Can";
import {subject} from "@casl/ability";

const service = new Api();

const KnowYourCustomer = (props) => {

    const {dateFormat, handleErrorMessage, setMessage, match, getLoggedUser, userData} = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [typeOfAction, setTypeOfAction] = useState(null);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [countries, setCountries] = useState([]);
    const [proofOfIdentityCategories, setProofOfIdentityCategories] = useState([]);
    const [proofOfAddressCategories, setProofOfAddressCategories] = useState([]);
    const [titles, setTitles] = useState([]);
    const [isValidData, setIsValidData] = useState(false);
    const [user, setUser] = useState({});
    const {t} = useTranslation();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const ability = useContext(AbilityContext);

    const canUpdateUser = (userId, companyId) => {
        return ability.can("update", subject("user", {_id: userId, company: companyId }));
    }

    const canVerifyUser = canUpdateUser(user._id, user.company);


    useEffect(() => {
        setIsDataFetching(true);
        (async () => {
            try {

                const userPromise =  service.getUsersUser(match.params.id);
                const countriesPromise = service.getServiceData({dataFor:"countries"});
                const proofOfIdentityCategoriesPromise = service.getServiceData({dataFor:"proofOfIdentityCategories"});
                const proofOfAddressCategoriesPromise = service.getServiceData({dataFor:"proofOfAddressCategories"});
                const titlesPromise = service.getServiceData({dataFor:"userPrefixes"});

                const responses = await Promise.all([userPromise, countriesPromise, proofOfIdentityCategoriesPromise, proofOfAddressCategoriesPromise, titlesPromise]);
                const [userResult, countriesResult, proofOfIdentityCategoriesResult, proofOfAddressCategoriesResult, titlesResult] = responses;

                const mappedCountries = countriesResult.data.map((country) => ({label: country.name, value: country["country-code"]}));

                setUser(userResult.data);

                setCountries(mappedCountries);
                setProofOfIdentityCategories(proofOfIdentityCategoriesResult.data);
                setProofOfAddressCategories(proofOfAddressCategoriesResult.data);


                setTitles(titlesResult.data);

                setIsDataFetching(false);
            }catch (e) {
                handleErrorMessage(e);
                setIsDataFetching(false);
            }
        })()
    },[]);

    useEffect(() => {
        const values = {};

        const preFilFields = [
            "firstName",
            "lastName",
            "middleName",
            "title",
            "dob",
            "addrl1",
            "addrl2",
            "city",
            "postCode",
            "country",
            "proofOfAddress",
            "proofOfAddressCategory",
            "proofOfIdentity",
            "proofOfIdentityCategory",
        ];

        preFilFields.forEach((key) => {
            if(user[key]){
                values[key] = user[key];
            }
        });

        fillFormValues(values);

    },[user]);


    const validate = (values) => {
        const errors = {};

        if(!values["title"]){
            errors["title"] = t('errors.required', {field: t("formFields.title")});
        }
        if(!values["lastName"]){
            errors["lastName"] = t('errors.required', {field: t("formFields.lastName")});
        }
        if(!values["firstName"]){
            errors["firstName"] = t('errors.required', {field: t("formFields.firstName")});
        }
        if(!values["addrl1"]){
            errors["addrl1"] = t('errors.required', {field: t("formFields.addrl1")});
        }
        if(!values["city"]){
            errors["city"] = t('errors.required', {field: t("formFields.city")});
        }
        if(!values["postCode"]){
            errors["postCode"] = t('errors.required', {field: t("formFields.postCode")});
        }
        if(!values["country"]){
            errors["country"] = t('errors.required', {field: t("formFields.country")});
        }
        if(!values["proofOfAddress"]){
            errors["proofOfAddress"] = t('errors.required', {field: t("formFields.proofOfAddress")});
        }
        if(!values["proofOfAddressCategory"]){
            errors["proofOfAddressCategory"] = t('errors.required', {field: t("formFields.documentType")});
        }
        if(!values["proofOfIdentity"]){
            errors["proofOfIdentity"] = t('errors.required', {field: t("formFields.proofOfIdentity")});
        }
        if(!values["proofOfIdentityCategory"]){
            errors["proofOfIdentityCategory"] = t('errors.required', {field: t("formFields.documentType")});
        }
        if (!isDateValid(values[`dob`])){
            errors[`dob`] = t("errors.notValid", {field: "$t(formFields.dob)"});
        }

        return errors;
    };

    const onCancel = () => {
        props.history.push(`/dashboard`);
    }

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


    const submit = async (isSave=false) => {
        const formValues = new FormData();
        if(isSave){
            formValues.append("isSave", "true");
        }

        Object.keys(values).forEach((key) => {
            let value = values[key];
            if(value === undefined || values[key] === null) value = "";

            formValues.append(key, value)

        });
        setIsSubmitting(true);
        try {
            const response = await service.submitKycForm(match.params.id, formValues);
            setUser(response.data);
            setIsSubmitting(false);
            if(match.params.id === userData._id){
                getLoggedUser() // fetch user
            }
            setValuesWasChanged(false); // allow to update page or moving to another router without prompt
            if(!isSave){
                props.history.push(`/dashboard`);
            }

            setMessage("success", 'success');
        }catch (e) {

            setServerErrors(e);
            setIsSubmitting(false);
        }
    }




    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );


    let warningMessage = null;
    if(user && user.kyc){
        warningMessage =  t("user.alreadyKyc");
    }

    useEffect(() => {
        setIsValidData(Object.keys(validate(values)).length > 0);
    },[values]);


    const isDisabled = !canVerifyUser

    return (
        <Box paddingLeft={'24px'} paddingRight={'24px'}>
            {!user.kyc ? (
                <>
                    <Prompt
                        when={valuesWasChanged}
                        message={t("unsavedChanges")}
                    />
                    <KycForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChangeWithDetectChanges}
                        values={values}
                        disabled={isDisabled}
                        errors={errors}
                        isSubmitting={isSubmitting}
                        dateFormat={dateFormat}
                        setMessage={setMessage}
                        countries={countries}
                        titles={titles}
                        isValidData={isValidData}
                        proofOfIdentityCategories={proofOfIdentityCategories}
                        proofOfAddressCategories={proofOfAddressCategories}
                        isDataFetching={isDataFetching}
                        onCancel={onCancel}
                        onSave={(match.params.id === userData._id ) ? submit : null}
                    />
                </>
            ) : (
                !!warningMessage && <VolveSnackbar message={<>{warningMessage}</>} icon={<Logo/>}/>
            )}
        </Box>
    );
};

const mapStateToProps = ({user}) => {
	const {userData, settings} = user;
	const {regional={}} = settings;
	const {dateFormat} = regional;

	return {
		userData,
		dateFormat
	}
}
export default compose(
	connect(
		mapStateToProps,
		{handleErrorMessage, setMessage, getLoggedUser}
	),
	withRouter
)(KnowYourCustomer);

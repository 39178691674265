import React, {useCallback, useEffect} from 'react';
import FormControl from "@material-ui/core/FormControl";
import SelectField from "../../../../../formElements/SelectField";

import Fieldset from "../../../../../formElements/Fieldset";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import InputFieldMemo from "../../../../../formElements/InputFieldMemo";
import Typography from "@material-ui/core/Typography";

const AdditionalInformation = (props) => {
    const {t} = useTranslation();

    const {handleChange, values, errors, setStepFields, numberOfCardsRequired, averageTransactionSizes, annualTransactionVolumes, anticipatedPercentOfInternationalTransactions, fetchData, idx, loading} = props;

    useEffect(() => {
        if(numberOfCardsRequired.length === 0){
            fetchData();
        }
    },[numberOfCardsRequired]);



    useEffect(() => {
        // fields will be send on this step
        setStepFields((fields) => {
            return {
                ...fields,
                [idx]:[
                    "numberOfCardsRequired",

                ]
            }
        })

    },[]);

    const memoizedCallback = useCallback(handleChange , []);

    return (
        <Fieldset title={t("kybFieldsetAdditionalInformation")}>
            <Grid container spacing={5}>
                <Grid item xs={12} style={{paddingBottom:"10px"}}>
                    <Typography variant="body2">
                        {t("paladisKybFieldsetAdditionalInformationText")}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl error={!!errors["numberOfCardsRequired"]} fullWidth>
                        <SelectField
                            label={t("formFields.numberOfCardsRequired")}
                            name={"numberOfCardsRequired"}
                            value={values["numberOfCardsRequired"]}
                            error={errors["numberOfCardsRequired"]}
                            options={numberOfCardsRequired}
                            onSelectChange={(val) => handleChange("numberOfCardsRequired", val)}
                            isLoading={loading}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl error={!!errors["anticipatedPercentOfInternationalTransactions"]} fullWidth>
                        <SelectField
                            label={t("formFields.anticipatedPercentOfInternationalTransactions")}
                            name={"anticipatedPercentOfInternationalTransactions"}
                            value={values["anticipatedPercentOfInternationalTransactions"]}
                            error={errors["anticipatedPercentOfInternationalTransactions"]}
                            options={anticipatedPercentOfInternationalTransactions}
                            onSelectChange={(val) => handleChange("anticipatedPercentOfInternationalTransactions", val)}
                            isLoading={loading}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl error={!!errors["averageTransactionSize"]} fullWidth>
                        <SelectField
                            label={t("formFields.averageTransactionSize")}
                            name={"averageTransactionSize"}
                            value={values["averageTransactionSize"]}
                            error={errors["averageTransactionSize"]}
                            options={averageTransactionSizes}
                            onSelectChange={(val) => handleChange("averageTransactionSize", val)}
                            isLoading={loading}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl error={!!errors["annualTransactionVolume"]} fullWidth>
                        <SelectField
                            label={t("formFields.annualTransactionVolume")}
                            name={"annualTransactionVolume"}
                            value={values["annualTransactionVolume"]}
                            error={errors["annualTransactionVolume"]}
                            options={annualTransactionVolumes}
                            onSelectChange={(val) => handleChange("annualTransactionVolume", val)}
                            isLoading={loading}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl error={!!errors["annualProposedUseOfCards"]} fullWidth>
                        <InputFieldMemo
                            label={t("formFields.annualProposedUseOfCards")}
                            placeholder={t("formFields.annualProposedUseOfCards")}
                            name="annualProposedUseOfCards"
                            type="text"
                            value={values["annualProposedUseOfCards"]}
                            onInputChange={memoizedCallback}
                            error={errors["annualProposedUseOfCards"]}
                            isLoading={loading}
                        />
                    </FormControl>
                </Grid>

            </Grid>

        </Fieldset>
    );
};

export default AdditionalInformation;

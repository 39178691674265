import React from 'react'
import Projects from "../userSection/content/Projects";
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";

const ProjectsPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef, settingsDarkMode} = props;
    return (
        <>
            <UserSectionWrapper>
                <Can I="read" a={"project"} passThrough>
                    {(can) => (
                        can ? <Projects underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} /> : <div>{t("project.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )

}



export default ProjectsPage

import React, {useState, useEffect, useRef} from 'react';
import {
    makeStyles,
    Button,
    FormControl,
    FormLabel,
    FormGroup,
    FormHelperText,
    Chip,
    Paper,
    useTheme,
    useMediaQuery,
    Box
} from '@material-ui/core';
import PhoneField from "../formElements/PhoneField";
import InputField from "../formElements/InputField";
import SelectField from "../formElements/SelectField";
import RadioFields from "../formElements/RadioFields";
import {useTranslation} from "react-i18next";
import SelectUsersForApproval from "./SelectUsersForApproval";

import {Link} from "react-router-dom";
import CheckBoxField from "../formElements/CheckBoxField";
import {subject} from "@casl/ability";
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start'
    },
    itemMenu: {
        position: 'sticky',
        top: '116px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '280px',
    },
    itemMenuLink: {
        backgroundColor: 'transparent !important',
        padding: '8px 16px',
        color: 'var(--battelship-grey)',
        justifyContent: 'flex-start',
        fontWeight: '600',
        height: 'initial',
        lineHeight: '1.6',
        letterSpacing: '0',
        "&:not(:last-child)": {
            marginBottom: '8px'
        },
        "& .MuiTouchRipple-root": {
            display: 'none !important'
        },
        "&.active": {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey) ' : 'var(--dark-secondary)',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary) !important' : 'var(--very-light-pink-primary) !important',
        }
    },
    itemContent: {
        flexGrow: '1',
        paddingLeft: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0'
        }
    },
    itemContentMenu: {
        display: 'block',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        '& .MuiInput-root': {
            width: '100%'
        }
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '40px',
        letterSpacing: '-.02em',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemBox: {
        width: '100%',
        height: '48px',
        padding: '0 10px',
        borderRadius: '12px',
        border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid transparent',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
        transition: 'all .2s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px'
    },
    itemFormSubmit: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
    itemFormFieldSetAnchor: {
        position: 'absolute',
        top: '-116px',
        left: '0',
        right: '0',
    },
    itemRadio: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px'
        }
    },
    groupCheckbox: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px'
        }
    },
    itemFieldCards: {
        '& .MuiFormLabel-root': {
            color: 'var(--charcoal-primary)',
            padding: '0',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '1.71429',
            marginBottom: '6px'
        },
        '& .MuiPaper-root': {
            marginBottom: '0',
            gap: '8px',
            backgroundColor: 'transparent'
        },
    },


    inputWrapper: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "20px",
        },
    },
    textAreaWrapper: {
        '& .MuiInput-formControl':{
            paddingBottom:0,
        },
        '& textarea:not([disabled])':{
            padding:"10px 16px",
            transition: 'all .2s'
        },
        '& .Mui-focused textarea:not([disabled])': {
            background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--very-light-pink-primary)',
            borderColor: (props) => props.isDarkModeActive ? 'rgba(154, 159, 165, 0.15) solid 2px' : 'var(--very-light-pink-primary) solid 2px'
        }
    },
    cards: {
        display: 'flex',
        // justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 0,
        margin: "0 0 20px 0",
    },
}));


const UserForm = (props) => {
    const {
        roles,
        titles,
        countries,
        groups,
        branches,
        departments,
        handleChange,
        handleSubmit,
        values,
        errors,
        isLoading,
        isDataFetching,
        user,
        match,
        ability,
        onRemoveUser,
        settingsDarkMode,
    } = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    const checkDisabledField = (field) => {
        return !ability.can("update", subject("user", user), field)
    }

    const onRolesChange = (val) => {
        const copyRoles = [...values.roles];
        if(values.roles.includes(val)){
            const newRoles = copyRoles.filter((role) => role !== val);
            handleChange("roles", newRoles);
        }else{
            handleChange("roles", [...copyRoles, val]);
        }
    }

    const onActive = (val) => {
        const notActiveStatus = user.status === "invited" ? user.status : "notActive";
        handleChange("status", val ? "active" : notActiveStatus)
    }

    // NEED TRANSLATION
    const formSections = [
        { id: 'personal', label: t('Personal') },
        { id: 'contact', label: t('Contact') },
        { id: 'professional', label: t('Professional') },
        { id: 'financial', label: ('Financial') },
        { id: 'additional', label: ('Additional') },
    ];
    const [activeSection, setActiveSection] = useState('personal')
    const [selectedMenu, setSelectedMenu]   = useState(formSections[0].id)
    const personalRef       = useRef(null);
    const contactRef        = useRef(null);
    const professionalRef   = useRef(null);
    const financialRef      = useRef(null);
    const additionalRef     = useRef(null);

    const refs = {
        personal: personalRef,
        contact: contactRef,
        professional: professionalRef,
        financial: financialRef,
        additional: additionalRef,
    }

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(selectedSection);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (rect.top <= scrollPosition && rect.bottom > scrollPosition) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs, formSections])

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    return (
        <div className={classes.item}>

            {!onMax1023 && (
                <div className={classes.itemMenu}>
                    {formSections.map((section) => (
                        <Button
                            key={section.id}
                            className={`${classes.itemMenuLink} ${
                                activeSection === section.id ? 'active' : ''
                            }`}
                            onClick={() => {
                                scrollToSection(section.id);
                                setActiveSection(section.id);
                                setSelectedMenu(section.id);
                            }}
                        >
                            {t(section.label)}
                        </Button>
                    ))}
                </div>
            )}

            <div className={classes.itemContent}>
                {onMax1023 && (
                    <Box className={classes.itemContentMenu}>
                        <SelectField
                            hideEmpty
                            options={formSections.map(section => ({ value: section.id, label: section.label }))}
                            onSelectChange={(selectedSection) => {
                                scrollToSection(selectedSection);
                                setSelectedMenu(selectedSection);
                            }}
                            value={selectedMenu}
                        />
                    </Box>
                )}

                <form onSubmit={handleSubmit} noValidate className={classes.itemForm}>
                    <Box className={classes.itemFormFieldSet}>
                        <Box ref={personalRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                        <Box className={`${classes.itemTitle} card-title card-title--blue`}>
                            {/* NEED TRANSLATION */}
                            {t('Personal Information')}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <FormControl fullWidth error={!!errors.email} className={classes.itemField}>
                                <RadioFields
                                    isLoading={isDataFetching}
                                    options={titles}
                                    name={"title"}
                                    label={t("formFields.title")}
                                    value={values["title"]}
                                    onRadioChange={(val) => handleChange("title", val)}
                                    error={errors["title"]}
                                    disabled={checkDisabledField("title")}
                                    settings={settingsDarkMode}
                                    className={classes.itemRadio}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors.firstName} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.firstName")}
                                    placeholder={t("formFields.firstName")}
                                    isLoading={isDataFetching}
                                    name="firstName"
                                    value={values["firstName"] ? values["firstName"] : ""}
                                    onInputChange={(val) => handleChange("firstName", val)}
                                    error={errors["firstName"]}
                                    disabled={checkDisabledField("firstName")}
                                />
                            </FormControl>

                            <Box className={classes.itemRow}>
                                <FormControl fullWidth error={!!errors["middleName"]} className={classes.itemField}>
                                    <InputField
                                        isLoading={isDataFetching}
                                        label={t("formFields.middleName")}
                                        placeholder={t("formFields.middleName")}
                                        name="middleName"
                                        value={values["middleName"]}
                                        onInputChange={(val) => handleChange("middleName", val)}
                                        disabled={checkDisabledField("middleName")}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors["lastName"]} className={classes.itemField}>
                                    <InputField
                                        isLoading={isDataFetching}
                                        label={t("formFields.lastName")}
                                        placeholder={t("formFields.lastName")}
                                        name="lastName"
                                        type="text"
                                        value={values["lastName"]}
                                        onInputChange={(val) => handleChange("lastName", val)}
                                        error={errors["lastName"]}
                                        disabled={checkDisabledField("lastName")}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.itemFormFieldSet}>
                        <Box ref={contactRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                        <Box className={`${classes.itemTitle} card-title card-title--green`}>
                            {/* NEED TRANSLATION */}
                            {t('Contact Information')}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <FormControl error={!!errors["addrl1"]} fullWidth className={classes.itemField}>
                                <InputField
                                    label={t("formFields.addrl1")}
                                    placeholder={t("formFields.addrl1")}
                                    name="addrl1"
                                    value={values["addrl1"]}
                                    onInputChange={(val) => handleChange("addrl1", val)}
                                    error={errors["addrl1"]}
                                    isLoading={isDataFetching}
                                    disabled={checkDisabledField("addrl1")}
                                />
                            </FormControl>

                            <FormControl error={!!errors["addrl2"]} fullWidth className={classes.itemField}>
                                <InputField
                                    label={t("formFields.addrl2")}
                                    placeholder={t("formFields.addrl2")}
                                    name="addrl2"
                                    value={values["addrl2"]}
                                    onInputChange={(val) => handleChange("addrl2", val)}
                                    error={errors["addrl2"]}
                                    disabled={checkDisabledField("addrl2")}
                                />
                            </FormControl>

                            <Box className={classes.itemRow}>
                                <FormControl error={!!errors["postCode"]} fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.postCode")}
                                        placeholder={t("formFields.postCode")}
                                        name="postCode"
                                        value={values["postCode"]}
                                        onInputChange={(val) => handleChange("postCode", val)}
                                        error={errors["postCode"]}
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("postCode")}
                                    />
                                </FormControl>

                                <FormControl error={!!errors["city"]} fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.city")}
                                        placeholder={t("formFields.city")}
                                        name="city"
                                        value={values["city"]}
                                        onInputChange={(val) => handleChange("city", val)}
                                        error={errors["city"]}
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("city")}
                                    />
                                </FormControl>
                            </Box>

                            <FormControl fullWidth className={classes.itemField}>
                                <SelectField
                                    label={t("formFields.country")}
                                    name={"country"}
                                    isLoading={isDataFetching}
                                    value={values["country"]}
                                    error={errors["country"]}
                                    options={countries}
                                    onSelectChange={(val) => handleChange("country", val)}
                                    disabled={checkDisabledField("country")}
                                    settings={settingsDarkMode}
                                />
                            </FormControl>

                            <Box className={classes.itemRow}>
                                <FormControl fullWidth error={!!errors.email} className={classes.itemField}>
                                    <InputField
                                        isLoading={isDataFetching}
                                        label={t("formFields.email")}
                                        name="email"
                                        type="email"
                                        value={values["email"]}
                                        onInputChange={(val) => handleChange("email", val)}
                                        error={errors["email"]}
                                        disabled={checkDisabledField("email")}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors.phone} className={classes.itemField}>
                                    <PhoneField
                                        label={t("formFields.phone")}
                                        isLoading={isDataFetching}
                                        name="phone"
                                        type="text"
                                        id="phone"
                                        disabled={checkDisabledField("phone")}
                                        value={values["phone"] ? values["phone"] : ""}
                                        onChangeHandler={(val) => handleChange("phone", val)}
                                        error={errors["phone"]}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={`${classes.itemFormFieldSet}`}>
                        <Box ref={professionalRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                        <Box className={`${classes.itemTitle} card-title card-title--purple`}>
                            {/* NEED TRANSLATION */}
                            {t('Professional Details')}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <FormControl fullWidth className={classes.itemField}>
                                <InputField
                                    label={t("formFields.volveUserId")}
                                    value={user.customerNumber}
                                    disabled={true}
                                    isLoading={isDataFetching}
                                />
                            </FormControl>

                            <FormControl fullWidth className={`${classes.itemField}`}>
                                <FormLabel component="label">
                                    {/* NEED TRANSLATION */}
                                    {t('Roles')}
                                </FormLabel>
                                <FormGroup row={true} className={classes.groupCheckbox}>
                                    {roles.map((role) => {
                                        const {value, label} = role;
                                        return (
                                            <CheckBoxField
                                                key={value}
                                                onCheckboxChange={() => onRolesChange(value)}
                                                value={values.roles ? values.roles.includes(value) : false}
                                                label={t(label)}
                                                disabled={checkDisabledField("roles") || value === "personal"}
                                            />
                                        )
                                    })}
                                </FormGroup>
                                {errors["roles"] && <FormHelperText error>{errors["roles"]}</FormHelperText>}
                            </FormControl>

                            {(values["roles"] && values["roles"].includes("approver")) && (
                                <FormControl className={classes.itemField}>
                                    <FormLabel component="label">
                                        {/* NEED TRANSLATION */}
                                        {t('Select Users for Approval')}
                                    </FormLabel>
                                    <SelectUsersForApproval
                                        isLoading={isDataFetching}
                                        handleChange={handleChange}
                                        values={values["usersForApproval"]}
                                        disabled={checkDisabledField("usersForApproval")}
                                    />
                                </FormControl>
                            )}

                            <FormControl fullWidth className={classes.itemField}>
                                <FormLabel component="label">
                                    {/* NEED TRANSLATION */}
                                    {t('Active Status')}
                                </FormLabel>
                                <CheckBoxField
                                    onCheckboxChange={(val) => onActive(val)}
                                    value={values["status"] === "active"}
                                    name={"status"}
                                    label={t("formFields.isActive")}
                                    disabled={checkDisabledField("status")}
                                />
                            </FormControl>

                            <Box className={classes.itemRow}>
                                <FormControl fullWidth className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.group")}
                                        name={"group"}
                                        value={values["group"]}
                                        error={errors["group"]}
                                        options={groups}
                                        onSelectChange={(val) => handleChange("group", val)}
                                        disabled={checkDisabledField("group")}
                                        isLoading={isDataFetching}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl fullWidth className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.branch")}
                                        name={"branch"}
                                        value={values["branch"]}
                                        error={errors["branch"]}
                                        options={branches}
                                        isLoading={isDataFetching}
                                        onSelectChange={(val) => handleChange("branch", val)}
                                        disabled={checkDisabledField("branch")}
                                        helperText={`${t("important")}: ${t("helpTextUserBranch")}`}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>
                            </Box>

                            <Box className={classes.itemRow}>
                                <FormControl fullWidth error={!!errors["position"]} className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.position")}
                                        placeholder={t("formFields.position")}
                                        name="position"
                                        isLoading={isDataFetching}
                                        value={values["position"]}
                                        onInputChange={(val) => handleChange("position", val)}
                                        error={errors["position"]}
                                        disabled={checkDisabledField("position")}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors["department"]} className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.department")}
                                        name={"department"}
                                        value={values["department"]}
                                        error={errors["department"]}
                                        options={departments}
                                        isLoading={isDataFetching}
                                        onSelectChange={(val) => handleChange("department", val)}
                                        disabled={checkDisabledField("department")}
                                    />
                                </FormControl>
                            </Box>

                            <FormControl fullWidth error={!!errors["personnelNumber"]} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.personnelNumber")}
                                    placeholder={t("formFields.personnelNumber")}
                                    name="personnelNumber"
                                    value={values["personnelNumber"]}
                                    onInputChange={(val) => handleChange("personnelNumber", val)}
                                    error={errors["personnelNumber"]}
                                    isLoading={isDataFetching}
                                    disabled={checkDisabledField("personnelNumber")}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    <Box className={classes.itemFormFieldSet}>
                        <Box ref={financialRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                        <Box className={`${classes.itemTitle} card-title card-title--red`}>
                            {/* NEED TRANSLATION */}
                            {t('Financial Information')}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <FormControl fullWidth error={!!errors["iban"]} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.iban")}
                                    placeholder={t("formFields.iban")}
                                    name="iban"
                                    isLoading={isDataFetching}
                                    value={values["iban"]}
                                    onInputChange={(val) => handleChange("iban", val)}
                                    error={errors["iban"]}
                                    disabled={checkDisabledField("iban")}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors["walletAddress"]} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.walletAddress")}
                                    placeholder={t("formFields.walletAddress")}
                                    name="walletAddress"
                                    isLoading={isDataFetching}
                                    value={values["walletAddress"]}
                                    onInputChange={(val) => handleChange("walletAddress", val)}
                                    error={errors["walletAddress"]}
                                    disabled={checkDisabledField("walletAddress")}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    <Box className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                        <Box ref={additionalRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                        <Box className={`${classes.itemTitle} card-title card-title--yellow`}>
                            {/* NEED TRANSLATION */}
                            {t('Additional Information')}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <FormControl fullWidth className={`${classes.itemField} ${classes.textAreaWrapper}`}>
                                <InputField
                                    name={"comment"}
                                    multiline
                                    rows="4"
                                    label={t("formFields.comment")}
                                    value={values["comment"]}
                                    error={errors["comment"]}
                                    disabled={checkDisabledField("comment")}
                                    isLoading={isDataFetching}
                                    onInputChange={(val) => handleChange("comment", val)}
                                />
                            </FormControl>

                            {(user.cards && user.cards.length > 0) && (
                                <FormControl fullWidth className={`${classes.itemField} ${classes.itemFieldCards}`}>
                                    <FormLabel component="div" className={classes.cardsLabel}>{t("formFields.cards")}</FormLabel>
                                    <Paper elevation={0} component="ul" className={classes.cards}>
                                        {user.cards.map((card) => {
                                            const {id, maskedPAN} = card;
                                            return <li key={id}>
                                                    <Chip
                                                        label={maskedPAN}
                                                        className={`status status-default ${classes.cardChip}`}
                                                        component={Link} to={`/cards/${id}`}
                                                        clickable
                                                    />
                                            </li>
                                        })}
                                    </Paper>
                                </FormControl>
                            )}
                        </Box>
                    </Box>


                    {ability.can("update", subject("user", user)) && (
                        <>
                            <Button
                                type="submit"
                                disabled={isLoading}
                                className={classes.itemFormSubmit}

                            >
                                {t("formFields.save")}
                            </Button>
                            {ability.can("delete", subject("user", user)) && (
                                <Button
                                    onClick={() => onRemoveUser(user._id)}
                                    className={`btn-stroke ${classes.itemFormSubmit}`}
                                >
                                    {t("formFields.delete")}
                                </Button>
                            )}
                        </>
                    )}
                </form>
            </div>
        </div>
    );
};


export default UserForm

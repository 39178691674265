import React, {useEffect, useState} from 'react';
import SelectField from "../../formElements/SelectField";
import {useTranslation} from "react-i18next";
import Api from "../../../services/api";
import {handleErrorMessage} from "../../Messages/actions";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
const service = new Api();

const CategoryField = (props) => {
    const {isLoading, ...restProps} = props;

    const params = useParams();
    const {id} = params; // expenseId from url

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [categories, setCategories] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {

        const promises = [
            service.getCategoriesAll(),
            id ? service.getExpenseCategories(id) : service.getMyCategories() // if id(expenseId) exist - use categories for expense, because expense can belongs to another user
        ];

        Promise.all(promises).then((response) => {
            setIsFetching(true);
            const [allCategoriesResult, availableCategories] = response;
            const categories = allCategoriesResult.data.categories.map(({name, _id}) => {
                const option = {
                    label:name,
                    value:_id
                };

                const isDisabled = !(~availableCategories.data.findIndex(item => item._id === _id));
                if(isDisabled){
                    option.disabled=true
                }
                return option
            });
            setCategories(categories);

        }).catch((e) => {
            dispatch(handleErrorMessage(e))
        }).finally(() => {
            setIsFetching(false);
        });

    },[]);


    return (
        <SelectField
            label={t("formFields.category")}
            name={"category"}
            options={categories}
            isLoading={isFetching || isLoading}
            {...restProps}
        />
    );
};

export default CategoryField;

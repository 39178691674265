import React, { useState } from 'react';
import AddExpenseBtn from "../AddExpenseBtn";
import {Button, CircularProgress, makeStyles} from "@material-ui/core";
import Search from "../../../Bar/Search";
import FilterButton from "../../../../FilterButton";
import ActionBtn from "../ActionBtn";
import ExportBtn from "../ExportBtn";
import {Can} from "../../../../../Context/Can";
import {useTranslation} from "react-i18next";
import { isDarkModeActive } from '../../../../App/App';
import SelectTab from "../../../../SelectTab";
import Api from "../../../../../services/api";
import Skeleton from "react-loading-skeleton";

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px',
            flexFlow: 'row wrap'
        },
    },
    searchWrapper: {
        position: 'relative',
        width: '360px',
        marginRight: 'auto',
        [theme.breakpoints.down('1023')]: {
            width: '250px',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 16px',
        },
        "& .MuiFormControl-root": {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            position: 'relative',
            "& button": {
                margin: '0',
                padding: '0',

                "& svg path": {
                    transition: 'fill .2s',
                },

                "&:hover": {
                    backgroundColor: 'transparent',

                    "& svg path": {
                        stroke: 'var(--clear-blue-primary)'
                    }
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            }
        }
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginLeft: 'auto',
        gap: '8px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            flexFlow: 'row-reverse wrap'
        }
    },

    actionWrapper: {
        [theme.breakpoints.down('767')]: {
            flex: '1',
            marginLeft: 'auto',
            display: 'flex',

            "& button": {
                marginLeft: 'auto'
            }
        }
    }
}));

const service = new Api();

const TopComponent = (props) => {
    const {onFilterOpen, isFilterOpen, fetchExpenses, selected, setSelected, settingsDarkMode, currentTab, tabOptions, onSelectChange} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await service.syncCompanyTransaction();
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
        
    };
    return (
        <div className={classes.head}>
            <div className={classes.searchWrapper}>
                {/* NEED TRANSLATION */}
                <Search
                    type="expenses"
                    placeholderTextExpense={t("Search by description")}
                    settingsDarkMode={settingsDarkMode}
                />
            </div>

            <div className={classes.control}>
                {loading ? (
                    <div
                        className="text-align-center"
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <Button onClick={handleClick}>Sync transactions</Button>
                )}

                <SelectTab
                    value={currentTab}
                    options={tabOptions}
                    onSelectChange={(val) => onSelectChange(val)}
                    settingsDarkMode={settingsDarkMode}
                />

                <ActionBtn
                    fetchExpenses={fetchExpenses}
                    selected={selected}
                    setSelected={setSelected}
                    settingsDarkMode={settingsDarkMode}
                />

                <ExportBtn
                    selected={selected}
                    setSelected={setSelected}
                    settingsDarkMode={settingsDarkMode}
                    fromParentClass={classes.actionWrapper}
                />

                <Can I="create" a={"expense"}>
                    <AddExpenseBtn settingsDarkMode={settingsDarkMode} />
                </Can>

                {!isFilterOpen && (
                    <FilterButton
                        onClick={onFilterOpen}
                        settingsDarkMode={settingsDarkMode}
                    />
                )}
            </div>
        </div>
    );
};

export default TopComponent;

import React, { useEffect, useState } from 'react'
import {withRouter} from "react-router-dom";
import {ReactComponent as HomeSVG} from "../../../../assets/homeNewInvesthub.svg"
import {ReactComponent as TransactionSVG} from "../../../../assets/transactionsInvesthub.svg"
import {ReactComponent as AccountsSVG} from "../../../../assets/accountsInvesthub.svg"
import {ReactComponent as CardSVG} from "../../../../assets/cardInvesthub.svg"
import {ReactComponent as ReportsSVG} from "../../../../assets/reportsInvesthub.svg"
import {ReactComponent as UsersSVG} from "../../../../assets/userCircleInvesthub.svg"
import {ReactComponent as ContactSVG} from "../../../../assets/contacsInvesthub.svg"
import {ReactComponent as SettingsNewSVG} from "../../../../assets/settingsInvesthubNew.svg"
import {ReactComponent as PieChartSVG} from "../../../../assets/chartNewInvesthub.svg"

import MenuItem from "./MenuItem";
import {useTranslation} from "react-i18next";
import MenuItemChild from "./MenuItemChild";
import {Can} from "../../../../Context/Can";
import { makeStyles, List } from '@material-ui/core';
import { compose } from "redux";
import connect from "react-redux/es/connect/connect";
import { isDarkModeActive } from '../../../App/App';

const useStyles = makeStyles(theme => ({
    menu: {
        padding: '0'
    },
}))

const Menu = (props) => {
    const {isOpen, viewPermissions, roles, settings} = props;
    const {t} = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const [canRenderAccountChart, setCanRenderAccountChart] = useState(false)

    useEffect(() => {
        if (!(roles?.length === 1 && roles[0] === "personal")) {
            setCanRenderAccountChart(true);
        }
    }, [roles]);

    return (
        <List component="nav" className={classes.menu}>
            <MenuItem Icon={HomeSVG} to={"dashboard"} isOpen={isOpen} name={t("menu.dashboard")} settings={settings} />

            {/* <Can I="read" a="card">
                <MenuItem Icon={PieChartSVG} to={"transactions"} isOpen={isOpen} name={t("menu.transactions")}>
                    <MenuItemChild to={"transactions/make-payment"} name={t("Make Payment")} />
                </MenuItem>
            </Can> */}

            <Can I="read" a="expense">
                <MenuItem Icon={TransactionSVG} to={"transactions"} isOpen={isOpen} name={t("menu.transactions")} settings={settings}>
                    {/* <MenuItemChild to={"transactions/make-payment"} name={t("Make Payment")} /> */}
                    <MenuItemChild to={"transactions/request-payment"} name={t("Request Payment")} settings={settings} />
                    <MenuItemChild to={"transactions/add"} name={t("Add Expense")} settings={settings} />
                </MenuItem>
            </Can>

            {/* {viewPermissions && viewPermissions.account !== "no" && ( */}
                <Can I="read" a="account">
                    <MenuItem Icon={AccountsSVG} to={"accounts"} isOpen={isOpen} name={t("menu.accounts")} settings={settings} />
                </Can>

            {/* )}  */}
            <Can I="read" a="account">
                {canRenderAccountChart && <MenuItem Icon={PieChartSVG} to={"chart-of-accounts"} isOpen={isOpen} name={t("Chart of Accounts")} settings={settings} />}
            </Can>
            {/* <Can I="read" a="balance">
                <MenuItem Icon={AccountsIcon} to={"balances"} isOpen={isOpen} name={t("menu.balance")}/>
            </Can> */}
            <Can I="read" a="card">
                <MenuItem Icon={CardSVG} to={"cards"} isOpen={isOpen} name={t("menu.cards")} settings={settings}/>
            </Can>

            <Can I="read" a="report">
                <MenuItem Icon={ReportsSVG} to={"reports"} isOpen={isOpen} name={t("menu.reports")} settings={settings}>
                    <Can I="create" a="expenseReportTemplate">
                        <MenuItemChild to={"reports/templates"} name={t("menu.expenseReportTemplates")} settings={settings}/>
                    </Can>
                </MenuItem>
            </Can>
            {viewPermissions && viewPermissions.user !== "no" && (
                <Can I="read" a="user">
                    <MenuItem Icon={UsersSVG} to={"users"} isOpen={isOpen} name={t("menu.users")} settings={settings}/>
                </Can>
            )}
            <Can I="read" a="account">
                <MenuItem Icon={ContactSVG} to={"contacts"} isOpen={isOpen} name={t("menu.contacts")} settings={settings}>
                </MenuItem>
            </Can>
            {viewPermissions && viewPermissions.businessManagement !== "no" && (
                <MenuItem Icon={SettingsNewSVG} to={"settings"} isOpen={isOpen} name={t("menu.settings")} settings={settings}>
                    <Can I="read" a="company">
                        <MenuItemChild to={"settings/company"} name={t("menu.company")} settings={settings}/>
                    </Can>
                    <Can I="read" a="category">
                        <MenuItemChild to={"settings/categories"} name={t("menu.categories")} settings={settings}/>
                    </Can>
                    <Can I="read" a="project">
                        <MenuItemChild to={"settings/projects"} name={t("menu.projects")} settings={settings} />
                    </Can>
                    <Can I="read" a="group">
                        <MenuItemChild to={"settings/groups"} name={t("menu.groups")} settings={settings} />
                    </Can>
                    <Can I="read" a="branch">
                        <MenuItemChild to={"settings/branches"} name={t("menu.branches")} settings={settings} />
                    </Can>
                    <Can I="read" a="taxRate">
                        <MenuItemChild to={"settings/tax-rates"} name={t("menu.taxRates")} settings={settings} />
                    </Can>
                    <Can I="read" a="department">
                        <MenuItemChild to={"settings/departments"} name={t("menu.departments")} settings={settings} />
                    </Can>
                    <MenuItemChild to={"settings/roles"} name={t("menu.roles")}  settings={settings} />
                    <Can I="read" a="rejectReason">
                        <MenuItemChild to={"settings/reject-reasons"} name={t("menu.rejectReasons")}  settings={settings} />
                    </Can>
                    <Can I="read" a="company">
                        <MenuItemChild to={"settings/advanced"} name={t("menu.settingsAdvanced")}  settings={settings} />
                    </Can>
                    <Can I="update:permissions" a="company">
                        <MenuItemChild to={"settings/permissions"} name={t("menu.userPermissions")}  settings={settings} />
                    </Can>
                    <Can I="read" a="company">
                        <MenuItemChild to={"settings/xero"} name={t("menu.xero")} settings={settings}/>
                    </Can>
                </MenuItem>
            )}
        </List>
    )

}
const mapStateToProps = (state) => {
    const { user } = state;
    return {
        roles: user.roles,
    };
};


export default compose(
    connect(mapStateToProps),
    withRouter
)(Menu);

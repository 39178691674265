import React from 'react'
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import XeroSettings from "../userSection/content/XeroSettings";

const XeroSettingsPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef, settings} = props;
    return (
        <>
            <UserSectionWrapper>
                <Can I="read" a="company" passThrough>
                    {(can) => (
                        can ? <XeroSettings underBarRef={underBarRef} settingsDarkMode={settings} /> : <div>{t("company.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )

}

export default XeroSettingsPage

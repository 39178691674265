import React from 'react';
// import {ReactComponent as PlusCircle} from "../../assets/plusCircle.svg";
// import {ReactComponent as ArrowBottom} from "../../assets/arrowBottom.svg";
import {ReactComponent as MoreHorizontal} from "../../assets/moreHorizontal.svg"
import {makeStyles, Button} from "@material-ui/core";
import clsx from 'clsx'
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles({
    button: {
        padding: '0 !important',
        width: '40px',
        height: '40px',
        background: 'none',
        transition: 'all .2s',
        boxShadow: (props) => props.isDarkModeActive ? '0 0 0 2px var(--dark-primary) inset' : '0 0 0 2px var(--very-light-pink-secondary) inset',
        minWidth: 'initial',

        '& svg': {
            width: '24px',
            height: '24px',
            fill: 'var(--battelship-grey)',
            transition: 'all .2s'
        },

        "&:hover": {
            boxShadow: '0 0 0 2px var(--bright-blue) inset',
            "& svg": {
                fill: 'white'
            }
        }
    },
    btnHasDropdown: {
        borderRadius: '50% !important',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--white)',
        boxShadow: 'none',
        "& .MuiButton-label > svg": {
            display: 'none'
        },
        "& .MuiButton-endIcon": {
            margin: '0',
            "& svg": {
                width: '18px',
                height: '18px'
            }
        },
        "&:hover":{
            background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--white)',
            boxShadow: 'none',
            "& svg": {
                fill: 'var(--clear-blue-primary)'
            }
        },
        "& .MuiTouchRipple-root":{
            display: 'none !important'
        }
    },
    btnHasDropdownActive: {
        "& .MuiButton-endIcon svg": {
            fill: 'white'
        }
    }
});

const PlusButton = (props) => {
    const {children, onClickHandler, hasDropdown, hasDropdownActive, customClass, settingsDarkMode, ...restProps} = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    return (
        <Button
            onClick={onClickHandler}
            // startIcon={<PlusCircle className={classes.startIcon}/>}
            endIcon={hasDropdown ? <MoreHorizontal className={classes.endIcon}/> : null}
            {...restProps}
            className={`${clsx(classes.button, customClass)} btn-small ${hasDropdown ? classes.btnHasDropdown : null} ${hasDropdownActive ? classes.btnHasDropdownActive : null}`}
        >
            {children}
        </Button>
    );
};

export default PlusButton;

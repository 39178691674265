import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {compose} from "redux";
import {handleErrorMessage} from "../../../Messages/actions";
import TransactionDescription from "../../../TransactionDescription";
import TopComponent from "./TopComponent";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import queryString from 'query-string';
import Api from "../../../../services/api";
import Pagination from "../../../Pagination";
import Portal from "@material-ui/core/Portal";
import DrawerFilter from "../../DrawerFilter";
import FormatDate from "../../../FormatDate";
import VolveMaterialTable from "../../../VolveMaterialTable";
import useMounted from "../../../../hooks/useMounted";
import checkClick from "../../../../helpers/checkClick";
import CellTransactionAmount from "../../../CellTransactionAmount";
import CellCardAmount from "../../../CellCardAmount";
import getCardName from "../../../../helpers/getCardName";
import TransactionsFilter from "../../DrawerFilter/TransactionsFilter";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    tableTransactions: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}));

const TransactionsTable = (props) => {
    const classes = useStyle();

    const isMounted = useMounted();
    const {dateFormat, location, history, handleErrorMessage, drawerOuterRef, underBarRef, tabs} = props;

    const {search} = location;
    const [transactions, setTransactions] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [info, setInfo] = useState({
        page: 0,
        total: 0,
        pageSize: 0
    });

    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    const queryObject = queryString.parse(search);
    const activeTab = tabs.active;

    const fetchTransactions = async (queryObject) => {
       try {
           setIsLoading(true);
           const response = await service.getTransaction({...queryObject, fromTab: activeTab});
           const {info, result} = response.data;

           setTransactions((old) => {
               if(info.page > 1){
                   // merge only if it is pagination request
                    return [...old, ...result]
               }
               return result
           });
           setInfo(info);
       }catch (e) {
           console.log(e);
           handleErrorMessage(e)
       }finally {
           setIsLoading(false);
       }

    }

    useEffect(() => {
        const queryObject = queryString.parse(search);
        fetchTransactions(queryObject);
    },[search, activeTab]);


    const {page, total, pageSize} = info;

    const handleChangePage = async (event, newPage) => {
        fetchTransactions({...queryObject, page:newPage})
    }

    const rowHandlerClick = (e, rowData) => {
        const {expense} = rowData;
        if(!expense || !expense._id) return;

        const wasClicked = checkClick();
        if(wasClicked) {
            history.push({
                pathname: `/transactions/${rowData.expense._id}`,
            })
        }
    }

    const columns = [
        {
            title: t('thead.date'),
            render: (row) => ( <FormatDate dateFormat={dateFormat}>{row.date}</FormatDate>)
        },
        {
            title: t('thead.description'),
            field: 'description',
            render : ({description}) => <TransactionDescription description={description}/>
        },
        ...(tabs.active !== "personal" ? [{
            title: t('thead.employee'),
            render: ({user}) => {
                if(!user) return '';
                const {firstName, lastName} = user;
                return [firstName, lastName].join(" ");
            }
        }] : [] ),
        {
            title: t('thead.card'),
            render: ({card}) => {
                if(!card) return '';
                return getCardName(card)
            }
        },
        {
            title: t('thead.transactionType'),
            field: 'type',
        },
        {
            title: t('thead.statusCode'),
            field: 'statusCode',
        },
        {
            title: t('thead.transactionAmount'),
            align:'right',
            render: (row) => <CellTransactionAmount {...row}/>,
        },
        {
            title: t('thead.cardAmount'),
            align:'right',
            render: (row) => <CellCardAmount {...row}/>,
        },
    ];

    return <>
        {isMounted && (
            <>
                <Portal container={drawerOuterRef.current}>
                    <DrawerFilter isOpen={isFilterOpen} onDrawerFilterClose={() => setIsFilterOpen(false)}>
                        <TransactionsFilter dateFormat={dateFormat}/>
                    </DrawerFilter>
                </Portal>
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("transaction.mainTitle")}
                    </div>
                </Portal>
            </>
        )}
        <div className={`card ${classes.tableTransactions}`}>
            <TopComponent onFilterOpen={() => setIsFilterOpen(true)} isFilterOpen={isFilterOpen}/>
            <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={transactions}
                onRowClick={rowHandlerClick}
            />
            <Pagination
                total={total}
                pageSize={pageSize}
                page={page}
                isLoading={isLoading}
                onChangePage={handleChangePage}
                loadMore
            />
        </div>
    </>
}

const mapStateToProps = (state) => {
    const {user, tabs} = state;
    const {regional={}} = user.settings;
    const {dateFormat} = regional;

    return {
        dateFormat,
        tabs: tabs["transactions"]
    }

}

export default compose(
    connect( mapStateToProps,{handleErrorMessage}),
    withRouter,
)(TransactionsTable)

import * as actionTypes from '../../../../store/actionTypes'

const initialState = {
	groups: {
		result:[],
		info:{
			fields:["id", "name"],
			page: 0,
			total: 0,
			pageSize: 0
		}
	},

}

const userGroups = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.GROUPS_FETCH_SUCCESS :
            return {
                ...state,
                groups:{
                    ...action.payload,
                    result: action.payload.info.page > 1 ? [...state.groups.result, ...action.payload.result] : action.payload.result,
                    info:{
                        ...action.payload.info,
                    }
                },
            }
		case actionTypes.GROUPS_FETCH_FAILURE :
			return {
				...state,
				groups:{
					result:[],
					info:initialState.groups.info
				}
			}
		case actionTypes.USER_LOGOUT:
			return initialState;

		default :
			return state
	}
	return state

}

export default userGroups

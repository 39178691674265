import React from 'react'
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {drawerMainToggle} from '../../DrawerMain/actions'

const useStyles = makeStyles(theme => ({
    headerBurger: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '48px',
        height: '48px',
        marginRight: '12px',
        cursor: 'pointer',
        "& span": {
            width: '16px',
            height: '2px',
            borderRadius: '2px',
            background: "var(--battelship-grey)",
        },
        "& span:first-child": {
            marginBottom: "6px"
        }
    },
}))

const ButtonMenu = ({drawerMainToggle}) => {
    const classes = useStyles();

    return (
        <div className={classes.headerBurger} onClick={drawerMainToggle}>
            <span>{' '}</span>
            <span>{' '}</span>
        </div>
    )
}

export default connect(
	null,
	{drawerMainToggle}
)(ButtonMenu)

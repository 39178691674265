import i18n from "i18next";
import getShortPan from "./getShortPan";

const getCardName = ({type, name, maskedPAN=''}) => {
    const shortPan = getShortPan(maskedPAN);
    if(type === 'virtual'){
        return [name, shortPan].filter((item) => item).join(" ")
    }else{
        return `${i18n.t('card.personalCard')} ${shortPan}`
    }

}

export default getCardName

import React, {useCallback, useEffect, useRef, useState} from 'react';
import DialogPopUp from "../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import {makeStyles, Button, DialogActions, DialogContent, RadioGroup, Radio, Box, FormControlLabel} from "@material-ui/core";
import Webcam from "react-webcam";
import base64ToFile from "../../../../../helpers/base64ToFile";
import {useDispatch} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions"
import Api from "../../../../../services/api";
import compressImage from "../../../../../helpers/compressImage";
import useExpenseQueues from "../../../../../hooks/useExpenseQueues";
import { isDarkModeActive } from '../../../../App/App';
const service = new Api();

const useStyles = makeStyles(theme => ({
            content: {
                "& video":{
                    width:"300px",
                    maxWidth:"100%"
                },
                // width: "300px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px",
                textAlign: "center",
            },
            actionsWrapper: {
                textAlign:"center",
                "& button":{
                    margin:"10px"
                }
            },

            information: {
                padding: '24px',
                borderRadius: '12px',
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
                fontWeight: '700',
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--charcoal-primary)'
            }
        }
    )
);



const CameraDialog = (props) => {
    const {handleClose, open, settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const dispatch = useDispatch();

    const [, setExpensesQueue] = useExpenseQueues();

    const [image, setImage] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [canUseCamera, setCanUseCamera] = useState(true);
    const [facingMode, setFacingMode] = useState("back");
    const webcamRef = useRef(null);

    const cameraHeight=400;

    useEffect(() => {
        setCanUseCamera(true);
    },[facingMode]);

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
        },
        [webcamRef]
    );

    const takePicture = async () => {
        try {
            setDisabled(true);
            const imageFile = base64ToFile(image);
            const formData = new FormData();
            const file = await compressImage(imageFile);
            formData.append("receipt", file);
            const createdExpenseQueue = await service.createExpenseQueue(formData);
            handleClose();
            dispatch(setMessage(t("success"), 'success'));
            setExpensesQueue((items) => {
                return [
                    createdExpenseQueue.data,
                    ...items
                ]
            })
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e))
        }finally {
            setDisabled(false);
        }
    }

    const onFacingModeChange = (e) => {
        setFacingMode(e.target.value);
    }



    return (
        <DialogPopUp
            open={open}
            onClose={handleClose}
            aria-labelledby="ocr-camera-title"
            aria-describedby="ocr-camera-description"
            title={t("expense.captureWithCamera")}
            pageTitleClass={'card-title--red'}
            settingsDarkMode={settingsDarkMode}
        >
            <DialogContent className={classes.content}>
                {canUseCamera ? (
                    <Box>
                        {image ? <img src={image} alt=""/> : (
                            <Webcam
                                audio={false}
                                onUserMediaError={(e) => {
                                    console.log(e);
                                    setCanUseCamera(false)
                                }}

                                height={cameraHeight}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    facingMode : facingMode === "front" ? "user" : {exact: "environment"}
                                }}
                            />
                        )}

                </Box>
                ) : (
                    <Box width={"100%"} height={cameraHeight} display="flex" alignItems="center" justifyContent="center" margin={"0 auto"}>
                        <Box className={classes.information}>{t("expense.cantUseCamera")}</Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Box>
                    {!image && (
                        <RadioGroup aria-label="camera-type" value={facingMode} onChange={onFacingModeChange} row={true}>
                            <FormControlLabel value="front" control={<Radio />} label={t("expense.cameraFront")} disabled={disabled}/>
                            <FormControlLabel value="back" control={<Radio />} label={t("expense.cameraBack")} disabled={disabled}/>
                        </RadioGroup>
                    )}
                    <Box className={classes.actionsWrapper}>
                        {(canUseCamera && !image) && (
                            <Button onClick={capture} color="primary"  variant="contained">
                                {t("expense.takePhoto")}
                            </Button>
                        )}

                        {(canUseCamera && image) && (
                            <>
                                <Button onClick={() => setImage(null)} color="primary"  variant="contained" disabled={disabled}>
                                    {t("expense.resetPhoto")}
                                </Button>
                                <Button onClick={() => takePicture()} color="primary"  variant="contained" disabled={disabled}>
                                    {t("expense.addPhotoToExpense")}
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </DialogActions>
        </DialogPopUp>
    );
};

export default CameraDialog;

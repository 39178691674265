import React from 'react';
import {
    Box,
    makeStyles,
} from '@material-ui/core'
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles(theme => ({
    // Header card
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },

    card: {
        height:"100%",
        width:"100%"
    },
    header: {
        padding: '34px 30px 10px 30px',
        '& .MuiTypography-root':{
            color: '#354052',
            fontWeight: 300,
            fontSize:'20px',
        },
        '& .MuiCardHeader-action':{
            margin:0,
        }
    },
}))

const DashboardPane = (props) => {
    const {
        title,
        // eslint-disable-next-line no-unused-vars
        action,
        cardContentProps,
        headingColorClasses,
        settingsDarkMode
    } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });



    return (
        <Box className='card'>
            <Box className={classes.head}>
                <Box className={`${classes.headTitle} card-title ${headingColorClasses}`}>
                    {title}
                </Box>
            </Box>
            <Box {...cardContentProps}>
                {props.children}
            </Box>
        </Box>
    )
};

export default DashboardPane;

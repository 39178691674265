import React, {useCallback, useContext, useEffect, useState} from 'react';
import {compose} from "redux";

// import TopComponent from "../TopComponent";
import TopComponentInvesthub from '../TopComponentInvesthub';
import {connect} from "react-redux";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {setMessage, handleErrorMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import useMounted from "../../../../../hooks/useMounted";
import CheckBoxField from "../../../../formElements/CheckBoxField";
import {AbilityContext} from "../../../../../Context/Can";
import CheckBoxFieldMemo from "../../../../formElements/CheckBoxFieldMemo";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles, Typography, Box, Portal} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}))

const CategoriesTable = (props) => {
    const {match, history, setMessage, handleErrorMessage, underBarRef, settingsDarkMode} = props;
    const classes = useStyle();
    const {t} = useTranslation();
    const ability = useContext(AbilityContext);

    const [info, setInfo] = useState({
        fields:["action", "id", "name"],
        page: 0,
        total: 0,
        pageSize: 0
    });

    const {page, total, pageSize} = info;
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const isMounted = useMounted();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchItems()
    },[]);

    const fetchItems = async (query) => {

        try {
            setIsLoading(true);
            const response = await service.getCategories(query);
            const {result, info} = response.data;
            setInfo({
                ...info,
                fields:["action", ...info.fields]
            });
            setCategories((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }

    }

    const handleChangePage = (event, newPage) => {
         return fetchItems({page:newPage});
    }

    const rowHandlerClick = async (e, rowData) => {
        if(['LABEL​', 'INPUT'].includes(e.target.tagName)) return;

        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }

    const onImport = async (formData) => {
        const response = await service.importCategories(formData);
        const {data} = response;
        const {modified, doNotModified} = data;
        const message = t("category.importInfo", {modified:modified, all:modified+doNotModified});
        setMessage(message, 'success');
        await fetchItems();
    }

    const onDisableBatch = async () => {
        await service.disableBatchCategories({ids: selectedCategories});
        setMessage("success", 'success');
        await fetchItems();
        setSelectedCategories([]);
    }


    const onCheckAll = (value) => {
        if(categories.length){
            setSelectedCategories(value ? categories.map((item) => item._id) : []);
        }
    }

    const onChange = (e ,value) => {
        setSelectedCategories((items) => {

            if(value){
                const copy = [...items];
                copy.push(e.target.id);
                return copy;
            }else{
                return items.filter((item) => item !== e.target.id)
            }
        })
    }


    const memoizedOnChange = useCallback(onChange , []);


    const columns = [
        ...(ability.can("update", "category") ? [
            {
                title: categories.length ? (
                    <Box paddingLeft={"6px"}>
                        <CheckBoxField
                            onCheckboxChange={(val) => onCheckAll(!!val)}
                            value={!!(categories.length && (selectedCategories.length === categories.length))}
                            label={''}
                        />
                    </Box>
                ) : '',
                headerStyle: { padding:' 0 0 0 16px'},
                cellStyle: { padding:' 0 0 0 16px'},
                maxWidth: 60,
                render: ({_id }) => (
                    <CheckBoxFieldMemo
                        onChange={memoizedOnChange}
                        checked={selectedCategories.includes(String(_id))}
                        label={''}
                        id={String(_id)}
                        name={""}
                    /> )
            }
        ] : []),
        {
            title: t('thead.name'),
            field: 'name',
        },
        {
            title: t('thead.branch'),
            field: 'branch',
            render: ({branch}) => (branch && branch.name) ? branch.name : ""
        },
        {
            title: t('thead.active'),
            field: 'active',
            render: ({active}) => active ? "Yes" :  "No",
        },
    ];

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <Box className={`card`}>
                <TopComponentInvesthub
                    history={history}
                    onImport={onImport}
                    selectedCategories={selectedCategories}
                    onDisableBatch={onDisableBatch}
                    settingsDarkMode={settingsDarkMode}
                />
                <Box className="table-wrap table-wrap--have-line table-wrap--have-px">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={categories}
                        onRowClick={rowHandlerClick}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>
            </Box>
        </>
    );
};


export default compose(
    connect(null, {setMessage, handleErrorMessage})
)(CategoriesTable);

import React, {
    useEffect,
    // eslint-disable-next-line no-unused-vars
    useState
} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import {
    makeStyles,
    useTheme,
    Box,
    ListItem,
    useMediaQuery,
    Tooltip,
    FormControl
} from '@material-ui/core';
import MaterialUiDrawer from "@material-ui/core/Drawer";
import clsx from 'clsx';
import {ReactComponent as Logo} from '../../../assets/iconEvolveWhite.svg'
// import {ReactComponent as Logo} from "../../../assets/paladis.svg"
import {ReactComponent as ArrowLeftSVG} from '../../../assets/arrowLeftInvesthub.svg'
import {ReactComponent as ArrowRightSVG} from '../../../assets/arrowRightInvesthub.svg'
import {connect} from "react-redux";
import {drawerMainMenuPermissions, drawerMainToggle} from './actions'
import Menu from "./Menu";
import {useTranslation} from "react-i18next";
// import Notifications from "./Notifications";
import {compose} from "redux";
// import useDrawerWidth from "../../../hooks/useDrawerWidth";
import SelectField from '../../formElements/SelectField';
import { getExternalFileUrl } from "../../../helpers/file"
import { isDarkModeActive } from '../../App/App';

const useStyles = makeStyles(theme => ({
    drawer: {
    },
    drawerInner: {
        "&.MuiDrawer-paper": {
            border: 'none',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-secondary)' : 'var(--pale-grey)',
            padding: '24px',
        }
    },
    logo: {
        padding: '22px 10px 33px 28px',
        display: 'inline-flex',
        maxWidth: '96px',
        '& svg': {
            width: '100%',
            height: 'auto'
        }
    },
    sidebarLogo: {
        display: "block",
        width: "165px",
        marginBottom: '48px',
        [theme.breakpoints.down('1339')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('1259')]: {
            textAlign: 'center'
        },

        "& svg": {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    sidebarDropdown: {
        marginBottom: '48px',
        [theme.breakpoints.down('1339')]: {
            marginBottom: '24px'
        },
    },
    sidebarMenu: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 'auto'
    },
    sidebarBottom: {
        marginTop: '24px',
        paddingTop: '18px',
        borderTop: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid var(--very-light-pink-primary)',
        textAlign: 'center',
        [theme.breakpoints.down(1259)]: {
            marginTop: '12px',
            paddingTop: '12px'
        }
    },
    sidebarBottomExpand: {
        width: '48px',
        height: '48px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--very-light-pink-primary)',
        "& svg": {
            width: '24px',
            height: '24px',
            fill: 'var(--battelship-grey)',
            transition: 'all .2s',
        },
        "&:hover": {
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--very-light-pink-primary)',
            "& svg": {
                fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            }
        }
    },
    sidebarBottomShrink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        borderRadius: '33px',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--very-light-pink-primary)',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',
        "& svg": {
            width: '24px',
            height: '24px',
            fill: 'var(--battelship-grey)',
            marginRight: '8px',
            position: 'relative',
            top: '-1px',
            transition: 'all .2s',
        },
        "&:hover": {
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--very-light-pink-primary)',
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            "& svg": {
                fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            }
        }
    },
    sidebarOverlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '25',
        background: (props) => props.isDarkModeActive ? 'rgba(39, 43, 48, 0.9)' : 'rgba(244, 244, 244, 0.8)',
        // visibility: 'hidden',
        // opacity: '0',
        transition: 'all .2s',
    }

}));

const DrawerMain = (props) => {
    const {
        drawerMain,
        drawerMainToggle,
        isAuthenticated,
        // eslint-disable-next-line no-unused-vars
        isWhiteLabel,
        drawerMainMenuPermissions,
        publicSettings,
        settings,
    } = props;

    const dummyOptions = [
        { value: 'personal', label: 'Personal' },
        { value: 'company', label: 'Company'}
    ];

    const {isOpen, viewPermissions} = drawerMain;
    // const drawerWidth = useDrawerWidth();

    const usStylesDrawer = makeStyles((theme) => (
        {
            drawerDefault: {
                width: '340px'
            },
            drawerInnerOnMax1339: {
                width: '300px',
                padding: '16px !important',
            },
            drawerInnerOnMax1259: {
                zIndex: '30',
                width: '96px',
                alignItems: 'center'
            },

            drawerInnerIsOpen: {
                '&$drawerDefault': {

                    [theme.breakpoints.down('1259')]: {
                        alignItems: 'stretch',
                        width: '300px',
                        boxShadow: '4px 0 32px rgba(17, 19, 21, 0.05)',
                    }

                }
            },

            drawerInnerShrink: {
                '&$drawerDefault': {
                    zIndex: '30',
                    width: '96px',
                    alignItems: 'center'
                },
                '&$drawerInnerOnMax1339': {
                    zIndex: '30',
                    width: '96px',
                    alignItems: 'center'
                },
                '&$drawerInnerOnMax1259': {
                    zIndex: '30',
                    width: '96px',
                    alignItems: 'center'
                }

            },
            drawerInnerExpand: {
                '&$drawerDefault': {
                    width: '340px',
                },
                "&$drawerInnerOnMax1339": {
                    width: '300px',
                },
                "&$drawerInnerOnMax1259": {
                    width: '300px',
                    boxShadow: '4px 0 32px rgba(17, 19, 21, 0.05)',
                    alignItems: 'stretch'
                }
            }

        }
    ));

    const {t} = useTranslation();

    const theme = useTheme();
    // const isPc = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});
    const onMax1339 = useMediaQuery(theme.breakpoints.down('1339'), {noSsr: true});
    const onMax1259 = useMediaQuery(theme.breakpoints.down('1259'), {noSsr: true});
    const isDown1023 = useMediaQuery(theme.breakpoints.up('1023'), {noSsr: true});
    const onMax767 = useMediaQuery(theme.breakpoints.down('767'), {noSsr: true})
    const variant = !onMax767 ? 'permanent' : 'temporary';

    useEffect(() => {
        drawerMainMenuPermissions();
    },[]);


    useEffect(() => {
        if (isOpen && !isDown1023) {
            // close main drawer by default on 1023 viewport
            drawerMainToggle();
        }
    }, []);


    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const drawerClasses = usStylesDrawer();
    if (!isAuthenticated) {
        return null;
    }

    return (
        <>
            <MaterialUiDrawer
                variant={variant}
                classes={{
                    paper: clsx(classes.drawerInner, drawerClasses.drawerDefault,
                    {
                        [drawerClasses.drawerInnerOnMax1339]: onMax1339,
                        [drawerClasses.drawerInnerOnMax1259]: onMax1259,
                        [drawerClasses.drawerInnerIsOpen]: isOpen,
                        [drawerClasses.drawerInnerOnVariant]: variant
                    })
                }}
                data-test="drawer-main"
                open={isOpen}
                ModalProps={{onBackdropClick: drawerMainToggle}}
            >
                {(isOpen) && (
                    <NavLink to={`/dashboard`} className={clsx(classes.sidebarLogo)}>
                        { publicSettings && publicSettings.logo_path &&
                            <img
                                className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                                src={getExternalFileUrl(publicSettings.kycHost, publicSettings.logo_path)}
                                alt="logo"
                            />
                        }
                        { (!publicSettings || !publicSettings.logo_path) &&
                            <Logo />
                        }
                    </NavLink>
                )}

                {(isOpen) && (
                    <Box className={clsx(classes.sidebarDropdown)}>
                        <FormControl fullWidth>
                            <SelectField
                                value="personal"
                                options={dummyOptions}
                                settings={settings}
                            />
                        </FormControl>
                    </Box>
                )}

                <Box className={clsx(classes.sidebarMenu)}>
                    <Menu isOpen={isOpen} viewPermissions={viewPermissions} settings={settings} />
                </Box>

                {onMax1259 && (
                    <Box className={classes.sidebarBottom}>
                        {/* NEED TRANSLATION */}
                        {(!isOpen) ? (
                            <>
                                <Tooltip title="Expand Menu">
                                    <ListItem
                                        button
                                        onClick={drawerMainToggle}
                                        className={classes.sidebarBottomExpand}
                                    >
                                        <ArrowRightSVG />
                                    </ListItem>
                                </Tooltip>
                            </>
                        ): (
                            <ListItem
                                button
                                onClick={drawerMainToggle}
                                className={classes.sidebarBottomShrink}
                            >
                                <ArrowLeftSVG />
                                {' '}
                                {t("collapseMenu")}
                            </ListItem>
                        )}
                    </Box>
                )}
            </MaterialUiDrawer>
            {onMax1259 && (
                <>
                    {(isOpen) && (
                        <Box className={classes.sidebarOverlay} onClick={drawerMainToggle}>{' '}</Box>
                    )}
                </>
            )}
        </>
    )
}

const mapStateToProps = ({drawerMain, user, publicSettings}) => {
    const {userData, isAuthenticated, roles} = user;
    const {company} = userData;
    const {isWhiteLabel} = company;

    return {
        drawerMain,
        isAuthenticated,
        roles,
        isWhiteLabel,
        publicSettings,
    }
};

export default compose(
    withRouter,
    connect( mapStateToProps,{drawerMainToggle, drawerMainMenuPermissions})
)(DrawerMain)

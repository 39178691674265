import React from 'react';
import {makeStyles} from "@material-ui/core";
import {ReactComponent as InfoExclamation} from "../../assets/infoExclamation.svg";
import Tooltip from "@material-ui/core/Tooltip";

const useToolTipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#F5A623',
        color:"#fff",
        fontSize: "12px"
    },
    arrow:{
        color:"#F5A623",
    }
}));


const ExclamationToolTip = (props) => {
    const toolTipClasses = useToolTipStyles();
    return <Tooltip arrow placement={"bottom"} {...props} classes={toolTipClasses}><InfoExclamation/></Tooltip>;
};

export default ExclamationToolTip;

import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";

const ExpenseDashboardStatus = (props) => {
    const { status } = props;
    let statusClass = null;
    switch (status) {
        case "toSubmit":
            statusClass = "status-yellow";
            break;
        case "completed":
            statusClass = "status-green";
            break;
        case "approved":
            statusClass = "status-green";
            break;
        case "submitted":
            statusClass = "status-blue";
            break;
        case "rejected":
            statusClass = "status-red";
            break;
        case "unapproved":
            statusClass = "status-red";
            break;
        case "recalled":
            statusClass = "status-red";
            break;

        default:
            statusClass = "status-default";
    }

    const useStyles = makeStyles((theme) => ({
        fontSize: {
            fontSize: "14px",
        },
    }));

    const classes = useStyles();

    const { t } = useTranslation();
    const handleStatusText = (status) => {
        if (!status) {
            return ""
        }
        if (status === "toSubmit") {
            return t(capitalizeFirstLetter("To be submitted"))
        } else {
            return t(capitalizeFirstLetter(status))
        }
    }
    return <Box className={`status ${statusClass} ${classes.fontSize}`}>{handleStatusText(status)}</Box>;
};

export default ExpenseDashboardStatus;

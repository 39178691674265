import { createTheme } from "@material-ui/core";

const secondaryColor = "#354052";
const primaryColor = "#ff0000";
const paladisWhite = "#FFF"; // --paladis-white: #FFF;
const paladisWhiteSmoke = "#F4F5F5"; // --paladis-white-smoke: #F4F5F5;
const paladisBlack = "#000000"; // --paladis-black: #000000;
const paladisBlackHole = "#010302"; // --paladis-black-hole: #010302;
const paladisGhostWhisperer = "#CBD1D5";// --paladis-ghost-whisperer: #CBD1D5;
const paladisYachtClub = "#595F61"; // --paladis-yacht-club: #595F61;
const paladisMithril = "#878588"; // --paladis-mithril: #878588;
const paladisEbony = "#31343A";// --paladis-ebony: #31343A;
const paladisAluminium = "#828588"; // --paladis-aluminium: #828588;
const paladisPlacebo= "#e8e8e8"; // --paladis-placebo: #e8e8e8;

const themeConfigurationPaladis = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                '1339': 1339,
                '1259': 1259,
                '1129': 1129,
                '1023': 1023,
                '767': 767,
                '476': 476

            },
        },

        // OVERRIDES FOR GLOBAL STYLES
        overrides: {
            // TYPOGRAPHY SETTINGS
            MuiTypography: {
                h1: {
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                    fontSize: '64px',
                    lineHeight: '1',
                    letterSpacing: '-.03em',

                },
                h2: {
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                    fontSize: '48px',
                    lineHeight: '1',
                    letterSpacing: '-.03em',
                },
                h3: {
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                    fontSize: '40px !important',
                    lineHeight: '1.2',
                    letterSpacing: '-.02em',
                    '@media only screen and (max-width: 767px)': {
                        fontSize: '32px',
                        lineHeight: '1.25',
                        letterSpacing: '-.03em',
                    }
                },
                h4: {
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                    fontSize: '28px',
                    lineHeight: '1.2'
                },
                h5: {
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                },
                h6: {
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                    fontSize: "17px",
                    lineHeight: "30px"
                },
                subtitle1: {
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                    fontSize: "20px",
                    lineHeight: "35px",
                },
                body2: {
                    fontSize: "15px",
                    lineHeight: "26px"
                }
            },
            // BASE GLOBAL STYLE SETTINGS
            MuiCssBaseline: {
                "@global": {
                    ":root": {
                    },
                    body: {
                        backgroundColor: 'var(--almost-black)',
                        color: 'var(--pale-grey)',
                        minWidth: '375px',
                        fontFamily: `'Inter', sans-serif`,
                        fontSize: '15px',
                        fontWeight: '600',
                        lineHeight: '1.6',
                        letterSpacing: '-.01em',
                        "&.no-scroll": {
                            overflow: 'hidden'
                        }
                    },
                    'a': {
                        textDecoration: 'none',
                    },
                    'button': {
                        background: 'none'
                    },
                    'button, input, textarea': {
                        fontFamily: `'Inter', sans-serif`
                    },
                    'svg, img': {
                        verticalAlign: 'middle'
                    },
                    '.icon': {
                        width: '16px',
                        height: '16px',
                        fill: 'var(--dark-secondary)'
                    },

                    // HEADING CLASSES
                    '.h1, .h2, .h3, .h4, .h5, .h6': {
                        fontFamily: `'Inter', sans-serif`,
                        fontWeight: '600',
                    },
                    '.h1': {
                        fontSize: '64px !important',
                        lineHeight: '1',
                        letterSpacing: '-.03em',
                    },
                    '.h2': {
                        fontSize: '48px !important',
                        lineHeight: '1',
                        letterSpacing: '-.03em',
                    },
                    '.h3': {
                        fontSize: '40px !important',
                        lineHeight: '1.2',
                        letterSpacing: '-.02em',
                        '@media only screen and (max-width: 767px)': {
                            fontSize: '32px !important',
                            lineHeight: '1.25',
                            letterSpacing: '-.03em',
                        }
                    },
                    '.h4': {
                        fontSize: '28px !important',
                        lineHeight: '1.2'
                    },

                    // TEXT-WEIGHT CLASSES
                    '.text-weight-200': {
                        fontWeight: '200'
                    },
                    '.text-weight-300': {
                        fontWeight: '300'
                    },
                    '.text-weight-500': {
                        fontWeight: '500'
                    },
                    '.text-weight-600': {
                        fontWeight: '600'
                    },
                    '.text-weight-700': {
                        fontWeight: '700'
                    },

                    // TEXT-ALIGN CLASSES
                    '.text-align-left': {
                        textAlign: 'left',
                    },
                    '.text-align-center': {
                        textAlign: 'center',
                    },
                    '.text-align-right': {
                        textAlign: 'right',
                    },

                    // TEXT-STYLE CLASSES
                    '.text-style-uppercase': {
                        textTransform: 'uppercase',
                    },
                    '.text-style-nowrap': {
                        whiteSpace: 'nowrap',
                    },
                    '.text-style-italic': {
                        textAlign: 'italic',
                    },
                    '.title-blue::before': {
                        backgroundColor: paladisBlackHole,
                    },

                    // BACKGROUND CLASSES
                    '.bg-red': {
                        backgroundColor: 'var(--orange-pink)'
                    },
                    '.bg-green': {
                        backgroundColor: 'var(--faded-green)'
                    },
                    '.bg-default': {
                        backgroundColor: 'var(--dark-primary)'
                    },

                    // STATUS
                    '.status': {
                        display: 'inline-block',
                        padding: '0 8px',
                        borderRadius: '6px',
                        fontSize: '12px',
                        lineHeight: '24px',
                        fontWeight: '700',
                    },
                    '.status-green': {
                        background: 'var(--pale-turquoise)', // Need in light var(--off-white)
                        color: 'var(--dark-secondary)',
                    },
                    '.status-red': {
                        background: 'var(--orange-pink)', // need in light var(--very-light-pink-tertiary)
                        color: 'var(--dark-secondary)',
                    },
                    '.status-purple': {
                        background: 'var(--light-periwinkle)',
                        color: 'var(--lighter-purple)',
                    },
                    '.status-blue': {
                        background: 'var(--powder-blue)',
                        color: 'var(--dark-secondary)'
                    },
                    '.status-yellow': {
                        background: 'var(--wheat)',
                        color: 'var(--dark-secondary)'
                    },
                    // CARD CLASS.
                    '.card': {
                        position: 'relative',
                        padding: '24px',
                        borderRadius: '8px',
                        backgroundColor: 'var(--dark-secondary)',
                        '&:not(:last-child)': {
                            marginBottom: '8px'
                        },
                        '@media only screen and (max-width: 767px)': {
                            padding: '16px'
                        }
                    },

                    // CARD TITLE CLASSES
                    '.card-title': {
                        marginRight: 'auto',
                        '&:before': {
                            content: `""`,
                            position: 'absolute',
                            top: '50%',
                            left: '0',
                            width: '16px',
                            height: '32px',
                            transform: 'translateY(-50%)',
                            borderRadius: '4px',
                        }
                    },
                    '.card-title--purple:before': {
                        backgroundColor: 'var(--light-periwinkle)'
                    },
                    '.card-title--red:before': {
                        backgroundColor: 'var(--pale-salmon)',
                    },
                    '.card-title--blue:before': {
                        backgroundColor: 'var(--powder-blue)'
                    },
                    '.card-title--yellow:before': {
                        backgroundColor: 'var(--wheat)'
                    },
                    '.card-title--green:before': {
                        backgroundColor: 'var(--pale-turquoise)'
                    },
                    '.card-title--pink:before': {
                        backgroundColor: 'var(--pink)'
                    },
                    '.card-title--pop:before': {
                        backgroundColor: 'var(--green-pop)'
                    },

                    // GLOBAL SELECT/DROPDOWN FIELD VARIANT SMALL *It must settings here
                    '.select-small': {
                        '& .MuiSelect-root.MuiSelect-select': {
                            height: '40px',
                            lineHeight: '40px',
                            padding: '0 40px 0 16px',
                            fontSize: '14px',
                            fontWeight: '600'
                        }
                    },
                    // GLOBAL SELECT/DROPDOWN FIELD VARIANT STROKE *It must settings here
                    '.select-stroke': {
                        '& .MuiSelect-root.MuiSelect-select': {
                            background: "none",
                            "&[aria-expanded='true']": {
                                boxShadow: 'inset 0 0 0 2px var(--charcoal-primary)',
                                background: 'var(--almost-black)'
                            }
                        }
                    },

                    // GLOBAL FIX TABLE *IT MUST PUT INSIDE .table-wrap
                    '.table-wrap': {
                        "& .MuiPaper-root": {
                            background: 'initial',
                            color: 'initial',
                            borderRadius: 'initial',

                            "& tr th:first-child" :{
                                "& .MuiBox-root": {
                                    paddingLeft: '0',
                                    paddingBottom: '8px'
                                }
                            }
                        },
                        // HAVE LINE
                        "&.table-wrap--have-line": {
                            "& .MuiTableBody-root .MuiTableCell-root": {
                                borderBottom: '1px solid var(--dark-primary)', // Need in light var(--very-light-pink-secondary)
                            }
                        },

                        // HAVE PADDING LEFT RIGHT
                        "&.table-wrap--have-px": {
                            "& .MuiTable-root tr th": {
                                paddingLeft: '16px !important',
                                paddingRight: '16px !important'
                            },
                            "& .MuiTable-root tr td": {
                                paddingLeft: '16px !important',
                                paddingRight: '16px !important'
                            }
                        },

                        // HAVE OFFSET LEFT RIGHT
                        // Must have parent 'card'
                        "&.table-wrap--have-mx-offset": {
                            marginLeft: '-24px',
                            marginRight: '-24px',
                            '@media only screen and (max-width: 767px)': {
                                marginLeft: '-16px',
                                marginRight: '-16px',
                            },

                            "& .MuiTable-root tr th": {
                                paddingLeft: '24px !important',
                                paddingRight: '24px !important'
                            },
                            "& .MuiTable-root tr td": {
                                paddingLeft: '24px !important',
                                paddingRight: '24px !important'
                            }
                        },

                        // HAVE HOVER
                        "&.table-wrap--have-hover": {
                            "& .MuiTableRow-root.MuiTableRow-hover:hover": {
                                backgroundColor: 'var(--dark-primary)', // Need in light var(--very-light-pink-secondary)
                            }
                        }
                    },

                    // GLOBAL BIG MODAL
                    '.big-modal': {
                        padding: '120px 24px 24px',
                        textAlign: 'center',
                        "@media only screen and (max-width: 767px)": {
                            padding: '88px 16px 24px',
                        },
                        "& > div:first-child": {
                            backgroundColor: 'var(--almost-black) !important', // Need in light rgba(244, 244, 244, 0.8)
                        },
                    },
                    '.big-modal__inner': {
                        textAlign: 'left',
                        position: 'static',
                        display: 'inline-block',
                        width: 'auto',
                        maxWidth: '100%',
                        padding: '0',
                        borderRadius: '8px',
                        backgroundColor: 'var(--dark-secondary)', // Need in light var(--pale-grey)
                    },
                    ".big-modal__set": {},
                    ".big-modal__control": {
                        position: 'absolute',
                        top: '24px',
                        left: '24px',
                        right: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        "@media only screen and (max-width: 767px)": {
                            top: '16px',
                            left: '16px',
                            right: '16px',
                        },
                    },
                    ".big-modal__close": {
                        width: '36px',
                        height: '36px',
                        marginLeft: 'auto',
                        borderRadius: '50%',
                        background: 'var(--dark-secondary)', // need in light var(--pale-grey)
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.06)',
                        fontSize: '0',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',

                        "& svg": {
                            width: '20px',
                            height: '20px',
                            fill: 'var(--pale-grey)', // need in light var(--charcoal-primary)
                            transition: 'transform .2s',
                        },

                        "&:hover svg": {
                            transform: 'rotate(90deg)',
                        }
                    },
                    ".big-modal__wrapper": {
                        display: 'contents',
                        justifyContent: 'center',
                        position: 'relative',
                        width: '100%',
                        maxWidth: '1020px',
                        background: 'var(--dark-secondary)', // Need in light var(--pale-grey)
                        borderRadius: '8px',
                        "@media only screen and (max-width: 1259px)": {
                            maxWidth: '100%',
                        },
                    },
                    ".big-modal__wrapper-inner": {
                        width: '100%',
                        padding: '24px',
                        flexGrow: '1'
                    },
                    ".big-modal__title": {
                        marginBottom: '12px',
                        "@media only screen and (max-width: 767px)": {
                            fontSize: '24px'
                        },
                    },
                    ".big-modal__content": {
                        position: 'relative',
                        marginTop: '40px',
                        "@media only screen and (max-width: 767px)": {
                            fontSize: '32px'
                        },
                    },
                }
            },
            // GLOBAL TOOLTIPS
            MuiTooltip:{
                tooltip:{
                    margin: '0 !important',
                    backgroundColor: "var(--dark-primary)", // Need in light
                    boxShadow: "0px 2px 4px rgba(0,0,0,0.1), inset 0px 0px 1px var(--ds-black)", // Need in light
                    border: 'none',
                    borderRadius: '4px',
                    fontFamily: `'Inter', sans-serif`,
                    fontSize: '12px',
                    fontWeight: '600',
                    padding: '8px',
                },
                arrow:{
                    color:"var(--dark-primary)", // Need in light
                }
            },
            // GLOBAL SELECT/DROPDOWN FIELD
            MuiSelect:{
                root: {
                    "&.MuiSelect-select": {
                        userSelect: "text",
                        height: '48px',
                        padding: '0 48px 0 16px',
                        background: 'var(--dark-primary)',
                        boxShadow: 'inset 0 0 0 2px var(--dark-primary)', // Need in light
                        color: 'var(--battelship-grey)', // Need in light
                        borderRadius: '12px !important',
                        border: 'none',
                        fontFamily: `'Inter', sans-serif`,
                        fontSize: '15px',
                        fontWeight: '600',
                        lineHeight: '48px',
                        "&[aria-expanded='true']": {
                            color: 'var(--pale-grey)'
                        }
                    }

                },
                icon:{
                    top: "calc(50% - 6px)",
                    width: '14px',
                    height: '14px',
                    right: '16px',
                    color: 'var(--battelship-grey)',
                    '& path': {
                        fill: 'var(--battelship-grey)'
                    }
                }
            },
            // GLOBAL SELECTOP/DROPDOWN OPTIONS FIELD
            MuiMenu: {
                paper: {
                    width: '264px',
                    marginTop: '2px',
                    padding: '8px 0',
                    borderRadius: '12px',
                    border: '1px solid transparent',
                    boxShadow: 'inset 0 0 0 2px #33383F, 0 4px 12px rgba(17, 19, 21, 0.1)',
                    background: 'var(--almost-black)',

                    // Scrollbar
                    "&::-webkit-scrollbar": {
                        width: '8px'
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: 'var(--charcoal-secondary)'
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: '8px',
                        backgroundColor: 'var(--white)'
                    },

                    // Menu list wrapper
                    '& .MuiMenu-list': {
                        padding: '0 !important',
                        margin: '0',
                        width: 'initial',
                    },

                    // Menu list item
                    '& .MuiListItem-root': {
                        alignItems: 'center',
                        width: '100%',
                        padding: '8px 16px',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '1.71429',
                        color: 'var(--battelship-grey)',
                        transition: 'all 0.2s',
                        cursor: 'pointer',
                        fontFamily: `'Inter', sans-serif`,

                        "&::after": {
                            display: 'none'
                        },
                        "&:hover": {
                            color: 'var(--pale-grey)'
                        },

                        "&.Mui-selected": {
                            color: 'var(--clear-blue-primary)'
                        }
                    },
                }
            },
            // GLOBAL BUTTON
            MuiButton:{
                text:{
                    padding: '0 24px', // in filter
                    textTransform:"none",
                },
                root:{
                    background: 'var(--clear-blue-primary)',
                    textAlign: 'center',
                    color: 'var(--white)',
                    borderRadius: '12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '48px',
                    padding: '0 20px',
                    fontFamily: 'inherit',
                    fontSize: '15px',
                    fontWeight: '700',
                    lineHeight: '1.6',
                    transition: 'all .2s',
                    display: 'inline-flex',

                    '&:hover': {
                        backgroundColor: 'var(--bright-blue)',
                        color: 'var(--white)',
                    },

                    // If have btn-fullwidth
                    '&.btn-fullwidth': {
                        width: '100%'
                    },

                    // VARIANT: STROKE
                    '&.btn-stroke': {
                        background: 'none',
                        boxShadow: '0 0 0 2px var(--dark-primary) inset', // Need in light
                        color: 'var(--pale-grey)', // need in light --dark-secondary

                        '&:hover': {
                            background: 'none',
                            boxShadow: '0 0 0 2px var(--very-light-pink-secondary) inset' // Need in light
                        },

                        '& svg': {
                            fill: 'var(--pale-grey)',
                        }
                    },
                    // VARIANT: SMALL
                    '&.btn-small': {
                        height: '40px',
                        padding: '0 16px',
                        borderRadius: '8px',
                        fontSize: '13px',
                    }
                },
                containedSecondary:{
                    color:"#fff",
                    backgroundColor: "#3D4259",
                    "&:hover":{
                        backgroundColor:"#23273B",
                        boxShadow:"none"
                    },
                    "&.Mui-disabled":{
                        backgroundColor: "#3D4259",
                        opacity:.4,
                        color:"#fff",
                    }
                },
                containedPrimary:{
                    color:"#fff",
                    background: "#24DAC4",
                    "&:hover":{
                        backgroundColor:"#1EB3A1",
                        boxShadow:"none"
                    },
                    "&.Mui-disabled":{
                        backgroundColor: "#24DAC4",
                        opacity:.4,
                        color:"#fff",
                    }
                },
                contained:{
                    boxShadow:"none"
                },
            },
            // GLOBAL INPUT: textfield or inputfield
            MuiInput:{
                root: {
                    // Input field focus
                    '&.Mui-focused input': {
                        borderColor: 'var(--charcoal-primary)',
                        background: 'var(--almost-black)',
                    },

                    // TEXTAREA
                    '&.MuiInput-multiline': {
                        "& textarea": {
                            height: 'initial',
                            padding: '16px 10px',
                        }
                    },
                },
                // Wrapper Text Field
                formControl: {
                    marginTop: '0',
                },
                // Input field.
                input: {
                    background: 'var(--dark-primary)',
                    color: 'var(--pale-grey)',
                    border: '2px solid transparent',
                    borderRadius: '12px',
                    width: '100%',
                    height: '44px',
                    padding: '0 10px',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '1.6',
                    transition: 'all .2s',
                },
                // Reset underline
                underline:{
                    '&.Mui-disabled':{
                        '&:before':{
                            borderBottomStyle:"hidden"
                        },
                    },
                    '&::after':{
                        display: 'none',
                    },
                    '&::before':{
                        display: 'none'
                    },
                    '&:hover:not(.Mui-disabled):before':{
                        borderBottom:"1px solid #23273b"
                    }
                },
            },
            // FIXING PLACEHOLDER INPUT
            MuiTextField: {
                root: {
                    "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input": {
                        "&::-webkit-input-placeholder": {
                            opacity: '1 !important',
                            color: 'var(--cool-grey)',
                        },
                        "&::-moz-placeholder": {
                            opacity: '1 !important',
                            color: 'var(--cool-grey)',
                        },
                        "&:-moz-placeholder": {
                            opacity: '1 !important',
                            color: 'var(--cool-grey)',
                        },
                        "&:-ms-input-placeholder": {
                            opacity: '1 !important',
                            color: 'var(--cool-grey)',
                        },
                    }
                }
            },
            // GLOBAL INPUT LABEL
            MuiFormLabel: {
                root: {
                    color: 'var(--very-light-pink-secondary) !important',  // Need in light
                    padding: '0',
                    position: 'relative !important',
                    fontSize: '14px',
                    transform: 'none !important',
                    fontFamily: `'Inter', sans-serif`,
                    fontWeight: '600',
                    lineHeight: '1.71429',
                    marginBottom: '14px',
                    letterSpacing: '0',
                    "& + .MuiInput-root": {
                        marginTop: '0 !important'
                    },
                }
            },
            // GLOBAL RADIO
            MuiRadio:{
                root: {
                    padding: '0',
                    "& svg": {
                        display: 'none !important'
                    },
                    "& .MuiIconButton-label": {
                        position: 'relative',
                        flexShrink: '0',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        border: '2px solid rgba(111, 118, 126, 0.4)', // Need in light
                        transition: 'all .2s',
                        display: "inline-block",
                        "&:before": {
                            content: `""`,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%) scale(0)',
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            background: 'var(--pale-grey)',
                            boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px var(--light-grey)',
                            transition: 'transform .2s',
                        }
                    },
                    "& + .MuiTypography-root.MuiFormControlLabel-label": {
                        color: 'var(--pale-grey)', // Need in light
                        fontFamily: `'Inter', sans-serif`,
                        fontSize: '15px',
                        fontWeight: '600',
                        lineHeight: '1.71429',
                        paddingTop: '0',
                        paddingLeft: '12px',
                        alignSelf: 'center',
                        position: 'relative',
                    },
                    "&:hover": {
                        "& .MuiIconButton-label": {
                            borderColor: 'var(--clear-blue-primary)'
                        }
                    },
                    "&.Mui-checked": {
                        "& .MuiIconButton-label": {
                            background: 'var(--clear-blue-primary)',
                            borderColor: 'var(--clear-blue-primary)',
                            "&:before": {
                                transform: 'translate(-50%, -50%) scale(1)',
                            }
                        },
                        "& + .MuiTypography-root.MuiFormControlLabel-label": {
                            color: 'var(--pale-grey)', // Need in light
                        },
                    },
                    "&.Mui-disabled": {
                        cursor: "not-allowed",
                        "& .MuiIconButton-label": {
                            cursor: "not-allowed",
                        },
                        "& + .MuiTypography-root.MuiFormControlLabel-label": {
                            cursor: "not-allowed"
                        }

                    }
                }
            },
            // START - DIALOG
            MuiDialog:{
                root:{
                    backdropFilter: 'blur(2px)',

                    "& .MuiPaper-root": {
                        backgroundColor: 'var(--dark-secondary)',
                        boxShadow: '0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '16px',
                        padding: '24px',
                    }
                }
            },
            MuiDialogTitle:{
                root:{
                    textAlign:"center",
                    color: "#4a4a4a",
                    fontSize:"15px",
                    padding:"50px 16px 10px 16px"
                }
            },
            MuiDialogContent:{
                root:{
                    padding:"0px 50px 10px 50px"
                }
            },
            MuiDialogActions:{
                root:{
                    paddingBottom: "50px",
                    justifyContent:"center"
                }
            },
            // END - DIALOG

            // START - TABLE
            MuiTable: {
                root: {
                    '& tbody tr:first-child > td': {
                        paddingTop: '16px'
                    },
                    '& tr td:first-child': {
                        paddingLeft: '0'
                    },
                    '& tr th:first-child': {
                        paddingLeft: '0'
                    },
                    '& tr td:last-child': {
                        paddingRight: '0'
                    },
                    '& tr th:last-child': {
                        paddingRight: '0'
                    },
                }
            },
            // Head
            MuiTableHead: {
                root: {
                    borderBottom: '1px solid var(--dark-primary)' // Need in light
                }
            },
            MuiTableCell: {
                root: {
                    borderBottom: "none",
                    padding: "10px 16px",
                },
                head: {
                    fontFamily: `'Inter', sans-serif`,
                    borderColor: "transparent",
                    backgroundColor: "transparent !important",
                    padding: '4px 12px 16px',
                    fontSize: '12px',
                    lineHeight: '1.33333',
                    fontWeight: '500',
                    color: 'var(--battelship-grey)'
                }
            },
            MuiTableBody: {
                root: {

                    "& .MuiTableCell-root": {
                        padding: '16px 12px',
                        fontFamily: `'Inter', sans-serif`,
                        fontSize: '14px !important',
                        fontWeight: '600 !important',
                        lineHeight: '1.71429 !important',
                        color: 'var(--pale-grey) !important', // Need in light - var(--dark-secondary)
                    }
                }
            },
            // END - TABLE
            // BELUM DIRAPIHKAN KEBAWAH
            // Textfield/Inputfield
            MuiInputBase:{
                root:{
                    fontSize:"15px",
                    lineHeight:"26px",
                    color:"#354052",

                    // First icon color
                    "& .MuiInputAdornment-positionStart svg": {
                        fill: "#595F61 !important"
                    },
                    // Start Position
                    "&.MuiInputBase-adornedStart": {
                        "& .MuiInputAdornment-positionStart.start_block": {
                            maxHeight: 'initial',
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            left: '2px',
                            bottom: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '46px',
                            paddingTop: '0px',
                            borderRadius: '10px 0 0 10px',
                            background: 'var(--dark-primary)', // Need in light
                            margin: 0,
                        },
                        "& .MuiTypography-root": {
                            fontFamily: `'Inter', sans-serif`,
                            fontSize: '15px',
                            fontWeight: '600',
                            lineHeight: '1.6',
                            color: 'var(--battelship-grey)', // Need in light
                        },
                        "& .MuiInputBase-input": {
                            paddingLeft: "58px"
                        }
                    },

                    // End Position
                    "& .MuiInputAdornment-positionEnd.end_block": {
                        marginLeft: '0'
                    },
                    "& .MuiInputAdornment-positionEnd.have-link": {
                        "& a": {
                            color: 'var(--cool-grey)',

                            "&:hover, &:focus": {
                                color: 'var(--clear-blue-primary)'
                            }
                        }
                    }
                }
            },
            MuiPaper: {
                root: {
                    backgroundColor: 'var(--dark-secondary)', // Need in light --pale-grey
                    borderRadius: '16px !important',
                },
                elevation1: {
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
                },
                rounded:{
                    borderRadius:"12px"
                }
            },

            MuiAppBar:{
                colorDefault:{
                    top: '0',
                    left: '340px',
                    right: '0',
                    zIndex: '20',
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: 'row wrap',
                    backgroundColor: 'var(--dark-secondary)',
                    padding: '24px 40px',
                    width: 'initial',
                    boxShadow: 'none',
                    '@media only screen and (max-width: 1338px)': {
                        left: '300px',
                        padding: '16px 24px'
                    },
                    '@media only screen and (max-width: 1259px)': {
                        left: '96px'
                    },
                    '@media only screen and (max-width: 767px)': {
                        left: '0',
                        padding: '16px 24px 16px 16px'
                    }

                }
            },

            // Global Label 1
            // MuiFormLabel:{
               // root:{
                   // marginBottom: '6px',
                    // fontSize: '14px',
                    // fontWeight: '600',
                    // lineHeight: '1.71429',
                    // color: 'var(--charcoal-primary)',
                    // '&.Mui-focused':{
                    //     color:"#8A96A0",
                    // }
                // }
            // },

            // Autocomplete
            MuiAutocomplete:{
                root: {
                    '&.MuiAutocomplete-hasClearIcon': {
                        '& .MuiInput-root': {
                            paddingRight: '0'
                        },
                        '& .MuiInput-root input': {
                            padding: '0 !important',
                            paddingLeft: '10px !important'
                        },

                        '& .MuiAutocomplete-endAdornment': {
                            right: '12px'
                        },

                        '& .MuiInput-root.MuiAutocomplete-inputRoot': {
                            paddingRight: '0'
                        }
                    }
                }
            },
            // Chip
            MuiChip:{
                root: {
                    "&.MuiChip-sizeSmall": {
                        display: "inline-block",
                        padding: '0 8px',
                        borderRadius: '6px',
                        lineHeight: '24px',
                        fontWeight: '600'
                    }
                }
            },

            MuiNativeSelect:{
            },


            // Checkbox
            MuiCheckbox:{
                root:{
                    padding: '0',
                    position: "relative",
                    width: '24px',
                    height: '24px',
                    borderRadius: '6px',
                    // border: '2px solid rgba(111, 118, 126, 0.4)',
                    boxShadow: 'inset 0 0 0 2px #272B30',
                    transition: 'all .2s',
                    display: "inline-block",

                    "& svg": {
                        display: 'none !important'
                    },
                    "& .MuiIconButton-label": {
                        backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="%23fff" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" /></svg>')`,
                        backgroundPosition: 'center center',
                        backgroundSize: '21px 21px',
                        backgroundRepeat: 'no-repeat',
                        marginLeft: '1px',
                        marginTop: '1px',
                        opacity: '0',
                        visibility: 'hidden',
                        width: '21px',
                        height: '21px',
                        display: 'block',
                        transform: 'rotate(18deg)'
                    },
                    "& + .MuiTypography-root.MuiFormControlLabel-label": {
                        color: 'var(--battelship-grey)',
                        fontFamily: `'Inter', sans-serif`,
                        fontWeight: '600',
                        lineHeight: '1.71429',
                        paddingTop: '0',
                        paddingLeft: '12px',
                        alignSelf: 'center',
                        position: 'relative',

                        "&:hover": {
                            color: 'var(--pale-grey)'
                        }
                    },
                    // Hover
                    "&:hover + .MuiTypography-root.MuiFormControlLabel-label": {
                        color: 'var(--pale-grey)', // Need in light
                    },
                    // Checked
                    "&.Mui-checked": {
                        color: 'transparent !important',
                        backgroundColor: 'var(--clear-blue-primary) !important',
                        boxShadow: 'inset 0 0 0 2px var(--clear-blue-primary)',
                        "& .MuiIconButton-label": {
                            opacity: '1',
                            visibility: 'visible'
                        },
                        "& + .MuiTypography-root.MuiFormControlLabel-label": {
                            color: 'var(--pale-grey)'
                        }
                    },
                    // Disabled
                    "&.Mui-disabled": {
                        color: 'transparent !important',
                        backgroundColor: 'var(--steel) !important', // NEED in light
                        boxShadow: 'inset 0 0 0 2px var(--steel) !important', // NEED in light
                        "& + .MuiTypography-root.MuiFormControlLabel-label": {
                            color: 'var(--battelship-grey) !important'
                        }
                    }
                },
            },
            MuiSwitch:{
                root: {
                    width: 35,
                    height: 21,
                    padding: 0,
                    margin: 5,
                },
                switchBase:{
                    padding: 2,
                    color: "#fff",
                    '&$checked':{
                        transform: 'translateX(14px)',
                        '& .MuiSwitch-thumb':{
                            // boxShadow:"none"
                        },
                        '&:not(.Mui-disabled) + $track':{
                            opacity: 1,
                            border: 'none',
                        },
                    },
                    '&$focusVisible $thumb': {
                        border: '6px solid #fff',
                    },
                    '&.Mui-checked.Mui-disabled.+.MuiSwitch-track':{
                        opacity:"0.1"
                    },
                },
                thumb:{
                    width: 17,
                    height: 17,
                    boxShadow: "0px 1px 1px #252830"
                },
                track:{
                    borderRadius: 21 / 2,
                    backgroundColor: "#8A969F",
                    opacity: 1,
                },
                checked: {},
                colorPrimary:{
                    '&.Mui-checked':{
                        color:"#fff"
                    },
                    '&.Mui-disabled .MuiSwitch-thumb':{
                        boxShadow:"none"
                    },
                    '&.Mui-disabled': {
                        color:"#fff"
                    }
                },
                colorSecondary:{
                    '&.Mui-checked':{
                        color:"#fff"
                    },
                    '&.Mui-disabled':{
                        color:"#fff"
                    },
                    '&.Mui-disabled .MuiSwitch-thumb':{
                        boxShadow:"none"
                    },
                },
            },
            MuiFormControlLabel:{
                root:{
                    marginLeft: "-6px",
                }
            },
            MuiFormControl:{
                root:{
                    marginBottom: '0',
                }
            },

            // Global Input helper
            MuiFormHelperText: {
                root: {
                    fontFamily: `'Inter', sans-serif`,
                    fontSize: '12px',
                    lineHeight: '1.33333',
                    fontWeight: 600,
                    color: 'var(--battelship-grey)',
                    letterSpacing: '0',
                    marginTop: '4px',
                    '&.Mui-error': {
                        color: 'var(--orange-pink)',
                    }
                }
            },
            MuiAvatar:{
                root:{
                    fontWeight:300
                },
                colorDefault:{
                    backgroundColor:"#EDEFF1",
                    color:"#8A96A0"
                }

            },

            // Datepicker
            MuiPickersBasePicker: {
                container: {
                    backgroundColor: 'var(--dark-secondary)', // Need in light --pale-grey
                    boxShadow: '0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '16px',
                    padding: '10px 0 24px'
                },
                // Datepicker - arrow
                pickerView: {
                    "& .MuiPickersCalendarHeader-switchHeader": {
                        margin: '0',
                        padding: '0 16px 16px',
                        "& .MuiButtonBase-root": {
                            backgroundColor: 'transparent',
                            padding: '0',
                            "& .MuiTouchRipple-root": {
                                display: 'none'
                            },
                            "& .MuiSvgIcon-root": {
                                fill: 'rgb(119, 126, 145)',
                                width: '20px',
                                height: '20px'
                            },

                            "&:hover": {
                                "& .MuiSvgIcon-root": {
                                    fill: '#2A85FF'
                                }
                            }
                        },
                        "& .MuiPickersCalendarHeader-transitionContainer .MuiTypography-root": {
                            fontSize: "18px",
                            color: 'var(--pale-grey)', // need in light --dark-secondary
                            fontFamily: `'Inter', sans-serif`,
                        },
                    },

                    // Day
                    "& .MuiPickersCalendarHeader-daysHeader": {
                        "& .MuiPickersCalendarHeader-dayLabel": {
                            fontSize: '14px',
                            color: 'var(--battelship-grey)'
                        }
                    },
                    "& .MuiPickersDay-day": {
                        color: 'var(--pale-grey)', // need in light var(--almost-black)
                        '& .MuiTypography-root': {
                            fontFamily: `'Inter', sans-serif`,
                        },
                        "&.MuiPickersDay-daySelected": {
                            background: '#272B30 !important', // need in light #FCFCFC
                            color: '#F4F4F4 !important', // need in light #1A1D1F
                        },
                        "&.MuiPickersDay-dayDisabled": {
                            color: "var(--battelship-grey) !important"
                        }
                    },

                    // Datepicker - picker year
                    "& .MuiPickersYearSelection-container": {
                        color: 'var(--pale-grey)', // need in light var(--almost-black)
                        fontFamily: `'Inter', sans-serif`,
                        fontSize: "18px !important",
                        // Scrollbar
                        "&::-webkit-scrollbar": {
                            width: '8px'
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: 'var(--charcoal-secondary)'
                        },
                        "&::-webkit-scrollbar-thumb": {
                            borderRadius: '8px',
                            backgroundColor: 'var(--white)'
                        },
                    },
                    // Datepicker - picker year selected
                    "& .MuiPickersYear-yearSelected": {
                        color: 'var(--clear-blue-primary)'
                    },
                    // Datepicker - picker year disabled
                    "& .MuiPickersYear-yearDisabled": {
                        color: 'var(--battelship-grey)'
                    }
                }
            },
            // Datepicker - Heaader
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: 'transparent',
                    height: 'initial',
                    flexFlow: 'row-reverse wrap !important',
                    alignItems: "center",
                    gap: '12px',


                    "& button": {
                        fontSize: "20px",
                        letterSpacing: '-.02em',
                        textTransform: 'capitalize',
                        fontFamily: `'Inter', sans-serif`,
                        backgroundColor: 'transparent !important',
                        padding: '0'
                    },
                    "& h4":{
                        fontSize: "20px",
                        letterSpacing: '-.02em',
                        textTransform: 'capitalize',
                    },
                    "& .MuiTouchRipple-root": {
                        display: 'none'
                    },
                }
            },


            MuiPickersToolbarText:{
                toolbarBtnSelected:{
                    color:"#fff"
                },
                toolbarTxt:{
                    color:"#fff"
                }
            },
            MuiPickersDay:{
                daySelected:{
                    color:"#fff"
                }
            },
            MuiSnackbarContent:{
                root:{
                    flexWrap:"nowrap"
                }
            },

        }
    },
)

export default themeConfigurationPaladis

import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import useMounted from "../../../../../hooks/useMounted";
// import TopComponent from "../TopComponent";
import TopComponentInvesthub from '../TopComponentInvesthub';
import VolveMaterialTable from "../../../../VolveMaterialTable";
import Pagination from "../../../../Pagination";
import {useTranslation} from "react-i18next";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {makeStyles, Portal, Typography, Box} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}))


const DepartmentsTable = (props) => {
    const {history, underBarRef, match, handleErrorMessage, settingsDarkMode} = props;

    const classes = useStyle();
    const isMounted = useMounted();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartments] = useState([]);

    const [info, setInfo] = useState({
        page: 0,
        total: 0,
        pageSize: 0
    });

    const {page, total, pageSize } = info;

    const fetchItems = async (query) => {

        try {
            setIsLoading(true);
            const response = await service.getDepartments(query);
            const {result, info} = response.data;
            setInfo(info);
            setDepartments((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        fetchItems()
    },[]);


    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
    ];

    const handleChangePage = (event, newPage) => {
        return fetchItems({page:newPage});
    }


    const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <Box className={`card`}>
                <TopComponentInvesthub history={history} settingsDarkMode={settingsDarkMode} />

                <Box className='table-wrap table-wrap--have-line table-wrap--have-px'>
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={departments}
                        onRowClick={rowHandlerClick}
                        settingsDarkMode={settingsDarkMode}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>
            </Box>
        </>
    );
};

export default compose(
    connect(null, {handleErrorMessage, setMessage})
)(DepartmentsTable);

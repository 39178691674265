import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import LimitGroupForm from "./LimitGroupForm";
import useForm from "../../../../../../../hooks/useForm";
import getCardIsDisabled from "../../../../../../../helpers/getCardIsDisabled";
import formatMoney from "../../../../../../../helpers/formatMoney";
import {subject} from "@casl/ability";

const service = new Api();


const LimitGroup = (props) => {
    const {handleErrorMessage, setMessage, card, decimal, ability, setCard, currencyOptions, currencyValue} = props;

    const [monthLimits, setMonthLimits] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const {curCode} = card;

    const fetchCardMonthLimits = async (curCode) => {
        setIsLoading(true);
        try{
            const response = await service.getServiceData({dataFor:"cardMonthLimits"});

            const limits = response.data[curCode].map(({value}) => (
                    {
                        value,
                        label: `${curCode} ${formatMoney(value, decimal)}`
                    }
                )
            );
            setMonthLimits(limits);
            setIsLoading(false);
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(curCode){
            fetchCardMonthLimits(curCode)
        }
    },[curCode]);


    useEffect(() => {
        handleChange('monthLimit', card.monthLimit);
        if(card){
            const isCardDisabled = getCardIsDisabled(card.currentStatCode);
            setIsDisabled(isCardDisabled);
        }
    },[card]);

    const submit = async () => {
        setIsLoading(true);
        try{
            const response = await service.cardSetMonthLimit(card._id, values);
            setCard((oldCard) => ({
                ...oldCard,
                ...response.data,
            }));

            setMessage("success", "success");
        }catch (e) {
            console.log(e);
            setServerErrors(e);
        }finally {
            setIsLoading(false);
        }

    }

	const validate = (values) => {
		return {};
	};

	const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
		submit,
		validate,
	);


    const canUpdateLimit = () => {
        if(card.user && card.company){
            const permissionObj = {
                user:card.user.id,
                company:card.company.id,
                currentStatCode: card.currentStatCode
            };

            return ability.can("updateLimit", subject("card", permissionObj));
        }
        return false
    }

    return (
        <LimitGroupForm
            currencyOptions={currencyOptions}
            currencyValue={currencyValue}
            isLoading={loading}
            handleChange={handleChange}
            values={values}
            errors={errors}
            monthLimits={monthLimits}
            handleSubmit={handleSubmit}
            disabled={!canUpdateLimit() || isDisabled}
            curCode={card.curCode}
        />
    );
};

export default LimitGroup;

import React from 'react'
import Expenses from "../userSection/content/Expenses";
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const ExpensesPage = (props) => {
    const {t} = useTranslation();
    const {drawerOuterRef, underBarRef, settingsDarkMode} = props;

    return (
        <UserSectionWrapper>
            <Can I="read" a={"expense"} passThrough>
                {(can) => (
                    can ? <Expenses drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode}/> : <div>{t("expense.noPermissionToRead")}</div>
                )}
            </Can>
        </UserSectionWrapper>
    )

}



export default ExpensesPage

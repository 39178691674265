import React from 'react';
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";


const InputField = (props) => {

	const noHandler = () => {
		console.log(`No handler for ${name} input field`);
	};


    const {value="", error="", placeholder, onInputChange=noHandler, label="", name="", disabled=false, isLoading, hasIcon=false, hasEndBlock=false, ...AdditionalFieldProps} = props;


    const styles = {
        inputComponent: {
            "& input": {
                paddingLeft: props.hasIcon ? "48px": "10px",
            }
            // "& .MuiInputLabel-shrink": {
            //     transform: "translate(0, -12px) scale(0.8)"
            // },
            // "& .MuiInputLabel-formControl":{
            //     fontSize:"15px",
            //     top:"-2px",
            //     whiteSpace: "nowrap",
            //     width: "100%",
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     "&.Mui-focused":{
            //         whiteSpace: "normal",
            //     }
            // },
            // marginTop: props.label ? "12px" : 0
        },
        inputHasIcon: {
            "& .MuiInputBase-root": {
                position: 'relative',

            },
            "& .MuiInputAdornment-positionStart": {
                pointerEvents: 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '48px',
                display: 'flex',
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                marginRight: '0',
                height: 'initial',
                maxHeight: 'initial'
            },
            "& .MuiInputAdornment-positionStart svg": {
                fill: 'var(--battelship-grey)',
                width: '24px',
                height: '24px'
            }
        },
        inputHasEndBlock: {
            "& .MuiInputBase-root": {
                marginBottom: '24px',
            },
            "& .MuiInputAdornment-positionStart.end_block": {
                position: 'absolute',
                width: '100%',
                alignItems: 'flex-start',
                color: 'var(--battelship-grey)',
                fontSize: '13px',
                lineHeight: '1.23077',
                fontWeight: '600',
                bottom: '-21px',
                right: 'auto',
                left: '0',
                top: 'initial',
                display: 'inline-block',
                pointerEvents: 'auto'
            },
            "& .MuiInputAdornment-positionStart.end_block a": {
                color: "var(--clear-blue-primary)"
            },
            "& .MuiInputAdornment-positionStart.end_block a:hover": {
                color: "var(--bright-blue)"
            },
        },
        helperMsg:{
            wordBreak:"break-word"
        }
    }


	const useStyles = makeStyles(styles);

	const classes = useStyles();

	// remove underline for textarea
    const useStylesInput = makeStyles({
        underline: {
            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        }
    });

    const classesInput = useStylesInput();

    const inputProps = {};
    if(props.multiline){
        inputProps.classes = classesInput
    }


    return (
        <>
            {isLoading ? (<Skeleton height={"50px"} width={"100%"}/>) : (
                    <TextField
                        label={label ? label : null}
                        id={`input-${name}`}
                        name={name}
                        disabled={disabled}
                        error={!!error}
                        value={value}
                        onChange={(e) => onInputChange(e.target.value)}
                        className={`${classes.inputComponent} ${hasIcon ? classes.inputHasIcon : ''} ${hasEndBlock ? classes.inputHasEndBlock : ''}`}
                        InputProps={inputProps}
                        InputLabelProps={props.multiline  ? {shrink: true} : null}
                        placeholder={(placeholder && !disabled) ? placeholder : ""}
                        variant="standard"
                        {...AdditionalFieldProps}
                    />
                )
            }

            {!!error &&  <FormHelperText error className={classes.helperMsg}>{error}</FormHelperText>}
        </>
    );
};

export default InputField;

import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import {useMediaQuery, useTheme, makeStyles, Typography, Box} from "@material-ui/core";
import {ReactComponent as CloseSVG} from '../../assets/closeInvesthub.svg'
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        fontFamily: `'Inter', sans-serif`,
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        paddingRight: '48px',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },

    dialogClose:{
        position: 'absolute',
        top: '24px',
        right: '24px',
        zIndex: '2',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)',
        transition: 'background .2s',

        '& svg': {
            width: '20px',
            height: '20px',
            fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--charcoal-primary)',
            transition: 'transform .25s',
        },
        '&:hover svg': {
            transform: 'rotate(90deg)'
        }
    },

    dialogSpace: {
        marginTop: '24px',
        paddingTop: '24px',
        borderTop: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)'
    },

    backdrop:{
        backgroundColor: (props) => props.isDarkModeActive ? "rgba(39, 43, 48, 0.9)" : "rgba(244, 244, 244, 0.8)",
    }
}));

const DialogPopUp = (props) => {
    const {title, children, pageTitleClass, settingsDarkMode, ...dialogProps} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <Dialog
            maxWidth={'md'}
            fullScreen={fullScreen}
            PaperProps={{elevation:0}}
            BackdropProps={{className:classes.backdrop}}
            {...dialogProps}
        >
            {title ? (
                <>
                    <Typography className={`${classes.dialogTitle} card-title ${pageTitleClass ? pageTitleClass : null}`}>{title}</Typography>
                </>
            ) : null}

            {dialogProps.onClose ? (
                <button type="button" onClick={dialogProps.onClose} className={classes.dialogClose}>
                    <CloseSVG />
                </button>
            ) : null}

            <Box className={classes.dialogSpace}>{' '}</Box>

            {children}
        </Dialog>
    );
};

export default DialogPopUp;

import React, {useEffect, useState} from 'react';
import ProjectForm from "../../../../ProjectForm";
import useMounted from "../../../../../hooks/useMounted";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useDispatch} from "react-redux";
import BackButton from "../../../../BackButton";
import Api from "../../../../../services/api";
import useForm2 from "../../../../../hooks/useForm2";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import {Can} from "../../../../../Context/Can";
import {useTranslation} from "react-i18next";
import {makeStyles, Typography, Portal, Button} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        minHeight: "40px",
        position: 'relative',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '32px',
        paddingBottom: '16px',
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            marginBottom: '24px'
        },
    },
    item: {
        position: 'relative'
    },
    btnLast: {
        marginLeft: '12px'
    }
}))


const Project = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyle();

    const {underBarRef, match, history, groups} = props;
    const {params} = match;
    const {id} = params;

    const [project, setProject] = useState({});
    const isMounted = useMounted();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);


    const fetchProject = async (id) => {
        try {
            setIsLoading(true);
            const response = await service.getProject(id);
            setProject(response.data);

        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));

        }finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchProject(id);
    },[id]);

    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.updateProject(id, values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/projects");
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const regex = getAlphaNumericRegex();

        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }

        if (values["accountingCode"] && !regex.test(values["accountingCode"].toString())) {
            errors.accountingCode = t("errors.notValid", {field:"$t(formFields.accountingCode)"});
        }

        return errors;
    };


    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );


    useEffect(() => {
        if(Object.keys(project).length){
            const {name, accountingCode, group, active} = project;
            fillFormValues({name, accountingCode, group, active});
        }
    },[project]);

    const onDeleteAction = async () => {
        try {
            setIsSubmitting(true);
            await service.deleteProject(id);
            dispatch(setMessage("success", 'success'));
            history.push("/settings/projects");

        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }


    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <ProjectForm
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        values={values}
                        groups={groups}
                        errors={errors}
                        loading={loading}
                    />

                    <Can I="update" a={"project"}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting || loading}
                        >
                            {t("formFields.update")}
                        </Button>
                    </Can>

                    <Can I="delete" a={"project"}>
                        <Button
                            onClick={onDeleteAction}
                            disabled={isSubmitting || loading}
                            className={`btn-stroke ${classes.btnLast}`}
                        >
                            {t("formFields.delete")}
                        </Button>
                    </Can>
                </div>
            </div>
        </>
    );
};

export default Project;

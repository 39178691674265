import React, {useState, useEffect} from 'react'

import {ReactComponent as IconExpense} from "../../../../../assets/expense.svg";
import {ReactComponent as IconFewExpenses} from "../../../../../assets/manyExpenses.svg";
import {ReactComponent as ExpenseCamera} from "../../../../../assets/expenseCamera.svg";
import {ReactComponent as PlusIconSVG} from "../../../../../assets/plusCircleInvesthub.svg";
import {ReactComponent as ReportSVG} from "../../../../../assets/reportsInvesthub.svg"
import {ReactComponent as ScheduledSVG} from "../../../../../assets/scheduledInvesthub.svg"
import {ReactComponent as CameraSVG} from "../../../../../assets/videoRecordedInvesthub.svg"
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PlusButton from "../../../../PlusButton";
import CameraDialog from "../CameraDialog";

import UploadImage from "../UploadImage";
import MenuOption from "../../../../MenuOption";
import {makeStyles, ClickAwayListener, Box} from "@material-ui/core";
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({
    menuAction: {
        position: 'relative',
        display: 'inline-block',
        zIndex: '11'
    },
    menuBody: {
        position: 'absolute',
        top: 'calc(100% + 12px)',
        right: '-12px',
        width: '264px',
        zIndex: '11',
        padding: '12px',
        borderRadius: '16px',
        border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
        boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
        background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
        visibility: 'hidden',
        opacity: '0',
        transform: 'translateY(3px)',
        transition: 'all .2s',
        margin: '0',
        '&:before': {
            content: `""`,
            position: 'absolute',
            right: '20px',
            bottom: '100%',
            width: '20px',
            height: '10px',
            background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")  no-repeat 50% 50%/100% auto` : `url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E) no-repeat 50% 50%/100% auto`
        },

        "& li.MuiButtonBase-root": {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '12px',
            borderRadius: '12px',
            transition: 'all .2s',
            cursor: 'pointer',
            background: 'transparent !important',
            justifyContent: 'flex-start',
            "& svg": {
                width: '24px',
                height: '24px',
                marginRight: '12px',
                fill: 'var(--battelship-grey)',
                transition: 'fill .2s',
            },
            "& .MuiTypography-root": {
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '1.6',
                color: 'var(--battelship-grey)',
                transition: 'all .2s',
                fontFamily: `'Inter', sans-serif`,
                letterSpacing: '0'
            },
            "&:hover": {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                "& svg": {
                    fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                },
                "& .MuiTypography-root": {
                    color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                }
            },
            "& .MuiTouchRipple-root": {
                display: 'none'
            },
            "& .MuiListItemText-root": {
                margin: '0'
            }
        }
    },
    menuBodyActive: {
        opacity: '1',
        transform: 'translateY(0)',
        visibility: 'visible'
    },
}));

const AddExpenseBtn = (props) => {
    const {history, settingsDarkMode} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [delayedIsActive, setDelayedIsActive] = useState(false);
    const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
    const {t} = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    useEffect(() => {
        let timeoutId;

        if (isActive) {
            timeoutId = setTimeout(() => {
                setDelayedIsActive(true);
            }, 300);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isActive]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsActive(!isActive);
    }

    const handleClose =() => {
        setAnchorEl(null);
        setIsActive(false);
    }

    const handleCloseCamera = () => {
        setIsCameraDialogOpen(false);
        handleClose();
    }

    const handleAwayClick = () => {
        handleClose();
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleAwayClick}>
                <Box className={classes.menuAction}>
                    <PlusButton
                        onClickHandler={handleClick}
                        hasDropdown
                        hasDropdownActive={isActive}
                        customClass={classes.customClass}
                        settingsDarkMode={settingsDarkMode}
                    >
                        <PlusIconSVG />
                    </PlusButton>

                    {isActive && (
                        <ul anchorEl={anchorEl} className={`${classes.menuBody} ${delayedIsActive ? classes.menuBodyActive: ''}`}>
                            <MenuOption onClick={() => history.push(`/transactions/add`)} className={classes.menuButton}>
                                <ReportSVG />
                                {' '}
                                {t("expense.addExpense")}
                            </MenuOption>
                            <UploadImage handleClose={handleClose} classExtend={classes.menuButton} settingsDarkMode={settingsDarkMode} />
                            <MenuOption onClick={() => setIsCameraDialogOpen(true)} className={classes.menuButton}>
                                <CameraSVG />
                                {t("expense.captureWithCamera")}
                            </MenuOption>
                            <MenuOption onClick={() => history.push(`/transactions/batchAdd`)} className={classes.menuButton}>
                                <ScheduledSVG />
                                {' '}
                                {t("expense.addFew")}
                            </MenuOption>
                            <MenuOption onClick={() => history.push(`/transactions/make-payment`)} className={classes.menuButton}>
                                <ReportSVG />
                                {' '}
                                {t("expense.addPaidRequest")}
                            </MenuOption>
                        </ul>
                    )}
                </Box>
            </ClickAwayListener>
            <CameraDialog open={isCameraDialogOpen} handleClose={handleCloseCamera} settingsDarkMode={settingsDarkMode} />
        </>

    )
}

export default withRouter(AddExpenseBtn)

import React, {useEffect, useState} from 'react';
import DialogPin from "./DialogPin";
import getCardIsDisabled from "../../../../../../../helpers/getCardIsDisabled";
import {useTranslation} from "react-i18next";
import {subject} from "@casl/ability";
import ButtonRequestInfo from "../ButtonRequestInfo";


const GetPinCode = (props) => {
	const {card, ability} = props;
	const {_id:cardId, user={}} = card;
	const {t} = useTranslation();
	const [isDisabled, setIsDisabled] = useState(false);

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if(card){
			const isCardDisabled = getCardIsDisabled(card.currentStatCode);
			setIsDisabled(isCardDisabled);
		}
	},[card]);


    const canGetPin = () => {
        if(card.user && card.company){
            const permissionObj = {
                user: card.user.id,
                company: card.company.id,
                type: card.type,
                currentStatCode: card.currentStatCode,
                isLive: card.isLive
            };

            return ability.can("read:pin", subject("card", permissionObj));
        }
        return false
    }

    const showButton = canGetPin();

    return showButton ? (
        <>
            {isOpen && <DialogPin isOpen={isOpen} setIsOpen={setIsOpen} cardId={cardId}/>}
            <ButtonRequestInfo
                disabled={isDisabled}
                onClick={() => setIsOpen(true)}
            >
                {t("formFields.getPin")}
            </ButtonRequestInfo>
        </>
    ) : null
};

export default GetPinCode;

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Link, withRouter} from "react-router-dom";

import {connect} from 'react-redux'
import {makeStyles, Box} from "@material-ui/core";

import {compose} from "redux";
import {useTranslation} from "react-i18next";

import queryString from "query-string";
import {subject} from "@casl/ability";
import Api from "../../../../../services/api";
import { AbilityContext } from "../../../../../Context/Can";
import checkClick from "../../../../../helpers/checkClick";
import canUseCardFunctionality from "../../../../../helpers/canUseCardFunctionality";
import CheckBoxFieldMemo from "../../../../formElements/CheckBoxFieldMemo";
import CheckBoxField from "../../../../formElements/CheckBoxField";
import UsersFilter from "../../../DrawerFilter/UsersFilter";
import DrawerFilter from "../../../DrawerFilter";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import { fetchUsers } from "../../Users/actions";
import { setMessage } from "../../../../Messages/actions";
import Pagination from "../../../../Pagination";

const service = new Api();

const useStyles = makeStyles(theme => ({

}));


const ContactsTable = (props) => {
    const {shouldRefresh, setShouldRefresh, userSectionUsers, fetchUsers, isAuthenticated, history, setMessage, match, companyCountry, location, countries, drawerOuterRef, underBarRef} = props;

    const {search} = location;
    const queryObject = queryString.parse(search);
    const [selected, setSelected] = useState([]);
    const ability = useContext(AbilityContext);
    // const {page, total, pageSize, fields} = info;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isMount, setIsMount] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [result, setResult] = useState([])
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)

    const {t} = useTranslation();

    const canUpdateContact = (userId, companyId) => {
        return ability.can("update", subject("contact", {_id: userId, company: companyId }));
    }


    const fetchContacts = async (dataQuery) => {
        setIsLoading(true);
        const contacts = await service.getContacts({...dataQuery});
        console.log('contacts',contacts)
        setResult(contacts.data.result)
        setPage(contacts.data.info.page)
        setPageSize(contacts.data.info.pageSize)
        setTotal(contacts.data.info.total)
        setIsLoading(false);
    }
    console.log('page',page)
    console.log('limit',pageSize)
    console.log('total',total)

    useEffect(() => {
        fetchContacts({page: 1, limit: 10 })
        setIsMount(true);
    },[]);

    const handleChangePage = async (event, newPage) => {
        fetchContacts({
            ...queryObject,
            page: newPage
        })
    }

    const rowHandlerClick = (e, rowData) => {
        if(['LABEL​', 'INPUT', 'A'].includes(e.target.tagName)) return;

        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`)
        }
    }

    const canUseCards = canUseCardFunctionality(companyCountry);

    const onChange = (e ,value) => {
        setSelected((items) => {

            if(value){
                const copy = [...items];
                copy.push(e.target.id);
                return copy;
            }else{
                return items.filter((item) => item !== e.target.id)
            }
        })
    }


    const memoizedOnChange = useCallback(onChange , []);

    const columns = [
        ...(ability.can("update", "contact") ? [
            {
                headerStyle: { padding:' 0 0 0 16px'},
                cellStyle: { padding:' 0 0 0 16px'},
                title: result.length ? (
                    <Box paddingLeft={"6px"}>
                        <CheckBoxField
                            value={!!(result.length && (selected.length === result.length))}
                            label={''}
                        />
                    </Box>
                ) : '',
                maxWidth: 60,
                render: (row) => {
                    const {_id, company={}} = row;
                    const canCheck = canUpdateContact(_id, company._id);

                    return (
                        <CheckBoxFieldMemo
                            onChange={memoizedOnChange}
                            checked={selected.includes(String(_id))}
                            disabled={!canCheck}
                            label={''}
                            id={String(_id)}
                            name={""}
                        />
                    )
                }
            },
        ] : [
            {
                headerStyle: { padding:' 0 0 0 16px'},
                cellStyle: { padding:' 0 0 0 16px'},
                title: '',
                maxWidth: 60,
            }
        ]),

        {
            title: t('thead.name'),
            field: 'name',
            render: (row) => (row.companyName || (row.firstName + ' ' + (row.lastName || '')))
        },
        {
            title: t('thead.contactType'),
            field: 'contactType',
            render: ((row)=>row.contactType)
        },
        {
            title: t('thead.address'),
            field: 'address',
        },
        {
            title: t('thead.country'),
            field: 'country',
            render: ((row)=>{
                return countries.find((c)=>c.value === row.country)?.label || ''
            })
        },
        {
            title: t('thead.walletAddress'),
            field: 'walletAddress',
            render: ((row)=>row.walletAddress)
        },
        {
            title: t('thead.iban'),
            field: 'iban',
            render: ((row)=>row.iban)
        },
    ];

    useEffect(()=> {
        if (shouldRefresh) {
        fetchContacts({page: 1, limit: 10 })
        }
    }, [shouldRefresh])
    return (
        <>
            <Box className={`card`}>
                {/* <TopContentInvesthub onFilterOpen={() => setIsFilterOpen(true)} isFilterOpen={isFilterOpen} onImport={onImport} selected={selected} setSelected={setSelected}/> */}
                <Box className="table-wrap table-wrap--have-line table-wrap--have-mx-offset">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={result}
                        // onRowClick={rowHandlerClick}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>
            </Box>
        </>
    )
};

const mapStateToProps = (state) => {
    const {userSectionUsers, user} = state;
    const {userData} = user;
    const {isWhiteLabel} = userData.company;

    return {
        userSectionUsers,
        isAuthenticated:state.user.isAuthenticated,
        companyCountry:state.user.userData && state.user.userData.company && state.user.userData.company.country,
        userEmail:state.user.userData.email,
        isWhiteLabel
    }
};


export default compose(
    connect(mapStateToProps, {fetchUsers, setMessage}),
    withRouter,
)(ContactsTable)

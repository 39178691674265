import React, {useEffect, useState} from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";
import {confirm} from '../../../../../../../../helpers/confirm';
import clsx from "clsx";
import CardOptionWrapper from "../../CardOptionWrapper";

const useStyles = makeStyles(theme => ({
    switchLabel:{
        margin:0,
        '& .MuiFormControlLabel-label':{
            flexGrow:1,
        },
        '& .MuiFormControlLabel-label.Mui-disabled':{
            color: "#8A96A0"
        }
    },
    switchError:{
        color:"#f44336"
    },
    formFields:{
        padding: "10px 0",
    },
    helpText:{
        width:"270px",
        maxWidth:"100%",
        color:'#354052'
    },
    helpTextDisabled:{
        color:'#8A96A0'
    },
    hiddenSwitch: {
        visibility:"hidden"
    }

}));

const ToggleAction = (props) => {
    const {label, name, error, isDisabled, onChangeHandler, value, helpText} = props;

    const classes = useStyles();

    const switchHandler = async (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        if(await confirm()){
            onChangeHandler(name, value);
        }
    }

    return (
        <CardOptionWrapper>
            <FormControl fullWidth className={classes.formFields} error={!!error}>
                <FormControlLabel
                    control={<Switch color="primary" />}
                    label={<span>{label}</span>}
                    name={name}
                    disabled={isDisabled}
                    checked={value}
                    labelPlacement="start"
                    className={classes.switchLabel}
                    onChange={switchHandler}
                />
                {!!error && <FormHelperText>{error}</FormHelperText>}
                {helpText && <FormHelperText className={clsx(classes.helpText, {[classes.helpTextDisabled]: isDisabled})}>{helpText}</FormHelperText>}
            </FormControl>
        </CardOptionWrapper>
    );
};

export default ToggleAction;

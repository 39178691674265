import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "react-loading-skeleton";
import {Box, makeStyles} from "@material-ui/core";
import {ReactComponent as SelectArrow} from "../../../assets/selectArrow.svg";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles({
    label: {
        '&.MuiInputLabel-shrink': {
            transform: "translate(0, 0) scale(1)",
            position: "static"
        },
    }
});


const SelectSearch = (props) => {
    const classes = useStyles();

    const {label, isLoading, value, inputHandler, placeholder, options=[], error} = props;

    const [val, setVal] = useState(null);

    useEffect(() => {
        const val = options.find((user) => user.value === value);
        setVal(val ? val : null);
    },[value, isLoading]);


    return (
        <>
            {isLoading ? (
                <Box><Skeleton width={"100%"} height={"23px"}/></Box>
            ) : (
                <>
                    <Autocomplete
                        value={val}
                        options={options}
                        onChange={(event, newValue) => {
                            inputHandler(newValue);
                        }}
                        popupIcon={<SelectArrow/>}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={placeholder ? placeholder : null}
                                label={label ? label : null}
                                InputLabelProps={{ shrink: true, className:classes.label }}
                            />
                        )}
                    />
                    {!!error && <FormHelperText>{error}</FormHelperText>}
                </>
            )}
        </>

    );
};

export default SelectSearch;

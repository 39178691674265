import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputField from "../../../../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";

const GetPinForm = (props) => {
   const {handleSubmit, errors, values, handleChange} = props;
    const {t} = useTranslation();
    return (
        <form onSubmit={handleSubmit} noValidate>
            <Grid container>
                <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors["cvv"]}>
                        <InputField
                            label={t("formFields.cvv")}
                            name="cvv"
                            type="cvv"
                            value={values["cvv"]}
                            onInputChange={(val) => handleChange("cvv", val)}
                            error={errors["cvv"]}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    );
};

export default GetPinForm;
import React, { useRef, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import InputField from "../../../../../formElements/InputField";
import SelectField from "../../../../../formElements/SelectField";
import ToggleCheckboxField from "../../../../../formElements/ToggleCheckboxField/ToggleCheckboxField";
import ToggleFieldWrapper from "../../../../../formElements/ToggleFieldWrapper/ToggleFieldWrapper";
import {
    InputAdornment,
    makeStyles,
    useTheme,
    useMediaQuery,
    Box,
    Button,
    FormControl,
    CircularProgress,
} from "@material-ui/core";
import { isDarkModeActive } from "../../../../../App/App";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../../../../../services/api";
import XeroSettingStatus from "./XeroSettingStatus";

const useStyles = makeStyles((theme) => ({
    item: {
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
    },
    itemMenu: {
        position: "sticky",
        top: "116px",
        display: "flex",
        flexDirection: "column",
        flexShrink: "0",
        width: "280px",
    },
    itemMenuLink: {
        backgroundColor: "transparent !important",
        padding: "8px 16px",
        color: "var(--battelship-grey)",
        justifyContent: "flex-start",
        fontWeight: "600",
        height: "initial",
        lineHeight: "1.6",
        letterSpacing: "0",
        "&:not(:last-child)": {
            marginBottom: "8px",
        },
        "& .MuiTouchRipple-root": {
            display: "none !important",
        },
        "&.active": {
            color: (props) =>
                props.isDarkModeActive
                    ? "var(--pale-grey) "
                    : "var(--dark-secondary)",
            backgroundColor: (props) =>
                props.isDarkModeActive
                    ? "var(--dark-primary) !important"
                    : "var(--very-light-pink-primary) !important",
        },
    },
    itemContent: {
        flexGrow: "1",
        paddingLeft: "32px",
        [theme.breakpoints.down("1023")]: {
            paddingLeft: "0",
        },
    },
    itemContentMenu: {
        display: "block",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "24px",
        },
        "& .MuiInput-root": {
            width: "100%",
        },
    },
    itemForm: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        [theme.breakpoints.down("1023")]: {
            display: "block",
        },
    },
    itemFormFieldSet: {
        position: "relative",
        width: "100%",
        marginBottom: "48px",
        [theme.breakpoints.down("1023")]: {
            marginBottom: "32px",
        },
    },
    itemFormFieldSetLast: {
        marginBottom: "0 !important",
    },
    itemTitle: {
        position: "relative",
        display: "inline-block",
        paddingLeft: "32px",
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "40px",
        letterSpacing: "-.02em",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            fontSize: "18px",
        },
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },

            "& $itemField": {
                flex: "0 0 calc(50% - 16px)",
                width: "calc(50% - 16px)",
                margin: "0 8px",
                [theme.breakpoints.down("1023")]: {
                    width: "100%",
                    margin: "0",
                },
            },
        },
    },

    itemField: {},

    itemRow: {
        display: "flex",
        margin: "0 -8px",
        [theme.breakpoints.down("1023")]: {
            display: "block",
            margin: "0",
        },
        "& $itemField": {
            [theme.breakpoints.up("1023")]: {
                marginBottom: "0 !important",
            },
        },
    },
    itemBox: {
        width: "100%",
        height: "48px",
        padding: "0 10px",
        borderRadius: "12px",
        border: (props) =>
            props.isDarkModeActive
                ? "2px solid var(--dark-primary)"
                : "2px solid transparent",
        background: (props) =>
            props.isDarkModeActive
                ? "var(--dark-primary)"
                : "var(--very-light-pink-primary)",
        transition: "all .2s",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
    },
    itemFormSubmit: {
        marginTop: "48px",
        [theme.breakpoints.down("1023")]: {
            marginTop: "32px",
        },
    },
    itemFormFieldSetAnchor: {
        position: "absolute",
        top: "-116px",
        left: "0",
        right: "0",
    },
}));

const service = new Api();

const XeroSettingsForm = (props) => {
    const {
        handleChange,
        handleSubmit,
        settingsDarkMode,
        xeroSettings,
        values,
        isDisabled,
        isShowConnect,
        isConnected
    } = props;
    const history = useHistory();

    const code = new URLSearchParams(history.location.search).get("code");
    const { t } = useTranslation();
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });
    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    // NEED TRANSLATION
    const formSections = [
        { id: "connectXero", label: t("Connect Xero") },
        { id: "xeroSettings", label: t("Xero Set Up") },
    ];
    const [activeSection, setActiveSection] = useState("expenseInformation");
    const [selectedMenu, setSelectedMenu] = useState(formSections[0].id);
    const connectXeroRef = useRef(null);
    const xeroSetupRef = useRef(null);
    const refs = {
        connectXero: connectXeroRef,
        xeroSetup: xeroSetupRef,
    };

    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await service.syncCompanyTransaction();
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }

    };

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: "smooth" });
            setActiveSection(selectedSection);
        }
    };

    const onClickConnect = () => {
        const redirectUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${
            xeroSettings?.clientId || ""
        }&redirect_uri=${
            xeroSettings?.redirectUrl || ""
        }&scope=openid profile email assets files accounting.contacts accounting.settings accounting.transactions accounting.attachments offline_access`;
        window.location.href = redirectUrl;
    };

    useEffect(() => {
        if (code && code?.length && xeroSettings) {
            const updateXeroSettings = async () => {
                await service.updateCompanyXeroSettings({
                    ...xeroSettings,
                    code: code,
                });
            };
            updateXeroSettings();
        }
    }, [code, xeroSettings]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (
                        rect.top <= scrollPosition &&
                        rect.bottom > scrollPosition
                    ) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [refs, formSections]);

    const ConnectComponent = useMemo(() => {
        if (isShowConnect && !isConnected) {
            return (
                <ToggleFieldWrapper settings={settingsDarkMode}>
                    <Button onClick={onClickConnect}>Connect to Xero</Button>
                </ToggleFieldWrapper>
            );
        } else if (!isShowConnect && isConnected) {
            return (
                <>
                    <ToggleFieldWrapper settings={settingsDarkMode}>
                        <XeroSettingStatus status="connected" />
                    </ToggleFieldWrapper>

                    {loading ? (
                        <div
                            className="text-align-center"
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <Button onClick={handleClick}>Sync Existing Data To Xero</Button>
                    )}
                </>
            );
        } else
            return (
                <ToggleFieldWrapper settings={settingsDarkMode}>
                    <XeroSettingStatus status="notConnected" />{" "}
                </ToggleFieldWrapper>
            );
    }, [isShowConnect, isConnected, loading]);

    return (
        <div className={classes.item}>
            {!onMax1023 && (
                <div className={classes.itemMenu}>
                    {formSections.map((section) => (
                        <Button
                            key={section.id}
                            className={`${classes.itemMenuLink} ${
                                activeSection === section.id ? "active" : ""
                            }`}
                            onClick={() => {
                                scrollToSection(section.id);
                                setActiveSection(section.id);
                                setSelectedMenu(section.id);
                            }}
                        >
                            {t(section.label)}
                        </Button>
                    ))}
                </div>
            )}

            <div className={classes.itemContent}>
                {onMax1023 && (
                    <Box className={classes.itemContentMenu}>
                        <SelectField
                            hideEmpty
                            options={formSections.map((section) => ({
                                value: section.id,
                                label: section.label,
                            }))}
                            onSelectChange={(selectedSection) => {
                                scrollToSection(selectedSection);
                                setSelectedMenu(selectedSection);
                            }}
                            value={selectedMenu}
                        />
                    </Box>
                )}

                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.itemForm}
                >
                    <div className={classes.itemForm}>
                        <div className={classes.itemFormFieldSet}>
                            <div
                                className={classes.itemFormFieldSetAnchor}
                                ref={connectXeroRef}
                            >
                                {" "}
                            </div>
                            <div
                                className={`${classes.itemTitle} card-title card-title--green`}
                            >
                                {t("Connect Xero")}
                            </div>
                            {ConnectComponent}
                        </div>

                        <div className={classes.itemFormFieldSet}>
                            <div
                                className={classes.itemFormFieldSetAnchor}
                                ref={xeroSetupRef}
                            >
                                {" "}
                            </div>
                            <div
                                className={`${classes.itemTitle} card-title card-title--purple`}
                            >
                                {/* NEED TRANSLATION */}
                                {t("Xero Setup")}
                            </div>

                            <div className={classes.itemSettings}>
                                <ToggleFieldWrapper settings={settingsDarkMode}>
                                    <FormControl
                                        fullWidth
                                        className={classes.itemField}
                                    >
                                        <InputField
                                            className={classes.currencyValue}
                                            name="clientId"
                                            type="text"
                                            label="Client Id"
                                            value={values["clientId"]}
                                            onInputChange={(val) =>
                                                handleChange("clientId", val)
                                            }
                                        />
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        className={classes.itemField}
                                    >
                                        <InputField
                                            className={classes.currencyValue}
                                            name="secretId"
                                            type="text"
                                            label="Client Secret"
                                            value={values["secretId"]}
                                            onInputChange={(val) =>
                                                handleChange("secretId", val)
                                            }
                                        />
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        className={classes.itemField}
                                    >
                                        <InputField
                                            className={classes.currencyValue}
                                            name="redirectUrl"
                                            type="text"
                                            label="Redirect Uri"
                                            value={values["redirectUrl"]}
                                            onInputChange={(val) =>
                                                handleChange("redirectUrl", val)
                                            }
                                        />
                                        Please make sure that this redirect uri
                                        is the same as redirect uri at your xero
                                        config
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        className={classes.itemField}
                                    >
                                        <InputField
                                            className={classes.currencyValue}
                                            name="tenantId"
                                            type="text"
                                            label="Xero Tenant Id"
                                            value={values["tenantId"]}
                                            onInputChange={(val) =>
                                                handleChange("tenantId", val)
                                            }
                                        />
                                    </FormControl>
                                </ToggleFieldWrapper>
                            </div>
                        </div>

                        <Button
                            type="submit"
                            disabled={isDisabled}
                            onClick={handleSubmit}
                            className={classes.itemFormSubmit}
                        >
                            {t("formFields.save")}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default XeroSettingsForm;

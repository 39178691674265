import React from 'react';
import {useTranslation} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";

const AddressCell = (props) => {
    const {t} = useTranslation();

    const {values, hasError} = props;
    return (
        <div>
            {values.filter((value) => value).join(", ")}
            {hasError && (
                <FormHelperText error>
                    {t("errors.required")}
                </FormHelperText>
            )}
        </div>
    );
};

export default AddressCell;

import React, {useEffect, useState} from 'react'
import Settings from '../userSection/content/Settings'
import {useTranslation} from "react-i18next";
import {
    Portal,
    Typography,
    makeStyles
} from "@material-ui/core";
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles(theme => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    }
}));


const UserPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef, settings} = props;
    const [isMount, setIsMount] = useState(false);
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    useEffect(() => {
        setIsMount(true);
    },[]);

    return (
        <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>{t("settingsTitle")}</Typography>
                </Portal>
            )}
            <Settings settings={settings} />
        </>
    )

}



export default UserPage

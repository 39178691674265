import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import useMounted from "../../../../../hooks/useMounted";
import TopComponentInvesthub from '../TopComponentInvesthub';
import {connect} from "react-redux";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import Pagination from "../../../../Pagination";
import {useTranslation} from "react-i18next";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {Portal, makeStyles, Typography, Box} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}))


const ProjectsTable = (props) => {
    const {handleErrorMessage, setMessage, history, match, underBarRef, settingsDarkMode} = props;

    const {t} = useTranslation();
    const classes = useStyle();
    const isMounted = useMounted();
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [info, setInfo] = useState({
        page: 0,
        total: 0,
        pageSize: 0
    });
    const {page, total, pageSize} = info;

    const fetchItems = async (query) => {
        try {
            setIsLoading(true);
            const response = await service.getProjects(query);
            const {result, info} = response.data;
            setInfo({
                ...info,
            });
            setProjects((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });

        }catch (e) {
            console.log(e);
            handleErrorMessage(e);

        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchItems();
    },[]);


    const handleChangePage = (event, newPage) => {
        return fetchItems({page:newPage});
    }


    const rowHandlerClick = async (e, rowData) => {
        if(['LABEL​', 'INPUT'].includes(e.target.tagName)) return;

        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }


    const onImport = async (formData) => {
        const response = await service.importProject(formData);
        const {data} = response;
        const {modified, doNotModified} = data;
        const message = t("project.importInfo", {modified:modified, all:modified+doNotModified});
        setMessage(message, "success");
        await fetchItems();
    }
    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
        {
            title: t('thead.accountingCode'),
            field: 'accountingCode',
        },
    ]

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <Box className={`card`}>
                <TopComponentInvesthub onImport={onImport} history={history} settingsDarkMode={settingsDarkMode} />
                <Box className="table-wrap table-wrap--have-line table-wrap--have-px">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={projects}
                        onRowClick={rowHandlerClick}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>
            </Box>
        </>
    );
};


export default compose(
    connect(null, {handleErrorMessage, setMessage})
)(ProjectsTable);

import React from 'react';
import Button from "@material-ui/core/Button";
import Fieldset from "../../../../../../formElements/Fieldset";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";

const DownloadSuccessful = (props) => {
    const {hasForward, setCurrentAction, onFinish, title, description, isSending} = props;
    const {t} = useTranslation();

    const onForward = () => {
        setCurrentAction("downloaded-forward")
    }

    return (
        <div>
            <Fieldset title={title ? title : ""}>
                <Grid container spacing={5}>
                    {description && (
                        <Grid item xs={12}>
                            <Typography variant="body2">{description}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box component={"span"} mr={2}>
                            <Button
                                onClick={onFinish}
                                variant="contained"
                                disabled={isSending}
                                color="primary"
                            >
                                {t("formFields.finishKyb")}
                            </Button>
                        </Box>
                            {hasForward && (
                                <Button
                                    onClick={onForward}
                                    variant="contained"
                                    disabled={isSending}
                                    color="secondary"
                                >
                                    {t("formFields.forwardForSignature")}
                                </Button>
                            )}
                    </Grid>
                </Grid>
            </Fieldset>

        </div>
    );
};

export default DownloadSuccessful;

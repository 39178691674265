import React, {useEffect, useState} from 'react';
import Portal from "@material-ui/core/Portal";
import useMounted from "../../../../../hooks/useMounted";
import TopComponentInvesthub from "../TopComponentInvesthub"
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../Messages/actions"
import Api from "../../../../../services/api";
import Pagination from "../../../../Pagination";
import checkClick from "../../../../../helpers/checkClick";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles, Typography} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },

    tableReportTemplate: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}))

const ExpenseReportTemplatesTable = (props) => {
    const {underBarRef, history, match, settingsDarkMode} = props;
    const isMounted = useMounted();
    const classes = useStyle();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [expenseReportTemplates, setExpenseReportTemplates] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState({
        fields:["name"],
        page: 0,
        total: 0,
        pageSize: 0
    });

    const {page, total, pageSize, fields} = info;

    const fetchItems = async (query) => {

        try {
            setIsLoading(true);
            const response = await service.getExpenseReportTemplates(query);
            const {result, info} = response.data;
            setInfo({
                ...info,
                fields:info.fields
            });
            setExpenseReportTemplates((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e))
        }finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        fetchItems()
    },[]);

    const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }

    const handleChangePage = (event, newPage) => {
        return fetchItems({page:newPage});
    }

    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
    ];

    return (
        <div>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("report.mainTitle")}
                    </Typography>
                </Portal>
            )}
            <div className={`card ${classes.tableReportTemplate}`}>
                <TopComponentInvesthub history={history} settingsDarkMode={settingsDarkMode} />
                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={expenseReportTemplates}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </div>
    );
};

export default ExpenseReportTemplatesTable;

import * as actionTypes from '../../../../store/actionTypes'

const initialState = {
    dashboard: {tabs: [], active: null},
    expenses: {tabs: [], active: null},
    transactions: {tabs: [], active: null},
    settings: {tabs: [], active: null},
    users: {tabs: [], active: null},
    accounts: {tabs: [], active: null},
    cards: {tabs: [], active: null},
    reports: {tabs: [], active: null},
};

const tabs = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case actionTypes.TAB_SET:
            return {
                ...state,
                ...payload,
            }
        case actionTypes.TAB_CHANGE:

            const {page, tab} = payload;

            return {
                ...state,
                [page]: {
                    ...state[page],
                    active: tab
                }
            }
    }

    return state
}

export default tabs

import React from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
    makeStyles,
    FormHelperText,
    FormLabel
} from "@material-ui/core";
import {ReactComponent as CalendarIcon} from "../../../assets/calendarIcon.svg";
import Skeleton from "react-loading-skeleton";
import { isDarkModeActive } from '../../App/App';

const DateField = (props) => {
    const noHandler = () => {
        console.log(`No handler for ${name} input field`);
    };


    const {
        value=null,
        error="",
        onDateChange=noHandler,
        label="",
        name="",
        disabled=false,
        dateFormat="",
        isLoading,
        settingsDarkMode,
        ...AdditionalFieldProps} = props;

    const useStyles = makeStyles({
        root: {
            "& .MuiInputBase-adornedEnd": {
                "& .MuiInputAdornment-positionEnd": {
                    maxHeight: 'initial',
                    height: '100%',
                    position: 'absolute',
                    top: '0px',
                    right: '2px',
                    bottom: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '46px',
                    paddingTop: '0px',
                    borderRadius: '0 10px 10px 0',
                    background: 'var(--dark-primary)', // Need in light
                    margin: 0,
                },
                "& .MuiButtonBase-root.MuiIconButton-root": {
                    padding: '0',
                    borderRadius: 'initial',
                    height: 'initial'
                },
                "& .MuiInputBase-input": {
                    paddingRight: '58px'
                },
                "& svg": {
                    fill: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--dark-secondary)',
                },
                "&.Mui-disabled": {
                    "& .MuiInputAdornment-positionEnd": {
                        display: 'none !important;'
                    }
                },

                "&.Mui-focused": {
                    "& .MuiInputAdornment-positionEnd": {
                        background: (props) => props.isDarkModeActive ? 'var(--charcoal-primary)' : '', // Need in light
                    }
                }
            },
        }
    });

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <>
            {label && <FormLabel component="label" htmlFor={`datepicker-${name}`}>{label}</FormLabel>}
            {isLoading ? <Skeleton width={"100%"} height={"40px"}/> : (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format={dateFormat}
                        id={name}
                        keyboardIcon={disabled ? '' : <CalendarIcon/>}
                        error={!!error}
                        value={value}
                        onChange={(val) => onDateChange(val)}
                        name={name}
                        disabled={disabled}
                        KeyboardButtonProps={{
                            'aria-label': `change ${name}`,
                        }}
                        className={`${classes.root} ${disabled ? classes.rootDisabled : ''}`}
                        {...AdditionalFieldProps}
                    />
                </MuiPickersUtilsProvider>
            )}
            {!!error &&  <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default DateField;

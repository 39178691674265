import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import PlusButton from "../../../../../PlusButton"
// import ActionMenu from "../../../../../ActionMenu";
import {ReactComponent as PlusIconSVG} from "../../../../../../assets/plusCircleInvesthub.svg";
import {ReactComponent as EditSVG} from "../../../../../../assets/editInvesthub.svg"
import {makeStyles, Box, ClickAwayListener, Button} from "@material-ui/core";
import { isDarkModeActive } from '../../../../../App/App';

const useStyles = makeStyles(theme => ({
    menuAction: {
        position: 'relative',
        display: 'inline-block',
        zIndex: '888'
    },
    menuBody: {
        position: 'absolute',
        top: 'calc(100% + 12px)',
        right: '-12px',
        width: '264px',
        zIndex: '2',
        padding: '12px',
        borderRadius: '16px',
        border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
        boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
        background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
        visibility: 'hidden',
        opacity: '0',
        transform: 'translateY(3px)',
        transition: 'all .2s',
        margin: '0',
        '&:before': {
            content: `""`,
            position: 'absolute',
            right: '20px',
            bottom: '100%',
            width: '20px',
            height: '10px',
            background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")  no-repeat 50% 50%/100% auto` : `url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E) no-repeat 50% 50%/100% auto`
        }
    },
    menuBodyActive: {
        opacity: '1',
        transform: 'translateY(0)',
        visibility: 'visible'
    },
    menuButton: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '12px',
        borderRadius: '12px',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',
        cursor: 'pointer',
        background: 'transparent !important',
        justifyContent: 'flex-start',
        "& svg": {
            width: '24px',
            height: '24px',
            marginRight: '12px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
        },
        "&:hover": {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            "& svg": {
                fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            }
        },
        "& .MuiTouchRipple-root": {
            display: 'none'
        }
    }
}));

const AddDepartmentBtn = (props) => {
    const {t} = useTranslation();
    const {history, settingsDarkMode} = props;
    const [
        // eslint-disable-next-line no-unused-vars
        anchorEl,
        setAnchorEl
    ] = useState(null);
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const [isActive, setIsActive] = useState(false);
    const [delayedIsActive, setDelayedIsActive] = useState(false);

    useEffect(() => {
        let timeoutId;

        if (isActive) {
            timeoutId = setTimeout(() => {
                setDelayedIsActive(true);
            }, 300);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isActive]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsActive(!isActive);
    }

    const handleClose =() => {
        setAnchorEl(null);
        setIsActive(false);
    }
    const handleAwayClick = () => {
        handleClose();
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleAwayClick}>
                <Box className={classes.menuAction}>
                    <PlusButton
                        onClickHandler={handleClick}
                        hasDropdown
                        hasDropdownActive={isActive}
                        customClass={classes.customClass}
                        settingsDarkMode={settingsDarkMode}
                    >
                        <PlusIconSVG />
                    </PlusButton>

                    {isActive && (
                        <div className={`${classes.menuBody} ${delayedIsActive ? classes.menuBodyActive : ''}`}>
                            <Button className={classes.menuButton} onClick={() => history.push("/settings/departments/add")}>
                                <EditSVG />
                                {t("department.addManually")}
                            </Button>
                        </div>
                    )}
                </Box>
            </ClickAwayListener>
        </>
    )
}

export default AddDepartmentBtn


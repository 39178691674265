import React, { useContext, useEffect, useState } from "react";
import useMounted from "../../../../../hooks/useMounted";
import { useTranslation } from "react-i18next";
import { AbilityContext, Can } from "../../../../../Context/Can";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../../services/api";
import { Prompt } from "react-router-dom";
import { makeStyles, Portal, Typography } from "@material-ui/core";
import { isDarkModeActive } from "../../../../App/App";
import XeroSettingsForm from "./XeroSettingsForm";
import { handleErrorMessage, setMessage } from "../../../../Messages/actions";
import useForm2 from "../../../../../hooks/useForm2";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: "24px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "16px",
        },
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: "40px",
        position: "relative",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "24px",
        },
        [theme.breakpoints.down("767")]: {
            flexWrap: "wrap",
            paddingBottom: "24px",
            marginBottom: "24px",
        },
    },
    headTitle: {
        position: "relative",
        display: "inline-block",
        paddingLeft: "32px",
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "1.6",
        letterSpacing: "-.02em",
        [theme.breakpoints.down("767")]: {
            fontSize: "18px",
        },
    },
}));

const Xero = (props) => {
    const { t } = useTranslation();
    const { underBarRef, settingsDarkMode } = props;
    const [isShowConnect, setIsShowConnect] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });
    const isMounted = useMounted();
    const ability = useContext(AbilityContext);
    const [isLoading, setIsLoading] = useState();
    const dispatch = useDispatch();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();

    const submit = async () => {
        try {
            setIsLoading(true);
            const formValues = values;
            const response = await service.updateCompanyXeroSettings(values);
            const { advancedSettings } = response.data;
            // dispatch(updateAdvancedSettings(advancedSettings));
            setValuesWasChanged(false);
            dispatch(setMessage(t("success"), "success"));
        } catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeWithDetectChanges = (name, value) => {

        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true);
        }
        handleChange(name, value)
    }

    const xeroSettings = useSelector((state) => {
        const { userData } = state.user;
        const { company = {} } = userData;
        const { xero = {} } = company;
        return xero;
    });

    useEffect(() => {
        if (xeroSettings) {
            const { clientId, secretId, redirectUrl, tenantId, code } = xeroSettings;
            fillFormValues({
                clientId,
                secretId,
                redirectUrl,
                tenantId
            });
            if (code) {
                setIsConnected(true)
            } else if (clientId && secretId && redirectUrl && tenantId) {
                setIsConnected(false)
                setIsShowConnect(true)
            } else {
                setIsConnected(false);
                setIsShowConnect(false)
            }
        }
    }, []);

    const validate = (values) => {
        const errors = {};
        if (!values[`clientId`]) {
            errors[`clientId`] = t("errors.required", {
                field: "$t(formFields.clientId)",
            });
        }
        if (!values[`secretId`]) {
            errors[`secretId`] = t("errors.required", {
                field: "$t(formFields.secretId)",
            });
        }
        if (!values[`redirectUrl`]) {
            errors[`redirectUrl`] = t("errors.required", {
                field: "$t(formFields.redirectUrl)",
            });
        }
        if (!values[`tenantId`]) {
            errors[`tenantId`] = t("errors.required", {
                field: "$t(formFields.tenantId)",
            });
        }

        return errors;
    };

    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        fillFormValues,
        setServerErrors,
    } = useForm2(submit, validate);

    return (
        <>
            {/* <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            /> */}
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography
                        variant="h1"
                        className={`${classes.pageTitle} h3`}
                    >
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <div
                        className={`${classes.headTitle} card-title card-title--blue`}
                    >
                        {t("xeroSettings.mainTitle")}
                    </div>
                </div>
                <Can I={"update"} a={"company"}>
                    <XeroSettingsForm
                        handleChange={handleChangeWithDetectChanges}
                        handleSubmit={handleSubmit}
                        xeroSettings={xeroSettings}
                        values={values}
                        isShowConnect={isShowConnect}
                        isConnected={isConnected}
                        // errors={errors}
                        // checkDisabledField={checkDisabledField}
                        // isShowThreshold={isShowThreshold}
                        // setIsShowThreshold={setIsShowThreshold}
                        // settingsDarkMode={settingsDarkMode}
                        isDisabled={isLoading}
                    />
                </Can>
            </div>
        </>
    );
};

export default Xero;

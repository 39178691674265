import React, {useEffect} from 'react';
import DialogPopUp from "../../DialogPopUp";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import {FormControl, makeStyles} from "@material-ui/core";
import SelectField from "../../formElements/SelectField";
import Button from "@material-ui/core/Button";
import useForm2 from "../../../hooks/useForm2";
import InputField from "../../formElements/InputField";
import Grid from "@material-ui/core/Grid";
import FileUploaderInline from "../../formElements/FileUploaderInline";

const useStyles = makeStyles(theme => ({
            content: {
                width: "880px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px"
            },
        }
    )
);

const BankAccountPopup = (props) => {
    const {t} = useTranslation();
    const {isOpen, setIsOpen, outerValues, index, memoizedCallback, currencies, onSave, isEditMode} = props;
    const classes = useStyles();

    const submit = async () => {
        onSave(index);

        const {bankName, accountName, accountNo, currency, proofOfAccount} = values;
        // change parent state
        memoizedCallback(`fundingBankAccounts[${index}][bankName]`, bankName);
        memoizedCallback(`fundingBankAccounts[${index}][accountName]`, accountName);
        memoizedCallback(`fundingBankAccounts[${index}][accountNo]`, accountNo);
        memoizedCallback(`fundingBankAccounts[${index}][currency]`, currency);
        memoizedCallback(`fundingBankAccounts[${index}][proofOfAccount]`, proofOfAccount);

    }

    const validate = (values) => {

        const errors = {};
        if (!values[`bankName`]) {
            errors[`bankName`] = t("errors.required", {field:"$t(formFields.bankName)"});
        }
        if (!values[`accountName`]) {
            errors[`accountName`] = t("errors.required", {field:"$t(formFields.accountName)"});
        }
        if (!values[`accountNo`]) {
            errors[`accountNo`] = t("errors.required", {field:"$t(formFields.accountNo)"});
        }
        if (!values[`currency`]) {
            errors[`currency`] = t("errors.required", {field:"$t(formFields.currency)"});
        }
        if (!values[`proofOfAccount`]) {
            errors[`proofOfAccount`] = t("errors.required", {field:"$t(formFields.proofOfAccount)"});
        }

        return errors;
    }



    const { handleChange, handleSubmit, values, errors, fillFormValues, setServerErrors } = useForm2(
        submit,
        validate
    );


    const onHandleChange = (name, value) => {
        handleChange([name], value)
    }



    useEffect(() => {
        // fill local values
        fillFormValues(outerValues)
    },[]);



    return (
        <DialogPopUp
            open={isOpen}
            onClose={() => setIsOpen(false)}
            title={t("formFields.saveBankAccount")}
        >
            <DialogContent className={classes.content}>
                <Grid container spacing={7}>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`bankName`]} fullWidth>
                            <InputField
                                label={t("formFields.bankName")}
                                placeholder={t("formFields.bankName")}
                                name={`bankName`}
                                value={values[`bankName`]}
                                onInputChange={(val) => onHandleChange("bankName", val)}
                                error={errors[`bankName`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`accountName`]} fullWidth>
                            <InputField
                                label={t("formFields.accountName")}
                                placeholder={t("formFields.accountName")}
                                name={`accountName`}
                                value={values[`accountName`]}
                                onInputChange={(val) => onHandleChange("accountName", val)}
                                error={errors[`accountName`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`accountNo`]} fullWidth>
                            <InputField
                                label={t("formFields.accountNo")}
                                placeholder={t("formFields.accountNo")}
                                name={`accountNo`}
                                value={values[`accountNo`]}
                                onInputChange={(val) => onHandleChange("accountNo", val)}
                                error={errors[`accountNo`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`currency`]} fullWidth>
                            <SelectField
                                label={t("formFields.currency")}
                                name={`currency`}
                                value={values[`currency`]}
                                error={errors[`currency`]}
                                options={currencies}
                                onSelectChange={(val) => onHandleChange(`currency`, val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FileUploaderInline
                            value={values[`proofOfAccount`]}
                            fieldName={"proofOfAccount"}
                            error={errors[`proofOfAccount`]}
                            handleChange={onHandleChange}
                            label={<>{t("formFields.proofOfAccount")}{":"} {t("formFields.proofOfAccountSuffix")}</>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    disabled={Object.keys(validate(values)).length > 0}
                    variant="contained"
                    color="primary"
                >
                    {t("formFields.save")}
                </Button>
            </DialogActions>
        </DialogPopUp>
    );
};

export default BankAccountPopup;

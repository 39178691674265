import * as propsTypes from '../../../../../../../store/actionTypes'
import Api from "../../../../../../../services/api";
import {handleErrorMessage} from "../../../../../../Messages/actions";
const service = new Api();

const fetchCards = (accountId, dataQuery={}) => async dispatch => {

	try {

		const result = await service.getAccountCards(accountId, dataQuery);
		const {data} = result;
		dispatch({type:propsTypes.ACCOUNT_CARDS_SUCCESS, payload:data});
	}catch (e) {
		dispatch(handleErrorMessage(e));
		dispatch({type:propsTypes.ACCOUNT_CARDS_FAILURE});
	}
}

export {
	fetchCards
};

import * as actionTypes from '../../../../store/actionTypes'

const initialState = {
    branches: {
        result: [],
        info:{
            fields:["id", "name", "currency"],
            page: 0,
            total: 0,
            pageSize: 0
        }
    },

}

const userBranches = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.BRANCHES_FETCH_SUCCESS :
            return {
                ...state,
                branches:{
                    result: action.payload.info.page > 1 ? [...state.branches.result, ...action.payload.result] : action.payload.result,
                    info:{
                        ...action.payload.info,
                    }
                },
            }
        case actionTypes.BRANCHES_FETCH_FAILURE :
            return {
                ...state,
                branches:{
                    result:[],
                    info:initialState.branches.info
                }
            }
        case actionTypes.USER_LOGOUT:
            return initialState;

        default :
            return state
    }
    return state

}

export default userBranches

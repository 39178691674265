import React, {useEffect, useState} from 'react';
import { Box, makeStyles, CircularProgress } from '@material-ui/core'
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import formatMoney from '../../../../../helpers/formatMoney';
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({

}))

const ExpenseChart = (props) => {
    const {
        expenses,
        // eslint-disable-next-line no-unused-vars
        currency,
        dateLte,
        decimal,
        isLoading,
        settingsDarkMode
    } = props;

    const {
        // eslint-disable-next-line no-unused-vars
        t
    } = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const momentDate = moment(dateLte);

    const [amounts, setAmounts] = useState([]);
    const [days, setDays] = useState([]);
    const [max, setMax] = useState(300);

    const [series, setSeries] = useState([{
            name: "",
            data: amounts,
        }]);

    useEffect(() => {
        setSeries([{
            name: "",
            data: amounts,
        }]);

    },[amounts]);

    useEffect(() => {

        const daysInMonth = momentDate.daysInMonth();

        const days = Array.from({length: daysInMonth}, (v, i) => i+1);

        setDays(days);

        const amounts = days.map((day) => {


            const foundItem = expenses.find((expense) => {
                const {_id} = expense; // _id is day number
                return day === _id;
            });

            return foundItem ? foundItem.totalAmountExchanged : 0;
        });

        const max = Math.max(...amounts);
        setMax(Math.ceil(max));

        setAmounts(amounts);
    }, [props]);

    function getColor({ value, seriesIndex, dataPointIndex, w }) {
        const day = dataPointIndex+1;
        const weekDay = moment(dateLte).set("date",day).weekday();
        return weekDay === 1 ? "#2A85FF" : "#0069F6";
    }

    return (
        <>
            {isLoading ? (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress />
                </Box>
            ) : (
                <Chart
                    options={{
                        tooltip: {
                            x: {formatter: (value) => value},
                            y: {formatter: (value) => formatMoney(value, decimal)},
                            marker: {show: false},
                            style:{
                                fontSize: '14px',
                                fontFamily: `'Inter', sans-serif`,
                                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                                backgroundColor: 'red'
                            },
                        },
                        colors:[getColor],
                        plotOptions: {
                            bar: {
                                columnWidth: 34,
                                barHeight: "100%",
                                backgroundBarRadius: 10,
                                stroke: {
                                    show: true,
                                    width: 1, // Atur lebar border sesuai kebutuhan
                                },
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        chart: {
                            redrawOnParentResize: true,
                            foreColor: "#6F767E",
                            toolbar: {show: false},
                            width: "100%", // Atur lebar chart sesuai kebutuhan
                        },
                        grid: {
                            borderColor: "#33383F",
                        },
                        xaxis: {
                            axisBorder: {show: false},
                            axisTicks: {show: false},
                            labels: {
                                style:{
                                    fontSize: "14px",
                                    fontFamily: `'Inter', sans-serif`,
                                    color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
                                },
                            },
                            categories: days
                        },
                        yaxis: {
                            opposite: false,
                            tickAmount: 3,
                            min: 0,
                            max: max,
                            labels: {
                                offsetX: 0,
                                style:{
                                    fontSize: "14px",
                                    fontFamily: `'Inter', sans-serif`,
                                    color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
                                },
                                formatter: (value) => (value === max) ? Math.ceil(value) :  Math.round(value),
                            },
                        },
                    }}
                    series={series}
                    type="bar"
                    width="100%"
                    height="290px"
                />
            )}
        </>
    )
}

export default ExpenseChart;


import React from "react"
import {useTranslation} from "react-i18next";
import {makeStyles, Box} from "@material-ui/core"
import {Can} from "../../../../../Context/Can";
import AddTaxRateBtnInvesthub from './AddTaxRateBtn'

const useStyles = makeStyles(theme => {
    return {
        head: {
            display: "flex",
            alignItems: "center",
            minHeight: '40px',
            position: 'relative',
            marginBottom: '32px',
            [theme.breakpoints.down('767')]: {
                marginBottom: '24px'
            },
            [theme.breakpoints.down('767')]: {
                // flexWrap: 'wrap',
                paddingBottom: '24px',
                marginBottom: '24px'
            },
        },
        headTitle: {
            position: 'relative',
            display: 'inline-block',
            paddingLeft: '32px',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '1.6',
            letterSpacing: '-.02em',
            [theme.breakpoints.down('767')]: {
                fontSize: '18px'
            }
        },
        headControl: {
            display: 'flex',
            marginLeft: 'auto',
        },
    }
})

const TopComponentInvesthub = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {history, onImport, settingsDarkMode} = props;

    return (
        <Box className={classes.head}>
            <Box className={`${classes.headTitle} card-title card-title--blue`}>
                {t("taxRate.mainTitle")}
            </Box>

            <Box className={classes.headControl}>
                <Can I="create" a={"taxRate"}>
                    <AddTaxRateBtnInvesthub
                        history={history}
                        onImport={onImport}
                        settingsDarkMode={settingsDarkMode}
                    />
                </Can>
            </Box>
        </Box>
    )
}

export default TopComponentInvesthub

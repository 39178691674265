import React from 'react'
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import {useTranslation} from "react-i18next";
import Reports from "../userSection/content/Reports";

const ReportsPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef, settingsDarkMode} = props;

    return (
        <UserSectionWrapper>
            <Can I="read" a={"report"} passThrough>
                {(can) => (
                    can ? <Reports underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} /> : <div>{t("report.noPermissionToRead")}</div>
                )}
            </Can>
        </UserSectionWrapper>
    )
}



export default ReportsPage

import React from 'react'

import Roles from "../userSection/content/Roles";

import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const RolesPage = (props) => {

    const {t} = useTranslation();
    return (
            <UserSectionWrapper>
                <Can I="read" a="user" passThrough>
                    {(can) => (
                        can ? <Roles {...props}/> : <div>{t("user.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
    )

}



export default RolesPage

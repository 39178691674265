import React, {useEffect} from 'react';
import useForm2 from "../../../../hooks/useForm2";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import RoleSelectField from "./RoleSelectField";
import StatusSelectField from "./UserStatusSelectField";
import KycStatusSelectField from "./KycStatusSelectField";
import FilterFormButton from "../FilterFormButton";
import FilterFormControls from "../FilterFormControls";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { isDarkModeActive } from '../../../App/App';

const useStyles = makeStyles((theme) => ({
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        },
        "& .MuiFormControl-root": {
            "& .MuiFormControl-root": {
                marginBottom: '0'
            }
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },

    itemFormSubmit: {
        marginTop: '48px',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: "100%",
        gap: '12px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
}));

const UsersFilter = (props) => {
    const {t} = useTranslation();
    const {history, isWhiteLabel, location, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {search} = location;
    const query = queryString.parse(search);

    const localFields = ['kyc', 'status', 'roles'];

    const onClearHandler = () => {
        fillFormValues({}); // reset form completely
        const resetQuery = {};

        history.push({
            search: `?${queryString.stringify(resetQuery)}`,
        });

    }

    useEffect(() => {
        // fill data from url

        const dataToFIll = localFields.reduce((obj, key) => {
            if(query[key]){
                obj[key] = query[key]
            }
            return obj
        },{});

        fillFormValues(dataToFIll);

        return () => {
            fillFormValues({});
        }
    },[search]);

    const validate = () => ({});

    const submit = () => {
        const oldQuery = queryString.parse(location.search);
        const newQuery = {...values};

        if(oldQuery["searchValue"]){
            newQuery.searchValue = oldQuery["searchValue"];
        }

        history.push({
            search: `?${queryString.stringify(newQuery)}`,
        });

    }

    const { handleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );


    return (
        <Box component={'form'} onSubmit={handleSubmit} className={classes.itemForm}>
            <FilterFormControls className={classes.itemFormFieldSet}>
                <Box className={classes.itemSettings}>
                    <FormControl
                        fullWidth
                        className={classes.itemField}
                    >
                        <RoleSelectField
                            label={t("formFields.role")}
                            name={"roles"}
                            isWhiteLabel={isWhiteLabel}
                            value={values["roles"]}
                            onSelectChange={(val) => handleChange("roles", val)}
                            settingsDarkMode={settingsDarkMode}
                        />
                    </FormControl>

                    <FormControl
                        fullWidth
                        className={classes.itemField}
                    >
                        <StatusSelectField
                            label={t("formFields.status")}
                            name={"status"}
                            value={values["status"]}
                            onSelectChange={(val) => handleChange("status", val)}
                            settingsDarkMode={settingsDarkMode}
                        />
                    </FormControl>

                    <FormControl
                        fullWidth
                        className={classes.itemField}
                    >
                        <KycStatusSelectField
                            label={t("formFields.kycStatus")}
                            name={"kyc"}
                            value={values["kyc"]}
                            onSelectChange={(val) => handleChange("kyc", val)}
                        />
                    </FormControl>
                </Box>
            </FilterFormControls>
            <Box className={classes.itemFormSubmit}>
                <FilterFormButton
                    onClick={() => onClearHandler()}
                    addtionalClass={`btn-stroke`}
                >
                    {t("formFields.reset")}
                </FilterFormButton>
                <FilterFormButton
                    type="submit"
                >
                    {t("formFields.submit")}
                </FilterFormButton>
            </Box>
        </Box>
    );
};

export default withRouter(UsersFilter);

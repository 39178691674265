import React, {useState} from 'react';
import ActionButton from "../../../../../ActionButton";
import {useTranslation} from "react-i18next";
import ActionMenu from "../../../../../ActionMenu/ActionMenu";
import MenuOption from "../../../../../MenuOption";
import {useHistory} from "react-router-dom";
import {handleErrorMessage} from "../../../../../Messages/actions";
import {useDispatch} from "react-redux";

const ActionBtn = (props) => {
    const {selectedCategories, onDisableBatch} = props;
    const {t} = useTranslation();

    const history = useHistory();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onBatchEdt = () => {
        history.push({pathname: `/settings/categories/batch-edit`, state: {ids: selectedCategories}})
    }

    const onDisable = async () => {
        try{
            setIsSubmitting(true);
            await onDisableBatch();
            handleClose();
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsSubmitting(false);
        }
    }

    return selectedCategories.length ? (
        <>
            <ActionButton
                disabled={isSubmitting}
                onClick={handleClick}
            >
                {t("category.actions")}
            </ActionButton>

            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuOption disabled={isSubmitting} onClick={onBatchEdt}>{t("category.batchEdit")}</MenuOption>
                <MenuOption disabled={isSubmitting} onClick={onDisable}>{t("category.disable")}</MenuOption>
            </ActionMenu>
        </>
    ) : null;
};

export default ActionBtn;

import React, {useEffect, useState} from 'react';
import {compose} from "redux";

import {connect} from "react-redux";
import HistoryCard from "./HistoryCard";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../services/api";
import {handleErrorMessage} from "../../../../../Messages/actions";
import Skeleton from "@material-ui/lab/Skeleton";
const service = new Api();

const History = (props) => {
    const {id,  dateFormat, handleErrorMessage, settingsDarkMode} = props;
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const fetchData = async () => {
       try{
           setLoading(true);
           const response = await service.getExpenseHistory(id);
           const {result} = response.data;
           setHistory(result);
           setLoading(false);
       }catch (e) {
           console.log(e);
           handleErrorMessage(e);
           setLoading(false);
       }
    }


    useEffect(() => {
        fetchData();
    },[]);

    return (
        <>
            {loading ? (
                <>
                    <Skeleton height={"60px"} width={"100%"}/>
                    <Skeleton height={"60px"} width={"100%"}/>
                    <Skeleton height={"60px"} width={"100%"}/>
                </>
            ) : (
                history.length ? history.map((historyItem) => {
                    return <HistoryCard item={historyItem} key={historyItem._id} dateFormat={dateFormat} settingsDarkMode={settingsDarkMode} />
                }) : t("expense.noHistory")
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const {user} = state;
    const {regional={}} = user.settings;
    const {dateFormat} = regional;
    return {
        dateFormat
    }
}
export default compose(
    connect(
        mapStateToProps,
        {handleErrorMessage}
    )
)(History);

import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import { ReactComponent as CloseSVG } from "../../../../../assets/closeInvesthub.svg";
import { isDarkModeActive } from "../../../../App/App";

const useStyles = makeStyles((theme) => ({
    itemForm: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        [theme.breakpoints.down("1023")]: {
            display: "block",
        },
    },
    itemFormFieldSet: {
        position: "relative",
        width: "100%",
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },

            "& $itemField": {
                flex: "0 0 calc(50% - 16px)",
                width: "calc(50% - 16px)",
                margin: "0 8px",
                [theme.breakpoints.down("1023")]: {
                    width: "100%",
                    margin: "0",
                },
            },
        },
    },

    itemField: {},

    itemRow: {
        display: "flex",
        margin: "0 -8px",
        [theme.breakpoints.down("1023")]: {
            display: "block",
            margin: "0",
        },
        "& $itemField": {
            [theme.breakpoints.up("1023")]: {
                marginBottom: "0 !important",
            },
        },
    },
}));

const SwapTokenPopup = (props) => {
    const { isOpenCreateModal, setIsOpenCreateModal, settingsDarkMode } = props;
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });

    const { t } = useTranslation();
    const handleClose = () => {
        setIsOpenCreateModal(false);
    };

    return (
        <>
            <Modal
                open={isOpenCreateModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="big-modal"
            >
                <Box className={`${classes.modalInner} big-modal__inner`}>
                    <Box className={"big-modal__set"}>
                        <Box className={"big-modal__control"}>
                            <Box
                                type="button"
                                onClick={handleClose}
                                className="big-modal__close"
                            >
                                <CloseSVG />
                            </Box>
                        </Box>

                        <Box className={`big-modal__wrapper`}>
                            <Box className={`big-modal__wrapper-inner`}>
                                <Typography
                                    component="h2"
                                    className={`h4 big-modal__title`}
                                >
                                    Swap Or Buy Token
                                </Typography>

                                <Box className={`big-modal__content`}>
                                    <iframe
                                        src="https://app.uniswap.org/#/swap?exactField=input&exactAmount=10&inputCurrency=0x6b175474e89094c44da98b954eedeac495271d0f"
                                        height="660px"
                                        width="400px"
                                        style={{
                                            border: 0,
                                            margin: "0 auto",
                                            display: "block",
                                            // borderRadius: "10px",
                                            maxWidth: "600px",
                                            minWidth: "300px",
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default withRouter(SwapTokenPopup);

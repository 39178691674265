import React, { useState, useEffect } from "react";
import axios from "axios";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
    CircularProgress,
    MenuItem,
    makeStyles,
    Grid,
    Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogPopUp from "../../../../DialogPopUp";
import DialogContent from "@material-ui/core/DialogContent";

import { COUNTRY_BY_ALPHA_2_CODE } from "../../../../../constants/country";
import Api from "../../../../../services/api";

import metamaskService from "../../../../../services/metamask";
import InputField from "../../../../formElements/InputField";
// import Icon from '../Icon'
import RadioFields from "../../../../formElements/RadioFields";
import FormControl from "@material-ui/core/FormControl";

import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { setMessage } from "../../../../Messages/actions";
import { TRANSACTION_STATUS } from "../../../../../constants/transactions";
import SelectField from "../../../../formElements/SelectField";
import DialogCreateFiatAccount from "../../Balances/DialogCreateFiatAccount";

const volveApi = new Api();

const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        padding: "24px 0",
    },
    tableCardWrapper: {
        display: "flex",
        "flex-flow": "row wrap",
        "align-items": "flex-start",
        margin: "0 -24px -32px",
    },
    tableCard: {
        "margin-bottom": "32px",
        "flex-grow": 0,
        "flex-shrink": 0,
        "flex-basis": "calc(50% - 24px)",
        width: "calc(50% - 24px)",
        "margin-left": "12px",
        "margin-right": "12px",
        border: "1px solid #EFEFEF",
        cursor: "pointer",
        transition: "border 250ms ease",
        "border-radius": "4px",
        display: "table",
    },
    row: {
        display: "table-row",
    },
    column: {
        background: "rgba(39, 43, 48, 0.3)",
        width: "120px",
        display: "table-cell",
        padding: "10px 16px",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.71429",
        "& :first-child": {
            "padding-left": "24px",
            "white-space": "nowrap",
            color: "#6F767E",
        },
    },
    label: {
        display: "none",
    },
}));

const ConfirmApprovedTransactionModal = ({
    accounts,
    setCurrentAccount,
    currentAccount,
    isOpenModal = false,
    setIsOpenModal,
    setMessage,
    openModalAddAccount, 
    setOpenModalAddAccount,
    isDisableFiat
}) => {
    const { t } = useTranslation();
    const [values, setValues] = useState({
        amount: "",
        bankName: '',
        iban: '',
        name: ''
    });
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { expenseId, amount, fiatId, currency } = queryString.parse(
        history.location.search
    );
    const [convertedAmount, setConvertedAmount] = useState(0);

    const getReceiverInfo = async () => {
        const { data } = await volveApi.getFiatPayeeById(fiatId);
        const { data: exchangeData } = await volveApi.getExchangeRate(currency, "EUR", amount);
            if (exchangeData?.result) {
                setConvertedAmount(exchangeData?.result)
            }
        setValues({
            amount: exchangeData?.result?.toFixed(2) || 0,
            memo: `Pay expense ${expenseId}`,
            bankName: data?.data?.bankDetails?.bankName,
            iban: data?.data?.bankDetails?.iban,
            name: data?.data?.name,
        });
    }

    useEffect(() => {
        const getAppData = async () => {
            await getReceiverInfo()
        };
        getAppData();
    }, []);

    const placeOrder = async () => {
        try {
            setLoading(true);
            let paymentScheme = "SCT";
            // switch (type) {
            //     case "internal":
            //         paymentScheme = "EAGLE_NET_TRANSFER"; // fiat internal transfer
            //         break;
            //     case "external":
            //         paymentScheme = "SCT";
            //         break;
            //     default:
            //         paymentScheme = "SCT";
            // }
            const data = {
                expenseId,
                amount: `${values?.amount}`,
                fromId: currentAccount?.id,
                reference: values.memo,
                toId: fiatId,
                paymentScheme,
                metadata: {
                    from: currentAccount?.name,
                    to: values?.name,
                }
            };
            const response = await volveApi.createFiatPayment(data);

            setMessage(t("Success"), "success");
            setLoading(false);
            setIsOpenModal(false);
            history.push('/transactions')
        } catch (error) {
            console.log(error.response.data);

            if (error.response && error.response.data) {
                setMessage(error.response.data.msg, "error");
            }
            setLoading(false);
        }
    };

    return (
        <DialogPopUp
            open={isOpenModal}
            onClose={() => {
                setIsOpenModal(false);
            }}
            aria-labelledby="form-dialog-title"
            title={t("Send money")}
        >
            <DialogContent>
                <div className="popup js-popup animation visible popup__kyc-medium">
                    <div
                        onClick={() => setIsOpenModal(false)}
                        className="popup__overlay js-popup-overlay"
                    >
                        {" "}
                    </div>

                    <div className="popup__wrap js-popup-wrap">
                        <div className="description">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <div className="w-full">
                                        <div className="field__label">
                                            <SelectField
                                                label={t(
                                                    "Current Fiat Republic Account"
                                                )}
                                                value={currentAccount}
                                                options={accounts}
                                                onSelectChange={(val) => {
                                                    setCurrentAccount(val);
                                                }}
                                                isLoading={loading}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="w-full">
                                        <div className="field__label">
                                            {currentAccount?.currency?.toUpperCase()}
                                            {": "}
                                            {currentAccount?.amount}
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("Amount")}
                                            value={values?.amount}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("Currency")}
                                            value="EUR"
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("Reference / Memo")}
                                            value={values?.memo}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("Name")}
                                            value={values?.name}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("IBAN")}
                                            value={values?.iban}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("Bank Name")}
                                            value={values?.bankName}                                            
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="field">
                                        <button
                                            className="btn button-stroke"
                                            onClick={placeOrder}
                                            disabled={loading}
                                        >
                                            {t("Confirm")}
                                        </button>
                                    </div>
                                </Grid>

                                {loading && (
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}
                            </Grid>
                        </div>

                        <button
                            className="popup__close js-popup-close"
                            onClick={() => setIsOpenModal(false)}
                        >
                            {/* <Icon
                className={classNames('close', 'icon-24')}
                name="close"
                size="24"
              /> */}
                        </button>
                    </div>
                </div>
            </DialogContent>
            {openModalAddAccount && (
                    <DialogCreateFiatAccount
                        setOpen={setOpenModalAddAccount}
                        title={t("Create Fiat Account")}
                        type="company"
                        isDisableFiat={isDisableFiat}
                    />
                )}
        </DialogPopUp>
    );
};

const FiatAccount = ({ accounts, setMessage, openModalAddAccount, setOpenModalAddAccount, isDisableFiat }) => {
    const { t } = useTranslation();
    const [transactionHistory, setTransactionHistory] = useState(null);
    const [loading, setLoading] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const styles = useStyles();
    const history = useHistory();
    const [currentAccount, setCurrentAccount] = useState();
    const { expenseId, fiatId, amount, currency } = queryString.parse(
        history.location.search
    );

    useEffect(() => {
        if (expenseId && fiatId && amount && setIsOpenModal) {
            setIsOpenModal(true);
        }

        // eslint-disable-next-line
    }, [expenseId, fiatId, amount, currency, setIsOpenModal]);

    return (
        <>
            <ConfirmApprovedTransactionModal
                accounts={accounts}
                setCurrentAccount={setCurrentAccount}
                currentAccount={currentAccount}
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                setMessage={setMessage}
                openModalAddAccount={openModalAddAccount}
                setOpenModalAddAccount={setOpenModalAddAccount}
                isDisableFiat={isDisableFiat}
            />

            <div className="page__title page__title-grid h3">
                {currentAccount?.currency?.toUpperCase()}
                {": "}
                {currentAccount?.amount}

                <div className="page__title-helper">
                    <button
                        type="button"
                        className="btn button button-xs"
                        onClick={() => setCurrentAccount(null)}
                    >
                        {/* <Icon
              className={classNames('arrow-left')}
              name='arrow-left'
              size='24'
            />{' '} */}
                        {t("Back")}
                    </button>
                </div>
            </div>

            {loading && (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <CircularProgress />{" "}
                </Box>
            )}
        </>
    );
};

export default compose(connect(null, { setMessage }))(FiatAccount);

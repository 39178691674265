import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Api from "../../../../../services/api";
import { handleErrorMessage, setMessage } from "../../../../Messages/actions";
import { useTranslation } from "react-i18next";
import { Can } from "../../../../../Context/Can";
import queryString from "query-string";
import BatchReject from "./BatchReject";
import MenuOption from "../../../../MenuOption";
import ActionButton from "../../../../ActionButton";
import ActionMenu from "../../../../ActionMenu/ActionMenu";
import { IN_OUT_TRANSACTIONS } from "../../../../../constants/transactions";
const service = new Api();

const ActionBtn = (props) => {
    const {
        history,
        selected,
        handleErrorMessage,
        setMessage,
        fetchExpenses,
        location,
        tabs,
        userId,
        setSelected,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const buildTabsQuery = (roleTab) => {
        let expenseTab = "all";

        const tabs = ["todo", "to-submit", "to-pay", "executed-transactions", "all"];
        tabs.forEach((e) => {
            if (props.location.pathname.includes(e)) {
                expenseTab = e;
            }
        });

        switch (roleTab) {
            case "administrator":
                if (expenseTab === "to-submit") {
                    return { status: ["toSubmit", "rejected"] };
                } else if (expenseTab === "todo") {
                    return { status: "submitted" };
                } else if (expenseTab === "to-pay") {
                    return { status: "approved" };
                } else if (IN_OUT_TRANSACTIONS.includes(expenseTab)) {
                    return { type: "executed-transactions" };
                } else return {};
            case "auditor":
                if (expenseTab === "to-submit") {
                    return { status: ["toSubmit", "rejected"] };
                } else if (expenseTab === "todo") {
                    return { status: "submitted" };
                } else if (expenseTab === "to-pay") {
                    return { status: "approved" };
                } else if (IN_OUT_TRANSACTIONS.includes(expenseTab)) {
                    return { type: "executed-transactions" };
                } else return {};
            case "approver":
                if (expenseTab === "to-submit") {
                    return { approver: userId, status: ["toSubmit", "rejected"] };
                } else if (expenseTab === "todo") {
                    return { approver: userId, status: "submitted" };
                } else if (expenseTab === "to-pay") {
                    return { status: "approved" };
                } else if (IN_OUT_TRANSACTIONS.includes(expenseTab)) {
                    return { type: "executed-transactions" };
                } else {
                    return { approver: userId };
                }
            default:
                if (expenseTab === "to-submit") {
                    return { user: userId, status: ["toSubmit", "rejected"] };
                } else if (expenseTab === "todo") {
                    return { user: userId, status: "submitted" };
                } else if (expenseTab === "to-pay") {
                    return { status: "approved", user: userId };
                } else if (IN_OUT_TRANSACTIONS.includes(expenseTab)) {
                    return { type: "executed-transactions", user: userId };
                } else return { user: userId };
        }
    };

    const updateExpenseList = async () => {
        const tabQuery = tabs ? buildTabsQuery(tabs.active) : {};
        const { search } = location;
        const queryObject = queryString.parse(search);

        await fetchExpenses({
            ...queryObject,
            ...tabQuery,
        });
    };

    const handleClickItem = async (id) => {
        handleClose();
        if (id === "edit") {
            history.push({
                pathname: `/transactions/batchEdit`,
                state: { selected },
            });
        } else {
            await onHandleAction(id);
        }
    };

    const onHandleAction = async (action) => {
        setIsSubmitting(true);

        let serviceAction = null;

        const dataToSend = { expenseIds: selected };

        switch (action) {
            case "delete":
                serviceAction = service.deleteBatchExpense;
                break;
            case "submit":
                serviceAction = service.submitBatchExpense;
                break;
            case "recall":
            case "approve":
            case "unApprove":
                dataToSend.action = action;
                serviceAction = service.actionBatchExpense;
                break;
            default:
                handleErrorMessage({ message: `expense.noAction` });
                setIsSubmitting(false);
                return;
        }

        try {
            const response = await serviceAction(dataToSend);
            const { data } = response;
            const { modified, doNotModified } = data;
            if (modified > 0) {
                const message = t("expense.updatedExpenses", {
                    modified: modified,
                    all: modified + doNotModified,
                });
                setMessage(message, "success");
                setSelected([]);
                await updateExpenseList();
            } else {
                handleErrorMessage({ message: `expense.cantUpdateAny` });
            }
            setIsSubmitting(false);
        } catch (e) {
            setIsSubmitting(false);
            handleErrorMessage(e);
        }
    };

    return (
        <>
            {selected.length > 0 && (
                <ActionButton disabled={isSubmitting} onClick={handleClick}>
                    {t("expense.actions")}
                </ActionButton>
            )}
            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuOption isTitle>{t("expense.availableActions")}</MenuOption>
                <Can I="approve" a={"expense"}>
                    <MenuOption onClick={() => handleClickItem("approve")}>
                        {t("formFields.approve")}
                    </MenuOption>
                </Can>
                <Can I="reject" a={"expense"}>
                    <BatchReject
                        selectedExpenses={selected}
                        closeMenu={() => handleClose()}
                        setSelected={setSelected}
                    />
                </Can>
                <Can I="unApprove" a="expense">
                    <MenuOption onClick={() => handleClickItem("unApprove")}>
                        {t("formFields.unApprove")}
                    </MenuOption>
                </Can>
                <Can I="update" a="expense">
                    <MenuOption onClick={() => handleClickItem("edit")}>
                        {t("formFields.edit")}
                    </MenuOption>
                </Can>
                <Can I="recall" a="expense">
                    <MenuOption onClick={() => handleClickItem("recall")}>
                        {t("formFields.recall")}
                    </MenuOption>
                </Can>
                <Can I="delete" a="expense">
                    <MenuOption
                        component={"li"}
                        onClick={() => handleClickItem("delete")}
                    >
                        {t("formFields.delete")}
                    </MenuOption>
                </Can>
                <Can I="update" a="expense">
                    <MenuOption onClick={() => handleClickItem("submit")}>
                        {t("formFields.submit")}
                    </MenuOption>
                </Can>
            </ActionMenu>
        </>
    );
};

const mapStateToProps = ({ user, tabs }) => {
    return {
        userId: user.userData._id,
        tabs: tabs["transactions"],
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, { handleErrorMessage, setMessage })
)(ActionBtn);

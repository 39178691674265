import React from 'react';
import {makeStyles, FormControl} from "@material-ui/core";
import InputFieldMemo from "../../formElements/InputFieldMemo";
import {useTranslation} from "react-i18next";
import { isDarkModeActive } from '../../App/App';

const useStyles = makeStyles(theme => ({
    textAreaWrapper:{
        '& .MuiInput-formControl':{
            borderRadius: "7px",
            padding: 0,
        },
        '& textarea':{
            borderRadius: "7px",
            lineHeight: "22px",
            fontSize:"15px",
            minHeight:"100px"
        },
        '& textarea:not([disabled])':{
            padding:"10px 16px",
            transition: 'all .2s',

            "&:hover":{
                borderColor:"#23273b"
            },
            "&:focus":{
                borderColor:"#23273b"
            }
        },
        '& .Mui-focused textarea:not([disabled])': {
            background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--very-light-pink-primary)',
            borderColor: (props) => props.isDarkModeActive ? 'rgba(154, 159, 165, 0.15) solid 2px' : 'var(--very-light-pink-primary) solid 2px'
        }
    },

}));

const CommentField = (props) => {
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(props.settingsDarkMode) });
    const {t} = useTranslation();

    return (
        <FormControl fullWidth error={!!props.error} className={classes.textAreaWrapper}>
            <InputFieldMemo
                {...props}
                multiline
                name={"comment"}
                rows="4"
                label={t("formFields.comment")}
            />
        </FormControl>
    );
};

export default CommentField;

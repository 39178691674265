import React from 'react'
import {makeStyles} from "@material-ui/core";
import NewAccountInvesthub from '../NewAccountInvesthub';

const useStyles = makeStyles((theme) => ({
    headNav: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '8px',
        marginLeft: 'auto',
    }
}))

const TopComponentInvesthub = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.headNav}>
            <NewAccountInvesthub />
        </div>
    )
}

export default TopComponentInvesthub

import React, {useState} from 'react';
import FinishingContent from "./FinishingContent";
import DownloadSuccessful from "./DownloadSuccessful";
import ForwardSuccessful from "./ForwardSuccessful";
import {useTranslation} from "react-i18next";
import ForwardForSignature from "./ForwardForSignature";
import { Trans } from 'react-i18next'
import {withRouter} from "react-router-dom";



const FinishingStep = (props) => {
    const {handleErrorMessage} = props;
    const {t} = useTranslation();

    const [currentAction, setCurrentAction] = useState(null);

    const [forwardEmail, setForwardEmail] = useState("");


    const onFinish = async () => {
        props.history.push(`/dashboard`);
    }

    return (
      <>
          {currentAction ? (
              <>

                  {currentAction === "downloaded" && (
                      <DownloadSuccessful
                          handleErrorMessage={handleErrorMessage}
                          setCurrentAction={setCurrentAction}
                          onFinish={onFinish}
                          title={t("kybFieldsetDownloadSuccessfulStep")}
                          description={t("kybFieldsetDownloadSuccessfulStepTextV1")}
                          hasForward
                      />
                  )}
                  {currentAction === "downloaded-forward" && (
                      <ForwardForSignature
                          handleErrorMessage={handleErrorMessage}
                          setForwardEmail={setForwardEmail}
                          setCurrentAction={setCurrentAction}
                          onFinish={onFinish}
                          title={t("kybFieldsetSignFormStep")}
                          description={t("paladisKybFieldsetSignFormStepText")}
                          alreadyDownloaded
                      />
                  )}
                  {currentAction === "downloaded-forward-done" && (
                      <ForwardSuccessful
                          handleErrorMessage={handleErrorMessage}
                          title={t("kybFieldsetDocumentSendStep")}
                          description={<Trans i18nKey="kybFieldsetDocumentSendTextStep">sendText<strong>{{email:forwardEmail}}</strong></Trans>}
                          setCurrentAction={setCurrentAction}
                          onFinish={onFinish}
                      />
                  )}
                  {currentAction === "forwarded" && (
                      <ForwardSuccessful
                          handleErrorMessage={handleErrorMessage}
                          title={t("kybFieldsetDocumentSendStep")}
                          description={<><Trans i18nKey="kybFieldsetDocumentSendTextStep">sendText<strong>{{email:forwardEmail}}</strong></Trans>{". "}<Trans i18nKey="kybFieldsetDocumentSendTextStepCanDownload"/></>}
                          setCurrentAction={setCurrentAction}
                          onFinish={onFinish}
                          hasDownload
                      />
                  )}
                  {currentAction === "forwarded-download" && (
                      <DownloadSuccessful
                          handleErrorMessage={handleErrorMessage}
                          setActiveComponent={setCurrentAction}
                          title={t("kybFieldsetDownloadSuccessfulStep")}
                          description={t("kybFieldsetDownloadSuccessfulStepTextV2")}
                          onFinish={onFinish}
                      />
                  )}
              </>
          ) : (
              <FinishingContent
                  handleErrorMessage={handleErrorMessage}
                  setCurrentAction={setCurrentAction}
                  setForwardEmail={setForwardEmail}
              />
          )}
      </>

    );
};

export default withRouter(FinishingStep)

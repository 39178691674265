import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {handleErrorMessage} from '../../../../Messages/actions'
import Api from "../../../../../services/api";
import {connect} from "react-redux";
import ActionCircle from '../../../../ActionCircleInvesthub/ActionCircleInvesthub';
import { ReactComponent as TrashSVG } from "../../../../../assets/trash.svg"
import { isDarkModeActive } from '../../../../App/App';

const service = new Api();

const useStyles = makeStyles(theme => ({
    top: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 -12px 12px 0',
        [theme.breakpoints.down('767')]: {
            marginRight: '-8px'
        }
    },
    title: {
        fontWeight: 600,
        letterSpacing: '-.02em',
        marginRight: 'auto',
        fontSize: '20px',
        lineHeight: '1.6',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',

        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        },
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '12px',
        borderRadius: '12px',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',
        cursor: 'pointer',
        textTransform: 'capitalize',
        border: 'none',
        '& svg': {
            width: '24px',
            height: '24px',
            marginRight: '12px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
            position: 'relative',
            top: '-2px'
        },
        '&:hover': {
            background: (props) => props.isDarkModeActive ? 'transparent' : 'var(--very-light-pink-secondary)',
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
        },
        '&:hover svg': {
            fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
        }
    }
}))

const Head = (props) => {
    const {settings} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const {t} = useTranslation();

    const {counter, setNotifications, setIsDisabled, handleErrorMessage} = props;

    const onCleanAll = async () => {
        setIsDisabled(true);
        try {
            await service.deleteNotificationAll();
            setNotifications([]);
            setIsDisabled(false);
        }catch (e) {
            handleErrorMessage(e);
            setIsDisabled(false);
        }
    }

    const [showAction, setShowAction] = useState({
        isShowActionActive: false
    })

    const handleShowActionClick = () => {
        setShowAction({ ...showAction, isShowActionActive: !showAction.isShowActionActive })
    }

    const handleShowActionClickAway = () => {
        setShowAction({ ...showAction, isShowActionActive: false })
    }

    return (
        <div className={classes.top}>
            <div className={classes.title}>
                {t('notifications')}
                {' '}
                {counter ? `(${counter})` : ""}
            </div>

            {counter > 0 && (
                <>
                    <ActionCircle
                        small={true}
                        onClick={handleShowActionClick}
                        onClickAway={handleShowActionClickAway}
                        isActive={showAction.isShowActionActive}
                        settings={settings}
                    >
                        <button
                            type="button"
                            className={classes.item}
                            onClick={() => onCleanAll()}
                        >
                            <TrashSVG />
                            {t("formFields.clearAll")}
                        </button>
                    </ActionCircle>
                </>
            )}
        </div>
    )
}

export default connect(
    null, {handleErrorMessage}
)(Head);


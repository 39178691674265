import React from 'react';
import {
    FormControl,
    FormControlLabel ,
    Checkbox,
    makeStyles,
    Box
} from '@material-ui/core';
import { isDarkModeActive } from '../../App/App';

const ToggleCheckboxField = (props) => {
    const noHandler = (fieldName) => {
        console.log(`No handler for ${fieldName} checkbox field`);
    }

    const {
        value=false,
        error,
        isReadOnly,
        name="",
        label="",
        onCheckboxChange=noHandler,
        settings,
        additionalClass,
        errorFields,
        ...restProps
    } = props;

    const useStyles = makeStyles((themes) => ({
        toggleCheckbox: {
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
        toggleCheckboxLabel: {
            flex: "1",
            marginRight: '24px',
            fontSize: '14px',
            lineHeight: '1.71429',
            color: (props) => props.isDarkModeActive ? 'var(--very-light-pink-secondary)' : 'var(--charcoal-primary)',
            display: 'inline-block',
            fontWeight: '600',
            fontFamily: `'Inter', sans-serif`,
        },
        toggleCheckboxField: {
            margin: '0',
            "& .MuiFormControlLabel-label":{
                position: 'relative',
                display: 'inline-block',
                transition: 'all .2s',
                padding: '0 !important',

                "&:before": {
                    content: `""`,
                    position: 'relative',
                    display: 'block',
                    width: '48px',
                    height: '24px',
                    background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--very-light-pink-primary)',
                    borderRadius: '12px',
                    transition: 'all .2s',
                },
                "&:after": {
                    content: `""`,
                    position: 'absolute',
                    top: '50%',
                    left: '2px',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--pale-grey)',
                    boxShadow: (props) => props.isDarkModeActive ? `0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px 1px 2px rgba(255, 255, 255, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.1)` : `'0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px 2px 2px #FFFFFF, inset 0px -1px 1px rgba(0, 0, 0, 0.1)'`,
                    transition: 'all .2s',
                }
            },
            "& .MuiButtonBase-root": {
                display: "none"
            },
            "& .Mui-checked": {
                "& + .MuiFormControlLabel-label": {
                    "&:before": {
                        backgroundColor: 'var(--clear-blue-primary)'
                    },
                    "&:after": {
                        transform: 'translate(24px, -50%)',
                        backgroundColor: 'var(--pale-grey)'
                    }
                }
            }
        }
    }));


    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    return (
        <FormControl
            fullwidth
            error={errorFields}
            className={`${classes.toggleCheckbox} ${additionalClass} ${error ? classes.toggleCheckboxError : null}`}
        >
            <Box className={classes.toggleCheckboxLabel}>
                {label}
            </Box>
            <FormControlLabel
                className={classes.toggleCheckboxField}
                disabled={isReadOnly}
                control={
                    <Checkbox
                        checked={value}
                        name={name}
                        onChange={(e) => onCheckboxChange(e.target.checked)}
                    />
                }
                {...restProps}
            />
        </FormControl>
    )
}

export default ToggleCheckboxField

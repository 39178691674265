import React from "react";
import Accounts from "../userSection/content/Accounts";
import { useTranslation } from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import { Can } from "../../Context/Can";
// eslint-disable-next-line no-unused-vars
import Balances from "../userSection/content/Balances";

const AccountsPage = (props) => {
    const { t } = useTranslation();
    const { drawerOuterRef, underBarRef, settingsDarkMode } = props;

    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <Can I="read" a={"account"} passThrough>
                        {(can) =>{
                            return  can ? (
                                <>
                                    <Accounts
                                        drawerOuterRef={drawerOuterRef}
                                        underBarRef={underBarRef}
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </>
                            ) : (
                                <div>{t("account.noPermissionToRead")}</div>
                            )}
                        }
                    </Can>
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    );
};

export default AccountsPage;

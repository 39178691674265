import React from 'react';
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";

import {ReactComponent as StepIconDone} from "../../../../../../../assets/stepDone.svg";
import {ReactComponent as StepIcon} from "../../../../../../../assets/step.svg";
import {ReactComponent as StepError} from "../../../../../../../assets/stepError.svg";

import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
    stepper: {
        background: "none",
        margin:"-24px"
    },
    step: {
        "& .MuiStepLabel-labelContainer":{
           width:"80px",
           maxWidth:"100%"
        },
        '&.active': {
            '& button svg circle': {
                stroke: "#24DAC4"
            },
            '& button svg path': {
                fill: "#24DAC4"
            },
            "& .MuiStepLabel-labelContainer .MuiStepLabel-alternativeLabel": {
                color: "#24DAC4",
            },
        },
        '&.MuiStep-root.error': {
            '& button svg circle': {
                stroke: "red"
            },
            '& button svg path': {
                fill: "red"
            },
            '& button svg rect': {
                fill: "red"
            },
            "& .MuiStepLabel-labelContainer .MuiStepLabel-alternativeLabel": {
                color: "red",
            },
        },
        "& .MuiStep-horizontal":{
            paddingLeft: "6px",
            paddingRight:"6px"
        },
        "& .MuiStepConnector-alternativeLabel": {
            top: "7px",
            left: "calc(-50% + 7px)",
            right: "calc(50% + 8px)"
        },
        "& .MuiStepConnector-line": {
            borderColor: "#8A96A0"
        },
        "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#8A96A0",
            marginTop: "5px",
            fontWeight: 400,
            "&.MuiStepLabel-completed":{
                color: "#24DAC4",
            }
        },

    },
    stepIndicator: {
        "& .MuiStepConnector-line": {
            borderColor: "#24DAC4"
        }
    }
});


const KybSteps = (props) => {

    const classes = useStyles();

    const {steps, activeStep, handleStep, completed, errors, stepFields} = props;

    return (
        <Box marginRight={'-30px'}>
            <Stepper alternativeLabel activeStep={activeStep} elevation={0} className={classes.stepper}>
                {steps.map(({label, validation}, index) => {
                    // check if step fields exist in errors
                    const hasErrorInside = stepFields[index] && stepFields[index].some((field) => Object.keys(errors).includes(field));

                    let IconStep = null;
                    if(hasErrorInside){
                        IconStep = StepError
                    }else if(completed[index]){
                        IconStep = StepIconDone
                    }else{
                        IconStep = StepIcon
                    }
                    return (
                        <Step
                            key={label}
                            className={`${classes.step} ${index <= activeStep ? classes.stepIndicator : ""} ${activeStep === index ? " active" : ""}${hasErrorInside? " error" : ""}`}
                            completed={completed[index]}
                        >
                            <StepButton
                                onClick={handleStep(index)}
                                icon={<IconStep/>}
                                completed={completed[index]}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    )
                })}
            </Stepper>
        </Box>
    );
};

export default KybSteps;

import React, { useCallback, useEffect, useState } from "react";
import CheckBoxField from "../../../../formElements/CheckBoxField";
// import { ReactComponent as ReceiptIcon } from "../../../../../assets/receipt.svg";
// import { ReactComponent as AccountsIcon } from "../../../../../assets/accounts.svg";
// import { ReactComponent as ChainIcon } from "../../../../../assets/chain.svg";
import { ReactComponent as TicketSVG } from "../../../../../assets/ticketInvesthub.svg"
import { ReactComponent as LinkSVG} from '../../../../../assets/linkInvesthub.svg'

// import MaterialTable from "material-table";
// import Paper from "@material-ui/core/Paper";
import checkClick from "../../../../../helpers/checkClick";
import { Link, withRouter } from "react-router-dom";
import { makeStyles, Tooltip, Box, IconButton} from "@material-ui/core";
import ExclamationToolTip from "../../../../ExclamationToolTip";
import { useTranslation } from "react-i18next";
import FormatDate from "../../../../FormatDate";
import ExpenseStatus from "../../../../ExpenseStatus";
import FormatMoney from "../../../../FormatMoney";
import ExpenseRow from "./ExpenseRow";
import CheckBoxFieldMemo from "../../../../formElements/CheckBoxFieldMemo";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import getCardName from "../../../../../helpers/getCardName";
import {
    IN_OUT_TRANSACTIONS,
    // eslint-disable-next-line no-unused-vars
    PAID_METHODS,
    TRANSACTIONS_TYPES,
    TRANSACTION_HASH_TYPES,
    TRANSACTION_STATUS,
} from "../../../../../constants/transactions";
import { isDarkModeActive } from "../../../../App/App";
import formatMoney from "../../../../../helpers/formatMoney";
import Api from "../../../../../services/api";
import metamaskService from "../../../../../services/metamask";
import Web3 from "web3";
import { ContractAbi } from "../../../../../constants/contractAbi";
import { handleErrorMessage, setMessage } from "../../../../Messages/actions";
import { useDispatch, useSelector } from "react-redux";
import { COINS, E_CURRENCIES_ALIAS, TYPICAL_CURRENCIES, CURRENCIES_ALIAS } from "../../../../../constants/currencies-ecurrencies";
import WalletPaymentMethods, { CURRENCY_MAPPING } from "./WalletPaymentMethods/WalletPaymentMethods";
import BankingPaymentMethods from "./BankingPaymentMethods/BankingPaymentMethods";

const useStyles = makeStyles((theme) => ({
    notReimbursable: {
        background: "#F5A623",
    },
    receiptStatus: {
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        transition: 'background .2s',
        padding: '0',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--pale-grey)',
        "& svg": {
            width: '18px',
            height: '18px',
            transition: 'fill .2s',
            fill: 'var(--battelship-grey)'
        },
        "&:hover svg": {
            fill: 'var(--clear-blue-primary)'
        }
    },
    exclamation: {
        display: "inline-block",
        verticalAlign: "middle",
    },
    "& .MuiButtonBase-root": {
        "& + .MuiButtonBase-root": {
            marginLeft: "4px"
        }
    }
}));

const service = new Api();

const ExpenseTable = (props) => {
    const { t } = useTranslation();

    const {
        expenses = [],
        isLoading,
        history,
        activeRoleTab,
        dateFormat,
        selected,
        setSelected,
        type,
        settingsDarkMode
    } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const [isMoneriumEnabled, setIsMoneriumEnabled] = useState();
    const [companySettings, setCompanySettings] = useState();
    const isProduction = window._env_.ENVIRONMENT === 'prod';
    const [open,setOpen] = useState(false);
    const [openBankingModal, setOpenBankingModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const [importedWallets, setImportedWallets] = useState([]);
    const companyCurrency = useSelector(state => {
        const {user} = state;
        if(user && user.userData && user.userData.company && user.userData.company.currency){
            return user.userData.company.currency
        }
        return null
    });
    const [sendingCurrency, setSendingCurrency] = useState(TYPICAL_CURRENCIES.includes(companyCurrency) ? companyCurrency : 'EUR')
    const [loading, setLoading] = useState(false);
    const [selectedToken, setSelectedToken] = useState();

    const dispatch = useDispatch()
    const onCheckAll = (value) => {
        if (expenses.length) {
            setSelected(value ? expenses.map((item) => item._id) : []);
        }
    };
    const getMoneriumAuthToken = async () => {
        try {
            const { data } = await service.getMoneriumAuthToken();
            setIsMoneriumEnabled(data?.success)
        } catch(error) {

        }
    };

    const onChange = (e, value) => {
        setSelected((items) => {
            if (value) {
                const copy = [...items];
                copy.push(e.target.id);
                return copy;
            } else {
                return items.filter((item) => item !== e.target.id);
            }
        });
    };

    const getTransactionExplorer = (network) => {
        switch (network) {
        case 'mainnet-only':
            return 'https://etherscan.io/tx';
        case 'mainnet':
        case 'ethereum':
            return isProduction ? 'https://etherscan.io/tx' : 'https://sepolia.etherscan.io/tx'
        case 'base':
            return isProduction ? 'https://basescan.org/tx' : 'https://sepolia.basescan.org/tx'
        case 'polygon':
            return isProduction ? 'https://polygonscan.com/tx' : 'https://amoy.polygonscan.com/tx'
        case 'xinfin':
            return isProduction ? 'https://explorer.xinfin.network/tx' : 'https://apothem.xinfin.network/tx'
        default:
            return 'https://basescan.org/tx'
            }
    };

    const onClickTransactionHash = (hash, type, network) => {
        const hashUrl = type === TRANSACTION_HASH_TYPES.CRYPTO ? `${getTransactionExplorer(network)}/${hash}` : `https://sandbox.fiatrepublic.com/dashboard/member/payments?limit=20&offset=0`;
        window.open(hashUrl, "_blank", "noopener,noreferrer");
    };

    const memoizedOnChange = useCallback(onChange, []);

    const getExchangeRate = async (currency,aliasCurrency, amount) => {
        const { data: exchangeData } = await service.getExchangeRate(
            currency,
            aliasCurrency,
            amount
        );
        return exchangeData || { result: 0 };
    }

    useEffect(()=>{
        getMoneriumAuthToken();
        getCompanySettings();
        getCompanyImportedWallets();
    }, [])

    const getCompanySettings = async () => {
        const { data } = await service.getCompany();
        if (data) {
            setCompanySettings(data);
        } else {
            setCompanySettings();
        }
    };

    const getCompanyImportedWallets = async () => {
        const { data } = await service.getCompanyImportedWallets();
        if (data) {
            setImportedWallets(data?.wallets)
        }
    }

    const transferringToken = useCallback(async (transaction) => {
        console.log('transaction', transaction);
        if (CURRENCY_MAPPING[transaction.currency] && CURRENCY_MAPPING[transaction.currency].length > 1 && !selectedToken) {
            dispatch(setMessage("Please select a token", "error"));
            return;
        }
        console.log('selectedToken', selectedToken);

        const isMultipleTokens = CURRENCY_MAPPING[transaction.currency].length > 1;
        // Break if metamask not available
        let totalAmount = transaction?.amount + (transaction?.taxAmount || 0);
        if (metamaskService.isMetamaskNotAvailable()) {
            dispatch(setMessage(
                "You need to install Metamask to use this feature",
                "error"
            ));
            return;
        }
        try {
            const address = metamaskService.formatAddress(
                transaction?.vendor?.walletAddress ||
                    transaction?.user?.walletAddress
            );

            if (!Web3.utils.isAddress(address)) {
                dispatch(setMessage(
                    `User ${transaction.user?.firstName || ''} ${transaction?.user?.lastName || ''}'s wallet is invalid`,
                    "error"
                ));
                return;
            }
            await metamaskService.requestAccounts();
            console.log('companySettings', companySettings);
            let network;
            switch (companySettings.network) {
                case "ethereum":
                    network = isProduction ? "mainnet" : "sepolia";

                    break;

                case "polygon":
                    network = isProduction ? "polygon" : "amoy";

                    break;

                case "xinfin":
                    network = isProduction ? "xinfin" : "apothem";

                    break;

                default:
                    dispatch(setMessage("Network not supported", "error"));

                    return;
            }

            console.log('network', network);

            await metamaskService.requireNetwork(network, () => {
                window.location.reload();
            });

            const account = metamaskService.getCurrentAddress();

            const importedWalletsArr = importedWallets.map((w)=> w?.walletAddress?.toLowerCase())
            const sendingAddress = importedWalletsArr.find((w) => w === account);

            if (!sendingAddress) {
                dispatch(setMessage(
                    "Please switch to your metamask wallet which is imported wallet",
                    "error"
                ));
                return;
            }

            const accountBalance = await metamaskService.getBalance(account);

            // convert amount & currency
            let exchangeData;
            // Flow: current currency -> USD -> POL
            // if (sendingCurrency && COINS.includes(sendingCurrency)) {

            if (transaction.currency && COINS.includes(transaction.currency)) {

                const { data: coinPriceResponse } = await service.getCoinPrice(
                    transaction.currency
                );
                const coinPrice =
                    coinPriceResponse?.coinPrice?.data[transaction.currency][0]
                        ?.quote?.USD?.price; // POL to USD rate
                if (coinPrice) {
                    // get exchangeData
                    let { data: exchangeDataInUSD } =
                        await service.getExchangeRate("EUR", "USD", totalAmount);
                    // got current currency in USD ;
                    exchangeData = {
                        result: exchangeDataInUSD?.result
                            ? exchangeDataInUSD?.result / coinPrice
                            : 0,
                    };
                }
            } else {
                exchangeData = await getExchangeRate(transaction.currency, transaction.currency, totalAmount);
            }
            let convertedAmount = transaction?.amount;
            if (exchangeData?.result) {
                convertedAmount = exchangeData?.result?.toFixed(6);
            }

            const {data: tokenContracts} = await service.getServiceData({dataFor: 'token-contracts'})
            const {data: tokenDecimals} = await service.getServiceData({dataFor: 'token-decimals'})

            let aliasCurrency = isMultipleTokens ? selectedToken : CURRENCIES_ALIAS[transaction.currency];
            if (!aliasCurrency) {
                // In case no match currency -> use EUR instead
                aliasCurrency = 'EURe';
                exchangeData = await getExchangeRate(transaction.currency, 'EUR', totalAmount);
                if (exchangeData?.result) {
                    convertedAmount = exchangeData?.result?.toFixed(6);
                }
            }

            const sendingTokenContract = tokenContracts[aliasCurrency.toLowerCase()]
            console.log('sendingTokenContract', sendingTokenContract);
            const sendingTokenDecimal = tokenDecimals[aliasCurrency.toLowerCase()];
            const web3 = new Web3(window.ethereum);

            const contractAddress = metamaskService.formatAddress(
                sendingTokenContract
            );
            const contract = new web3.eth.Contract(
                ContractAbi.token,
                contractAddress
            );
            /* eslint-disable-line no-restricted-syntax */
            /* eslint-disable no-await-in-loop */

            setLoading(true);
            const data = await contract.methods
                .transfer(
                    metamaskService.formatAddress(
                        transaction?.vendor.walletAddress ||
                            transaction?.user?.walletAddress
                    ),
                    `${Math.ceil(+convertedAmount * 10 ** sendingTokenDecimal)}`
                )
                .send({
                    from: account,
                    value: 0,
                });

            if (data) {
                // todo add logic to trigger transaction status and save hash
                const metamaskData = {
                    tokenCurrency: aliasCurrency,
                    txHash: data?.transactionHash,
                    gasUsed: data?.gasUsed,
                    cumulativeGasUsed: data?.cumulativeGasUsed,
                    from: data?.events?.Transfer?.returnValues?.from,
                    to: data?.events?.Transfer?.returnValues?.to,
                    effectiveGasPrice: data?.effectiveGasPrice
                };
                const update = await service.expenseAction(transaction._id, TRANSACTION_STATUS.COMPLETED, 'wallet', metamaskData)
            }
            setLoading(false);
            setOpen(false);
            /* eslint-enable no-await-in-loop */
        } catch (error) {
            setLoading(false);
            return;
        }

    }, [selectedToken, companySettings, dispatch, importedWallets, isProduction]);

    const columns = [
        {
            headerStyle: { padding: " 0 0 0 16px" },
            cellStyle: { padding: " 0 0 0 16px", whiteSpace: "nowrap" },
            title: expenses.length ? (
                <Box paddingLeft={"6px"}>
                    <CheckBoxField
                        onCheckboxChange={(val) => onCheckAll(!!val)}
                        value={
                            !!(
                                expenses.length &&
                                selected.length === expenses.length
                            )
                        }
                        disabled={activeRoleTab === "auditor"}
                        label={""}
                    />
                </Box>
            ) : (
                ""
            ),
            render: ({ _id, reimbursable }) => (
                <>
                    <CheckBoxFieldMemo
                        onChange={memoizedOnChange}
                        checked={selected.includes(String(_id))}
                        label={""}
                        id={String(_id)}
                        name={""}
                        disabled={activeRoleTab === "auditor"}
                    />
                    {reimbursable === false && (
                        <ExclamationToolTip
                            title={t("formFields.nonReimbursable")}
                            className={classes.exclamation}
                        />
                    )}
                </>
            ),
        },
        {
            title: t("thead.date"),
            cellStyle: { whiteSpace: "nowrap" },
            field: "date",
            render: (row) => {
                return row?.date ? (
                    <FormatDate dateFormat={dateFormat}>{row.date}</FormatDate>
                ) : (
                    <FormatDate dateFormat={dateFormat}>
                        {Date(+row.timeStamp)}
                    </FormatDate>
                );
            },
        },
        {
            title: t("thead.employee"),
            cellStyle: { whiteSpace: "nowrap" },
            field: "user",
            render: (row) => {
                return (
                    <>
                        {row?.user?.firstName} {row?.user?.lastName}
                    </>
                );
            },
        },
        ...(IN_OUT_TRANSACTIONS.includes(type)
            ? [
                  {
                      title: t("thead.hash"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "fromTo",
                      render: (row) => {
                          return (
                              <Link
                                  to="#"
                                  onClick={() => {
                                      onClickTransactionHash(
                                          row?.hash,
                                          row?.vendor?.fiat
                                              ? TRANSACTION_HASH_TYPES.FIAT
                                              : TRANSACTION_HASH_TYPES.CRYPTO,
                                          row?.network
                                      );
                                  }}
                              >
                                  {row?.hash}
                              </Link>
                          );
                      },
                  },
              ]
            : []),

        ...(!IN_OUT_TRANSACTIONS.includes(type)
            ? [
                  {
                      title: t("thead.description"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "comment",
                      render: (row) => {
                          return `${row?.comment || ""}`;
                      },
                  },
                  {
                      title: t("thead.merchant"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "merchant",
                      render: (row) => {
                          return `${row?.merchant || ""}`;
                      },
                  },
                  {
                      title: t("thead.vendor"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "vendor",
                      render: (row) => {
                          return `${
                              row?.vendor?.companyName ||
                              row?.vendor?.firstName + row?.vendor?.lastName ||
                              ""
                          }`;
                      },
                  },
                  {
                      title: t("thead.category"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "category",
                      render: ({ category }) =>
                          category && category.name ? category.name : " ",
                  },
              ]
            : []),
        ...(!IN_OUT_TRANSACTIONS.includes(type) && activeRoleTab !== "personal"
            ? [
                  {
                      title: t("thead.receiver"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "user",
                      render: ({ user, vendor, paymentType }) => {
                          if (paymentType === "Request Payment") {
                              return vendor?.companyName
                                  ? vendor?.companyName
                                  : vendor?.firstName + " " + vendor?.lastName;
                          } else
                              return user
                                  ? `${user.firstName} ${user.lastName}`
                                  : " ";
                      },
                  },
              ]
            : []),
        ...(IN_OUT_TRANSACTIONS.includes(type)
            ? [
                  {
                      title: t("thead.fromTo"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "fromTo",
                      render: ({
                          user,
                          vendor,
                          paymentType,
                          company,
                          paidWith,
                          from,
                          to,
                      }) => {
                          const receiver =
                              paymentType === "Expense" ? user : vendor;
                          let receiveAddress = "";
                          if (receiver) {
                              receiveAddress =
                                  paidWith === "wallet"
                                      ? receiver?.walletAddress || ""
                                      : receiver?.iban || "";
                          }
                          const fromAddress = company?.name || from;
                          const toAddress = receiveAddress || to;
                          return (
                              <>
                                  From: {fromAddress}
                                  <hr />
                                  To: {toAddress}
                              </>
                          );
                      },
                  },
              ]
            : []),
        ...(!IN_OUT_TRANSACTIONS.includes(type)
            ? [
                  {
                      title: t("thead.paymentType"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "paymentType",
                      render: ({ paymentType, merchant, vendor }) => {
                          if (!paymentType) return "Expense";

                          if (typeof paymentType === "string") {
                              return paymentType;
                          } else if (typeof paymentType === "object") {
                              return getCardName(paymentType);
                          }
                          return "";
                      },
                  },
                  {
                      title: t("Pay out"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "paidWith",
                      align: "right",
                      render: (row) => {
                          if (
                              (!row?.user?.walletAddress && row?.user?.iban) ||
                              (!row?.vendor?.walletAddress && row?.vendor?.iban)
                          ) {
                              //In case just IBAN
                              return isMoneriumEnabled ? "CRYPTO" : row?.vendor?.fiat ? "FIAT" : "CRYPTO";
                          }
                          return "CRYPTO";
                      },
                  },
                  {
                      title: t("Spent Currency"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "spent",
                      align: "right",
                      render: ({
                          currency,
                          amount,
                          gasFee,
                          reportedCurrency,
                          reportedExchangeRate,
                      }) => {
                          return (
                              <Tooltip
                                  title={
                                      gasFee ? (
                                          <>
                                              Gas Fee:
                                              <br />
                                              {gasFee} POL
                                          </>
                                      ) : (
                                          <>
                                              {" "}
                                              {currency} {amount}
                                              <br />
                                              {currency} 1 = {reportedCurrency}{" "}
                                              {reportedExchangeRate}{" "}
                                          </>
                                      )
                                  }
                                  placement="right"
                              >
                                  <span>
                                      {currency}{" "}
                                      <FormatMoney>{amount}</FormatMoney>
                                  </span>
                              </Tooltip>
                          );
                      },
                  },
                  {
                      title: t("thead.amount"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "paidWith",
                      align: "right",
                      render: ({
                          amount,
                          currency,
                          reportedExchangeRate,
                          reportedCurrency,
                          calculatedAmount,
                      }) => {
                          return (
                              <Tooltip
                                  title={
                                      <>
                                          {" "}
                                          {currency} {amount}
                                          <br />
                                          {currency} 1 = {reportedCurrency}{" "}
                                          {reportedExchangeRate}{" "}
                                      </>
                                  }
                                  placement="right"
                              >
                                  <span>
                                      {reportedCurrency}{" "}
                                      <FormatMoney>
                                          {calculatedAmount}
                                      </FormatMoney>
                                  </span>
                              </Tooltip>
                          );
                      },
                  },
              ]
            : [
                  {
                      title: t("thead.in"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "paidWith",
                      align: "right",
                      render: ({ value, from, to, isIn, tokenSymbol }) => {
                          const amount =
                              isIn && value !== "0"
                                  ? formatMoney(Number(value), ".") +
                                    " " +
                                    tokenSymbol
                                  : "-";
                          return (
                              <Tooltip
                                  title={
                                      <>
                                          {" "}
                                          {amount !== "0" ? amount : "-"}
                                          <br />
                                      </>
                                  }
                                  placement="right"
                              >
                                  <span style={{ color: "green" }}>
                                      {amount !== "-" ? "+" : ""}{" "}
                                      {amount !== "0" ? amount : "-"}
                                  </span>
                              </Tooltip>
                          );
                      },
                  },
                  {
                      title: t("thead.out"),
                      cellStyle: { whiteSpace: "nowrap" },
                      field: "paidWith",
                      align: "right",
                      render: ({ value, from, to, isIn, tokenSymbol }) => {
                          const amount = isIn
                              ? "-"
                              : value !== "0"
                              ? formatMoney(Number(value), ".") +
                                " " +
                                tokenSymbol
                              : "-";

                          return (
                              <Tooltip
                                  title={
                                      <>
                                          {" "}
                                          {amount}
                                          <br />
                                      </>
                                  }
                                  placement="right"
                              >
                                  <span style={{ color: "red" }}>
                                      {amount !== "-" ? "-" : ""} {amount}
                                  </span>
                              </Tooltip>
                          );
                      },
                  },
              ]),
        {
            title: t("thead.status"),
            cellStyle: { whiteSpace: "nowrap" },
            field: "status",
            render: (row) => (
                <ExpenseStatus status={row.status || "completed"} />
            ),
        },
        ...(!IN_OUT_TRANSACTIONS.includes(type)
            ? [
                  {
                      cellStyle: {
                          padding: "0 16px 0 16px",
                          whiteSpace: "nowrap",
                      },
                      title: "",
                      field: "receiptUri",
                      align: "right",
                      render: (row) => (
                          <>
                              <Tooltip
                                  arrow
                                  placement={"bottom"}
                                  title={
                                      row.receiptUri
                                          ? t("expense.hasReceipt")
                                          : t("expense.noReceipt")
                                  }
                              >
                                  <IconButton className={classes.receiptStatus}>
                                      <TicketSVG />
                                  </IconButton>
                              </Tooltip>
                              {type === "all" && row?.txHash?.length ? (
                                  <Tooltip
                                      placement={"bottom"}
                                      title={t("thead.hash")}
                                      onClick={() => {
                                          onClickTransactionHash(
                                              row?.txHash,
                                              row?.vendor?.fiat
                                                  ? TRANSACTION_HASH_TYPES.FIAT
                                                  : TRANSACTION_HASH_TYPES.CRYPTO
                                          );
                                      }}
                                  >
                                      <IconButton
                                          className={
                                              !row.receiptUri
                                                  ? classes.receiptStatus
                                                  : null
                                          }
                                      >
                                          <LinkSVG />
                                      </IconButton>
                                  </Tooltip>
                              ) : null}
                              {row.status === TRANSACTION_STATUS.APPROVED && (
                                  <Tooltip
                                      arrow
                                      placement={"bottom"}
                                      title={t("Pay")}
                                  >
                                      <IconButton
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              // if (row?.paidWith === PAID_METHODS.WALLET) {
                                              const receivingWallet =
                                                  row.paymentType === "Expense"
                                                      ? row.user.walletAddress
                                                      : row.vendor
                                                            .walletAddress;
                                              const receivingIban =
                                                  row.paymentType === "Expense"
                                                      ? row.user.iban
                                                      : row.vendor.iban;
                                              const receivingFirstName =
                                                  row.paymentType === "Expense"
                                                      ? row.user.firstName
                                                      : row.vendor.firstName;
                                              const receivingLastName =
                                                  row.paymentType === "Expense"
                                                      ? row.user.lastName
                                                      : row.vendor.lastName;
                                              const sendType = row?.vendor
                                                  ?.contactType
                                                  ? row?.vendor?.contactType
                                                  : "individual";
                                              const companyName =
                                                  row?.vendor?.companyName;
                                              const country =
                                                  row?.vendor?.country;
                                              const isVendor = row?.vendor;
                                              const isWallet = isVendor
                                                  ? !row?.vendor?.iban &&
                                                    row?.vendor?.walletAddress
                                                  : !row?.user?.iban &&
                                                    row?.user?.walletAddress;
                                              console.log("row", row, isWallet);
                                              if (
                                                  (!row?.user?.walletAddress &&
                                                      row?.user?.iban) ||
                                                  (!row?.vendor
                                                      ?.walletAddress &&
                                                      row?.vendor?.iban)
                                              ) {
                                                setOpenBankingModal(true);
                                                setSelectedTransaction({...row, receivingWallet, receivingIban, receivingFirstName, receivingLastName, sendType, companyName, country, isVendor, isWallet})

                                              } else if (isWallet) {
                                                    setOpen(true);
                                                    setSelectedTransaction({...row, receivingWallet });
                                              }
                                          }}
                                          className={classes.receiptStatus}
                                      >
                                          <TicketSVG />
                                      </IconButton>
                                  </Tooltip>
                              )}
                          </>
                      ),
                  },
              ]
            : []),
    ];

    return (
        <>
        <BankingPaymentMethods loading={loading} setLoading={setLoading} openBankingModal={openBankingModal} setOpenBankingModal={setOpenBankingModal} selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} isMoneriumEnabled={isMoneriumEnabled} />
        <WalletPaymentMethods loading={loading} setLoading={setLoading} open={open} setOpen={setOpen} selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} transferringToken={transferringToken} setSelectedToken={setSelectedToken} selectedToken={selectedToken}></WalletPaymentMethods>
            <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={[
                    ...(activeRoleTab === "personal" &&
                    !IN_OUT_TRANSACTIONS.includes(type)
                        ? [{ isExpenseQueueRow: true }]
                        : []),
                    ...expenses,
                ]}
                onRowClick={(e, rowData) => {
                    if (type === TRANSACTIONS_TYPES.EXECUTED) {
                        return;
                    }
                    if (["LABEL​", "INPUT", "SPAN"].includes(e.target.tagName)) return;
                    const wasClicked = checkClick();
                    if (wasClicked) {
                        history.push({
                            pathname: `/transactions/${rowData._id}`,
                            state: { backPath: props.match.url },
                        });
                    }
                }}
                components={{
                    Row: ExpenseRow,
                }}
            />
        </>
    );
};

export default withRouter(ExpenseTable);

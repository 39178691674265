import React from 'react';
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import KybSteps from "./KybSteps";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    search:{
        marginLeft:"auto",
        display:"flex",
    },
});

const TopComponent = (props) => {
    const classes = useStyles();
    return (
            <>
                <Grid item>
                    <Typography variant="h1">{"Verify Company"}</Typography>
                </Grid>
                <Grid item className={classes.search}>
                    <KybSteps {...props}/>
                </Grid>
            </>
    );
};

export default TopComponent;

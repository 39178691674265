import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import RadioFields from "../../../../../../formElements/RadioFields";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import BranchField from "./BranchField";
import UserField from "./UserField";
import GroupField from "./GroupField";
import Box from "@material-ui/core/Box";

const ScopeFields = (props) => {
    const {t} = useTranslation();

    const {handleChange, values, errors} = props;
    const [isDataFetching, setIsDataFetching] = useState(false);

    const items = [
        {value:"company", label:t("formFields.company")},
        {value:"branch", label:t("formFields.branch")},
        {value:"group", label:t("formFields.group")},
        {value:"user", label:t("formFields.user")},
    ];

    useEffect(() => {

        handleChange("scope", "company"); // select default company

        return () => {
           handleChange("scope"); // delete
        }
    },[]);


    return (
        <>
            <FormControl fullWidth error={!!errors.scope}>
                <RadioFields
                    disabled={isDataFetching}
                    options={items}
                    name={"scope"}
                    label={t("formFields.selectScope")}
                    value={values["scope"]}
                    onRadioChange={(val) => handleChange("scope", val)}
                    error={errors["scope"]}
                />
            </FormControl>
            {values["scope"] === "branch" && (
                    <Box marginTop={"10px"}>
                        <BranchField
                            isDataFetching={isDataFetching}
                            setIsDataFetching={setIsDataFetching}
                            handleChange={handleChange}
                            value={values["branch"]}
                            error={errors["branch"]}/>
                    </Box>
                )
            }
            {values["scope"] === "group" && (
                    <Box marginTop={"10px"}>
                        <GroupField
                            isDataFetching={isDataFetching}
                            setIsDataFetching={setIsDataFetching}
                            handleChange={handleChange}
                            value={values["group"]}
                            error={errors["group"]}/>
                    </Box>
                )
            }
            {values["scope"] === "user" && (
                    <Box marginTop={"10px"}>
                        <UserField
                            isDataFetching={isDataFetching}
                            setIsDataFetching={setIsDataFetching}
                            handleChange={handleChange}
                            value={values["user"]}
                            error={errors["user"]}/>
                    </Box>
                )
            }
        </>
    );
};

export default ScopeFields;

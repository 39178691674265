import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import SelectField from "../../../../../../../formElements/SelectField";
import {FormControl, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import ThinButton from "../../../../../../../ThinButton";
import CardOptionWrapper from "../../CardOptionWrapper";

const useStyles = makeStyles(theme => ({
    currency:{
        width:"35%"
    },
    monthLimit:{
        width:"65%"
    }
}));

const LimitGroupForm = (props) => {
    const {handleSubmit, values, errors, handleChange, disabled, monthLimits, curCode, currencyValue, currencyOptions, isLoading} = props;
    const {t} = useTranslation();
    const classes = useStyles();


    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <CardOptionWrapper>
                        <Box display={"flex"} flexWrap={"nowrap"}>
                            <FormControl className={classes.currency}>
                                <SelectField
                                    disabled
                                    label={t("formFields.currency")}
                                    name={"account"}
                                    value={currencyValue}
                                    options={currencyOptions}
                                />
                            </FormControl>
                            <FormControl className={classes.monthLimit} error={!!errors.monthLimit}>
                                <SelectField
                                    label={t("formFields.monthLimit")}
                                    name={"monthLimit"}
                                    value={values["monthLimit"]}
                                    options={monthLimits}
                                    onSelectChange={(val) => handleChange("monthLimit", val)}
                                    error={errors["monthLimit"]}
                                    disabled={disabled || isLoading}
                                />
                            </FormControl>
                        </Box>
                    </CardOptionWrapper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {!disabled && (
                        <Box mt={"30px"}>
                            <CardOptionWrapper>
                                <ThinButton noPadding type={"submit"} color={"primary"} disabled={isLoading} disableTouchRipple={false}>{t("formFields.setMonthlyLimit")}</ThinButton>
                            </CardOptionWrapper>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

export default LimitGroupForm;

import React from 'react'
import {makeStyles} from "@material-ui/core"
import {NavLink, matchPath, useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next";
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => {
    return {
        head: {
            display: "flex",
            alignItems: "center",
            minHeight: '40px',
            position: 'relative',
            marginBottom: '32px',
            [theme.breakpoints.down('767')]: {
                marginBottom: '24px'
            },
            [theme.breakpoints.down('767')]: {
                flexWrap: 'wrap',
                paddingBottom: '24px',
                marginBottom: '24px'
            },
        },
        headTitle: {
            position: 'relative',
            display: 'inline-block',
            paddingLeft: '32px',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '1.6',
            letterSpacing: '-.02em',
            [theme.breakpoints.down('767')]: {
                fontSize: '18px'
            }
        },
        headNav: {
            display: 'flex',
            marginLeft: 'auto',
            [theme.breakpoints.down('767')]: {
                width: '100%',
                // margin: '0 0 16px'
            }
        },
        headLink: {
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'all .2s',
            backgroundColor: 'transparent',
            [theme.breakpoints.down('767')]: {
                flexGrow: '1',
                marginTop: '16px'
            },
            '&:not(:last-child)': {
                marginRight: '4px',
                [theme.breakpoints.down('767')]: {
                    marginRight: '0'
                }
            },
            '&:hover': {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
            },
            '&.active': {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)'
            },
        }

    }
});

const HeadContentInvesthub =(props) => {
    const {t} = useTranslation();
    const {
        routes=[],
        rightContent,
        settings,
        titleColor
    } = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    const location = useLocation();
    const matchedPathUser = matchPath(location.pathname, {
        path: "/user",
        exact: true,
        strict: false
    });

    const matchedPathProfile = matchPath(location.pathname, {
        path: "/user/profile",
        exact: true,
        strict: false
    });

    let titleName = null;
    if (matchedPathUser) {
        titleName = <>{t("regionalSettings")}</>
    }
    if (matchedPathProfile) {
        titleName = <>{t("User Profile")} {/* NEED TRANSLATION */}</>
    }

    const titleClasses = `${classes.headTitle} card-title ${titleColor || ''}`;

    return(
        <div className={classes.head}>
            <div className={titleClasses}>
                {titleName}
            </div>
            <div className={classes.headNav}>
                {routes.map((item, key) => {
                    return (
                        <NavLink
                            key={key}
                            className={classes.headLink}
                            exact
                            to={item.url}
                        >
                            {item.name}
                        </NavLink>
                    )
                })}
            </div>
            {rightContent}
        </div>
    )
}

export default HeadContentInvesthub

import React, {useState} from 'react';
import RejectDialog from "../../Expense/EditExpense/RejectDialog";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../services/api";
import {useDispatch} from "react-redux";
import {setMessage} from "../../../../../Messages/actions"
import MenuOption from "../../../../../MenuOption";
const service = new Api();

const BatchReject = (props) => {

    const {t} = useTranslation();

    const {selectedExpenses, closeMenu, setSelected} = props;


    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const onShowDialog = () => {
        closeMenu(); // close menu
        setOpen(true); // open popup
    }

    const onReject = async (values) => {
        const {rejectReason} = values;
        const ids = selectedExpenses.map(({id}) => id);
        const response = await service.rejectBatchExpense({rejectReason, expenseIds:ids});

        const {data} = response;
        const {modified, doNotModified} = data;
        const message = t("expense.updatedExpenses", {modified:modified, all:modified+doNotModified});
        dispatch(setMessage(message, 'success'));
        setSelected([]);
        setOpen(false);
    }



    return (
        <>
            {selectedExpenses.some((item) => item.status === 'submitted') && (
                <MenuOption  onClick={onShowDialog}>
                    {t("formFields.reject")}
                </MenuOption>
            )}
            <RejectDialog open={open} setOpen={setOpen} onReject={onReject}/>
        </>
    );
};

export default BatchReject;

import React, {useCallback} from 'react';
import {FormControl} from "@material-ui/core";
import InputFieldMemo from "../../../../../formElements/InputFieldMemo";
import SelectField from "../../../../../formElements/SelectField";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../../Context/Can";

const useStyles = makeStyles(theme => ({
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 8px)',
        width: 'calc(100% - 8px)',
        margin: '0 8px 16px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 8px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    }
}))

const FbaForm = (props) => {
    const classes = useStyles();
    const {handleSubmit, isLoading, values, errors, handleChange, currencies} = props;
    const {t} = useTranslation();
    const memoizedCallback = useCallback(handleChange , []);

    return (
        <form onSubmit={handleSubmit}>
            <Can I="update" a={"company"} passThrough>
                {(can) => (
                    <FormControl fullWidth error={!!errors.monthLimit} className={classes.itemSettings}>
                        <FormControl error={!!errors["bankName"]} fullWidth className={classes.itemFieldFull}>
                            <InputFieldMemo
                                label={t("formFields.bankName")}
                                name={"bankName"}
                                value={values["bankName"]}
                                onInputChange={memoizedCallback}
                                error={errors["bankName"]}
                                isLoading={isLoading}
                                disabled={!can}
                            />
                        </FormControl>

                        <FormControl error={!!errors["accountName"]} fullWidth className={classes.itemFieldFull}>
                            <InputFieldMemo
                                label={t("formFields.accountName")}
                                name={"accountName"}
                                value={values["accountName"]}
                                onInputChange={memoizedCallback}
                                error={errors["accountName"]}
                                isLoading={isLoading}
                                disabled={!can}
                            />
                        </FormControl>

                        <FormControl error={!!errors["accountNo"]} fullWidth className={classes.itemFieldFull}>
                            <InputFieldMemo
                                label={t("formFields.accountNo")}
                                name={"accountNo"}
                                value={values["accountNo"]}
                                onInputChange={memoizedCallback}
                                error={errors["accountNo"]}
                                isLoading={isLoading}
                                disabled={!can}
                            />
                        </FormControl>
                        <FormControl fullWidth error={!!errors[`currency`]} className={classes.itemFieldFull}>
                            <SelectField
                                label={t("formFields.currency")}
                                name={"currency"}
                                value={values["currency"]}
                                error={errors["currency"]}
                                options={currencies}
                                isLoading={isLoading}
                                onSelectChange={(val) => handleChange("currency", val)}
                                disabled={!can}
                            />
                        </FormControl>
                    </FormControl>
                )}
            </Can>
        </form>
    );
};

export default FbaForm;

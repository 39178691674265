/**
 * check if user want to copy table row data or click
 * got from here // https://stackoverflow.com/questions/36215191/detect-if-text-is-highlighted-on-mouse-up-jquery
 *
 */

const checkClick = () => {
	let highlightedText = "";
	if (window.getSelection) {
		highlightedText = window.getSelection().toString();
	}
	else if (document.selection && document.selection.type !== "Control") {
		highlightedText = document.selection.createRange().text;
	}

	return !highlightedText
}

export default checkClick
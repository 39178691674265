import React from 'react';

import RightDrawer from '../../../components/RightDrawer'
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { isDarkModeActive } from '../../App/App';

const useStyles = makeStyles(theme => ({
    contentDrawer: {

    }
}));

const DrawerFilter = (props) => {
    const {onDrawerFilterClose, isOpen, children, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    return (
        <RightDrawer
            isOpen={isOpen}
            onClose={onDrawerFilterClose}
            titleDrawer={t("formFields.filter")}
            className={classes.drawer}
            settingsDarkMode={settingsDarkMode}
        >
            {children}
        </RightDrawer>
    );
}

export default DrawerFilter

import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import { Translation } from 'react-i18next';
const styles = theme => ({
	root: {
		padding: theme.spacing(3, 2),
	},
});

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {

		this.setState({
			error: error,
			errorInfo: errorInfo
		})
	}

	render() {
		const { classes } = this.props;

		if (this.state.errorInfo) {
			// Error path
			return (
				<Paper className={classes.root}>
					<Typography variant="h5" component="h3">
						<Translation>
							{t => <Typography variant="h5" component="h3">{t('cantRenderComponent')}</Typography>}
						</Translation>
					</Typography>
				</Paper>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default  withStyles(styles)(ErrorBoundary)
import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import getTimeAgo from "../../../../../../helpers/getTimeAgo";
import {ReactComponent as CloseSVG} from "../../../../../../assets/closeInvesthub.svg"
import {ReactComponent as CheckSVG} from "../../../../../../assets/checkInvesthub.svg"
import {ReactComponent as InfoSVG} from "../../../../../../assets/infoInvesthub.svg"
import { isDarkModeActive } from '../../../../../App/App';
// eslint-disable-next-line no-unused-vars
import { ReactComponent as ChainIcon } from "../../../../../../assets/chain.svg";

const useStyles = makeStyles(theme => ({
    itemNotif: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
        transition: 'background .2s',
        borderRadius: '8px',

        "&:not(:last-child):before": {
            content: '""',
            position: 'absolute',
            left: '12px',
            right: '12px',
            bottom: '0',
            height: '1px',
            background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)',
        }
    },
    itemChoosen: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '48px !important',
        height: '48px',
        borderRadius: '50%',

        '& svg': {
            width: '24px',
            height: '24px',
            margin: '0 auto',
            fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            transition: 'fill .2s',
        }
    },
    itemContent: {
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',
        flex: '0 0 calc(100% - 48px)',
        width: 'calc(100% - 48px)',
        paddingLeft: '12px',
        '& .text-color-faded-green svg': {
            fill: 'var(--faded-green)'
        },
        '& .text-color-orange-pink svg': {
            fill: 'var(--orange-pink)'
        },
        '& .text-color-pale-salmon svg': {
            fill: 'var(--pale-salmon)'
        }
    },
    timeAgo: {
        display: 'block',
        fontSize: '13px',
        fontWeight: '700',
        lineHeight: '1.23077',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
    }
}))

const isProduction = window._env_.ENVIRONMENT === 'prod';
const netWorkScanLink = isProduction ? 'https://polygonscan.com/tx/': 'https://mumbai.polygonscan.com/tx/'

const Item = (props) => {
    const {
        notification,
        // eslint-disable-next-line no-unused-vars
        deleteMessage,
        isDisabled,
        onClose,
        settings
    } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const {t} = useTranslation();

    const {
        type,
        translateKey,
        // eslint-disable-next-line no-unused-vars
        _id,
        payload,
        textProperty,
        createdAt,
        status,
        // eslint-disable-next-line no-unused-vars
        txHash
    } = notification;

    let link = null;
    let text = t(translateKey, {textProperty});

    if(type === "expenses"){
        if(payload){
            const [
                // eslint-disable-next-line no-unused-vars
                role,
                expenseTabOrId
            ] = payload.split(":");
            link = txHash ? `${netWorkScanLink}${txHash}` : `/transactions/${expenseTabOrId}`;
        }

    }else if(type === "kyc"){
        if(payload){
            const [
                // eslint-disable-next-line no-unused-vars
                role,
                userId
            ] = payload.split(":");
            link = `/users/${userId}`;
        }
    }else if(type === "card"){
        const [
            // eslint-disable-next-line no-unused-vars
            role,
            cardId
        ] = payload.split(":");
        link = `/cards/${cardId}`;
    }

    const [timeAgo, setTimeAgo] = useState(null);

    useEffect(() => {

        if(createdAt){
            const date = moment(createdAt).toDate();
            setTimeAgo(date);
            setInterval(() => {
                setTimeAgo(date); // update seconds counter 1 time per minute
            }, 1000*60);
        }

    },[createdAt]);

    let choosenIcon = null;
    if(status === "failure"){
        choosenIcon = <span className={`${classes.itemChoosen} bg-red`}><CloseSVG /></span>
    }else if(status === "success"){
        choosenIcon = <span className={`${classes.itemChoosen} bg-green`}><CheckSVG /></span>
    } else {
        choosenIcon = <span className={`${classes.itemChoosen} bg-default`}><InfoSVG /></span>
    }

    const timeAgoBlock = timeAgo ? <span className={classes.timeAgo}>{getTimeAgo(timeAgo)}</span> : null;

    return (
        <div className={`${classes.itemNotif} ${isDisabled ? 'disabled' : ''}`}>
            {choosenIcon}
            <div className={classes.itemContent}>

                <div className={classes.itemContentdetails}>
                    {text}
                    {link ? (
                        <Link
                            to={link}
                            className={classes.itemLink}
                            onClick={() => {
                                onClose();
                                window.open(link, "_blank", "noopener,noreferrer");
                            }}
                        >
                            {' '}
                        </Link>
                    ) : ''}
                </div>

                {/* <div className={classes.itemExtra}>
                    {timeAgoBlock}
                </div> */}
                {/* {link ? (
                    <ChainIcon onClick={()=> {
                        onClose();
                        window.open(link, "_blank", "noopener,noreferrer");
                    }}/>
                ) : ''} */}
            </div>
            <div className={classes.itemExtra}>
                {timeAgoBlock}
            </div>
        </div>
    )
}

export default Item

import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ReactComponent as NotificationSVG } from "../../../../assets/notification.svg"
import Api from "../../../../services/api";
import {handleErrorMessage} from "../../../Messages/actions";
import {useDispatch} from "react-redux";
import HeadNotification from './Head';
import ContentNotification from './Items';
import { isDarkModeActive } from '../../../App/App';

const service = new Api();
const useStyles = makeStyles(theme =>({
    notification: {
        position: 'relative',
        marginRight: '24px',
        // marginRight: '24px',
        [theme.breakpoints.down('767')]: {
            position: 'static'
        },
        '&.active $body': {
            visibility: 'visible',
            opacity: '1',
            transform: 'translateY(0)'
        },
        '&.active $head svg': {
            fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
        }

    },
    head: {
        width: '48px',
        height: '48px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            border: (props) => props.isDarkModeActive ? '2px solid var(--dark-secondary)' : '2px solid var(--white)',
            background: 'var(--orange-pink)',
            opacity: '0',
            visibility: 'hidden',
        },
        '& svg': {
            width: '24px',
            height: '24px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
        },
        "&:hover svg": {
            fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',

        }
    },
    headCursor: {
        cursor: 'pointer',

        '&:before': {
            opacity: '1',
            visibility: 'visible'
        },
        '&:hover svg': {
            fill: 'var(--dark-secondary)'
        }
    },
    body: {
        position: 'absolute',
        top: 'calc(100% + 18px)',
        border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
        borderRadius: '16px',
        boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
        background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
        visibility: 'hidden',
        opacity: '0',
        transform: 'translateY(3px)',
        transition: 'all .2s',
        padding: '12px 24px 24px',
        width: '392px',
        right: '-92px',
        zIndex: '10',
        [theme.breakpoints.down('1339')]:{
            top: 'calc(100% + 15px)',
            right: '-73px'
        },
        [theme.breakpoints.down('767')]:{
            top: 'calc(100% - 80px)',
            left: '16px',
            right: '16px',
            width: 'auto',
            padding: '12px 16px 20px'
        },

        "&:before": {
            content: `""`,
            position: 'absolute',
            bottom: '100%',
            width: '20px',
            height: '10px',
            right: '105px',
            background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")` : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto`,
            [theme.breakpoints.down('1339')]:{
                right: '88px'
            },
            [theme.breakpoints.down('767')]: {
                right: '95px'
            }
        }
    },
}));

const Notifications = (props) => {
    const {settings} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [open, setOpen] = useState({
        isOpenActive: false
    });

    const handleOpenNotification = async () => {
        setOpen({ ...open, isOpenActive: !open.isOpenActive })
    }

    const handleOpenNotificationAway = async () => {
        setOpen({ ...open, isOpenActive: false })
    }

    const fetchMessages = async () => {
        setIsDisabled(true);
        try{
            const response = await service.getNotifications();
            setNotifications(response.data);
            setIsDisabled(false);
        }catch (e) {
            console.log(e);
            setIsDisabled(false);
        }
    }

    const onDeleteMessage = async (id) => {
        setIsDisabled(true);
        try {
            await service.deleteNotification(id);

            const notificationsCopy = [...notifications];

            const deleteIndex = notificationsCopy.findIndex((item) => item._id === id);

            if(deleteIndex !== -1){
                notificationsCopy.splice(deleteIndex, 1);
                setNotifications(notificationsCopy);
            }
            setIsDisabled(false);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
            setIsDisabled(false);
        }
    }

    useEffect(() => {
        (async() => {
            await fetchMessages();
        })();

        setInterval(fetchMessages, 1000*60);

    },[]);

    let notifComponent = (
            <>
                <ClickAwayListener
                    onClickAway={handleOpenNotificationAway}
                >
                    <div className={`${classes.notification} ${open.isOpenActive ? 'active' : null } `}>
                        <div
                            className={`${classes.head} ${notifications.length > 0 ? classes.headCursor : null }`}
                            onClick={handleOpenNotification}
                        >
                            <NotificationSVG />
                        </div>

                        <div className={classes.body}>
                            <HeadNotification
                                counter={notifications.length}
                                setNotifications={setNotifications}
                                setIsDisabled={setIsDisabled}
                                settings={settings}
                            />

                            <ContentNotification
                                notifications={notifications}
                                deleteMessage={onDeleteMessage}
                                onClose={() => setOpen({ ...open, isOpenActive: false })}
                                isDisabled={isDisabled}
                                setIsDisabled={setIsDisabled}
                                settings={settings}
                                setNotifications={setNotifications}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            </>
        )

    return (
        <>
            {notifComponent}
        </>
    )
}

export default Notifications

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import BackButton from "../../../../BackButton";
import useForm2 from "../../../../../hooks/useForm2";
import {setMessage} from "../../../../Messages/actions";
import Api from "../../../../../services/api";
import {Can} from "../../../../../Context/Can";
import ProjectForm from "../../../../ProjectForm";
import {makeStyles, Typography, Portal, Button} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },

    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    item: {
        position: 'relative'
    },
}))



const AddProject = (props) => {
    const {underBarRef, history, groups, settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyle();

    const dispatch = useDispatch();
    const isMounted = useMounted();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validate = (values) => {
        const errors = {};
        const regex = getAlphaNumericRegex();

        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }

        if (values["accountingCode"] && !regex.test(values["accountingCode"].toString())) {
            errors.accountingCode = t("errors.notValid", {field:"$t(formFields.accountingCode)"});
        }

        return errors;
    };

    const submit = async () => {
        setIsSubmitting(true);
        try {
            await service.createProject(values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/projects");
        } catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }


    useEffect(() => {
        fillFormValues({active:true}); // active by default if create new
    },[]);

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <ProjectForm
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        values={values}
                        groups={groups}
                        errors={errors}
                        settingsDarkMode={settingsDarkMode}
                    />

                    <Can I="create" a={"project"}>
                        <Button onClick={handleSubmit} disabled={isSubmitting}>
                            {t("formFields.create")}
                        </Button>
                    </Can>
                </div>
            </div>
        </>

    );
};

export default AddProject;

import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import ToggleAction from "../ToggleActions/ToggleAction";
import getCardIsDisabled from "../../../../../../../helpers/getCardIsDisabled";
import {useTranslation} from "react-i18next";
import {subject} from "@casl/ability";
import {Box, makeStyles} from "@material-ui/core";
const service = new Api();
const {blockUnblockCard} = service;

const useStyles = makeStyles({
    switchWrapper: {
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track':{
            backgroundColor:"#FD5B60"
        }
    },
});

const BlockUnblock = (props) => {
    const classes = useStyles();

    const {card, handleErrorMessage, setMessage, ability, setCard} = props;

    const [isDisabled, setIsDisabled] = useState(false);

    const [isBlocked, setIsBlocked] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if(card){
            const isCardBlocked = card.currentStatCode !== "00";
            setIsBlocked(isCardBlocked);

            const isCardDisabled = getCardIsDisabled(card.currentStatCode);
            setIsDisabled(isCardDisabled);
        }

    },[card]);

    const onChangeHandler = async (value) => {
        const actionName = isBlocked ? "unblock" : "block";
        setIsDisabled(true);
        try {
            const response = await blockUnblockCard(card._id, actionName);
            setCard((oldCard) => ({
                ...oldCard,
                ...response.data,
            }));
            setIsBlocked(value);
            setMessage("success", 'success');
        }catch (e) {
            console.log(e)
            handleErrorMessage(e);
        }finally {
            setIsDisabled(false);
        }
    };

    const canSetDisabled = () => {
        if(card.user && card.company){
            return ability.can("blockUnblock", subject("card", {user:card.user.id, company:card.company.id}));
        }
        return false;
    }


    return (
        <Box className={classes.switchWrapper}>
            <ToggleAction
                label={t("formFields.freezeCard")}
                name={"isBlocked"}
                helpText={t("card.blockCardText")}
                onChangeHandler={(name, value) => onChangeHandler(value)}
                isDisabled={!canSetDisabled() || isDisabled}
                value={isBlocked}
            />
        </Box>
    );
};

export default BlockUnblock;

import React from 'react';
import {ReactComponent as Radio1} from "../../../assets/radio1.svg";
import {ReactComponent as Radio0} from "../../../assets/radio0.svg";
import Radio from "@material-ui/core/Radio";
import {makeStyles} from "@material-ui/core";

const RadioField = (props) => {
    const {disabled} = props;

    const useStyles = makeStyles({
        formControlLabel: {
            "&.Mui-disabled .MuiIconButton-label":{
                opacity: disabled ? .5 : 1
            }
        }
    });

    const classes = useStyles();

    return (
        <Radio
            checkedIcon={<Radio1/>}
            icon={<Radio0/>}
            className={classes.formControlLabel}
            {...props}
        />
    );
};

export default RadioField;

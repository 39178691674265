import imageCompression from 'browser-image-compression';

const compressImage = async (file, opts={}) => {
    if(!file){
        throw new Error("No file")
    }
    const {type, name} = file;

    if(file.size/1024 < 100){
        // if image is less than 100 KB - do nothing
        return file
    }

    // console.log(`originalFile size ${(file.size / 1024 / 1024).toFixed(2)} MB`);

    const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1400,
        useWebWorker: true,
        ...opts
    };
    const compressedFile = await imageCompression(file, options);

    // console.log(`compressedFile size ${(compressedFile.size / 1024 / 1024).toFixed(2)} MB`); // smaller than maxSizeMB

    return new File([compressedFile], name, {type})

}

export default compressImage

import React from 'react';
import ToggleActions from "../ToggleActions";
import BlockUnblock from "../BlockUnblock";
import Grid from "@material-ui/core/Grid";

const CardToggles = (props) => {
    const {setMessage, handleErrorMessage, card, setCard, ability} = props;

    return (
        <Grid container spacing={4}>
            {card.type === "physical" && (
                <ToggleActions
                    setCard={setCard}
                    setMessage={setMessage}
                    handleErrorMessage={handleErrorMessage}
                    card={card}
                    ability={ability}
                />
            )}
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <BlockUnblock
                    card={card}
                    setCard={setCard}
                    setMessage={setMessage}
                    handleErrorMessage={handleErrorMessage}
                    ability={ability}
                />
            </Grid>

        </Grid>
    );
};

export default CardToggles;

import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {fetchCards} from "./actions";

import {withRouter} from "react-router-dom";
import checkClick from "../../../../../../../helpers/checkClick";
import FormatDate from "../../../../../../FormatDate";
import Pagination from "../../../../../../Pagination";
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import VolveMaterialTable from "../../../../../../VolveMaterialTable";
import ColumnCard from "../../../../Cards/CardsTable/ColumnCard";
import ColumnEmployee from "../../../../Cards/CardsTable/ColumnEmployee";
import isCardDeleted from "../../../../../../../helpers/isCardDeleted";
import FormatMoney from "../../../../../../FormatMoney";
import BackButton from "../../../../../../BackButton";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    tableCards: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}));

const AccountCardsTable = (props) => {
    const classes = useStyle();

    const {id, fetchCards, userAccountCards, dateFormat, underBarRef, backPath, accountTabs} = props;
    const {t} = useTranslation();

    const {cards:{result, info={}}} = userAccountCards;
    const {page, total, pageSize} = info;

    const [isLoading, setIsLoading] = useState(false);
    const [isMount, setIsMount] = useState(false);

	useEffect(() => {
        (async() => {
            setIsLoading(true);
            await fetchCards(id);
            setIsLoading(false);
        })();

    },[fetchCards]);

    useEffect(() => {
        setIsMount(true);
    },[]);

    const handleChangePage = async (event, newPage) => {
        setIsLoading(true);
        await fetchCards(id, {page:newPage});
        setIsLoading(false);
    }

    const rowHandlerClick = (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            props.history.push({pathname: `/cards/${rowData._id}`, state: {backPath: props.match.url}})
        }
    }

    const columns = [
        {
            title: t('thead.card'),
            render: (row) => <ColumnCard {...row}/>
        },
        {
            title: t('thead.cardType'),
            render: ({type}) => type
        },
        {
            title: t('thead.validTo'),
            field: 'validTo',
            render: (row) => isCardDeleted(row) ? "" : <FormatDate dateFormat={dateFormat}>{row.validTo}</FormatDate>
        },
        {
            title: t('thead.employeeName'),
            field: 'user',
            render: (row) => <ColumnEmployee {...row}/>
        },
        {
            title: t('thead.monthLimit'),
            field: 'monthLimit',
            align:'right',
            render: (row) => isCardDeleted(row) ? "" : <FormatMoney>{row.monthLimit}</FormatMoney>
        },
        {
            title: t('thead.remainingBalance'),
            align:'right',
            render: (row) => {
                if(isCardDeleted(row))  return "";

                const {cardRemainingBalance} = row;
                return cardRemainingBalance ? <FormatMoney>{cardRemainingBalance}</FormatMoney> : '';
            }
        },

    ];


    return (
        <>
            {accountTabs}

            <div className={`${classes.tableCards}`}>
                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={result}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </>
    );
};
const mapStateToProps = ({user, userAccountCards}) => {
	const {regional={}} = user.settings;
	const {dateFormat} = regional;
	return {
		isAuthenticated: user.isAuthenticated,
		userAccountCards,
		dateFormat
	}
}

export default connect(
	mapStateToProps,
	{fetchCards}
)(withRouter(AccountCardsTable));

import React from 'react';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core";
import { FormLabel } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CloseThin} from "../../../assets/closeThin.svg";
import {ReactComponent as Pen} from "../../../assets/pen.svg";
import useInlineDocument from "../../../hooks/useInlineDocument";

const useStyles = makeStyles(theme => ({
    urlButton: {
        fontWeight: 400,
        paddingLeft:"0px",
        paddingRight:"0px",
        paddingTop:"1px",
        paddingBottom:"1px",
        fontSize:"15px",
        maxWidth: "80%",
        width:"auto",
        "&:hover": {
            backgroundColor: "inherit",
            textDecoration:"underline"
        },
        "& .MuiButton-label": {
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }
    },
    textCenter: {
        textAlign: "center"
    },
    innerContent: {
        width: "460px",
        maxWidth: "100%",
        paddingTop:0,
        paddingBottom:0
    },
    deleteIcon:{
        "& svg path":{
            stroke:"#2c2c36"
        }
    },
    label: {
        marginBottom: "12px",
        display:"block"
    },
    uploadBtn: {
        fontWeight:400,
        padding:"0",
        fontSize:"15px",
        "&:hover": {
            backgroundColor:"inherit",
            textDecoration:"underline"
        }
    },
    acceptedFormats: {
        marginTop: "4px"
    },
    actions:{
        right:0
    }
}));


const FileUploaderInline = (props) => {

    const {
        label,
        value,
        error,
        disabled,
        handleChange,
        fieldName,
        acceptedDocuments=[".png",".jpeg",".jpg",".pdf"],
        buttonLabel="formFields.uploadDocument"
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();


    const handleUpload = (e) => {
        if(e.target.files && e.target.files[0]){
            handleChange(fieldName, e.target.files[0]);
        }else{
            handleChange(fieldName);
        }
    };

    const onDelete = () => {
        handleChange(fieldName, undefined)
    }

    const {onDownloadDocument, isDownloading, fileName} = useInlineDocument(value);

    return (
        <>
            {label && (<FormLabel className={classes.label}>{label}</FormLabel>)}
            <Box display="flex" flexDirection={"column"} position={"relative"}>
                {fileName ? (
                    <>
                        <ListItemText>
                            <Button
                                onClick={() => onDownloadDocument()}
                                disabled={isDownloading || disabled}
                                color="secondary"
                                className={classes.urlButton}
                                disableTouchRipple
                            >
                                {fileName}
                            </Button>
                        </ListItemText>
                        <ListItemSecondaryAction className={classes.actions}>
                            <IconButton edge="end" onClick={() => onDelete()} className={classes.deleteIcon} disabled={isDownloading || disabled}>
                                <CloseThin/>
                            </IconButton>
                            <IconButton
                                edge="end"
                                component="label"
                                variant={"contained"}
                                disabled={isDownloading || disabled}
                            >
                                <input type="file"
                                       name={fieldName}
                                       accept={acceptedDocuments.join(",")}
                                       onChange={handleUpload}
                                       style={{display: "none"}}
                                />
                                <Pen/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </>
                ) : (
                    <Box>
                        <Button
                            component="label"
                            color="primary"
                            disableTouchRipple
                            disabled={disabled}
                            className={classes.uploadBtn}
                        >
                            <input type="file"
                                   name={fieldName}
                                   accept={acceptedDocuments.join(",")}
                                   onChange={handleUpload}
                                   style={{display: "none"}}
                            />
                            {t(buttonLabel)}
                        </Button>
                        <FormHelperText className={classes.acceptedFormats}>{t("formFields.acceptedFormats")} {acceptedDocuments.join(", ").toUpperCase()}</FormHelperText>
                    </Box>
                )}
            </Box>
            {!!error && <FormHelperText error>{error}</FormHelperText>}
        </>

    );
};

export default FileUploaderInline;

import * as actionTypes from "../../../../store/actionTypes";

const initialState = {
    cards:{
        result:[],
        info:{
            fields:["maskedPAN", "account", "validTo", "monthLimit"],
            page: 0,
            total: 0,
            pageSize: 0
        }
    }
}

const userCards = (state=initialState, action) => {
	switch (action.type) {
        case actionTypes.CARDS_FETCH_SUCCESS :
            return {
                ...state,
                cards:{
                    result: action.payload.info.page > 1 ? [...state.cards.result, ...action.payload.result] : action.payload.result,
                    info:{
                        ...action.payload.info,
                    }

                }
            }
        case actionTypes.CARDS_FETCH_FAILURE :
            return {
                ...state,
                cards:{
                    result:[]
                }
            }
        case actionTypes.CARDS_CLEAN :
            return {
                ...state,
                cards:{
                    result:[],
                    info:{
                        ...state.cards.info,
                    }
                }
            }
        case actionTypes.USER_LOGOUT:
            return initialState;
    }
    return state
}

export default userCards


import {useEffect, useRef, useState} from "react";

const useBeforeUnload = () => {
    const [valuesWasChanged, setValuesWasChanged] = useState(false);

    const wasChangedRef = useRef();

    useEffect(() => {
        wasChangedRef.current = valuesWasChanged;
    });
    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnload);
        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        }
    }, []);

    const beforeUnload = (e) => {
        if(wasChangedRef.current){
            e.preventDefault();
            e.returnValue = true;
        }
    }

    return [valuesWasChanged, setValuesWasChanged]
}

export default useBeforeUnload

import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../components/Messages/actions";
import {useState} from "react";
import openBlobFromUrl from "../helpers/openBlobFromUrl";


const useInlineDocument = (value) => {
    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState(false);


    const onDownloadDocument = () => {
        if(value instanceof Blob){
            const blobUrl = window.URL.createObjectURL(value);
            window.open(blobUrl, '_blank');
        }else if(value && (typeof value === "string")){
            setIsDownloading(true);

            openBlobFromUrl(value).catch((e) => {
                console.log(e);
                dispatch(handleErrorMessage(e));
            }).finally(() => {
                setIsDownloading(false);
            });

        }else{
            dispatch(handleErrorMessage({message:"errors.somethingWentWrong"}))
        }
    }


    const getFileName = (value) => {
        let fileName = "";
        if(!value){
            return fileName;
        }
        if(value instanceof File){
            fileName = value.name;
        }else{
            fileName = value.split("/").pop();
        }
        return fileName
    }

    return {
        onDownloadDocument,
        isDownloading,
        fileName:getFileName(value)
    }

}

export default useInlineDocument

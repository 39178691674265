import React from 'react';
import Box from "@material-ui/core/Box";
import CheckBoxField from "../formElements/CheckBoxField";
import TermsOfServiceLink from "../TermsOfServiceLink";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(theme => ({
    termWrapper: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        gap: '4px',
        alignItems: 'center'
    }
}));

const TermsAndConditionsField = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {values, errors, handleChange} = props;
    return (
        <div className={classes.termWrapper}>
            <CheckBoxField
                onCheckboxChange={(val) => handleChange("termsAndConditions", val)}
                value={values["termsAndConditions"]}
                name={"termsAndConditions"}
                label={t("formFields.accept")}
                error={errors["termsAndConditions"]}
            />

            <Box component={"span"} className={classes.termWrapperAccept}><TermsOfServiceLink/></Box>
        </div>
    );
};

export default TermsAndConditionsField;

/*
	convert string symbols to number (string).
	comma "," is used as decimal separator.
	uses in form inputs
	example: 195,22
 */

const stringAsNumber = (value, decimal) => {
	let newValue = "";
	if(value){
		const convertedValue = String(value).match(/([-]?[\d]+[\.\,]?[\d?]?[\d]?)/);
		if(convertedValue && convertedValue[1]){
			newValue = convertedValue[1];
		}else{
			newValue = "";
		}
	}

	return newValue.replace(/\./, decimal ? decimal : ",");
};

export default stringAsNumber

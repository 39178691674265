export const DRAWER_MAIN_TOGGLE = 'DRAWER_MAIN_TOGGLE';
export const DRAWER_MAIN_VIEW_PERMISSIONS = 'DRAWER_MAIN_VIEW_PERMISSIONS';

export const GROUPS_FETCH_SUCCESS = 'GROUPS_FETCH_SUCCESS';
export const GROUPS_FETCH_FAILURE = 'GROUPS_FETCH_FAILURE';

export const BRANCHES_FETCH_SUCCESS = 'BRANCHES_FETCH_SUCCESS';
export const BRANCHES_FETCH_FAILURE = 'BRANCHES_FETCH_FAILURE';

export const CARDS_FETCH_SUCCESS = 'CARDS_FETCH_SUCCESS';
export const CARDS_FETCH_FAILURE = 'CARDS_FETCH_FAILURE';
export const CARDS_CLEAN = 'CARDS_CLEAN';

export const ACCOUNTS_FETCH_SUCCESS = 'ACCOUNTS_FETCH_SUCCESS';
export const ACCOUNTS_FETCH_FAILURE = 'ACCOUNTS_FETCH_FAILURE';


export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_DATA = 'USER_UPDATE_DATA';
export const USER_UPDATE_COMPANY = 'USER_UPDATE_COMPANY';
export const USER_UPDATE_COMPANY_ADVANCED_SETTINGS = 'USER_UPDATE_COMPANY_ADVANCED_SETTINGS';
export const USER_UPDATE_CONFIGURABLE_PERMISSIONS = 'USER_UPDATE_CONFIGURABLE_PERMISSIONS';

export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILURE = 'USERS_FETCH_FAILURE';


export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';


export const ACCOUNT_CARDS_SUCCESS = 'ACCOUNT_CARDS_SUCCESS';
export const ACCOUNT_CARDS_FAILURE = 'ACCOUNT_CARDS_FAILURE';

export const FBAS_FETCH_SUCCESS = 'FBAS_FETCH_SUCCESS';
export const FBAS_FETCH_FAILURE = 'FBAS_FETCH_FAILURE';
export const FBAS_UPDATE = 'FBAS_UPDATE';

export const TAB_SET = 'TAB_SET';
export const TAB_CHANGE = 'TAB_CHANGE';
export const INIT_SETTING = 'INIT_SETTING';

import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import clsx from 'clsx';
import Skeleton from "@material-ui/lab/Skeleton";
import { ReactComponent as CardVolveLogo} from '../../../../../../assets/cardVolveLogo.svg'
import { ReactComponent as CardVisaLogo} from '../../../../../../assets/cardVisaLogo.svg'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: "10px",
        width:"260px",
        height:"164px",
        position:"relative",
        color:"#354052",
        fontWeight:300,
        display: "flex",
        flexDirection:"column",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        marginBottom:"38px",
        background:'rgb(138 150 160 / 20%)',
    },
    virtualCard: {
        background: "linear-gradient(60.91deg, rgba(144, 166, 210, 0.4) 0%, rgba(144, 166, 210, 0) 46.58%)",
    },
    physicalCard: {
        background: "linear-gradient(60.91deg, rgba(36, 218, 196, 0.49) 0%, rgba(36, 218, 196, 0) 46.58%)",
    },
    skeleton: {
        position:"absolute",
        transform: "scale(1)",
        top:0,
        left:0,
        right:0,
        bottom:0
    },
    top:{
        padding: "10px",
        display:"flex",
        justifyContent: "flex-end"
    },
    middle: {
        flexGrow: 1,
        width: "100%",
        paddingLeft:"18px",
        paddingRight:"20px",
        fontSize:"16px",
        display:"flex",
        flexDirection: "column",
        '& > div:first-child':{
            paddingTop:"10px"
        },
        '& > div:last-child':{
            marginTop:"auto"
        },
    },
    bottom:{
        paddingLeft:"19px",
        paddingRight: "10px",
        paddingBottom:"5px",
        paddingTop:"10px",
        display: "flex",
        flexWrap: "nowrap"
    },
    textLine:{
        whiteSpace: "nowrap",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    innerBottomLabel: {
        fontSize: "8px",
        lineHeight: "8px",
        textTransform: "uppercase",
    },
    innerBottomValue: {
        fontSize:"14px",
        lineHeight:"14px"
    },
    virtualLogo: {
        '& svg path':{
            fill:"#90A6D2"
        }
    },
    physicalLogo: {
        '& svg path':{
            fill:"#24DAC4"
        }
    },
    visa: {
        flexGrow:1,
        display: "flex",
        justifyContent:"flex-end",
        paddingTop: "6px"
    },
    bottomItems:{
        display:"flex"
    },
    innerBottomItem:{
        marginRight:"30px"
    },

}));


const Card = (props) => {
    const classes = useStyles();

    const {card, isLoading, cvv} = props;
    const {
        _id,
        embossName,
        endDate,
        maskedPAN,
        type,
        company,

    } = card;

    const {t} = useTranslation();

    let modifiedEmbossName = "";
    if(company && company.name && embossName){
        modifiedEmbossName = embossName.replace(new RegExp(company.name, "i"), "").trim();
    }

    const cardIsActive = card.currentStatCode === "00";

    return (
        <Box className={clsx(classes.card, {
            [classes.virtualCard]: (type === 'virtual' && cardIsActive),
            [classes.physicalCard]: (type === 'physical' && cardIsActive),
        })}>
            {isLoading && <Skeleton className={classes.skeleton}/>}
            <Box className={classes.top}>
                <Box className={clsx({
                    [classes.virtualLogo]: (type === 'virtual' && cardIsActive),
                    [classes.physicalLogo]: (type === 'physical' && cardIsActive),
                })}>
                    <CardVolveLogo/>
                </Box>
            </Box>
            <Box className={classes.middle}>
                {(company && type === "virtual") && <div className={classes.textLine}>{company.name}</div>}
                {modifiedEmbossName && <div className={classes.textLine}>{modifiedEmbossName}</div>}
                {maskedPAN && <div className={classes.textLine}>{maskedPAN}</div>}
            </Box>
            <Box className={classes.bottom}>
                <Box className={classes.bottomItems}>
                    <div className={classes.innerBottomItem}>
                        <div className={classes.innerBottomLabel}>{t("card.validThru")}</div>
                        <div>{endDate && endDate}</div>
                    </div>
                    <div className={classes.innerBottomItem}>
                        <div className={classes.innerBottomLabel}>CVV:</div>
                        <div className={classes.innerBottomValue}>{cvv}</div>
                    </div>
                </Box>
                <Box className={classes.visa}>
                    <CardVisaLogo/>
                </Box>
            </Box>
        </Box>
    );
};

export default Card;

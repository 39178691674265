import React, {useContext, useEffect, useState} from 'react';
import useMounted from "../../../../../hooks/useMounted";
import {useTranslation} from "react-i18next";
import AdvancedSettingsForm from "./AdvancedSettingsForm";
import useForm2 from "../../../../../hooks/useForm2";
import {AbilityContext, Can} from "../../../../../Context/Can";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {updateAdvancedSettings} from "../../../../User/actions";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import {Prompt} from "react-router-dom";
import {
    makeStyles,
    Portal,
    Typography
 } from '@material-ui/core';
 import { isDarkModeActive } from '../../../../App/App';

const service = new Api();

const useStyles = makeStyles(theme => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
}))

const Advanced = (props) => {
    const {t} = useTranslation();
    const {underBarRef, settingsDarkMode} = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const isMounted = useMounted();
    const dispatch = useDispatch();
    const ability = useContext(AbilityContext);
    const [isShowThreshold, setIsShowThreshold] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();

    const advancedSettings = useSelector(state => {
        const {userData} = state.user;
        const {company={}} = userData;
        const {advancedSettings = {}} = company;
        return advancedSettings
    });


    useEffect(() => {
        if(advancedSettings){
            const {
                expenseCategoryRequired,
                expenseCommentRequired,
                expenseCountryRequired,
                expenseMerchantIsRequired,
                expenseProjectRequired,
                expenseProjectShow,
                expenseReimbursableActive,
                expenseThreshold
            } = advancedSettings;

            fillFormValues({
                expenseCategoryRequired,
                expenseCommentRequired,
                expenseCountryRequired,
                expenseMerchantIsRequired,
                expenseProjectRequired,
                expenseProjectShow,
                expenseReimbursableActive,
                expenseThreshold,
                advancedSettingsExpenseAutoInvestmentActive: expenseThreshold ? true : false
            });
            if (expenseThreshold) {
                setIsShowThreshold(true)
            }
        }


    },[advancedSettings]);



    const submit = async () => {
          try{
              setIsLoading(true);
              const formValues = values
              if (!formValues?.advancedSettingsExpenseAutoInvestmentActive) {
                formValues.expenseThreshold = null
              }
              const response = await service.updateCompanyAdvancedSettings(values);
              const {advancedSettings} = response.data;
              dispatch(updateAdvancedSettings(advancedSettings));
              setValuesWasChanged(false);
              dispatch(setMessage(t("success"), 'success'));
          }catch (e) {
              console.log(e);
              dispatch(handleErrorMessage(e));
          }finally {
              setIsLoading(false);
          }

    }

    const validate = () => ({});

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );


    const handleChangeWithDetectChanges = (name, value) => {

        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true);
        }
        handleChange(name, value)
    }

    const checkDisabledField = (field) => {
        const hasAbility = ability.can("update", "company");

        if(field === "expenseProjectRequired" && values["expenseProjectShow"] === false){
            // disable field
            return true;
        }

        return !hasAbility;
    }

    return (
        <>
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <div className={`${classes.headTitle} card-title card-title--blue`}>
                        {t("advancedSettings.mainTitle")}
                    </div>
                </div>
                <Can I={"update"} a={"company"}>
                    <AdvancedSettingsForm
                        handleChange={handleChangeWithDetectChanges}
                        handleSubmit={handleSubmit}
                        values={values}
                        errors={errors}
                        checkDisabledField={checkDisabledField}
                        isShowThreshold={isShowThreshold}
                        setIsShowThreshold={setIsShowThreshold}
                        settingsDarkMode={settingsDarkMode}
                        isDisabled={isLoading}
                    />
                </Can>
            </div>

        </>
    );
};

export default Advanced;

import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../../../../Messages/actions";
import Api from "../../../../../../../../services/api";
import {FormControl, Grid} from "@material-ui/core";
import SelectField from "../../../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
const service = new Api();


const UserField = (props) => {
    const {handleChange, value, error, isDataFetching, setIsDataFetching} = props;
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        try {
            setIsDataFetching(true);
            const response = await service.getUsersAll();
            const mappedUsers = response.data.map((user) => {
                const {_id, firstName, lastName} = user;
                return {value: _id, label: `${firstName} ${lastName}`}
            });
            setUsers(mappedUsers);
        } catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsDataFetching(false);
        }
    }

    useEffect(() => {
        fetchUsers();
        return () => {
            // remove value if component unmount
            handleChange("user")
        }
    },[]);


    return (
        <div>
            <FormControl fullWidth error={!!error}>
                <SelectField
                    isLoading={isDataFetching}
                    label={t("formFields.user")}
                    placeholder={t("formFields.user")}
                    name={"user"}
                    value={value}
                    error={error}
                    options={users}
                    onSelectChange={(val) => handleChange("user", val)}
                />
            </FormControl>
        </div>
    );
};

export default UserField;

import React from 'react'

import MaterialUiDrawer from "@material-ui/core/Drawer/Drawer";
import {makeStyles, useTheme} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import {ReactComponent as CloseSVG} from '../../assets/closeInvesthub.svg'
import { isDarkModeActive } from '../App/App';

// const drawerWidth = 500;

const RightDrawer = ({isOpen, onClose, children, titleDrawer, settingsDarkMode}) => {


	const theme = useTheme();
	// const isPc = useMediaQuery(theme.breakpoints.up('md'));
    // const onMax1339 = useMediaQuery(theme.breakpoints.down('1339'));
    // const onMax1259 = useMediaQuery(theme.breakpoints.down('1259'));
    // const onMax1023 = useMediaQuery(theme.breakpoints.up('1023'));
    const onMax767 = useMediaQuery(theme.breakpoints.down('767'))
	const variant = onMax767 ? 'permanent' : 'temporary';

	const useStyles = makeStyles(theme => ({
		drawer: {
            "& .MuiBackdrop-root": {
                backgroundColor: (props) => props.isDarkModeActive ? 'rgba(39, 43, 48, 0.9)' : 'rgba(244, 244, 244, 0.8)',
            }
		},
		drawerOpen: {
            width: '100%',
            maxWidth: '408px',
            borderLeft: '0',
            boxShadow: '0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
            padding: '24px',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-secondary)' : 'var(--pale-grey)',
            [theme.breakpoints.down('1339')]: {
                padding: '16px'
            },
            [theme.breakpoints.down('1259')]: {
                alignItems: 'stretch',
            },
            [theme.breakpoints.down('767')]: {
                padding: '24px',
                maxWidth: '100%',
            },
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: 0,
		},
        filterOverlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: '25',
            background: 'rgba(244, 244, 244, 0.8)',
            // visibility: 'hidden',
            // opacity: '0',
            transition: 'all .2s',
        },
        head: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '24px',
        },
        headTitle: {
            position: 'relative',
            display: 'inline-block',
            paddingLeft: '32px',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '1.6',
            letterSpacing: '-.02em',
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            [theme.breakpoints.down('767')]: {
                fontSize: '18px'
            }
        },
        headClose: {
            marginLeft: '24px',
            borderRadius: '50%',
            border: 'none',
            width: '36px',
            height: '36px',
            padding: '0',
            cursor: 'pointer',
            background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)',
            transition: 'all .2s',
            '& svg': {
                width: '20px',
                height: '20px',
                transition: 'transform .2s, -webkit-transform .2s',
                fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            },
            "&:hover svg": {
                transform: 'rotate(90deg)',
            }
        }
	}));

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

	const openClose = {
		[classes.drawerOpen]: isOpen,
		[classes.drawerClose]: !isOpen,
	};

	return (
        <>
            <MaterialUiDrawer
                variant={variant}
                className={clsx(classes.drawer, openClose)}
                classes={{ paper: clsx(classes.drawerInner, openClose),}}
                open={isOpen}
                anchor='right'
                ModalProps={{ onBackdropClick: () => onClose() }}
            >
                <Box className={classes.head}>
                    {titleDrawer && (
                        <Box className={`${classes.headTitle} card-title card-title--red`}>
                            {titleDrawer}
                        </Box>
                    )}
                    <button type="button" onClick={onClose} className={classes.headClose}>
                        <CloseSVG />
                    </button>
                </Box>
                {children}
            </MaterialUiDrawer>

            {onMax767 && (
                <>
                    {(isOpen) && (
                        <Box className={classes.filterOverlay} onClick={onClose} />
                    )}
                </>
            )}
        </>
    )
}

export default RightDrawer

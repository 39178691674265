import React, {useCallback, useEffect, useState} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {ReactComponent as Pen} from "../../../../../../assets/pen.svg";
import {ReactComponent as CloseThin} from "../../../../../../assets/closeThin.svg";

import Fab from "@material-ui/core/Fab";
import {makeStyles} from "@material-ui/core";

import {useTranslation} from "react-i18next";
import ItemCell from "./ItemCell";
import ManuallyPopup from "./ManuallyPopup";

import Box from "@material-ui/core/Box";
import FormatDate from "../../../../../FormatDate";
import InvitePersonPopup from "./InvitePersonPopup";
import AddressCell from "./AddressCell";
import FullNameCell from "./FullNameCell";
import Fieldset from "../../../../../formElements/Fieldset";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DocumentCell from "./DocumentCell";


const useStyles = makeStyles(theme => ({
    deleteBtnWrapper:{
        '&[disabled] svg':{
            opacity:"0.5"
        }
    },
    fabButton: {
        boxShadow: "none",
        background: "none",
        width:"20px",
        height:"20px",
        minHeight:"20px",
        margin:"4px",
        "& svg path":{
            stroke:"#1e1e25"
        }
    },
    inviteBtn:{
        fontWeight:400,
        paddingLeft:"5px",
        paddingRight:"5px"
    },
    manuallyBtn:{
        marginRight:"10px"
    },
    table: {
        "& th": {
            padding: "12px"
        },
        "& th:first-child": {
            paddingLeft: 0
        },
        "& td": {
            padding: "12px"
        },
        "& td:first-child": {
            paddingLeft: 0
        }
    },
    documentText: {
        whiteSpace: "nowrap",
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color:"#354052"

    }

}));



const OfficersAndShareholders = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const [popupIdx, setPopupIdx] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const {handleChange, errors, values, titles, personTypes, dateFormat, countries, officersAndShareholdersIndexes, setOfficersAndShareholdersIndexes, idx, isReadOnly, isLoading, setStepFields, fetchData, skipIfNeedsOfficersAndShareholders, setMessage, proofOfAddressCategories, proofOfIdentityCategories} = props;

    useEffect(() => {
        skipIfNeedsOfficersAndShareholders();
    },[values])


    useEffect(() => {
        if(personTypes.length === 0){
            fetchData();
        }
    },[personTypes]);


    const onSave = (idx) => {
        if(!officersAndShareholdersIndexes.includes(idx)){
            // add new index if not exist
            setOfficersAndShareholdersIndexes((rowIdxes) => ([...rowIdxes, idx]));
        }
        if(isManuallyOpen){
            setIsManuallyOpen(false);
        }
        if(isInvitePersonOpen){
            setIsInvitePersonOpen(false);
        }
        setPopupIdx(null);
    }
    const onEdit = (idx) => {
        setPopupIdx(idx);
        setIsEditMode(true);

        if(values[`officersAndShareholders[${idx}][user]`]){
            setIsInvitePersonOpen(true);
        }else{
            setIsManuallyOpen(true);
        }

    }

    const getNewIndex = () => {
        if(officersAndShareholdersIndexes.length){
            const lastIndex = officersAndShareholdersIndexes[officersAndShareholdersIndexes.length-1]; // get last index value
            return lastIndex+1;
        }else{
            return 0;
        }
    }

    const addDetailsManually = () => {
        setIsEditMode(false);
        setPopupIdx(getNewIndex());
        setIsManuallyOpen(true);
    }

    const invitePerson = () => {
        setPopupIdx(getNewIndex());
        setIsInvitePersonOpen(true);
    }


    const onDeleteRow = (index) => {
        // remove values

        setOfficersAndShareholdersIndexes((officersAndShareholdersIndexes) => officersAndShareholdersIndexes.filter((idx) => idx !== index));

        handleChange(`officersAndShareholders[${index}][personType]`);
        handleChange(`officersAndShareholders[${index}][_id]`);
        handleChange(`officersAndShareholders[${index}][firstName]`);
        handleChange(`officersAndShareholders[${index}][lastName]`);

        if(!values[`officersAndShareholders[${index}][user]`]){
            handleChange(`officersAndShareholders[${index}][addrl1]`);
            handleChange(`officersAndShareholders[${index}][addrl2]`);
            handleChange(`officersAndShareholders[${index}][postalCode]`);
            handleChange(`officersAndShareholders[${index}][city]`);
            handleChange(`officersAndShareholders[${index}][country]`);
            handleChange(`officersAndShareholders[${index}][idNo]`);
            handleChange(`officersAndShareholders[${index}][dob]`);
            handleChange(`officersAndShareholders[${index}][nationality]`);
            handleChange(`officersAndShareholders[${index}][proofOfAddress]`);
            handleChange(`officersAndShareholders[${index}][proofOfAddressCategory]`);
            handleChange(`officersAndShareholders[${index}][proofOfIdentity]`);
            handleChange(`officersAndShareholders[${index}][proofOfIdentityCategory]`);
        }else{
            handleChange(`officersAndShareholders[${index}][user]`);
            handleChange(`officersAndShareholders[${index}][title]`);
            handleChange(`officersAndShareholders[${index}][email]`);
        }


    }

    const memoizedCallback = useCallback(handleChange , []);

    const [isManuallyOpen, setIsManuallyOpen] = useState(false);
    const [isInvitePersonOpen, setIsInvitePersonOpen] = useState(false);



    useEffect(()=> {

        const currentFields = [];

        if(officersAndShareholdersIndexes.length > 0){
            officersAndShareholdersIndexes.forEach((index) => {
                currentFields.push(`officersAndShareholders[${index}][personType]`);

                if(!values[`officersAndShareholders[${index}][user]`]){
                    currentFields.push(`officersAndShareholders[${index}][firstName]`);
                    currentFields.push(`officersAndShareholders[${index}][lastName]`);
                    currentFields.push(`officersAndShareholders[${index}][addrl1]`);
                    currentFields.push(`officersAndShareholders[${index}][addrl2]`);
                    currentFields.push(`officersAndShareholders[${index}][postalCode]`);
                    currentFields.push(`officersAndShareholders[${index}][city]`);
                    currentFields.push(`officersAndShareholders[${index}][country]`);
                    currentFields.push(`officersAndShareholders[${index}][idNo]`);
                    currentFields.push(`officersAndShareholders[${index}][dob]`);
                    currentFields.push(`officersAndShareholders[${index}][nationality]`);

                }else{
                    currentFields.push(`officersAndShareholders[${index}][user]`);
                }

                if(values[`officersAndShareholders[${index}][_id]`]){
                    currentFields.push(`officersAndShareholders[${index}][_id]`);
                }
            });
        }else{
            currentFields.push(`officersAndShareholders`);
        }

        setStepFields((fields) => {
            return {
                ...fields,
                [idx]:currentFields
            }
        })

    },[officersAndShareholdersIndexes]);


    const getCountryByCode = (code) => {
        const country = countries.find((country) => country.value === code);
        return country ? country.label : code;
    }


    return (
        <Fieldset title={t("kybFieldsetOfficersAndShareholders")}>
            <Grid container spacing={5}>
                <Grid item xs={12} style={{paddingBottom:"5px"}}>
                    <Typography variant={"body2"}>
                        {t("kybFieldsetOfficersAndShareholdersText")}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{paddingTop:"5px"}}>
                    <Box mb={5}>
                        <Box mb={5}>
                            {isManuallyOpen && (
                                <ManuallyPopup
                                    isEditMode={isEditMode}
                                    isOpen={isManuallyOpen}
                                    setIsOpen={setIsManuallyOpen}
                                    outerValues={{
                                        personType:values[`officersAndShareholders[${popupIdx}][personType]`],
                                        firstName:values[`officersAndShareholders[${popupIdx}][firstName]`],
                                        lastName:values[`officersAndShareholders[${popupIdx}][lastName]`],
                                        addrl1:values[`officersAndShareholders[${popupIdx}][addrl1]`],
                                        addrl2:values[`officersAndShareholders[${popupIdx}][addrl2]`],
                                        postalCode:values[`officersAndShareholders[${popupIdx}][postalCode]`],
                                        city:values[`officersAndShareholders[${popupIdx}][city]`],
                                        country:values[`officersAndShareholders[${popupIdx}][country]`],
                                        idNo:values[`officersAndShareholders[${popupIdx}][idNo]`],
                                        dob:values[`officersAndShareholders[${popupIdx}][dob]`],
                                        nationality:values[`officersAndShareholders[${popupIdx}][nationality]`],
                                        proofOfIdentity:values[`officersAndShareholders[${popupIdx}][proofOfIdentity]`],
                                        proofOfIdentityCategory:values[`officersAndShareholders[${popupIdx}][proofOfIdentityCategory]`],
                                        proofOfAddress:values[`officersAndShareholders[${popupIdx}][proofOfAddress]`],
                                        proofOfAddressCategory:values[`officersAndShareholders[${popupIdx}][proofOfAddressCategory]`],
                                    }}
                                    index={popupIdx}
                                    memoizedCallback={memoizedCallback}
                                    personTypes={personTypes}
                                    countries={countries}
                                    proofOfIdentityCategories={proofOfIdentityCategories}
                                    proofOfAddressCategories={proofOfAddressCategories}
                                    dateFormat={dateFormat}
                                    onSave={onSave}
                                />
                            )}
                            {isInvitePersonOpen && (
                                <InvitePersonPopup
                                    onSave={onSave}
                                    isEditMode={isEditMode}
                                    memoizedCallback={memoizedCallback}
                                    index={popupIdx}
                                    titles={titles}
                                    isOpen={isInvitePersonOpen}
                                    setIsOpen={setIsInvitePersonOpen}
                                    personTypes={personTypes}
                                    setMessage={setMessage}
                                    outerValues={{
                                        firstName:values[`officersAndShareholders[${popupIdx}][firstName]`],
                                        lastName:values[`officersAndShareholders[${popupIdx}][lastName]`],
                                        personType:values[`officersAndShareholders[${popupIdx}][personType]`],
                                        email:values[`officersAndShareholders[${popupIdx}][email]`],
                                        title:values[`officersAndShareholders[${popupIdx}][title]`],
                                    }}
                                />
                            )}

                            <Table aria-label="Funding Bank Accounts" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("formFields.type")}</TableCell>
                                        <TableCell>{t("formFields.fullName")}</TableCell>
                                        <TableCell>{t("formFields.address")}</TableCell>
                                        <TableCell>{t("formFields.idNo")}</TableCell>
                                        <TableCell>{t("formFields.dob")}</TableCell>
                                        <TableCell>{t("formFields.nationality")}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {officersAndShareholdersIndexes.map((index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <ItemCell
                                                        error={errors[`officersAndShareholders[${index}][personType]`]}
                                                        value={values[`officersAndShareholders[${index}][personType]`]}
                                                        isLoading={isLoading}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <FullNameCell
                                                        values={[
                                                            values[`officersAndShareholders[${index}][firstName]`],
                                                            values[`officersAndShareholders[${index}][lastName]`]
                                                        ]}
                                                        hasError={
                                                            errors[`officersAndShareholders[${index}][firstName]`]
                                                            || errors[`officersAndShareholders[${index}][lastName]`]
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <AddressCell
                                                        values={[
                                                            values[`officersAndShareholders[${index}][addrl1]`],
                                                            values[`officersAndShareholders[${index}][addrl2]`],
                                                            values[`officersAndShareholders[${index}][postalCode]`],
                                                            values[`officersAndShareholders[${index}][city]`],
                                                            getCountryByCode(values[`officersAndShareholders[${index}][country]`]),
                                                        ]}
                                                        hasError={
                                                            errors[`officersAndShareholders[${index}][addrl1]`]
                                                            || errors[`officersAndShareholders[${index}][addrl2]`]
                                                            || errors[`officersAndShareholders[${index}][postalCode]`]
                                                            || errors[`officersAndShareholders[${index}][city]`]
                                                            || errors[`officersAndShareholders[${index}][country]`]
                                                        }
                                                    />
                                                    {!values[`officersAndShareholders[${index}][user]`] && (
                                                        <Box className={classes.documentText}>
                                                            <DocumentCell
                                                                error={errors[`officersAndShareholders[${index}][proofOfAddress]`]}
                                                                value={values[`officersAndShareholders[${index}][proofOfAddress]`]}
                                                                isLoading={isLoading}
                                                            />
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <ItemCell
                                                        error={errors[`officersAndShareholders[${index}][idNo]`]}
                                                        value={values[`officersAndShareholders[${index}][idNo]`]}
                                                        isLoading={isLoading}
                                                    />
                                                    {!values[`officersAndShareholders[${index}][user]`] && (
                                                        <Box className={classes.documentText}>
                                                            <DocumentCell
                                                                error={errors[`officersAndShareholders[${index}][proofOfIdentity]`]}
                                                                value={values[`officersAndShareholders[${index}][proofOfIdentity]`]}
                                                                isLoading={isLoading}
                                                            />
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <ItemCell
                                                        error={errors[`officersAndShareholders[${index}][dob]`]}
                                                        value={<>{values[`officersAndShareholders[${index}][dob]`] && <FormatDate dateFormat={dateFormat}>{values[`officersAndShareholders[${index}][dob]`]}</FormatDate>}</>}
                                                        isLoading={isLoading}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <ItemCell
                                                        error={errors[`officersAndShareholders[${index}][nationality]`]}
                                                        value={getCountryByCode(values[`officersAndShareholders[${index}][nationality]`])}
                                                        isLoading={isLoading}
                                                    />
                                                </TableCell>
                                                <TableCell align={"right"}>

                                                    {!values[`officersAndShareholders[${index}][user]`] && (
                                                        <Fab className={classes.fabButton}
                                                             onClick={() => onEdit(index)}
                                                        >
                                                            <Pen/>
                                                        </Fab>
                                                    )}
                                                    <Fab className={classes.fabButton}
                                                         onClick={() => onDeleteRow(index)}
                                                    >
                                                        <CloseThin/>
                                                    </Fab>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box>
                            <Button
                                color="secondary"
                                variant="contained"
                                className={classes.manuallyBtn}
                                disabled={isReadOnly}
                                onClick={() => addDetailsManually()}
                            >
                                {t("formFields.addDetailsManually")}
                            </Button>
                            <Button
                                color="secondary"
                                className={classes.inviteBtn}
                                disabled={isReadOnly}
                                onClick={() => invitePerson()}
                            >
                                {t("formFields.invitePerson")}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Fieldset>
    );
};

export default OfficersAndShareholders;

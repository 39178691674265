import { ENQUEUE_MESSAGE, CLOSE_MESSAGE, REMOVE_MESSAGE } from './actions';

const defaultState = [];

export default (state = defaultState, action) => {
	switch (action.type) {
		case ENQUEUE_MESSAGE:
			return [
				...state,
				{key: action.key, ...action.notification},
			];

		case CLOSE_MESSAGE:
			return state.map(notification => (
				(action.dismissAll || notification.key === action.key)
					? { ...notification, dismissed: true }
					: { ...notification }
			));
		case REMOVE_MESSAGE:
			return state.filter(notification => notification.key !== action.key)
		default:
			return state;
	}
};
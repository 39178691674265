import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {Can} from "../../../../../Context/Can";
import AddCategoryBtn from './AddCategoryBtn';
import ActionBtn from '../TopComponent/ActionBtn/ActionBtn';

const useStyle = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    headControl: {
        display: 'flex',
        marginLeft: 'auto',
    },
}))

const TopComponentInvesthub = (props) => {
    const {onImport, history, onDisableBatch, selectedCategories, settingsDarkMode} = props;
    const classes = useStyle();
    const {t} = useTranslation();

    return (
        <div className={classes.head}>
            <div className={`${classes.headTitle} card-title card-title--blue`}>
                {t("category.mainTitle")}
            </div>

            <div className={classes.headControl}>
                <Can I="create" a={"category"}>
                    <AddCategoryBtn history={history} onImport={onImport} settingsDarkMode={settingsDarkMode} />
                </Can>

                <Can I="update" a={"category"}>
                    <ActionBtn
                        onDisableBatch={onDisableBatch}
                        selectedCategories={selectedCategories}
                    />
                </Can>
            </div>
        </div>
    )
}

export default TopComponentInvesthub

import React from 'react';
import ExpenseTransactions from "../../../../../ExpenseForm/ExpenseTransactions";

const Transactions = (prop) => {
    const {expense} = prop;
    const {transactions} = expense;
    return (
        <>
            <ExpenseTransactions transactions={transactions}/>
        </>
    );
};

export default Transactions;

import React, {useEffect, useState} from 'react';
import useForm2 from "../../../../../../../hooks/useForm2";
import ForwardForSignatureForm from "./ForwardForSignatureForm";
import Api from "../../../../../../../services/api";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {setMessage, handleErrorMessage} from "../../../../../../Messages/actions";
import Fieldset from "../../../../../../formElements/Fieldset";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
const service = new Api();



const ForwardForSignature = (props) => {
    const {setMessage, setCurrentAction, alreadyDownloaded, description, title, setForwardEmail} = props;
    const [titles, setTitles] = useState([]);

    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = async () => {
        setIsSubmitting(true);
        try{
            await service.kybForwardForSignature(values);
            setIsSubmitting(false);
            if(setForwardEmail){
                setForwardEmail(values["email"])
            }
            setMessage("success", 'success');
            if(alreadyDownloaded){
                setCurrentAction("downloaded-forward-done");
            }else{
                setCurrentAction("forwarded");
            }
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const fetchData = async () => {
        const titlesPromise = service.getServiceData({dataFor: "userPrefixes"});
        setLoading(false);
        try {
            const responses = await Promise.all([
                titlesPromise
            ]);
            const [titlesResult] = responses;
            setTitles(titlesResult.data);

        }catch (e) {

            console.log(e);
            handleErrorMessage(e)
        }
    }


    useEffect(() => {
        fetchData();
    },[]);



    const validate = () => {
        const errors = {};
        if(!values["email"]){
            errors["email"] = t("errors.required", {field: "$t(formFields.email)"})
        }
        if(!values["firstName"]){
            errors["firstName"] = t("errors.required", {field: "$t(formFields.firstName)"})
        }
        if(!values["lastName"]){
            errors["lastName"] = t("errors.required", {field: "$t(formFields.lastName)"})
        }
        if(!values["title"]){
            errors["title"] = t("errors.required", {field: "$t(formFields.title)"})
        }
        return errors;
    }


    const { handleChange, handleSubmit, values, errors, fillFormValues, setServerErrors } = useForm2(
        submit,
        validate
    );

    return (
        <>
            {title && (
                <Fieldset title={title}>
                    <Grid container spacing={5}>
                        {description && (
                            <Grid item xs={12}>
                                <Typography variant="body2">{description}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Fieldset>
            )}
            <ForwardForSignatureForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                values={values}
                errors={errors}
                titles={titles}
                loading={loading}
                isSubmitting={isSubmitting}
            />

        </>
    );
};

export default connect(
    null,
    {setMessage, handleErrorMessage}
)(ForwardForSignature);

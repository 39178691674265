/*
convert base64 to file for FormData object
 */

const base64ToFile = (base64) => {
    const mime = base64.split(',')[0].split(':')[1].split(';')[0];
    const binary = atob(base64.split(',')[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(array)], {type: mime});

    return new File( [blob], 'image.jpg', {type: mime});
}

export default base64ToFile

import React from 'react';
import RadioFields from "../../../../../formElements/RadioFields";
import {useTranslation} from "react-i18next";
import {
    makeStyles,
    Box,
    FormHelperText,
    Tooltip
} from '@material-ui/core';
import {ReactComponent as InfoSVG} from "../../../../../../assets/infoInvesthub.svg"
import { isDarkModeActive } from '../../../../../App/App';

const useStyles = makeStyles(theme => ({
    settingsItem: {
        position: 'relative',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
    },

    settingsItemLast: {
        marginBottom: '0',
        paddingBottom: '0',
        borderBottom: 'none !important'
    },

    fieldGroup: {
        display: 'flex',
        paddingBottom: '16px',
        [theme.breakpoints.down('767')]: {
            flexFlow: 'column nowrap',
            gap: '12px',
        },
        "&:not(:last-child)": {
            marginBottom: '32px',
            borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-primary)',
            [theme.breakpoints.down('1023')]: {
                marginBottom: '24px'
            },
        }
    },

    fieldLabel: {
        marginRight: '24px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
        flex: '1',
        color: (props) => props.isDarkModeActive ? 'var(--very-light-pink-secondary)' :'var(--charcoal-primary)',
        "& svg": {
            top: '-1px',
            position: 'relative',
            display: 'inline-block',
            marginLeft: '4px',
            cursor: 'pointer',
            width: '16px',
            height: '16px',
            fill: (props) => props.isDarkModeActive ? 'var(--cool-grey)' : 'var(--dark-secondary)'
        }
    },

    fieldContent: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0',
            '&:last-child': {
                marginRight: '0'
            }
        }
    },

    item: {
        display: 'flex',
        paddingBottom: '16px',
        marginBottom: '32px',
        borderBottom: '1px solid var(--very-light-pink-secondary)',

        [theme.breakpoints.down('767')]: {
            display: 'block'
        }
    },

    itemTitle: {
        marginRight: '24px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
        color: 'var(--charcoal-primary)',
        [theme.breakpoints.down('767')]: {
            marginRight: '0',
            marginBottom: '12px',
            display: "block"
        }
    },
    itemContent: {
        marginLeft: 'auto',
        [theme.breakpoints.down('767')]: {
            marginLeft: '0',
            marginRight: 'auto'
        }
    },

}))

const Permissions = (props) => {
    const {role, handleChange, values, disabled, isLastIndex, settingsDarkMode} = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();
    const permissions = ['account', 'card', 'user', 'businessManagement'];

    const getOptions = () => ['no', 'view', 'viewAndEdit'].map((option) => ( {label: t(`userPermissions.${option}`), value: option}));

    return (
        <Box className={`${classes.settingsItem} ${isLastIndex ? classes.settingsItemLast : ""}`}>
            {permissions.map((permission) => {
                const fieldName = `configurablePermissions[${role}][${permission}]`;

                return (
                    <Box className={`${classes.fieldGroup}`} key={permission}>
                        <Box className={classes.fieldLabel}>
                            {t(`userPermissions.${permission}`)}
                            {permission === "card" && (
                                <Tooltip
                                    title={t("userPermissionsAccountText")}
                                >
                                    <InfoSVG />
                                </Tooltip>
                            )}
                        </Box>
                        <Box className={classes.fieldContent}>
                            <RadioFields
                                disabled={disabled}
                                options={getOptions()}
                                name={fieldName}
                                value={values[fieldName]}
                                onRadioChange={(val) => handleChange(fieldName, val)}
                                className={classes.itemRadio}

                            />
                        </Box>

                    </Box>
                )
            })}
        </Box>
    );
};

export default Permissions;

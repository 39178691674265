import React, {useState} from 'react';
import Fieldset from "../../../../../../formElements/Fieldset";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ForwardForSignature from "../ForwardForSignature";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../../services/api";
import CircularProgress from '@material-ui/core/CircularProgress'
// import * as loader from "../../../../../../../assets/animations/loader";
import SendingAnimationWrapper from "../../../../../../SendingAnimationWrapper";
const service = new Api();

const FinishingContent = (props) => {

    const {t} = useTranslation();
    const {handleErrorMessage, setCurrentAction, setForwardEmail} = props;
    const [isDownloading, setIsDownloading] = useState(false);


    const onDownload = async () => {
        setIsDownloading(true);
        try{
            await service.downloadKybDocument();
            setIsDownloading(false);
            setCurrentAction("downloaded");
        }catch (e) {
            handleErrorMessage(e);
            setIsDownloading(false);
        }
    }

    return (
        <>
            <Fieldset title={t("kybFieldsetDocumentStep")}>
                <Box position={"relative"}>
                    {isDownloading && (
                        <SendingAnimationWrapper>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                {/* <CircularProgress /> */}
                                <CircularProgress />
                            </Box>
                        </SendingAnimationWrapper>
                    )}
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {t("kybFieldsetDocumentStepTextDownload")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mr={2} component={"span"}>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    onClick={onDownload}
                                    disabled={isDownloading}
                                >
                                    {t("formFields.download")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Fieldset>
            <Fieldset>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {t("kybFieldsetDocumentStepTextForward")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ForwardForSignature setCurrentAction={setCurrentAction} setForwardEmail={setForwardEmail}/>
                    </Grid>
                </Grid>
            </Fieldset>
        </>
    );
};

export default FinishingContent;

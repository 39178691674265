import React, {useEffect, useState} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import EditExpense from "./EditExpense";
import History from "./History";
import {compose} from "redux";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../BackButton";
import TabsContent from "../../../../TabsContent";
import useMounted from "../../../../../hooks/useMounted";
import Transactions from "./Transactions";
import Api from "../../../../../services/api";
import {handleErrorMessage} from "../../../../Messages/actions";
import {useDispatch} from "react-redux";
import {
    makeStyles,
    Portal,
    Typography
} from "@material-ui/core";
import { isDarkModeActive } from '../../../../App/App';
const service = new Api();

const useStyles = makeStyles((theme) => ({
    // Page title.
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
        "& .MuiButton-root": {
            [theme.breakpoints.down('767')]: {
                width: '100%',
            }
        }
    },

}))

const Expense = (props) => {
    const {match, history, underBarRef, settingsDarkMode} = props;
    const dispatch = useDispatch();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const {params} = match;
    const {id} = params;

    const {t} = useTranslation();
    const linksTab = [
        {url:`${match.url}`,name:t("expense.expenseTab")},
        {url:`${match.url}/history`,name:t("expense.historyTab")}
    ];

    const [tabs, setTabs] = useState(linksTab);

    const isMounted = useMounted();
    const [expense, setExpense] = useState({});
    const [isExpenseLoading, setIsExpenseLoading] = useState(true);

    const {transactions} = expense;

    useEffect(() => {

        if(transactions && transactions.length){
            setTabs([
                ...linksTab,
                {url:`${match.url}/transactions`,name:t("expense.transactionsTab")}
            ])
        }
    },[transactions])

    const fetchExpense = async (id) => {
        try{
            setIsExpenseLoading(true);
            const response = await service.getExpense(id);
            setExpense(response.data);
        }catch (e){
            dispatch(handleErrorMessage(e))
        }finally {
            setIsExpenseLoading(false);
        }
    }

    useEffect(() => {
        fetchExpense(id);
    },[id]);

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {t("transaction.mainTitle")}
                    </Typography>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <BackButton/>

                    <TabsContent routes={tabs} settingsDarkMode={settingsDarkMode} />
                </div>

                <Switch>
                    <Route
                        exact
                        path={`${match.path}`}
                        render={() => <EditExpense
                            id={params.id}
                            history={history}
                            expense={expense}
                            setExpense={setExpense}
                            isExpenseLoading={isExpenseLoading}
                            settingsDarkMode={settingsDarkMode}
                        />}
                    />
                    <Route
                        exact
                        path={`${match.path}/history`}
                        render={() =>
                            <History
                                id={params.id}
                                settingsDarkMode={settingsDarkMode}
                            />
                        }
                    />
                    <Route
                        exact
                        path={`${match.path}/transactions`}
                        render={() => <Transactions expense={expense}/>}
                    />
                </Switch>
            </div>
        </>
    );
};

export default compose(
    withRouter,
)(Expense)


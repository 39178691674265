const getShortPan = (maskedPan) => {
    if(typeof maskedPan !== 'string') return '';

    const result = maskedPan.match(/[\d]+$/);
    if (!result) {
        return '';
    }
    return `***${result[0]}`;
}

export default getShortPan

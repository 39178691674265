import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles, Portal, Typography, Box} from "@material-ui/core";
import TabsContent from "../../../../../../TabsContent";
import ShortInfoItem from "../../../../../../ShortInfoItem";
import FormatMoney from "../../../../../../FormatMoney";
import BackButton from '../../../../../../BackButton';

const useStyles = makeStyles(theme => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },

    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
        "& .MuiButton-root": {
            [theme.breakpoints.down('767')]: {
                width: '100%',
            }
        }
    },
}))

const AccountTabs = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {url, account, backPath, settingsDarkMode, isMounted, underBarRefCurrent} = props;
    const {accountNumber, balance={}} = account;

    const {toCurrency, sumInCurrency} = balance;

    const routes = [
        {url:`${url}/details`, name:t("account.detailTab")},
    ];

    // eslint-disable-next-line no-unused-vars
    const rightContent =(
        <Box className={classes.rightContent}>
            <ShortInfoItem isLoading={account.isLoading} label={t('formFields.accountNo')} value={accountNumber}/>
            <ShortInfoItem isLoading={account.isLoading} label={t('account.totalBalance')} value={(
                <>{toCurrency} <FormatMoney>{sumInCurrency}</FormatMoney></>
            )}/>
        </Box>
    )

    return (
        <>
            {isMounted && (
                <Portal container={underBarRefCurrent}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {t("account.detailTab")}
                    </Typography>
                </Portal>
            )}

            <Box className={classes.head}>
                <BackButton backUrl={backPath} />
                <TabsContent routes={routes} settingsDarkMode={settingsDarkMode} />
            </Box>
        </>
    );
};

export default AccountTabs;

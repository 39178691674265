import React, {useEffect, useState} from 'react';
import useMounted from "../../../../../hooks/useMounted";
import useForm2 from "../../../../../hooks/useForm2";
import Box from "@material-ui/core/Box";
import isNumeric from "../../../../../helpers/isNumeric";
import {useTranslation} from "react-i18next";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useDispatch, useSelector} from "react-redux";
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import {Grid} from "@material-ui/core";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import TaxRateForm from "../../../../TaxRateForm";
const service = new Api();


const TaxRate = (props) => {
    const isMounted = useMounted();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const decimal = useSelector(state => {
        const {settings={}} = state.user;
        const {regional={}} = settings;
        return regional.decimal
    });

    const {underBarRef, match, history, countries, categories } = props;
    const {params} = match;
    const {id} = params;

    const [taxRate, setTaxRate] = useState({});

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const fetchTaxRate = async (id) => {
        try{
            setIsLoading(true);
            const response = await service.getTaxRate(id);
            setTaxRate(response.data);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchTaxRate(id);
    },[id]);


    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }
        if (!isNumeric(values.taxRate)) {
            errors.taxRate = t("errors.notValid", {field:"$t(formFields.taxRate)"});
        }
        if (!values.countries) {
            errors.countries = t("errors.required", {field:"$t(formFields.countries)"});
        }

        return errors;
    };


    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.updateTaxRate(id, values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/tax-rates");
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    useEffect(() => {
        if(Object.keys(taxRate).length){
            const {name, taxRate:taxRateFromServer, countries, categories, active} = taxRate;
            fillFormValues({name, taxRate:taxRateFromServer, countries, categories, active})
        }else{
            fillFormValues({active:true});
        }
    },[taxRate]);


    const onDeleteAction = async () => {
        try {
            setIsSubmitting(true);
            await service.deleteTaxRate(id);
            dispatch(setMessage("success", 'success'));
            history.push("/settings/tax-rates");
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    return (
        <Box paddingLeft={'24px'} paddingRight={'24px'}>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <BackButton/>
                </Portal>
            )}
            <Box mb={"20px"}>
                <TaxRateForm
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    values={values}
                    errors={errors}
                    loading={loading}
                    countries={countries}
                    categories={categories}
                    decimal={decimal}
                />
            </Box>
            <Grid container spacing={2}>
                <Grid item>
                    <Can I="update" a={"taxRate"}>
                        <Button onClick={handleSubmit} disabled={isSubmitting || loading} color="primary" variant="contained">
                            {t("formFields.update")}
                        </Button>
                    </Can>
                </Grid>
                <Grid item>
                    <Can I="delete" a={"taxRate"}>
                        <Button onClick={onDeleteAction} disabled={isSubmitting || loading} color="secondary" variant="contained">
                            {t("formFields.delete")}
                        </Button>
                    </Can>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TaxRate;

import React, {useEffect, useState} from 'react'
import {
    makeStyles,
    Button,
    FormControl
} from "@material-ui/core";
import {connect} from "react-redux";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {updateUserState} from "../../../../User/actions";

import {useTranslation} from "react-i18next";
import SelectField from "../../../../formElements/SelectField";
import useForm from "../../../../../hooks/useForm";
import {Prompt} from "react-router-dom";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import { isDarkModeActive } from '../../../../App/App';


const service = new Api();
const {updateCurrentUser} = service;

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative'
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    // Wrapper fields
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemFormSubmit: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
}));

const SettingsForm = (props) => {
	const {handleErrorMessage, setMessage, settings, updateUserState, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
	const [isDisabled, setIsDisabled] = useState(false);
	const {t} = useTranslation();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();

	const [isLoading, setIsLoading] = useState(false);

	const [currencies, setCurrencies] = useState([]);
	const [decimals, setDecimals] = useState([]);
	const [dateFormats, setDateFormats] = useState([]);
	const [distances, setDistances] = useState([]);
	const [networks, setNetworks] = useState([]);

	useEffect(() => {
		if(settings.regional){
			Object.keys(settings.regional).forEach((key) => {
				handleChange(key, settings.regional[key]);
			})
		}
    }, [settings]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {

                const response = await Promise.all([
                    service.getServiceData({dataFor: "currencies-all"}),
                    service.getServiceData({dataFor: "decimals"}),
                    service.getServiceData({dataFor: "dateformat"}),
                    service.getServiceData({dataFor: "distance"}),
                    service.getServiceData({dataFor: "network"}),
                ]);

                const [currenciesResult, decimalsResult, dateFormatsResult, distancesResult, networksResult] = response;
                const mappedCurrencies = currenciesResult.data.map(({value}) => ({label:value, value}));
                const mappedDecimals = decimalsResult.data.map(({value, name}) => ({label:name, value}));
                const mappedDateFormats = dateFormatsResult.data.map(({value, name}) => ({label:name, value}));
                const mappedDistances = distancesResult.data.map(({value, name}) => ({label:name, value}));
                const mappedNetworks = networksResult.data.map(({value, name}) => ({label:name, value}));
                setCurrencies(mappedCurrencies);
                setDecimals(mappedDecimals);
                setDateFormats(mappedDateFormats);
                setDistances(mappedDistances);
                setNetworks(mappedNetworks);
            }catch (e) {

                handleErrorMessage(e);
            }finally {
                setIsLoading(false);
            }

        })();
    },[]);


    const submit = async () => {
        setIsDisabled(true);
        const {currency, dateFormat, decimal, distance, network} = values;

        try {

            const dataValues = {settings:{regional:{currency, dateFormat, decimal, distance, network}}}

            const result = await updateCurrentUser(dataValues);
            const {data} = result;

            updateUserState(data);
            setValuesWasChanged(false);
            setMessage("successfullyUpdated", 'success');
            setIsDisabled(false);
        }catch (e) {
            setServerErrors(e);
            setIsDisabled(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values["decimal"]) {
            errors["decimal"] = t('errors.required', {field: t("formFields.decimal")});
        }
        if (!values["dateFormat"]) {
            errors["dateFormat"] = t('errors.required', {field: t("formFields.dateFormat")});
        }
        if (!values["distance"]) {
            errors["distance"] = t('errors.required', {field: t("formFields.distanceUnit")});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }

	return (
        <>
            <div className={classes.item}>
                <form onSubmit={handleSubmit} className={classes.itemForm}>
                    <Prompt
                        when={valuesWasChanged}
                        message={t("unsavedChanges")}
                    />
                    <div className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                        <div className={classes.itemSettings}>
                            <div className={classes.itemSettings}>
                                <div className={classes.itemRow}>
                                    <FormControl fullWidth className={classes.itemField} error={!!errors["decimal"]}>
                                        <SelectField
                                            label={t("formFields.decimal")}
                                            name={"decimal"}
                                            value={values['decimal']}
                                            options={decimals}
                                            onSelectChange={(val) => handleChangeWithDetectChanges("decimal", val)}
                                            isLoading={isLoading}
                                            error={errors["decimal"]}
                                            settings={settingsDarkMode}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth className={classes.itemField} error={!!errors["dateFormat"]}>
                                        <SelectField
                                            label={t("formFields.dateFormat")}
                                            name={"dateFormat"}
                                            value={values['dateFormat']}
                                            options={dateFormats}
                                            onSelectChange={(val) => handleChangeWithDetectChanges("dateFormat", val)}
                                            isLoading={isLoading}
                                            error={errors["dateFormat"]}
                                            settings={settingsDarkMode}
                                        />
                                    </FormControl>
                                </div>

                                <div className={classes.itemRow}>
                                    <FormControl fullWidth className={classes.itemField} error={!!errors["currency"]}>
                                        <SelectField
                                            label={t("formFields.defaultCurrency")}
                                            name={"currency"}
                                            value={values['currency']}
                                            options={currencies}
                                            onSelectChange={(val) => handleChangeWithDetectChanges("currency", val)}
                                            isLoading={isLoading}
                                            error={errors["currency"]}
                                            settings={settingsDarkMode}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth className={classes.itemField}  error={!!errors["distance"]}>
                                        <SelectField
                                            label={t("formFields.distanceUnit")}
                                            name={"distance"}
                                            value={values['distance']}
                                            options={distances}
                                            onSelectChange={(val) => handleChangeWithDetectChanges("distance", val)}
                                            isLoading={isLoading}
                                            error={errors["distance"]}
                                            settings={settingsDarkMode}
                                            hideEmpty
                                        />
                                    </FormControl>
                                </div>

                                <FormControl fullWidth className={classes.itemField}  error={!!errors["network"]}>
                                    <SelectField
                                        label={t("formFields.network")}
                                        name={"network"}
                                        value={values['network']}
                                        options={networks}
                                        onSelectChange={(val) => handleChangeWithDetectChanges("network", val)}
                                        isLoading={isLoading}
                                        error={errors["network"]}
                                        settings={settingsDarkMode}
                                        hideEmpty
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <Button
                        type="submit"
                        disabled={isDisabled}
                        className={classes.itemFormSubmit}
                    >
                        {t("formFields.save")}
                    </Button>
                </form>
            </div>
        </>
	)
}

const mapStateToProps = ({user}) => {
	return {
		settings:user.settings
	}
}

export default connect(
	mapStateToProps,
	{handleErrorMessage, setMessage, updateUserState}
)(SettingsForm)


import React, {useEffect, useState} from 'react';
import MultipleSelectSearch from "../../formElements/MultipleSelectSearch";
import Api from "../../../services/api";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";

const service = new Api();


const SelectUsersForApproval = (props) => {
    const {handleChange, values, disabled} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                const response = await service.getUsersAll();
                const mappedUsers = response.data.map((user) => {
                    const {_id, firstName, lastName} = user;
                    return {value: _id, label: `${firstName} ${lastName}`}
                });
                setOptions(mappedUsers);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        })()
    }, []);


    const inputHandler = (values) => {
        const vals = values ? values.filter((value) => value).map((item) => item.value) : [];
        handleChange("usersForApproval", vals);
    }

    return (
        <>
            {isLoading ? (
                <Skeleton width={"100%"} height={"40px"}/>
            ) : (
                <MultipleSelectSearch
                    options={options}
                    values={values}
                    label={t("formFields.usersForApproval")}
                    disabled={disabled}
                    name={"usersForApproval"}
                    inputHandler={inputHandler}
                />
            )}
        </>
    );
};

export default SelectUsersForApproval;

import React, {useCallback, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import InputFieldMemo from "../../../../../formElements/InputFieldMemo";

import {useTranslation} from "react-i18next";
import Fieldset from "../../../../../formElements/Fieldset";
import SelectField from "../../../../../formElements/SelectField";
import RadioFields from "../../../../../formElements/RadioFields";
import PhoneField from "../../../../../formElements/PhoneField";
import Box from "@material-ui/core/Box";
import FileUploaderInline from "../../../../../formElements/FileUploaderInline";

const CompanyDetails = (props) => {
    const {t} = useTranslation();

    const {handleChange, values, errors, setStepFields, industries, titles, fetchData, idx, loading} = props;

    useEffect(() => {
        if(industries.length === 0){
            fetchData();
        }
    },[industries]);


    useEffect(() => {
        // fields will be send on this step
        setStepFields((fields) => {
            return {
                ...fields,
                [idx]:[
                    "companyRegistrationNo",
                    "businessLicenceNo",
                    "websiteUrl",
                    "dateBusinessEstablished",
                    "industry",
                    "primaryContactTitle",
                    "primaryContactFirstName",
                    "primaryContactMiddleName",
                    "primaryContactLastName",
                    "primaryContactEmail",
                    "primaryContactPhone",
                    "proofOfCompanyRegistration"
                ]
            }
        })

    },[]);

    const memoizedCallback = useCallback(handleChange , []);

    return (
        <>
            <Box mb={"62px"}>
                <Fieldset title={t("kybFieldsetCompanyDetails")}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={!!errors["companyRegistrationNo"]} fullWidth>
                                <InputFieldMemo
                                    label={t("formFields.companyRegistrationNo")}
                                    placeholder={t("formFields.companyRegistrationNo")}
                                    name="companyRegistrationNo"
                                    type="text"
                                    value={values["companyRegistrationNo"]}
                                    onInputChange={memoizedCallback}
                                    error={errors["companyRegistrationNo"]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FileUploaderInline
                                value={values[`proofOfCompanyRegistration`]}
                                fieldName={"proofOfCompanyRegistration"}
                                error={errors[`proofOfCompanyRegistration`]}
                                handleChange={handleChange}
                                label={t("formFields.proofOfCompanyRegistration")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={!!errors["businessLicenceNo"]} fullWidth>
                                <InputFieldMemo
                                    label={`${t("formFields.businessLicenceNo")} ${t("formFields.optional")}`}
                                    placeholder={t("formFields.businessLicenceNo")}
                                    name="businessLicenceNo"
                                    type="text"
                                    value={values["businessLicenceNo"]}
                                    onInputChange={memoizedCallback}
                                    error={errors["businessLicenceNo"]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={!!errors["websiteUrl"]} fullWidth>
                                <InputFieldMemo
                                    label={`${t("formFields.companyWebsite")} ${t("formFields.optional")}`}
                                    placeholder={t("formFields.companyWebsite")}
                                    name="websiteUrl"
                                    type="text"
                                    value={values["websiteUrl"]}
                                    onInputChange={memoizedCallback}
                                    error={errors["websiteUrl"]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={!!errors["dateBusinessEstablished"]} fullWidth>
                                <InputFieldMemo
                                    label={t("formFields.dateBusinessEstablished")}
                                    placeholder={t("formFields.dateBusinessEstablished")}
                                    name="dateBusinessEstablished"
                                    type="text"
                                    value={values["dateBusinessEstablished"]}
                                    onInputChange={memoizedCallback}
                                    error={errors["dateBusinessEstablished"]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={!!errors["industry"]} fullWidth>
                                <SelectField
                                    label={t("formFields.industry")}
                                    placeholder={t("formFields.industry")}
                                    name={"industry"}
                                    value={values["industry"]}
                                    error={errors["industry"]}
                                    options={industries}
                                    onSelectChange={(val) => handleChange("industry", val)}
                                    isLoading={loading}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Fieldset>
            </Box>
            <Fieldset title={t("kybFieldsetPrimaryContactPerson")}>
                <Grid container spacing={5}>
                    <Grid item xs={12} style={{paddingBottom:"8px"}}>
                        <Typography variant="body2">
                            {t("kybFieldsetPrimaryContactPersonText")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={!!errors["primaryContactTitle"]} fullWidth>
                            <RadioFields
                                options={titles}
                                name={"primaryContactTitle"}
                                label={t("formFields.primaryContactTitle")}
                                placeholder={t("formFields.primaryContactTitle")}
                                value={values["primaryContactTitle"]}
                                onRadioChange={(val) => handleChange("primaryContactTitle", val)}
                                error={errors["primaryContactTitle"]}
                                isLoading={loading}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["primaryContactFirstName"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.primaryContactFirstName")}
                                placeholder={t("formFields.primaryContactFirstName")}
                                name="primaryContactFirstName"
                                type="text"
                                value={values["primaryContactFirstName"]}
                                onInputChange={memoizedCallback}
                                error={errors["primaryContactFirstName"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["primaryContactMiddleName"]} fullWidth>
                            <InputFieldMemo
                                label={`${t("formFields.primaryContactMiddleName")} ${t("formFields.optional")}`}
                                placeholder={t("formFields.primaryContactMiddleName")}
                                name="primaryContactMiddleName"
                                type="text"
                                value={values["primaryContactMiddleName"]}
                                onInputChange={memoizedCallback}
                                error={errors["primaryContactMiddleName"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["primaryContactLastName"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.primaryContactLastName")}
                                placeholder={t("formFields.primaryContactLastName")}
                                name="primaryContactLastName"
                                type="text"
                                value={values["primaryContactLastName"]}
                                onInputChange={memoizedCallback}
                                error={errors["primaryContactLastName"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["primaryContactEmail"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.primaryContactEmail")}
                                placeholder={t("formFields.primaryContactEmail")}
                                name="primaryContactEmail"
                                value={values["primaryContactEmail"]}
                                onInputChange={memoizedCallback}
                                error={errors["primaryContactEmail"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth error={!!errors["primaryContactPhone"]}>
                            <PhoneField
                                id="primaryContactPhone"
                                name="primaryContactPhone"
                                label={t("formFields.primaryContactPhone")}
                                placeholder={t("formFields.primaryContactPhone")}
                                type="text"
                                error={errors["primaryContactPhone"]}
                                value={values["primaryContactPhone"]}
                                onChangeHandler={(val) => handleChange("primaryContactPhone", val)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Fieldset>
        </>
    );
}


export default CompanyDetails

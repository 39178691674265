import React, {useEffect, useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../../../../../services/api";
import {handleErrorMessage} from "../../../../../../Messages/actions";
import useExpenseQueues from "../../../../../../../hooks/useExpenseQueues";
import useExpenses from "../../../../../../../hooks/useExpenses";
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";
import ExpenseQueueStatus from "../../../../../../ExpenseQueueStatus";
import ReceiptButtons from "./ReceiptButtons";

const service = new Api();

const QueueRow = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {columns} = props;
    const [expensesQueue, setExpensesQueue] = useExpenseQueues();
    const [expenses] = useExpenses();
    const onFetchByInterval = () => {
        return setInterval(fetchExpensesQueue, 1000*30);
    }

    const tabsExpenses = useSelector(state => state.tabs["transactions"]);
    const activeRoleTab = tabsExpenses.active;

    useEffect(() => {
        let functionId;
        if(activeRoleTab === "personal"){
            fetchExpensesQueue();
            functionId = onFetchByInterval();
        }
        return () => {
            if(functionId) clearInterval(functionId);
        }
    },[activeRoleTab]);


    const fetchExpensesQueue = async () => {
        try{
            const response = await service.getExpensesQueue();
            setExpensesQueue(response.data);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }
    }

    const onDeleteReceipt = async (id) => {
        await service.deleteExpenseQueue(id);
        setExpensesQueue((oldState) => {
            return oldState.filter((expenseQueue) => expenseQueue._id !== id);
        });
    }

    const isEmptyExpensesAndExpenseQueues = expenses.length === 0 && expensesQueue.length === 0;

    return (
        isEmptyExpensesAndExpenseQueues ? (
            <TableRow>
                <TableCell colSpan={columns.length} style={{padding:0, height: isEmptyExpensesAndExpenseQueues && '49px', textAlign: isEmptyExpensesAndExpenseQueues && 'center'}}>
                    {t('tableIsEmpty')}
                </TableCell>
            </TableRow>
        ) : (
            <React.Fragment>
                {expensesQueue.map((item) => {
                    const {status} = item;
                    return <TableRow key={item._id}>
                        {status === "notCreated" ? (
                            <TableCell colSpan={props.columns.length-2}>{t("expenseQueue.notCreatedMsg")}</TableCell>
                        ) : (
                            Array(props.columns.length-2).fill(null).map((item, idx) => {

                                const {align} = columns[idx];

                                return (
                                    <TableCell key={idx} style={{textAlign:align}}>
                                        {idx === 0 ? (
                                            // checkbox column
                                            <Box paddingLeft={'10px'}>-</Box>
                                        ) : '-'}

                                    </TableCell>
                                )
                            })
                        )}
                        <TableCell><ExpenseQueueStatus status={status}/></TableCell>
                        <TableCell align={"right"}>
                            <ReceiptButtons expenseQueue={item} onDeleteReceipt={onDeleteReceipt}/>
                        </TableCell>
                    </TableRow>

                })}
            </React.Fragment>
        )
    )
};

export default QueueRow;

import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Fieldset from "../../../../../../formElements/Fieldset";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import Api from "../../../../../../../services/api";
import SendingAnimationWrapper from "../../../../../../SendingAnimationWrapper";
import CircularProgress from '@material-ui/core/CircularProgress'
import * as loader from "../../../../../../../assets/animations/loader";
const service = new Api();


const ForwardSuccessful = (props) => {
    const {setCurrentAction, hasDownload, onFinish, title, description, handleErrorMessage, isSending} = props;
    const {t} = useTranslation();

    const [isDownloading, setIsDownloading] = useState(false);

    const onDownload = async () => {


        setIsDownloading(true);
        try{
            await service.downloadKybDocument();
            setCurrentAction("forwarded-download");
            setIsDownloading(false);
        }catch (e) {
            handleErrorMessage(e);
            setIsDownloading(false);
        }

    }

    return (
            <Fieldset title={title ? title : ""}>
                <Box position={"relative"}>
                    {isDownloading && (
                        <SendingAnimationWrapper>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <CircularProgress />
                            </Box>
                        </SendingAnimationWrapper>
                    )}
                    <Grid container spacing={5}>
                        {description && (
                            <Grid item xs={12}>
                                <Typography variant="body2">{description}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box component={"span"} mr={2}>
                                <Button
                                    onClick={onFinish}
                                    disabled={isDownloading || isSending}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t("formFields.finishKyb")}
                                </Button>
                            </Box>

                            {hasDownload && (
                                <Box mr={2} component={"span"}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={onDownload}
                                        disabled={isDownloading || isSending}
                                    >
                                        {t("formFields.download")}
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Fieldset>

    );
};

export default ForwardSuccessful;

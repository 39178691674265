import React from 'react';
import {useTranslation} from "react-i18next";
// eslint-disable-next-line no-unused-vars
import FilterButton from "../../../../FilterButton";
import {
    makeStyles,
    // eslint-disable-next-line no-unused-vars
    Box,
    // eslint-disable-next-line no-unused-vars
    Grid,
    Typography
} from "@material-ui/core";
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    search:{
        marginLeft:"auto",
        display:"flex",
    },
}));

const TopComponent = (props) => {
    const {
        // eslint-disable-next-line no-unused-vars
        onFilterOpen,
        // eslint-disable-next-line no-unused-vars
        isFilterOpen,
        settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <>
            <Typography variant="h1" className={`${classes.pageTitle} h3`}>{t("dashboard.mainTitle")}</Typography>
            {/* <Grid item className={classes.search}>
                {!isFilterOpen && <Grid item><FilterButton onClick={onFilterOpen}/></Grid>}
            </Grid> */}
        </>
    );
};

export default TopComponent;

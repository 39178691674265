import React from 'react';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import {Can} from "../../../../../Context/Can";
import PlusButton from "../../../../PlusButton"
import {ReactComponent as PlusIconSVG} from "../../../../../assets/plusCircleInvesthub.svg"

const useStyle = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    headControl: {
        display: 'flex',
        marginLeft: 'auto',
    },
}))


const TopComponent = (props) => {
    const {history, settingsDarkMode} = props;
    const classes = useStyle();

    const {t} = useTranslation();
    return (
        <div className={classes.head}>
            <div className={`${classes.headTitle} card-title card-title--blue`}>
                {t("expenseReportTemplate.mainTitle")}
            </div>

            <div className={classes.headControl}>
                <Can I="create" a={"expenseReportTemplate"}>
                    <Tooltip title={t("expenseReportTemplate.addNew")}>
                        <PlusButton onClickHandler={() => history.push("/reports/templates/add")} settingsDarkMode={settingsDarkMode}>
                            <PlusIconSVG />
                        </PlusButton>
                    </Tooltip>
                </Can>
            </div>
        </div>
    );
};

export default TopComponent;

import React, {useEffect, useState} from 'react'
import {withRouter} from "react-router-dom";
import {fetchCards} from '../actions'
import {connect} from 'react-redux'
import TopComponent from "./TopComponent";
import FormatMoney from "../../../../FormatMoney";
import checkClick from "../../../../../helpers/checkClick";
import {compose} from "redux";
import FormatDate from "../../../../FormatDate";
import {makeStyles, Portal, Typography, Box } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import buildTabsQueryCards from "../../../../../helpers/buildTabsQueryCards";
import Pagination from "../../../../Pagination";
import queryString from "query-string";
import DrawerFilter from "../../../DrawerFilter";
import {Ability} from "@casl/ability";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import ColumnCard from "./ColumnCard";
import isCardDeleted from "../../../../../helpers/isCardDeleted";
import ColumnEmployee from "./ColumnEmployee";
import useMounted from "../../../../../hooks/useMounted";
import CardsFilter from "../../../DrawerFilter/CardsFilter";

const useStyles = makeStyles(theme => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },

}));

const CardsTable = (props) => {
    const classes = useStyles();

    const {
        userCards,
        fetchCards,
        type,
        history,
        dateFormat,
        tabs,
        userId,
        location,
        drawerOuterRef,
        underBarRef,
        commonRules,
        settingsDarkMode
    } = props;

    const {search} = location;
    const queryObject = queryString.parse(search);

    const {cards:{result, info={}}} = userCards;
    const {page, total, pageSize} = info;

    const [isLoading, setIsLoading] = useState(false);

    const [ability, setAbility] = useState(new Ability());

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const isMounted = useMounted();

    const {t} = useTranslation();

    const tabQuery = tabs ? buildTabsQueryCards(tabs.active, type) : {};

    const fetchUserCards = async (dataQuery) => {
        setIsLoading(true);
        await fetchCards(dataQuery);
        setIsLoading(false);
    }


    useEffect(() => {
        fetchUserCards({
            ...tabQuery,
            ...queryObject
        })
    },[search, tabs, type]);


    useEffect(() => {
        const ability = new Ability(commonRules);
        setAbility(ability);
    },[commonRules]);

    const handleChangePage = async (event, newPage) => {
        fetchUserCards({
            ...tabQuery,
            ...queryObject,
            page:newPage
        })
    }

    const rowHandlerClick = (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push({pathname: `/cards/${rowData._id}`, state: {backPath: props.match.url}})
        }
    }

    const canShowFilter = ability.can("read", "user"); // user's filter has only one field (users select). and we don't show filter block if user can't read other users;

    const columns = [
        {
            title: t('thead.card'),
            render: (row) => <ColumnCard {...row}/>
        },
        {
            title: t('thead.validTo'),
            render: (row) => isCardDeleted(row) ? "" : <FormatDate dateFormat={dateFormat}>{row.validTo}</FormatDate>
        },
        ...(tabs.active !== 'personal' ? [
            {
                title: t('thead.employeeName'),
                field: 'user',
                render: (row) => <ColumnEmployee {...row}/>
            }
        ] : []),
        {
            title: t('thead.account'),
            field: 'parent',
            render: (row) => {
                if(isCardDeleted(row)) return "";

                const {parent} = row;
                let accountName = '';
                if(parent && parent.account && parent.account.currency){
                    accountName = parent.account.currency
                }
                return accountName
            }
        },
        {
            title: t('thead.monthLimit'),
            field: 'monthLimit',
            align:'right',
            render: (row) => isCardDeleted(row) ? "" : <FormatMoney>{row.monthLimit}</FormatMoney>
        },
        {
            title: t('thead.remainingBalance'),
            align:'right',
            render: (row) => {
                if(isCardDeleted(row)){
                    return "";
                }
                const {cardRemainingBalance} = row;

                return cardRemainingBalance ? <FormatMoney>{cardRemainingBalance}</FormatMoney> : '';
            }
        },
    ];


    return (
            <>
                {isMounted && (
                    <>
                        <Portal container={drawerOuterRef.current}>
                            {canShowFilter && (
                                <DrawerFilter isOpen={isFilterOpen} onDrawerFilterClose={() => setIsFilterOpen(false)} settingsDarkMode={settingsDarkMode}>
                                    <CardsFilter settingsDarkMode={settingsDarkMode}/>
                                </DrawerFilter>
                            )}
                        </Portal>
                        <Portal container={underBarRef.current}>
                            <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                                {t("card.mainTitle")}
                            </Typography>
                        </Portal>
                    </>
                )}

                <Box className={`card`}>
                    <TopComponent onFilterOpen={() => setIsFilterOpen(true)} isFilterOpen={isFilterOpen} canShowFilter={canShowFilter} settingsDarkMode={settingsDarkMode} />

                    <Box className="table-wrap table-wrap--have-line table-wrap--have-mx-offset">
                        <VolveMaterialTable
                            columns={columns}
                            isLoading={isLoading}
                            data={result}
                            onRowClick={rowHandlerClick}
                        />
                        <Pagination
                            total={total}
                            pageSize={pageSize}
                            page={page}
                            isLoading={isLoading}
                            onChangePage={handleChangePage}
                            loadMore
                        />
                    </Box>
                </Box>
            </>
    )
};
const mapStateToProps = (state) => {
    const {userCards, user, tabs} = state;
    const {settings, commonRules, userData} = user;
    const {usersForApproval} = userData;

    const {regional={}} = settings;
    const {dateFormat} = regional;
    return {
        userCards,
        isAuthenticated:user.isAuthenticated,
        dateFormat,
        tabs:tabs["cards"],
        userId:user.userData._id,
        commonRules,
        usersForApproval
    }
};

export default compose(
    connect( mapStateToProps, {fetchCards}),
    withRouter,
)(CardsTable)

import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux";
import {userLogout} from '../User/actions'

const Logout = (props) => {
	useEffect(() => {
		props.onLogout()
	},[]);
	return <Redirect to="/"/>
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout : () => {
			dispatch(userLogout())
		}
	}
};

export default connect(
	null,
	mapDispatchToProps
)(Logout)
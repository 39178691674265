import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Fieldset from "../../../../../formElements/Fieldset";
import CheckBoxField from "../../../../../formElements/CheckBoxField";
import Typography from "@material-ui/core/Typography";

const SubmitStep = (props) => {
    const {values, errors, handleChange} = props;
    const {t} = useTranslation();

    return (
        <>
            <Fieldset title={t("kybFieldsetSubmitStep")}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                            {t("paladisKybFieldsetSubmitStepText")}
                        </Typography>
                        <FormControl error={!!errors["accepted"]} fullWidth>
                            <CheckBoxField
                                onCheckboxChange={(val) => handleChange("accepted", val)}
                                value={values["accepted"]}
                                label={t("formFields.acceptKybCheckbox")}
                                name={"accepted"}
                                error={errors["accepted"]}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Fieldset>
        </>
    );
};

export default SubmitStep;

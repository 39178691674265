import React, {useEffect} from 'react';
import Fieldset from "../../../../../formElements/Fieldset";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FundingBankAccounts2 from "../../../../../FundingBankAccounts2";
import Typography from "@material-ui/core/Typography";

const AccountDetails = (props) => {
    const {t} = useTranslation();
    const {values, errors, loading, handleChange, fundingBankAccountsIndexes, setFundingBankAccountsIndexes, setStepFields, idx, fetchData, currencies} = props;

    useEffect(() => {
        if(currencies.length === 0){
            fetchData();
        }
    },[]);


    return (
        <Fieldset title={t("kybFieldsetAccountDetails")}>
            <Grid container spacing={5}>
                <Grid item xs={12} style={{paddingBottom:"5px"}}>
                    <Typography variant="body2">
                        {t("paladisKybFieldsetAccountDetailsText")}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{paddingTop:"5px"}}>
                    <FundingBankAccounts2
                        setStepFields={setStepFields}
                        values={values}
                        errors={errors}
                        idx={idx}
                        isLoading={loading}
                        handleChange={handleChange}
                        fundingBankAccountsIndexes={fundingBankAccountsIndexes}
                        setFundingBankAccountsIndexes={setFundingBankAccountsIndexes}
                        currencies={currencies}
                    />
                </Grid>
            </Grid>
        </Fieldset>
    );
}

export default AccountDetails

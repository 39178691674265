import React from 'react'
import {withRouter, Route, Switch, matchPath} from 'react-router-dom'
import CardsTable from './CardsTable'
import CardView from './CardView'
import {useTranslation} from "react-i18next";
import SelectTab from "../../../SelectTab";
import {cleanCards} from "./actions";
import {useDispatch} from "react-redux";
import UnderCardDockInvesthub from "../../../UnderCardDockInvesthub"

const Cards = (props) => {
	const {match, location, drawerOuterRef, underBarRef, settingsDarkMode} = props;
	const {pathname} = location;
    const dispatch = useDispatch();

	const {t} = useTranslation();

    const types = [
        {id:"physical", name: t("card.physicalTab")},
        {id:"virtual", name:  t("card.virtualTab")}
    ];


    const matchedPath = matchPath(pathname, {
        path: "/cards/:tab",
        exact: true,
        strict: false
    });
    const currentTab = matchedPath ? matchedPath.params.tab : "";

    const onSelectChange = (val) => {
        dispatch(cleanCards()); // if we switch the tab - need to show skeleton. But skeleton shows only if cards are empty. And here we clean cards state
        props.history.push(`${match.url}/${val}`)
    };

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[`${match.path}/physical`, `${match.path}/virtual`]}
                    render={() => {
                        return <CardsTable type={currentTab} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                    }}
                />
                <Route path={`${match.path}/:id`} render={() => <CardView underBarRef={underBarRef} drawerOuterRef={drawerOuterRef} settingsDarkMode={settingsDarkMode} />}/>
            </Switch>

            {['physical', 'virtual'].includes(currentTab) && (
                <>
                    <UnderCardDockInvesthub
                        titleDock={t('Page Inner')}
                        settingsDarkMode={settingsDarkMode}
                    >
                        <SelectTab
                            value={currentTab}
                            options={types.map(({id, name}) => ({value:id, label:name}))}
                            onSelectChange={(val) => onSelectChange(val)}
                            settingsDarkMode={settingsDarkMode}
                        />
                    </UnderCardDockInvesthub>
                </>
            )}
        </>
    )
}

export default withRouter(Cards)

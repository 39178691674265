import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import getCardIsDisabled from "../../../../../../../helpers/getCardIsDisabled";
import {useTranslation} from "react-i18next";
import {subject} from "@casl/ability";
import {confirm} from '../../../../../../../helpers/confirm';
import ThinButton from "../../../../../../ThinButton";
import ConfirmTextWrapper from "../../../../../../ConfirmTextWrapper";
import Box from "@material-ui/core/Box";

const service = new Api();
const {deleteCard} = service;

const DeleteCard = (props) => {

	const {handleErrorMessage, setMessage, card, setCard, ability} = props;
	const {_id:cardId, user={}} = card;

	const [isSending, setIsSending] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const {t} = useTranslation();


    const deleteHandler = async () => {

        if (await confirm(
            {
                confirmation: t('deleteCardTitle'),
                yesText: t('formFields.deleteCard'),
                noText:  t('formFields.cancel'),
                content: (
                    <ConfirmTextWrapper>
                        <Box>{t('deleteCardLine1')}</Box>
                        <Box>{t('deleteCardLine2')}</Box>
                    </ConfirmTextWrapper>
                )
            }
        )) {
            try {
                setIsSending(true);
                const response = await deleteCard(cardId);
                setIsSending(false);
                setMessage("success", 'success');
                setCard((oldCard) => ({
                    ...oldCard,
                    ...response.data,
                }));
            }catch (e) {
                handleErrorMessage(e);
                setIsSending(false);
            }
        }

    };

    useEffect(() => {
        if(card){
            const isCardDisabled = getCardIsDisabled(card.currentStatCode);
            setIsDisabled(isCardDisabled);
        }
    },[card]);


    const canDelete = () => {
        if(card.user && card.company){
            return ability.can("delete", subject("card", {user:card.user.id, company:card.company.id}));
        }
        return false
    }

    return canDelete() ? <ThinButton disabled={isDisabled || isSending} disableTouchRipple={false} onClick={deleteHandler}>{t("formFields.deleteCard")}</ThinButton> : null
};

export default DeleteCard;

import React, {useEffect, useState} from 'react';
import {compose} from "redux";

import TopComponentInvesthub from '../TopComponentInvesthub';
import {connect} from "react-redux";
import {fetchGroups} from "../actions";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import useMounted from "../../../../../hooks/useMounted";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles, Typography, Portal, Box} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}))


const GroupsTable = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();

    const {fetchGroups, groups, setMessage, underBarRef, history, match, settingsDarkMode} = props;

    const {result, info} = groups;
    const {page, total, pageSize} = info;

    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useMounted();

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await fetchGroups();
            setIsLoading(false);
        })();
    },[fetchGroups]);

    const handleChangePage = async (event, newPage) => {
        setIsLoading(true);
        await fetchGroups({
            page:newPage,
        });
        setIsLoading(false);
    }

    const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }


    const onImport = async (formData) => {
        const response = await service.importGroups(formData);
        const {data} = response;
        const {modified, doNotModified} = data;
        const message = t("group.importInfo", {modified:modified, all:modified+doNotModified});
        setMessage(message, 'success');
        fetchGroups();
    }

    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
        {
            title: t('thead.members'),
            members: 'members',
            render: ({members}) => members ? members.map(({firstName, lastName}) => `${firstName} ${lastName}`).join(", ") : "",
        },
        {
            title: t('thead.id'),
            field: '_id',
        },
    ];

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}

            <Box className={`card`}>
                <TopComponentInvesthub onImport={onImport} history={history} settingsDarkMode={settingsDarkMode}/>

                <Box className="table-wrap">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={result}
                        onRowClick={rowHandlerClick}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>
            </Box>
        </>
    );
};

const mapStateToProps = ({userGroups}) => {
    const {groups} = userGroups;
    return {
        groups
    }
}

export default compose(
    connect(mapStateToProps, {fetchGroups, handleErrorMessage, setMessage})
)(GroupsTable);

import React, { useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import Pagination from "../../../../Pagination";
import Api from "../../../../../services/api";

const service = new Api();
const useStyle = makeStyles((theme) => ({}));

const AccountsChartTable = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        const getAppData = async () => {
            const { data } = await service.getCompanyXeroAccounts();
            console.log(data);
            if (data?.xeroAccounts?.Accounts) {
                setAccounts(data?.xeroAccounts?.Accounts);
            } else {
                setAccounts([]);
            }
        };
        getAppData();
    }, []);

    const columns = [
        {
            title: t("Code"),
            field: "Code",
        },
        {
            title: t("Name"),
            field: "Name",
        },
        {
            title: t("Type"),
            field: "Type",
        },
        {
            title: t("Description"),
            field: "Description",
        },
    ];

    return (
        <>
            <Box
                className={`table-wrap table-wrap--have-line table-wrap--have-mx-offset`}
            >
                <VolveMaterialTable columns={columns} data={accounts}/>
                <Pagination loadMore />
            </Box>
        </>
    );
};

export default AccountsChartTable;

import React from 'react';
import DateField from "../../../../../../../formElements/DateField";

import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import ThinButton from "../../../../../../../ThinButton";
import CardOptionWrapper from "../../CardOptionWrapper";

const FutureBlockingForm = (props) => {
    const {handleChange, handleSubmit, values, errors, dateFormat, disabled, isSubmitting} = props;

    const {t} = useTranslation();

    const onDateChange = (date) => {
        handleChange(`futureBlockDate`, moment(date).startOf("day"))
    }

    const onReset = (e) => {
        handleChange(`futureBlockDate`);
        handleSubmit(e);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box marginTop={"15px"} marginBottom={"15px"}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <CardOptionWrapper>
                            <FormControl error={!!errors["futureBlockDate"]} fullWidth>
                                <DateField
                                    placeholder={dateFormat}
                                    label={t("formFields.futureBlockDate")}
                                    dateFormat={dateFormat}
                                    onDateChange={onDateChange}
                                    value={values[`futureBlockDate`]}
                                    error={errors["futureBlockDate"]}
                                    name="futureBlockDate"
                                    disabled={disabled}
                                    disablePast
                                    invalidDateMessage={null}
                                />
                            </FormControl>
                        </CardOptionWrapper>

                    </Grid>
                    {!disabled && (
                        <Grid item xs={12} sm={6}>
                            <Box display={"flex"} marginTop={"30px"}>
                                <CardOptionWrapper>
                                    <span style={{marginRight:"14px"}} >
                                    <ThinButton
                                        noPadding
                                        type={"submit"}
                                        disableTouchRipple={false}
                                        color={"primary"}
                                        disabled={disabled || isSubmitting}
                                    >
                                        {t("formFields.setFutureBlockDate")}
                                    </ThinButton>
                                </span>

                                    {values["futureBlockDate"] && (
                                        <span>
                                        <ThinButton
                                            disableTouchRipple={false}
                                            disabled={disabled || isSubmitting}
                                            onClick={onReset}
                                            noPadding
                                        >
                                            {t("formFields.resetFutureBlockDate")}
                                        </ThinButton>
                                    </span>
                                    )}
                                </CardOptionWrapper>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>

        </form>
    );
};

export default FutureBlockingForm;

import React from 'react';
import MaterialTable from "material-table";
import {Paper, CircularProgress, Box} from "@material-ui/core";
// import * as loader from "../../assets/animations/loader";
import {useTranslation} from "react-i18next";

const Container = props => <Paper {...props} elevation={0}/>;
const OverlayLoading = () => (
    <div style={{backgroundColor: 'rgba(255, 255, 255, 0.7)',justifyContent:'center', height: '100%', display: 'flex', alignItems: 'center', width: '100%'}}>
        <CircularProgress />
    </div>
);

const EmptyWrapper = ({minBodyHeight, children}) => {
    return (
        <Box height={minBodyHeight} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {children}
        </Box>
    )
};

const VolveMaterialTable = (props) => {
    const {components, options, EmptyComponent, settingsDarkMode, ...restProps} = props;
    const {t} = useTranslation();
    return (
        <MaterialTable
            localization={{
                body: {
                    emptyDataSourceMessage: !props.isLoading && (EmptyComponent ? <EmptyWrapper minBodyHeight={(props.options && props.options.minBodyHeight) ? props.options.minBodyHeight : undefined}><EmptyComponent/></EmptyWrapper> : t('tableIsEmpty'))
                }
            }}
            {...restProps}
            components={{
                Container,
                OverlayLoading,
                ...(components && components)
            }}
            options={{
                minBodyHeight: 400,
                toolbar: false,
                search: false,
                paging: false,
                sorting: false,
                draggable: false,
                ...(options && options)
            }}
        />

    );
};

export default VolveMaterialTable;

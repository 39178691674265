import React from 'react';
import {matchPath, NavLink, useLocation} from "react-router-dom";
import {
    Tooltip,
    ListItem,
    List,
    Collapse,
    Box,
    useMediaQuery,
    makeStyles,
    useTheme,
} from "@material-ui/core";
import clsx from 'clsx';
import { isDarkModeActive } from '../../../../App/App';

import {ReactComponent as ArrowDownSVG} from "../../../../../assets/arrowDownInvesthub.svg"

const MenuItem = (props) => {
    const {to, Icon, isOpen, name, children, settings} = props;
    const theme = useTheme();
    const onMax1259 = useMediaQuery(theme.breakpoints.down('1259'), {noSsr: true});

    const useStyles = makeStyles(theme => ({
        sidebarListItem: {
            "&:not(:last-child)": {
                marginBottom: '8px'
            },
        },
        sidebarItem: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '48px',
            padding: '0 12px',
            borderRadius: '12px',
            whiteSpace: 'nowrap',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'background .2s, box-shadow .2s, color .2s',

            "& svg": {
                position: 'relative',
                top: '-1px',
                width: '24px',
                height: '24px',
                fill: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--dark-secondary)',
                transition: 'fill .2s',
                marginRight: '12px',
                "&#balance": {
                    fill: 'none'
                }
            },

            "&:hover": {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                "& svg": {
                    fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
                }
            },
            "&.active": {
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)',
                boxShadow: (props) => props.isDarkModeActive ? 'inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)' : 'inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF',
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                "& svg": {
                    fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
                }
            }
        },
        sidebarItemDropdown: {
            flexFlow: "column nowrap",
        },
        sidebarItemDropdownInner: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '48px',
            padding: '0 12px',
            borderRadius: '12px',
            whiteSpace: 'nowrap',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'background .2s, box-shadow .2s, color .2s',

            "& svg": {
                position: 'relative',
                top: '-1px',
                width: '24px',
                height: '24px',
                fill: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--dark-secondary)',
                transition: 'fill .2s',

                "&#balance": {
                    fill: 'none'
                },
                "&:first-child": {
                    marginRight: '12px'
                },
                "&:last-child": {
                    marginLeft: 'auto',
                }
            },

            "&:hover": {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                "& svg": {
                    fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
                }
            },
            "&.active": {
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)',
                boxShadow: (props) => props.isDarkModeActive ? 'inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)' : 'inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF',
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                "& svg": {
                    fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                    transform: 'rotate(180deg)'
                }
            }
        },

        childWrapper:{
            width: '100%',
            display: 'block',

            '& .MuiList-root': {
                paddingLeft: '36px',
                "&:before": {
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    left: '23px',
                    bottom: '32px',
                    width: '2px',
                    borderRadius: '2px',
                    background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)',
                },

                '& .MuiListItem-root': {
                    position: "relative",
                    "&:before": {
                        content: '""',
                        position: 'absolute',
                        top: '12px',
                        left: '-13px',
                        width: '12px',
                        height: '12px',
                        background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23272B30' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto` : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto`,
                    },

                    "&:hover": {
                        backgroundColor: 'transparent'
                    }
                },

                '& a': {
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '48px',
                    padding: '0 12px',
                    borderRadius: '12px',
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '1.6',
                    color: 'var(--battelship-grey)',
                    transition: 'background .2s, box-shadow .2s, color .2s',

                    "&:hover": {
                        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                        backgroundColor: 'transparent'
                    },
                    "&.active": {
                        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                        // boxShadow: (props) => props.isDarkModeActive ? 'inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)' : 'inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF',
                        // backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)'
                        backgroundColor: 'transparent'
                    }
                }
            }
        },

        sidebarItemIsExpand: {
            "&$sidebarItem": {
            },
            "&$sidebarItemDropdown": {
            },
            "& $sidebarItemDropdownInner": {
                "& svg": {

                    "&:first-child": {
                    },
                }
            },
            "& $childWrapper": {
                "& .MuiList-root": {
                    "& a": {
                    }
                }
            }
        },
        sidebarItemisshrink: {
            // Link without child
            "&$sidebarItem": {
                [theme.breakpoints.down(1259)]: {
                    fontSize: "0",
                    width: "48px",
                    "& svg": {
                        marginRight: "0"
                    }
                }
            },

            // Link with child
            "&$sidebarItemDropdown": {
                [theme.breakpoints.down(1259)]: {
                    width: '48px',
                    height: '48px',
                    padding: '0',
                },
                "& $sidebarItemDropdownInner": {
                    [theme.breakpoints.down(1259)]: {
                        fontSize: "0",
                        width: "48px",
                        padding: "0",
                        "& svg": {
                            marginRight: 'auto',
                            marginLeft: 'auto'
                        }
                    }
                }
            }
        }
    }));

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const { pathname } = useLocation();
    const isMatchedPath = matchPath(pathname, `/${to}`);

    let showChild = !!(children && isOpen && isMatchedPath);

    if (!isOpen && !onMax1259 && isMatchedPath) {
        showChild = true
    }

    return (
        <>
            {children ? (
                <ListItem
                    className={clsx(
                        classes.sidebarListItem,
                        classes.sidebarItemDropdown,
                        {
                            [classes.sidebarItemIsExpand]: isOpen,
                            [classes.sidebarItemisshrink]: !isOpen
                        }
                    )}
                    dense={false}
                    disableGutters={true}
                    disableRipple
                    component={'div'}
                >
                    <Box
                        className={classes.sidebarItemDropdownInner}
                        component={NavLink}
                        to={`/${to}`}
                    >
                        <Tooltip title={name} className={classes.sidebarTooltip}>
                            <Icon />
                        </Tooltip>
                        {name}

                        {!onMax1259 && (
                            <>
                                {(!isOpen) &&  <ArrowDownSVG />}
                            </>
                        )}
                        {isOpen && <ArrowDownSVG />}
                    </Box>

                    <Collapse in={showChild} timeout="false" unmountOnExit className={classes.childWrapper}>
                        <List component="div" disablePadding>
                            {children}
                        </List>
                    </Collapse>
                </ListItem>
            ) :
                <ListItem
                    className={clsx(
                        classes.sidebarListItem,
                        classes.sidebarItem,
                        {
                            [classes.sidebarItemIsExpand]: isOpen,
                            [classes.sidebarItemisshrink]: !isOpen
                        }
                    )}
                    dense={false}
                    disableGutters={true}
                    disableRipple
                    component={NavLink}
                    to={`/${to}`}
                >
                    <Tooltip title={name}>
                        <Icon />
                    </Tooltip>
                    {name}
                </ListItem>

            }
       </>
    );
};

export default MenuItem;

import React, {useState, useEffect} from 'react';
import {Portal, Typography, makeStyles} from "@material-ui/core";
import {withRouter, Route, Switch} from 'react-router-dom'
import {compose} from "redux";
import {useTranslation} from "react-i18next";
import HeadContent from "./HeadContent/HeadContent";
import AccountsChartTable from './AccountsChartTable/AccountsChartTable';
import AccountsRules from "./AccountsRules/AccountsRules"

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}))

const AccountsChart = (props) => {
    const {match, underBarRef, settingsDarkMode} = props;
    const {path} = match;
    const {t} = useTranslation();
    const classes = useStyles();
    const [isOpenModal, setIsOpenModal] = useState(false);
    // NEED TRANSLATIONS
    const tabs = [
        {url:match.url, name:t("Charts")},
        {url:`${match.url}/rules`, name:t("Rules")}
    ];

    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        setIsMount(true);
    }, []);

    return (
        <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    {/* NEED TRANSLATION */}
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("Chart of Accounts")}
                    </Typography>
                </Portal>
            )}

            <div className="card">
                <HeadContent routes={tabs} settingsDarkMode={settingsDarkMode} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />

                <Switch>
                    <Route exact path={path} render={
                        (props) => {
                            return <AccountsChartTable {...props} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                        }
                    } />

                    <Route exact path={`${path}/rules`} render={
                        (props) => <AccountsRules {...props} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                    }/>
                </Switch>
            </div>
        </>
    )
}

export default compose(
    withRouter,
)(AccountsChart)


import IMask from "imask";

const getDisplayedTaxRate = (amount="", decimal) => {
    const masked = IMask.createMask({
        mask: Number,
        mapToRadix: ['.', ','],
        thousandsSeparator: ' ',
        padFractionalZeros: true,
        normalizeZeros: false,
        radix: decimal ? decimal : ".",
        scale: 1,
    });

    return masked.resolve(String(amount));
}

export default getDisplayedTaxRate

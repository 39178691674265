import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Api from "../../../../../../services/api";
import useForm2 from "../../../../../../hooks/useForm2";
import DialogPopUp from "../../../../../DialogPopUp";
import { setMessage } from "../../../../../Messages/actions";

const useStyles = makeStyles((theme) => ({
    content: {
        width: "300px",
        maxWidth: "100%",
        paddingTop: "28px",
        paddingBottom: "28px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    button: {
        marginTop: "28px",
    },
}));

const BankingPaymentMethods = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        setOpenBankingModal,
        openBankingModal,
        selectedTransaction,
        setSelectedTransaction,
        loading,
        isMoneriumEnabled
    } = props;

    const onClose = () => {
        setOpenBankingModal(false);
    };
    console.log("openBankingModal", openBankingModal);
    console.log('isMoneriumEnabled', isMoneriumEnabled)
    const onFiatRepublicTransfer = () => {
        //In case just IBAN
        if (!selectedTransaction?.vendor?.fiat) {
            dispatch(
                setMessage(
                    t("Please create fiat contact for this contact"),
                    "error"
                )
            );
            return history.goBack()
        }

        history.push({
            pathname: "/balances",
            search:
                "?" +
                new URLSearchParams({
                    expenseId: selectedTransaction.id,
                    fiatId: selectedTransaction?.vendor?.fiat?.id,
                    amount: selectedTransaction.amount + (selectedTransaction.taxAmount || 0),
                    currency: selectedTransaction.currency,
                }).toString(),
        });
    };

    const onMoneriumTransfer = () => {
        if (!isMoneriumEnabled) {
            dispatch(
                setMessage(
                    t("Please enable monerium"),
                    "error"
                )
            );
            return history.goBack()
        }
        history.push({
            pathname: "/balances",
            search:
                "?" +
                new URLSearchParams({
                    expenseId: selectedTransaction.id,
                    iban: selectedTransaction?.receivingIban,
                    amount: selectedTransaction.amount,
                    country: selectedTransaction?.country,
                    firstName: selectedTransaction?.receivingFirstName,
                    lastName: selectedTransaction?.receivingLastName,
                    sendType: selectedTransaction?.sendType,
                    companyName: selectedTransaction?.companyName,
                }).toString(),
        });
    };

    return (
        <DialogPopUp
            open={openBankingModal}
            onClose={onClose}
            aria-labelledby="reject-title"
            title={t("formFields.reject")}
        >
            <DialogContent className={classes.content}>
                <FormControl fullWidth>
                    <Grid container>
                        <Grid item xs={12}>
                            {loading ? (
                                <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    {t(
                                        `Please do not close this tab until the transaction is executed.`
                                    )}
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <FormControl fullWidth>
                                    {t(
                                        `Please select your banking payment method.`
                                    )}
                                    <Button
                                        className={classes.button}
                                        onClick={onFiatRepublicTransfer}
                                    >
                                        {t(`Fiat Republic Transferring`)}
                                    </Button>
                                    <Button
                                        className={classes.button}
                                        onClick={onMoneriumTransfer}
                                    >
                                        {t(`Monerium Transferring`)}
                                    </Button>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpenBankingModal(false)}
                    color="secondary"
                    variant="contained"
                >
                    {t("formFields.cancel")}
                </Button>
            </DialogActions>
        </DialogPopUp>
    );
};

export default BankingPaymentMethods;

import React, {useEffect, useState} from 'react';
import Api from "../../../../../services/api";
import {compose} from "redux";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import DialogFba from "../DialogFba";
import {connect} from "react-redux";
import {handleErrorMessage} from "../../../../Messages/actions";
import {fetchFbas} from "../actions";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../Context/Can";
import {ReactComponent as EditSVG} from "../../../../../assets/editInvesthub.svg"
import {ReactComponent as ApprovedSVG} from "../../../../../assets/checkAllInvesthub.svg"
import VolveMaterialTable from "../../../../VolveMaterialTable";
const service = new Api();

const useStyles = makeStyles(theme => ({
    tableFundingBankAccounts:{
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    },
    item:{
        position: 'relative',
        '&:not(:last-child)': {
            marginBottom: '32px',
            borderBottom: '1px solid var(--very-light-pink-secondary)',

            [theme.breakpoints.down('1023')]: {
                marginBottom: '24px'
            }
        },
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            borderRadius: '0'
        }
    },
    icon:{
        width:"30px",
        fill:"#24dac4"
    },
    button:{
        margin:"20px 0"
    },
    editBtn:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: '0',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        backgroundColor: 'var(--very-light-pink-secondary)',
        marginLeft: 'auto',
        marginRight: 'auto',
        "& svg": {
            width: '20px',
            height: '20px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
        },
        "&:hover svg": {
            fill: 'var(--clear-blue-primary)'
        }
    }
}));


const FundingBankAccountsTable = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {handleErrorMessage, fetchFbas, fundingBankAccounts, match} = props;

    const {result} = fundingBankAccounts;

    const [isLoading, setIsLoading] = useState(true);
    const [fundingBankAccount, setFundingBankAccount] = useState(null);
    const [open, setOpen] = useState(false);
    const [isFetchingFba, setIsFetchingFba] = useState(false);


    const onFbaClick = async (id) => {
        setOpen(true);
        setIsFetchingFba(true);

        try {
            const response = await service.getCompanyFba(id);
            setFundingBankAccount(response.data);
            setIsFetchingFba(false);
        }catch (e) {
            handleErrorMessage(e);
            setFundingBankAccount(null);
            setIsFetchingFba(false);
            setOpen(false);
        }
    }

    useEffect(() => {
        (async() => {
            await fetchFbas();
            setIsLoading(false);
        })();
    },[fetchFbas]);

    const columns = [
        {
            title: t('thead.bankName'),
            field: 'bankName',
        },
        {
            title: t('thead.accountNo'),
            field: 'accountNo',
        },
        {
            title: t('thead.accountName'),
            field: 'accountName',
        },
        {
            title: t('thead.currency'),
            field: 'currency',
        },
        {
            title: t('thead.approved'),
            field: 'approved',
            render: ({approved}) => approved ? <span className={`status-circle-green `}><ApprovedSVG/></span> : ''
        },
        {
            title: t('thead.action'),
            align:'right',
            render: ({_id}) => {
                return  <Can I="update" a={"company"} passThrough>
                    {(can) => can ? (
                        <button
                            type="button"
                            className={classes.editBtn}
                            onClick={() => onFbaClick(_id)}
                        >
                            <EditSVG />
                        </button>
                    ) : (
                        <Button onClick={() => onFbaClick(_id)} color="secondary" variant="contained" size={"small"}>
                            <Can I="update" a={"company"} passThrough>
                                {(can) => can ? t("formFields.edit") :  t("formFields.open") }
                            </Can>
                        </Button>
                    )}
                </Can>
            }
        },

    ]

    return (
        <div className={`${classes.item} ${classes.tableFundingBankAccounts}`}>
            {open && (
                <DialogFba
                    open={open}
                    setOpen={setOpen}
                    isLoading={isFetchingFba}
                    fundingBankAccount={fundingBankAccount}
                    title={t("company.editNewFabTitle")}
                    action={"update"}
                />
            )}
            <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={result}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    const {userFundingBankAccounts} = state;
    const {fundingBankAccounts} = userFundingBankAccounts;
    return {
        fundingBankAccounts
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {handleErrorMessage, fetchFbas})
)(FundingBankAccountsTable);

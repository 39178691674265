import React, { useEffect, useState, useRef } from "react";
import {
    makeStyles,
    useTheme,
    Button,
    FormControl,
    InputAdornment,
    Typography,
    Box,
    useMediaQuery,
    Portal
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useMounted from "../../../../hooks/useMounted";
import SelectField from "../../../formElements/SelectField";
import InputField from "../../../formElements/InputField";
// import UploadField from "../../../formElements/FileUploaderInlineInvesthub/FileUploaderInlineInvesthub";
// import InnerNav from "./Navigation/Navigation";
import Api from "../../../../services/api";
import useForm2 from "../../../../hooks/useForm2";
// import axios from "axios";
import capitalizeFirstLetter from "../../../../helpers/capitalizeFirstLetter";
import { isDarkModeActive } from "../../../App/App";

const service = new Api();
const API_URL =
    window._env_.ENVIRONMENT === "prod"
        ? "https://api.monerium.app"
        : "https://api.monerium.dev";

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start'
    },
    itemMenu: {
        position: 'sticky',
        top: '116px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '280px',
    },
    itemMenuLink: {
        backgroundColor: 'transparent !important',
        padding: '8px 16px',
        color: 'var(--battelship-grey)',
        justifyContent: 'flex-start',
        fontWeight: '600',
        height: 'initial',
        lineHeight: '1.6',
        letterSpacing: '0',
        "&:not(:last-child)": {
            marginBottom: '8px'
        },
        "& .MuiTouchRipple-root": {
            display: 'none !important'
        },
        "&.active": {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey) ' : 'var(--dark-secondary)',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary) !important' : 'var(--very-light-pink-primary) !important',
        }
    },
    itemContent: {
        flexGrow: '1',
        paddingLeft: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0'
        }
    },
    itemContentMenu: {
        display: 'block',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        '& .MuiInput-root': {
            width: '100%'
        }
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '40px',
        letterSpacing: '-.02em',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },
    itemField: {},
    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemFormSubmit: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
    itemFormFieldSetAnchor: {
        position: 'absolute',
        top: '-116px',
        left: '0',
        right: '0',
    },
}));

const MakePayment = (props) => {
    const { underBarRef, settingsDarkMode } = props;
    const classes = useStyle({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const { t } = useTranslation();
    const isMounted = useMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [tanganyWalletBalances, setTanganyWalletBalances] = useState([]);
    const [myWallets, setMyWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState();
    const [balance, setCurrentBalance] = useState(0);
    const [currency, setCurrency] = useState();
    const [balances, setBalances] = useState([]);

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    const formSections = [
        { id: 'payingForm', label: t('Paying From') },
        { id: 'payingTo', label: t('Paying To') },
        { id: 'additionalDetails', label: t('Additional Details (Optional)') },
    ];
    const [activeSection, setActiveSection] = useState('payingForm');
    const [selectedMenu, setSelectedMenu] = useState(formSections[0].id);
    const payingFromRef = useRef(null);
    const payingToRef = useRef(null);
    const additionalDetailsRef = useRef(null);

    const refs = {
        payingFrom: payingFromRef,
        payingTo: payingToRef,
        additionalDetails: additionalDetailsRef
    }

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(selectedSection);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (rect.top <= scrollPosition && rect.bottom > scrollPosition) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs, formSections])

    const getAuthContext = async (token) => {
        try {
            const response = await service.getMoneriumAuthContext(token)

            return response.data;
        } catch (error) {
            console.log(error);

            return null;
        }
    };
    const getTanganyWalletBalances = async () => {
        setIsLoading(true);
        try {
            const host = localStorage?.getItem("host");
            const { data } = await service.getTanganyWalletBalances(host);
            if (data?.wallet?.tokenBalances?.length) {
                const wallets = data.wallet.tokenBalances.map((e) => ({
                    ...e,
                    avlBal: e.balance,
                    wallet: data?.wallet.address,
                }));
                setTanganyWalletBalances(wallets);
            }
        } catch {
            setIsLoading(false);
        }
    };
    const getProfileBalances = async (profile, token) => {
        try {
            setIsLoading(true);
            const response = await service.getMoneriumProfileBalances(profile, token)

            const balanceList = [];
            if (response?.data?.length) {
                response.data.forEach((e) => {
                    if (e?.balances?.length) {
                        e.balances.forEach((b) => {
                            balanceList.push({
                                wallet: e.address,
                                currency: b.currency.toUpperCase(),
                                avlBal: parseFloat(b.amount),
                                label:
                                    capitalizeFirstLetter(e.chain) +
                                    " - " +
                                    capitalizeFirstLetter(e.network),
                            });
                        });
                    }
                });
            }
            setBalances(balanceList);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };
    const onSubmit = async () => {
        //In case wallet
        await service.createExpense({
            paidWith: "wallet",
            currency: currency, //hard set
            reportedCurrency: currency,
            reportedExchangeRate: 1,
            exchangeRateCompany: 1,
            reimbursable: true,
            date: new Date(),
            merchant: values.receiving,
            amount: values.amount,
            tax: 0,
            taxAmount: 0,
            status: "submitted",
        });
        // TODO: Add in case bank transfer
    };
    const { handleSubmit, values, errors, handleChange, fillFormValues } =
        useForm2(onSubmit);

    console.log("data", tanganyWalletBalances);

    useEffect(() => {
        const getAppData = async () => {
            const host = localStorage?.getItem("host");
            const { data } = await service.getMoneriumAuthToken(host);
            const profile = await getAuthContext(data.data.access_token);

            if (profile) {
                await getProfileBalances(profile, data.data.access_token);
            }
        };
        getTanganyWalletBalances();
        getAppData();
    }, []);

    useEffect(() => {
        if (tanganyWalletBalances?.length) {
            setMyWallets(
                tanganyWalletBalances.map((e) => {
                    return {
                        label: e.wallet + " (" + e.currency + ") ",
                        value: e.currency,
                    };
                })
            );
        }
    }, [tanganyWalletBalances]);

    const onChangeWallet = (e) => {
        const currentWallet = tanganyWalletBalances.find(
            (w) => w.currency === e.target.value
        );
        setCurrentBalance(currentWallet.balance);
        setCurrency(currentWallet.currency);
        setSelectedWallet(e.target.value);
    };
console.log(selectedWallet)
    return (
        <>
            {isMounted && (
                <>
                    <Portal container={underBarRef.current}>
                        <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                            {t("transaction.mainTitle")}
                        </Typography>
                    </Portal>
                </>
            )}

            <Box className={`card`}>
                <Box className={classes.head}>
                    <Box className={`${classes.headTitle} card-title card-title--blue`}>
                        {/* NEED TRANSLATION */}
                        {t("Make payment")}
                    </Box>
                </Box>

                <Box className={classes.item}>
                    {!onMax1023 && (
                        <Box className={classes.itemMenu}>
                            {formSections.map((section) => (
                                <Button
                                    key={section.id}
                                    className={`${classes.itemMenuLink} ${
                                        activeSection === section.id ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        scrollToSection(section.id);
                                        setActiveSection(section.id);
                                        setSelectedMenu(section.id);
                                    }}
                                >
                                    {t(section.label)}
                                </Button>
                            ))}
                        </Box>
                    )}

                    <Box className={classes.itemContent}>
                        {onMax1023 && (
                            <Box className={classes.itemContentMenu}>
                                <SelectField
                                    hideEmpty
                                    options={formSections.map(section => ({ value: section.id, label: section.label }))}
                                    onSelectChange={(selectedSection) => {
                                        scrollToSection(selectedSection);
                                        setSelectedMenu(selectedSection);
                                    }}
                                    value={selectedMenu}
                                />
                            </Box>
                        )}

                        <form
                            className={`${classes.itemForm}`}
                            onSubmit={handleSubmit}
                        >
                            <Box className={classes.itemFormFieldSet}>
                                <Box ref={payingFromRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                                <Box className={`${classes.itemTitle} card-title card-title--green`}>
                                    {/* NEED TRANSLATION */}
                                    {t('Paying From')}
                                </Box>

                                <Box className={classes.itemSettings}>
                                    <FormControl
                                        fullWidth
                                        className={`${classes.itemField}`}
                                    >
                                        {/* NEED TRANSLATION */}
                                        <SelectField
                                            label={t("Select Wallet or Safe")}
                                            options={myWallets}
                                            onChange={onChangeWallet}
                                            value={selectedWallet}
                                            settings={settingsDarkMode}
                                        />
                                    </FormControl>

                                    <Box className={classes.itemField}>
                                        {/* NEED TRANSLATION */}
                                        {t("Balance")}: {balance} {currency}
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={classes.itemFormFieldSet}>
                                <Box ref={payingToRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                                <Box className={`${classes.itemTitle} card-title card-title--purple`}>
                                    {/* NEED TRANSLATION */}
                                    {t("Paying To")}
                                </Box>

                                <Box className={classes.itemSettings}>
                                    <FormControl
                                        fullWidth
                                        className={`${classes.itemField} `}
                                    >
                                        <InputField
                                            multiline
                                            rows="4"
                                            label={t("Wallet")}
                                            name={"receiving"}
                                            value={values["receiving"]}
                                            onInputChange={(val) =>
                                                handleChange("receiving", val)
                                            }
                                        />
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        className={`${classes.itemField}`}
                                    >
                                        <InputField
                                            className={classes.currencyValue}
                                            type="number"
                                            name="amount"
                                            value={values["amount"]}
                                            onInputChange={(val) =>
                                                handleChange("amount", val)
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className={`start_block`}>
                                                        {currency || "$"}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>

                            <Box className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                                <Box ref={additionalDetailsRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                                <Box className={`${classes.itemTitle} card-title card-title--pink`}>
                                    {/* NEED TRANSLATION */}
                                    {t("Additional Details (optional)")}
                                </Box>

                                <Box className={classes.itemSettings}>
                                    <FormControl
                                        fullWidth
                                        className={`${classes.itemField}`}
                                    >
                                        {/* NEED TRANSLATION */}
                                        <SelectField
                                            label={t("Add Account")}
                                            settings={settingsDarkMode}
                                        />
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        className={`${classes.itemField} `}
                                    >
                                        {/* NEED TRANSLATION */}
                                        <InputField
                                            multiline
                                            rows="4"
                                            label={t("Add Notes")}
                                            name={"addNotes"}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>

                            <Button
                                onClick={onSubmit}
                                className={classes.itemFormSubmit}
                            >
                                {/* NEED TRANSLATION */}
                                {t("Save")}
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default MakePayment;

import * as actionTypes from "../../../../store/actionTypes";
const initialState = {
    users:{
        result: [],
        info:{
            fields:["checkbox", "email", "firstName", "lastName", "status", "role", "kyc"],
            page: 0,
            total: 0,
            pageSize: 0
        }
    }
}

const userSectionUsers = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.USERS_FETCH_SUCCESS :
            return {
                ...state,
                users:{
                    result: action.payload.info.page > 1 ? [...state.users.result, ...action.payload.result] : action.payload.result,
                    info:{
                        ...action.payload.info,
                        fields:[
                            "checkbox",
                            ...action.payload.info.fields,
                        ]
                    }
                }
            }
        case actionTypes.USERS_FETCH_FAILURE :
            return {
                ...state,
                users:{
                    result:[]
                }
            }
        case actionTypes.USER_LOGOUT:
            return initialState;
    }
    return state
}

export default userSectionUsers


import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import CompanyCountryCheck from "../CompanyCountryCheck";
import AccountsCharts from "../userSection/content/AccountsChart/AccountsChart";
import { Can } from "../../Context/Can";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";

const AccountsChartPage = (props) => {
    const { t } = useTranslation();
    const { drawerOuterRef, underBarRef, roles, settingsDarkMode } = props;
    const [canRenderAccountChart, setCanRenderAccountChart] = useState(false)

    useEffect(() => {
        if (!(roles?.length === 1 && roles[0] === "personal")) {
            setCanRenderAccountChart(true);
        }
    }, [roles]);

    return (
        <UserSectionWrapper>
            <CompanyCountryCheck>
                <Can I="read" a={"account"} passThrough>
                    {(can) => {
                        return can && canRenderAccountChart ? (
                            <>
                                <AccountsCharts
                                    drawerOuterRef={drawerOuterRef}
                                    underBarRef={underBarRef}
                                    settingsDarkMode={settingsDarkMode}
                                />
                            </>
                        ) : (
                            <div>{t("account.noPermissionToRead")}</div>
                        )}
                    }
                </Can>
            </CompanyCountryCheck>
        </UserSectionWrapper>
    )
}
const mapStateToProps = (state) => {
    const { user } = state;
    return {
        roles: user.roles,
    };
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(AccountsChartPage);

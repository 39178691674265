import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ReactComponent as CheckboxEnabled} from '../../../assets/checkboxEnabled.svg';
import { ReactComponent as CheckboxDisabled} from '../../../assets/checkboxDisabled.svg';
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";


const CheckBoxField = (props) => {

    const noHandler = () => {
        console.log(`No handler for ${name} checkbox field`);
    };

    const {value=false, error, isReadOnly, name="", label="", onCheckboxChange=noHandler, ...restProps} = props;

    const useStyles = makeStyles({
        checkbox: {
            alignItems: "flex-start",
            verticalAlign: "top",
            margin: '0',
            '& .MuiTypography-body1': {
                fontSize: "15px",
                color: error ? "#f44336" : "inherit",
                paddingTop:"6px",
                paddingLeft:"3px"
            },
            "&.Mui-disabled .MuiIconButton-label": {
                opacity: .5
            }
        },
        iconError: {
            "& rect":{
                stroke:"#f44336",
            }

        },
        checkedIconError: {
            "& rect": {
                stroke: "#f44336",
                fill: "#f44336"
            }
        }

    });

    const classes = useStyles();


    return (
        <>
            <FormControlLabel
                className={classes.checkbox}
                disabled={isReadOnly}
                control={
                    <Checkbox
                        checkedIcon={<Tooltip arrow title={error ? error : ""} placement="top" open={!!error}><CheckboxEnabled className={error ? classes.checkedIconError : null}/></Tooltip>}
                        icon={<Tooltip arrow title={error ? error : ""} placement="top" open={!!error}><CheckboxDisabled className={error ? classes.iconError : null}/></Tooltip>}
                        checked={value}
                        name={name}
                        onChange={(e) => onCheckboxChange(e.target.checked)}
                    />
                }
                label={label}
                {...restProps}
            />
        </>
    );
};

export default CheckBoxField;

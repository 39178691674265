import React from 'react'
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import RejectReasons from "../userSection/content/RejectReasons";

const RejectReasonsPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef, settingsDarkMode} = props;
    return (
        <>
            <UserSectionWrapper>
                <Can I="read" a={"rejectReason"} passThrough>
                    {(can) => (
                        can ? <RejectReasons underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} /> : <div>{t("rejectReason.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )

}

export default RejectReasonsPage

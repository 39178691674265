import React from 'react'
import {makeStyles} from "@material-ui/core";
import Item from './Item';
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({
    list: {
        margin: '0 -12px',
    },
}))

const Items = (props) => {
    const {
        notifications,
        deleteMessage,
        isDisabled,
        onClose,
        // eslint-disable-next-line no-unused-vars
        setNotifications,
        // eslint-disable-next-line no-unused-vars
        setIsDisabled,
        settings} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    return (
        <div className={classes.list}>
            {notifications.length > 0 ? (
                notifications.map((notification, idx) => {
                    const {
                        _id,
                        // eslint-disable-next-line no-unused-vars
                        type,
                        // eslint-disable-next-line no-unused-vars
                        translateKey,
                        // eslint-disable-next-line no-unused-vars
                        payload,
                        // eslint-disable-next-line no-unused-vars
                        textProperty,
                        // eslint-disable-next-line no-unused-vars
                        createdAt,
                        // eslint-disable-next-line no-unused-vars
                        status} = notification;

                    return <Item
                                key={_id}
                                notification={notification}
                                deleteMessage={deleteMessage}
                                isDisabled={isDisabled}
                                onClose={onClose}
                                settings={settings}
                            />
                })
            ) : (
                <Item notification={{translateKey:"noNotifications"}} />
            )}
        </div>
    )
}

export default Items

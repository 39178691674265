import isDateValid from "../helpers/idValidDate";
import {useTranslation} from "react-i18next";

const useExpenseValidation = (advancedSettings) => {
    const {t} = useTranslation();

    const validate = (values) => {
        let errors = {};

        const {
            expenseMerchantIsRequired,
            expenseCategoryRequired,
            expenseProjectRequired,
            expenseProjectShow,
            expenseCommentRequired,
            expenseCountryRequired,
            // expenseReimbursableActive,
        } = advancedSettings;


        if(expenseMerchantIsRequired && !values["merchant"]){
            errors["merchant"] = t('errors.required', {field: "$t(formFields.merchant)"});
        }

        if(expenseCategoryRequired && !values["category"]){
            errors["category"] = t('errors.required', {field: "$t(formFields.category)"});
        }

        if(expenseProjectRequired && expenseProjectShow && !values["project"]){
            errors["project"] = t('errors.required', {field: "$t(formFields.project)"});
        }

        if(expenseCommentRequired && !values["comment"]){
            errors["comment"] = t('errors.required', {field: "$t(formFields.comment)"});
        }
        if(expenseCountryRequired && !values["country"]){
            errors["country"] = t('errors.required', {field: "$t(formFields.country)"});
        }

        if (!values["amount"]) {
            errors["amount"] = t('errors.required', {field: "$t(formFields.amount)"});
        }

        if(!isDateValid(values["date"])){
            errors["date"] =  t('errors.notValid', {field: "$t(formFields.date)"});
        }

        return errors;
    }

    return validate

}

export default useExpenseValidation

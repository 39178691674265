import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router-dom";
import TaxRateTable from "./TaxRateTable";
import TaxRate from "./TaxRate";
import {useDispatch} from "react-redux";
import { Portal, Typography, makeStyles } from '@material-ui/core';
import Api from "../../../../services/api";
import {handleErrorMessage} from "../../../Messages/actions";
import AddTaxRate from "./AddTaxRate";
import {useTranslation} from "react-i18next";
import useMounted from '../../../../hooks/useMounted';

const service = new Api();

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}))


const TaxRates = (props) => {
    const {match, underBarRef, settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    const {url} = match;
    const dispatch = useDispatch();

    const [countries, setCountries] = useState([]);
    const [categories, setCategories] = useState([]);

    const isMounted = useMounted();

    const fetchCategories = async () => {
        try {

            const categoriesResult = await service.getCategoriesAll();

            const mappedCategories = categoriesResult.data.categories.map((category) => {
                return {
                    label: category.name,
                    value: category._id
                }
            });

            setCategories(mappedCategories);

        }catch (e) {
            console.log(e);
            handleErrorMessage(e);

        }
    }

    const fetchCountries = async () => {
        try {
            const countriesResult = await service.getServiceData({dataFor:"countries"});

            const mappedCountries = countriesResult.data.map((country) => {
                return {
                    label: country.name,
                    value: country["country-code"]
                }
            });

            setCountries(mappedCountries);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));

        }
    }


    useEffect(() => {
        fetchCategories();
        fetchCountries();
    },[]);

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}


            <Switch>
                <Route exact path={url} render={(props) => <TaxRateTable {...props} underBarRef={underBarRef} countries={countries} settingsDarkMode={settingsDarkMode}/>}/>
                <Route exact path={`${url}/add`} render={(props) => <AddTaxRate {...props} underBarRef={underBarRef} countries={countries} categories={categories} settingsDarkMode={settingsDarkMode} />}/>
                <Route exact path={`${url}/:id`} render={(props) => <TaxRate {...props} underBarRef={underBarRef} countries={countries} categories={categories} settingsDarkMode={settingsDarkMode} />}/>
            </Switch>
        </>
    );
};

export default TaxRates;

import React, {useCallback, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import InputFieldMemo from "../../../../../formElements/InputFieldMemo";
import SelectField from "../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";

import Fieldset from "../../../../../formElements/Fieldset";

const CompanyAddress = (props) => {
    const {handleChange, values, errors, types, countries, fetchData, loading, setStepFields, idx} = props;
    const {t} = useTranslation();
    useEffect(() => {
        if(types.length === 0){
            fetchData();
        }

    },[types]);


    useEffect(() => {
        // fields will be send on this step
        setStepFields((fields) => {
            return {
                ...fields,
                [idx]:[
                    "name",
                    "tradingAsOrDBA",
                    "type",
                    "addressNumber",
                    "addressRegion",
                    "addressStreet",
                    "addrl2",
                    "postCode",
                    "city",
                    "country",
                    "numberOfYearsInThisLocation"
                ]
            }
        })

    },[]);


    const memoizedCallback = useCallback(handleChange , []);

    return (
        <>
            <Fieldset title={t("kybFieldsetCompanyAddressStep")}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["name"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.companyName")}
                                placeholder={t("formFields.companyName")}
                                name="name"
                                type="text"
                                value={values["name"]}
                                onInputChange={memoizedCallback}
                                error={errors["name"]}
                                isLoading={loading}
                            />
                            <FormHelperText>{t("companyNameFull")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["tradingAsOrDBA"]} fullWidth>
                            <InputFieldMemo
                                label={`${t("formFields.tradingAsOrDBA")} ${t("formFields.optional")}`}
                                placeholder={`${t("formFields.tradingAsOrDBA")}`}
                                name="tradingAsOrDBA"
                                value={values["tradingAsOrDBA"]}
                                onInputChange={memoizedCallback}
                                error={errors["tradingAsOrDBA"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["type"]} fullWidth>
                            <SelectField
                                label={t("formFields.typeOfIncorporation")}
                                placeholder={t("formFields.typeOfIncorporation")}
                                name={"type"}
                                value={values["type"]}
                                error={errors["type"]}
                                options={types}
                                onSelectChange={(val) => handleChange("type", val)}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["addressStreet"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.addressStreet")}
                                placeholder={t("formFields.addressStreet")}
                                autoComplete='address-line1'
                                name="addressStreet"
                                value={values["addressStreet"]}
                                onInputChange={memoizedCallback}
                                error={errors["addressStreet"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["addressNumber"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.addressNumber")}
                                placeholder={t("formFields.addressNumber")}
                                name="addressNumber"
                                value={values["addressNumber"]}
                                onInputChange={memoizedCallback}
                                error={errors["addressNumber"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["addrl2"]} fullWidth>
                            <InputFieldMemo
                                label={`${t("formFields.addrl2")} ${t("formFields.optional")}`}
                                placeholder={t("formFields.addrl2")}
                                name="addrl2"
                                type="text"
                                autoComplete='address-line2'
                                value={values["addrl2"]}
                                onInputChange={memoizedCallback}
                                error={errors["addrl2"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["postCode"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.postCode")}
                                placeholder={t("formFields.postCode")}
                                name="postCode"
                                type="text"
                                value={values["postCode"]}
                                onInputChange={memoizedCallback}
                                error={errors["postCode"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["city"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.city")}
                                placeholder={t("formFields.city")}
                                name="city"
                                value={values["city"]}
                                onInputChange={memoizedCallback}
                                error={errors["city"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["addressRegion"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.addressRegion")}
                                placeholder={t("formFields.addressRegion")}
                                name="addressRegion"
                                value={values["addressRegion"]}
                                onInputChange={memoizedCallback}
                                error={errors["addressRegion"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["country"]} fullWidth>
                            <SelectField
                                label={t("formFields.country")}
                                name={"country"}
                                value={values["country"]}
                                error={errors["country"]}
                                options={countries}
                                onSelectChange={(val) => handleChange("country", val)}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["numberOfYearsInThisLocation"]} fullWidth>
                            <InputFieldMemo
                                label={t("formFields.numberOfYearsInThisLocation")}
                                placeholder={t("formFields.numberOfYearsInThisLocation")}
                                name="numberOfYearsInThisLocation"
                                type="text"
                                value={values["numberOfYearsInThisLocation"]}
                                onInputChange={memoizedCallback}
                                error={errors["numberOfYearsInThisLocation"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Fieldset>
        </>
    );
}

export default CompanyAddress

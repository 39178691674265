import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import TopComponent from "../TopComponent";
import {withRouter} from "react-router-dom";
import Pagination from "../../../../Pagination";
import queryString from "query-string";
import useExpenseTabsQuery from "../../../../../hooks/useExpenseTabsQuery";
import DrawerFilter from "../../../DrawerFilter";
import Api from "../../../../../services/api";
import {handleErrorMessage} from '../../../../Messages/actions'
import ExpenseTable from "../ExpenseTable";
import useMounted from "../../../../../hooks/useMounted";
import useExpenses from "../../../../../hooks/useExpenses";
import ExpensesFilter from "../../../DrawerFilter/ExpensesFilter";
import {makeStyles, Typography, Portal} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { isDarkModeActive } from '../../../../App/App';
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}));

const ExpensesView = (props) => {
    const {type, tabs, handleErrorMessage, location, drawerOuterRef, underBarRef, dateFormat, settingsDarkMode, decimal, tabOptions, onSelectChange} = props;
    const classes = useStyle({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    const {search} = location;
    const [expenses, setExpenses] = useExpenses();
    const [selected, setSelected] = useState([]);

    const [info, setInfo] = useState({
        fields:[], // we don't use field from backend
        page: 0,
        total: 0,
        pageSize: 0
    });

    const {page, total, pageSize} = info;
    const queryObject = queryString.parse(search);

    const [isLoading, setIsLoading] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const isMounted = useMounted();

    const tabQuery = useExpenseTabsQuery();

    const fetchExpenses = async (query) => {
        try {
            setIsLoading(true);
            const response = await service.getExpenses(query);
            const {result, info} = response.data;
            setInfo({
                ...info,
                fields:["action", ...info.fields]
            });
            setExpenses((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }
    }

    const fetchUserExpenses = async (dataQuery) => {
        setIsLoading(true);
        await fetchExpenses(dataQuery);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchUserExpenses({
            ...tabQuery,
            ...queryObject,
        });

    },[search, tabs, type]);


    const activeRoleTab = tabs.active;

    useEffect(() => {
        // reset selected
        setSelected([]);
    },[activeRoleTab, type]);


    const handleChangePage = async (event, newPage) => {
        fetchUserExpenses({
            ...tabQuery,
            ...queryObject,
            page:newPage
        });
    }

    return (
        <>
            {isMounted && (
                <>
                    <Portal container={drawerOuterRef.current}>
                        <DrawerFilter isOpen={isFilterOpen} onDrawerFilterClose={() => setIsFilterOpen(false)} settingsDarkMode={settingsDarkMode}>
                            <ExpensesFilter dateFormat={dateFormat} settingsDarkMode={settingsDarkMode} />
                        </DrawerFilter>
                    </Portal>
                    <Portal container={underBarRef.current}>
                        <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                            {t('Transactions')}
                        </Typography>
                    </Portal>
                </>
            )}

            <div className={`card`}>
                <TopComponent
                    onFilterOpen={() => setIsFilterOpen(true)}
                    isFilterOpen={isFilterOpen}
                    fetchExpenses={fetchExpenses}
                    selected={selected}
                    setSelected={setSelected}
                    settingsDarkMode={settingsDarkMode}
                    onSelectChange={onSelectChange}
                    tabOptions={tabOptions}
                    currentTab={type}
                />
                <div className="table-wrap table-wrap--have-hover table-wrap--have-line table-wrap--have-mx-offset">
                    <ExpenseTable
                        selected={selected}
                        setSelected={setSelected}
                        activeRoleTab={activeRoleTab}
                        expenses={expenses}
                        isLoading={isLoading}
                        dateFormat={dateFormat}
                        handleErrorMessage={handleErrorMessage}
                        type={type}
                        settingsDarkMode={settingsDarkMode}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const {user, tabs} = state;
    const {regional={}} = user.settings;
    const {dateFormat, decimal} = regional;
    return {
        userExpenses: state.userExpenses,
        isAuthenticated: state.user.isAuthenticated,
        tabs: tabs["transactions"],
        userId:user.userData._id,
        dateFormat,
        decimal
    }
}

export default compose(
    withRouter,
    connect( mapStateToProps, {handleErrorMessage}),
)(ExpensesView)

import React from 'react';
import Intercom from 'react-intercom';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
const intercomAppId = window._env_.INTERCOM_APP_ID;

const VolveIntercom = (props) => {
    const {email, firstName, lastName, _id} = props;

    const intercomUser = {};
    if(email) intercomUser.email = email;

    const name = [firstName, lastName].filter((item) => item).join(" ");
    if(name) intercomUser.name = name;

    if(_id) intercomUser.user_id = _id;
    if(!intercomAppId){
        return null
    }
    return (
        <Intercom
            appID={intercomAppId}
            {...intercomUser}
        />
    );
};


const mapStateToProps = ({user}) => {
    const {userData={}} = user;
    const {email, firstName, lastName, _id} = userData;

    return {
        email,
        firstName,
        lastName,
        _id,
    }
}

export default connect(mapStateToProps)(withRouter(VolveIntercom));

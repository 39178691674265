import React from 'react';
import FormHelperText from "@material-ui/core/FormHelperText";
import Skeleton from "@material-ui/lab/Skeleton";

const AccountCell = (props) => {
    const {error, value, isLoading} = props;

    return (
        <>
            {isLoading ? <Skeleton width={"100%"} height={"40px"}/> : (
                <>
                    {value}
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </>
            )}
        </>
    );
};

export default AccountCell;

import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Box, CircularProgress, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { useHistory } from "react-router-dom";
import {useDispatch} from "react-redux";
import Api from "../../../../../../services/api";
import useForm2 from "../../../../../../hooks/useForm2";
import DialogPopUp from "../../../../../DialogPopUp";
import SelectField from "../../../../../formElements/SelectField";

const useStyles = makeStyles(theme => ({
            content: {
                width: "300px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px",
                marginLeft:"auto",
                marginRight:"auto"
            },
            button: {
                marginTop: '28px'
            }
        }
    )
);

export const CURRENCY_MAPPING = {
    USD: ['USDT', 'USDC'],
    CHF: ['ZCHF'],
    SGD: ['XSGD'],
    EUR: ['EURe', 'EURC'],
    POL: ['POL'],
    ETH: ['ETH'],
};

const WalletPaymentMethods = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {setOpen, open, selectedTransaction, transferringToken, loading, setSelectedToken, selectedToken} = props;

    console.log('selectedTransaction', selectedTransaction);

    const onClose = () => {
        setOpen(false)
    }

    const onMetamaskTransfer = () => {
        return transferringToken(selectedTransaction);
    }

    const onTanganyTransfer = () => {
        history.push({
            pathname: "/balances",
            search:
                "?" +
                new URLSearchParams({
                    expenseId: selectedTransaction.id,
                    wallet: selectedTransaction?.receivingWallet,
                    amount: selectedTransaction.amount,
                    currency:
                    selectedTransaction.currency,
                }).toString(),
        });
    }

    if (!selectedTransaction) {
        return null;
    }

    return (
            <DialogPopUp
                open={open}
                onClose={onClose}
                aria-labelledby="reject-title"
                title={t("formFields.reject")}
            >
                <DialogContent className={classes.content}>
                    <FormControl fullWidth>
                        <Grid container>
                            <Grid item xs={12}>
                                {loading ?
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        Please do not close this tab until the transaction is executed.
                                        <CircularProgress />
                                    </Box> :
                                    <>
                                        { CURRENCY_MAPPING[selectedTransaction.currency] && CURRENCY_MAPPING[selectedTransaction.currency].length > 1 &&
                                            <Box className={classes.itemContentMenu}>
                                                <SelectField
                                                    label={t("formFields.selectToken")}
                                                    hideEmpty
                                                    options={CURRENCY_MAPPING[selectedTransaction.currency].map(currency => ({ value: currency, label: currency }))}
                                                    onSelectChange={(selectedSection) => {
                                                        setSelectedToken(selectedSection);
                                                    }}
                                                    value={selectedToken}
                                                />
                                            </Box>
                                        }

                                        <FormControl fullWidth >
                                            Please select your wallet payment method.
                                            {/* <Button className={classes.button} onClick={onTanganyTransfer}>Tangany wallet</Button> */}
                                            <Button className={classes.button} onClick={onMetamaskTransfer}>Metamask wallet</Button>
                                        </FormControl>
                                    </>
                                }

                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary" variant="contained" >
                        {t("formFields.cancel")}
                    </Button>
                </DialogActions>
            </DialogPopUp>
    );
};

export default WalletPaymentMethods;

import React from 'react';
import {makeStyles, Box} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles(theme => {

    return {
        headNav: {
            display: 'flex',
            marginLeft: 'auto',
            [theme.breakpoints.down('767')]: {
                width: '100%',
                // margin: '0 0 16px'
            }
        },

        headLink: {
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'all .2s',
            backgroundColor: 'transparent',
            [theme.breakpoints.down('767')]: {
                flexGrow: '1',
                marginTop: '16px'
            },
            '&:not(:last-child)': {
                marginRight: '4px',
                [theme.breakpoints.down('767')]: {
                    marginRight: '0'
                }
            },
            '&:hover': {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
            },
            '&.active': {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)'
            },
        }
    }
});


const TabsContent = (props) => {
    const {routes=[], rightContent, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <Box className={classes.headNav}>
            {routes.map((item, key) => {
                return (
                    <NavLink key={key} to={item.url} exact className={classes.headLink}>{item.name}</NavLink>
                )
            })}

            {rightContent}
        </Box>
    );
};

export default TabsContent;

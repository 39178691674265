import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import capitalizeFirstLetter from "../../../../../../helpers/capitalizeFirstLetter";

const XeroSettingStatus = (props) => {
    const {status} = props;

    let statusClass = null;
    switch (status) {
        case "connected" : statusClass = "status-green"; break;
        case "notConnected" : statusClass = "status-red"; break;
        default:  statusClass = "status-default";
    }

    const useStyles = makeStyles(theme => ({
        fontSize :{
            fontSize: "14px"
        }

    }));

    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <>
            <span className={`text-weight-600 status ${statusClass} ${classes.fontSize}`}>{t(`${capitalizeFirstLetter(status)}`)}{status === 'connected' ? '✓' : ''}</span>
        </>
    );
};

export default XeroSettingStatus;

import React from 'react';
import {Route, Switch} from "react-router-dom";
import RejectReasonsTable from "./RejectReasonsTable";

const RejectReasons = (props) => {
    const {url, underBarRef, settingsDarkMode} = props;
    return (
        <>
            <Switch>
                <Route exact path={url} render={
                    () => {
                        return <RejectReasonsTable underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                    }
                }
                />
            </Switch>
        </>
    );
};

export default RejectReasons;

import React from 'react';
import Chart from "react-apexcharts";
import {
    makeStyles,
    Box,
    CircularProgress
} from "@material-ui/core"
import {useTranslation} from "react-i18next";
import formatMoney from '../../../../../helpers/formatMoney';
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({
    boxDonuts: {
        "& .apexcharts-legend-text-category": {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '12px',
            fontWeight: '600',
        },
        "& .apexcharts-legend-text-amount": {
            fontFamily: `'Inter', sans-serif`,
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
            display: 'block',
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
        },
        "& .apexcharts-legend-text": {
            "&[rel='1']": {
                "& .apexcharts-legend-text-category": {
                    color: 'var(--light-periwinkle)'
                }
            },
            "&[rel='2']": {
                "& .apexcharts-legend-text-category": {
                    color: 'var(--pale-salmon)'
                }
            },
            "&[rel='3']": {
                "& .apexcharts-legend-text-category": {
                    color: 'var(--powder-blue)'
                }
            },
            "&[rel='4']": {
                "& .apexcharts-legend-text-category": {
                    color: 'var(--wheat)'
                }
            },
        }
    }
}));

const ExpenseChartDonut = (props) => {
    const {
        expenses,
        isLoading,
        decimal,
        currency,
        // eslint-disable-next-line no-unused-vars
        isFilterOpen,
        settingsDarkMode
    } = props;
    const {
        // eslint-disable-next-line no-unused-vars
        t
    } = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <Box className={classes.boxDonuts}>
            {isLoading ? (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {expenses.length > 0 ? (
                        <Chart
                            options={
                                {
                                    tooltip: {
                                        y: {
                                            formatter: (value) => formatMoney(value, decimal),
                                        },
                                    },
                                    chart: {
                                        type: 'donut',
                                        // events: {
                                        //     updated: getChartRef,
                                        //     mounted: getChartRef
                                        // }
                                    },
                                    colors:['#CABDFF', '#FFBC99', '#B1E5FC', '#FFD88D'],
                                    stroke: {
                                        width: 0
                                    },
                                    labels: expenses.map(({name}) => name)
                                    // formatter: (value) => formatMoney(value, decimal),
                                    ,
                                    plotOptions: {
                                        pie: {
                                            customScale: 0.8,
                                            expandOnClick: false,
                                            donut: {
                                                size: '75%',
                                            }
                                        },

                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    legend: {
                                        formatter: function(val, opts) {
                                            const amount = opts.w.globals.series[opts.seriesIndex];
                                            return `
                                                <span class="apexcharts-legend-text-category">${val}</span>
                                                <span class="apexcharts-legend-text-amount">${currency} ${formatMoney(amount, decimal)}</span>`
                                        },
                                        markers: {
                                            width: 8,
                                            height: 8,
                                            radius: 4,
                                        },
                                        itemMargin: {
                                            vertical: 5
                                        },
                                        offsetY: -20,
                                        position: "right"
                                    },
                                }
                            }
                            series={expenses.map(({totalAmountExchanged}) => parseFloat(totalAmountExchanged))}
                            type="donut"
                        />
                    ) : (
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <CircularProgress />
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default ExpenseChartDonut;

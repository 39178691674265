import React from 'react';
import {MTableBodyRow} from "material-table";
import QueueRow from "./QueueRow";

const ExpenseRow = (props) => {

    const {data, columns} = props;

    const {isExpenseQueueRow} = data;

    if(isExpenseQueueRow){
        return <QueueRow {...props} />;
    }

    return <MTableBodyRow {...props} style={{transition:'none'}}/>
};

export default ExpenseRow;

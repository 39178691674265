import React from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {
        
    },
}));

const FilterFormButton = (props) => {
    const {addtionalClass, ...restProps} = props;
    const classes = useStyles();

    return (
        <Button className={`${classes.button} ${addtionalClass}`} disableRipple {...restProps}/>
    );
};

export default FilterFormButton;

import React from 'react';

const ColumnEmployee = (props) => {
    const {user} = props;
    const {firstName, lastName} = user;

    return [firstName, lastName].filter((item) => item).join(" ")
};

export default ColumnEmployee;

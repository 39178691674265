import React from 'react'
import UnderBar from "../userSection/UnderBar";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import NotFound from "../NotFound";


const useStyles = makeStyles({
	section: {
		padding: "0px 60px 50px 50px",
	},
	sectionInner: {
		border: "1px solid #EDEFF1",
		backgroundColor:"#ffffff",
		boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
		borderRadius: 6,
		padding:30
	},
	content: {
		flexGrow: 1,
		paddingTop:"35px",
	},
});

const NotFoundPage = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <>
            <Box className={classes.content}>
                <UnderBar pageTitle={t("pageNotFound")}/>
                <Box className={classes.section}>
                    <Box className={classes.sectionInner}>
                        <NotFound/>
                    </Box>
                </Box>
            </Box>
        </>
    )

}



export default NotFoundPage

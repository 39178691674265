import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    makeStyles,
    FormControl,
    useTheme,
    useMediaQuery,
    Box,
    Button,
} from "@material-ui/core";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/logoInvesthub.svg";
import Api from "../../services/api";
import isNumeric from "../../helpers/isNumeric";
import VolveSnackbar from "../VolveSnackbar";
import DateField from "../formElements/DateField";
import InputFieldMemo from "../formElements/InputFieldMemo";
import NumericInput from "../formElements/NumericInput";
import SelectField from "../formElements/SelectField";
import ReportedExchangeRate from "../ExpenseForm/ReportedExchangeRate";
import ReportedAmount from "../ExpenseForm/ReportedAmount";
import CategoryField from "../ExpenseForm/CategoryField";
import ProjectField from "../ExpenseForm/ProjectField";
import EmployeeField from "../ExpenseForm/EmployeeField";
import CommentField from "../ExpenseForm/CommentField";
import CheckBoxField from "../formElements/CheckBoxField";
import ReceiptUploader from "../ExpenseForm/ReceiptUploader";
import NoReceipt from "../ExpenseForm/NoReceipt";
import { handleErrorMessage } from "../Messages/actions";
import { isDarkModeActive } from "../App/App";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    item: {
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
    },
    itemMenu: {
        position: "sticky",
        top: "116px",
        display: "flex",
        flexDirection: "column",
        flexShrink: "0",
        width: "280px",
    },
    itemMenuLink: {
        backgroundColor: "transparent !important",
        padding: "8px 16px",
        color: "var(--battelship-grey)",
        justifyContent: "flex-start",
        fontWeight: "600",
        height: "initial",
        lineHeight: "1.6",
        letterSpacing: "0",
        "&:not(:last-child)": {
            marginBottom: "8px",
        },
        "& .MuiTouchRipple-root": {
            display: "none !important",
        },
        "&.active": {
            color: (props) =>
                props.isDarkModeActive
                    ? "var(--pale-grey) "
                    : "var(--dark-secondary)",
            backgroundColor: (props) =>
                props.isDarkModeActive
                    ? "var(--dark-primary) !important"
                    : "var(--very-light-pink-primary) !important",
        },
    },
    itemContent: {
        flexGrow: "1",
        paddingLeft: "32px",
        [theme.breakpoints.down("1023")]: {
            paddingLeft: "0",
        },
    },
    itemContentMenu: {
        display: "block",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "24px",
        },
        "& .MuiInput-root": {
            width: "100%",
        },
    },
    itemForm: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        [theme.breakpoints.down("1023")]: {
            display: "block",
        },
    },
    itemFormFieldSet: {
        position: "relative",
        width: "100%",
        marginBottom: "48px",
        paddingBottom: "48px",
        borderBottom: (props) =>
            props.isDarkModeActive
                ? "1px solid var(--dark-primary)"
                : "1px solid var(--very-light-pink-secondary)",
        [theme.breakpoints.down("1023")]: {
            marginBottom: "32px",
            paddingBottom: "32px",
        },
    },
    itemFormFieldSetLast: {
        marginBottom: "0 !important",
        paddingBottom: "0 !important",
        border: "none !important",
    },
    itemTitle: {
        position: "relative",
        display: "inline-block",
        paddingLeft: "32px",
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "40px",
        letterSpacing: "-.02em",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            fontSize: "18px",
        },
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },

            "& $itemField": {
                flex: "0 0 calc(50% - 16px)",
                width: "calc(50% - 16px)",
                margin: "0 8px",
                [theme.breakpoints.down("1023")]: {
                    width: "100%",
                    margin: "0",
                },
            },
        },
    },
    itemField: {},
    itemRow: {
        display: "flex",
        margin: "0 -8px",
        [theme.breakpoints.down("1023")]: {
            display: "block",
            margin: "0",
        },
        "& $itemField": {
            [theme.breakpoints.up("1023")]: {
                marginBottom: "0 !important",
            },
        },
    },
    itemFormFieldSetAnchor: {
        position: "absolute",
        top: "-116px",
        left: "0",
        right: "0",
    },
    itemFormSubmit: {
        marginTop: "48px",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "12px",
        [theme.breakpoints.down("1023")]: {
            marginTop: "32px",
        },
    },
}));

const VendorForm = (props) => {
    const {
        handleErrorMessage,
        dateFormat,
        handleChange,
        handleSubmit,
        values,
        errors,
        decimal,
        // eslint-disable-next-line no-unused-vars
        paidWith = [],
        taxRatesByCountry,
        setTaxRatesByCountry,
        checkDisabledField,
        currencies = [],
        countries = [],
        isLoading,
        formMessage,
        advancedSettings,
        onDateChange,
        onCurrencyChange,
        rateIsLoading,
        employee,
        actionButtons,
        settingsDarkMode,
    } = props;

    const { expenseProjectShow } = advancedSettings;

    const { t } = useTranslation();

    const [taxRates, setTaxRates] = useState([]);
    const [isTaxRateLoading, setIsTaxRateLoading] = useState(false);

    const [isTaxDisabled, setIsTaxDisabled] = useState(false);
    const [isTaxAmountDisabled, setIsTaxAmountDisabled] = useState(false);
    const [vendorsList, setVendorsList] = useState(null);
    const [isVendorsLoading, setIsVendorLoading] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });

    const calculateTax = (amount, value) => {
        const intAmount = parseFloat(amount);
        const intTax = parseFloat(value);
        let result; // undefined

        if (isNumeric(intAmount) && isNumeric(intTax)) {
            result = (intAmount / (intAmount - intTax) - 1) * 100;
            result = result.toFixed(1);
            result = parseFloat(result);
        }

        return result;
    };

    const calculateAmountTax = (amount, tax) => {
        let result; // undefined

        const intAmount = parseFloat(amount);
        const intTax = parseFloat(tax);

        if (isNumeric(intAmount) && isNumeric(intTax)) {
            result = intAmount - intAmount / (1 + intTax / 100);
            result = result.toFixed(2);
            result = parseFloat(result);
        }
        return result;
    };

    const onTaxChange = (name, value) => {
        const newAmountTax = calculateAmountTax(values["amount"], value);
        handleChange("tax", value);
        handleChange("taxAmount", newAmountTax);
    };

    const onTaxAmountChange = (value, amount) => {
        const newTax = calculateTax(amount, value);
        handleChange("taxAmount", value);

        handleChange("tax", newTax);
    };

    const {
        amount,
        // eslint-disable-next-line no-unused-vars
        status,
        country,
        category,
        // eslint-disable-next-line no-unused-vars
        currency,
        tax,
        taxAmount,
        receiptUri,
        noReceiptReason,
    } = values;

    useEffect(() => {
        if (receiptUri && noReceiptReason) {
            handleChange("noReceiptReason"); // remove noReceiptReason
        }
    }, [receiptUri, noReceiptReason]);

    useEffect(() => {
        if (
            values["country"] &&
            values["taxRate"] &&
            values["taxRate"] !== "other"
        ) {
            setIsTaxAmountDisabled(true);
            setIsTaxDisabled(true);
        } else {
            setIsTaxAmountDisabled(false);
            setIsTaxDisabled(false);
        }
    }, [values["taxRate"], values["country"]]);

    useEffect(() => {
        let taxRates = [];

        if (country) {
            if (category) {
                // filtered with `category` and `country` field
                taxRates = taxRatesByCountry.filter((taxRate) => {
                    const { categories } = taxRate;
                    return (
                        categories.length === 0 || categories.includes(category)
                    );
                });
                setTaxRates(taxRates);
            } else {
                // filtered with `country` field
                taxRates = taxRatesByCountry;
            }
        }

        setTaxRates(taxRates);
    }, [category, country, taxRatesByCountry]);

    const selectTaxRateByCategory = (taxRatesByCountry, category) => {
        if (taxRatesByCountry.length) {
            // if category is selected - try to find taxRate with this category
            const taxRateByCategory = category
                ? taxRatesByCountry.find(({ categories }) =>
                      categories.includes(category)
                  )
                : undefined;

            // if no taxRateByCategory - try to find first taxRate without any category
            const foundTax = taxRateByCategory
                ? taxRateByCategory
                : taxRatesByCountry.find(
                      ({ categories }) => categories.length === 0
                  );

            if (foundTax) {
                handleChange("taxRate", foundTax.value);
                onTaxChange("tax", foundTax.taxRate);
            } else {
                if (values["taxRate"]) {
                    handleChange("taxRate", "other");
                }
            }
        } else {
            if (values["taxRate"]) {
                handleChange("taxRate", "other");
            }
        }
    };

    const onCategoryChange = (val) => {
        selectTaxRateByCategory(taxRatesByCountry, val);

        handleChange("category", val);
    };

    const onCountryChange = async (value) => {
        try {
            setIsTaxRateLoading(true);
            handleChange("country", value);

            if (value) {
                const response = await service.getTaxRates({
                    countries: value,
                    "no-limit": true,
                });
                const mappedTaxRates = response.data.result.map(
                    ({ name, _id, taxRate, categories }) => ({
                        label: name,
                        value: _id,
                        taxRate,
                        categories,
                    })
                );
                selectTaxRateByCategory(mappedTaxRates, values["category"]);
                setTaxRatesByCountry(mappedTaxRates);
            } else {
                handleChange("tax", "");
                handleChange("taxAmount", "");
                handleChange("taxRate", "");
                setTaxRatesByCountry([]);
            }
        } catch (e) {
            console.log(e);
            handleErrorMessage(e);
        } finally {
            setIsTaxRateLoading(false);
        }
    };

    const onTaxRateChange = (val) => {
        handleChange("taxRate", val);
        if (val && val !== "other") {
            const foundTaxRate = taxRatesByCountry.find(
                (taxRate) => taxRate.value === val
            );
            if (foundTaxRate && isNumeric(foundTaxRate.taxRate)) {
                onTaxChange("tax", foundTaxRate.taxRate);
            }
        }
    };

    const onVendorChange = (val) => {
        handleChange("vendor", val);
        const selectedVendor = vendorsList.find((e) => e.value === val);
        if (selectedVendor) {
            handleChange("vendorWalletAddress", selectedVendor.wallet);
            handleChange("iban", selectedVendor.iban);
            onCategoryChange(selectedVendor.category);
            if (selectedVendor?.country) {
                onCountryChange(selectedVendor.country)
            }
        }
    };

    const onAmountChange = (name, value) => {
        handleChange("amount", value);
        const newAmountTax = calculateAmountTax(value, values["tax"]);
        handleChange("taxAmount", newAmountTax);
    };

    const memoizedCallback = useCallback(handleChange, []);
    const memoizedTaxChange = useCallback(onTaxChange, [amount]);
    const memoizedAmountChange = useCallback(onAmountChange, [tax, taxAmount]);
    const fetchContactsList = async () => {
        try {
            setIsVendorLoading(true);
            const response = await service.getContacts();
            const mappedVendors = response.data.result.map((contact) => {
                const {
                    _id,
                    companyName,
                    firstName,
                    lastName,
                    walletAddress,
                    iban,
                    country,
                    category
                } = contact;
                return {
                    value: _id,
                    label: companyName
                        ? companyName
                        : firstName + " " + lastName,
                    wallet: walletAddress,
                    iban: iban,
                    country: country,
                    category: category
                };
            });
            setVendorsList(mappedVendors);
        } catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        } finally {
            setIsVendorLoading(false);
        }
    };

    useEffect(() => {
        fetchContactsList();
        return () => {};
    }, []);

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    // NEED TRANSLATION
    const formSections = [
        { id: "paymentDetails", label: t("Payment Details") },
        { id: "financialInformation", label: t("Financial Information") },
        { id: "projectAndLocation", label: "Project and Location" },
        { id: "taxDetails", label: "Tax Details" },
        { id: "additionalInformation", label: "Additional Information" },
        { id: "reimbursementAndActions", label: "Reimbursement and Actions" },
    ];

    const [activeSection, setActiveSection] = useState("paymentDetails");
    const [selectedMenu, setSelectedMenu] = useState(formSections[0].id);

    const paymentDetailsRef = useRef(null);
    const financialInformationRef = useRef(null);
    const projectAndLocationRef = useRef(null);
    const taxDetailsRef = useRef(null);
    const additionalInformationRef = useRef(null);
    const reimbursementAndActionsRef = useRef(null);

    const refs = {
        paymentDetails: paymentDetailsRef,
        financialInformation: financialInformationRef,
        projectAndLocation: projectAndLocationRef,
        taxDetails: taxDetailsRef,
        additionalInformation: additionalInformationRef,
        reimbursementAndActions: reimbursementAndActionsRef,
    };

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: "smooth" });
            setActiveSection(selectedSection);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (
                        rect.top <= scrollPosition &&
                        rect.bottom > scrollPosition
                    ) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [refs, formSections]);

    return (
        <div className={classes.item}>
            {!onMax1023 && (
                <Box className={classes.itemMenu}>
                    {formSections.map((section) => (
                        <Button
                            key={section.id}
                            className={`${classes.itemMenuLink} ${
                                activeSection === section.id ? "active" : ""
                            }`}
                            onClick={() => {
                                scrollToSection(section.id);
                                setActiveSection(section.id);
                                setSelectedMenu(section.id);
                            }}
                        >
                            {t(section.label)}
                        </Button>
                    ))}
                </Box>
            )}

            <Box className={classes.itemContent}>
                {onMax1023 && (
                    <Box className={classes.itemContentMenu}>
                        <SelectField
                            hideEmpty
                            options={formSections.map((section) => ({
                                value: section.id,
                                label: section.label,
                            }))}
                            onSelectChange={(selectedSection) => {
                                scrollToSection(selectedSection);
                                setSelectedMenu(selectedSection);
                            }}
                            value={selectedMenu}
                        />
                    </Box>
                )}

                <form
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    noValidate
                    className={classes.itemForm}
                >
                    {formMessage && (
                        <VolveSnackbar
                            message={<>{formMessage}</>}
                            icon={<Logo />}
                        />
                    )}

                    <Box className={classes.itemFormFieldSet}>
                        <Box
                            ref={paymentDetailsRef}
                            className={classes.itemFormFieldSetAnchor}
                        ></Box>
                        <Box
                            className={`${classes.itemTitle} card-title card-title--green`}
                        >
                            {/* NEED TRANSLATION */}
                            {t("Payment Date")}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <Box className={classes.itemRow}>
                                <FormControl
                                    fullWidth
                                    className={`${classes.itemField}`}
                                    error={!!errors.date}
                                >
                                    <DateField
                                        label={t("Payment Date")}
                                        disableFuture
                                        placeholder={t("formFields.date")}
                                        disabled={checkDisabledField("date")}
                                        dateFormat={dateFormat}
                                        onDateChange={(date) =>
                                            onDateChange(date)
                                        }
                                        value={values["date"]}
                                        error={errors["date"]}
                                        isLoading={isLoading}
                                        name="date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    className={`${classes.itemField}`}
                                    error={!!errors.merchant}
                                >
                                    <SelectField
                                        name={"vendor"}
                                        label={t("formFields.vendor")}
                                        placeholder={t("formFields.vendor")}
                                        value={values["vendor"]}
                                        error={errors["vendor"]}
                                        disabled={checkDisabledField("vendor")}
                                        onSelectChange={(val) =>
                                            onVendorChange(val)
                                        }
                                        isLoading={isVendorsLoading}
                                        options={vendorsList}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>
                            </Box>

                            <Box className={classes.itemRow}>
                                <FormControl
                                    fullWidth
                                    error={!!errors.amount}
                                    className={`${classes.itemField}`}
                                >
                                    <NumericInput
                                        decimal={decimal}
                                        name={"amount"}
                                        maskProps={{
                                            signed: checkDisabledField("amount"),
                                        }}
                                        label={t("formFields.amount")}
                                        placeholder={t("formFields.amount")}
                                        value={values["amount"]}
                                        error={errors["amount"]}
                                        disabled={checkDisabledField("amount")}
                                        onInputChange={memoizedAmountChange}
                                        isLoading={isLoading}
                                    />
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={!!errors.currency}
                                    className={`${classes.itemField}`}
                                >
                                    <SelectField
                                        label={t("formFields.currency")}
                                        name={"currency"}
                                        value={values["currency"]}
                                        error={errors.currency}
                                        options={currencies}
                                        disabled={checkDisabledField(
                                            "currency"
                                        )}
                                        onSelectChange={(val) =>
                                            onCurrencyChange(val)
                                        }
                                        isLoading={isLoading}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>
                            </Box>

                            <Box className={classes.itemRow}>
                                <FormControl
                                    fullWidth
                                    className={`${classes.itemField} ${classes.fixFieldSpace} ${classes.exchangeRateWrapper}`}
                                >
                                    <ReportedExchangeRate
                                        isLoading={isLoading || rateIsLoading}
                                        decimal={decimal}
                                        value={values["reportedExchangeRate"]}
                                    />
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    className={classes.itemFielld}
                                >
                                    <ReportedAmount
                                        reportedExchangeRate={
                                            values["reportedExchangeRate"]
                                        }
                                        reportedCurrency={
                                            values["reportedCurrency"]
                                        }
                                        amount={values["amount"]}
                                        isLoading={isLoading || rateIsLoading}
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.itemFormFieldSet}>
                        <Box
                            ref={financialInformationRef}
                            className={classes.itemFormFieldSetAnchor}
                        >
                            {" "}
                        </Box>
                        <Box
                            className={`${classes.itemTitle} card-title card-title--purple`}
                        >
                            {/* NEED TRANSLATIOn */}
                            {t("Financial Information")}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <Box className={classes.itemRow}>
                                <FormControl
                                    fullWidth
                                    error={!!errors["category"]}
                                    className={`${classes.itemField}`}
                                >
                                    <CategoryField
                                        value={values["category"]}
                                        error={errors["category"]}
                                        disabled={checkDisabledField(
                                            "category"
                                        )}
                                        onSelectChange={(val) =>
                                            onCategoryChange(val)
                                        }
                                        isLoading={isLoading}
                                    />
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={!!errors["iban"]}
                                    className={`${classes.itemField}`}
                                >
                                    <InputFieldMemo
                                        name={"iban"}
                                        label={t("formFields.iban")}
                                        // placeholder={t("formFields.vendor")}
                                        value={values["iban"]}
                                        error={errors["iban"]}
                                        disabled={true}
                                        onInputChange={memoizedCallback}
                                        isLoading={isLoading}
                                    />
                                </FormControl>
                            </Box>

                            <FormControl
                                fullWidth
                                error={!!errors["vendorWalletAddress"]}
                                className={`${classes.itemField}`}
                            >
                                <InputFieldMemo
                                    name={"vendorWalletAddress"}
                                    label={t("formFields.walletAddress")}
                                    // placeholder={t("formFields.vendor")}
                                    value={values["vendorWalletAddress"]}
                                    error={errors["vendorWalletAddress"]}
                                    disabled={true}
                                    onInputChange={memoizedCallback}
                                    isLoading={isLoading}
                                />
                            </FormControl>

                            {values["transactionStatus"] && (
                                <FormControl
                                    fullWidth
                                    className={`${classes.itemField}`}
                                    error={!!errors.transactionStatus}
                                >
                                    <InputFieldMemo
                                        name={"transactionStatus"}
                                        label={t(
                                            "formFields.transactionStatus"
                                        )}
                                        placeholder={t(
                                            "formFields.transactionStatus"
                                        )}
                                        value={
                                            values["transactionStatus"] &&
                                            t(
                                                `expenseTransactionStatus.${values["transactionStatus"]}`
                                            )
                                        }
                                        error={errors["transactionStatus"]}
                                        disabled={true}
                                        onInputChange={memoizedCallback}
                                        isLoading={isLoading}
                                    />
                                </FormControl>
                            )}
                        </Box>
                    </Box>

                    <Box className={classes.itemFormFieldSet}>
                        <Box
                            ref={projectAndLocationRef}
                            className={classes.itemFormFieldSetAnchor}
                        >
                            {" "}
                        </Box>
                        <Box
                            className={`${classes.itemTitle} card-title card-title--red`}
                        >
                            {/* NEED TRANSALATION */}
                            {t("Project and Location")}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <Box className={classes.itemRow}>
                                {expenseProjectShow && (
                                    <FormControl
                                        fullWidth
                                        error={!!errors["project"]}
                                        className={`${classes.itemField}`}
                                    >
                                        <ProjectField
                                            value={values["project"]}
                                            error={errors["project"]}
                                            disabled={checkDisabledField(
                                                "project"
                                            )}
                                            onSelectChange={(val) =>
                                                handleChange("project", val)
                                            }
                                            isLoading={isLoading}
                                        />
                                    </FormControl>
                                )}

                                {employee && (
                                    <FormControl
                                        fullWidth
                                        className={classes.itemField}
                                    >
                                        <EmployeeField employee={employee} />
                                    </FormControl>
                                )}

                                <FormControl
                                    fullWidth
                                    error={!!errors["country"]}
                                    className={`${classes.itemField}`}
                                >
                                    <SelectField
                                        label={t("formFields.country")}
                                        name={"country"}
                                        isLoading={isLoading}
                                        value={values["country"]}
                                        error={errors["country"]}
                                        options={countries}
                                        disabled={checkDisabledField("country")}
                                        onSelectChange={(val) =>
                                            onCountryChange(val)
                                        }
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.itemFormFieldSet}>
                        <Box
                            ref={taxDetailsRef}
                            className={classes.itemFormFieldSetAnchor}
                        >
                            {" "}
                        </Box>
                        <Box
                            className={`${classes.itemTitle} card-title card-title--yellow`}
                        >
                            {/* NEED TRANSLATION */}
                            {t("Tax Details")}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <Box className={classes.itemRow}>
                                <FormControl
                                    fullWidth
                                    className={`${classes.itemField} `}
                                >
                                    <SelectField
                                        label={t("formFields.taxRate")}
                                        name={"taxRate"}
                                        value={values["taxRate"]}
                                        error={errors["taxRate"]}
                                        options={[
                                            {
                                                value: "other",
                                                label: t("expense.other"),
                                            },
                                            ...taxRates,
                                        ]}
                                        disabled={checkDisabledField("taxRate")}
                                        onSelectChange={(val) =>
                                            onTaxRateChange(val)
                                        }
                                        isLoading={
                                            isLoading || isTaxRateLoading
                                        }
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    className={`${classes.itemField}`}
                                >
                                    <NumericInput
                                        decimal={decimal}
                                        name={"tax"}
                                        label={t("formFields.tax")}
                                        placeholder={t("formFields.tax")}
                                        value={values["tax"]}
                                        error={errors["tax"]}
                                        maskProps={{ scale: 1 }}
                                        disabled={
                                            checkDisabledField("tax") ||
                                            isTaxDisabled
                                        }
                                        onInputChange={memoizedTaxChange}
                                        isLoading={
                                            isLoading || isTaxRateLoading
                                        }
                                    />
                                </FormControl>
                            </Box>

                            <FormControl
                                fullWidth
                                className={`${classes.itemField}`}
                            >
                                <NumericInput
                                    decimal={decimal}
                                    name={"taxAmount"}
                                    label={t("formFields.taxAmount")}
                                    placeholder={t("formFields.taxAmount")}
                                    value={values["taxAmount"]}
                                    error={errors["taxAmount"]}
                                    disabled={
                                        checkDisabledField("taxAmount") ||
                                        isTaxAmountDisabled
                                    }
                                    onInputChange={(name, value) =>
                                        onTaxAmountChange(
                                            value,
                                            values["amount"]
                                        )
                                    }
                                    isLoading={isLoading || isTaxRateLoading}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    <Box className={classes.itemFormFieldSet}>
                        <Box
                            ref={additionalInformationRef}
                            className={classes.itemFormFieldSetAnchor}
                        >
                            {" "}
                        </Box>
                        <Box
                            className={`${classes.itemTitle} card-title card-title--pink`}
                        >
                            {/* NEED TRANSLATIONS */}
                            {t("Additional Information")}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <FormControl
                                fullWidth
                                className={`${classes.itemField}`}
                            >
                                <CommentField
                                    placeholder={
                                        !checkDisabledField("comment")
                                            ? t("formFields.addComment")
                                            : null
                                    }
                                    value={values["comment"]}
                                    error={errors["comment"]}
                                    disabled={checkDisabledField("comment")}
                                    onInputChange={memoizedCallback}
                                    isLoading={isLoading}
                                    settingsDarkMode={settingsDarkMode}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    <Box
                        className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}
                    >
                        <Box
                            ref={reimbursementAndActionsRef}
                            className={classes.itemFormFieldSetAnchor}
                        >
                            {" "}
                        </Box>
                        <Box
                            className={`${classes.itemTitle} card-title card-title--pop`}
                        >
                            {/* NEED TRANSLATION */}
                            {t("Reimbursement and Actions")}
                        </Box>

                        <Box className={classes.itemSettings}>
                            <FormControl
                                fullWidth
                                className={classes.itemField}
                            >
                                <CheckBoxField
                                    onCheckboxChange={(val) =>
                                        handleChange("reimbursable", !!val)
                                    }
                                    value={values["reimbursable"]}
                                    label={t("formFields.reimbursable")}
                                    name={"reimbursable"}
                                    error={errors["reimbursable"]}
                                    disabled={checkDisabledField(
                                        "reimbursable"
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth
                                className={classes.itemField}
                            >
                                <ReceiptUploader
                                    handleErrorMessage={handleErrorMessage}
                                    value={values.receiptUri}
                                    disabled={checkDisabledField("receiptUri")}
                                    handleChange={handleChange}
                                    isLoading={isLoading}
                                />
                                {!values["receiptUri"] && (
                                    <NoReceipt
                                        value={values["noReceiptReason"]}
                                        disabled={
                                            checkDisabledField(
                                                "noReceiptReason"
                                            ) || isLoading
                                        }
                                        handleChange={handleChange}
                                    />
                                )}
                            </FormControl>
                        </Box>
                    </Box>

                    <Box className={classes.itemFormSubmit}>
                        {actionButtons}
                    </Box>
                </form>
            </Box>
        </div>
    );
};

export default compose(connect(null, { handleErrorMessage }))(VendorForm);

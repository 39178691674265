import React from 'react'
import {withRouter, Route, Switch} from 'react-router-dom'
import UsersTable from './UsersTable'
import User from "./UsersTable/User";

const Users = (props) => {
    const {match, drawerOuterRef, underBarRef, settingsDarkMode} = props;
    return (
        <>
            <Switch>
                <Route exact path={match.path} render={() => <UsersTable drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />}/>
                <Route path={`${match.path}/:id`} render={() => <User underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />}/>
            </Switch>
        </>
    )
}

export default withRouter(Users)

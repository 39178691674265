import React, {useContext, useEffect, useState} from 'react';
import useMounted from "../../../../../hooks/useMounted";
import {useTranslation} from "react-i18next";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../../../services/api";
import Permissions from "./Permissions";
import {
    Box,
    Portal,
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import useForm2 from "../../../../../hooks/useForm2";
import {updateConfigurablePermissions} from "../../../../User/actions";
import Skeleton from "react-loading-skeleton";
import {AbilityContext} from "../../../../../Context/Can";
import { isDarkModeActive } from '../../../../App/App';

const service = new Api();

const useStyles = makeStyles(theme => ({
    // Page title.
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },

    // Header card
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },

    // Button
    button: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },

}))

const UserPermissionsForm = (props) => {
    const {underBarRef, settingsDarkMode} = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const isMounted = useMounted();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const configurablePermissions = useSelector(state => {
        const {userData} = state.user;
        const {company={}} = userData;
        const {configurablePermissions = {}} = company;
        return configurablePermissions;
    });

    const ability = useContext(AbilityContext);
    const canUpdate = ability.can("update:permissions", "company");

    useEffect(() => {
        fetchData()
    },[]);


    const [isFetching, setIsFetching] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [roles, setRoles] = useState([]);


    const fetchData = async () => {
        try{
            setIsFetching(true);
            const rolesResponse = await service.getServiceData({dataFor:"roles"});
            const {roles} = rolesResponse.data;
            const mappedRoles = roles
                .filter((role) => !['personal', 'administrator'].includes(role.id)); // delete 'personal' and 'administrator' role
            setRoles(mappedRoles);

        }catch (e) {
            dispatch(handleErrorMessage(e));
        }finally {
            setIsFetching(false);
        }
    }


    useEffect(() => {

        const fillValues = Object.keys(configurablePermissions).reduce((accum, role) => {
            Object.keys(configurablePermissions[role]).forEach((permission) => {
                const fieldName = `configurablePermissions[${role}][${permission}]`;
                accum[fieldName] = configurablePermissions[role][permission];
            });
            return accum
        },{});

        fillFormValues(fillValues);

    },[configurablePermissions]);

    const submit = async () => {

        try{
            const data = new FormData();
            Object.keys(values).map((field) => {
                data.append(field, values[field]);
            });
            setIsSending(true);
            const response = await service.updateCompanyPermissions(data);
            const {configurablePermissions} = response.data;
            dispatch(updateConfigurablePermissions(configurablePermissions));
            dispatch(setMessage("success", 'success'));
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsSending(false);
        }
    }

    const validate = () => ({});


    const { handleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>{t("userPermissions.mainTitle")}</Typography>
                </Portal>
            )}
            <div className="card">
                <form action="#" onSubmit={handleSubmit} className={classes.itemContent}>
                    {isFetching ? (
                        <Box paddingBottom={"20px"}>
                            <Skeleton height={"40px"} width={"100px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                        </Box>
                    ) : (
                        roles.map((role, index, array) => {
                            const colorClasses = ['card-title--blue', 'card-title--purple', 'card-title--yellow', 'card-title--red', 'card-title--green'];
                            const colorClass = colorClasses[index % colorClasses.length];

                            const isLastIndex = index === array.length - 1;

                            return (
                                <React.Fragment key={role.id}>
                                    <div className={classes.head}>
                                        <div className={`${classes.headTitle} card-title ${colorClass}`}>
                                            {role.name}
                                        </div>
                                    </div>
                                    <Permissions
                                        role={role.id}
                                        handleChange={handleChange}
                                        values={values}
                                        disabled={isSending || !canUpdate}
                                        settingsDarkMode={settingsDarkMode}
                                        isLastIndex={isLastIndex}
                                    />
                                </React.Fragment>
                            )
                        })
                    )}
                    <Button
                        className={classes.button}
                        onClick={handleSubmit}
                        disabled={isFetching || isSending || !canUpdate}
                    >
                        {t("formFields.submit")}
                    </Button>
                </form>
            </div>
        </>
    );
};

export default UserPermissionsForm;

import React from 'react';
import {makeStyles} from "@material-ui/core";

const MainWrapper = (props) => {

    const useStyle = makeStyles(theme => ({
        pageInner: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 96px)',
            padding: '40px',
            [theme.breakpoints.down('1339')]: {
                padding: '32px 24px'
            },
            [theme.breakpoints.down('767')]: {
                padding: '24px 16px',
            }
        },
        pageContainerWide: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            width: '100%',
            maxWidth: '100%',
            margin: '0 auto'
        }
    }));
    const classes = useStyle();

    return (
        <main className={classes.pageInner}>
            <div className={classes.pageContainerWide}>
                {props.children}
            </div>
        </main>
    );
};

export default MainWrapper;

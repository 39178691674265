import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { IN_OUT_TRANSACTIONS } from "../constants/transactions";

const useExpenseTabsQuery = () => {
    const tabsExpenses = useSelector((state) => state.tabs["transactions"]);
    const userId = useSelector((state) => state.user.userData._id);
    const location = useLocation();

    const matchedPath = matchPath(location.pathname, {
        path: "/transactions/:tab",
        exact: true,
        strict: false,
    });

    if (!matchedPath) {
        return {};
    }
    const matchTab = matchedPath.params.tab;
    switch (tabsExpenses.active) {
        case "administrator":
            if (matchTab === "to-submit") {
                return { status: ["toSubmit", "rejected"] };
            } else if (matchTab === "todo") {
                return { status: ["submitted"] };
            } else if (matchTab === "to-pay") {
                return { status: "approved" };
            } else if (IN_OUT_TRANSACTIONS.includes(matchTab)) {
                return { type: "executed-transactions" };
            } else {
                //all
                return {};
            }

        case "auditor":
            if (matchTab === "to-submit") {
                return { status: ["toSubmit", "rejected"] };
            } else if (matchTab === "todo") {
                return { status: "submitted" };
            } else if (matchTab === "to-pay") {
                return { status: "approved" };
            } else if (IN_OUT_TRANSACTIONS.includes(matchTab)) {
                return { type: "executed-transactions" };
            } else {
                return {};
            }
        case "approver":
            if (matchTab === "to-submit") {
                return { approver: userId, status: ["toSubmit", "rejected"] };
            } if (matchTab === "todo") {
                return { approver: userId, status: "submitted" };
            } else if (matchTab === "to-pay") {
                return { approver: userId, status: "approved" };
            } else if (matchTab === "executed-transactions") {
                return { type: "executed-transactions" };
            }
            else {
                return { approver: userId };
            }
        default:
            if (matchTab === "to-submit") {
                return { user: userId, status: ["toSubmit", "rejected"] };
            } else if (matchTab === "todo") {
                return { user: userId, status: "submitted" };
            } else if (matchTab === "to-pay" ) {
                return { status: "approved", user: userId };
            } else if (matchTab === "executed-transactions") {
                return { type: "executed-transactions", user: userId };
            }
            else {
                return { user: userId };
            }
    }
};

export default useExpenseTabsQuery;

import React, { useRef, useState, useEffect } from 'react'
import {
    FormControl,
    Button,
    makeStyles,
    FormLabel,
    // FormHelperText,
    useTheme,
    useMediaQuery,
    Box
} from "@material-ui/core";
import AvatarUploader from "../../../../AvatarUploader";
import PhoneField from "../../../../formElements/PhoneField";
import InputField from "../../../../formElements/InputField";
import SelectField from "../../../../formElements/SelectField";
import RadioFields from "../../../../formElements/RadioFields";
import {useTranslation} from "react-i18next";
// import CheckBoxField from "../../../../formElements/CheckBoxField";
import ToggleCheckboxField from '../../../../formElements/ToggleCheckboxField/ToggleCheckboxField';
import ToggleFieldWrapper from '../../../../formElements/ToggleFieldWrapper/ToggleFieldWrapper';
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles((theme) => ({
    item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start'
    },
    itemMenu: {
        position: 'sticky',
        top: '116px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '280px',
    },
    itemMenuLink: {
        backgroundColor: 'transparent !important',
        padding: '8px 16px',
        color: 'var(--battelship-grey)',
        justifyContent: 'flex-start',
        fontWeight: '600',
        height: 'initial',
        lineHeight: '1.6',
        letterSpacing: '0',
        "&:not(:last-child)": {
            marginBottom: '8px'
        },
        "& .MuiTouchRipple-root": {
            display: 'none !important'
        },
        "&.active": {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey) ' : 'var(--dark-secondary)',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary) !important' : 'var(--very-light-pink-primary) !important',
        }
    },
    itemContent: {
        flexGrow: '1',
        paddingLeft: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0'
        }
    },
    itemContentMenu: {
        display: 'block',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        '& .MuiInput-root': {
            width: '100%'
        }
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '40px',
        letterSpacing: '-.02em',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },

    // Wrapper fields
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemBox: {
        width: '100%',
        height: '48px',
        padding: '0 10px',
        borderRadius: '12px',
        border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid transparent',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
        transition: 'all .2s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px'
    },
    itemFormSubmit: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
    itemFormFieldSetAnchor: {
        position: 'absolute',
        top: '-116px',
        left: '0',
        right: '0',
    },

    itemFieldImage:{
        width: '280px',
        [theme.breakpoints.down('1023')]: {
            width: '210px'
        },
    },
    itemRadio: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px'
        }
    },

}));

const ProfileForm = (props) => {
    const {
        roles=[],
        countries,
        titles,
        groups,
        branches,
        departments,
        handleChange,
        handleSubmit,
        errors,
        values,
        isDisabled,
        customerNumber,
        ability,
        isDataFetching,
        settings
    } = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const {t} = useTranslation();

    const checkDisabledStatus = (field) => {
        return !ability.can("update", "user", field)
    }

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    // NEED TRANSLATION
    const formSections = [
        { id: 'personal', label: t('Personal') },
        { id: 'contact', label: t('Contact') },
        { id: 'professional', label: t('Professional') },
        { id: 'preferences', label: ('Preferences') },
    ];
    const [activeSection, setActiveSection] = useState('personal');
    const [selectedMenu, setSelectedMenu] = useState(formSections[0].id);
    const personalRef = useRef(null);
    const contactRef = useRef(null);
    const professionalRef = useRef(null);
    const preferencesRef = useRef(null);

    const refs = {
        personal: personalRef,
        contact: contactRef,
        professional: professionalRef,
        preferences: preferencesRef,
    };

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(selectedSection);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (rect.top <= scrollPosition && rect.bottom > scrollPosition) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs, formSections])


    return (
        <div className={classes.item}>

            {!onMax1023 && (
                <div className={classes.itemMenu}>
                    {formSections.map((section) => (
                        <Button
                            key={section.id}
                            className={`${classes.itemMenuLink} ${
                                activeSection === section.id ? 'active' : ''
                            }`}
                            onClick={() => {
                                scrollToSection(section.id);
                                setActiveSection(section.id);
                                setSelectedMenu(section.id);
                            }}
                        >
                            {t(section.label)}
                        </Button>
                    ))}
                </div>
            )}
            
            <div className={classes.itemContent}>
                {onMax1023 && (
                    <Box className={classes.itemContentMenu}>
                        <SelectField
                            hideEmpty
                            options={formSections.map(section => ({ value: section.id, label: section.label }))}
                            onSelectChange={(selectedSection) => {
                                scrollToSection(selectedSection);
                                setSelectedMenu(selectedSection);
                            }}
                            value={selectedMenu}
                        />
                    </Box>
                )}
                <form method="post" encType="multipart/form-data" onSubmit={handleSubmit} className={classes.itemForm}>
                    <div className={classes.itemFormFieldSet}>
                        <div  ref={personalRef} className={classes.itemFormFieldSetAnchor}>{' '}</div>
                        <div className={`${classes.itemTitle} card-title card-title--green`}>
                            {/* NEED TRANSLATION */}
                            {t('Personal Information')}
                        </div>

                        <div className={classes.itemSettings}>
                            <FormControl fullWidth className={classes.itemField}>
                                <div className={classes.itemFieldImage}>
                                    <AvatarUploader src={values["avatarUrl"]} setFile={(file) => handleChange("avatarUrl", file)}/>
                                </div>
                            </FormControl>
                            <FormControl fullWidth error={!!errors.title} className={classes.itemField}>
                                <RadioFields
                                    options={titles}
                                    name={"title"}
                                    label={t("formFields.title")}
                                    value={values["title"]}
                                    onRadioChange={(val) => handleChange("title", val)}
                                    error={errors["title"]}
                                    disabled={checkDisabledStatus("title")}
                                    isLoading={isDataFetching}
                                    className={classes.itemRadio}
                                    settings={settings}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors["firstName"]} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.firstName")}
                                    placeholder={t("formFields.firstName")}
                                    name="firstName"
                                    error={errors["firstName"]}
                                    value={values["firstName"]}
                                    onInputChange={(val) => handleChange("firstName", val)}
                                    disabled={checkDisabledStatus("firstName")}
                                    isLoading={isDataFetching}
                                />
                            </FormControl>

                            <div className={classes.itemRow}>
                                <FormControl fullWidth error={!!errors["middleName"]} className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.middleName")}
                                        placeholder={t("formFields.middleName")}
                                        name="middleName"
                                        error={errors["middleName"]}
                                        value={values["middleName"]}
                                        onInputChange={(val) => handleChange("middleName", val)}
                                        disabled={checkDisabledStatus("middleName")}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors["lastName"]} className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.lastName")}
                                        placeholder={t("formFields.lastName")}
                                        name="lastName"
                                        error={errors["lastName"]}
                                        value={values["lastName"]}
                                        onInputChange={(val) => handleChange("lastName", val)}
                                        disabled={checkDisabledStatus("lastName")}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className={classes.itemFormFieldSet}>
                        <div  ref={contactRef} className={classes.itemFormFieldSetAnchor}>{' '}</div>
                        <div className={`${classes.itemTitle} card-title card-title--blue`}>
                            {/* NEED TRANSLATION */}
                            {t('Contact Information')}
                        </div>

                        <div className={classes.itemSettings}>
                            <FormControl fullWidth className={classes.itemField}>
                                <InputField
                                    label={t("formFields.email")}
                                    placeholder={t("formFields.email")}
                                    name="email"
                                    value={values["email"]}
                                    error={errors["email"]}
                                    onInputChange={(val) => handleChange("email", val)}
                                    disabled={checkDisabledStatus("email")}
                                    isLoading={isDataFetching}
                                    settings={settings}
                                />
                            </FormControl>

                            <div className={classes.itemRow}>
                                <FormControl fullWidth className={classes.itemField}>
                                    <PhoneField
                                        label={t("formFields.phone")}
                                        name={"phone"}
                                        value={values["phone"]}
                                        onChangeHandler={(val) => handleChange("phone", val)}
                                        disabled={checkDisabledStatus("phone")}
                                        error={errors["phone"]}
                                        isLoading={isDataFetching}
                                        settings={settings}
                                    />
                                </FormControl>

                                <FormControl fullWidth className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.country")}
                                        name={"country"}
                                        value={values["country"]}
                                        error={errors["country"]}
                                        options={countries}
                                        onSelectChange={(val) => handleChange("country", val)}
                                        disabled={checkDisabledStatus("country")}
                                        isLoading={isDataFetching}
                                        settings={settings}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className={classes.itemFormFieldSet}>
                        <div  ref={professionalRef} className={classes.itemFormFieldSetAnchor}>{' '}</div>
                        <div className={`${classes.itemTitle} card-title card-title--purple`}>
                            {/* NEED TRANSLATION */}
                            {t('Professional Details')}
                        </div>

                        <div className={classes.itemSettings}>
                            <FormControl fullWidth className={classes.itemField}>
                                <InputField
                                    label={t("formFields.volveUserId")}
                                    value={customerNumber}
                                    disabled={true}
                                    isLoading={isDataFetching}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.itemField}>
                                <FormLabel component="label">{t("formFields.role")}</FormLabel>
                                <div className={classes.itemBox}>
                                    {roles.map((role) => {
                                        return  (
                                            <div className='status-default' key={role}>
                                                {role}
                                            </div>
                                        )
                                    })}
                                </div>
                            </FormControl>

                            <div className={classes.itemRow}>
                                <FormControl fullWidth className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.group")}
                                        name={"group"}
                                        value={values["group"]}
                                        error={errors["group"]}
                                        options={groups}
                                        onSelectChange={(val) => handleChange("group", val)}
                                        disabled={checkDisabledStatus("group")}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>

                                <FormControl fullWidth className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.branch")}
                                        name={"branch"}
                                        value={values["branch"]}
                                        error={errors["branch"]}
                                        options={branches}
                                        onSelectChange={(val) => handleChange("branch", val)}
                                        disabled={checkDisabledStatus("branch")}
                                        isLoading={isDataFetching}
                                        helperText={`${t("important")}: ${t("helpTextUserBranch")}`}
                                        settings={settings}
                                    />
                                </FormControl>
                            </div>

                            <div className={classes.itemRow}>
                                <FormControl fullWidth error={!!errors["position"]} className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.position")}
                                        placeholder={t("formFields.position")}
                                        name="position"
                                        error={errors["position"]}
                                        value={values["position"]}
                                        onInputChange={(val) => handleChange("position", val)}
                                        disabled={checkDisabledStatus("position")}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors["department"]} className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.department")}
                                        name={"department"}
                                        value={values["department"]}
                                        error={errors["department"]}
                                        options={departments}
                                        onSelectChange={(val) => handleChange("department", val)}
                                        disabled={checkDisabledStatus("department")}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.itemRow}>
                                <FormControl fullWidth error={!!errors["iban"]} className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.iban")}
                                        placeholder={t("formFields.iban")}
                                        name="iban"
                                        error={errors["iban"]}
                                        value={values["iban"]}
                                        onInputChange={(val) => handleChange("iban", val)}
                                        disabled={checkDisabledStatus("iban")}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>                                
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                        <div  ref={preferencesRef} className={classes.itemFormFieldSetAnchor}>{' '}</div>
                        <div className={`${classes.itemTitle} card-title card-title--red`}>
                            {/* NEED TRANSLATION */}
                            {t('Preferences')}
                        </div>

                        <ToggleFieldWrapper
                            settings={settings}
                        >
                            <ToggleCheckboxField
                                additionalClass="toggle-item"
                                errorField={!!errors["expenseReminder"]}
                                onCheckboxChange={(val) => handleChange("expenseReminder", val)}
                                value={!!(values["expenseReminder"])}
                                label={t("expenseReminderText")}
                                name={"expenseReminder"}
                                error={errors["expenseReminder"]}
                                disabled={checkDisabledStatus("expenseReminder") || isDataFetching}
                                settings={settings}
                            />
                        </ToggleFieldWrapper>
                    </div>

                    <Button
                        type="submit"
                        disabled={isDisabled || isDataFetching}
                        className={classes.itemFormSubmit}
                    >
                        {t("formFields.save")}
                    </Button>
                </form>
            </div>
        </div>
    )
}


export default ProfileForm

import React from 'react';
import DialogPopUp from "../../../../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import SortableMultiSelect from "../../../../../../../formElements/SortableMultiSelect";
import {makeStyles} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
            content: {
                width: "600px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px",
                marginLeft:"auto",
                marginRight:"auto"
            },
        }
    )
);


const DialogFields = (props) => {

    const {t} = useTranslation();
    const {value, handleChange, setOpen, open} = props;
    const classes = useStyles();

    return (
        <DialogPopUp
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="report-dialog-title"
            aria-describedby="report-dialog-description"
            title={t("formFields.reportTemplate")}
        >
            <DialogContent className={classes.content}>
                <SortableMultiSelect
                    value={value}
                    handleChange={handleChange}
                    label={t("formFields.fields")}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    color="secondary"
                    variant={"contained"}
                >
                    {t("formFields.continue")}
                </Button>
            </DialogActions>

        </DialogPopUp>
    );
};

export default DialogFields;

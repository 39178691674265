import React from 'react';
import DashboardPane from "../../../../DashboardPane";
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../../../../CompanyCountryCheck";
import KycInfo from "../KYCInfo";
import KYBInfo from "../KYCBnfo";
import ExpensesToDo from "./ExpensesToDo";
import {Can} from "../../../../../Context/Can";
import {makeStyles} from "@material-ui/core";
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({
    cardContent:{
        "& > .MuiSnackbarContent-root": {
            marginBottom: '0'
        },
        "& > .MuiSnackbarContent-root:not(:last-child)": {
            marginBottom: '6px'
        }
    }
}));


const PersonalToDo = (props) => {
    const {t} = useTranslation();
    const {user, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <DashboardPane
            title={t("user.toDo")}
            cardContentProps={{className:classes.cardContent}}
            headingColorClasses={"card-title--purple"}
            settingsDarkMode={settingsDarkMode}
        >
            <>
                <CompanyCountryCheck noText settingsDarkMode={settingsDarkMode}>
                    <KycInfo user={user} settingsDarkMode={settingsDarkMode} />
                    <Can I="update" a={"company"}>
                        <KYBInfo user={user} settingsDarkMode={settingsDarkMode} />
                    </Can>
                </CompanyCountryCheck>
                <Can I="read" a={"expense"} passThrough>
                    {(can) => (
                        <ExpensesToDo hasPermissionsToRead={can} settingsDarkMode={settingsDarkMode} />
                    )}
                </Can>
            </>

        </DashboardPane>
    );
};

export default PersonalToDo;

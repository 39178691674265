import React from 'react';
import {useSelector} from 'react-redux'
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import canUseCardFunctionality from "../../helpers/canUseCardFunctionality";

const CompanyCountryCheck = (props) => {
    const {noText, children} = props;
    const {t} = useTranslation();
    const companyCountry = useSelector(state => {
        const {user} = state;
        if(user && user.userData && user.userData.company && user.userData.company.country){
            return user.userData.company.country
        }
        return null
    });

    const canShow = canUseCardFunctionality(companyCountry);


    return (
        <>
            {canShow ? children : (
                noText ? null : (
                    <div>
                        <Typography variant="h6" gutterBottom>{t("comingSoonTitle")}</Typography>
                        <Typography variant="body1" gutterBottom>{t("comingSoonText")}</Typography>
                    </div>
                )
            )}
        </>
    );
};

export default CompanyCountryCheck;

import React, {useEffect, useState} from 'react';
import FutureBlockingForm from "./FutureBlockingForm";
import useForm2 from "../../../../../../../hooks/useForm2";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";
import Api from "../../../../../../../services/api";
import {subject} from "@casl/ability";
const services = new Api();

const FutureBlocking = (props) => {
    const {t} = useTranslation();

    const {dateFormat, card, setMessage, ability, setCard} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if(card.futureBlockDate){
            fillFormValues({futureBlockDate:card.futureBlockDate});
        }
    },[card]);

    const submit = async () => {
        try {
            setIsSubmitting(true);
            const response = await services.futureBlockCard(card._id, values);
            const cardData = response.data;

            setCard((oldCard) => ({
                ...oldCard,
                futureBlockDate: cardData.futureBlockDate,
            }));

            setMessage(t("success"), 'success');
        }catch (e) {
            console.log(e);
            setServerErrors(e)
        }finally {
            setIsSubmitting(false);
        }

    }

    const validate = (values) => {
        let errors = {};

        if(values["futureBlockDate"]){
            const futureDate = moment(values["futureBlockDate"]).startOf("day");

            if(futureDate.isValid()){
                const now = moment().startOf("day");
                if(!futureDate.isAfter(now)){
                    errors.futureBlockDate = t('errors.notValid', {field: t("formFields.futureBlockDate")});
                }
            }else{
                const isEmptyString = typeof futureDate._i === 'number' && isNaN(futureDate._i);

                if(!isEmptyString){
                    errors.futureBlockDate = t('errors.notValid', {field: t("formFields.futureBlockDate")});
                }
            }
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    const canSetFutureBlock = Object.keys(card).length ? ability.can("futureBlock", subject("card", {company:card.company.id, currentStatCode:card.currentStatCode})) : false;

    return (
            <FutureBlockingForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                values={values}
                errors={errors}
                dateFormat={dateFormat}
                disabled={!canSetFutureBlock}
                isSubmitting={isSubmitting}
            />
    );
};

export default FutureBlocking;

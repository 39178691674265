import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Img from "../Img";
import isAbsoluteSrc from "../../helpers/isAbsoluteSrc";
const awsUrl = window._env_.AWS_PUBLIC_BUCKET_URL;
const useStyles = makeStyles(theme => ({
	button: {
		'&.MuiButton-text':{
            width: '280px',
            height: '280px',
            borderRadius: '50% !important',
            overflow: 'hidden !important',
            backgroundColor: 'rgba(244, 244, 244, .8)',
            padding: '0',
            [theme.breakpoints.down('1023')]: {
                width: '210px',
                height: '210px',
            },
			'&:hover .upload-you-image':{
				opacity:1,
				visibility:"visible"
			},
            '& svg': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
            }
		},
	},
	img:{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '50%',
	},

	uploadHelper:{
		position: "absolute",
		top:"0",
		left:"0",
		width:"100%",
		height:"100%",
		background:"rgba(0,0,0,0.6)",
		color:"#fff",
		display:"flex",
		alignItems: "center",
		justifyContent: "center",
		textTransform: "initial",
		zIndex:1,
		opacity:0,
		visibility:"hidden",
		transition:"opacity 0.5s",
		'&.active':{
			visibility:"visible",
			opacity:"1"
		}
	},

	file:{
		position: "absolute",
		top:0,
		left:0,
		width: "100%",
		bottom: 0,
		zIndex:2,
		cursor:"pointer",
		opacity:0
	},
}));


const AvatarUploader = (props) => {
	const classes = useStyles();
	const {src, setFile} = props;
	const [avatarUrl, setAvatarUrl] = useState(null);

	const [drugIsActive, setDrugIsActive] = useState(false);
	const {t} = useTranslation();


	const inputHandler = (e) => {
		if(e.target.files && e.target.files[0]){
			setFile(e.target.files[0]);
		}
	};


    const getAvaUrl = (src) => {
        let url;
        if(!src){
            return undefined
        }
        if(src instanceof File){
            url = window.URL.createObjectURL(src);
        }else if(isAbsoluteSrc(src)){
            url = src
        }else{
            url = `${awsUrl}/${src}`
        }

        return url
    }

    useEffect(() => {
        let url;
        if(src){
            if(src instanceof File){
                url = window.URL.createObjectURL(src);
            }else if(isAbsoluteSrc(src)){
                url = src
            }else{
                url = `${awsUrl}/${src}`
            }
        }

        setAvatarUrl(url);
    },[src]);


	return (
		<>
			<Button
				label='File'
				className={classes.button}
				onDragEnter={(e) => setDrugIsActive(true)}
				onDragLeave={(e) => setDrugIsActive(false)}
				onDrop={(e) => setDrugIsActive(false)}
			>

				<Img src={avatarUrl}
					 alt=""
					 lazy
					 className={classes.img}
				/>

				<input type="file" name="file" accept=".png,.jpeg,.jpg" className={classes.file} onChange={inputHandler}/>
				<span className={`${classes.uploadHelper} upload-you-image ${drugIsActive ? 'active' : ""}`}>
					{drugIsActive ? t("putFileHere") : (
                        isAbsoluteSrc(src) ? t("uploadYourPicture") : t("changeYourPicture")
					)}
				</span>
			</Button>
		</>
	);
};

export default React.memo(AvatarUploader);

import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import BackButton from "../../../../BackButton";
import {Can} from "../../../../../Context/Can";
import useForm2 from "../../../../../hooks/useForm2";
import ExpenseReportTemplateForm from "../../../../ExpenseReportTemplateForm";
import Api from "../../../../../services/api";
import {setMessage} from "../../../../Messages/actions";
import {makeStyles, Typography, Button, Portal} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    headControl: {
        display: 'flex',
        marginLeft: 'auto',
    },

    item: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'flex-start'
    },

    buttonSubmit: {
        marginTop: '48px',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '12px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    }

}))

const AddExpenseReportTemplates = (props) => {
    const {underBarRef, history, settingsDarkMode} = props;

    const classes = useStyle();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();
    const [isSubmitting, setIsSubmitting] = useState(false);


    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.createExpenseReportTemplate(values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/reports/templates");
        }catch (e) {
            console.log(e);
            setIsSubmitting(false);
            setServerErrors(e);
        }
    }


    const validate = (values) => {
        const errors = {};
        if(!values["name"]){
            errors["name"] = t("errors.required", {field:"$t(formFields.name)"});
        }
        return errors;
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors} = useForm2(
        submit,
        validate,
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("report.mainTitle")}
                    </Typography>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <ExpenseReportTemplateForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        settingsDarkMode={settingsDarkMode}
                    />

                    <Can I="create" a={"expenseReportTemplate"}>
                        <div className={classes.buttonSubmit}>
                            <Button
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                {t("formFields.create")}
                            </Button>
                        </div>
                    </Can>
                </div>
            </div>
        </>
    );
};

export default AddExpenseReportTemplates;

import * as actionTypes from '../../../store/actionTypes'
import Api from "../../../services/api";
import getHighestPriorityRole from "../../../helpers/getHighestPriorityRole";
const service = new Api();

const drawerMainToggle = () => {
    return {type:actionTypes.DRAWER_MAIN_TOGGLE}
}

const drawerMainMenuPermissions = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const user = state.user;
        const {roles, userData} = user;
        const {company} = userData;

        if (roles.includes('administrator')) {
            // administrator can do everything
            return dispatch(
                {
                    type: actionTypes.DRAWER_MAIN_VIEW_PERMISSIONS,
                    payload: {
                        account: "viewAndEdit",
                        card: "viewAndEdit",
                        user: "viewAndEdit",
                        businessManagement: "viewAndEdit",
                        balance: "viewAndEdit"
                    }
                }
            )

        }else{
            try{
                const rolesResponse = await service.getServiceData({dataFor: "roles"});
                const allRoles = rolesResponse.data.roles;
                const highestPriorityRole = getHighestPriorityRole(roles, allRoles);
                return dispatch({
                    type:actionTypes.DRAWER_MAIN_VIEW_PERMISSIONS,
                    payload: company.configurablePermissions[highestPriorityRole]
                });

            }catch (e) {
                console.log(e)
            }
        }
    }
}

export {
    drawerMainToggle,
    drawerMainMenuPermissions
}

import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import getCardIsDisabled from "../../../../../../../helpers/getCardIsDisabled";
import {useTranslation} from "react-i18next";
import {subject} from "@casl/ability";
import {Button} from "@material-ui/core";
import {confirm} from "../../../../../../../helpers/confirm";
import Box from "@material-ui/core/Box";
import ConfirmTextWrapper from "../../../../../../ConfirmTextWrapper";

const service = new Api();

const LostOrStolen = (props) => {
    const {card, handleErrorMessage, setMessage, ability, setCard} = props;
    const [isDisabled, setIsDisabled] = useState(false);
    const {t} = useTranslation();

    const onBlockPermanently = async () => {
        if (await confirm({
            confirmation: t('reportLostOrStolenCardTitle'),
            yesText: t('formFields.reportLostOrStolenCard'),
            noText:  t('formFields.cancel'),
            content: (
                <ConfirmTextWrapper>
                    <Box>{t('reportLostOrStolenCardLine1')}</Box>
                    <Box>{t('reportLostOrStolenCardLine2')}</Box>
                </ConfirmTextWrapper>
            )
        })) {
            setIsDisabled(true);
            try {
                const response = await service.blockCardPermanently(card._id);
                setCard((oldCard) => ({
                    ...oldCard,
                    ...response.data,
                }));
                setIsDisabled(false);
                setMessage("success", 'success');
            } catch (e) {
                handleErrorMessage(e);
                setIsDisabled(false);
            }
        }
    };

    useEffect(() => {
        if(card){
            const isCardDisabled = getCardIsDisabled(card.currentStatCode);
            setIsDisabled(isCardDisabled);
        }

    },[card]);

    const canBlockPermanently = () => {
        if(card.user && card.company){
            return ability.can("blockPermanently", subject("card", {user:card.user.id, company:card.company.id}));
        }
        return false
    }

    return (
        <Box mr={"20px"} component={"span"}>
            <Button
                onClick={onBlockPermanently}
                disabled={!canBlockPermanently() || isDisabled}
                variant="contained"
                color="secondary"
            >
                {t("formFields.blockPermanently")}
            </Button>
        </Box>
    )
};

export default LostOrStolen;

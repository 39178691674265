import React, {useEffect, useState} from 'react';
import useForm2 from "../../../../hooks/useForm2";
import queryString from "query-string";
import {withRouter, matchPath} from "react-router-dom";
import DateField from "../../../formElements/DateField";
import {useTranslation} from "react-i18next";
import Api from "../../../../services/api";
import SelectField from "../../../formElements/SelectField";
import moment from 'moment-timezone';
import FilterFormButton from "../FilterFormButton";
import FilterFormControls from "../FilterFormControls";
import {makeStyles, FormControl, Box} from "@material-ui/core";
import { PAYMENT_TYPES_LIST } from "../../../../constants/transactions";
import { isDarkModeActive } from '../../../App/App';

const service = new Api();

const useStyle = makeStyles((theme) => ({
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        },
        "& .MuiFormControl-root": {
            "& .MuiFormControl-root": {
                marginBottom: '0'
            }
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },

    itemFormSubmit: {
        marginTop: '48px',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: "100%",
        gap: '12px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
}));



const ExpensesFilter = (props) => {
    const {history, dateFormat, location, settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyle({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const [expenseStatuses, setExpenseStatuses] = useState([]);

    const {search} = location;
    const query = queryString.parse(search);

    const matchedPath = matchPath(location.pathname, {
        path: "/transactions/:tab",
        exact: true,
        strict: false
    });

    const currentTab = matchedPath ? matchedPath.params.tab : "";
    const isToDoTab = currentTab === "todo";

    const localFields = ['date[gte]', 'date[lte]'];
    if(!isToDoTab){
        // for `All` tab we need additionally `status` field
        localFields.push("status");
    }

    useEffect(() => {
        service.getServiceData({dataFor:"expenseStatuses"}).then((expenseStatuses) => {
            const mappedStatuses = expenseStatuses.data.map((status) => ({label:t(`expense.${status}`), value:status}));
            setExpenseStatuses(mappedStatuses)
        }).catch((e) => {
            console.log(e)
        });

    },[]);


    const onClearHandler = () => {
        fillFormValues({}); // reset form completely
        const resetQuery = {};

        history.push({
            search: `?${queryString.stringify(resetQuery)}`,
        });

    }

    useEffect(() => {
        // fill data from url

        const dataToFIll = localFields.reduce((obj, key) => {
            if(query[key]){
                obj[key] = query[key]
            }
            return obj
        },{});

        fillFormValues(dataToFIll);

        return () => {
            fillFormValues({});
        }
    },[search]);

    const onDateChange = (id, date) => {
        let midnight = undefined;
        if (date && date.isValid()) {
            if (id === 'date[gte]') {
                midnight = moment(date).startOf("day");
            } else {
                midnight = moment(date).endOf("day");
            }
        }
        handleChange(id, midnight);
    }

    const validate = () => ({});

    const submit = () => {
        const oldQuery = queryString.parse(location.search);
        const newQuery = {...values};

        if(oldQuery["searchValue"]){
            newQuery.searchValue = oldQuery["searchValue"];
        }

        history.push({
            search: `?${queryString.stringify(newQuery)}`,
        });

    }

    const { handleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );

    return (
        <Box component={'form'} onSubmit={handleSubmit} className={classes.itemForm}>
            <FilterFormControls className={classes.itemFormFieldSet}>
                <Box className={classes.itemSettings}>
                    <FormControl
                        fullWidth
                        className={`${classes.itemField}`}
                    >
                        <DateField
                            label={t("formFields.dateFrom")}
                            placeholder={t("formFields.date")}
                            dateFormat={dateFormat}
                            onDateChange={(date) => onDateChange(`date[gte]`, date)}
                            value={values[`date[gte]`]}
                            name="date[gte]"
                            settingsDarkMode={settingsDarkMode}
                            KeyboardButtonProps={{
                                'aria-label': 'change from',
                            }}

                        />
                    </FormControl>

                    <FormControl
                        fullWidth
                        className={`${classes.itemField}`}
                    >
                        <DateField
                            label={t("formFields.dateTo")}
                            placeholder={t("formFields.date")}
                            dateFormat={dateFormat}
                            onDateChange={(date) => onDateChange(`date[lte]`, date)}
                            value={values[`date[lte]`]}
                            name="date[lte]"
                            settingsDarkMode={settingsDarkMode}
                            KeyboardButtonProps={{
                                'aria-label': 'change to',
                            }}
                        />
                    </FormControl>

                    {!isToDoTab && (
                        <FormControl
                            fullWidth
                            className={classes.itemField}
                        >
                            <SelectField
                                label={t("formFields.status")}
                                name={"status"}
                                value={values["status"]}
                                options={expenseStatuses}
                                onSelectChange={(val) => handleChange("status", val)}
                                settings={settingsDarkMode}
                            />
                        </FormControl>
                    )}

                    <FormControl
                        fullWidth
                        className={classes.itemField}
                    >
                        <SelectField
                            label={t("formFields.paymentType")}
                            name={"paymentType"}
                            value={values["paymentType"]}
                            options={PAYMENT_TYPES_LIST}
                            onSelectChange={(val) => handleChange("paymentType", val)}
                            settings={settingsDarkMode}
                        />
                    </FormControl>
                </Box>
            </FilterFormControls>
            <Box className={classes.itemFormSubmit}>
                <FilterFormButton
                    addtionalClass={`btn-stroke`}
                    onClick={() => onClearHandler()}
                >
                    {t("formFields.reset")}
                </FilterFormButton>
                <FilterFormButton
                    type="submit"
                >
                    {t("formFields.submit")}
                </FilterFormButton>
            </Box>
        </Box>
    );
};

export default withRouter(ExpensesFilter);


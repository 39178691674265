import React, {useEffect, useState} from 'react'
import {Grid} from "@material-ui/core";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import ActivateCard from "../ActivateCard";
import {Ability} from "@casl/ability";
import Api from "../../../../../../services/api";
import useMounted from "../../../../../../hooks/useMounted";
import BackButton from "../../../../../BackButton";
import Portal from "@material-ui/core/Portal";
import CardOptions from "./CardOptions";
import Card from "../Card";
import ReceivePan from "./ReceivePan";
import DeleteCard from "./DeleteCard";
import GetPinCode from "./GetPinCode";
import LostOrStolen from "./LostOrStolen";
import ReadCvv from "./ReadCvv";
import CardHelpText from "./CardHelpText";
import Skeleton from "@material-ui/lab/Skeleton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import formatMoney from "../../../../../../helpers/formatMoney";
import {makeStyles} from "@material-ui/core";
const services = new Api();

const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: '8px',
    }
}))

const Settings = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {handleErrorMessage, setMessage, match, underBarRef, backPath, setShortInfo, user, cardTabs} = props;
    const {settings: {regional={}} = {}} = user;
    const {decimal, dateFormat} = regional;

    const {id} = match.params;
    const isMounted = useMounted();

    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const [card, setCard] = useState({});
    const [ability, setAbility] = useState(new Ability());
    const [isLoading, setIsLoading] = useState(false);
    const [cvv, setCvv] = useState('***');

    const fetchCard = async (id) => {
        try{
            setIsLoading(true);
            const cardResponse = await services.getCard(id);
            const {rules, ...restCard} = cardResponse.data;
            setCard(restCard);
            setAbility(new Ability(rules));

        }catch (e) {
            console.log(e);
            setCard({});
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(Object.keys(card).length){
            const {cardRemainingBalance, curCode} = card;

            setShortInfo({
                label: t("remainingBalanceThisMonth"),
                value:`${curCode} ${formatMoney(cardRemainingBalance, decimal)}`
            })
        }
    },[card]);

    useEffect(() => {
        fetchCard(id);
    },[id]);


    const cardIsDeleted = card.currentStatCode === "83";
    const cardIsLost = card.currentStatCode === "43";

    const canShowBottomButtons = !(cardIsDeleted || cardIsLost) && card._id;


    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Grid item>

                    </Grid>
                </Portal>
            )}
            <div className={`card`}>
                <div className={classes.head}>
                    <BackButton backUrl={backPath}/>

                    <div className={classes.control}>
                        {cardTabs}
                    </div>
                </div>

                <Box display={"flex"} flexWrap={isLgDown ? 'wrap' : 'nowrap'}>
                    <Box flexBasis={"450px"} pr={"60px"}>
                        <Card card={card} isLoading={isLoading} ability={ability} cvv={cvv}/>
                        {cardIsDeleted && <CardHelpText text={t('card.deletedText')}/>}
                        {cardIsLost && <CardHelpText text={t('card.lostText')}/>}
                        <ReceivePan
                            setMessage={setMessage}
                            handleErrorMessage={handleErrorMessage}
                            card={card}
                            ability={ability}
                        />
                        <GetPinCode
                            setMessage={setMessage}
                            handleErrorMessage={handleErrorMessage}
                            card={card}
                            ability={ability}
                        />
                        <ReadCvv
                            setMessage={setMessage}
                            handleErrorMessage={handleErrorMessage}
                            card={card}
                            ability={ability}
                            cvv={cvv}
                            setCvv={setCvv}
                        />
                    </Box>
                    <Box flexGrow={1}>
                        {card._id ? (
                            card.isLive ? (
                                <CardOptions
                                    card={card}
                                    currentUser={user.userData}
                                    handleErrorMessage={handleErrorMessage}
                                    setMessage={setMessage}
                                    setCard={setCard}
                                    ability={ability}
                                    decimal={decimal}
                                    dateFormat={dateFormat}
                                    cardIsDeleted={cardIsDeleted}
                                />
                            ) : (
                                <ActivateCard card={card} setCard={setCard} setMessage={setMessage}/>
                            )
                        ) : (
                            isLoading ? (
                                <div>
                                    <Skeleton height={"50px"} width={"100%"}/>
                                    <Skeleton height={"50px"} width={"100%"}/>
                                    <Skeleton height={"50px"} width={"100%"}/>
                                    <Skeleton height={"50px"} width={"100%"}/>
                                    <Skeleton height={"50px"} width={"100%"}/>

                                </div>
                            ) : (
                                <div>{t("card.cantGet")}</div>
                            )
                        )}
                    </Box>
                </Box>

                {canShowBottomButtons && (
                    <Box pt={"20px"}>
                        <LostOrStolen
                            setMessage={setMessage}
                            handleErrorMessage={handleErrorMessage}
                            setCard={setCard}
                            card={card}
                            ability={ability}
                        />
                        <DeleteCard
                            setMessage={setMessage}
                            handleErrorMessage={handleErrorMessage}
                            card={card}
                            setCard={setCard}
                            ability={ability}
                        />
                    </Box>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const {user} = state;
    return {user}
};

export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage}),
)(Settings)

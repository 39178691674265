import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import Xero from "./Xero";

const AdvancedSettings = (props) => {
    const {match, settingsDarkMode, ...restProps} = props;

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={
                        () => <Xero {...restProps} settingsDarkMode={settingsDarkMode} />
                    }
                />
            </Switch>
        </>
    );
};

export default withRouter(AdvancedSettings);

import React, { useRef, useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import InputField from "../../../../../formElements/InputField";
import SelectField from "../../../../../formElements/SelectField";
import ToggleCheckboxField from '../../../../../formElements/ToggleCheckboxField/ToggleCheckboxField';
import ToggleFieldWrapper from '../../../../../formElements/ToggleFieldWrapper/ToggleFieldWrapper';
import {
    InputAdornment ,
    makeStyles,
    useTheme,
    useMediaQuery,
    Box,
    Button,
    FormControl
} from "@material-ui/core";
import { isDarkModeActive } from '../../../../../App/App';
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start'
    },
    itemMenu: {
        position: 'sticky',
        top: '116px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '280px',
    },
    itemMenuLink: {
        backgroundColor: 'transparent !important',
        padding: '8px 16px',
        color: 'var(--battelship-grey)',
        justifyContent: 'flex-start',
        fontWeight: '600',
        height: 'initial',
        lineHeight: '1.6',
        letterSpacing: '0',
        "&:not(:last-child)": {
            marginBottom: '8px'
        },
        "& .MuiTouchRipple-root": {
            display: 'none !important'
        },
        "&.active": {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey) ' : 'var(--dark-secondary)',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary) !important' : 'var(--very-light-pink-primary) !important',
        }
    },
    itemContent: {
        flexGrow: '1',
        paddingLeft: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0'
        }
    },
    itemContentMenu: {
        display: 'block',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        '& .MuiInput-root': {
            width: '100%'
        }
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
    },
    itemTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '40px',
        letterSpacing: '-.02em',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemBox: {
        width: '100%',
        height: '48px',
        padding: '0 10px',
        borderRadius: '12px',
        border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid transparent',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
        transition: 'all .2s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px'
    },
    itemFormSubmit: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
    itemFormFieldSetAnchor: {
        position: 'absolute',
        top: '-116px',
        left: '0',
        right: '0',
    },
}))

const AdvancedSettingsForm = (props) => {
    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        checkDisabledField,
        isShowThreshold,
        setIsShowThreshold,
        isDisabled,
        settingsDarkMode
    } = props;
    const {t} = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const onExpenseProjectShow = (val) => {
        handleChange("expenseProjectShow", val);
        if(val === false){
            handleChange("expenseProjectRequired", false);
        }
    }

    useEffect(()=> {
        if (values['expenseThreshold']) {
            setIsShowThreshold(true)
        }
    }, [])

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    // NEED TRANSLATION
    const formSections = [
        { id: 'expenseInformation', label: t('Expense Information') },
        { id: 'projectDetails', label: t('Project Details') },
        { id: 'expenseRequirements', label: t('Expense Requirements') },
        { id: 'automation', label: ('Automation') },
    ];
    const [activeSection, setActiveSection] = useState('expenseInformation');
    const [selectedMenu, setSelectedMenu] = useState(formSections[0].id);
    const expenseInformationRef = useRef(null);
    const projectDetailsRef = useRef(null);
    const expenseRequirementsRef = useRef(null);
    const automationRef = useRef(null);
    const companyCurrency = useSelector(state => {
        const {user} = state;
        if(user && user.userData && user.userData.company && user.userData.company.currency){
            return user.userData.company.currency
        }
        return null
    });

    const refs = {
        expenseInformation: expenseInformationRef,
        projectDetails: projectDetailsRef,
        expenseRequirements: expenseRequirementsRef,
        automation: automationRef,
    }

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(selectedSection);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (rect.top <= scrollPosition && rect.bottom > scrollPosition) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs, formSections])


    return (
        <div className={classes.item}>
            {!onMax1023 && (
                <div className={classes.itemMenu}>
                    {formSections.map((section) => (
                        <Button
                            key={section.id}
                            className={`${classes.itemMenuLink} ${
                                activeSection === section.id ? 'active' : ''
                            }`}
                            onClick={() => {
                                scrollToSection(section.id);
                                setActiveSection(section.id);
                                setSelectedMenu(section.id);
                            }}
                        >
                            {t(section.label)}
                        </Button>
                    ))}
                </div>
            )}

            <div className={classes.itemContent}>
                {onMax1023 && (
                    <Box className={classes.itemContentMenu}>
                        <SelectField
                            hideEmpty
                            options={formSections.map(section => ({ value: section.id, label: section.label }))}
                            onSelectChange={(selectedSection) => {
                                scrollToSection(selectedSection);
                                setSelectedMenu(selectedSection);
                            }}
                            value={selectedMenu}
                        />
                    </Box>
                )}

                <form onSubmit={handleSubmit} noValidate className={classes.itemForm}>
                    <div className={classes.itemForm}>
                        <div className={classes.itemFormFieldSet}>
                            <div className={classes.itemFormFieldSetAnchor} ref={expenseInformationRef}>{' '}</div>
                            <div className={`${classes.itemTitle} card-title card-title--green`}>
                                {/* NEED TRANSLATION */}
                                {t('Expense Information')}
                            </div>

                            <ToggleFieldWrapper
                                settings={settingsDarkMode}
                            >
                                <ToggleCheckboxField
                                    additionalClass="toggle-item"
                                    errorField={!!errors["expenseMerchantIsRequired"]}
                                    onCheckboxChange={(val) => handleChange("expenseMerchantIsRequired", val)}
                                    value={values["expenseMerchantIsRequired"]}
                                    label={t("formFields.advancedSettingsExpenseMerchantRequired")}
                                    name={"expenseMerchantIsRequired"}
                                    error={errors["expenseMerchantIsRequired"]}
                                    disabled={checkDisabledField("expenseMerchantIsRequired")}
                                    settings={settingsDarkMode}
                                />

                                <ToggleCheckboxField
                                    additionalClass="toggle-item"
                                    settings={settingsDarkMode}
                                    errorField={!!errors["expenseCommentRequired"]}
                                    onCheckboxChange={(val) => handleChange("expenseCommentRequired", val)}
                                    value={values["expenseCommentRequired"]}
                                    label={t("formFields.advancedSettingsExpenseCommentRequired")}
                                    name={"expenseCommentRequired"}
                                    error={errors["expenseCommentRequired"]}
                                    disabled={checkDisabledField("expenseCommentRequired")}
                                />

                                <ToggleCheckboxField
                                    additionalClass="toggle-item"
                                    errorField={!!errors["expenseReimbursableActive"]}
                                    onCheckboxChange={(val) => handleChange("expenseReimbursableActive", val)}
                                    value={values["expenseReimbursableActive"]}
                                    label={t("formFields.advancedSettingsExpenseReimbursableActive")}
                                    name={"expenseReimbursableActive"}
                                    error={errors["expenseReimbursableActive"]}
                                    disabled={checkDisabledField("expenseReimbursableActive")}
                                    settings={settingsDarkMode}
                                />
                            </ToggleFieldWrapper>
                        </div>

                        <div className={classes.itemFormFieldSet}>
                            <div className={classes.itemFormFieldSetAnchor} ref={projectDetailsRef}>{' '}</div>
                            <div className={`${classes.itemTitle} card-title card-title--purple`}>
                                {/* NEED TRANSLATION */}
                                {t('Project Details')}
                            </div>

                            <div className={classes.itemSettings}>
                                <ToggleFieldWrapper settings={settingsDarkMode}>
                                    <ToggleCheckboxField
                                        additionalClass="toggle-item"
                                        errorField={!!errors["expenseProjectShow"]}
                                        onCheckboxChange={(val) => onExpenseProjectShow(val)}
                                        value={values["expenseProjectShow"]}
                                        label={t("formFields.advancedSettingsExpenseProjectShow")}
                                        name={"expenseProjectShow"}
                                        error={errors["expenseProjectShow"]}
                                        disabled={checkDisabledField("expenseProjectShow")}
                                        settings={settingsDarkMode}
                                    />

                                    <ToggleCheckboxField
                                        additionalClass="toggle-item"
                                        errorField={!!errors["expenseProjectRequired"]}
                                        onCheckboxChange={(val) => handleChange("expenseProjectRequired", val)}
                                        value={values["expenseProjectRequired"]}
                                        label={t("formFields.advancedSettingsExpenseProjectRequired")}
                                        name={"expenseProjectRequired"}
                                        error={errors["expenseProjectRequired"]}
                                        disabled={checkDisabledField("expenseProjectRequired")}
                                        settings={settingsDarkMode}
                                    />
                                </ToggleFieldWrapper>
                            </div>
                        </div>

                        <div className={classes.itemFormFieldSet}>
                            <div className={classes.itemFormFieldSetAnchor} ref={expenseRequirementsRef}>{' '}</div>
                            <div className={`${classes.itemTitle} card-title card-title--pink`}>
                                {/* NEED TRANSLATION */}
                                {t('Expense Requirements')}
                            </div>

                            <div className={classes.itemSettings}>
                                <ToggleFieldWrapper settings={settingsDarkMode}>
                                    <ToggleCheckboxField
                                        additionalClass="toggle-item"
                                        errorField={!!errors["expenseCategoryRequired"]}
                                        onCheckboxChange={(val) => handleChange("expenseCategoryRequired", val)}
                                        value={values["expenseCategoryRequired"]}
                                        label={t("formFields.advancedSettingsExpenseCategoryRequired")}
                                        name={"expenseCategoryRequired"}
                                        error={errors["expenseCategoryRequired"]}
                                        disabled={checkDisabledField("expenseCategoryRequired")}
                                        settings={settingsDarkMode}
                                    />

                                    <ToggleCheckboxField
                                        additionalClass="toggle-item"
                                        settings={settingsDarkMode}
                                        errorField={!!errors["expenseCountryRequired"]}
                                        onCheckboxChange={(val) => handleChange("expenseCountryRequired", val)}
                                        value={values["expenseCountryRequired"]}
                                        label={t("formFields.advancedSettingsExpenseCountryRequired")}
                                        name={"expenseCountryRequired"}
                                        error={errors["expenseCountryRequired"]}
                                        disabled={checkDisabledField("expenseCountryRequired")}
                                    />
                                </ToggleFieldWrapper>
                            </div>
                        </div>

                        <div className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                            <div className={classes.itemFormFieldSetAnchor} ref={expenseRequirementsRef}>{' '}</div>
                            <div className={`${classes.itemTitle} card-title card-title--red`}>
                                {/* NEED TRANSLATION */}
                                {t('Automation')}
                            </div>

                            <div className={classes.itemSettings}>
                                <ToggleFieldWrapper settings={settingsDarkMode}>
                                    <ToggleCheckboxField
                                        additionalClass="toggle-item"
                                        settings={settingsDarkMode}
                                        errorField={!!errors["advancedSettingsExpenseAutoInvestmentActive"]}
                                        onCheckboxChange={(val) => {
                                                setIsShowThreshold(val)
                                                handleChange("advancedSettingsExpenseAutoInvestmentActive", val)}
                                        }
                                        value={values["advancedSettingsExpenseAutoInvestmentActive"]}
                                        label={t("formFields.advancedSettingsExpenseAutoInvestmentActive")}
                                        name={"advancedSettingsExpenseAutoInvestmentActive"}
                                        error={errors["advancedSettingsExpenseAutoInvestmentActive"]}
                                        disabled={checkDisabledField("advancedSettingsExpenseAutoInvestmentActive")}
                                    />
                                </ToggleFieldWrapper>

                                {isShowThreshold && (
                                    <FormControl fullWidth className={classes.itemField}>
                                        <InputField
                                            className={classes.currencyValue}
                                            name="expenseThreshold"
                                            type="number"
                                            value={values["expenseThreshold"]}
                                            onInputChange={(val) =>
                                                handleChange("expenseThreshold", val)
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className={`start_block`}>
                                                        {companyCurrency}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                )}
                            </div>
                        </div>

                        <Button
                            type="submit"
                            disabled={isDisabled}
                            onClick={handleSubmit}
                            className={classes.itemFormSubmit}
                        >
                            {t("formFields.save")}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdvancedSettingsForm;

import React from 'react';
import moment from "moment-timezone";
import Moment from "react-moment";

const FormatDate = ({dateFormat, children}) => {
    return (
        <Moment format={dateFormat ? dateFormat : null}>{moment(children)}</Moment>
    );
};

export default FormatDate;

import React from 'react'

const HeadContentInvesthub =(props) => {
    const {rightContent} = props;

    return(
        <>
            {rightContent}
        </>
    )
}

export default HeadContentInvesthub

import React from 'react'
import Dashboard from "../userSection/content/Dashboard";
import UserSectionWrapper from "../UserSectionWrapper";

const DashboardPage = (props) => {
    const {underBarRef, drawerOuterRef, settingsDarkMode} = props;


    return (
        <>
            <UserSectionWrapper>
                <Dashboard underBarRef={underBarRef} drawerOuterRef={drawerOuterRef} settingsDarkMode={settingsDarkMode}/>
            </UserSectionWrapper>
        </>
    )

}



export default DashboardPage

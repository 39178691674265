import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import Api from "../../../../../../../services/api";
import Skeleton from "react-loading-skeleton";
import {withRouter} from "react-router-dom";
import buildTabsQueryCards from "../../../../../../../helpers/buildTabsQueryCards";

const service = new Api();

const useStyles = makeStyles({
    contentInner:{
        maxWidth: "380px",
        width:"340px",
        marginLeft:"auto",
        marginRight:"auto",
        paddingBottom:"20px",
        paddingTop:"20px",
    },

    btnWrap: {
        display: 'flex',
        gap: '16px',
        marginTop: '16px'
    }
});

const FinishStep = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const {cardData, setCardData, setCurrentStep, handleErrorMessage, setMessage, handleClose, setTitle, tabs, userId} = props;

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        setIsSubmit(true);
    },[]);

    useEffect(() => {
        setTitle(t("card.creationResult"));
        if(isSubmit){
            (async() => {
                setIsLoading(true);
                try {
                    const result = await service.createCard(cardData);
                    const {data} = result;
                    const {card, messages} = data;
                    messages.forEach(({message, type}) => {
                        setMessage(message, type);
                    });

                    setIsLoading(false);
                    setIsSuccess(true);

                    const tabQuery = tabs ? buildTabsQueryCards(tabs.active, card.type) : {};

                    // redirect to card's type tab.
                    props.history.push(`/cards/${card.type}`); // change url according type of created card

                    if(card.type === tabQuery.type){
                        // update card list by hand
                        props.fetchCards({
                            ...tabQuery,
                        });
                    }

                    return true;
                }catch (e) {

                    if(e.response && e.response.data && e.response.data.errors){
                        Object.keys(e.response.data.errors).forEach((field) => {
                            // create right object for handleErrorMessage function
                            const error = {message:t(e.response.data.errors[field], {field: `$t(formFields.${field})`})};
                            handleErrorMessage(error);
                        })
                    }else{
                        handleErrorMessage(e);
                    }
                    setIsLoading(false);
                    setIsSuccess(false);
                }

            })();
        }
    },[isSubmit]);

    const onFinish = () => {
        setCardData({});
        handleClose();
    }

    return (
        <>
            <div className={classes.contentInner}>
                {isLoading ? (
                    <>
                        <Skeleton width={"100%"} height={"40px"}/>
                        <Skeleton width={"100%"} height={"40px"}/>
                    </>
                ) : (
                    <div className={`h4 text-weight-200 text-align-center`}>
                        {isSuccess ? t("card.success") : t("card.cantCreate")}
                    </div>
                )}

                <div className={classes.btnWrap}>
                    {isSuccess ? (
                        <Button
                            onClick={() => onFinish()}
                            disabled={isLoading}
                        >
                            {t("formFields.continue")}
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setCurrentStep((oldStep) => oldStep-1)}
                            disabled={isLoading}
                            className={`btn-stroke`}
                        >
                            {t("formFields.back")}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default withRouter(FinishStep);

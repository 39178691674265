export const ACCOUNT_TYPES = {
    BANK: 'BANK',
    CURRENT: 'CURRENT',
    CURRLIAB: 'CURRLIAB',
    DEPRECIATN: 'DEPRECIATN',
    DIRECTCOSTS: 'DIRECTCOSTS',
    EQUITY: "EQUITY",
    EXPENSE: "EXPENSE",
    FIXED: 'FIXED',
    INVENTORY: 'INVENTORY',
    LIABILITY: "LIABILITY",
    NONCURRENT: 'NONCURRENT',
    OTHERINCOME	: 'OTHERINCOME	',
    OVERHEADS	: 'OVERHEADS	',
    PREPAYMENT: 'PREPAYMENT',
    REVENUE: "REVENUE",
    SALES: 'SALES',
    TERMLIAB: 'TERMLIAB'
};

export const ACCOUNT_CLASS_TYPES = {
    ASSET: "ASSET",
    EQUITY: "EQUITY",
    EXPENSE: "EXPENSE",
    LIABILITY: "LIABILITY",
    REVENUE: "REVENUE",
};

export const ACCOUNT_STATUS_CODE = {
    ACTIVE: "ACTIVE",
    ARCHIVED: "ARCHIVED"
};

export const BANK_ACCOUNT_TYPES = {
    BANK: "BANK",
    CREDITCARD: "CREDITCARD",
    PAYPAL: "PAYPAL	"
};

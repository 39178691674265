import React from 'react';
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles(theme => ({
            textWrapper:{
                color: "#8A96A0",
                fontSize:"14px",
                textAlign:"center",
                fontWeight:300,
                paddingTop:"20px",
                minWidth: "357px",
                maxWidth: "100%"
            }
        }
    )
);

const ConfirmTextWrapper = (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.textWrapper}>
            {props.children}
        </Box>
    );
};

export default ConfirmTextWrapper;

import React from 'react';
import ThinButton from "../../../../../../ThinButton";
import Box from "@material-ui/core/Box";

const ButtonRequestInfo = (props) => {
    return (
        <Box pb={"15px"}>
            <ThinButton disableTouchRipple={false} color={"primary"} noPadding {...props}/>
        </Box>
    );
};

export default ButtonRequestInfo;

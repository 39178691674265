import React from 'react';
import Box from "@material-ui/core/Box";
import FormatMoney from "../FormatMoney/FormatMoney";

const CellTransactionAmount = (props) => {
    const {amount, currency} = props;
    return amount ? <><Box component={"span"} marginRight={"5px"}>{currency}</Box> <span><FormatMoney>{amount}</FormatMoney></span></> : ""
};

export default CellTransactionAmount;

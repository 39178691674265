import React from 'react'
// import {Can} from "../../Context/Can";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import MakePayment from "../userSection/content/TransactionsMakePayment/MakePayment"

const TransactionsMakePaymentPage = (props) => {
    const {drawerOuterRef, underBarRef, settingsDarkMode} = props;

    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <MakePayment drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    )
}

export default TransactionsMakePaymentPage

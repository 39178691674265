import React from 'react';
import {makeStyles, Box} from "@material-ui/core";
import { isDarkModeActive } from '../../App/App';

const ToggleFieldWrapper = (props) => {
    const { children, settings } = props;
    const useStyles = makeStyles((themes) => ({
        toggleList: {
            "& .toggle-item": {
                display: 'flex',
                paddingBottom: '24px',
                [themes.breakpoints.down('1023')]: {
                    marginBottom: '8px'
                },
                "&:not(:last-child)": {
                    marginBottom: '24px',
                    borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
                    [themes.breakpoints.down('1023')]: {
                        marginBottom: '16px'
                    }
                }
            }
        }
    }))

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    return (
        <Box className={classes.toggleList}>
            {children}
        </Box>
    )
}

export default ToggleFieldWrapper

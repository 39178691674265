import React, {useEffect, useState} from 'react';
import ExpenseForm from "../../../../../ExpenseForm";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import Api from "../../../../../../services/api";
import useForm2 from "../../../../../../hooks/useForm2";

import {Prompt, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useBeforeUnload from "../../../../../../hooks/useBeforeUnload";
import {Ability, subject} from "@casl/ability";
import {Can} from "@casl/react";
import RejectButton from "./RejectButton";
import ThinButton from "../../../../../ThinButton";
import useExpenseValidation from "../../../../../../hooks/useExpenseValidation";
import moment from "moment-timezone";
import {confirm} from "../../../../../../helpers/confirm";
import ConfirmTextWrapper from "../../../../../ConfirmTextWrapper";
import getCardName from "../../../../../../helpers/getCardName";
import {
    Box,
    Button
} from "@material-ui/core";
const service = new Api();


const EditExpense = (props) => {
    const {
        handleErrorMessage,
        setMessage,
        regional,
        history,
        id,
        userId,
        // eslint-disable-next-line no-unused-vars
        match,
        advancedSettings,
        expense,
        setExpense,
        isExpenseLoading,
        settingsDarkMode
    } = props;

    const fields = ["taxAmount", "reportedCurrency", "reportedExchangeRate", "paidWith", "date", "merchant", "status", "amount", "currency", "category", "project", "country", "taxRate", "tax", "comment", "reimbursable", "receiptUri", "noReceiptReason", "transactionStatus", "paymentType", "vendor"];

    const [ability, setAbility] = useState(new Ability());

    const {t} = useTranslation();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const {rules, employee, ...restExpense} = expense;
    useEffect(() => {
        setAbility(new Ability(rules));
    },[rules]);

    // static data for form
    const [currencies, setCurrencies] = useState([]);
    const [paidWith, setPaidWith] = useState([]);
    const [countries, setCountries] = useState([]);
    const [taxRatesByCountry, setTaxRatesByCountry] = useState([]);
    const [rateIsLoading, setRateIsLoading] = useState(false);

    const fillData = async () => {
        const values = {};
        fields.forEach((field) => {
            if(restExpense[field] !== undefined){
                values[field] = restExpense[field];
            }
        });


        fillFormValues(values);

        try {
            if(restExpense["country"]){
                const taxRateByCountryResponse = await service.getTaxRates({countries: restExpense["country"], "no-limit": true});
                const mappedTaxRates = taxRateByCountryResponse.data.result.map(({name, _id,  taxRate, categories}) => ({label:name, value:_id, taxRate, categories}));
                setTaxRatesByCountry([
                    ...mappedTaxRates,
                ])
            }
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }
    }

    const getReportedExchangeRate = async (fromCurrency, toCurrency, date) => {

        const theSameCurrency = fromCurrency === toCurrency;

        const doNotCalculate = theSameCurrency || !fromCurrency || !toCurrency;

        if(doNotCalculate){
            handleChange("reportedExchangeRate", 1);
            return;
        }

        try {
            setRateIsLoading(true);
            const response = await service.getExchangeRate(fromCurrency, toCurrency, 1, date);
            handleChange("reportedExchangeRate", response.data.result);
        }catch (e) {
            handleErrorMessage(e);
        }finally {
            setRateIsLoading(false);
        }
    };


    useEffect(() => {
        fillData();
    },[expense]);

    const checkDisabledField = (field) => {
        return !ability.can("update", subject("expense", restExpense), field)
    }

    const {dateFormat, decimal} = regional;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const submit = async () => {
        setValuesWasChanged(false);

        const formValues = new FormData();

        try {
            setIsSubmitting(true);
            fields.forEach((field) => {
                formValues.append(field, values[field] !== undefined ? values[field] : "");
            });

            await service.updateExpense(id, formValues);
            setIsSubmitting(false);

            setMessage('success', 'success');
            history.push("/transactions");
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }


    const validate = useExpenseValidation(advancedSettings);

    const onActionExpense = async (action) => {
        setIsSubmitting(true);
        try{
            const response = await service.expenseAction(id, action);
            if(action === "recall"){
                setExpense(response.data)
            }else{

                history.push("/transactions");
            }
            setIsSubmitting(false);
            setMessage('success', 'success');

        }catch (e) {
            handleErrorMessage(e);
            setIsSubmitting(false);
        }
    }

    const deleteExpense = async () => {
        setIsSubmitting(true);
        try {
            await service.deleteExpense(id);

            setMessage("expense.deleted", 'success');
            setIsSubmitting(false);
            history.push("/transactions");
        }catch (e) {
            handleErrorMessage(e);
            setIsSubmitting(false);
        }
    }

    const { handleChange, handleSubmit, values, errors, fillFormValues, setServerErrors } = useForm2(
        submit,
        validate
    );

    const fetchExpenseData = async () => {
        const promises = [
            // static fields
            service.getServiceData({dataFor:"currencies-all"}),
            service.getServiceData({dataFor:"paidWith"}),
            service.getCardsForExpense(id),
            service.getServiceData({dataFor:"countries"})
        ];

        const response = await Promise.all(promises);

        try {
            setIsLoading(true);

            const [currencies, paidWith, cards, countriesResult] = response;

            // static fields
            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);


            const mappedCards = cards.data.map((card) => {
                const {id} = card;
                return {value: id, label: getCardName(card)}
            });
            const mappedPaidWith = paidWith.data.map(({value, name}) => ({label:name, value:value}));
            setPaidWith([
                ...mappedPaidWith,
                ...mappedCards
            ]);

            const mappedCountries = countriesResult.data.map((country) => {
                return {
                    label: country.name,
                    value: country["country-code"]
                }
            });
            setCountries(mappedCountries);

        }catch (e){
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        fetchExpenseData();
    },[]);

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }

    const onSubmit = (e, status) => {
        handleChange("status", status);
        handleSubmit(e);
    }

    const formMessage = (expense.rejectReason && expense.rejectReason.name) ? `${t(`expense.${expense.status}`)}: ${expense.rejectReason.name}` : null;


    const onDateChange = (date) => {
        const startOfDay = date ? moment(date).startOf('day') : null;
        getReportedExchangeRate(values["currency"], expense.reportedCurrency, startOfDay);
        handleChangeWithDetectChanges("date", startOfDay);
    }

    const onCurrencyChange = (currency) => {
        const startOfDay = values["date"] ? moment(values["date"]).startOf('day') : null;
        getReportedExchangeRate(currency, expense.reportedCurrency, startOfDay);
        handleChangeWithDetectChanges("currency", currency);
    }

    const showEmployeeField = employee && (employee._id !== userId);


    const onUnApprove = async () => {
        if (await confirm({
            confirmation: t('declineExpenseTitle'),
            yesText: t('formFields.decline'),
            noText:  t('formFields.cancel'),
            content: (
                <ConfirmTextWrapper>
                    <Box>{t('declineExpenseLine1')}</Box>
                    <Box>{t('declineExpenseLine2')}</Box>
                    <Box>{t('declineExpenseLine3')}</Box>
                </ConfirmTextWrapper>
            )
        })) {
            onActionExpense('unApprove');
        }

    }

    const actionButtons = (
        <>
            <Can I="approve" this={subject("expense", restExpense)} ability={ability}>
                <Button
                    disabled={isSubmitting}
                    onClick={() => onActionExpense('approve')}
                >
                    {t("formFields.approve")}
                </Button>
            </Can>
            <Can I="update" this={subject("expense", restExpense)} ability={ability}>
                <Button
                    disabled={isSubmitting}
                    onClick={(e) => onSubmit(e, 'submitted')}
                >
                    {t("formFields.saveAndSubmit")}
                </Button>
            </Can>
            <Can I="update" this={subject("expense", restExpense)} ability={ability}>
                <Button
                    className="btn-stroke"
                    disabled={isSubmitting}
                    onClick={(e) => onSubmit(e, 'toSubmit')}
                >
                    {t("formFields.save")}
                </Button>
            </Can>
            <Can I="recall" this={subject("expense", restExpense)} ability={ability}>
                <Button
                    className="btn-stroke"
                    disabled={isSubmitting}
                    onClick={() => onActionExpense('recall')}
                >
                    {t("formFields.recall")}
                </Button>
            </Can>
            <Can I="reject" this={subject("expense", restExpense)} ability={ability}>
                <RejectButton disabled={isSubmitting} id={id}/>
            </Can>
            <Can I="unApprove" this={subject("expense", restExpense)} ability={ability}>
                <Button
                    className="btn-stroke"
                    disabled={isSubmitting}
                    onClick={onUnApprove}
                >
                    {t("formFields.unApprove")}
                </Button>
            </Can>
            <Can I="delete" this={subject("expense", restExpense)} ability={ability}>
                <ThinButton
                    disabled={isSubmitting}
                    onClick={deleteExpense}
                >
                    {t("formFields.delete")}
                </ThinButton>
            </Can>
            <ThinButton
                onClick={() => history.push(`/transactions`)}
            >
                {t("formFields.cancel")}
            </ThinButton>
        </>
    )

    return (
        <>
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            <ExpenseForm
                dateFormat={dateFormat}
                values={values}
                handleChange={handleChangeWithDetectChanges}
                errors={errors}
                handleSubmit={handleSubmit}
                decimal={decimal}
                paidWith={paidWith}
                taxRatesByCountry={taxRatesByCountry}
                setTaxRatesByCountry={setTaxRatesByCountry}
                currencies={currencies}
                checkDisabledField={checkDisabledField}
                isLoading={isLoading || isExpenseLoading}
                countries={countries}
                formMessage={formMessage}
                advancedSettings={advancedSettings}
                onDateChange={onDateChange}
                onCurrencyChange={onCurrencyChange}
                rateIsLoading={rateIsLoading}
                employee={showEmployeeField ? employee : undefined}
                settingsDarkMode={settingsDarkMode}
                actionsButons={actionButtons}
                formType={values["paymentType"]}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    const {user} = state;
    const {_id, company={}} = user.userData;
    const {settings} = user;
    const {regional} = settings;
    const {advancedSettings} = company;

    return {
        regional,
        userId:_id,
        advancedSettings
    }
};
export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage}),
    withRouter,
)(EditExpense);

import formatMoney from "./formatMoney";

const setFormat = (decimal) => {
    return (amount) => formatMoney(amount, decimal);
}

const stringToMoney = (str, decimal) => {
    return str.replace(/(\d{1,})/g, setFormat(decimal));
}

export default stringToMoney

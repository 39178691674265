import React, {Children, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {Can} from "../../../../../../Context/Can";
import {useTranslation} from "react-i18next";
import Search from "../../../../Bar/Search";
import EmailForm from './EmailForm'
import FilterButton from "../../../../../FilterButton";
import AddUsersBtn from './AddUsersBtn'
import ActionBtn from "../TopContent/ActionBtn"

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px',
            flexFlow: 'row wrap'
        },
    },
    searchWrapper: {
        position: 'relative',
        width: '360px',
        marginRight: 'auto',
        [theme.breakpoints.down('1023')]: {
            width: '250px',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 16px',
        },
        "& .MuiFormControl-root": {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            position: 'relative',
            "& button": {
                margin: '0',
                padding: '0',

                "& svg path": {
                    transition: 'fill .2s',
                },

                "&:hover": {
                    backgroundColor: 'transparent',

                    "& svg path": {
                        stroke: 'var(--clear-blue-primary)'
                    }
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            }
        }
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginLeft: 'auto',
        gap: '8px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            flexFlow: 'row-reverse wrap'
        }
    },

    actionWrapper: {
        [theme.breakpoints.down('767')]: {
            flex: '1',
            marginLeft: 'auto',
            display: 'flex',

            "& button": {
                marginLeft: 'auto'
            }
        }
    }
}))

const TopContentInvesthub = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {onFilterOpen, isFilterOpen, onImport, selected, setSelected, settingsDarkMode} = props;
    const [showForm, setShowForm] = useState(false);

    return (
        <>
            <div className={classes.head}>
                <div className={classes.searchWrapper}>
                    {/* NEED TRANSLATION */}
                    <Search type="users" placeholderText={t('Search by email')} settingsDarkMode={settingsDarkMode} />
                </div>

                <div className={classes.control}>

                    {!showForm && (
                        <Can I="update" a={"user"}>
                            <ActionBtn selected={selected} setSelected={setSelected} settingsDarkMode={settingsDarkMode} fromParentClass={classes.actionWrapper} />
                        </Can>
                    )}

                    <AddUsersBtn
                        onImport={onImport}
                        setShowForm={setShowForm}
                        showForm={showForm}
                        settingsDarkMode={settingsDarkMode}
                    />

                    {!isFilterOpen && <FilterButton onClick={onFilterOpen} settingsDarkMode={settingsDarkMode} />}
                </div>
            </div>

            {showForm && (
                <EmailForm setShowForm={setShowForm} settingsDarkMode={settingsDarkMode} />
            )}
        </>
    )
}

export default withRouter(TopContentInvesthub)

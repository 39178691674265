import React, {memo} from 'react';
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";


const InputFieldMemo = (props) => {
	const noHandler = () => {
		console.log(`No handler for ${name} input field`);
	};


    const {value="", error="", onInputChange=noHandler, label="", name="", disabled=false, isLoading, ...AdditionalFieldProps} = props;

    const useStyles = makeStyles({
        inputComponent: {
        },
        helperMsg:{
            wordBreak:"break-word"
        }
    });

    const classes = useStyles();

    // remove underline for textarea
    const useStylesInput = makeStyles({
        underline: {
            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        }
    });

    const classesInput = useStylesInput();

    const inputProps = {};
    if(props.multiline){
        inputProps.classes = classesInput
    }

    return (
        <>
            {isLoading ? (<Skeleton height={"50px"} width={"100%"}/>) : (
                    <TextField
                        label={label}
                        id={`input-${name}`}
                        name={name}
                        disabled={disabled}
                        error={!!error}
                        value={value}
                        onChange={(e) => onInputChange(name, e.target.value)}
                        className={classes.inputComponent}
                        margin={"none"}
                        InputProps={inputProps}
                        InputLabelProps={props.multiline  ? {shrink: true} : null}
                        {...AdditionalFieldProps}
                    />
                )
            }

            {!!error && <FormHelperText className={classes.helperMsg}>{error}</FormHelperText>}
        </>
    );
};

export default memo(InputFieldMemo);

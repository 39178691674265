import React from 'react';
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    sendingAnimationWrapper:{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(255, 255, 255, 0.5)",
        zIndex:1,
        justifyContent: "center",
        alignItems: "center",
        display:"flex",
    }
}));

const SendingAnimationWrapper = (props) => {
    const classes = useStyles();
    const {children} = props;

    return (
        <Box className={classes.sendingAnimationWrapper}>
            {children}
        </Box>
    );
};

export default SendingAnimationWrapper;

import React, {useEffect, useState} from 'react';
import DrawerWidthContext from "../../Context/DrawerWidthContext";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const initialDrawerWidth = 280;
const DrawerWidthProvider = (props) => {
    const [width, setWidth] = useState(initialDrawerWidth);
    const theme = useTheme();

    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {

        if(isSmDown || isLgUp){
            setWidth(initialDrawerWidth);
        }else{
            setWidth(initialDrawerWidth-80)
        }

    },[isLgUp, isSmDown]);

    return (
        <DrawerWidthContext.Provider value={width}>
            {props.children}
        </DrawerWidthContext.Provider>
    )
};

export default DrawerWidthProvider;

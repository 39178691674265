import * as actionTypes from '../../store/actionTypes'
import Api from '../../services/api';
const service = new Api();

const initPublicSetting = () => {
	return async (dispatch)  => {
		try {
			const { data } = await service.getPublicSetting();
			dispatch({
				type:actionTypes.INIT_SETTING,
				payload: data.settings
			})

			return data.settings;
		} catch (error) {
			console.log('error', error);
			const defaultSettings = {
				logo_path: 'fbeeecb9-2fe9-4ed4-8020-5ea1519448ac.svg',
				logo_for_dark_bg_path: 'fbeeecb9-2fe9-4ed4-8020-5ea1519448ac.svg',
				kycHost: "roascapital2-dev.investhub.io",
				originHost: "dev-evolve.investhub.io",
				companyName: "Investhub"
			}
			dispatch({
				type:actionTypes.INIT_SETTING,
				payload: defaultSettings
			})

			return defaultSettings;
		}
	};
}

export {
    initPublicSetting,
}

import React from "react"
import {makeStyles} from "@material-ui/core"
import {NavLink, matchPath, useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next"
import ActionBtn from "../ActionBtn/ActionBtn"
import BackButton from "../../../../BackButton"
import { isDarkModeActive } from "../../../../App/App"
import CreateAccountPopup from "../AccountsChartComponents/CreateAccountPopup"
import { ACCOUNT_CLASS_TYPES } from "../../../../../constants/xero"

const useStyles = makeStyles(theme => {
    return {
        head: {
            display: "flex",
            alignItems: "center",
            minHeight: '40px',
            position: 'relative',
            marginBottom: '32px',

            [theme.breakpoints.down('767')]: {
                flexWrap: 'wrap',
                paddingBottom: '24px',
                marginBottom: '24px'
            },
        },
        headTitle: {
            position: 'relative',
            display: 'inline-block',
            paddingLeft: '32px',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '1.6',
            letterSpacing: '-.02em',
            [theme.breakpoints.down('767')]: {

                fontSize: '18px',
                marginBottom: '16px',
            },
        },
        headTitleFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',

            "& .MuiButtonBase-root": {
                marginLeft: 'auto',
                marginRight: '0'
            }
        },
        headNav: {
            display: 'flex',
            marginLeft: 'auto',
            [theme.breakpoints.down('767')]: {
                marginLeft: '0'
            }
        },
        headLink: {
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'all .2s',
            backgroundColor: 'transparent',
            [theme.breakpoints.down('767')]: {
                flexGrow: '1',
                // marginTop: '16px'
            },
            '&:not(:last-child)': {
                marginRight: '4px',
                [theme.breakpoints.down('767')]: {
                    marginRight: '0'
                }
            },
            '&:hover': {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
            },
            '&.active': {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)'
            },
        },
        headControl: {
            display: 'flex',
            marginLeft: '8px',
            [theme.breakpoints.down('767')]: {
                marginLeft: 'auto'
            }
        },
    }
});

const HeadContent = (props) => {
    const {t} = useTranslation();
    const {routes=[], rightContent, settingsDarkMode, setIsOpenModal, isOpenModal} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const types = Object.values(ACCOUNT_CLASS_TYPES).map((e)=> {
        return {
            label: e,
            value: e
        }
    })
    const location = useLocation();
    const matchedPathAccountsOfChart = matchPath(location.pathname, {
        path: "/chart-of-accounts",
        exact: true,
        strict: false,
    });

    const matchedPathAccountsOfRules = matchPath(location.pathname, {
        path: "/chart-of-accounts/rules",
        exact: true,
        strict: false
    });

    let titleName = null;
    let titleButton = null;
    // let hasTitleButton = false;
    // NEED TRANSLATION
    if (matchedPathAccountsOfChart) {
        titleName = <>{t("Chart of Accounts")}</>

    }
    // NEED TRANSLATION
    if (matchedPathAccountsOfRules) {
        titleName = <>{t("Chart of Rules")}</>
    }

    return (
        <div className={classes.head}>
            <div className={`${classes.headTitle} card-title card-title--blue ${matchedPathAccountsOfRules ? classes.headTitleFlex : ''}`}>
                {titleName}

                {titleButton}
                {matchedPathAccountsOfRules && (
                    <BackButton />
                )}
            </div>
            <CreateAccountPopup types={types} setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal}></CreateAccountPopup>
            <div className={classes.headNav}>
                {!matchedPathAccountsOfRules && (
                    <>
                        {routes.map((item, key) => {
                            return (
                                <NavLink
                                    key={key}
                                    className={classes.headLink}
                                    exact
                                    to={item.url}
                                >
                                    {item.name}
                                </NavLink>
                            )
                        })}
                    </>
                )}
            </div>

            <div className={classes.headControl}>
                {!matchedPathAccountsOfRules && (
                    <ActionBtn settingsDarkMode={settingsDarkMode} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}/>
                )}


                {rightContent}
            </div>
        </div>
    )
}

export default HeadContent


import React from 'react';
import {
    Link,
    withRouter
} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {ReactComponent as Logo} from '../../../../../assets/iconEvolveWhite.svg'
import VolveSnackbar from "../../../../VolveSnackbar";
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({
    kycInfoSnackbar: {
        position: 'relative',
        padding: '12px',
        fontFamily: `'Inter', sans-serif`,
        fontSize: '15px',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)': 'var(--dark-secondary)',
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--pale-grey)',
        display: 'flex',

        "& .MuiListItemIcon-root": {
            display: 'none'
        },
        "& .MuiSnackbarContent-action": {
            margin: '0',
            position: 'initial'
        },
        "& .MuiTypography-root": {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '15px',
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)': 'var(--dark-secondary)',
            lineHeight: '1.6'
        }
    },
    link:{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },
}));


const KYBInfo = (props) => {
    const { user, settingsDarkMode } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const {userData={}} = user;
    const {company={}} = userData;
    const {t} = useTranslation();

    return (
        !company.kyb ? (
                <VolveSnackbar
                    customClass={classes.kycInfoSnackbar}
                    message={<>{t("verifyKyb")}</>}
                    action={<><Link to={`/settings/company/verify`} className={classes.link}>{' '}</Link></>}
                    icon={<Logo width={"27px"} height={"27px"}/>}
                />
        ) : null
    );
};

export default KYBInfo;

import React from 'react';
import {Route, Switch} from "react-router-dom";
import Wallets from "./Wallets";

const Balances = (props) => {
    const {url, underBarRef, settingsDarkMode } = props;

    return (
        <>
            <Switch>
                <Route exact path={url} render={
                    () => {
                        return <Wallets underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                    }
                }
                />
            </Switch>
        </>
    );
};

export default Balances;

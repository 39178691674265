import React, {useEffect, useState} from 'react';
import {FormControl, makeStyles} from "@material-ui/core";
import SelectField from "../../../../../../formElements/SelectField";
import useForm from "../../../../../../../hooks/useForm";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../../services/api";
import Button from "@material-ui/core/Button";
import formatMoney from "../../../../../../../helpers/formatMoney";

const service = new Api();

const useStyles = makeStyles(theme => ({
    contentInner:{
        maxWidth: "380px",
        width:"340px",
        marginLeft:"auto",
        marginRight:"auto",
        paddingBottom:"20px",
        paddingTop:"20px",
    },

    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },

    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'var(--charcoal-primary)'
        }
    },

    btnWrap: {
        display: 'flex',
        gap: '16px',
        marginTop: '16px'
    }
}));

const LimitStep = (props) => {


    const {handleErrorMessage, setCurrentStep, setCardData, cardData, setTitle, selectedCurrency, decimal} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [monthLimits, setMonthLimits] = useState({});
    const [limitOptions, setLimitOptions] = useState([]);

    const classes = useStyles();
    const {t} = useTranslation();


    useEffect(() => {
        Object.keys(cardData).forEach((key) => {
            handleChange(key, cardData[key])
        });
    },[cardData]);

    useEffect(() => {
        setTitle(t("card.setLimit"));
        setIsLoading(true);
        (async() => {

            try {
                const monthLimits = await service.getServiceData({dataFor:"cardMonthLimits"});

                setMonthLimits(monthLimits.data);
                setIsLoading(false);
            }catch (e) {
                console.log(e);
                handleErrorMessage(e);
                setIsLoading(false);
            }
        })();

    },[]);

    useEffect(() => {
        if(selectedCurrency && Object.keys(monthLimits).length){
            const reducedMonthLimits = Object.keys(monthLimits).reduce((accum, currency) => {
                accum[currency] = monthLimits[currency].map(({value}) => ({
                    value,
                    label: `${formatMoney(value, decimal)} ${selectedCurrency ? selectedCurrency : ""}`
                }));
                return accum;
            }, {});
            setLimitOptions(reducedMonthLimits[selectedCurrency]);
        }
    },[selectedCurrency, monthLimits]);


    const submit = () => {
        setCardData((prevValues) => ({
            ...prevValues,
            ...values
        }));
        setCurrentStep((oldStep) => oldStep+1);
    };

    const validate = () => {
        return {}
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    return (
        <>
            <div className={classes.contentInner}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.itemSettings}>
                        <FormControl fullWidth error={!!errors.monthLimit} className={classes.itemFieldFull}>
                            <SelectField
                                isLoading={isLoading}
                                label={t("formFields.monthLimit")}
                                name={"monthLimit"}
                                value={values["monthLimit"]}
                                options={limitOptions}
                                onSelectChange={(val) => handleChange("monthLimit", val)}
                                error={errors["monthLimit"]}
                                alignRight
                            />
                        </FormControl>
                    </div>
                </form>

                <div className={classes.btnWrap}>
                    <Button
                        onClick={handleSubmit}
                    >
                        {t("formFields.continue")}
                    </Button>
                    <Button
                        onClick={() =>setCurrentStep((oldStep) => oldStep-1)}
                        className={`btn-stroke`}
                    >
                        {t("formFields.back")}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default LimitStep;

import React from 'react';
import {
    makeStyles,
    SnackbarContent,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles((theme) => (
    {
        snackBar: {
            width: "100%",
            display: 'block',
            marginBottom: '32px',
            borderRadius: '12px',
            color: (props) => props.isDarkModeActive ? 'var(--very-light-pink-primary)' : 'var(--dark-secondary)',
            backgroundColor: (props) => props.isDarkModeActive ? 'rgba(177, 229, 252, 0.1)' : '#ecf9fe',
            padding: '32px',
            [theme.breakpoints.down('767')]: {
                marginBottom: '24px'
            },
            "& .MuiSnackbarContent-message":{
                padding:0
            },

            "& .MuiListItemText-root":{
                margin: '0'
            },
            "& .MuiTypography-root": {
                fontFamily: `'Inter', sans-serif`,
                fontWeight: '600',
                lineHeight: '1.23077',
                color: (props) => props.isDarkModeActive ? 'var(--very-light-pink-secondary)' : 'var(--charcoal-primary)'
            },

            "& .MuiSnackbarContent-action":{
                margin: '16px 0',
                padding: '0',
                position: 'relative',
                zIndex: '2',
            }
        },
        icon: {
            width: "48px",
            minWidth: "48px",
            height:"48px",
            minHeight:"48px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "12px"
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0
        }
    }
));


const VolveSnackbar = (props) => {
    const {message, action, icon, settings, customClass} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });


    return <SnackbarContent className={`${classes.snackBar} ${customClass}`} message={
        <ListItem component={"div"} disableGutters className={classes.item}>
            <ListItemIcon className={classes.icon}>{icon ? icon : null}</ListItemIcon>
            <ListItemText primary={message} primaryTypographyProps={{variant:"body2"}}/>
        </ListItem>
    } action={action ? action : null} elevation={0} />;
};

export default VolveSnackbar;

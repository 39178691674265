import React, {useEffect, useState} from 'react';
import DashboardPane from "../../../../DashboardPane";
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Api from "../../../../../services/api";
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage} from '../../../../Messages/actions'
import FormatMoney from "../../../../FormatMoney/FormatMoney";
import Box from "@material-ui/core/Box";
import FormatDate from "../../../../FormatDate";
import ExpenseStatus from "../../../../ExpenseStatus";
import {makeStyles} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress'
// import * as animationData from "../../../../../assets/animations/list";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import useActiveTab from "../../../../../hooks/useActiveTab";
import checkClick from "../../../../../helpers/checkClick";
import DashboardPaneActionButton from "../../../../DashboardPane/DashboardPaneActionButton";
const service = new Api();

const useStyles = makeStyles(theme => ({
    secondLine: {
        fontSize: 12,
        lineHeight:1
    },
    merchant:{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "200px",
        overflow: "hidden",
    }

}));



const LastExpenses = (props) => {
    const {history, handleErrorMessage, dateFormat, dashboardTableMinHeight} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const activeTab = useActiveTab('dashboard');

    const [isLoading, setIsLoading] = useState(false);
    const [expenses, setExpenses] = useState([]);

    const fetchLastExpenses = async (activeTab) => {
        try {
            setIsLoading(true);
            const response = await service.getDashboardLatest({fromTab:activeTab});
            setExpenses(response.data.result);
        } catch (e) {
            console.log(e);
            handleErrorMessage(e);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if(activeTab){
            fetchLastExpenses(activeTab);
        }
    },[activeTab]);

    const columns = [
        {
            title: t('thead.expense'),
            render: ({merchant, status, vendor }) => {
                return (
                    <>
                        <Box className={classes.merchant}>
                            {merchant ? merchant : vendor
                                ? `${
                                      vendor?.companyName ||
                                      vendor?.firstName + vendor?.lastName ||
                                      ""
                                  }`
                                : ""}
                        </Box>
                        <Box className={classes.secondLine}>
                            <ExpenseStatus status={status} />
                        </Box>
                    </>
                );
            }
        },
        {
            title: t('thead.date'),
            field: 'date',
            render: ({date}) => <FormatDate dateFormat={dateFormat}>{date}</FormatDate>
        },
        {
            title: t('thead.amount'),
            align:'right',
            render: (row) => {
                const {reportedCurrency, calculatedAmount, currency, amount} = row;
                return (
                    <>
                        <Box>{reportedCurrency} <FormatMoney>{calculatedAmount}</FormatMoney></Box>
                        {(reportedCurrency !== currency) && (
                            <Box className={classes.secondLine}>
                                {currency} <FormatMoney>{amount}</FormatMoney>
                            </Box>
                        )}
                    </>
                )
            }
        }
    ];
    const rowHandlerClick = (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push({ pathname:`/transactions/${rowData._id}`});
        }
    }

    const getTitle = () => {
        switch (activeTab) {
            case "personal" :
                return t("dashboard.expensesLatestTitleMy");
            case "approver" :
            case "manager" :
                return t("dashboard.expensesLatestTitleTeam");
            default :  return t("dashboard.expensesLatestTitle");
        }
    };

    const title = getTitle();

    return (
        <DashboardPane
            title={title}
            action={<DashboardPaneActionButton onClick={() => history.push(`/transactions`)}>{t("expense.seeAll")}</DashboardPaneActionButton>}
        >
            <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={expenses}
                options={{
                    header: false,
                    minBodyHeight: dashboardTableMinHeight,
                }}
                onRowClick={rowHandlerClick}
                EmptyComponent={() => <CircularProgress />}
            />
        </DashboardPane>
    );
};


const mapStateToProps = ({user}) => {
    return {
        dateFormat:user.settings.regional.dateFormat
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {handleErrorMessage})
)(LastExpenses);

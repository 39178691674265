import React from 'react';
import {makeStyles} from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
const useStyles = makeStyles(theme => ({
	snackbar:{
		background: "#ff9800",
		fontSize: "1rem"
	}

}));


const WarningMessage = ({message}) => {
	const classes = useStyles();
	return message ? (
		<SnackbarContent
			message={message}
			className={classes.snackbar}
		/>
	) : null;
};

export default WarningMessage;

import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ActivationForm from "./ActivationForm";
import useForm from "../../../../../../hooks/useForm";
import Api from "../../../../../../services/api";
import {useTranslation} from "react-i18next";

const service = new Api();

const useStyles = makeStyles(theme => ({
			title:{
				color: "#1a173b",
				fontSize: "35px",
				fontWeight: 300,
				letterSpacing: "0.24px",
				textDecoration: "none"
			}
		}
	)
);

const ActivateCard = (props) => {
	const {t} = useTranslation();
	const {setMessage, card, setCard} = props;
	const cardId = card._id;


	const classes = useStyles();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const validate = (values) => {
		const errors = {};
		if(!values['cvv']){
			errors["cvv"] = t("errors.required", {field: "$t(formFields.cvv)"})
		}
		return errors;
	};



    const submit = async () => {
        setIsSubmitting(true);
        const {cvv} = values;
        try {
            const response = await service.activateCard(cardId, {cvv});
            setCard((oldCard) => {
                return {
                    ...oldCard,
                    ...response.data,
                }
            });
            setMessage("success", 'success');
        }catch (e) {
            setServerErrors(e);
        }finally {
            setIsSubmitting(false);
        }

    }

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );


	return (
		<div>
			<Typography variant="h1" gutterBottom className={classes.title}>
				{t("card.notLiveTitle")}
			</Typography>
			<ActivationForm
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				errors={errors}
				values={values}
				isSubmitting={isSubmitting}
			/>

		</div>
	);
};

export default ActivateCard;

import React, {useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchInput from "../SearchInput";
import {handleErrorMessage} from "../../../../Messages/actions";
import {useDebouncedCallback} from "use-debounce";
import {useDispatch} from "react-redux";
import {ReactComponent as SearchIcon} from "../../../../../assets/search.svg"
import { IconButton, InputAdornment, makeStyles, Popper } from '@material-ui/core';
const minSymbols = 1;


const SearchAutocomplete = (props) => {
    const {getOptions, onOptionClick, renderOption, getOptionLabel, role, inputValue, handleChange, handleSubmit, getOptionPlaceholder, settingsDarkMode} = props;

    const useStyles = makeStyles({
        field: {
            "& .MuiInputBase-root.MuiInputBase-adornedStart .MuiInputAdornment-positionStart.start_block": {
                backgroundColor: 'transparent'
            },
            "& .MuiIconButton-root": {
                paddingLeft: '0 !important',
                width: '100% !important',
                right: '0 !important',
                "& svg": {
                    width: '20px',
                    height: '20px',
                    fill: '#6F767E !important',
                    transition: 'fill .2s',
                }
            }
        }
    });

    const classes = useStyles();
    const dispatch = useDispatch();

    const [autocompleteIsSearching, setAutocompleteIsSearching] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);


    const onGetOptions = async (searchValue) => {
        if(searchValue.length < minSymbols){
            setAutocompleteOptions([]);
        }else{
            try {
                setAutocompleteIsSearching(true);

                const response = await getOptions({searchValue, role});
                setAutocompleteOptions(response.data.result);
            } catch (e) {
                console.log(e);
                dispatch(handleErrorMessage(e));
            } finally {
                setAutocompleteIsSearching(false);
            }
        }

    }

    const debounced = useDebouncedCallback(onGetOptions, 1000);
    const onInputChange = (e, val) => {
        handleChange("searchValue", val);
        debounced(val);
    };

    const onHandleSubmit = (e) => {
        handleSubmit(e);
        setAutocompleteOptions([]);

    }

    return (
        <Autocomplete
            freeSolo={true}
            inputValue={inputValue ? inputValue : "" }
            loading={autocompleteIsSearching}
            options={autocompleteOptions}
            onChange={onOptionClick}
            onInputChange={onInputChange}
            PopperComponent={(props) => <Popper {...props} style={{ width: 250 }} placement='bottom-start' />}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => {
                const {endAdornment, className, ...restInputProps} = params.InputProps;
                return <SearchInput {...params} InputProps={restInputProps} handleSubmit={onHandleSubmit} searchInputPlaceholder={getOptionPlaceholder} settingsDarkMode={settingsDarkMode} />
            }}
            className={classes.field}
            InputProps={{
                startAdornment: (
                    <React.Fragment>
                        <InputAdornment className={`start_block`} position="start" onClick={onOptionClick}>
                            <IconButton aria-label="Searching" className={classes.btnWrapper}>
                                <SearchIcon className={classes.icon}/>
                            </IconButton>
                        </InputAdornment>
                    </React.Fragment>
                )
            }}
        />

    );
};

export default SearchAutocomplete;

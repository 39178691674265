import React from 'react';
import {SnackbarProvider} from "notistack";
import {ReactComponent as NotySuccess} from "../../assets/notySuccess.svg";
import {ReactComponent as NotyFailure} from "../../assets/notyFailure.svg";
import {ReactComponent as NotyExclamation} from "../../assets/notyExclamation.svg";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
            variant: {
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "6px",
                padding: "6px 12px",
                paddingLeft: "20px",
                '& .MuiSnackbarContent-message': {
                    padding: "7px 0"
                },
                '& svg': {
                    marginRight: "9px",
                }
            },
            variantSuccess: {
                background: "#31BC71"
            },
            variantFailure: {
                background: "#FD5B60"
            },
            variantInfo: {
                background: "#f5a623"
            }
        }
    )
);



const NotyStackProvider = (props) => {
    const classes = useStyles();
    return (
        <SnackbarProvider
            maxSnack={5}
            classes={{
                variantSuccess: `${classes.variant} ${classes.variantSuccess}`,
                variantError: `${classes.variant} ${classes.variantFailure}`,
                variantWarning: `${classes.variant} ${classes.variantInfo}`,
                variantInfo: `${classes.variant} ${classes.variantInfo}`,
            }}
            iconVariant={{
                success: <NotySuccess/>,
                error: <NotyFailure/>,
                warning: <NotyExclamation/>,
                info: <NotyExclamation/>,
            }}
        >
            {props.children}
        </SnackbarProvider>
    );
};

export default NotyStackProvider;

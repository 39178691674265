import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    inputWrapper: {
        display: "flex",
        marginBottom: "33px",
        '&.MuiFormControl-fullWidth:last-child':{
            marginBottom: "22px",
        }
    }
}));

const FilterFormControl = (props) => {
    const classes = useStyles();
    const {children} = props;


    return (
        <FormControl fullWidth className={classes.inputWrapper}>
            {children}
        </FormControl>
    );
};

export default FilterFormControl;

import React, {useEffect, useState} from 'react';
import useMounted from "../../../../../hooks/useMounted";
import BackButton from "../../../../BackButton";
import GroupForm from "../../../../GroupForm";
import useForm2 from "../../../../../hooks/useForm2";
import {Can} from "../../../../../Context/Can";
import {useTranslation} from "react-i18next";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useDispatch} from "react-redux";
import Members from "./Members";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import {makeStyles, Typography, Button, Portal} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        minHeight: "40px",
        position: 'relative',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '32px',
        paddingBottom: '16px',
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            marginBottom: '24px'
        },
    },
    item: {
        position: 'relative'
    },
    lastButton: {
        marginLeft: '16px'
    }
}))

const Group = (props) => {
    const {underBarRef, match, history} = props;
    const {params} = match;
    const {id} = params;

    const classes = useStyle();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();

    const [group, setGroup] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const fetchGroup = async (id) => {
        try {
            setIsLoading(true);
            const response = await service.getGroup(id);
            setGroup(response.data);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchGroup(id);
    },[id]);


    useEffect(() => {
        if(Object.keys(group).length){
            const {name, excludeFromApprovalFlow, accountingCode} = group;
            fillFormValues({name, excludeFromApprovalFlow, accountingCode})
        }
    },[group]);



    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.updateGroup(id, values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/groups");
        }catch (e) {
            console.log(e);
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const onDeleteAction = async () => {
        setIsSubmitting(true);
        try {
            await service.deleteGroup(id);
            dispatch(setMessage("success", 'success'));
            history.push("/settings/groups");

        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const accountingCodeRegex = getAlphaNumericRegex();
        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }

        if (values["accountingCode"] && !accountingCodeRegex.test(values["accountingCode"].toString())) {
            errors.accountingCode = t("errors.notValid", {field:"$t(formFields.accountingCode)"});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}
            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>
                <div className={classes.item}>
                    <GroupForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        loading={loading}
                    />
                    <Members members={group.members}/>
                </div>

                <Can I="update" a={"group"}>
                    <Button
                        onClick={handleSubmit}
                        disabled={isSubmitting || loading}
                    >
                        {t("formFields.update")}
                    </Button>
                </Can>

                <Can I="delete" a={"group"}>
                    <Button
                        onClick={onDeleteAction}
                        disabled={isSubmitting || loading}
                        className={classes.lastButton}
                    >
                        {t("formFields.delete")}
                    </Button>
                </Can>
            </div>
        </>
    );
};

export default Group;

import React from 'react';
import {useTranslation} from "react-i18next";
import NumericInput from "../../formElements/NumericInput";

const ReportedExchangeRate = (props) => {
    const {isLoading, decimal, value} = props;
    const {t} = useTranslation();

    return (
            <NumericInput
                label={t("formFields.exchangeRate")}
                decimal={decimal}
                name={"taxRate"}
                disabled
                isLoading={isLoading}
                maskProps={{scale:2, padFractionalZeros:false}}
                value={value}
                placeholder={t("formFields.exchangeRate")}
            />
    );
};

export default ReportedExchangeRate;

import {combineReducers} from 'redux'

import drawerMain from '../components/userSection/DrawerMain/reducers'
import userCards from '../components/userSection/content/Cards/reducers'
import userSectionUsers from '../components/userSection/content/Users/reducers'
import user from "../components/User/reducers";
import messages from "../components/Messages/reducers";
import userAccounts from "../components/userSection/content/Accounts/reducers";
import userAccountCards from "../components/userSection/content/Accounts/AccountsTable/Account/AccountCardsTable/reducers";
import userAccount from "../components/userSection/content/Accounts/AccountsTable/Account/reducers";
import userGroups from "../components/userSection/content/Groups/reducers";
import userBranches from "../components/userSection/content/Branches/reducers";
import userFundingBankAccounts from '../components/userSection/content/FundingBankAccounts/reducers'
import tabs from "../components/userSection/Bar/TopTabs/reducers";
import publicSettings from "../components/Settings/reducers";

export default combineReducers({
	drawerMain,
	userCards,
	userSectionUsers,
	user,
	messages,
	userAccounts,
	userAccountCards,
	userAccount,
	userGroups,
	userBranches,
	userFundingBankAccounts,
	tabs,
	publicSettings,
})

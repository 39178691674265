import React from 'react';
import {
    Link,
    // eslint-disable-next-line no-unused-vars
    withRouter
} from "react-router-dom";
import {
    makeStyles
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
// import {ReactComponent as Logo} from "../../../../../assets/logoInvesthub.svg";
import VolveSnackbar from "../../../../VolveSnackbar";
import { isDarkModeActive } from '../../../../App/App';

const useStyles = makeStyles(theme => ({
    kycInfoSnackbar: {
        position: 'relative',
        padding: '12px',
        fontFamily: `'Inter', sans-serif`,
        fontSize: '15px',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)': 'var(--dark-secondary)',
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--pale-grey)',
        display: 'flex',

        "& .MuiListItemIcon-root": {
            display: 'none'
        },
        "& .MuiSnackbarContent-action": {
            margin: '0',
            position: 'initial'
        },
        "& .MuiTypography-root": {
            lineHeight: '1.6'
        }
    },
    link:{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },

}));

const KYCInfo = (props) => {
    const {user, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    const {userData} = user;
    const {_id} = userData;

    const canShowMessage = userData.status === "active" && !userData.kyc;
    return (
        canShowMessage ? (
                <VolveSnackbar
                    customClass={classes.kycInfoSnackbar}
                    message={<>{t("verifyKyc")}</>}
                    settings={settingsDarkMode}
                    action={
                        <>
                            <Link to={`/user-verification/${_id}`} className={classes.link}>{' '}</Link>
                        </>
                    }
                />
        ) : null

    );
};

export default KYCInfo;

import React, {useEffect, useState} from 'react';
import Api from "../../../services/api";
import MccDialog from "./MccDialog";
import useForm2 from "../../../hooks/useForm2";
import {useTranslation} from "react-i18next";
import InputField from "../InputField";
import Skeleton from "react-loading-skeleton";
import {createFilterOptions, Autocomplete} from "@material-ui/lab";
import {FormLabel, Chip} from "@material-ui/core";
const service = new Api();
const filter = createFilterOptions({
    stringify:function (item) {
        const {value, label} = item;
        return `${value} ${label}`
    }
});

const MccField = (props) => {
    const {t} = useTranslation();

    const {parentHandleChange, parentValue=[], loading, disabled} = props;

    const [open, setOpen] = useState(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);
    const [options, setOptions] = useState([]);


    useEffect(() => {
        const allDataLoaded = (!loading && !isOptionsLoading);
        if(allDataLoaded){

            const mappedValues = parentValue.map((value) => {
                const foundIdx = options.findIndex(option => option.value === value.value);
                if(foundIdx !== -1){
                    // create link to object from options to have possibility detect equal objects
                    options[foundIdx]._id = value._id;
                    return options[foundIdx];
                }else{
                    return value
                }
            });

            parentHandleChange("mcc", mappedValues);
        }

    },[loading, isOptionsLoading]);

    const fetchMccCategories = async () => {
        try{
            setIsOptionsLoading(true);
            const response = await service.getServiceData({dataFor: "mcc"});
            setOptions(response.data);
        }catch (e) {
            console.log(e);
        }finally {
            setIsOptionsLoading(false);
        }
    }


    useEffect(() => {
        fetchMccCategories();
    },[]);

    const submit = () => {
        const old = parentValue;

        const newValue =  {
            label: values.label,
            value: values.value,
        };

        parentHandleChange("mcc", [...old, newValue]);

        setOpen(false);

    }


    const validate = (values) =>{
        const errors = {};
        if (!values[`value`]) {
            errors[`value`] = t("errors.required", {field:"$t(formFields.mccCode)"});
        }
        if (!values[`label`]) {
            errors[`label`] = t("errors.required", {field:"$t(formFields.mccDesc)"});
        }

        return errors;
    };


    const {handleChange, handleSubmit, values, fillFormValues, errors} = useForm2(
        submit,
        validate
    );

    return (
        <>
            <FormLabel>{t("formFields.mcc")}</FormLabel>
            {isOptionsLoading ? (
                <Skeleton/>
            ) : (
                <Autocomplete
                    multiple
                    disabled={disabled}
                    value={parentValue}
                    filterSelectedOptions
                    onChange={(event, newValue, reason, ) => {
                        if(reason === "create-option"){
                            setOpen(true);
                            handleChange("label", newValue[newValue.length-1]); // fill popup values
                        }else{
                            parentHandleChange("mcc", newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        return filter(options, params);
                    }}
                    options={options}
                    getOptionLabel={(option) => `${option.value} ${option.label}`}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(option) => `${option.value} ${option.label}`}
                    freeSolo
                    renderInput={(params) => {
                        return <InputField {...params} />
                    }}
                    renderTags={(value, getTagProps) => {

                        return value.map((option, index) => (
                            <Chip
                                label={`${option.value} ${option.label}`}
                                size="small"
                                {...getTagProps({ index })}
                            />
                        ))
                    }}
                />
            )}

            <MccDialog
                open={open}
                errors={errors}
                setOpen={setOpen}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                values={values}
                fillFormValues={fillFormValues}
            />

        </>

    );
};

export default MccField;

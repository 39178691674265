import * as actionTypes from '../../../../../../store/actionTypes'

const initialState = {
	account:{
		name:"",
		isLoading:false
	}

}

const userAccount = (state=initialState, action) => {
	const {type, payload} = action;

	switch (type) {
		case actionTypes.ACCOUNT_REQUEST:
			return {
				...state,
				account:{
					...state.account,
					isLoading:true
				}
			}
		case actionTypes.ACCOUNT_SUCCESS:
			return {
				...state,
				account:{
					...payload,
					isLoading:false
				}
			}
		case actionTypes.ACCOUNT_FAILURE:
			return {
				...initialState,
				account:{
					...initialState,
					isLoading:false
				}
			}
		case actionTypes.USER_LOGOUT:
			return initialState;
	}
	return state

}

export default userAccount

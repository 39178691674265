import React, {useEffect} from 'react';
import queryString from "query-string";
import useForm2 from "../../../../hooks/useForm2";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import UserSelectField from "./UserSelectField";
import {Can} from "../../../../Context/Can";
import FilterFormButton from "../FilterFormButton";
import FilterFormControls from "../FilterFormControls";
import {makeStyles, FormControl, Box} from "@material-ui/core";
import { isDarkModeActive } from '../../../App/App';

const useStyle = makeStyles((theme) => ({
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        },
        "& .MuiFormControl-root": {
            "& .MuiFormControl-root": {
                marginBottom: '0'
            }
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },

    itemFormSubmit: {
        marginTop: '48px',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: "100%",
        gap: '12px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
}));

const CardsFilter = (props) => {

    const {location, history, settingsDarkMode} = props;
    const {search} = location;
    const query = queryString.parse(search);
    const localFields = ['user'];

    const {t} = useTranslation();
    const classes = useStyle({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const onClearHandler = () => {
        fillFormValues({}); // reset form completely
        const resetQuery = {};

        history.push({
            search: `?${queryString.stringify(resetQuery)}`,
        });

    }


    useEffect(() => {
        // fill data from url

        const dataToFill = localFields.reduce((obj, key) => {
            if(query[key]){
                obj[key] = query[key]
            }
            return obj;
        },{});

        fillFormValues(dataToFill);

        return () => {
            fillFormValues({});
        }
    },[search]);

    const validate = () => ({});

    const submit = () => {
        const oldQuery = queryString.parse(location.search);
        const newQuery = {...values};

        if(oldQuery["searchValue"]){
            newQuery.searchValue = oldQuery["searchValue"];
        }

        history.push({
            search: `?${queryString.stringify(newQuery)}`,
        });

    }



    const { handleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );

    return (
        <Box component={'form'} className={classes.itemFOrm} onSubmit={handleSubmit}>
            <FilterFormControls className={classes.itemFormFieldSet}>
                <Box className={classes.itemSettings}>
                    <Can I="read" a="user">
                        <FormControl
                            fullWidth
                            className={classes.itemField}
                        >
                            <UserSelectField
                                label={t("formFields.user")}
                                name={"user"}
                                value={values["user"]}
                                onSelectChange={(val) => handleChange("user", val)}
                                settingsDarkMode={settingsDarkMode}
                            />
                        </FormControl>
                    </Can>
                </Box>
            </FilterFormControls>
            <Box className={classes.itemFormSubmit}>
                <FilterFormButton
                    addtionalClass={`btn-stroke`}
                    onClick={() => onClearHandler()}
                >
                    {t("formFields.reset")}
                </FilterFormButton>

                <FilterFormButton
                    type="submit"
                >
                    {t("formFields.submit")}
                </FilterFormButton>
            </Box>
        </Box>
    );
};

export default withRouter(CardsFilter);

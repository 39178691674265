import React from 'react';
import FormatDate from "../../FormatDate";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import VolveMaterialTable from "../../VolveMaterialTable";
import CellTransactionAmount from "../../CellTransactionAmount";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    transactionsTable: {
        
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}))

const ExpenseTransactions = (props) => {
    const {transactions} = props;
    const {t} = useTranslation();
    const classes = useStyle();

    const dateFormat = useSelector(state => {
        const {settings={}} = state.user;
        const {regional={}} = settings;
        return regional.dateFormat
    });
    const columns = [
        {
            title: t('thead.date'),
            render: (row) => ( <FormatDate dateFormat={dateFormat}>{row.date}</FormatDate>)
        },
        {
            title: t('thead.transactionType'),
            field: 'type',
        },
        {
            title: t('thead.statusCode'),
            field: 'statusCode',
        },
        {
            title: t('thead.transactionAmount'),
            align:'right',
            render: (row) => <CellTransactionAmount {...row}/>,
        },
    ];

    const hasTransactions = !!(transactions && transactions.length);

    return (
        <div className={classes.transactionsTable}>
            {hasTransactions && (
                <VolveMaterialTable
                    columns={columns}
                    data={transactions}
                    options={{ minBodyHeight: 'auto'}}
                />
            )}
        </div>
    );
};

export default ExpenseTransactions;

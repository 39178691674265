import React from 'react';
import FundingBankAccountsTable from "./FundingBankAccountsTable";

const FundingBankAccounts = (props) => {
    const {underBarRef} = props;


    return (
        <>
            <FundingBankAccountsTable underBarRef={underBarRef}/>
        </>
    );
};


export default FundingBankAccounts;

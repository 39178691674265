import Api from '../../services/api';
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
    FormControl,
    makeStyles,
    InputAdornment,
    useTheme,
    useMediaQuery,
    Typography
} from "@material-ui/core";
import {connect} from "react-redux";

import useForm from "../../hooks/useForm";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import getEmailRegex from "../../helpers/getEmailRegex";
import { isDarkModeActive } from '../App/App';
import { ReactComponent as Logo} from '../../assets/logoVolve.svg'
// import { ReactComponent as LogoPaladis } from "../../assets/paladis.svg"
import { ReactComponent as MailSVG } from "../../assets/mail.svg"
import { getExternalFileUrl } from "../../helpers/file"

const service = new Api();

const useStyles = makeStyles((theme) => ({
    entry: {
        flexFlow: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100dvw',
        height: '100dvh',
        display: 'flex'
    },
    entryWrapper: {
        borderRadius: '8px',
        flexFlow: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        width: '700px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('1023')]: {
            marginLeft: '24px',
            marginRight: '24px'
        },
        [theme.breakpoints.down('767')]: {
            width: 'initial',
        }
    },
    entryColFirst: {
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--black)' : 'white',
        flex: '0 0 300px',
        width: '100%',
        maxWidth: '300px',
        padding: '40px',
    },
    entryColSecond: {
        flex: '0 0 400px',
        width: '100%',
        maxWidth: '400px',
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-secondary)' : 'white',
        padding: '48px',
        [theme.breakpoints.down('1023')]: {
            flex: '1',
            width: '100%',
            maxWidth: '375px',
            padding: '80px 32px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '68px 24px'
        }
    },
    entryIcon: {
        display: 'inline-flex',
        maxWidth: '168px',
        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entryLogo: {
        display: 'inline-flex',
        maxHeight: '68px',
        marginBottom: '40px',

        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entryTitle: {
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            textAlign: 'center !important',
        }
    },
    entryText: {
        marginBottom: '20px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
        display: 'block',
        [theme.breakpoints.down('767')]: {
            textAlign: 'center !important',
        }
    },
    entrySubtitle: {
        marginBottom: '20px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
    },
    entryFromControl: {
        marginBottom: '12px',
    },
    entryFormControlPassword: {
        marginBottom: '24px',

        "& .MuiInputAdornment-root.end_block a": {
            position: 'absolute',
            bottom: '-24px',
            left: '0',
            fontFamily: `'Inter', sans-serif`,
            fontSize: '13px',
            lineHeight: '1.23077',
            fontWeight: '600',
            height: 'initial'
        },
    },
    entryFormLoginCode: {
        marginBottom: '24px'
    },
    entryNote: {
        color: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'black',
        fontSize: '13px',
        lineHeight: '1.23077',
        marginTop: '32px',
        [theme.breakpoints.down('767')]: {
            marginTop: '24px',
            textAlign: 'center !important'
        },
        '& a': {
            fontWeight: 700,
            transition: 'color .2s',
            color: (props) => props.isDarkModeActive ? 'var(--white)' : 'black',

            '&:hover, &:focus': {
                color: (props) => props.isDarkModeActive ? 'var(--clear-blue-primary)' : 'black'
            }
        }
    },
}));

const ForgotPasswordForm = (props) => {
    const { publicSettings, settings } = props;
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [successMessage, setSuccessMessage] = useState();
    const validate = (values) => {
        let errors = {};
        const emailRegex = getEmailRegex();
        if (!values.email) {
            errors.email = t('errors.required', {field: t("formFields.email")});
        }
        if (!emailRegex.test(values.email)) {
            errors.email = t('errors.notValid', {field: t("formFields.email")});
        }

        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate
    );

    async function submit(){
        setIsLoading(true);
        try {
            const response = await service.forgotPassword(values.email);
            const {msg} = response.data;
            setSuccessMessage(t(msg, {email:values.email}));
            setIsLoading(false);

        }catch (e) {
            setIsLoading(false);
            setServerErrors(e);
        }
    }

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    const theme = useTheme();
    const onMax767 = useMediaQuery(theme.breakpoints.down(767))

    return (
        <div className={classes.entry}>
            <div className={classes.entryWrapper}>
                {!onMax767 && (
                    <div className={`${classes.entryColFirst}`}>
                        <Link to={"/"} className={classes.entryLogo}>
                            {
                                publicSettings && publicSettings.logo_path &&
                                    <img
                                        className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                                        src={getExternalFileUrl(publicSettings.kycHost, publicSettings.logo_path)}
                                        alt="logo"
                                    />
                            }
                            { (!publicSettings || !publicSettings.logo_path) &&
                                <Logo />
                            }
                        </Link>
                    </div>
                )}

                <div className={`${classes.entryColSecond}`}>
                    {onMax767 && (
                        <div className={`text-align-center`}>
                            <Link to={"/"} className={classes.entryLogo}>
                                {
                                    publicSettings && publicSettings.logo_path &&
                                        <img
                                            className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                                            src={getExternalFileUrl(publicSettings.kycHost, publicSettings.logo_path)}
                                            alt="logo"
                                        />
                                }
                                { (!publicSettings || !publicSettings.logo_path) &&
                                    <Logo />
                                }
                            </Link>
                        </div>
                    )}

                    <Typography variant="h1" className={`h3 ${classes.entryTitle}`}>
                        {t("forgotPassword")}
                    </Typography>
                    <Typography variant="div" className={`${classes.entryText}`}>
                        {/* NEED TRANSLATION */}
                        {t('Insert your email for recover')}
                    </Typography>

                    <form onSubmit={handleSubmit} noValidate>

                        {successMessage ? (
                            <div className={classes.entrySubtitle}>
                                {successMessage}
                            </div>
                        ) : (
                            <>
                                <FormControl fullWidth error={!!errors.email} className={classes.entryFromControl}>
                                    <InputField
                                        name="email"
                                        type="email"
                                        placeholder={t("formFields.YourEmail")}
                                        error={errors["email"]}
                                        value={values["email"]}
                                        onInputChange={(val) => handleChange("email", val)}
                                        hasIcon={true}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><MailSVG /></InputAdornment>
                                        }}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    className='btn-fullwidth'
                                >
                                    {t("formFields.submit")}
                                </Button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>

    )
};

const mapStateToProps = ({publicSettings}) => {
    return { publicSettings }
}

export default connect(
    mapStateToProps,
    {}
)(ForgotPasswordForm);

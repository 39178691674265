import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import SelectField from "../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import canUseCardFunctionality from "../../../../helpers/canUseCardFunctionality";
import FilterFormButton from "../FilterFormButton";
import Api from "../../../../services/api";
import {handleErrorMessage} from "../../../Messages/actions";
import useActiveTab from "../../../../hooks/useActiveTab";
import FilterFormControls from "../FilterFormControls";
import getCardName from "../../../../helpers/getCardName";
// import * as loader from "../../../../assets/animations/loader";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const service = new Api();

const useStyle = makeStyles((theme) => ({
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'var(--charcoal-primary)'
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        "& .MuiFormControl-root.MuiFormControl-fullWidth": {
            marginBottom: '0'
        },
        // Small size
        "& .MuiSelect-select": {
            height: '40px !important',
            padding: '0 16px',
            fontSize: '13px',
            borderRadius: '8px !important',
            lineHeight: '40px',
        },

        '& .MuiSelect-icon': {
            width: '12px',
            height: '12px'
        }
    },
    btnWrapper: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
        margin: '0 8px'
    }
}));


const DashboardFilter = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();
    const {handleChange, fillFormValues, handleSubmit, values} = props;

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const companyCountry = useSelector(state => {
        const {user} = state;
        if(user && user.userData && user.userData.company && user.userData.company.country){
            return user.userData.company.country;
        }
        return null
    });

    const activeTab = useActiveTab('dashboard');

    const canUseCards = canUseCardFunctionality(companyCountry);

    const onClearHandler = (e) => {
        const cleanedData = {
            'date[gte]':values['date[gte]'] // do not delete date
        };
        fillFormValues(cleanedData); // reset form completely
        handleSubmit(e);
    }


    const fetchOptions = async (activeTab) => {
        try{
            setIsLoading(true);
            const options = [];

            const paidWithResult = await service.getServiceData({dataFor:"paidWith"});
            const paidWithOptions = paidWithResult.data.map(({value, name}) => ({value:value, label:name}));
            options.push(...paidWithOptions);
            if(canUseCards){
                const cardsResult = await service.getCardsList(activeTab);
                const cardOptions = cardsResult.data.cards.map((card) => ({value:card.id, label: getCardName(card)}));
                options.push(...cardOptions)
            }

            setPaymentMethods(options);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(activeTab){
            fetchOptions(activeTab);
        }
        // handleChange('paidWith',); // reset because it could not be not available on another tab.
    },[activeTab]);


    return (
        <>
            <Box component={'form'} onSubmit={handleSubmit} className={classes.itemSettings}>
                <FilterFormControls>
                    {canUseCards && (
                        <FormControl
                            fullWidth
                            className={classes.itemFieldFull}
                        >
                            <SelectField
                                isLoading={isLoading}
                                label={t("formFields.paymentMethod")}
                                name={"status"}
                                emptyLabel={t("formFields.allLabel")}
                                value={values["paidWith"]}
                                options={paymentMethods}
                                onSelectChange={(val) => handleChange("paidWith", val)}
                            />
                        </FormControl>
                    )}
                </FilterFormControls>

                <Box className={classes.btnWrapper}>
                    <FilterFormButton
                        addtionalClass={`btn-small`}
                        type="submit"
                    >
                        {t("formFields.submit")}
                    </FilterFormButton>
                    <FilterFormButton
                        addtionalClass={`btn-small`}
                        onClick={onClearHandler}
                    >
                        {t("formFields.reset")}
                    </FilterFormButton>
                </Box>
            </Box>
        </>
    );
};

export default DashboardFilter;

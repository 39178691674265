import React, { useEffect, useState } from 'react';
import {Can} from "../../Context/Can";
import InputField from "../formElements/InputField";
import SelectField from "../formElements/SelectField";
import MccField from "../formElements/MccField";
// import CheckBoxField from "../formElements/CheckBoxField";
import {useTranslation} from "react-i18next";
import {makeStyles, FormControl, FormHelperText} from "@material-ui/core";
import ToggleFieldWrapper from '../formElements/ToggleFieldWrapper/ToggleFieldWrapper';
import ToggleCheckboxField from '../formElements/ToggleCheckboxField/ToggleCheckboxField';
import Api from "../../services/api";

const service = new Api();

const useStyle = makeStyles((theme) => ({
    itemFields: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0',
        },
        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
    },
    itemFieldCheckbox: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    }
}))

const CategoryForm = (props) => {
    const {handleSubmit, handleChange, values, errors, branches, loading, isBatchEdit, settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyle();
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        const getAppData = async () => {
            const { data } = await service.getCompanyXeroAccounts();
            console.log(data);
            if (data?.xeroAccounts?.Accounts) {
                const mappedAccounts = data?.xeroAccounts?.Accounts.map(({Code, Name, AccountID}) => ({value: Code || AccountID, label: Name + ' - ' + Code || AccountID}));
                setAccounts(mappedAccounts);
            } else {
                setAccounts([]);
            }
        };
        getAppData();
    }, []);

console.log(errors)
    return (
        <form onSubmit={handleSubmit} noValidate>
            <Can I="update" a={"category"} passThrough>
                {(can) => (
                    <div className={classes.itemFields}>
                        {!isBatchEdit && (
                            <FormControl fullWidth error={!!errors["name"]} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.name")}
                                    placeholder={t("formFields.name")}
                                    name="name"
                                    disabled={!can}
                                    value={values["name"]}
                                    isLoading={loading}
                                    onInputChange={(val) => handleChange("name", val)}
                                    error={errors["name"]}
                                />
                            </FormControl>
                        )}

                        <FormControl fullWidth error={!!errors["branch"]} className={classes.itemField}>
                            <SelectField
                                label={t("formFields.branch")}
                                name={"branch"}
                                value={values["branch"]}
                                options={branches}
                                disabled={!can}
                                onSelectChange={(val) => handleChange("branch", val)}
                                error={errors["branch"]}
                                isLoading={loading}
                                hideEmpty={!isBatchEdit} // do not show All (and hideEmpty=true) option for batch, because it means empty, that's why it will be not updated on backend
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["generalLedgerAccount"]} className={classes.itemField}>
                            <SelectField
                                label={t("formFields.generalLedgerAccount")}
                                placeholder={t("formFields.generalLedgerAccount")}
                                name="generalLedgerAccount"
                                options={accounts}
                                disabled={!can}
                                value={values["generalLedgerAccount"]}
                                isLoading={loading}
                                onSelectChange={(val) => handleChange("generalLedgerAccount", val)}
                                error={errors["generalLedgerAccount"]}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemField}>
                            <MccField parentHandleChange={handleChange} parentValue={values["mcc"]} loading={loading} disabled={!can}/>
                            <FormHelperText>{t("newMccHelpText")}</FormHelperText>
                        </FormControl>

                        {!isBatchEdit && (
                            <div className={`${classes.itemField} ${classes.itemFieldFull}`}>
                                <ToggleFieldWrapper settings={settingsDarkMode}>
                                    <ToggleCheckboxField
                                        additionalClass="toggle-item"
                                        errorField={!!errors["active"]}
                                        onCheckboxChange={(val) => handleChange("active", !!val)}
                                        value={values["active"]}
                                        label={t("formFields.active")}
                                        name={"active"}
                                        error={errors["active"]}
                                        disabled={!can || loading}
                                        settings={settingsDarkMode}
                                    />
                                </ToggleFieldWrapper>
                            </div>
                        )}
                    </div>
                )}
            </Can>
        </form>
    );
};

export default CategoryForm;

import React, {
    // eslint-disable-next-line no-unused-vars
    useEffect,
    // eslint-disable-next-line no-unused-vars
    useState
} from 'react';
import {FormControl, makeStyles, Box, FormLabel, Button} from "@material-ui/core";
import SelectField from "../../../../../../formElements/SelectField";
import InputField from "../../../../../../formElements/InputField";
import DateField from "../../../../../../formElements/DateField";
import {useTranslation} from "react-i18next";
import NumericInput from "../../../../../../formElements/NumericInput";
import { isDarkModeActive } from '../../../../../../App/App';

const useStyles = makeStyles(theme => ({
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},
    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
}));


const ExpenseRow = (props) => {
	const {handleChange, values, index, errors, currencies=[], categories=[], onDeleteRow, dateFormat, decimal, settingsDarkMode} = props;
	const {t} = useTranslation();

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

	return (
		<>
            <Box className={`${classes.itemFormFieldSet} item-form-fieldset`}>
                <Box className={classes.itemSettings}>
                    <FormControl fullWidth error={!!errors[`expenses[${index}][date]`]} className={classes.itemField}>
                        {/* NEED TRANSLATION */}
                        <DateField
                            label={t("Date")}
                            dateFormat={dateFormat}
                            disableFuture
                            onDateChange={(date) => handleChange(`expenses[${index}][date]`, date)}
                            value={values[`expenses[${index}][date]`]}
                            error={errors[`expenses[${index}][date]`]}
                            name={`expenses[${index}][date]`}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </FormControl>

                    <Box className={classes.itemRow}>
                        <FormControl fullWidth error={!!errors[`expenses[${index}][amount]`]} className={classes.itemField}>
                            {/* NEED TRANSLATION */}
                            <FormLabel component="label">{t("Amount")}</FormLabel>
                            <NumericInput
                                name={`expenses[${index}][amount]`}
                                value={values[`expenses[${index}][amount]`]}
                                error={errors[`expenses[${index}][amount]`]}
                                onInputChange={(name, value) => handleChange(`expenses[${index}][amount]`, value)}
                                decimal={decimal}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors[`expenses[${index}][currency]`]} className={classes.itemField}>
                            {/* NEED TRANSLATION */}
                            <SelectField
                                label={t("Currency")}
                                name={`expenses[${index}][currency]`}
                                value={values[`expenses[${index}][currency]`]}
                                error={errors[`expenses[${index}][currency]`]}
                                options={currencies}
                                onSelectChange={(val) => handleChange(`expenses[${index}][currency]`, val)}
                                settings={settingsDarkMode}
                            />
                        </FormControl>
                    </Box>

                    <FormControl fullWidth error={!!errors[`expenses[${index}][merchant]`]} className={classes.itemField}>
                        {/* NEED TRANSLATION */}
                        <InputField
                            label={t('Merchant')}
                            name={`expenses[${index}][merchant]`}
                            error={errors[`expenses[${index}][merchant]`]}
                            value={values[`expenses[${index}][merchant]`]}
                            onInputChange={(val) => handleChange(`expenses[${index}][merchant]`, val)}
                        />
                    </FormControl>

                    <FormControl fullWidth error={!!errors[`expenses[${index}][category]`]} className={classes.itemField}>
                        {/* NEED TRANSLATION */}
                        <SelectField
                            label={t('Category')}
                            settings={settingsDarkMode}
                            name={`expenses[${index}][category]`}
                            value={values[`expenses[${index}][category]`]}
                            error={errors[`expenses[${index}][category]`]}
                            options={categories}
                            onSelectChange={(val) => handleChange(`expenses[${index}][category]`, val)}
                        />
                    </FormControl>

                    <FormControl fullWidth error={!!errors[`expenses[${index}][comment]`]} className={classes.itemField}>
                        <InputField
                            multiline
                            rows="1"
                            name={`expenses[${index}][comment]`}
                            value={values[`expenses[${index}][comment]`]}
                            onInputChange={(val) => handleChange(`expenses[${index}][comment]`, val)}
                        />
                    </FormControl>

                    <Button
                        className={`btn-stroke`}
                        onClick={() => onDeleteRow(index)}
                    >
                        {/* NEED TRANSLATION */}
                        {t('Delete this line')}
                    </Button>
                </Box>
            </Box>
		</>
	);
};

export default ExpenseRow;

import React from 'react';
import usePagination from "@material-ui/lab/Pagination/usePagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as Next} from "../../assets/paginationNext.svg"
import {ReactComponent as Prev} from "../../assets/paginationPrev.svg"
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(theme => ({
    pagination: {
        marginTop: '32px',
        textAlign: 'center',
    },
    counter: {
        color: "#8A96A0",
        fontSize: "15px"
    },
    buttonIcon: {
        padding: "9px",
        "& svg": {
            transform: "scale(1.1)"
        },
        "&[disabled] svg path": {
            stroke: "#8A96A0"
        }
    },
}));




const Pagination = (props) => {
    const {total=0, pageSize=0, page=0, isLoading, onChangePage, loadMore} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const countPages = Math.ceil(total/pageSize);

    const {items} = usePagination({
        count: countPages,
        onChange:onChangePage,
        page
    });


    const prev = items[0];
    const next = items[items.length-1];


    let from = 0;
    let to = 0;

    if(total > 0){
       if(countPages === page){
            // last page
            from = pageSize*(page-1)+1;
            to = total ;
        }else if(page === 1){
           // first page
           from = 1;
           to = pageSize;
       }else{
            // between pages
            from = (page-1)*pageSize+1;
            to = pageSize*page;
        }
    }

    const additionalProps = {};
    if(isLoading){
        additionalProps.disabled = true
    }

    return (
        <>
            {total > 0 && (
                <div className={classes.pagination}>
                    {loadMore ? (
                        <>
                            {!next.disabled && (
                                <Button
                                    {...next}
                                    {...additionalProps}
                                    className="btn-stroke btn-small"
                                >
                                    {t("formFields.loadMore")}
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <IconButton {...prev} {...additionalProps} className={classes.buttonIcon}>
                                <Prev/>
                            </IconButton>
                            <Box className={classes.counter}>{t("pagination",{from, to, total})}</Box>
                            <IconButton {...next} {...additionalProps} className={classes.buttonIcon}>
                                <Next />
                            </IconButton>
                        </>
                    )}
                </div>
            )}

        </>
    )

};

export default Pagination;

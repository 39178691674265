import Api from '../../../../services/api'
import * as actionTypes from '../../../../store/actionTypes'
import {handleErrorMessage} from "../../../Messages/actions";

const fetchBranches = (dataQuery) => async dispatch => {

	const {getBranches} = new Api();

	try {
		const response = await getBranches(dataQuery);
		const {data} = response;
		const {result, info} = data;
		dispatch({type:actionTypes.BRANCHES_FETCH_SUCCESS, payload:{result, info}});
	}catch (e) {
		dispatch(handleErrorMessage(e));
		dispatch({type:actionTypes.BRANCHES_FETCH_FAILURE})
	}

};


export {
    fetchBranches,
}

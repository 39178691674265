import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import FormControl from "@material-ui/core/FormControl";
import SelectField from "../../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
import DialogFields from "./DialogFields";
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../../../Messages/actions";
const service = new Api();


const CustomReportType = (props) => {
    const {handleChange, fields, error} = props;

    const [templates, setTemplates] = useState([]);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(""); // id
    const [isFieldsOpen,  setIsFieldsOpen] = useState(false);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetchOptions = async () => {
        try{
            setIsDataFetching(true);
            const response = await service.getExpenseReportTemplateAll();

            const mappedTemplates = response.data.map((option) => ({...option, value:option._id, label:option.name}));

            setTemplates(mappedTemplates);

        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsDataFetching(false);
        }
    }

    useEffect(() => {
        fetchOptions()
    },[])

    const onChangeTemplate = (value) => {
        setIsFieldsOpen(true);
        if(value){
            const foundTemplate = templates.find((template) => template.value === value);
            if(foundTemplate){
                const {fields} = foundTemplate;
                handleChange("fields", fields)
            }else{
                handleChange("fields")
            }
        }else{
            handleChange("fields")
        }
        setSelectedTemplate(value);
    }



    return (
        <div>
            <FormControl fullWidth error={!!error}>
                <SelectField
                    label={t("formFields.reportTemplate")}
                    name={"reportTemplate"}
                    error={error}
                    value={selectedTemplate}
                    options={templates}
                    onSelectChange={(val) => onChangeTemplate(val)}
                    isLoading={isDataFetching}
                />
            </FormControl>
            <DialogFields
                value={fields}
                handleChange={handleChange}
                setOpen={setIsFieldsOpen}
                open={isFieldsOpen}
            />
        </div>
    );
};

export default CustomReportType;

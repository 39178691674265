import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {ReactComponent as Warning} from "../../../../../../assets/warning-sign.svg";
import {useTranslation} from "react-i18next";
import isCardDeleted from "../../../../../../helpers/isCardDeleted";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import getCardName from "../../../../../../helpers/getCardName";

const useStyles = makeStyles((theme) => (
    {
      name:{
          position:'relative',
          "&::after": {
              display: "block",
              left: 0,
              right: 0,
              content: "''",
              background: "#8A96A0",
              height: "1px",
              top:'50%',
              position: "absolute"
          },
      }
    }
));

const ColumnCard = (props) => {
    const {isLive} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const cardIsDeleted = isCardDeleted(props);
    const cardName = getCardName(props);

    return (
        <>
            <Box className={cardIsDeleted ? classes.name : undefined} component={'span'}>{cardName}</Box>
            {isLive ? "" : <Tooltip placement={"top"} title={t("card.notLive")}><Warning/></Tooltip>}
        </>
    );
};

export default ColumnCard;

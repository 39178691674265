import React from 'react'

import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import Contacts from "../userSection/content/Contacts";


const ContactsPage = (props) => {
    const {drawerOuterRef, underBarRef, settingsDarkMode} = props;

    const {t} = useTranslation();
    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <Can I="read" a={"account"} passThrough>
                        {(can) => {
                            console.log('can', can)
                            return can ? <Contacts drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} /> : <div>{t("card.noPermissionToRead")}</div>
                        }}
                    </Can>
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    )

}

export default ContactsPage

import React from 'react';
import {useTranslation} from "react-i18next";
import TabsContent from "../../../../../TabsContent";

const CardTabs = (props) => {
    const {t} = useTranslation();
    const {url} = props;

    const linksTab = [
        {url:`${url}`,name:t("card.transactionsTab")},
        {url:`${url}/settings`,name:t("card.settingsTab")}
    ];
    return (
        <TabsContent routes={linksTab}/>
    )
};

export default CardTabs;

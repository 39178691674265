import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import isNumeric from "../../../../../helpers/isNumeric";
import Api from "../../../../../services/api";
import useForm2 from "../../../../../hooks/useForm2";
import TaxRateForm from "../../../../TaxRateForm";
import BackButton from "../../../../BackButton";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import {setMessage} from "../../../../Messages/actions";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',

        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    item: {
        position: 'relative'
    },
}))

const AddTaxRate = (props) => {
    const classes = useStyle();
    const {underBarRef, history, countries, categories} = props;
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const isMounted = useMounted();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const decimal = useSelector(state => {
        const {settings={}} = state.user;
        const {regional={}} = settings;
        return regional.decimal
    });


    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }
        if (!isNumeric(values.taxRate)) {
            errors.taxRate = t("errors.notValid", {field:"$t(formFields.taxRate)"});
        }
        if (!values.countries) {
            errors.countries = t("errors.required", {field:"$t(formFields.countries)"});
        }

        return errors;
    };


    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.createTaxRate(values);
            setIsSubmitting(false);
            dispatch(setMessage("success", 'success'));
            history.push("/settings/tax-rates");
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    useEffect(() => {
        fillFormValues({active:true}); // active by default if create new
    },[]);

    return (
            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <TaxRateForm
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        values={values}
                        errors={errors}
                        countries={countries}
                        categories={categories}
                        decimal={decimal}
                    />

                    <Can I="create" a={"taxRate"}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {t("formFields.create")}
                        </Button>
                    </Can>
                </div>
            </div>

    );
};

export default AddTaxRate;

import { useState, useEffect } from "react";
import {handleErrorMessage} from "../components/Messages/actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const useForm = (callback, validate) => {
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const [values, setValues] = useState({});
	const [errors, setErrors] = useState({});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const cleanForm = () => {
		setValues({});
		setErrors({});
	};

	const handleChange = (name, value) => {
		setValues(values => {
				const oldValues = {...values};

				if (value !== undefined) {
					return {
						...oldValues,
						[name]: value
					}
				}else{
					// if no value - delete property
					delete oldValues[name];
					return {
						...oldValues,
					}
				}
			}
		);
		setErrors(errors => {
			const oldErrors = {...errors};

				if(isSubmitting){
					setIsSubmitting(false);
				}

				if(value){
					return {
						...oldErrors,
						[name]: null
					}
				}else{
					delete oldErrors[name];
					return {
						...oldErrors,
					}
				}
			}
		);
	};

	const handleSubmit = event => {
		event.preventDefault();

		setErrors(validate(values));
		setIsSubmitting(true);
	};
	
	const setServerErrors = (e) => {
		if(e.response && e.response.data && e.response.data.errors){
			const serverErrors = e.response.data.errors;
			const serverErrors2 = Object.keys(serverErrors).reduce((errors, field) => {
				errors[field] = t(serverErrors[field], { field: t(`formFields.${field}`)});
				return errors
			},{});

			setErrors(errors => (
				{
					...errors,
					...serverErrors2
				}
			));
		}else {
			dispatch(handleErrorMessage(e));
		}
	};

	useEffect(() => {
		if (Object.keys(errors).length === 0 && isSubmitting) {
			callback();
		}
	}, [errors]);

	return {
		handleChange,
		handleSubmit,
		setServerErrors,
		cleanForm,
		values,
		errors
	};
};

export default useForm;
import React, {useEffect, useState} from 'react';
import ToggleAction from "./ToggleAction";
import Api from "../../../../../../../services/api";
import {withRouter} from "react-router-dom";
import getCardIsDisabled from "../../../../../../../helpers/getCardIsDisabled";
import {useTranslation} from "react-i18next";
import {subject} from "@casl/ability";
import Grid from "@material-ui/core/Grid";

const service = new Api();

const ToggleActions = (props) => {
    const {match, handleErrorMessage, setMessage, card, ability, setCard} = props;
    const {id} = match.params;
    const [isDisabled, setIsDisabled] = useState(false);
    const {t} = useTranslation();

    const onToggleAction = async (name, val) => {
        setIsDisabled(true);
        try {
            const dataToSubmit = {
                disableContactlessPayments: card.disableContactlessPayments,
                disableAtmWithdrawals: card.disableAtmWithdrawals,
                disableECommercePayments: card.disableECommercePayments,
                [name]:val
            };
            const response = await service.disableCard(id, dataToSubmit);

            setCard((oldCard) => ({
                ...oldCard,
                ...response.data,
            }));

            setIsDisabled(false);
            setMessage("success", 'success');
        } catch (e) {
            handleErrorMessage(e);
            setIsDisabled(false);
        }
    };

    useEffect(() => {
        if(card){
            const isCardDisabled = getCardIsDisabled(card.currentStatCode);
            setIsDisabled(isCardDisabled);
        }
    },[card]);

    const canChangeDisabled = () => {
        if(card.user && card.company){
            return ability.can("disable", subject("card", {user:card.user.id, company:card.company.id, type:card.type, currentStatCode: card.currentStatCode}));
        }
        return false
    }

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <ToggleAction
                    label={t("formFields.disableContactlessPayments")}
                    name={"disableContactlessPayments"}
                    helpText={t("card.disableContactlessPaymentsText")}
                    onChangeHandler={(name, value) => onToggleAction(name, value)}
                    isDisabled={!canChangeDisabled() || isDisabled}
                    value={!!card.disableContactlessPayments}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <ToggleAction
                    label={t("formFields.disableAtmWithdrawals")}
                    name={"disableAtmWithdrawals"}
                    helpText={t("card.disableAtmWithdrawalsText")}
                    onChangeHandler={(name, value) => onToggleAction(name, value)}
                    isDisabled={!canChangeDisabled() || isDisabled}
                    value={!!card.disableAtmWithdrawals}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <ToggleAction
                    label={t("formFields.disableECommercePayments")}
                    name={"disableECommercePayments"}
                    helpText={t("card.disableECommercePaymentsText")}
                    onChangeHandler={(name, value) => onToggleAction(name, value)}
                    isDisabled={!canChangeDisabled() || isDisabled}
                    value={!!card.disableECommercePayments}
                />
            </Grid>
        </>
    );
};

export default withRouter(ToggleActions);

import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export default React.forwardRef((props, ref) => {

    const {children, Icon, isTitle, ...restProps} = props;

    const useStyles = makeStyles(theme => ({
                text: {
                    '& span': {
                        fontSize: isTitle ? "17px" : "14px",
                        fontWeight: 300,
                        letterSpacing: "-0.34px",
                        color: "#343640"
                    }
                },
                menuItem: {
                    minHeight: "30px",
                    padding: "5px 35px"
                },
                iconWrapper: {
                    minWidth: "24px"
                },
            }
        )
    );

    const classes = useStyles();

    return (
        <MenuItem component="li" className={classes.menuItem} {...restProps}>
            {Icon && <ListItemIcon className={classes.iconWrapper}><Icon/></ListItemIcon>}
            <ListItemText className={classes.text} >{children}</ListItemText>
        </MenuItem>
    );
})

import React from 'react'
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import TaxRates from "../userSection/content/TaxRates";
import {Can} from "../../Context/Can";


const TaxRatesPage = (props) => {
    const {t} = useTranslation();
    return (

        <UserSectionWrapper>
            <CompanyCountryCheck>
                <Can I="read" a={"taxRate"} passThrough>
                    {(can) => (
                        can ? <TaxRates {...props}/> : <div>{t("taxRate.noPermissionToRead")}</div>
                    )}
                </Can>
            </CompanyCountryCheck>
        </UserSectionWrapper>

    )

}



export default TaxRatesPage

import React, {useEffect, useRef, useState} from 'react';
import {Can} from "../../Context/Can";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import SelectField from "../formElements/SelectField";
import {makeStyles, Box, FormControl, useTheme, useMediaQuery, Button} from "@material-ui/core";
import { isDarkModeActive } from '../App/App';

const useStyle = makeStyles((theme) => ({
    item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start'
    },
    itemMenu: {
        position: 'sticky',
        top: '116px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '280px',
    },
    itemMenuLink: {
        backgroundColor: 'transparent !important',
        padding: '8px 16px',
        color: 'var(--battelship-grey)',
        justifyContent: 'flex-start',
        fontWeight: '600',
        height: 'initial',
        lineHeight: '1.6',
        letterSpacing: '0',
        "&:not(:last-child)": {
            marginBottom: '8px'
        },
        "& .MuiTouchRipple-root": {
            display: 'none !important'
        },
        "&.active": {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey) ' : 'var(--dark-secondary)',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary) !important' : 'var(--very-light-pink-primary) !important',
        }
    },
    itemContent: {
        flexGrow: '1',
        paddingLeft: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0'
        }
    },
    itemContentMenu: {
        display: 'block',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        '& .MuiInput-root': {
            width: '100%'
        }
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '40px',
        letterSpacing: '-.02em',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemFormSubmit: {
        marginTop: '48px',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '12px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
    itemFormFieldSetAnchor: {
        position: 'absolute',
        top: '-116px',
        left: '0',
        right: '0',
    },
}))

const BranchForm = (props) => {
    const {handleSubmit, handleChange, values, errors, loading, currencies, countries, isEditMode, settingsDarkMode, actionButtons} = props;
    const {t} = useTranslation();
    const classes = useStyle({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    // NEED TRANSLATION
    const formSections = [
        { id: 'basic', label: t('Basic') },
        { id: 'locationDetails', label: t('Location Detail') },
        { id: 'financialInformation', label: t('Financial Information') },
        { id: 'branchInformation', label: ('Branch Information') },
    ];
    const [activeSection, setActiveSection]     = useState('basic');
    const [selectedMenu, setSelectedMenu]       = useState(formSections[0].id);

    const basicRef = useRef(null);
    const locationDetailsRef = useRef(null);
    const financialInformationRef = useRef(null);
    const branchInformationRef = useRef(null);

    const refs = {
        basic: basicRef,
        locationDetails: locationDetailsRef,
        financialInformation: financialInformationRef,
        branchInformation: branchInformationRef
    }

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(selectedSection);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (rect.top <= scrollPosition && rect.bottom > scrollPosition) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs, formSections])

    return (
        <Box className={classes.item}>
            {!onMax1023 && (
                <Box className={classes.itemMenu}>
                    {formSections.map((section) => (
                        <Button
                            key={section.id}
                            className={`${classes.itemMenuLink} ${
                                activeSection === section.id ? 'active' : ''
                            }`}
                            onClick={() => {
                                scrollToSection(section.id);
                                setActiveSection(section.id);
                                setSelectedMenu(section.id);
                            }}
                        >
                            {t(section.label)}
                        </Button>
                    ))}
                </Box>
            )}

            <Box className={classes.itemContent}>
                {onMax1023 && (
                    <Box className={classes.itemContentMenu}>
                        <SelectField
                            hideEmpty
                            options={formSections.map(section => ({ value: section.id, label: section.label }))}
                            onSelectChange={(selectedSection) => {
                                scrollToSection(selectedSection);
                                setSelectedMenu(selectedSection);
                            }}
                            value={selectedMenu}
                        />
                    </Box>
                )}

                <form onSubmit={handleSubmit} noValidate className={classes.itemForm}>
                    <Can I="update" a={"branch"} passThrough>
                        {(can) => (
                            <>
                                <Box className={classes.itemFormFieldSet}>
                                    <Box ref={basicRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                                    <Box className={`${classes.itemTitle} card-title card-title--blue`}>
                                        {/* NEED TRANSLATION */}
                                        {t('Basic Information')}
                                    </Box>

                                    <Box className={classes.itemSettings}>
                                        <Box className={classes.itemRow}>
                                            <FormControl fullWidth error={!!errors["name"]} className={classes.itemField}>
                                                <InputField
                                                    label={t("formFields.name")}
                                                    placeholder={t("formFields.name")}
                                                    name="name"
                                                    value={values["name"]}
                                                    onInputChange={(val) => handleChange("name", val)}
                                                    error={errors["name"]}
                                                    isLoading={loading}
                                                    disabled={!can}
                                                />
                                            </FormControl>

                                            <FormControl fullWidth error={!!errors["enterpriseNumber"]} className={classes.itemField}>
                                                <InputField
                                                    label={t("formFields.enterpriseNumber")}
                                                    placeholder={t("formFields.enterpriseNumber")}
                                                    name="enterpriseNumber"
                                                    value={values["enterpriseNumber"]}
                                                    onInputChange={(val) => handleChange("enterpriseNumber", val)}
                                                    error={errors["enterpriseNumber"]}
                                                    isLoading={loading}
                                                    disabled={!can}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={classes.itemFormFieldSet}>
                                    <Box ref={locationDetailsRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                                    <Box className={`${classes.itemTitle} card-title card-title--green`}>
                                        {/* NEED TRANSLATION */}
                                        {t('Location Details')}
                                    </Box>

                                    <Box className={classes.itemSettings}>
                                        <Box className={classes.itemRow}>
                                            <FormControl fullWidth error={!!errors["country"]} className={classes.itemField}>
                                                <SelectField
                                                    label={t("formFields.country")}
                                                    placeholder={t("formFields.country")}
                                                    name={"country"}
                                                    value={values["country"]}
                                                    error={errors["country"]}
                                                    options={countries}
                                                    disabled={!can}
                                                    onSelectChange={(val) => handleChange("country", val)}
                                                />
                                            </FormControl>

                                            <FormControl fullWidth error={!!errors["street"]} className={classes.itemField}>
                                                <InputField
                                                    label={t("formFields.street")}
                                                    placeholder={t("formFields.street")}
                                                    name="street"
                                                    value={values["street"]}
                                                    onInputChange={(val) => handleChange("street", val)}
                                                    error={errors["street"]}
                                                    isLoading={loading}
                                                    disabled={!can}
                                                />
                                            </FormControl>
                                        </Box>

                                        <Box className={classes.itemRow}>
                                            <FormControl fullWidth error={!!errors["postalCode"]} className={classes.itemField}>
                                                <InputField
                                                    label={t("formFields.postalCode")}
                                                    placeholder={t("formFields.postalCode")}
                                                    name="postalCode"
                                                    value={values["postalCode"]}
                                                    onInputChange={(val) => handleChange("postalCode", val)}
                                                    error={errors["postalCode"]}
                                                    isLoading={loading}
                                                    disabled={!can}
                                                />
                                            </FormControl>

                                            <FormControl fullWidth error={!!errors["city"]} className={classes.itemField}>
                                                <InputField
                                                    label={t("formFields.city")}
                                                    placeholder={t("formFields.city")}
                                                    name="city"
                                                    value={values["city"]}
                                                    onInputChange={(val) => handleChange("city", val)}
                                                    error={errors["city"]}
                                                    isLoading={loading}
                                                    disabled={!can}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={classes.itemFormFieldSet}>
                                    <Box ref={financialInformationRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                                    <Box className={`${classes.itemTitle} card-title card-title--purple`}>
                                        {/* NEED TRANSLATION */}
                                        {t('Financial Information')}
                                    </Box>

                                    <Box className={classes.itemSettings}>
                                        <FormControl fullWidth error={!!errors.currency} className={classes.itemField}>
                                            <SelectField
                                                label={t("formFields.currency")}
                                                name={"currency"}
                                                value={values["currency"]}
                                                error={errors.currency}
                                                options={currencies}
                                                disabled={!can || !!isEditMode}
                                                onSelectChange={(val) => handleChange("currency", val ? val : undefined)}
                                                isLoading={loading}
                                                settings={settingsDarkMode}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>


                                <Box className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                                    <Box ref={branchInformationRef} className={classes.itemFormFieldSetAnchor}>{' '}</Box>
                                    <Box className={`${classes.itemTitle} card-title card-title--red`}>
                                        {/* NEED TRANSLATION */}
                                        {t('Branch Information')}
                                    </Box>

                                    <Box className={classes.itemSettings}>
                                        <FormControl fullWidth error={!!errors["branchId"]} className={classes.itemField}>
                                            <InputField
                                                label={t("formFields.branchId")}
                                                placeholder={t("formFields.branchId")}
                                                name="branchId"
                                                value={values["branchId"]}
                                                onInputChange={(val) => handleChange("branchId", val)}
                                                error={errors["branchId"]}
                                                isLoading={loading}
                                                disabled={!can}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Can>

                    <Box className={classes.itemFormSubmit}>
                        {actionButtons}
                    </Box>
                </form>
            </Box>
        </Box>
    );
};

export default BranchForm;

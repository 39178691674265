import * as actionTypes from '../../../../store/actionTypes'

const initialState = {
    fundingBankAccounts: {
        result: [],
        info:{
            fields:["bankName", "accountNo", "accountName", "currency"],
        }
    }

}

const userFundingBankAccounts = (state=initialState, action) => {

    switch (action.type) {
        case actionTypes.FBAS_FETCH_SUCCESS :
            return {
                fundingBankAccounts:{
                    ...action.payload,
                    info:{
                        ...action.payload.info,
                    }
                },
            }
        case actionTypes.FBAS_UPDATE :
            return {
                fundingBankAccounts:{
                    ...state.fundingBankAccounts,
                    result:[
                        ...action.payload,
                    ]
                },
            }
        case actionTypes.FBAS_FETCH_FAILURE :
            return {
                ...state,
                fundingBankAccounts:{
                    result:[]
                }
            }
        case actionTypes.USER_LOGOUT:
            return initialState;
        default :
            return state
    }
    return state

}

export default userFundingBankAccounts

import React, {useEffect, useState} from 'react';
import ProfileForm from "../ProfileForm/ProfileForm";
import Api from "../../../../../services/api";
import useForm2 from "../../../../../hooks/useForm2";
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {updateUserState} from "../../../../User/actions";
import {Link, Prompt, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../../../../CompanyCountryCheck";
import VolveSnackbar from "../../../../VolveSnackbar";
import {ReactComponent as Logo} from "../../../../../assets/logoInvesthub.svg";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import {Ability} from "@casl/ability";
import {
    Grid,
    makeStyles
} from "@material-ui/core"
import { isDarkModeActive } from '../../../../App/App';
const service = new Api();

const useStyles = makeStyles(theme => ({
	infoItem:{
		flexGrow:1,
		width:"100%"
	},
    snackbarWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        "& .MuiSnackbarContent-root": {
            width: "100%",
            maxWidth: '576px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
	warning:{
		background: "#ff9800",
	},
	error:{
		background:"#d32f2f",
	},

    link:{
        color: "#24DAC4",
        fontSize: "15px",
        lineHeight: "26px",
        textDecoration:"none",
        "&:hover":{
            textDecoration:"none",
            color: "#1EB3A1",
            background: "none"
        }
    },
}));

const UserProfile = (props) => {
    const {t} = useTranslation();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const {userData, handleErrorMessage, setMessage, updateUserState, roles, settings} = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [countries, setCountries] = useState([]);
    const [titles, setTitles] = useState([]);
    const [groups, setGroups] = useState([]);
    const [branches, setBranches] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [ability, setAbility] = useState(new Ability());

    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        (async () => {
            setIsDataFetching(true);
            try {
                const countriesPromise = service.getServiceData({dataFor:"countries"});
                const titlesPromise = service.getServiceData({dataFor:"userPrefixes"});
                const groupsPromise = service.getGroupsAll();
                const branchesPromise = service.getBranchesAll();
                const departmentsPromise = service.geDepartmentsAll();
                const response = await Promise.all([countriesPromise, titlesPromise, groupsPromise, branchesPromise, departmentsPromise]);

                const [countriesFieldsResult, titlesResult, groupsResult, branchesResult, departmentsResult] = response;

                const mappedCountries = countriesFieldsResult.data.map((country) => {
                    return {
                        label: country.name,
                        value: country["country-code"]
                    }
                });
                setCountries(mappedCountries);

                setTitles(titlesResult.data);

                const mappedGroups = groupsResult.data.groups.map(({_id, name}) => ({label:name, value:_id}));
                setGroups(mappedGroups);

                const mappedBranches = branchesResult.data.branches.map(({_id, name}) => ({label:name, value:_id}));
                setBranches(mappedBranches);

                const mappedDepartments = departmentsResult.data.departments.map(({_id, name}) => ({label: name, value: _id}));
                setDepartments(mappedDepartments);

                const userResult = await service.getCurrentUser();
                const {data} = userResult;
                updateUserState(data);

                setIsDataFetching(false);
            }catch (e) {
                handleErrorMessage(e);
                setIsDataFetching(false);
            }
        })();

    },[]);

    useEffect(() => {
        const fields = ["avatarUrl", "title", "firstName", "middleName", "lastName", "email", "phone", "country", "group", "position", "department", "expenseReminder", "iban"];
        const objectToFill = {};

        fields.forEach((field) => {
            objectToFill[field] = userData[field] ? userData[field] : "";
        });
        if(userData.branch && userData.branch._id){
            objectToFill["branch"] = userData.branch._id;
        }

        fillFormValues(objectToFill);

        const {userRules} = userData;

        const ability = new Ability(userRules);
        setAbility(ability);

    },[userData]);

    const submit = async () => {
        setDisabled(true);
        const formValues = new FormData();

        Object.keys(values).forEach((key) => {
            formValues.append(key, values[key]);
        });

        try {
            const result = await service.updateCurrentUser(formValues);
            const {data} = result;
            updateUserState(data);
            setMessage("successfullyUpdated", 'success');
            setDisabled(false);
            setValuesWasChanged(false);
        }catch (e) {
            setServerErrors(e);
            setDisabled(false);
        }
    }

    const validate = (values) => {
        return {};
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );


    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


    return (
        <>
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            <CompanyCountryCheck noText>
                {!isDataFetching && (
                    <div className={classes.snackbarWrapper}>
                        {!userData.kyc && (
                            <VolveSnackbar
                                message={<>{t("verifyKyc")}</>}
                                action={
                                    <>
                                        <Link to={`/user-verification/${userData._id}`}
                                                className={classes.link}>{t("formFields.verify")}</Link>
                                    </>}
                                icon={<Logo/>}
                                settings={settings}
                            />
                        )}
                        {userData.kyc === "pending" && (
                            <Grid container spacing={5}>
                                <Grid item xs={12} lg={6}>
                                    <VolveSnackbar
                                        message={<>{t("kycIsPending")}</>}
                                        icon={<Logo width={"27px"} height={"27px"}/>}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </div>
                )}
            </CompanyCountryCheck>

            <ProfileForm
                countries={countries}
                titles={titles}
                groups={groups}
                branches={branches}
                departments={departments}
                isDisabled={isDisabled}
                handleSubmit={handleSubmit}
                errors={errors}
                values={values}
                roles={roles}
                customerNumber={userData.customerNumber}
                handleChange={handleChangeWithDetectChanges}
                ability={ability}
                isDataFetching={isDataFetching}
                settings={settings}
            />
        </>
    );
};

const mapStateToProps = ({user}) => {
    const {userData, roles} = user;
    return {
        userData,
        roles
    }
}

export default compose(
    withRouter,
    connect( mapStateToProps,{handleErrorMessage, setMessage, updateUserState})
)(UserProfile)

import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import NoReceiptDialog from "./NoReceiptDialog";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles(theme => ({
            button: {
                padding: "1px 0 1px 0",
                fontWeight:400,
                textAlign:"left",
                minWidth: "auto",
                "&:hover": {
                    background: "none"
                }
            },
            noReceiptText:{
                fontSize:"15px",
                margin:"13px 0"
            },

            receiptEdit: {
                display: 'flex',
                flexFlow: 'column nowrap',
                gap: '16px'
            },

            receiptEditText: {
                fontWeight: '500',
                color: 'var(--battelship-grey)',
            },

            btnFullWidth: {
                width: '100%'
            }
        }
    )
);



const NoReceipt = (props) => {
    const {t} = useTranslation();
    const {disabled, value, handleChange} = props;
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    return (
        <>
            {(value && disabled) ? (
                <Box className={classes.receiptEdit}>
                    <Box className={classes.receiptEditText}>
                        {value}
                    </Box>
                </Box>
            ) : (
                <>
                    {value ? (
                            <Box className={classes.receiptEdit}>
                               <Box className={classes.receiptEditText}>{value}</Box>
                                <Button
                                    onClick={() => setOpen(true)}
                                    disableTouchRipple={true}
                                    className={`btn-small ${classes.btnFullWidth}`}
                                >
                                    {t("expense.editMissingReceipt")}
                                </Button>
                            </Box>
                        ) : (
                            <Button
                                onClick={() => setOpen(true)}
                                disableTouchRipple={true}
                                className={`btn-stroke btn-small ${classes.btnFullWidth}`}
                            >
                                {t("expense.missingReceipt")}
                            </Button>
                        )
                    }
                    <NoReceiptDialog
                        disabled={disabled}
                        value={value}
                        handleChange={handleChange}
                        open={open}
                        setOpen={setOpen}
                    />
                </>
            )}
        </>

    );
};

export default NoReceipt;

import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { groupBy, filter } from "lodash";

import Api from "../../../../../services/api";
import { handleErrorMessage, setMessage } from "../../../../Messages/actions";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../Pagination";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { ReactComponent as CloseThin } from "../../../../../assets/closeThin.svg";
import { ReactComponent as PlusIconSVG } from "../../../../../assets/plusCircleInvesthub.svg";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as Pen } from "../../../../../assets/pen.svg";
import { ReactComponent as Download } from "../../../../../assets/download.svg";
import { makeStyles, CircularProgress } from "@material-ui/core";
import FormatDate from "../../../../FormatDate";
import Box from "@material-ui/core/Box";
import downloadFile from "../../../../../helpers/downloadFile";
import { Can } from "../../../../../Context/Can";
import { subject } from "@casl/ability";
import PlusButton from "../../../../PlusButton";
import Button from "@material-ui/core/Button";
import DialogCreateFiatAccount from "../DialogCreateFiatAccount";
import { updateUserState } from "../../../../User/actions";

import { confirm } from "../../../../../helpers/confirm";
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import ShortenText from "../../../../../shared/ShortenText";
import MoneriumAccount from "../MoneriumAccount";
import TanganyWallet from "../TanganyWallet";
import Tooltip from "@material-ui/core/Tooltip";
import * as loader from "../../../../../assets/animations/loader";
import FiatAccount from "../FiatAccount";
const service = new Api();
const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
        position: "relative",
        minHeight: "40px",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid var(--very-light-pink-secondary)",
        marginBottom: "56px",
        paddingBottom: "32px",
        [theme.breakpoints.down("1023")]: {
            marginBottom: "34px",
            paddingBottom: "24px",
        },
        [theme.breakpoints.down("767")]: {
            flexWrap: "wrap",
            alignItems: "flex-start",
            gap: "16px",
        },
    },

    customClass: {
        "&.MuiButton-root": {
            width: "40px",
            height: "40px",
            padding: "0",
            background: "none",
            boxShadow: "0 0 0 2px #EFEFEF inset",
            transition: "all .2s",
            minWidth: "initial",
            borderRadius: "8px",
            "& svg": {
                width: "24px",
                height: "24px",
                transition: "all .2s",
                fill: "var(--battelship-grey)",
            },

            "&:hover": {
                background: "var(--clear-blue-primary)",
                boxShadow: "0 0 0 2px var(--clear-blue-primary) inset",

                "& svg": {
                    fill: "var(--pale-grey)",
                },
            },

            "& .MuiButton-startIcon": {
                display: "none",
            },
            "& .MuiButton-endIcon": {
                display: "none",
            },
        },
    },

    tableFiat: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: "-24px",
            marginRight: "-24px",
            [theme.breakpoints.down("767")]: {
                marginLeft: "-16px",
                marginRight: "-16px",
            },
        },
        "& thead tr th": {
            padding: "10px 16px",
            "&:first-child": {
                paddingLeft: "24px",
                [theme.breakpoints.down("767")]: {
                    paddingLeft: "16px",
                },
            },
            "&:last-child": {
                paddingRight: "24px",
                [theme.breakpoints.down("767")]: {
                    paddingRight: "16px",
                },
            },
        },
        "& tbody tr td": {
            fontSize: "14px !important",
            fontWeight: "600 !important",
            lineHeight: "1.71429",
            color: "var(--battelship-grey) !important",
            padding: "10px 16px !important",
            transition: "color .2s",
            "&:hover": {
                color: "var(--dark-secondary) !important",
            },

            "&:first-child": {
                paddingLeft: "24px !important",
                [theme.breakpoints.down("767")]: {
                    paddingLeft: "16px !important",
                },
            },
            "&:last-child": {
                paddingRight: "24px !important",
                [theme.breakpoints.down("767")]: {
                    paddingRight: "16px !important",
                },
            },
        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: "rgba(239, 239, 239, 0.35)",
        },
    },

    balanceItems: {
        position: "relative",

        "&:not(:last-child)": {
            marginBottom: "32px",

            [theme.breakpoints.down("767")]: {
                marginBottom: "24px",
            },
        },
    },

    balanceItemsTitle: {
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "1.6",
        [theme.breakpoints.down("767")]: {
            fontSize: "18px",
        },
    },

    balanceItemsInner: {
        display: "flex",
        margin: "24px -24px 0",
        overflow: "auto",
        overflowX: "auto",
        scrollbarWidth: "none",

        "&:before": {
            content: `""`,
            display: "block",
            flexShrink: "0",
            width: "24px",
            height: "1px",
        },
        "&:after": {
            content: `""`,
            display: "block",
            flexShrink: "0",
            width: "24px",
            height: "1px",
        },
    },

    balanceCard: {
        position: "relative",
        flexShrink: "0",
        width: "280px",
        height: "166px",
        borderRadius: "12px",
        overflow: "hidden",
        display: "flex",
        flexFlow: "column noWrap",
        backgroundColor: "var(--very-light-pink-primary)",

        "&:not(:last-child)": {
            marginRight: "12px",
        },
    },

    balanceCardInner: {
        display: "flex",
        flexFlow: "column nowrap",
        flex: "1",
    },

    balanceCardTitle: {
        fontSize: "18px",
        [theme.breakpoints.down("767")]: {
            fontSize: "16px",
        },
    },
}));

const Wallets = (props) => {
    const { location, userId, underBarRef, settingsDarkMode } = props;
    const classes = useStyles();
    const { search } = location;
    const queryObject = queryString.parse(search);
    const [isMount, setIsMount] = useState(false);

    const [fiatRepublicListAccountLoading, setFiatRepublicListAccountLoading] =
        useState(false);

    const [openModalAddAccount, setOpenModalAddAccount] = useState(false);

    const [authToken, setAuthToken] = useState(null);
    const [balances, setBalances] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [loading, setLoading] = useState(null);
    const { expenseId, iban, amount, wallet } = queryObject;
    const [fiatAccounts, setFiatAccounts] = useState([]);
    const [moneriumAccounts, setMoneriumAccounts] = useState([]);
    const [fiatOptions, setFiatOptions] = useState([])
    const [isDisableFiat, setIsDisableFiat] = useState(false);
    const getAuthContext = async (token) => {
        try {
            const response = await service.getMoneriumAuthContext(token)
            return response.data;
        } catch (error) {
            console.log(error);

            return null;
        }
    };

    const getProfileBalances = async (profile, token) => {
        try {
            const response = await service.getMoneriumProfileBalances(profile, token)
            const balancesByAddress = groupBy(response.data, "address");
            setBalances(balancesByAddress);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfileAccounts = async (profile, token) => {
        try {
            const response = await service.getMoneriumProfileAccounts(profile, token)
            setMoneriumAccounts(
                response.data.accounts.map((b) => ({
                    label:
                        b.address +
                        " - " +
                        b.currency +
                        " - " +
                        b.chain +
                        " - " +
                        b.network,
                    value: {
                        address: b.address,
                        chain: b.chain,
                        currency: b.currency,
                        id: b.id,
                        network: b.network,
                        standard: b?.standard,
                        iban: b?.iban,
                        state: b?.state,
                    },
                }))
            );
            setAccounts(response.data.accounts);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const getAppData = async () => {
            setLoading(true);
            const host = localStorage?.getItem("host");
            const { data } = await service.getMoneriumAuthToken(host);
            setAuthToken(data.data.access_token);

            // if (!expenseId) {
            const profile = await getAuthContext(data.data.access_token);

            if (profile) {
                await getProfileBalances(profile, data.data.access_token);
                getProfileAccounts(profile, data.data.access_token);
            }
            // }
            setLoading(false);
        };

        getAppData();
        // eslint-disable-next-line
    }, []);

    const onSetCurrentAccount = (account, balance) => {
        console.log("account", { ...account, ...balance });
        setCurrentAccount({ ...account, ...balance });
    };


    useEffect(() => {
        const getFirstAccount = () => {
            let result;
            Object.keys(balances).forEach((address) => {
                const balancesItem = balances[address];
                const accountsByAddress = filter(
                    accounts,
                    (account) => account.address === address
                );

                if (accountsByAddress && accountsByAddress.length) {
                    accountsByAddress.forEach((account) => {
                        if (result) {
                            return;
                        }
                        const balancesByAccount = balancesItem.find(
                            (b) =>
                                b.chain === account.chain &&
                                b.network === account.network
                        );

                        if (balancesByAccount) {
                            const balance = balancesByAccount.balances.find(
                                (b) => b.currency === account.currency
                            );

                            if (balance) {
                                result = [account, balance];
                            }
                        }
                    });
                }
            });

            return result;
        };
        //set default source bank account
        if (expenseId && iban && amount && authToken) {
            setCurrentAccount({
                address: "0x45AC69479611EF0BC9B18eF2B3C454D946705C44",
                amount: "385503",
                chain: "polygon",
                currency: "eur",
                iban: "IS17 2800 9840 7365 0930 4506 12",
                id: "007e52c8-b35c-11ed-8d50-3aa1c633f614",
                network: "mumbai",
                standard: "iban",
                state: "approved",
            });
        }
        // eslint-disable-next-line
    }, [authToken, expenseId, iban, amount]);

    const getCompanyFiatRepublicListAccount = async () => {
        setFiatRepublicListAccountLoading(true);
        try {
            const host = localStorage?.getItem("host");
            const { data } = await service.getCompanyFiatRepublicListAccount(
                host
            );
            setFiatOptions(data?.data?.map((f)=> ({
                label: `${f?.balance?.available} ${f?.currency} - ${f?.label} ${f?.bankDetails?.iban}}`,
                value: {
                    id: f?.id,
                    currency: f?.currency,
                    amount: f?.balance?.available,
                    name: f?.label,
                }
            })));
            if (data?.data?.length) {
                setOpenModalAddAccount(false);
            } else {
                setOpenModalAddAccount(true);
            }
            if (data?.msg === t("errors.enableFiat")) {
                setOpenModalAddAccount(true);
                setIsDisableFiat(true);
            }
            setFiatRepublicListAccountLoading(false);
        } catch {
            setFiatRepublicListAccountLoading(false);
        }
    };

    const getFiatRepublicListAccount = async () => {
        setFiatRepublicListAccountLoading(true);
        try {
            const host = localStorage?.getItem("host");
            const { data } = await service.getFiatRepublicListAccount(host);
            setFiatAccounts(data.data);
            setFiatRepublicListAccountLoading(false);
        } catch {
            setFiatRepublicListAccountLoading(false);
        }
    };

    useEffect(() => {
        setIsMount(false);
        getFiatRepublicListAccount();
        getCompanyFiatRepublicListAccount();
        // eslint-disable-next-line
    }, []);

    const { t } = useTranslation();

    const [isCreatingFiatAccount, setIsCreatingFiatAccount] = useState(false);

    const buildTabsQuery = (roleTab) => {
        switch (roleTab) {
            case "administrator":
            case "auditor":
                return {};
            case "approver":
                return { approver: userId };
            default:
                return { user: userId };
        }
    };

    const columns = [
        {
            title: t("Label"),
            field: "label",
            render: (row) => {
                return row.label;
            },
        },
        {
            title: t("Balance"),
            field: "balance",
            render: (row) => {
                const { balance } = row;
                return balance && balance.actual;
            },
        },
        {
            title: t("Currency"),
            field: "currency",
            render: (row) => {
                return row.currency;
            },
        },
        {
            title: t("IBAN"),
            field: "iban",
            render: (row) => {
                const { bankDetails } = row;
                return bankDetails && bankDetails.iban;
            },
        },
    ];
    if (expenseId && amount && wallet) {
        return <TanganyWallet/>
    } else if (currentAccount && authToken) {
        return <MoneriumAccount accounts={moneriumAccounts} authToken={authToken} currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} />
    } else {
       return <FiatAccount accounts={fiatOptions} openModalAddAccount={openModalAddAccount} setOpenModalAddAccount={setOpenModalAddAccount} isDisableFiat={isDisableFiat}/>
    }

    return (
        <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {/* NEED TRANSLATION */}
                        {t("Balance")}
                    </div>
                </Portal>
            )}

            <TanganyWallet />

            {openModalAddAccount ? (
                <DialogCreateFiatAccount
                    setOpen={setOpenModalAddAccount}
                    loading={isCreatingFiatAccount}
                    title={t("Create Fiat Account")}
                    getFiatRepublicListAccount={getFiatRepublicListAccount}
                    type="user"
                />
            ) : null}

            <div className={`card ${classes.tableFiat}`}>
                <div className={classes.head}>
                    <div
                        className={`title-blue text-weight-300 margin-bottom-0`}
                    >
                        {/* NEED TRANSLATION */}
                        {t("Fiat Accounts")}
                    </div>

                    <div className={classes.control}>
                        {/* NEED TRANSLATION */}
                        <Tooltip title={t("Create Fiat Account")}>
                            <PlusButton
                                onClick={() => setOpenModalAddAccount(true)}
                                customClass={classes.customClass}
                            >
                                <PlusIconSVG />
                            </PlusButton>
                        </Tooltip>
                    </div>
                </div>

                <VolveMaterialTable
                    columns={columns}
                    isLoading={fiatRepublicListAccountLoading}
                    data={fiatAccounts}
                    // onRowClick={rowHandlerClick}
                />

                {balances &&
                    Object.keys(balances).map((address) => {
                        const balancesItem = balances[address];
                        const accountsByAddress = filter(
                            accounts,
                            (account) => account.address === address
                        );

                        return (
                            <div className={classes.card} key={address}>
                                <div className="card__head">
                                    <div className="title-clear card__title mobile-hide">
                                        {address}
                                    </div>
                                    <div className="title-clear card__title mobile-show">
                                        <ShortenText text={address} v2 />
                                    </div>
                                </div>

                                <div className={classes.tableCardWrapper}>
                                    {accountsByAddress &&
                                        accountsByAddress.map((account) => {
                                            const balancesByAccount =
                                                balancesItem.find(
                                                    (b) =>
                                                        b.chain ===
                                                            account.chain &&
                                                        b.network ===
                                                            account.network
                                                );

                                            if (!balancesByAccount) {
                                                return "";
                                            }

                                            const balance =
                                                balancesByAccount.balances.find(
                                                    (b) =>
                                                        b.currency ===
                                                        account.currency
                                                );

                                            if (!balance) {
                                                return "";
                                            }

                                            return (
                                                <div
                                                    className={
                                                        classes.tableCard
                                                    }
                                                    key={account.id}
                                                    onClick={() =>
                                                        onSetCurrentAccount(
                                                            account,
                                                            balance
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={classes.row}
                                                    >
                                                        {" "}
                                                    </div>
                                                    <div
                                                        className={classes.row}
                                                    >
                                                        <div
                                                            className={
                                                                classes.column
                                                            }
                                                        >
                                                            {balance.currency}
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.column
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.label
                                                                }
                                                            >
                                                                {
                                                                    balance.currency
                                                                }
                                                            </div>
                                                            {balance.amount}
                                                        </div>
                                                    </div>

                                                    {account.iban && (
                                                        <div
                                                            className={
                                                                classes.row
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.column
                                                                }
                                                            >
                                                                IBAN
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.column
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        classes.label
                                                                    }
                                                                >
                                                                    IBAN
                                                                </div>
                                                                {account.iban}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })}
            </div>

            <div className="card">
                <div className={classes.head}>
                    <div
                        className={`title-blue text-weight-300 margin-bottom-0`}
                    >
                        {/* NEED TRANSLATION */}
                        {t("Web3 Account")}
                    </div>
                </div>

                {loading && (
                    <div
                        className="text-align-center"
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {/* <LottieAnimation animationData={loader} width={300} height={300} /> */}
                        <CircularProgress />
                    </div>
                )}

                {balances &&
                    Object.keys(balances).map((address) => {
                        const balancesItem = balances[address];
                        const accountsByAddress = filter(
                            accounts,
                            (account) => account.address === address
                        );

                        return (
                            <div className={classes.balanceItems} key={address}>
                                <div className={classes.balanceItemsTitle}>
                                    <span className={"mobile-hide"}>
                                        {address}
                                    </span>

                                    <span className={"mobile-show"}>
                                        <ShortenText text={address} v2 />
                                    </span>
                                </div>

                                <div className={classes.balanceItemsInner}>
                                    {accountsByAddress &&
                                        accountsByAddress.map((account) => {
                                            const balancesByAccount =
                                                balancesItem.find(
                                                    (b) =>
                                                        b.chain ===
                                                            account.chain &&
                                                        b.network ===
                                                            account.network
                                                );

                                            if (!balancesByAccount) {
                                                return "";
                                            }

                                            const balance =
                                                balancesByAccount.balances.find(
                                                    (b) =>
                                                        b.currency ===
                                                        account.currency
                                                );

                                            if (!balance) {
                                                return "";
                                            }

                                            return (
                                                <div
                                                    className={
                                                        classes.balanceCard
                                                    }
                                                    key={account.id}
                                                    onClick={() =>
                                                        onSetCurrentAccount(
                                                            account,
                                                            balance
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.balanceCardInner
                                                        }
                                                    >
                                                        <div
                                                            className={`text-weight-300 margin-bottom-8 ${classes.balanceCardTitle}`}
                                                        >
                                                            {balance.currency}
                                                        </div>

                                                        <div
                                                            className={`${classes.balanceCardText}`}
                                                        >
                                                            {balance.amount}
                                                            <br />

                                                            {account.iban && (
                                                                <>
                                                                    IBAN:{" "}
                                                                    {
                                                                        account.iban
                                                                    }
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <Button
                                                        onClick={() =>
                                                            onSetCurrentAccount(
                                                                account,
                                                                balance
                                                            )
                                                        }
                                                        className={`btn-small`}
                                                    >
                                                        {t("Transfer")}
                                                    </Button>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { user, tabs } = state;
    const { userData } = user;
    const { regional = {} } = user.settings;
    const { dateFormat } = regional;

    return {
        dateFormat,
        tabs: tabs["reports"],
        userId: userData._id,
        userData,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, {
        handleErrorMessage,
        setMessage,
        updateUserState,
    })
)(Wallets);

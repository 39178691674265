import Api from '../../../../services/api'
import {handleErrorMessage} from '../../../Messages/actions'
import * as actionTypes from '../../../../store/actionTypes'


const fetchFbas = () => {
    return async dispatch => {
        const {getCompanyFbas} = new Api();
        try {
            const response = await getCompanyFbas();
            const {data} = response;
            dispatch({type:actionTypes.FBAS_FETCH_SUCCESS, payload:data});
        }catch (e) {
            dispatch(handleErrorMessage(e));
            dispatch({type:actionTypes.FBAS_FETCH_FAILURE})
        }
    }
};

const updateItems  = (items) => dispatch => {
    dispatch({type:actionTypes.FBAS_UPDATE, payload:items});
}

export {
    fetchFbas,
    updateItems,
}
import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import Api from "../../../../../../services/api";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import useForm from "../../../../../../hooks/useForm";
import {
    makeStyles,
    Button,
    // eslint-disable-next-line no-unused-vars
    Table,
    // eslint-disable-next-line no-unused-vars
    TableHead,
    // eslint-disable-next-line no-unused-vars
    TableRow,
    // eslint-disable-next-line no-unused-vars
    TableCell,
    // eslint-disable-next-line no-unused-vars
    TableBody,
    Portal,
    Box,
    Typography
} from "@material-ui/core";
import ExpenseRow from "./ExpenseRow";
// eslint-disable-next-line no-unused-vars
import Skeleton from "react-loading-skeleton";
import {Prompt, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../../BackButton";
import isDateValid from "../../../../../../helpers/idValidDate";
import useBeforeUnload from "../../../../../../hooks/useBeforeUnload";
import { isDarkModeActive } from '../../../../../App/App';
const service = new Api();

const useStyles = makeStyles(theme => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        },
        "& .item-form-fieldset:last-child": {
            marginBottom: '0 !important',
            paddingBottom: '0 !important',
            border: 'none !important'
        }
    },
    itemFormSubmit: {
        marginTop: '48px',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        width: '100%',
        gap: '16px',

        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
}));


const BatchAdd = (props) => {
    const {
        handleErrorMessage,
        setMessage,
        history,
        dateFormat,
        // eslint-disable-next-line no-unused-vars
        userId,
        underBarRef,
        decimal,
        settingsDarkMode
    } = props;
    const [valuesWasChanged, setValuesWasChanged ] = useBeforeUnload();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [rowIndexes, setRowIndexes] = useState([0,1]);

    // static data for form
    const [currencies, setCurrencies] = useState([]);
    const [categories, setCategories] = useState([]);

    const [validationHandler, setValidationHandler] = useState(() => () => ({}));

    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        setIsMount(true);
    },[]);

    useEffect(() => {

        const validateAllItems = () => {

            return (values) => {

                return rowIndexes.reduce((errors, errorIdx) => {

                    if(!isDateValid(values[`expenses[${errorIdx}][date]`])){
                        errors[`expenses[${errorIdx}][date]`] =  t('errors.notValid', {field: "$t(formFields.date)"});
                    }
                    if (!values[`expenses[${errorIdx}][amount]`]) {
                        errors[`expenses[${errorIdx}][amount]`] = t('errors.required', {field: "$t(formFields.amount)"});
                    }

                    return errors;
                }, {});

            }

        };

        setValidationHandler(() => validateAllItems())

    },[rowIndexes]);


    useEffect(() => {
        setIsLoading(true);
        const promises = [
            // static fields
            service.getServiceData({dataFor:"currencies-all"}),
            service.getCategoriesAll(),
            service.getMyCategories(),
        ];

        Promise.all(promises).then((response) => {
            // static fields
            const [currencies, categoriesResult, userCategories] = response;

            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);

            const categories = categoriesResult.data.categories.map(({name, _id}) => {
                const option = {
                    label:name,
                    value:_id
                };

                const isDisabled = !(~userCategories.data.findIndex(item => item._id === _id));
                if(isDisabled){
                    option.disabled=true
                }

                return option
            });

            setCategories(categories);

            setIsLoading(false);

        }).catch((e) => {
            setIsLoading(false);
            handleErrorMessage(e);
        });

    },[]);

	const validate = (values) => {
		return validationHandler(values);
	}


    const submit = async () => {
        setIsSubmitting(true);
        const formValues = new FormData();

        Object.keys(values).map((key) => formValues.append(key, values[key]));



        try {
            await service.addBatchExpense(formValues);
            setIsSubmitting(false);
            setMessage("success", 'success');
            setValuesWasChanged(false);
            history.push("/transactions");
        }catch (e) {
            setServerErrors(e);

            setIsSubmitting(false);
        }


	}

	const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
		submit,
		validate
	);

	const onAddNewRow = () => {
		if(rowIndexes.length){
			const lastIndex = rowIndexes[rowIndexes.length-1]; // get last index value
			const newIndex = lastIndex+1;
			setRowIndexes((rowIdxes) => ([...rowIdxes, newIndex]));
		}else{
			setRowIndexes(() => ([0]));
		}

	}

	const onDeleteRow = (index) => {
		// remove values
		setRowIndexes((rowIndexes) => rowIndexes.filter((idx) => idx !== index));
		handleChange(`expenses[${index}][date]`);
		handleChange(`expenses[${index}][amount]`);
		handleChange(`expenses[${index}][currency]`);
		handleChange(`expenses[${index}][merchant]`);
		handleChange(`expenses[${index}][category]`);
		handleChange(`expenses[${index}][comment]`);

	}

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


    return (
        <div>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>{t("Payment")}</Typography>
                </Portal>
            )}

            <Box className="card">
                <Box className={classes.head}>
                    <BackButton/>
                </Box>

                <Prompt
                    when={valuesWasChanged}
                    message={t("unsavedChanges")}
                />
                <form onSubmit={handleSubmit} noValidate className={classes.itemForm}>
                    {isLoading ? '...' : (
                        rowIndexes.map((rowIndex) => {
                            return (
                                <React.Fragment key={rowIndex}>
                                    <ExpenseRow
                                        index={rowIndex}
                                        handleChange={handleChangeWithDetectChanges}
                                        values={values}
                                        errors={errors}
                                        currencies={currencies}
                                        categories={categories}
                                        onDeleteRow={onDeleteRow}
                                        decimal={decimal}
                                        dateFormat={dateFormat}
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </React.Fragment>
                            )
                        })
                    )}
                    <Box className={classes.itemFormSubmit}>
                        <Button
                            onClick={() => onAddNewRow()}
                            className='btn-stroke'
                        >
                            {t("formFields.newLine")}
                        </Button>
                        <Button
                            type="submit"
                            disabled={isSubmitting || !rowIndexes.length}
                        >
                            {t("formFields.save")}
                        </Button>
                    </Box>
                </form>
            </Box>

        </div>
    );
};

const mapStateToProps = ({user}) => {
    const {settings, userData} = user;
    const {regional} = settings;
    const {dateFormat, decimal} = regional;
    return {
        dateFormat,
        decimal,
        userId: userData._id
    }
}
export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage}),
    withRouter
)(BatchAdd);

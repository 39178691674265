import React from 'react';
import {
    makeStyles,
    FormLabel,
    FormHelperText,
} from "@material-ui/core";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Skeleton from "react-loading-skeleton";
import { isDarkModeActive } from '../../App/App';


const PhoneField = (props) => {
    const noHandler = () => {
        console.log(`No handler: PhoneField`);
    };

    const {name="", value="", error="", onChangeHandler=noHandler, label="", disabled=false, isLoading, settings} = props;

    // eslint-disable-next-line no-unused-vars
    let borderColor = "#8A96A0";
    if(error){
        borderColor = "#f44336"
    }else if(disabled){
        borderColor = "#fff"
    }

    const useStyles = makeStyles({
        input: {

        },
        label:{
            display:"block",
        },
        phoneWrapper:{
            // Layout
            '& :disabled':{
                cursor:"text",
            },
            '& .flag-dropdown': {
                border: 0,
                background: "none!important",
                paddingLeft: '0',
                width: '48px',
                borderRadius: '0',
                "& .arrow": {
                    display: 'none'
                }
            },
            '& .selected-flag':{
                background:"none!important",
                paddingLeft:"0",
                width: '48px'
            },
            '& .selected-flag .flag': {
                left: '50%',
                marginLeft: '-8px',
            },
            // Country list
            "& .react-tel-input .country-list": {
                marginTop: '2px',
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--almost-black)' :'var(--white)',
                boxShadow: (props) => props.isDarkModeActive ? 'inset 0 0 0 2px #33383F, 0 4px 12px rgba(17, 19, 21, 0.1)' : 'inset 0 0 0 2px #EFEFEF, 0 4px 12px rgba(244, 244, 244, 0.1)',
                borderRadius: "12px",
                padding:"8px 0",
                border: 'none',

                "&::-webkit-scrollbar": {
                    width: '8px'
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: (props) => props.isDarkModeActive ? 'var(--charcoal-secondary)' : 'var(--very-ligh-pink-primary)'
                },
                "&::-webkit-scrollbar-thumb": {
                    borderRadius: '8px',
                    backgroundColor: (props) => props.isDarkModeActive ? 'var(--white)' : 'var(--cool-grey)'
                },

                "& .country": {
                    position:"relative",
                    minHeight: 'auto',
                    padding: '8px 16px',
                    background: 'none',
                    fontFamily: `'Inter', sans-serif`,
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.71429',
                    transition: 'all 0.2s',
                    color: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--battelship-grey)',

                    "& .dial-code": {
                        color: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--battelship-grey)',
                        transition: 'all 0.2s',
                    },
                    "&:hover": {
                        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                        "& .dial-code": {
                            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                        }
                    },
                    "&.highlight": {
                        color: (props) => props.isDarkModeActive ? 'var(--clear-blue-primary)' : 'var(--clear-blue-primary)',
                        "& .dial-code": {
                            color: (props) => props.isDarkModeActive ? 'var(--clear-blue-primary)' : 'var(--clear-blue-primary)',
                        }
                    },
                    "&.highlight:hover": {
                        color: (props) => props.isDarkModeActive ? 'var(--clear-blue-primary)' : 'var(--clear-blue-primary)',
                        "& .dial-code": {
                            color: (props) => props.isDarkModeActive ? 'var(--clear-blue-primary)' : 'var(--clear-blue-primary)',
                        }
                    },
                },
                "& .divider": {
                    display: "none"
                },
            },

            '& .form-control':{
                width: '100%',
                border: 'none',
                height: '48px',
                padding: '0 10px',
                paddingLeft: '48px',
                fontSize: '14px',
                background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--white)',
                transition: 'all .2s',
                fontFamily: `'Inter', sans-serif`,
                fontWeight: '600',
                lineHeight: '1.6',
                borderRadius: '12px',
                color: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--dark-secondary)',
                boxShadow: (props) => props.isDarkModeActive ? 'inset 0 0 0 2px #272B30' : 'inset 0 0 0 2px #EFEFEF',
            },
            '& .form-control:focus': {
                background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            },
            '& .form-control.open': {
                background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
                boxShadow: (props) => props.isDarkModeActive ? 'inset 0 0 0 2px #33383F' : 'inset 0 0 0 2px #EFEFEF',
            },
            '& label + .react-tel-input':{
                marginTop: "12px"
            },
        }
    });

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });


    return (
        <>
            <div className={classes.phoneWrapper}>
                {label && <FormLabel component="label" htmlFor={`phone-${name}`} className={classes.label}>{label}</FormLabel>}
                {isLoading ? (
                    <Skeleton height={"42px"} width={"100%"}/>
                ) : (
                    <PhoneInput
                        country={'sg'}
                        enableTerritories
                        preferredCountries={['sg']}
                        disableAreaCodes={'false'}
                        value={value}
                        placeholder={""}
                        disabled={disabled}
                        masks={{sg: ' .... ....'}}
                        onChange={(value) => {
                            onChangeHandler(value)
                        }}
                    />
                )}
            </div>
            {!!error &&  <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default PhoneField;

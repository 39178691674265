import axios from "axios";
import Api from "../services/api";
const service = new Api();

const openBlobFromUrl = async (value) => {
    const response = await axios.get(`${service._basicPath}/files/${value}`, {responseType:"blob"});
    const blobUrl = window.URL.createObjectURL(response.data);
    window.open(blobUrl, '_blank');
}

export default openBlobFromUrl

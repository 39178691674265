import React from 'react';
import Search from "../../../Bar/Search";
import {makeStyles} from "@material-ui/core";
import FilterButton from "../../../../FilterButton/FilterButton";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            gap: '16px'
        },
        '& .title-blue': {
            marginRight: 'auto',
            marginBottom: '0',
            [theme.breakpoints.down('767')]: {
                // paddingBottom: '6px',
                margin: '0 0 16px'
            }
        },

        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    searchWrapper: {
        position: 'relative',
        width: '360px',
        marginRight: 'auto',
        [theme.breakpoints.down('1023')]: {
            width: '250px',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 16px',
        },
        '& .MuiInputLabel-root': {
            display: 'none'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiInputBase-root': {
            position: 'relative',

            "& input[type:'text']": {
                paddingRight: '44px',
            },

            "& button": {
                position: 'absolute',
                top: '0',
                right: '12px',
                bottom: '0',
                width: '44px',
                paddingLeft: '4px',
                margin: '0',
                padding: '0',

                "& svg path": {
                    transition: 'fill .2s',
                },

                "&:hover": {
                    backgroundColor: 'transparent',

                    "& svg path": {
                        stroke: 'var(--clear-blue-primary)'
                    }
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            }
        }
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '8px'
    }
}));


const TopComponent = (props) => {
    const {onFilterOpen, isFilterOpen} = props;
    const {t} = useTranslation();

    const classes = useStyles();
    return (
        <div className={classes.head}>
            <div className={classes.searchWrapper}>
                <Search type="transactions" placeholderText={t('Search by description')}/>
            </div>

            <div className={classes.control}>
                {!isFilterOpen && <FilterButton onClick={onFilterOpen}/>}
            </div>
        </div>
    );
};

export default TopComponent;

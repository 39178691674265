export const TRANSACTION_STATUS = {
    APPROVED: "approved",
    SUBMITTED: "submitted",
    COMPLETED: "completed",
};

export const PAID_METHODS = {
    CASH: "cash",
    WALLET: "wallet",
    BANK: "bank",
};

export const VENDORS = {
    BANK: "bank",
    WALLET: "wallet",
};

export const VENDORS_LIST = [
    {
        label: "Bank",
        value: "bank",
    },
    {
        label: "Wallet",
        value: "wallet",
    },
];

export const PAYMENT_TYPES = {
    EXPENSE: "Expense",
    REQUEST_PAYMENT: "Request Payment",
};

export const PAYMENT_TYPES_LIST = [
    {
        label: "Expense",
        value: "Expense",
    },
    {
        label: "Request Payment",
        value: "Request Payment",
    },
];

export const MESSAGES = {
    CONTACT_EXIST: "Contact existed",
    CREATED_SUCCESS: "Created successfully",
};

export const IN_OUT_TRANSACTIONS = [
    "incoming-transactions",
    "outgoing-transactions",
    "executed-transactions",
];

export const TRANSACTIONS_TYPES = {
    EXECUTED: "executed-transactions",
    TODO: "todo",
    ALL: "all",
};

export const TRANSACTION_HASH_TYPES = {
    FIAT: "FIAT",
    CRYPTO: "CRYPTO",
};

export const ACCOUNT_TYPES = {
    FIAT: "fiat",
    MONERIUM: "monerium",
    TANGANY: "tangany",
    IMPORTED: 'imported'
};

export const CHAINS = [
    {
        label: "Polygon",
        value: "polygon",
    },
    {
        label: "Ethereum",
        value: "ethereum",
    },
];

import React from 'react';
import {NavLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    link:{
        color:"#8a8d9b",
        fontSize: "12px",
        letterSpacing:"0.08px",
        textDecoration:"none",
        display:"block",
        width:"100%",
        padding:"10px 10px 10px 35px",
        '&.active':{
            color:"#fff",
            background:"rgba(255, 255, 255, 0.2)"
        }
    },
    item:{
        paddingTop:0,
        paddingBottom:0
    }

}));


const MenuItemChild = (props) => {
    const {to, name} = props;
    const classes = useStyles();

    return (
        <ListItem button dense={false} disableGutters={true} className={classes.item}>
            <NavLink to={`/${to}`} className={classes.link}>
                {name}
            </NavLink>
        </ListItem>
    );
};

export default MenuItemChild;

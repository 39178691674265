import React from 'react';
import {makeStyles, InputAdornment, IconButton} from "@material-ui/core";
import InputField from "../../../../formElements/InputField";
import {ReactComponent as SearchIcon} from "../../../../../assets/search.svg";
// import {useTranslation} from "react-i18next";

const SearchInput = (props) => {

    const useStyles = makeStyles({
        field: {
            "& .MuiInputBase-root.MuiInputBase-adornedStart .MuiInputAdornment-positionStart.start_block": {
                backgroundColor: 'transparent'
            },
            "& .MuiIconButton-root": {
                paddingLeft: '0 !important',
                width: '100% !important',
                right: '0 !important',
                "& svg": {
                    width: '20px',
                    height: '20px',
                    fill: '#6F767E !important',
                    transition: 'fill .2s',
                }
            }
        }
    });

    const classes = useStyles();

    // const {t} = useTranslation();

    const {InputProps, handleSubmit, searchInputPlaceholder, settingsDarkMode, ...restProps} = props;

    return (
        <InputField
            {...restProps}
            onInputChange={() => {}} // do nothing. Handler is in params.inputProps.onChange
            // label={t("formFields.search")}
            className={classes.field}
            InputProps={{
                startAdornment: (
                    <React.Fragment>
                        <InputAdornment className={`start_block`} position="start" onClick={handleSubmit ? handleSubmit : () => {}}>
                            <IconButton aria-label="Searching" className={classes.btnWrapper}>
                                <SearchIcon className={classes.icon}/>
                            </IconButton>
                        </InputAdornment>
                    </React.Fragment>
                ),
                ...InputProps,
            }}
            placeholder={searchInputPlaceholder}
        />
    );
};

export default SearchInput;

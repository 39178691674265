import React, {useEffect, useState} from 'react';
import ExpenseForm from "../../../../../ExpenseForm";
import {compose} from "redux";
import {connect} from "react-redux";
import Api from "../../../../../../services/api";

import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../../BackButton";
import useBeforeUnload from "../../../../../../hooks/useBeforeUnload";
import {Prompt} from "react-router-dom";
import useForm2 from "../../../../../../hooks/useForm2";
import ThinButton from "../../../../../ThinButton";
import useMounted from "../../../../../../hooks/useMounted";
import useExpenseValidation from "../../../../../../hooks/useExpenseValidation";
import moment from "moment-timezone";
import {
    makeStyles,
    Button,
    Portal,
    Typography,
    Box
} from "@material-ui/core";
import { isDarkModeActive } from '../../../../../App/App';
import formatMoney from "../../../../../../helpers/formatMoney";
const service = new Api();

const useStyles = makeStyles((theme) => ({
    // Page title.
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
        "& .MuiButton-root": {
            [theme.breakpoints.down('767')]: {
                width: '100%',
            }
        }
    },

    item: {
        position: 'relative'
    },

    btnWrapper: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        gap: '8px'
    }
}))

const AddExpense = (props) => {
    const {
        setMessage,
        regional,
        history,
        userId,
        branch,
        underBarRef,
        handleErrorMessage,
        company,
        settingsDarkMode
    } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {advancedSettings} = company;

    const validate = useExpenseValidation(advancedSettings);

    const {dateFormat, decimal} = regional;
    const userSettingsCurrency = regional.currency;

    const companyCurrency = company.currency;
    const branchCurrency = branch && branch.currency ? branch.currency : null;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rateIsLoading, setRateIsLoading] = useState(false);

    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const isMounted = useMounted();

    const {t} = useTranslation();



    const submit = async () => {

        setIsSubmitting(true);
        values.paymentType = "Expense";
        const formValues = new FormData();
        Object.keys(values).forEach((field) => {
            formValues.append(field, values[field] !== undefined ? values[field] : "");
        });

        try {
            await service.createExpense(formValues);
            setIsSubmitting(false);
            setValuesWasChanged(false);
            history.push("/transactions");
            setMessage("success", "success");
        } catch (e) {
            if (e?.response?.data?.msg) {
                setMessage(t(e?.response?.data?.msg), "error");
            } else {
                setServerErrors(e);
            }
            setIsSubmitting(false);
        }
    }

    const [currencies, setCurrencies] = useState([]);
    const [countries, setCountries] = useState([]);

    const [paidWith, setPaidWith] = useState([]);
    const [taxRatesByCountry, setTaxRatesByCountry] = useState([]);


    useEffect(() => {
        setIsLoading(true);

        const promises = [
            service.getServiceData({dataFor:"currencies-all"}),
            service.getServiceData({dataFor:"paidWith"}),
            service.getServiceData({dataFor:"countries"}),
        ];

        Promise.all(promises).then((response) => {
            const [currencies, paidWith, countriesResult] = response;

            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);

            const mappedPaidWith = paidWith.data.map(({value, name}) => ({label:name, value:value}));

            setPaidWith(mappedPaidWith);

            const mappedCountries = countriesResult.data.map((country) => {
                return {
                    label: country.name,
                    value: country["country-code"]
                }
            });

            setCountries(mappedCountries);


            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
            handleErrorMessage(e);
        });

    },[]);


    const { handleChange, handleSubmit, values, errors, setServerErrors} = useForm2(
        submit,
        validate,
    );

    const {reportedCurrency, currency, date} = values;


    const getReportedExchangeRate = async (fromCurrency, toCurrency, date) => {

        const theSameCurrency = fromCurrency === toCurrency;

        const doNotCalculate = theSameCurrency || !fromCurrency || !toCurrency;

        if(doNotCalculate){
            handleChange("reportedExchangeRate", 1);
            return;
        }

        try {
            setRateIsLoading(true);
            const response = await service.getExchangeRate(fromCurrency, toCurrency, 1, date);
            handleChange("reportedExchangeRate", response.data.result);
        }catch (e) {
            handleErrorMessage(e);
        }finally {
            setRateIsLoading(false);
        }
    };

    useEffect(() => {
        // do not use fillFormValues() because some data fills in deeper component
        handleChange('paidWith', "cash");

        const reportedCurrency = branchCurrency ? branchCurrency : companyCurrency;

        if(userSettingsCurrency){
            handleChange('currency', userSettingsCurrency);
        }else{
            handleChange('currency', reportedCurrency);
        }

        handleChange('reportedCurrency', reportedCurrency);

    },[]);

    useEffect(() => {
        getReportedExchangeRate(currency, reportedCurrency, date)
    },[reportedCurrency, currency, date]);

    useEffect(() => {
        const {expenseReimbursableActive} = advancedSettings;
        handleChange('reimbursable', expenseReimbursableActive);
    },[advancedSettings]);

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true);
        }
        handleChange(name, value)
    }


    const checkDisabledField = (name) => {
        const readOnlyFields = ["paidWith"];
        return readOnlyFields.includes(name);
    }


    const onSubmit = (e, status) => {
        handleChange("status", status);
        handleSubmit(e);
    }

    const onDateChange = (date) => {
        handleChangeWithDetectChanges("date", date ? moment(date).startOf('day').format() : null);
    }
    const onCurrencyChange = (currency) => {
        handleChangeWithDetectChanges("currency", currency);
    }
    const actionButtons = (
        <>
            <Button
                disabled={isSubmitting}
                onClick={(e) => onSubmit(e, "toSubmit")}
            >
                {t("formFields.saveAndSubmit")}
            </Button>

            <Button
                className="btn-stroke"
                disabled={isSubmitting}
                onClick={(e) => onSubmit(e, "toSubmit")}
            >
                {t("formFields.save")}
            </Button>

            <ThinButton onClick={() => history.push(`/transactions`)}>
                {t("formFields.cancel")}
            </ThinButton>
        </>
    )
    console.log('decimal ex', decimal)

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                        {/* NEED TRANSLATION */}
                        {t("Payment")}
                    </Typography>
                </Portal>
            )}
            <div className="card">
                <Box className={classes.head}>
                    <BackButton />
                </Box>

                <div className={classes.item}>
                    <Prompt
                        when={valuesWasChanged}
                        message={t("unsavedChanges")}
                    />

                    <ExpenseForm
                        isLoading={isLoading}
                        dateFormat={dateFormat}
                        values={values}
                        handleChange={handleChangeWithDetectChanges}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        decimal={decimal}
                        advancedSettings={advancedSettings}
                        paidWith={paidWith}
                        currencies={currencies}
                        checkDisabledField={checkDisabledField}
                        countries={countries}
                        taxRatesByCountry={taxRatesByCountry}
                        setTaxRatesByCountry={setTaxRatesByCountry}
                        history={history}
                        onDateChange={onDateChange}
                        onCurrencyChange={onCurrencyChange}
                        rateIsLoading={rateIsLoading}
                        settingsDarkMode={settingsDarkMode}
                        actionsButons={actionButtons}
                        formType='Expense'
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const {user} = state;
    const {settings, userData} = user;
    const {regional} = settings;
    const {_id, branch, company} = userData;

    return {
        regional,
        userId: _id,
        branch,
        company,
    }
};
export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage}),
)(AddExpense);

import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../../../../Messages/actions";
import Api from "../../../../../../../../services/api";
import {FormControl, Grid} from "@material-ui/core";
import SelectField from "../../../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
const service = new Api();


const GroupField = (props) => {
    const {handleChange, value, error, isDataFetching, setIsDataFetching} = props;
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const [groups, setGroups] = useState([]);

    const fetchGroups = async () => {
        try {
            setIsDataFetching(true);
            const resp = await service.getGroupsAll();
            const mappedGroups = resp.data.groups.map(({_id, name}) => ({label:name, value:_id}));
            setGroups(mappedGroups);
        } catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsDataFetching(false);
        }
    }

    useEffect(() => {
        fetchGroups();
        return () => {
            // remove value if component unmount
            handleChange("group")
        }
    },[]);


    return (
        <div>
            <FormControl fullWidth error={!!error}>
                <SelectField
                    isLoading={isDataFetching}
                    label={t("formFields.group")}
                    placeholder={t("formFields.group")}
                    name={"group"}
                    value={value}
                    error={error}
                    options={groups}
                    onSelectChange={(val) => handleChange("group", val)}
                />
            </FormControl>
        </div>
    );
};

export default GroupField;

import React, {useState, useEffect} from "react";
import {compose} from "redux";

import Typography from "@material-ui/core/Typography";

import {useTranslation} from "react-i18next";
import { withRouter } from "react-router-dom";
import {getLoggedUser} from '../User/actions'
import {connect} from "react-redux";
import setAuthToken from "../../helpers/setAuthToken";

const SsoLogin = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    console.log('props', props)

    const token = new URLSearchParams(props.location.search).get("token")
    const expires = new URLSearchParams(props.location.search).get("expires")
    const host = new URLSearchParams(props.location.search).get("host")
    useEffect(() => {
        console.log('token', token)
        if (token) {
            setIsLoading(false);
            localStorage.setItem('token', token);
            localStorage.setItem('expires', expires);
            localStorage.setItem('host', host)
            setAuthToken(token);
            setIsLoading(false);
            props.getLoggedUser();
            console.log('getLoggedUser')
            props.history.push(`/users`);
        }
    }, [token])


    return (
        <>
            <Typography variant="h1" gutterBottom>
                {t("Redirecting")}
            </Typography>
        </>

    )
};

export default compose(
    withRouter,
    connect(null, { getLoggedUser }),
)(SsoLogin)


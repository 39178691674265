import React, {useState} from 'react';
import {subject} from "@casl/ability";
import Api from "../../../../../../../services/api";
import {useTranslation} from "react-i18next";
import ButtonRequestInfo from "../ButtonRequestInfo";
const service = new Api();

const ReadCvv = (props) => {
    const [isHiddenInfoLoading, setIsHiddenInfoLoading] = useState(false);
    const {t} = useTranslation();

    const {handleErrorMessage, card, ability, cvv, setCvv} = props;

    const canReadCvv= () => {
        if(card.user && card.company){
            return ability.can("read:cvv", subject("card", {user:card.user.id, company:card.company.id, type:card.type, currentStatCode: card.currentStatCode }));
        }
        return false;
    };

    const showButton = canReadCvv();

    const onGetHiddenInfo = async () => {
        setIsHiddenInfoLoading(true);
        try {
            const response = await service.getCardHiddenInfo(card._id);
            const {cvv} = response.data;
            setCvv(cvv);
        }catch (e) {
            console.log(e)
            handleErrorMessage(e);
        }finally {
            setIsHiddenInfoLoading(false);
        }
    }

    const onHideInfo = () => {
        setCvv('***');
    }

    return showButton ?
        (
            cvv === "***" ? (
                <ButtonRequestInfo onClick={onGetHiddenInfo} disabled={isHiddenInfoLoading}>
                    {t("card.showCvv")}
                </ButtonRequestInfo>
            ) : (
                <ButtonRequestInfo onClick={onHideInfo} disabled={isHiddenInfoLoading}>
                    {t("card.hideCvv")}
                </ButtonRequestInfo>
            )

        ) : null
};

export default ReadCvv;

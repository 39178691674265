import React, {useState, useEffect} from 'react'
import {
    makeStyles,
    Tabs,
    Tab,
    useTheme,
    useMediaQuery,
    ClickAwayListener,
    Box,
    Button
} from "@material-ui/core";
import {connect} from "react-redux";
import {ReactComponent as MoreHorizontal} from "../../../../assets/moreHorizontal.svg"
import {withRouter} from "react-router-dom";
import {tabChange} from "./actions";
import { isDarkModeActive } from '../../../App/App';

const useStyles = makeStyles(theme => ({
	tabs:{
        [theme.breakpoints.down('1023')]:{
            order: '10',
            width: "100%",
            marginTop: '24px'
        },
        "& .MuiTabs-flexContainer": {
            gap: '8px',
            [theme.breakpoints.down('1023')]: {
                gap: '16px'
            }
        },

		'& .MuiTabs-indicator':{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > span': {
                width: '100%',
                backgroundColor: '#24dac4',
                marginLeft:'12px',
                marginRight:'12px',
            },
        },
        "& .MuiTabs-scroller > span:last-child": {
            height: "100%",
            "& span": {
                display: 'none'
                // marginLeft: '0',
                // marginRight: '0',
                // backgroundColor: 'var(--very-light-pink-secondary)',
                // borderRadius: '8px'
            }
        }
    },

	tab:{
        padding: '0',
        minWidth: 'initial',
        minHeight: 'initial',
        [theme.breakpoints.down('1023')]: {
            flex: '1',
            maxWidth: 'initial',
            borderRadius: '8px'
        },
        "& .MuiTab-wrapper": {
            padding: '12px 16px',
            borderRadius: '12px',
            textAlign: 'center',
            fontFamily: `'Inter', sans-serif`,
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--pale-grey)',
            transition: 'all .2s',
            minWidth: '168px',
            textTransform: 'capitalize',
            zIndex: 9,
            backgroundColor: 'var(--clear-blue-primary)',
            letterSpacing: '0',
            "&:hover": {
                color: 'var(--pale-grey)',
                backgroundColor: 'var(--bright-blue)'
            }
        },
        "& .MuiTouchRipple-root > *": {
            display: "none"
        },
        "&.Mui-selected .MuiTab-wrapper": {
            color: 'var(--pale-grey)',
            backgroundColor: 'var(--bright-blue)'
        },
	},

    // Menu horizonal
    tabMenu: {
        position: 'relative',
        display: 'inline-block',
        zIndex: '888'
    },

    tabMenuButton: {
        padding: '0',
        width: '48px',
        minWidth: 'initial',
        cursor: 'pointer',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'transparent !important',

        "& svg": {
            width: '16px',
            height: '16px',
            fill: 'var(--battelship-grey)'
        },

        "& .MuiTouchRipple-root": {
            display: 'none'
        }
    },

    tabMenuInner: {
        position: 'absolute',
        top: 'calc(100% + 18px)',
        border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
        borderRadius: '16px',
        boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
        background: (props) => props.isDarkModeActive ? 'var(--almost-black)': 'var(--white)',
        visibility: 'visible',
        opacity: '1',
        transform: 'translateY(3px)',
        transition: 'all .2s',
        width: '280px',
        padding: '16px',
        left: '-20px',
        zIndex: '10',
        [theme.breakpoints.down('1339')]: {
            top: 'calc(100% + 15px)',
            left: '0',
            borderRadius: '12px'
        },
        [theme.breakpoints.down('767')]: {
            top: 'calc(100% - 72px)',
            left: '16px',
            right: '16px',
            width: 'auto',
            padding: '12px 16px 20px'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: '100%',
            width: '20px',
            height: '10px',
            background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E") no-repeat 50% 50%/100%auto` : `url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E\") no-repeat 50% 50%/100% auto`,
            left: '33px',
            [theme.breakpoints.down('1339')]: {
                left: '13px'
            },
            [theme.breakpoints.down('767')]: {
                left: '21px'
            }
        }
    },
    tabMenuInnerButton: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '48px',
        padding: '0 12px',
        borderRadius: '12px',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',
        background: 'transparent !important',
        justifyContent: 'flex-start',
        textAlign: 'left',
        '&:hover':{
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
        },

        "& .MuiTouchRipple-root": {
            display: 'none'
        }
    },
    tabMenuInnerButtonCurrent: {
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
    }

}));

const TopTabs = (props) => {
    const {location, tabs, tabChange, settings} = props;

    const displayOnPaths = [
        "/dashboard",
        "/transactions/request-payment",
        "/transactions/add",
        "/transactions/todo",
        "/transactions/to-submit",
        "/transactions/to-pay",
        // "/transactions/incoming-transactions",
        // "/transactions/outgoing-transactions",
        "/transactions/executed-transactions",
        "/transactions/all",
        "/transactions",
        "/settings",
        "/settings/company",
        "/users",
        "/accounts",
        "/cards",
        "/cards/physical",
        "/cards/virtual",
        "/reports",
        "/settings",
    ];

    const currentPage = location.pathname.split("/")[1];
    const currentTabs = tabs[currentPage];

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    const [isActive, setIsActive] = useState(false);
    const [delayedIsActive, setDelayedIsActive] = useState(false);

    useEffect(() => {
        let timeoutId;

        if (isActive) {
            timeoutId = setTimeout(() => {
                setDelayedIsActive(true);
            }, 300);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isActive]);

    const handleClick = (event) => {
        setIsActive(!isActive);
    }

    const handleClose = () => {
        setIsActive(false)
    }

    const handleAwayClick = () => {
        handleClose();
    }

    const canShowTabs = () => {
        return displayOnPaths.includes(location.pathname) && currentTabs
    }

    const handleButtonClick = (tab) => {
        // Implement logic to handle button click, similar to tabChange
        tabChange(currentPage, tab);
        handleClose();
    };

    return (
        <>
            {!onMax1023 && (
                <>
                    {canShowTabs() ? (
                        <Tabs
                            value={currentTabs["active"]}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="off"
                            className={classes.tabs}
                            onChange={(e, val) => tabChange(currentPage, val)}
                            TabIndicatorProps={{ children: <span /> }}
                        >
                            {currentTabs["tabs"].map((tab) => {
                                return <Tab label={tab} key={tab} value={tab} className={classes.tab}/>
                            })}

                        </Tabs>
                    ) : null}
                </>
            )}

            {onMax1023 && (
                <>
                    <ClickAwayListener onClickAway={handleAwayClick}>
                        <Box className={classes.tabMenu}>
                            <Button onClick={handleClick} className={classes.tabMenuButton}>
                                <MoreHorizontal />
                            </Button>

                            {isActive && (
                                <Box className={classes.tabMenuInner}>
                                {currentTabs["tabs"].map((tab) => {
                                    const isCurrentTab = tab === currentTabs["active"];

                                    return (
                                        <Button key={tab} onClick={() => handleButtonClick(tab)} className={`${classes.tabMenuInnerButton} ${isCurrentTab ? classes.tabMenuInnerButtonCurrent : ''}`}>
                                            {tab}
                                        </Button>
                                    );
                                })}
                                </Box>
                            )}
                        </Box>
                    </ClickAwayListener>
                </>
            )}

        </>
    )
}

const mapStateToProps = ({user, tabs}) => {
    const {roles} = user;

    return {
        roles,
        tabs
    }
}
export default connect(
	mapStateToProps, {tabChange}
)(withRouter(TopTabs))

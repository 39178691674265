import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import Api from "../../../../../services/api";
import {connect} from "react-redux";
import {setMessage, handleErrorMessage} from "../../../../Messages/actions";
import {fetchAccounts} from "../actions";
import PlusButton from "../../../../PlusButton"
import useForm from "../../../../../hooks/useForm";
import NewAccountForm from "./NewAccountForm";
import DialogPopUp from "../../../../DialogPopUp";
import {Can} from "../../../../../Context/Can";
import Tooltip from "@material-ui/core/Tooltip";
import {ReactComponent as PlusIconSVG} from "../../../../../assets/plusCircleInvesthub.svg";

const service = new Api();
const {createAccount} = service;

const useStyles = makeStyles((theme) => ({
    item: {
        position: 'relative',
        maxWidth: '480px',
        [theme.breakpoints.up('xs')]: {
            minWidth: '340px'
        }
    },
    button: {
        marginTop: '24px'
    },

    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },
}))

const NewAccountInvesthub = (props) => {
	const {setMessage, fetchAccounts, handleErrorMessage} = props;
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDataFetching, setIsDataFetching] = useState(false);
	const {t} = useTranslation();
	const [currencies, setCurrencies] = useState([]);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const getCurrencies = async () => {
        setIsDataFetching(true);
        try {
            const response = await service.getServiceData({dataFor:"currencies"});
            const mappedCurrencies = response.data.map(({code, name}) => ({label:name, value:code}));
            setCurrencies(mappedCurrencies);
            setIsDataFetching(false);
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
            setIsDataFetching(false);
        }
    }


	useEffect(() => {
	    if(open){
            getCurrencies();
        }

    },[open]);

	const handleClose = () => {
		setOpen(false);
	};

	const submit = async () => {
		const {currency} = values;
		setIsLoading(true);
		try {
			await createAccount({currency});
			await fetchAccounts();
			handleClose(true);
			setIsLoading(false);
			setMessage("success", 'success');

		}catch (e) {
			setIsLoading(false);
			setServerErrors(e)
		}
	};

	const validate = (values) => {
		const errors = {};
		if(!values["currency"]){
			errors["currency"] = t('errors.required', {field: "$t(formFields.currency)"});
		}
		return errors
	};

	const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
		submit,
		validate,
	);

    return (
        <>
            <Can I="create" a={"account"}>
                <Tooltip title={t("account.addNew")}>
                    <PlusButton onClickHandler={handleClickOpen} customClass={classes.customClass}>
                        <PlusIconSVG />
                    </PlusButton>
                </Tooltip>
            </Can>

            <DialogPopUp
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                title={t("account.addNew")}
            >
                <div className={classes.item}>
                    <NewAccountForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        currencies={currencies}
                        isLoading={isLoading}
                        isDataFetching={isDataFetching}
                    />

                    <Button
                        onClick={handleSubmit}
                        disabled={isLoading}
                        className={classes.button}
                    >
                        {t("formFields.createAccount")}
                    </Button>
                </div>
            </DialogPopUp>
        </>
    )
}

export default connect(
	null, {setMessage, fetchAccounts, handleErrorMessage}
)(NewAccountInvesthub);

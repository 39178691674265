import React, {useEffect, useState} from 'react';
import PlusButton from "../../../../../PlusButton";
import DialogPopUp from "../../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import StartStep from "./StartStep";
import LinkAccountStep from "./LinkAccountStep";
import LimitStep from "./LimitStep";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import FinishStep from "./FinishStep";
import {fetchCards} from "../../actions";
import DeliveryStep from "./DeliveryStep";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/core";
import {ReactComponent as PlusIconSVG} from "../../../../../../assets/plusCircleInvesthub.svg";

const useStyles = makeStyles(theme => ({
}))

const NewCard = (props) => {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();
    const {company, handleErrorMessage, fetchCards, setMessage, decimal, tabs, userId, settingsDarkMode} = props;

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null); // use for limit step (only display)
    const [cardData, setCardData] = useState({});
    const [title, setTitle] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setCurrentStep(0);
        setCardData({});
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const [currentStep, setCurrentStep] = useState(0);




    const allSteps = [
        {
            component: <StartStep
                handleErrorMessage={handleErrorMessage}
                setCurrentStep={setCurrentStep}
                setCardData={setCardData}
                cardData={cardData}
                company={company}
                setTitle={setTitle}
            />,
        },
        {
            component: <LinkAccountStep
                handleErrorMessage={handleErrorMessage}
                setCurrentStep={setCurrentStep}
                setCardData={setCardData}
                cardData={cardData}
                setTitle={setTitle}
                setSelectedCurrency={setSelectedCurrency}
            />,
        },
        {
            component:
                <LimitStep
                    handleErrorMessage={handleErrorMessage}
                    setCurrentStep={setCurrentStep}
                    setCardData={setCardData}
                    cardData={cardData}
                    setTitle={setTitle}
                    selectedCurrency={selectedCurrency}
                    decimal={decimal}
                />,
        },
        {
            component:
                <DeliveryStep
                    setCurrentStep={setCurrentStep}
                    setCardData={setCardData}
                    cardData={cardData}
                    setTitle={setTitle}
                />,
            for:["physical"]
        },
        {
            component:
                <FinishStep
                    handleErrorMessage={handleErrorMessage}
                    setCurrentStep={setCurrentStep}
                    setCardData={setCardData}
                    cardData={cardData}
                    setMessage={setMessage}
                    handleClose={handleClose}
                    fetchCards={fetchCards}
                    setTitle={setTitle}
                    tabs={tabs}
                    userId={userId}
                />,
        }
    ];

    const [steps, setSteps] = useState(allSteps);

    const {type} = cardData;

    useEffect(() => {
        if(type){
            const virtualSteps = allSteps.filter((step) => !step["for"] || step["for"].includes(type));
            setSteps(virtualSteps);
        }else{
            setSteps(allSteps);
        }
    },[cardData, currentStep]);



    return (
        <>
            <Tooltip
                title={t("card.addNew")}
            >
                <PlusButton onClickHandler={handleClickOpen}  settingsDarkMode={settingsDarkMode}>
                    <PlusIconSVG />
                </PlusButton>
            </Tooltip>

            <DialogPopUp
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                disableBackdropClick
                title={title}
                pageTitleClass={'card-title--red'}
                settingsDarkMode={settingsDarkMode}
            >
                <>
                    {steps[currentStep] ? steps[currentStep].component : null}
                </>
            </DialogPopUp>
        </>
    );
};

const mapStateToProps = (state) => {
    const {user, tabs} = state;

    const {userData, settings} = user;
    const {company} = userData;
    return {
        company,
        decimal: settings.regional.decimal,
        tabs:tabs["cards"],
        userId:user.userData._id
    }
}


export default connect(
    mapStateToProps,
    {handleErrorMessage, setMessage, fetchCards}
)(NewCard);

import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import {useTranslation} from "react-i18next";
import Pagination from "../../../../../Pagination";
import Api from "../../../../../../services/api";
import {handleErrorMessage} from "../../../../../Messages/actions"
import FormatDate from "../../../../../FormatDate";
import TransactionDescription from "../../../../../TransactionDescription";
import CellTransactionAmount from "../../../../../CellTransactionAmount";
import CellCardAmount from "../../../../../CellCardAmount";
import VolveMaterialTable from "../../../../../VolveMaterialTable";
import checkClick from "../../../../../../helpers/checkClick";
import queryString from "query-string";
import TopComponent from "../TopComponent";
import DrawerFilter from "../../../../DrawerFilter";
import useMounted from "../../../../../../hooks/useMounted";
import CardTransactionFilter from "../../../../DrawerFilter/CardTransactionFilter/CardTransactionFilter";
import getShortPan from "../../../../../../helpers/getShortPan";
import {makeStyles, Portal, Typography} from "@material-ui/core";
import BackButton from '../../../../../BackButton';
import FilterButton from '../../../../../FilterButton';

const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },

    transactionsTable: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    },

    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: '8px',
    }
}));

const CardTransactionsTable = (props) => {
    const classes = useStyle();

    const {match, dateFormat, handleErrorMessage, history, location, drawerOuterRef, underBarRef, backPath, setShortInfo, cardTabs, shortInfo} = props;
    const cardId = match.params.id;
    const {search} = location;
    const isMounted = useMounted();

    const [info, setInfo] = useState({
        page: 0,
        total: 0,
        pageSize: 0
    });

    const {page, total, pageSize} = info;

    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [transactions, setTransactions] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const fetchShortInfo = async (id) => {
        // set info to tabs right block
        try{
            setIsLoading(true);
            const response = await service.getCardShortInfo(id);
            const {type, name, maskedPAN=''} = response.data;
            const shortPan = getShortPan(maskedPAN);
            const shortInfo ={
                label: t(`card.${type}`),
                value: `${name ? name : t('card.personalCard')} ${shortPan}`
            };
            setShortInfo(shortInfo)
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchShortInfo(cardId);
    },[cardId]);


    const fetchTransactions = async (query) => {

        try {
            setIsLoading(true);
            const response = await service.getCardTransactions(cardId, query);
            const {result, info} = response.data;
            setInfo(info);
            setTransactions((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        const queryObject = queryString.parse(search);
        fetchTransactions(queryObject);
    },[search]);

    const handleChangePage = (event, newPage) => {
        return fetchTransactions({page:newPage});
    }

    const columns = [
        {
            title: t('thead.date'),
            render: (row) => ( <FormatDate dateFormat={dateFormat}>{row.date}</FormatDate>)
        },
        {
            title: t('thead.description'),
            field: 'description',
            render : ({description}) => <TransactionDescription description={description}/>
        },
        {
            title: t('thead.transactionType'),
            field: 'type',
        },
        {
            title: t('thead.statusCode'),
            field: 'statusCode',
        },
        {
            title: t('thead.transactionAmount'),
            align:'right',
            render: (row) => <CellTransactionAmount {...row}/>,
        },
        {
            title: t('thead.cardAmount'),
            align:'right',
            render: (row) =>  <CellCardAmount {...row}/>,
        },
    ];

    const rowHandlerClick = (e, rowData) => {
        const {expense} = rowData;
        if(!expense || !expense._id) return;

        const wasClicked = checkClick();
        if(wasClicked) {
            history.push({
                pathname: `/transactions/${rowData.expense._id}`,
            })
        }
    }

    const {label="", value=""} = shortInfo;

    return (
        <>
            {isMounted && (
                <>
                    <Portal container={underBarRef.current}>
                        <Typography variant='h1' className={`${classes.pageTitle} h3`}>
                            {label}
                            {' '}
                            {value}
                        </Typography>
                    </Portal>
                </>
            )}
            <div className={`card ${classes.transactionsTable}`}>
                <div className={classes.head}>
                    <TopComponent isFilterOpen={isFilterOpen} backPath={backPath}/>

                    <div className={classes.control}>
                        {cardTabs}

                        <DrawerFilter isOpen={isFilterOpen} onDrawerFilterClose={() => setIsFilterOpen(false)}>
                            <CardTransactionFilter dateFormat={dateFormat}/>
                        </DrawerFilter>

                        <BackButton backUrl={backPath} />

                        {!isFilterOpen && <FilterButton onClick={() => setIsFilterOpen(true)} /> }
                    </div>
                </div>

                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={transactions}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>

        </>
    )
};

const mapStateToProps = (state) => {
    const {user} = state;
    const {regional={}} = user.settings;
    const {dateFormat} = regional;
    return {
        isAuthenticated:user.isAuthenticated,
        dateFormat
    }
};

export default connect(
    mapStateToProps,
    {handleErrorMessage}
)(CardTransactionsTable);

import React, { useState, useEffect, useRef } from 'react';
import SelectField from "../../../../../formElements/SelectField";
import InputField from "../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";
import {
    makeStyles,
    FormControl,
    Button,
    useTheme,
    useMediaQuery,
    Box
} from "@material-ui/core";
import {Can} from "../../../../../../Context/Can";
import Api from '../../../../../../services/api'
import { isDarkModeActive } from '../../../../../App/App';
import formatMoney from "../../../../../../helpers/formatMoney";
import { useSelector } from "react-redux";
import { E_CURRENCIES_ALIAS } from "../../../../../../constants/currencies-ecurrencies";

const service = new Api();
const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start'
    },
    itemMenu: {
        position: 'sticky',
        top: '116px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '280px',
    },
    itemMenuLink: {
        backgroundColor: 'transparent !important',
        padding: '8px 16px',
        color: 'var(--battelship-grey)',
        justifyContent: 'flex-start',
        fontWeight: '600',
        height: 'initial',
        lineHeight: '1.6',
        letterSpacing: '0',
        "&:not(:last-child)": {
            marginBottom: '8px'
        },
        "& .MuiTouchRipple-root": {
            display: 'none !important'
        },
        "&.active": {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey) ' : 'var(--dark-secondary)',
            backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary) !important' : 'var(--very-light-pink-primary) !important',
        }
    },
    itemContent: {
        flexGrow: '1',
        paddingLeft: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0'
        }
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '40px',
        letterSpacing: '-.02em',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    // Wrapper fields
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemFormSubmit: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
    itemContentMenu: {
        display: 'block',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        '& .MuiInput-root': {
            width: '100%'
        }
    },
    itemFormFieldSetAnchor: {
        position: 'absolute',
        top: '-116px',
        left: '0',
        right: '0',
    },
}));

const CompanyForm = (props) => {
    const {t} = useTranslation();
    const {
        handleSubmit,
        values,
        handleChange,
        errors,
        isLoading,
        isDataFetching,
        countries = [],
        types = [],
        employeesRangeList = [],
        checkDisabledField,
        currencies,
        settingsDarkMode
    } = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const [tanganyWalletBalances, setTanganyWalletBalances] = useState()
    const [balance, setBalance] = useState(0)
    const [networkOptions, setNetworkOptions] = useState([])

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023));

    // NEED TRANSLATION
    const formSections = [
        { id: 'information', label: t('Information') },
        { id: 'address', label: t('Address') },
        { id: 'financialDetails', label: t('Financial Details')}
    ];
    const [activeSection, setActiveSection] = useState('information');
    const [selectedMenu, setSelectedMenu] = useState(formSections[0].id);
    const informationRef = useRef(null);
    const addressRef = useRef(null);
    const financialDetailsRef = useRef(null);
    const companyCurrency = useSelector(state => {
        const {user} = state;
        if(user && user.userData && user.userData.company && user.userData.company.currency){
            return user.userData.company.currency
        }
        return null
    });
    const refs = {
        information: informationRef,
        address: addressRef,
        financialDetails: financialDetailsRef
    }

    const scrollToSection = (selectedSection) => {
        const selectedRef = refs[selectedSection];
        if (selectedRef && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(selectedSection);
        }
    };

    // Menu
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            formSections.forEach((section) => {
                const sectionRef = refs[section.id];
                if (sectionRef && sectionRef.current) {
                    const rect = sectionRef.current.getBoundingClientRect();

                    if (rect.top <= scrollPosition && rect.bottom > scrollPosition) {
                        setActiveSection(section.id);
                    }
                }
            })
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs, formSections])

    const getCompanyTanganyWalletBalances = async () => {
        try {
            const { data } = await service.getCompanyTanganyWalletBalances();
            if (data?.evolveWallets?.length) {
                const wallets = [];
                data.evolveWallets.forEach((w, index) => {
                    const baseCurrencyToken = data?.wallets[index]?.tokenBalances?.find((e)=>
                    E_CURRENCIES_ALIAS[e?.currency] === companyCurrency
                    );
                    wallets.push({
                        label: w.ethAddress,
                        value: w._id,
                        balance: baseCurrencyToken ? baseCurrencyToken.balance : 0
                    })
                });
                setTanganyWalletBalances(wallets);
            }
        } catch(error) {
            console.log('error', error)
        }
    };

    useEffect(() => {
        (async () => {
            const response = await service.getServiceData({
                dataFor: "network",
            });
            const mappedNetworks = response.data.map(({ value, name }) => ({
                label: name,
                value,
            }));
            setNetworkOptions(mappedNetworks);
        })();
        if (companyCurrency) {
            getCompanyTanganyWalletBalances();
        }
    }, [companyCurrency]);

    return (
        <div className={classes.item}>
            {!onMax1023 && (
                <div className={classes.itemMenu}>
                    {formSections.map((section) => (
                        <Button
                            key={section.id}
                            className={`${classes.itemMenuLink} ${
                                activeSection === section.id ? 'active' : ''
                            }`}
                            onClick={() => {
                                scrollToSection(section.id);
                                setActiveSection(section.id);
                                setSelectedMenu(section.id);
                            }}
                        >
                            {t(section.label)}
                        </Button>
                    ))}
                </div>
            )}

            <div className={classes.itemContent}>
                {onMax1023 && (
                    <Box className={classes.itemContentMenu}>
                        <SelectField
                            hideEmpty
                            options={formSections.map(section => ({ value: section.id, label: section.label }))}
                            onSelectChange={(selectedSection) => {
                                scrollToSection(selectedSection);
                                setSelectedMenu(selectedSection);
                            }}
                            value={selectedMenu}
                        />
                    </Box>
                )}
                <form onSubmit={handleSubmit} noValidate className={classes.itemForm}>
                    <div className={classes.itemFormFieldSet}>
                        <div ref={informationRef} className={classes.itemFormFieldSetAnchor}>{' '}</div>

                        <div className={`${classes.itemTitle} card-title card-title--green`}>
                            {/* NEED TRANSLATION */}
                            {t('Information')}
                        </div>

                        <div className={classes.itemSettings}>
                            <div className={classes.itemRow}>
                                <FormControl error={!!errors["name"]} fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.companyName")}
                                        placeholder={t("formFields.companyName")}
                                        name="name"
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("name")}
                                        value={values["name"]}
                                        onInputChange={(val) => handleChange("name", val)}
                                        error={errors["name"]}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl error={!!errors["employeesRange"]} fullWidth className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.employeesRange")}
                                        name={"employeesRange"}
                                        value={values["employeesRange"]}
                                        error={errors["employeesRange"]}
                                        options={employeesRangeList}
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("employeesRange")}
                                        onSelectChange={(val) => handleChange("employeesRange", val)}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.itemRow}>
                                <FormControl error={!!errors["companyRegistrationNo"]} fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.companyRegistrationNo")}
                                        placeholder={t("formFields.companyRegistrationNo")}
                                        name="companyRegistrationNo"
                                        disabled={checkDisabledField("companyRegistrationNo")}
                                        fullWidth
                                        isLoading={isDataFetching}
                                        value={values["companyRegistrationNo"]}
                                        onInputChange={(val) => handleChange("companyRegistrationNo", val)}
                                        error={errors["companyRegistrationNo"]}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl error={!!errors["type"]} fullWidth className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.typeOfIncorporation")}
                                        name={"type"}
                                        isLoading={isDataFetching}
                                        value={values["type"]}
                                        error={errors["type"]}
                                        options={types}
                                        disabled={checkDisabledField("type")}
                                        onSelectChange={(val) => handleChange("type", val)}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className={classes.itemFormFieldSet}>
                        <div ref={addressRef} className={classes.itemFormFieldSetAnchor}>{' '}</div>

                        <div className={`${classes.itemTitle} card-title card-title--blue`}>
                            {/* NEED TRANSLATION */}
                            {t('Address')}
                        </div>

                        <div className={classes.itemSettings}>
                            <FormControl error={!!errors["addressStreet"]} fullWidth  className={classes.itemField}>
                                <InputField
                                    label={t("formFields.addressStreet")}
                                    placeholder={t("formFields.addressStreet")}
                                    name="addressStreet"
                                    isLoading={isDataFetching}
                                    disabled={checkDisabledField("addressStreet")}
                                    value={values["addressStreet"]}
                                    onInputChange={(val) => handleChange("addressStreet", val)}
                                    error={errors["addressStreet"]}
                                />
                            </FormControl>

                            <div className={classes.itemRow}>
                                <FormControl error={!!errors["addressNumber"]} fullWidth  className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.addressNumber")}
                                        placeholder={t("formFields.addressNumber")}
                                        name="addressNumber"
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("addressNumber")}
                                        value={values["addressNumber"]}
                                        onInputChange={(val) => handleChange("addressNumber", val)}
                                        error={errors["addressNumber"]}
                                    />
                                </FormControl>

                                <FormControl error={!!errors["addrl2"]} fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.addrl2")}
                                        placeholder={t("formFields.addrl2")}
                                        name="addrl2"
                                        isLoading={isDataFetching}
                                        value={values["addrl2"]}
                                        onInputChange={(val) => handleChange("addrl2", val)}
                                        error={errors["addrl2"]}
                                        disabled={checkDisabledField("addrl2")}
                                    />
                                </FormControl>
                            </div>

                            <div className={classes.itemRow}>
                                <FormControl error={!!errors["postCode"]} fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.postCode")}
                                        placeholder={t("formFields.postCode")}
                                        name="postCode"
                                        isLoading={isDataFetching}
                                        value={values["postCode"]}
                                        onInputChange={(val) => handleChange("postCode", val)}
                                        error={errors["postCode"]}
                                        disabled={checkDisabledField("postCode")}
                                    />
                                </FormControl>

                                <FormControl error={!!errors["city"]} fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.city")}
                                        placeholder={t("formFields.city")}
                                        name="city"
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("city")}
                                        value={values["city"]}
                                        onInputChange={(val) => handleChange("city", val)}
                                        error={errors["city"]}
                                    />
                                </FormControl>
                            </div>

                            <div className={classes.itemRow}>
                                <FormControl error={!!errors["addressRegion"]} fullWidth  className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.addressRegion")}
                                        placeholder={t("formFields.addressRegion")}
                                        name="addressRegion"
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("addressRegion")}
                                        value={values["addressRegion"]}
                                        onInputChange={(val) => handleChange("addressRegion", val)}
                                        error={errors["addressRegion"]}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors["country"]} className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.country")}
                                        name={"country"}
                                        isLoading={isDataFetching}
                                        value={values["country"]}
                                        error={errors["country"]}
                                        options={countries}
                                        disabled={checkDisabledField("country")}
                                        onSelectChange={(val) => handleChange("country", val)}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                        <div ref={financialDetailsRef} className={classes.itemFormFieldSetAnchor}>{' '}</div>
                        <div className={`${classes.itemTitle} card-title card-title--purple`}>
                            {/* NEED TRANSLATION */}
                            {t('Financial Details')}
                        </div>

                        <div className={classes.itemSettings}>
                            {/* <FormControl fullWidth error={!!errors["selectedWallet"]} className={classes.itemField}>
                                <SelectField
                                    label={t("formFields.selectedWallet")}
                                    name={"selectedWallet"}
                                    value={values["selectedWallet"]}
                                    error={errors.currency}
                                    options={tanganyWalletBalances}
                                    onSelectChange={(val) => {
                                        handleChange("selectedWallet", val)
                                        setBalance(tanganyWalletBalances.find((e)=>e.value === val).balance)
                                    }}
                                    isLoading={isDataFetching}
                                    settings={settingsDarkMode}
                                />
                            </FormControl> */}

                            <div className={classes.itemRow}>
                                <FormControl error={!!errors["walletAddress"]} fullWidth  className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.walletAddress")}
                                        placeholder={t("formFields.walletAddress")}
                                        name="walletAddress"
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("walletAddress")}
                                        value={values["walletAddress"]}
                                        onInputChange={(val) => handleChange("walletAddress", val)}
                                        error={errors["walletAddress"]}
                                    />
                                </FormControl>

                                <FormControl error={!!errors["walletPrivateKey"]} fullWidth  className={classes.itemField}>
                                    <InputField
                                        label={t("formFields.walletPrivateKey")}
                                        placeholder={t("formFields.walletPrivateKey")}
                                        name="walletPrivateKey"
                                        isLoading={isDataFetching}
                                        disabled={checkDisabledField("walletPrivateKey")}
                                        value={values["walletPrivateKey"]}
                                        onInputChange={(val) => handleChange("walletPrivateKey", val)}
                                        error={errors["walletPrivateKey"]}
                                    />
                                </FormControl>
                            </div>

                            <div className={classes.itemRow}>
                                <FormControl fullWidth error={!!errors["currency"]} className={classes.itemField}>
                                    <SelectField
                                        label={t("formFields.currency")}
                                        name={"currency"}
                                        value={values["currency"]}
                                        error={errors.currency}
                                        options={currencies}
                                        disabled={checkDisabledField("currency")}
                                        onSelectChange={(val) => handleChange("currency", val)}
                                        isLoading={isDataFetching}
                                        settings={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl fullWidth className={classes.itemField}>
                                    <InputField
                                        label={t("Available Balance")}
                                        value={formatMoney(balance) + ' ' + companyCurrency}
                                        disabled={true}
                                    />
                                </FormControl>
                            </div>

                            <FormControl fullWidth error={!!errors["network"]} className={classes.itemField}>
                                <SelectField
                                    label={t("formFields.network")}
                                    name={"network"}
                                    isLoading={isDataFetching}
                                    value={values["network"]}
                                    error={errors["network"]}
                                    options={networkOptions}
                                    // disabled={checkDisabledField("network")}
                                    onSelectChange={(val) => handleChange("network", val)}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <Can I={"update"} a={"company"}>
                        <Button
                            type="submit"
                            disabled={isLoading}
                            className={classes.itemFormSubmit}
                        >
                            {t("formFields.save")}
                        </Button>
                    </Can>
                </form>
            </div>
        </div>
    );
};

export default CompanyForm;

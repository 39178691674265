import React from 'react';
import FormHelperText from "@material-ui/core/FormHelperText";
import Skeleton from "@material-ui/lab/Skeleton";
import useInlineDocument from "../../../hooks/useInlineDocument";

const AccountCellDocument = (props) => {
    const {error, value, isLoading} = props;

    const {fileName} = useInlineDocument(value);

    return (
        <>
            {isLoading ? <Skeleton width={"100%"} height={"40px"}/> : (
                <>
                    {fileName}
                    {error && <FormHelperText error>{error}</FormHelperText>}

                </>
            )}
        </>
    );
};

export default AccountCellDocument;

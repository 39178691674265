import React from 'react'
import Balances from "../userSection/content/Balances";

import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const BalancesPage = (props) => {
    const {drawerOuterRef, underBarRef} = props;

    const {t} = useTranslation();
    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <Can I="read" a={"balance"} passThrough>
                        {(can) => {
                            console.log('can', can)
                            return can ? <Balances drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/> : <div>{t("card.noPermissionToRead")}</div>
                        }}
                    </Can>
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    )

}

export default BalancesPage

import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import {Link} from "react-router-dom";

import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const Members = (props) => {
    const {members=[]} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Box mb={"20px"}>
                    <FormLabel component="div">{t("formFields.members")}</FormLabel>
                    <Paper elevation={0}>
                        {members.map(({firstName, lastName, _id}) => {
                            return <Chip
                                key={_id}
                                variant="outlined"
                                className={classes.chip}
                                label={`${firstName} ${lastName}`}
                                component={Link} to={`/users/${_id}`}
                                clickable
                            />
                        })}
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Members;

import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import {removeMessage, closeMessage } from './actions';
import {compose} from "redux";
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {ReactComponent as NotyClose} from "../../assets/notyClose.svg";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    closeBtn:{
        marginRight:9,
        "& .MuiIconButton-label svg":{
            marginRight:0
        },
        padding:"5px"
    }
}));

const Messages = (props) => {
	const classes = useStyles();

	const {messages, removeMessage, closeMessage} = props;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [displayed, setDisplayed] = useState([]);

	const storeDisplayed = (id) => {
		setDisplayed((displayed) => [...displayed, id]);
	};


	const removeDisplayed = (id) => {
		setDisplayed((displayed) => [...displayed.filter(key => id !== key)]);
	};

	useEffect(() => {

		messages.forEach(({ key, message, options = {}, dismissed = false }) => {

			if (dismissed) {
				// dismiss snackbar using notistack
				closeSnackbar(key);
				return;
			}

			// do nothing if snackbar is already displayed
			if (displayed.includes(key)) return;

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
				action: key => (
					<IconButton onClick={() => {closeMessage(key);}} className={classes.closeBtn}>
						<NotyClose/>
					</IconButton>
				),
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey);
					}
				},
				onExited: (event, myKey) => {
					// remove this snackbar from redux store
					removeMessage(myKey);
					removeDisplayed(myKey);
				},
			});

			// keep track of snackbars that we've displayed
			storeDisplayed(key);
		});
	}, [messages]);

	return null;
};

const mapStateToProps = ({messages}) => {
	return {
		messages
	}
}

export default compose(
	connect(mapStateToProps, {closeMessage, removeMessage})
)(Messages);

import React, {useEffect, useState} from 'react';
import {compose} from "redux";

// import TopComponent from "../TopComponent";
import TopComponentInvesthub from "../TopComponentInvesthub"
import {connect} from "react-redux";
import {fetchBranches} from "../actions";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import useMounted from "../../../../../hooks/useMounted";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles, Typography, Portal, Box} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}))


const BranchesTable = (props) => {
    const {t} = useTranslation();
    const {fetchBranches, branches, handleErrorMessage, countries=[], history, match, setMessage, underBarRef, settingsDarkMode} = props;
    const {result, info} = branches;
    const {page, total, pageSize, fields} = info;
    const classes = useStyle();


    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useMounted();

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await fetchBranches();
            setIsLoading(false);
        })();
    },[fetchBranches]);



    const handleChangePage = async (event, newPage) => {
        setIsLoading(true);
        await fetchBranches({
            page:newPage,
        });
        setIsLoading(false);
    }

	const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }

    const onImport = async (formData) => {
        const response = await service.importBranches(formData);
        const {data} = response;
        const {modified, doNotModified} = data;
        const message = t("branch.importInfo", {modified:modified, all:modified+doNotModified});
        setMessage(message, 'success');
        fetchBranches();
    }


    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
        {
            title: t('thead.currency'),
            field: 'currency',
        },
        {
            title: t('thead.country'),
            field: 'country',
            render: ({country}) => {
                const foundCountry = country ? countries.find((countryObj) => countryObj["value"] === country) : undefined;
                return foundCountry ? foundCountry.label : ""
            }
        },
        {
            title: t('thead.enterpriseNumber'),
            field: 'enterpriseNumber',
        },
        {
            title: t('thead.id'),
            field: '_id',
        },
    ];

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>

                </Portal>
            )}
            <div className={`card`}>
                <TopComponentInvesthub history={history} onImport={onImport} settingsDarkMode={settingsDarkMode} />
                <Box className="table-wrap table-wrap--have-line table-wrap--have-px">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={result}
                        onRowClick={rowHandlerClick}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>
            </div>
        </>
    );
};

const mapStateToProps = ({userBranches}) => {
    const {branches} = userBranches;
    return {
        branches
    }
}

export default compose(
    connect(mapStateToProps, {fetchBranches, handleErrorMessage, setMessage})
)(BranchesTable);

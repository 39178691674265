import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./store/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { client } from "./apolloClient";
import { ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const queryClient = new QueryClient();

const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </QueryClientProvider>
    </Provider>,
    document.getElementById("root")
);
serviceWorker.unregister();

import React from 'react';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '15px',
        marginRight:'5px',
        color:'#8A96A0'
    },
    value: {
        fontSize: '20px',
        fontWeight: 300,
    },
    wrapper:{
        color:'#354052',
        marginLeft:'25px',
        '&:first-child':{
            marginLeft:0,
        }
    }
}));


const DashboardPaneTopInfo = (props) => {
    const {label, value, isLoading} = props;

    const classes = useStyles();

    return (
        <Box className={classes.wrapper} component={'span'}>
            {isLoading ? '...' : (
                value && (
                    <>
                        <Box component={'span'} className={classes.label}>{label}</Box>
                        <Box component={'span'} className={classes.value}>{value}</Box>
                    </>
                )
            )}
        </Box>
    );
};

export default DashboardPaneTopInfo;

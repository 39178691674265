import React from 'react';
import {makeStyles} from "@material-ui/core";
import {ReactComponent as DotSVG } from "../../assets/moreHorizontal.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { isDarkModeActive } from '../App/App';

const ActionCircleInvesthub = (props) => {
    const {children, small=false, onClick, onClickAway, isActive=false, settings} = props;

    const styles = {
        actions: {
            position: 'relative',
            display: 'inline-block',
            zIndex: '3',
        },
        actionsButton: {
            position: 'relative',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-secondary)',
            userSelect: 'none',
            cursor: 'pointer',
            border: 'none',
            transition: 'background .2s',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'fill .2s',
                fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--battelship-grey)',
            }
        },
        actionsBody: {
            position: 'absolute',
            top: 'calc(100% + 12px)',
            right: '-12px',
            width: '264px',
            zIndex: '2',
            padding: '12px',
            borderRadius: '16px',
            border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
            boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
            background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
            visibility: 'hidden',
            opacity: '0',
            transform: 'translateY(3px)',
            transition: 'all .2s',
            "&:before": {
                content: `""`,
                position: 'absolute',
                right: '16px',
                bottom: '100%',
                width: '20px',
                height: '10px',
                background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto` : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto`,
            }
        },
        actionsSmall: {
            '& $actionsButton': {
                width: '32px',
                height: '32px',
            },
            '& $actionsButton svg': {
                width: '20px',
                height: '20px'
            }
        },
        actionsActive: {
            '& $actionsBody': {
                opacity: '1',
                visibility: 'visible',
                transform: 'translateY(0)'
            }
        }
    }

    const useStyles = makeStyles(styles);
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });


    return (
        <>
            <ClickAwayListener onClickAway={onClickAway}>
                <div
                    className={`${classes.actions} ${small ? classes.actionsSmall : ''} ${isActive ? classes.actionsActive : ''}`}
                >
                    <button
                        type="button"
                        className={classes.actionsButton}
                        onClick={onClick}
                    >
                        <DotSVG />
                    </button>

                    <div className={classes.actionsBody}>
                        {children}
                    </div>
                </div>
            </ClickAwayListener>
        </>
    )
}

export default ActionCircleInvesthub;

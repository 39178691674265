import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../Messages/actions";
import FilterFormControl from "../../FilterFormControl";
import SelectField from "../../../../formElements/SelectField";
import Api from "../../../../../services/api";
const service = new Api();

const UserStatusSelectField = (props) => {

    const dispatch = useDispatch();

    const [statuses, setStatuses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUserStatuses = async () => {
        try{
            setIsLoading(true);
            const userStatusesResponse = await service.getServiceData({dataFor:"userStatuses"});

            const {statuses} = userStatusesResponse.data;
            const mappedStatuses = statuses.map((status) => ({label:status, value:status}));
            setStatuses(mappedStatuses);

        }catch (e) {
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchUserStatuses();
    },[]);

    return (
        <FilterFormControl>
            <SelectField
                {...props}
                options={statuses}
                isLoading={isLoading}
            />
        </FilterFormControl>
    );
};

export default UserStatusSelectField;

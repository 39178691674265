import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import Api from "../../../../../services/api";
import useForm2 from "../../../../../hooks/useForm2";
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import ExpenseReportTemplateForm from "../../../../ExpenseReportTemplateForm";
const service = new Api();

const ExpenseReportTemplate = (props) => {
    const {underBarRef, match, history} = props;
    const {params} = match;
    const {id} = params;

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();

    const [expenseReportTemplate, setExpenseReportTemplate] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);


    const fetchExpenseReportTemplate = async (id) => {
        try {
            setIsLoading(true);
            const response = await service.getExpenseReportTemplate(id);
            setExpenseReportTemplate(response.data);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchExpenseReportTemplate(id);
    },[id]);


    const submit = async () => {

        try{
            setIsSubmitting(true);
            await service.updateExpenseReportTemplates(id, values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/reports/templates");
        }catch (e) {
            console.log(e);
            setIsSubmitting(false);
            setServerErrors(e);
        }
    }

    const validate = (values) => {
        const errors = {};
        if(!values["name"]){
            errors["name"] = t("errors.required", {field:"$t(formFields.name)"});
        }

        return errors;
    }

    const onDeleteAction = async () => {
        setIsSubmitting(true);
        try {
            await service.deleteExpenseReportTemplate(id);
            dispatch(setMessage("success", 'success'));
            history.push("/reports/templates");

        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    useEffect(() => {
        if(Object.keys(expenseReportTemplate).length){
            const {name, fields} = expenseReportTemplate;
            fillFormValues({name, fields});
        }
    },[expenseReportTemplate]);

    return (
        <Box paddingLeft={'24px'} paddingRight={'24px'}>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <BackButton/>
                </Portal>
            )}
            <Box mb={"20px"}>
                <ExpenseReportTemplateForm
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    loading={loading}
                />
            </Box>
            <Grid container spacing={2}>
                <Can I="update" a={"expenseReportTemplate"}>
                    <Grid item>
                        <Button onClick={handleSubmit} disabled={isSubmitting || loading} color="primary" variant="contained">
                            {t("formFields.update")}
                        </Button>
                    </Grid>
                </Can>
                <Can I="delete" a={"expenseReportTemplate"}>
                    <Grid item>
                        <Button onClick={onDeleteAction} disabled={isSubmitting || loading} color="secondary" variant="contained">
                            {t("formFields.delete")}
                        </Button>
                    </Grid>
                </Can>
            </Grid>
        </Box>
    );
};

export default ExpenseReportTemplate;

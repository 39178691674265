import React from "react";
import { withRouter } from "react-router-dom";
import ContactsComponent from "./ContactsComponent";

const ContactsPage = (props) => {
    const { match, settingsDarkMode, ...restProps} = props;
    return <ContactsComponent {...restProps} settingsDarkMode={settingsDarkMode} />;
};

export default withRouter(ContactsPage);

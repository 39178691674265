import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DialogPopUp from "../../../../../../DialogPopUp";

import useForm2 from "../../../../../../../hooks/useForm2";
import SelectField from "../../../../../../formElements/SelectField";
import Api from "../../../../../../../services/api";
import { useHistory } from "react-router-dom";
import {setMessage} from "../../../../../../Messages/actions";
import {useDispatch} from "react-redux";

const service = new Api();

const useStyles = makeStyles(theme => ({
            content: {
                width: "300px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px",
                marginLeft:"auto",
                marginRight:"auto"
            },
        }
    )
);


const RejectDialog = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {setOpen, open, onReject} = props;
    const [isSending, setIsSending] = useState(false);
    const [rejectReasons, setRejectReasons] = useState([]);
    const rejectReasonsLength = rejectReasons.length;
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if(open && !rejectReasonsLength){
            fetchReasons();
        }
    },[open, rejectReasonsLength]);

    const fetchReasons = async () => {
        try {
            setIsLoading(true);
            const response = await service.getRejectReasonsAll();
            const mappedRejectReasons = response.data.rejectReasons.map(({_id, name}) => ({value:_id, label:name}));
            setRejectReasons(mappedRejectReasons);
        }catch (e) {
            console.log(e)
        }finally {
            setIsLoading(false);
        }
    }

    const submit = async () => {
        try{
            setIsSending(true);
            await onReject(values);
            setIsSending(false);

            history.push("/transactions");
        }catch (e) {
            setIsSending(false);
            console.log(e);
            setServerErrors(e);
        }

    }

    const validate = (values) => {
        let errors = {};
        if (!values["rejectReason"]) {
            errors["rejectReason"] = t('errors.required', {field: t("formFields.rejectReason")});
        }
        return errors;
    };

    const {handleChange, handleSubmit, values, fillFormValues, errors, setServerErrors, resetErrors} = useForm2(
        submit,
        validate
    );

    const onExited = () => {
        fillFormValues({});
        resetErrors();

    }

    const onClose = () => {
        setOpen(false)
    }

    return (
            <DialogPopUp
                open={open}
                onClose={onClose}
                aria-labelledby="reject-title"
                title={t("formFields.reject")}
                onExited={onExited}
            >
                <DialogContent className={classes.content}>
                    <FormControl fullWidth>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={!!errors["rejectReason"]}>
                                    <SelectField
                                        label={t("formFields.rejectReason")}
                                        name={"rejectReason"}
                                        disabled={isSending}
                                        isLoading={isLoading}
                                        value={values["rejectReason"]}
                                        error={errors["rejectReason"]}
                                        options={rejectReasons}
                                        onSelectChange={(val) => handleChange("rejectReason", val)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSending}>
                        {t("formFields.reject")}
                    </Button>
                    <Button onClick={() => setOpen(false)} color="secondary" variant="contained" disabled={isSending}>
                        {t("formFields.cancel")}
                    </Button>
                </DialogActions>
            </DialogPopUp>
    );
};

export default RejectDialog;

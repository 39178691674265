import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles, Box} from "@material-ui/core";
import {Can} from "../../../../../Context/Can";
import AddBranchBtn from './AddBranchBtn';

const useStyle = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    headControl: {
        display: 'flex',
        marginLeft: 'auto',
    },
}))

const TopComponent = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();
    const {onImport, history, settingsDarkMode} = props;

    return (
        <Box className={classes.head}>
            <Box className={`${classes.headTitle} card-title card-title--blue`}>
                {t("branch.mainTitle")}
            </Box>

            <Box className={classes.control}>
                <Can I="create" a={"branch"}>
                    <AddBranchBtn history={history} onImport={onImport} settingsDarkMode={settingsDarkMode} />
                </Can>
            </Box>
        </Box>
    );
};

export default TopComponent;

import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux'
import Api from "../../services/api";
import {getLoggedUser} from "../User/actions";
import {handleErrorMessage, setMessage} from "../Messages/actions";
import setAuthToken from "../../helpers/setAuthToken";
import useForm from "../../hooks/useForm";
import {FormControl, InputAdornment} from "@material-ui/core";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import TermsAndConditionsField from "../TermsAndConditionsField";
import getEmailRegex from "../../helpers/getEmailRegex";
import getPasswordRegex from "../../helpers/getPasswordRegex";
import PhoneField from "../formElements/PhoneField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ReactComponent as Logo} from '../../assets/logoVolve.svg'
// import { ReactComponent as LogoPaladis} from '../../assets/paladis.svg'
import { ReactComponent as IconVolve} from '../../assets/iconEvolveWhite.svg'
import { ReactComponent as UserSVG } from "../../assets/userCircle.svg"
import { ReactComponent as MailSVG } from "../../assets/mail.svg"
import { ReactComponent as LockSVG } from "../../assets/lock.svg"
import { getExternalFileUrl } from "../../helpers/file"

const service = new Api();

const useStyles = makeStyles(theme => ({
    pageTitle: {
        fontSize: "35px",
        lineHeight: "49px",
        marginBottom: "30px"
    },
    form: {
        width: "570px",
        maxWidth: "100%",
        paddingTop:"10px"
    },
    formControl: {
        marginBottom: "20px"
    },

    entry: {
        minHeight: 'calc(var(--vh, 1vh) * 100)',
        background: 'var(--pale-grey)',
        display: 'flex',
        position: 'relative',
    },
    entryCol: {
        padding: '96px 24px',
    },
    entryColFirst: {
        flexShrink: '0',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        display: 'flex',
    },
    entryColSecond: {
        flexGrow: '1',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    entryWrap: {
        maxWidth: '212px'
    },
    entryPreview: {
        maxWidth: '180px',
        margin: '0 auto 40px -32px',
        '& svg': {
            width: '100%',
            height: 'auto'
        }
    },
    entryLogo: {
        zIndex: '2',
        width: '48px',
        display: 'inline-block',
        position: 'absolute',
        top: '24px',
        left: '24px',

        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entryInfo: {
        color: 'var(--battelship-grey)',
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '1.23077',
        position: 'absolute',
        top: '40px',
        right: '40px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '80px'
        },
        [theme.breakpoints.down('767')]: {
            top: '32px',
            right: '24px'
        },
        '& a': {
            color: 'var(--dark-secondary)',
            fontWeight: '600',
            transition: 'color .2s'
        },
        '& a:hover': {
            color: 'var(--clear-blue-primary)'
        }
    },
    entryWrapper: {
        maxWidth: '618px'
    },
    entryForm: {
        display: 'flex',
        margin: '0 -8px',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('767')]: {
            display: 'block',
            margin: '0'
        }
    },
    entryFormField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0'
        },
        '&:not(:last-child)': {
            marginBottom: '16px',
        }
    },
    entryFormSubmit: {
        display: 'block',
        width: 'calc(100% - 16px)',
        flex: '0 0 calc(100% - 16px)',
        margin: '0 8px',
        [theme.breakpoints.down('767')]: {
            margin: '0'
        }
    },
    btn: {
        background: '#265dbd'
    }

}));


const InvitationForm = (props) => {
    const {handleErrorMessage, setMessage, match, publicSettings} = props;
    const {hash} = match.params;
    const [isLoading, setIsLoading] = useState(false);

    const {t} = useTranslation();

    const validate = (values) => {
        const emailRegex = getEmailRegex();
        const passwordRegex = getPasswordRegex();

        const errors = {};

        if (!values.email) {
            errors.email = t('errors.required', {field: t("formFields.email")});
        }

        if (!values.password) {
            errors.password = t('errors.required', {field: t("formFields.password")});
        }
        if(values.password && !values.password.toString().match(passwordRegex)){
            errors.password = t('errors.passwordNotValid');
        }
        if (!values.firstName) {
            errors.firstName = t('errors.required', {field: t("formFields.firstName")});
        }
        if (!values.lastName) {
            errors.lastName = t('errors.required', {field: t("formFields.lastName")});
        }
        if (!values.phone) {
            errors.phone = t('errors.required', {field: t("formFields.phone")});
        }

        if (!values.password_repeat) {
            errors.password_repeat = t('errors.required', {field: t("formFields.repeatPassword")});
        }

        if((values.password_repeat && values.password) && (values.password_repeat !== values.password)){
            errors.password = t("errors.passwordsAreDifferent");
            errors.password_repeat =  t("errors.passwordsAreDifferent");
        }

        if (!emailRegex.test(values["email"])) {
            errors["email"] =  t("errors.notValid", {field:"$t(formFields.email)"});
        }
        if(!values.termsAndConditions){
            errors.termsAndConditions = t('errors.required', {field: t("formFields.termsOfService")});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    async function submit(){
        setIsLoading(true);
        try {

            const { firstName, lastName, password, email, phone} = values;
            const response = await service.updateInvitedUser({firstName, hash, lastName, password, email, phone});
            const {expires, token} = response.data;

            localStorage.setItem('token', token);
            localStorage.setItem('expires', expires);
            setAuthToken(token);
            props.getLoggedUser();

            setMessage(t("success"), 'success');
            setIsLoading(false);
        } catch (e) {
            setServerErrors(e);
            setIsLoading(false);
        }
    }

    const [invitedUser, setInvitedUser] = useState({});
    const [company, setCompany] = useState({});
    const [createdBy, setCreatedBy] = useState({});

    useEffect(() => {
        service.getInvitedUser(hash).then((resp) => {
            const {user} = resp.data;
            handleChange('email', user.email);
            handleChange('firstName', user.firstName);
            handleChange('lastName', user.lastName);

            const {company, createdBy, ...userData} = user;
            setCompany(company);
            setInvitedUser(userData);
            setCreatedBy(createdBy);

        }).catch((e) => {
            handleErrorMessage(e)
        })
    },[]);


    const classes = useStyles();


    if(!invitedUser.email){
        // if no user - return empty block
        return <></>
    }

    return (
        <div className={classes.entry}>
            <div className={`bg-color-black tablet-hide ${classes.entryCol} ${classes.entryColFirst}`}>
                <div className={classes.entryWrap}>
                    <div className={classes.entryPreview}>
                        {
                            publicSettings && publicSettings.logo_path &&
                                <img
                                    className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                                    src={getExternalFileUrl(publicSettings.kycHost, publicSettings.logo_path)}
                                    alt="logo"
                                />
                        }
                        { (!publicSettings || !publicSettings.logo_path) &&
                            <Logo />
                        }
                    </div>
                </div>
            </div>

            <div className={`${classes.entryCol} ${classes.entryColSecond}`}>
                <div className={classes.entryHead}>
                    <Link href="/" className={classes.entryLogo}>
                        {
                            publicSettings && publicSettings.logo_path &&
                                <img
                                    className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                                    src={getExternalFileUrl(publicSettings.kycHost, publicSettings.logo_path)}
                                    alt="logo"
                                />
                        }
                        { (!publicSettings || !publicSettings.logo_path) &&
                            <Logo />
                        }
                    </Link>

                    <div className={classes.entryInfo}>
                        {t("hasAccount")}
                        {' '}
                        <Link href="/">
                            {t("logIn")}
                        </Link>
                    </div>
                </div>

                <div className={classes.entryWrapper}>
                    <div className={`h4 text-weight-200 margin-bottom-40`}>
                        {t("helloInvited", {name:invitedUser.firstName})}<br/>
                        {createdBy ? (
                            t("paladisInvitedText", {createdBy:createdBy.firstName, createdByEmail:createdBy.email, company:company.name})
                        ) : (
                            t("paladisInvitedTextNoCreatedBy", {company:company.name})
                        )}
                    </div>

                    <form onSubmit={handleSubmit} noValidate className={classes.entryForm}>
                        <FormControl fullWidth error={!!errors.firstName} className={classes.entryFormField}>
                            <InputField
                                name="firstName"
                                placeholder={t("formFields.firstName")}
                                value={values["firstName"] ? values["firstName"] : ""}
                                onInputChange={(val) => handleChange("firstName", val)}
                                error={errors["firstName"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><UserSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.lastName} className={classes.entryFormField}>
                            <InputField
                                name="lastName"
                                placeholder={t("formFields.lastName")}
                                value={values["lastName"]}
                                onInputChange={(val) => handleChange("lastName", val)}
                                error={errors["lastName"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><UserSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.email} className={classes.entryFormField}>
                            <InputField
                                name="email"
                                placeholder={t("formFields.email")}
                                type="email"
                                disabled
                                value={values["email"]}
                                onInputChange={(val) => handleChange("email", val)}
                                error={errors["email"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><MailSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.phone} className={classes.entryFormField}>
                            <PhoneField
                                id="phone"
                                name="phone"
                                type="text"
                                error={errors.phone}
                                value={values["phone"]}
                                onChangeHandler={(val) => handleChange("phone", val)}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.password} className={classes.entryFormField}>
                            <InputField
                                name="password"
                                placeholder={t("formFields.password")}
                                type="password"
                                value={values["password"]}
                                onInputChange={(val) => handleChange("password", val)}
                                error={errors["password"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LockSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth error={!!errors.password_repeat} className={classes.entryFormField}>
                            <InputField
                                name="password_repeat"
                                placeholder={t("formFields.repeatPassword")}
                                type="password"
                                value={values["password_repeat"]}
                                onInputChange={(val) => handleChange("password_repeat", val)}
                                error={errors["password_repeat"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LockSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth error={!!errors.termsAndConditions} className={`${classes.entryFormField} ${classes.entryTermsAndCondition}`}>
                            <TermsAndConditionsField values={values} errors={errors} handleChange={handleChange}/>
                        </FormControl>

                        <FormControl fullWidth className={`${classes.entryFormSubmit}`}>
                            <Button
                                className={`${classes.btn}`}
                                variant="contained"
                                type="submit"
                                disabled={isLoading}
                            >
                                {t("formFields.continue")}
                            </Button>
                        </FormControl>
                    </form>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({publicSettings}) => {
    return { publicSettings }
}

export default connect(
    mapStateToProps,
    {getLoggedUser, handleErrorMessage, setMessage}
)(InvitationForm);

import React, {useEffect, useState} from 'react';
import LazyImageSuspense from "../LazyImageSuspense";

const Img = (props) => {

    const {src, lazy, ...restProps} = props;

    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        if(src){
            if(src.startsWith("blob:")){
                // uploaded directly now from browser
                setImageSrc(src)
            }else{
                if(src){
                    setImageSrc(src);
                }
            }
        }

    },[src]);

    return (
        lazy ? (
            <LazyImageSuspense src={imageSrc} {...restProps} />
        ) : (
            <img src={src} {...restProps}/>
        )
    )
};

export default Img;

import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";

import DialogPopUp from "../../../../../../../../DialogPopUp";
import Api from "../../../../../../../../../services/api";
import DateField from "../../../../../../../../formElements/DateField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";
import {handleErrorMessage} from "../../../../../../../../Messages/actions";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
const service = new Api();


const useStyles = makeStyles(theme => (
        {
            button:{
                fontWeight: 400,
                marginTop: "2px",
                paddingLeft:"25px",
                paddingRight:"25px"
            },
            inputWrapper: {
                width: "100%"
            },
            fieldsWrapper:{
                width:"400px",
                maxWidth:"100%",
                margin:"0 auto",
                paddingTop:"20px",
                paddingBottom:"20px"
            }
        }
    )
);



const GetStatement = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const dateFormat = useSelector(state => {
        const {settings={}} = state.user;
        const {regional={}} = settings;
        return regional.dateFormat
    });

    const match = useRouteMatch();
    const {id} = match.params;

    const [open, setOpen] = useState(false);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);



    useEffect(() => {
        if(!fromDate){
            setIsDisabled(true); // disable download button if from range is not set
        }else{
            setIsDisabled(false);
        }

    },[fromDate]);

    const onGetAccountStatement = async (ext) => {

        const formDate = {ext};

        if(fromDate) formDate["date[gte]"] = fromDate;
        if(toDate) formDate["date[lte]"] = toDate;

        setLoading(true);
        try {
            await service.getAccountStatement(id, formDate);
            setFromDate(null);
            setToDate(null);
            setOpen(false);
        }catch (e) {
                dispatch(handleErrorMessage(e));
        }finally {
            setLoading(false);
        }

    }


    return (
        <>
            <Button onClick={() => setOpen(true)} className={"btn-stroke btn-small"}>
                {t("formFields.getStatement")}
            </Button>
            <DialogPopUp
                onClose={() => setOpen(false)}
                open={open}
                title={t("formFields.getStatement")}
            >
                <DialogContent>
                    <div className={classes.fieldsWrapper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth className={classes.inputWrapper}>
                                    <DateField
                                        label={t("formFields.dateFrom")}
                                        placeholder={t("formFields.date")}
                                        dateFormat={dateFormat}
                                        onDateChange={(date) => setFromDate(date ? date.set({hour: 0, minute: 0, second: 0, millisecond: 0}) : null)}
                                        value={fromDate}
                                        name="gte"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change from',
                                        }}
                                    />
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth className={classes.inputWrapper}>
                                    <DateField
                                        label={t("formFields.dateTo")}
                                        placeholder={t("formFields.date")}
                                        dateFormat={dateFormat}
                                        onDateChange={(date) => setToDate(date ? date.set({hour: 23, minute: 59, second: 59, millisecond: 59}) : null)}
                                        value={toDate}
                                        name="lte"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change from',
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onGetAccountStatement("pdf")}
                            variant="contained"
                            disabled={loading || isDisabled}
                    >
                        {t("formFields.getStatementPdf")}
                    </Button>
                    <Button onClick={() => onGetAccountStatement("xlsx")}
                            variant="contained"
                            disabled={loading || isDisabled}
                    >
                        {t("formFields.getStatementExcel")}
                    </Button>
                </DialogActions>
            </DialogPopUp>
        </>
    );
};

export default GetStatement;

import React, {useEffect, useState} from 'react';
import SelectSearch from "../../../../formElements/SelectSearch";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Api from "../../../../../services/api";
import {FormLabel, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import getShortPan from "../../../../../helpers/getShortPan";
import {handleErrorMessage} from "../../../../Messages/actions";
import getCardName from "../../../../../helpers/getCardName";
const service = new Api();


const useStyles = makeStyles(theme => ({
    selectSearchWrapper: {
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child':{
            padding:0
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]':{
            paddingBottom:0
        },
        '& .MuiFormLabel-root + div': {
            marginTop:'12px'
        }
    }

}));

const SelectCard = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const tabsTransactions = useSelector(state => state.tabs["transactions"]);

    const {value, handleChange} = props;

    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchCards = async (tab) => {
        try {
            setIsLoading(true);
            const response = await service.getCardsList(tab);
            const mappedCards = response.data.cards.map((card) => {
                const {_id} = card;
                return {
                    value: _id,
                    label: getCardName(card)
                }
            });

            setCards(mappedCards)

        } catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e))
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchCards(tabsTransactions.active);
        handleChange('card',); // reset card
    },[tabsTransactions]);


    return (
        <Box className={classes.selectSearchWrapper}>
            <FormLabel>{t('card.selectCardLabel')}</FormLabel>
            <SelectSearch
                options={cards}
                value={value ? value : ""}
                inputHandler={(val) => handleChange('card', val ? val.value : undefined)}
                placeholder={t("card.selectCardPlaceholder")}
                isLoading={isLoading}
            />
        </Box>
    );
};

export default SelectCard;

import React, { useState } from "react";
import {
    withRouter,
    // eslint-disable-next-line no-unused-vars
    Route,
    // eslint-disable-next-line no-unused-vars
    Switch,
    // eslint-disable-next-line no-unused-vars
    matchPath
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// eslint-disable-next-line no-unused-vars
import UnderCardDockInvesthub from "../../../../UnderCardDockInvesthub";
// eslint-disable-next-line no-unused-vars
import SelectTab from "../../../../SelectTab";
import useMounted from "../../../../../hooks/useMounted";
import { Button, makeStyles, Portal, Typography, Box } from "@material-ui/core";
import ContactPopup from "./ContactPopup";
import ContactsTable from "./ContactsTable";
import { useEffect } from "react";
import { handleErrorMessage } from "../../../../Messages/actions";
import Api from "../../../../../services/api";
import { isDarkModeActive } from "../../../../App/App";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
        "& .MuiButton-root": {
            [theme.breakpoints.down('767')]: {
                width: '100%',
            }
        }
    },
}));
const ContactsComponent = (props) => {
    const {
        // eslint-disable-next-line no-unused-vars
        match,
        location,
        drawerOuterRef,
        underBarRef,
        settingsDarkMode } = props;
    // eslint-disable-next-line no-unused-vars
    const { pathname } = location;
    const isMounted = useMounted();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const { t } = useTranslation();
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const handleOpenContactPopUp = (val) => {
        setIsOpenCreateModal(true);
    };
    const [countries, setCountries] = useState([]);
    const dispatch = useDispatch();

    const fetchStaticData = async () => {
        try {
            const countriesPromise = await service.getServiceData({
                dataFor: "countries",
            });
            const mappedCountries = countriesPromise.data.map((country) => ({
                label: country.name,
                value: country["country-code"],
            }));
            setCountries(mappedCountries);
        } catch (e) {
            dispatch(handleErrorMessage(e));
        }
    };
    useEffect(() => {
        fetchStaticData();
    }, []);
    return (
        <>
            {isMounted && (
                <>
                    <Portal container={drawerOuterRef.current}></Portal>
                    <Portal container={underBarRef.current}>
                        <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                            {t("contact.mainTitle")}
                        </Typography>
                    </Portal>
                </>
            )}

            <Box className={`card`}>
                <Box className={classes.head}>
                    <Button onClick={handleOpenContactPopUp} className="btn-small">Add Contact</Button>
                </Box>

                <ContactPopup
                    isOpenCreateModal={isOpenCreateModal}
                    setIsOpenCreateModal={setIsOpenCreateModal}
                    countries={countries}
                    shouldRefresh={shouldRefresh}
                    setShouldRefresh={setShouldRefresh}
                    settingsDarkMode={settingsDarkMode}
                />

                <Box className="table-wrap table-wrap--have-line table-wrap--have-mx-offset">
                    <ContactsTable
                        drawerOuterRef={drawerOuterRef}
                        underBarRef={underBarRef}
                        countries={countries}
                        shouldRefresh={shouldRefresh}
                        setShouldRefresh={setShouldRefresh}
                    />
                </Box>
            </Box>
        </>
    );
};

export default withRouter(ContactsComponent);

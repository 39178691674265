import React from 'react';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core";
import { FormLabel } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as EditSVG} from "../../../assets/editInvesthub.svg"
import {ReactComponent as CloseSVG} from "../../../assets/closeInvesthub.svg"
import useInlineDocument from "../../../hooks/useInlineDocument";
import { isDarkModeActive } from '../../App/App';

const useStyles = makeStyles(theme => ({
    file: {
        // marginBottom: '14px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
        // color: '--charcoal-primary'
    },
    fileWrap: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        borderRadius: '12px',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
        overflow: 'hidden',
        '& .MuiButton-root': {
            display: 'flex',
            height: '48px',
            padding: '0 20px',
            border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary) !important' : '2px solid var(--very-light-pink-secondary) !important',
            background: (props) => props.isDarkModeActive ? 'var(--dark-secondary)' : 'var(--pale-grey)',
            boxShadow: (props) => props.isDarkModeActive ? `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 12px 13px -6px rgba(0, 0, 0, 0.04), inset 0px 2px 4px #31353B` : `0px 12px 13px -6px rgba(0, 0, 0, 0.04)`,
            fontSize: '15px',
            fontWeight: '700',
            letterSpacing: '0',
            lineHeight: '1.6',
            transition: 'border-color .2s',
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
        },
        '& .MuiButton-root:hover': {
            // borderColor: 'var(--battelship-grey)'
        },
        '& .MuiFormHelperText-root': {
            fontFamily: `'Inter', sans-serif`,
        }
    },
    fileWrapFill: {
        display: 'flex',
        paddingBottom: '16px',

        '&:not(:last-child)': {
            marginBottom: '32px',
            borderBottom: '1px solid var(--very-light-pink-secondary)',
        }
    },
    fileWrapContent: {
        marginRight: '24px',
    },
    fileWrapActions: {
        display: 'inline-flex',
        gap: '12px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        marginLeft: 'auto'
    },
    fileWrapActionsIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: '0',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        backgroundColor: 'var(--very-light-pink-secondary)',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0',
        "& svg": {
            width: '20px',
            height: '20px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
        },
        "&:hover svg": {
            fill: 'var(--clear-blue-primary)'
        }
    },
    deleteIcon: {
        background: 'var(--very-light-pink-tertiary)',
        "& svg": {
            fill: 'var(--orange-pink)'
        },
        "&:hover svg": {
            fill: 'var(--dark-secondary)'
        }
    }
}))

const FileUploaderInlineInvesthub = (props) => {
    const {
        label,
        value,
        error,
        disabled,
        handleChange,
        fieldName,
        acceptedDocuments=[".png",".jpeg",".jpg",".pdf"],
        buttonLabel="formFields.uploadDocument",
        settingsDarkMode
    } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    const handleUpload = (e) => {
        if(e.target.files && e.target.files[0]){
            handleChange(fieldName, e.target.files[0]);
        }else{
            handleChange(fieldName);
        }
    };

    const onDelete = () => {
        handleChange(fieldName, undefined)
    }

    const {onDownloadDocument, isDownloading, fileName} = useInlineDocument(value);

    return (
        <div className={classes.file}>
            {label && (<FormLabel className={classes.fileLabel}>{label}</FormLabel>)}
            {fileName ? (
                <div className={classes.fileWrapFill}>
                    <div className={classes.fileWrapContent}>
                        <Button
                            onClick={() => onDownloadDocument()}
                            disabled={isDownloading || disabled}
                            className="btn-stroke btn-small"
                            disableTouchRipple
                        >
                            {fileName}
                        </Button>
                    </div>
                    <div className={classes.fileWrapActions}>
                        <IconButton
                            edge="end"
                            onClick={() => onDelete()}
                            className={`${classes.fileWrapActionsIcon} ${classes.deleteIcon}`}
                            disabled={isDownloading || disabled}
                        >
                            <CloseSVG />
                        </IconButton>
                        <IconButton
                            edge="end"
                            component="label"
                            variant={"contained"}
                            disabled={isDownloading || disabled}
                            className={classes.fileWrapActionsIcon}
                        >
                            <input type="file"
                                    name={fieldName}
                                    accept={acceptedDocuments.join(",")}
                                    onChange={handleUpload}
                                    style={{display: "none"}}
                            />
                            <EditSVG />
                        </IconButton>
                    </div>
                </div>
            ) : (
                <>
                    <div className={classes.fileWrap}>
                        <Button
                            component="label"
                            disableTouchRipple
                            disabled={disabled}
                            className={classes.uploadBtn}
                        >
                            <input type="file"
                                    name={fieldName}
                                    accept={acceptedDocuments.join(",")}
                                    onChange={handleUpload}
                                    style={{display: "none"}}
                            />
                            {t(buttonLabel)}
                        </Button>
                    </div>
                    <FormHelperText className={classes.acceptedFormats}>{t("formFields.acceptedFormats")} {acceptedDocuments.join(", ").toUpperCase()}</FormHelperText>
                </>
            )}
            {!!error && <FormHelperText error>{error}</FormHelperText>}
        </div>
    )
}

export default FileUploaderInlineInvesthub

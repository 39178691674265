import React, {useState} from 'react'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import { isDarkModeActive } from '../../../App/App';

const User = (props) => {
	const useStyles = makeStyles(theme => ({
        headerUser: {
            position: 'relative',
            [theme.breakpoints.down('767')]: {
                position: 'static'
            },
            '&.active': {
                visibility: 'visible',
                opacity: '1',
                transform: 'translateY(0)',
            }
        },
        headerHead: {
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            cursor: 'pointer',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '50%'
            },
            '& .MuiAvatar-root': {
                width: '48px',
                height: '48px',
                backgroundColor: 'var(--light-periwinkle)',
                color: 'var(--dark-secondary)'
            }
        },
        headerBody: {
            position: 'absolute',
            top: 'calc(100% + 18px)',
            border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
            borderRadius: '16px',
            boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
            background: (props) => props.isDarkModeActive ? 'var(--almost-black)': 'var(--white)',
            visibility: 'visible',
            opacity: '1',
            transform: 'translateY(3px)',
            transition: 'all .2s',
            width: '280px',
            padding: '16px',
            right: '-20px',
            zIndex: '10',
            [theme.breakpoints.down('1339')]: {
                top: 'calc(100% + 15px)',
                right: '0',
                borderRadius: '12px'
            },
            [theme.breakpoints.down('767')]: {
                top: 'calc(100% - 72px)',
                left: '16px',
                right: '16px',
                width: 'auto',
                padding: '12px 16px 20px'
            },
            '&:before': {
                content: '""',
                position: 'absolute',
                bottom: '100%',
                width: '20px',
                height: '10px',
                background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E") no-repeat 50% 50%/100%auto` : `url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E\") no-repeat 50% 50%/100% auto`,
                right: '33px',
                [theme.breakpoints.down('1339')]: {
                    right: '13px'
                },
                [theme.breakpoints.down('767')]: {
                    right: '21px'
                }
            }
        },
        headerNav: {

            '&:not(:last-child)': {
                marginBottom: '12px',
                paddingBottom: '12px',
                borderBottom: '1px solid var(--very-light-pink-secondary)',
                [theme.breakpoints.down('767')]:{
                    marginBottom: '8px',
                    paddingBottom: '8px'
                }
            }
        },
        headerLink: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '48px',
            padding: '0 12px',
            borderRadius: '12px',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'all .2s',
            '&:hover':{
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
            }
        },
		svg:{
			'& path':{
				fill:'#8d99a2',
				[theme.breakpoints.down('sm')]: {
					fill:'#fff',
				},
			}
		},
		link:{
			textDecoration:'none',
			color:'#000'
		},
		avatar:{
			padding:"5px"
		}
	}));

	const {firstName="", lastName="", avatarUrl="", settings} = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
	const {t} = useTranslation();

    const [userShow, setUserShow] = useState({
        isUserShowActive: false
    })

    const handleUserClick = () => {
        setUserShow({ ...userShow, isUserShowActive: true })
    }

    const handleUserClickAway = () => {
        setUserShow({ ...userShow, isUserShowActive: false})
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleUserClickAway}>
                <div className={`${classes.headerUser}`}>
                    <div
                        onClick={handleUserClick}
                        className={`tap-highlight ${classes.headerHead}`}
                    >
                        <Avatar
                            alt={firstName && firstName}
                            src={
                                avatarUrl.startsWith("http")
                                    ? avatarUrl
                                    : `${window._env_.AWS_PUBLIC_BUCKET_URL}/${avatarUrl}`
                            }
                            title={`${firstName} ${lastName}`}
                        />
                    </div>

                    {userShow?.isUserShowActive ? (
                        <div className={classes.headerBody}>
                            <div className={classes.headerNav}>
                                <NavLink
                                    to={`/user/profile`}
                                    className={classes.headerLink}
                                >
                                    {t("user.profile")}
                                </NavLink>
                                <NavLink
                                    to="/logout"
                                    className={classes.headerLink}
                                >
                                    {t("user.logout")}
                                </NavLink>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </ClickAwayListener>
        </>
    );
}
const mapStateToProps = (state) => {
	const {userData={}} = state.user;

	const {firstName, lastName, avatarUrl} = userData;
	return {
		firstName,
		lastName,
		avatarUrl
	}
}
export default connect(
	mapStateToProps
)(withRouter(User))

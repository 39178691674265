import Api from '../../../../services/api'
import * as actionTypes from '../../../../store/actionTypes'

import {handleErrorMessage} from "../../../Messages/actions";

const fetchAccounts = (dataQuery) => {
	return async dispatch => {
		const {getAccounts} = new Api();
		try {
			const response = await getAccounts(dataQuery);
			const {data} = response;
			const {result, info} = data;
			dispatch({type:actionTypes.ACCOUNTS_FETCH_SUCCESS, payload:{result, info}});
		}catch (e) {
			dispatch(handleErrorMessage(e));
			dispatch({type:actionTypes.ACCOUNTS_FETCH_FAILURE})
		}
	}
};

export {
	fetchAccounts
}
import React, {useEffect, useState} from 'react';

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import {FormControl, makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useForm2 from "../../../../../../../hooks/useForm2";
import DialogPopUp from "../../../../../../DialogPopUp";
import InputField from "../../../../../../formElements/InputField";
import SelectField from "../../../../../../formElements/SelectField";
import RadioFields from "../../../../../../formElements/RadioFields";
import Grid from "@material-ui/core/Grid";
import Api from "../../../../../../../services/api";
import getEmailRegex from "../../../../../../../helpers/getEmailRegex";
const service = new Api();

const useStyles = makeStyles(theme => ({
            content: {
                width: "880px",
                maxWidth: "100%",
                paddingTop: 0,
                padding:"25px 40px 28px 40px"
            },
            formControlWrapper: {
                paddingRight: "10px",
                paddingLeft: "10px"
            }
        }
    )
);


const InvitePersonPopup = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const emailRegex = getEmailRegex();
    const {setMessage, isOpen, setIsOpen, outerValues, index, memoizedCallback, onSave, personTypes, titles} = props;
    const [isLoading, setIsLoading] = useState(false);

    const submit = async () => {


        const {personType, firstName, lastName, title, email} = values;

        try {
            const response = await service.createInvitedUser({personType, firstName, lastName, title, email});
            setIsLoading(false);

            // change parent state
            memoizedCallback(`officersAndShareholders[${index}][title]`, title);
            memoizedCallback(`officersAndShareholders[${index}][firstName]`, firstName);
            memoizedCallback(`officersAndShareholders[${index}][lastName]`, lastName);
            memoizedCallback(`officersAndShareholders[${index}][personType]`, personType);
            memoizedCallback(`officersAndShareholders[${index}][email]`, email);
            memoizedCallback(`officersAndShareholders[${index}][user]`, response.data.user.id);
            setMessage(t("emailWasSentTo", {email:response.data.user.email}), 'success');
            onSave(index);

        }catch (e) {
            setIsLoading(false);
            setServerErrors(e);
        }


    }

    const validate = (values) => {

        const errors = {};
        if (!values[`title`]) {
            errors[`title`] = t("errors.required", {field: "$t(formFields.title)"});
        }
        if (!values[`firstName`]) {
            errors[`firstName`] = t("errors.required", {field: "$t(formFields.firstName)"});
        }
        if (!values[`lastName`]) {
            errors[`lastName`] = t("errors.required", {field: "$t(formFields.lastName)"});
        }
        if (!values[`personType`]) {
            errors[`personType`] = t("errors.required", {field: "$t(formFields.personType)"});
        }
        if (!emailRegex.test(values["email"])) {
            errors["email"] =  t("errors.notValid", {field:"$t(formFields.email)"});
        }

        return errors;
    }



    const { handleChange, handleSubmit, values, errors, fillFormValues, setServerErrors } = useForm2(
        submit,
        validate
    );


    const onHandleChange = (name, value) => {
        handleChange([name], value)
    }



    useEffect(() => {
        // fill local values
        fillFormValues(outerValues)
    },[]);



    return (
        <DialogPopUp
            open={isOpen}
            onClose={() => setIsOpen(false)}
            title={t("addPersonTitle")}
        >
            <DialogContent className={classes.content}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <FormControl error={!!errors["title"]} fullWidth className={classes.formControlWrapper}>
                            <RadioFields
                                options={titles}
                                name={"title"}
                                label={t("formFields.title")}
                                placeholder={t("formFields.title")}
                                value={values["title"]}
                                onRadioChange={(val) => handleChange("title", val)}
                                error={errors["title"]}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`firstName`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`firstName`}
                                label={t(`formFields.firstName`)}
                                placeholder={t(`formFields.firstName`)}
                                value={values[`firstName`]}
                                onInputChange={(val) => onHandleChange("firstName", val)}
                                error={errors[`firstName`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`lastName`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`lastName`}
                                label={t(`formFields.lastName`)}
                                placeholder={t(`formFields.lastName`)}
                                value={values[`lastName`]}
                                onInputChange={(val) => onHandleChange("lastName", val)}
                                error={errors[`lastName`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`personType`]} fullWidth className={classes.formControlWrapper}>
                            <SelectField
                                label={t(`formFields.personType`)}
                                name={`personType`}
                                value={values[`personType`]}
                                error={errors[`personType`]}
                                options={personTypes}
                                onSelectChange={(val) => onHandleChange(`personType`, val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`email`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`email`}
                                label={t(`formFields.email`)}
                                placeholder={t(`formFields.email`)}
                                value={values[`email`]}
                                onInputChange={(val) => onHandleChange("email", val)}
                                error={errors[`email`]}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    disabled={(Object.keys(validate(values)).length > 0) || isLoading}
                    variant="contained"
                    color="primary"
                >
                    {t("formFields.save")}
                </Button>
            </DialogActions>
        </DialogPopUp>
    );
};

export default InvitePersonPopup;

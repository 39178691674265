import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputField from "../../../../../../formElements/InputField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(theme => ({
			inputWrapper: {
				width: "50%",
				maxWidth: "100%",
				marginBottom: "20px",
			},
		}
	)
);

const ActivationForm = (props) => {
	const classes = useStyles();
	const {t} = useTranslation();
	const {handleChange, handleSubmit, errors, values, isSubmitting} = props;
	return (
		<form onSubmit={handleSubmit} noValidate className={classes.form}>
			<Grid container>
				<Grid item xs={12}>
					<FormControl fullWidth error={!!errors.cvv} className={classes.inputWrapper}>
						<InputField
							label={t("formFields.cvv")}
							name="cvv"
							type="cvv"
							value={values["cvv"]}
							onInputChange={(val) => handleChange("cvv", val)}
							error={errors["cvv"]}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						disabled={isSubmitting}
					>
						{t("formFields.submit")}
					</Button>
				</Grid>
			</Grid>


		</form>
	);
};

export default ActivationForm;

import React, {useEffect, useState} from 'react';
import useForm from "../../../../../../../hooks/useForm";
import {Trans, useTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import SelectField from "../../../../../../formElements/SelectField";
import InputField from "../../../../../../formElements/InputField";
import Button from "@material-ui/core/Button";
import Api from "../../../../../../../services/api";
import SelectSearch from "../../../../../../formElements/SelectSearch";
const service = new Api();


const useStyles = makeStyles((theme) => ({
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },

    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'var(--charcoal-primary)'
        }
    },

    contentInner:{
        maxWidth: "380px",
        width:"340px",
        marginLeft:"auto",
        marginRight:"auto",
        paddingBottom:"20px",
        paddingTop:"20px",
    },
}));


const StartStep = (props) => {

    const {match, company, handleErrorMessage, setCardData, cardData, setCurrentStep, setTitle} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setTitle(t("card.orderCard"));
        setIsLoading(true);
        (async() => {
            try{
                const users = await service.getUsersAll();
                const mappedUsers = users.data.map(({firstName,lastName, _id, kyc, phone, status}) => (
                        {
                            label: `${firstName} ${lastName}`,
                            value: _id,
                            kyc: kyc,
                            phone,
                            status
                        }
                    )
                );
                setUsers(mappedUsers);
                setIsLoading(false);
            }catch (e) {
                console.log(e);
                handleErrorMessage(e);
                setIsLoading(false);
            }

        })();

    },[]);

    const submit = () => {
        setCardData((prevValues) => ({
            ...prevValues,
            ...values
        }));
        setCurrentStep((oldStep) => oldStep+1);
    }


    useEffect(() => {
        Object.keys(cardData).forEach((key) => {
            handleChange(key, cardData[key])
        });
    },[cardData]);

    const validate = () => {
        const errors = {};
        if (!values.user) {
            errors.user = t('errors.required', {field: "$t(formFields.user)"});
        }else if(values.user){
            const selectedUser = users.find((item) => values.user === item.value);
            const {role} = match.params;

            if(selectedUser.status !== "active"){

                errors.user = t('user.notActive');

            }else if(values.type && values.type === "virtual"){

                if(company && company.kyb !== "accepted"){
                    errors.user = t('company.notKyb');
                }
                if(!selectedUser.phone){
                    errors.user = t("user.noPhone");
                }

            }else if(values.type && values.type === "physical"){

                if(!selectedUser.kyc){
                    errors.user = (
                        <>
                            <Trans i18nKey="verifyKybMessage">
                                <Link to={`/user-verification/${values.user}`} className={classes.errorLink}>VerifyLink</Link><span>verifyText</span>
                            </Trans>
                        </>
                    )
                }else if(selectedUser.kyc && selectedUser.kyc !== 'accepted'){
                    errors.user = t("user.notKyc")
                }
            }
        }
        if (!values.type) {
            errors.type = t('errors.required', {field: "$t(formFields.type)"});
        }

        if(!values.name && values.type === "virtual"){
            errors.name = t('errors.required', {field: "$t(formFields.cardName)"});
        }
        return errors;
    }


    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    const setSelectedUser = (val) => {
        handleChange('user', val ? val.value : undefined );
    }


    return (
       <>
            <div className={classes.contentInner}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.itemSettings}>
                        <FormControl fullWidth error={!!errors["type"]} className={classes.itemFieldFull}>
                            <SelectField
                                isLoading={isLoading}
                                label={t("formFields.type")}
                                name={"type"}
                                value={values["type"]}
                                error={errors["type"]}
                                options={[{label:t("card.physical"), value:"physical"}, {label:t("card.virtual"), value:"virtual"}]}
                                onSelectChange={(val) => handleChange("type", val)}
                            />
                        </FormControl>

                        {values["type"] === 'virtual' && (
                            <FormControl fullWidth error={!!errors["name"]} className={classes.itemFieldFull}>
                                <InputField
                                    isLoading={isLoading}
                                    name="name"
                                    label={t("formFields.cardName")}
                                    value={values["name"]}
                                    onInputChange={(val) => handleChange("name", val)}
                                    margin="none"
                                    error={errors["name"]}
                                />
                            </FormControl>
                        )}

                        <FormControl error={!!errors["user"]} fullWidth className={classes.itemFieldFull}>
                            <SelectSearch
                                label={t("formFields.responsibleEmployee")}
                                isLoading={isLoading}
                                value={values["user"] ? values["user"] : ''}
                                inputHandler={setSelectedUser}
                                options={users}
                                placeholder={t("user.searchPlaceholder")}
                                error={errors["user"]}
                            />
                        </FormControl>
                    </div>
                </form>
            </div>
            <Button onClick={handleSubmit} >
                {t("formFields.continue")}
            </Button>
       </>
    );
};

export default withRouter(StartStep);

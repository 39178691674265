import React from 'react';
import Button from "@material-ui/core/Button";
import clsx from "clsx";


const ThinButton = (props) => {
    const {children, noPadding, ...restProps} = props;
    return (
        <Button

            disableTouchRipple={true}
            className={clsx("btn-stroke")}
            {...restProps}
        >
            {children}
        </Button>
    );
};

export default ThinButton;

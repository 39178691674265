import React, { useEffect, useState } from "react";
import {
    makeStyles,
    Button,
    Box,
    FormControl,
    Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as InfoSVG } from "../../../../../assets/infoInvesthub.svg";
import SelectField from "../../../../formElements/SelectField";
import { isDarkModeActive } from "../../../../App/App";
import Api from "../../../../../services/api";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../../Messages/actions";

const useStyles = makeStyles((theme) => ({
    fieldGroup: {
        display: "flex",
        alignItems: "center",
        paddingBottom: "16px",
        [theme.breakpoints.down("767")]: {
            alignItems: "flex-start",
            flexFlow: "column nowrap",
            gap: "12px",
        },
        "&:not(:last-child)": {
            marginBottom: "32px",
            borderBottom: (props) =>
                props.isDarkModeActive
                    ? "1px solid var(--dark-primary)"
                    : "1px solid var(--very-light-pink-primary)",
            [theme.breakpoints.down("1023")]: {
                marginBottom: "24px",
            },
        },
    },

    fieldLabel: {
        marginRight: "24px",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "1.71429",
        flex: "1",
        color: (props) =>
            props.isDarkModeActive
                ? "var(--very-light-pink-secondary)"
                : "var(--charcoal-primary)",
        "& svg": {
            top: "-1px",
            position: "relative",
            display: "inline-block",
            marginLeft: "4px",
            cursor: "pointer",
            width: "16px",
            height: "16px",
            fill: (props) =>
                props.isDarkModeActive
                    ? "var(--cool-grey)"
                    : "var(--dark-secondary)",
        },
    },

    fieldContent: {
        minWidth: "180px",

        [theme.breakpoints.down("767")]: {
            minWidth: "100%",
        },

        "& .MuiInputBase-root": {
            width: "100%",
        },
    },
}));

const service = new Api();

const AccountsRules = (props) => {
    const { settingsDarkMode } = props;
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });
    const { t } = useTranslation();
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState();
    const dispatch = useDispatch();
    const getXeroSettings = async () => {
        setIsLoading(true)
        const { data } = await service.getCompany();
        console.log(data);
        if (data) {
            setValue(data);
        } else {
            setValue();
        }
        setIsLoading(false)
    };

    const getXeroAccounts = async () => {
        const { data } = await service.getCompanyXeroAccounts();
        if (data?.xeroAccounts?.Accounts) {
            setAccounts(
                data?.xeroAccounts?.Accounts.map((a) => ({
                    label: a.Name,
                    value: a.Code,
                }))
            );
        } else {
            setAccounts([]);
        }
    };
    useEffect(() => {
        getXeroSettings();
        getXeroAccounts();
    }, []);

    const onChangeAccount = async (type, accountCode) => {
        try {
            setIsLoading(true);
            const data = {
                ...value?.xero?.accounts,
            };
            switch (type) {
                case "gasFee":
                    data.gasFee = accountCode;
                    break;
                case "realisedGains":
                    data.realisedGains = accountCode;
                    break;
                case "realisedLosses":
                    data.realisedLosses = accountCode;
                    break;
                case "roundingError":
                    data.roundingError = accountCode;
                    break;
                default:
                    break;
            }
            const response = await service.updateCompanyXeroAccountSettings(
                data
            );
            await getXeroSettings();
            dispatch(setMessage(t("success"), "success"));
        } catch (e) {
            console.log(e);
            // dispatch(handleErrorMessage(e));
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <>
            <form action="#">
                <Box className={`${classes.fieldGroup}`}>
                    <Box className={classes.fieldLabel}>
                        {/* NEED TRANSLATION */}
                        {t("Gas Fees")}
                        <Tooltip
                            title={t(
                                "The cost per gas unit paid to the miners by the users who want their transactions processed."
                            )}
                        >
                            <InfoSVG />
                        </Tooltip>
                    </Box>

                    <Box className={classes.fieldContent}>
                        <SelectField
                            placeholder={t("Select Account")}
                            name={"gasFees"}
                            settings={settingsDarkMode}
                            options={accounts}
                            value={value?.xero?.accounts?.gasFees}
                            onSelectChange={(selectedSection) => {
                                onChangeAccount("gasFee", selectedSection);
                            }}
                            disabled={isLoading}
                        />
                    </Box>
                </Box>

                <Box className={`${classes.fieldGroup}`}>
                    <Box className={classes.fieldLabel}>
                        {/* NEED TRANSLATION */}
                        {t("Realised Gains")}
                        <Tooltip
                            title={t(
                                "A gain occurs when the current price of an asset rises"
                            )}
                        >
                            <InfoSVG />
                        </Tooltip>
                    </Box>

                    <Box className={classes.fieldContent}>
                        <SelectField
                            placeholder={t("Select Account")}
                            name={"realisedGains"}
                            settings={settingsDarkMode}
                            options={accounts}
                            value={value?.xero?.accounts?.realisedGains}
                            onSelectChange={(selectedSection) => {
                                onChangeAccount(
                                    "realisedGains",
                                    selectedSection
                                );
                            }}
                            disabled={isLoading}
                        />
                    </Box>
                </Box>

                <Box className={`${classes.fieldGroup}`}>
                    <Box className={classes.fieldLabel}>
                        {/* NEED TRANSLATION */}
                        {t("Realised Losses")}
                        <Tooltip
                            title={t(
                                "A loss occurs when the price has dropped since the transfer was made."
                            )}
                        >
                            <InfoSVG />
                        </Tooltip>
                    </Box>

                    <Box className={classes.fieldContent}>
                        <SelectField
                            placeholder={t("Select Account")}
                            name={"realisedLosses"}
                            settings={settingsDarkMode}
                            options={accounts}
                            value={value?.xero?.accounts?.realisedLosses}
                            onSelectChange={(selectedSection) => {
                                onChangeAccount(
                                    "realisedLosses",
                                    selectedSection
                                );
                            }}
                            disabled={isLoading}
                        />
                    </Box>
                </Box>

                <Box className={`${classes.fieldGroup}`}>
                    <Box className={classes.fieldLabel}>
                        {/* NEED TRANSLATION */}
                        {t("Rounding Error")}
                        <Tooltip
                            title={t(
                                "Minor discrepancy that occurs when numbers are rounded to a specific decimal place during calculations of financial reporting."
                            )}
                        >
                            <InfoSVG />
                        </Tooltip>
                    </Box>

                    <Box className={classes.fieldContent}>
                        <SelectField
                            placeholder={t("Select Account")}
                            name={"roundingError"}
                            settings={settingsDarkMode}
                            options={accounts}
                            value={value?.xero?.accounts?.roundingError}
                            onSelectChange={(selectedSection) => {
                                onChangeAccount(
                                    "roundingError",
                                    selectedSection
                                );
                            }}
                            disabled={isLoading}
                        />
                    </Box>
                </Box>

                {/* <Button className={classes.button}>
                    {t("formFields.submit")}
                </Button> */}
            </form>
        </>
    );
};

export default AccountsRules;

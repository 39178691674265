import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import {handleErrorMessage} from "../../../../../../Messages/actions";
import {useDispatch} from "react-redux";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core';
import { isDarkModeActive } from '../../../../../../App/App';

const service = new Api();

const useStyles = makeStyles(theme => ({
    report: {
        padding: '24px',
        borderRadius: '12px',
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
        fontWeight: '700',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--charcoal-primary)'
    }
}))

const ReportCounter = (props) => {
    const {queryForExport, settingsDarkMode} = props;
    const {xlsxReportType, ext, fileName, markAsReported, notReported, scope, ...restQuery} = queryForExport; // skip some fields
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });


    const restQueryJsonString = JSON.stringify(restQuery); // we need this data as primitive in useEffect dependency
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetchCounter = async () => {
        try{
            setIsLoading(true);
            const {status, ...restQuery} = queryForExport; // skip status. Status is set on backend
            const response = await service.getExpensesExportCount(restQuery);
            const {count} = response.data;
            setCount(count);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchCounter({...restQuery});
    },[restQueryJsonString]);

    return (
        <div>
            {isLoading ? (
                <Skeleton width={"100%"} height={"20px"}/>
            ) : (
                <div className={classes.report}>{t("notReportedExpenses", {count:count})}</div>
            )}
        </div>
    );
};

export default ReportCounter;

import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import RejectDialog from "../RejectDialog";
import Api from "../../../../../../../services/api";
import {setMessage} from "../../../../../../Messages/actions";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
const service = new Api();

const RejectButton = (props) => {
    const {t} = useTranslation();
    const {isSubmitting, id, history} = props;
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const onShowDialog = () => {
        setOpen(true);
    }

    const onReject = async (values) => {
        await service.rejectExpense(id, values);
        dispatch(setMessage("success", 'success'));
    }


    return (
         <>
             <Button
                className="btn-stroke"
                disabled={isSubmitting}
                onClick={onShowDialog}
            >
                {t("formFields.reject")}
            </Button>
             <RejectDialog open={open} setOpen={setOpen} onReject={onReject}/>
         </>
    );
};

export default withRouter(RejectButton);

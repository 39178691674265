import React, {useEffect, useState} from 'react';
import Api from "../../../../../services/api";
import checkClick from "../../../../../helpers/checkClick";
import queryString from "query-string";
import {Portal, Typography, makeStyles, Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useMounted from "../../../../../hooks/useMounted";
import VolveMaterialTable from "../../../../VolveMaterialTable";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },

    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
}));

const RolesTable = (props) => {
    const {history, underBarRef, settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const isMounted = useMounted();

    const [roles, setRoles] = useState([]);

    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const response = await service.getGroupedRoles();
                const {data} = response;
                const {result} = data;
                setRoles(result);
                setIsLoading(false);
            }catch (e) {
                setRoles([]);
                setIsLoading(false);
            }
        })();
    },[]);

    const rowHandlerClick = (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push({
                pathname:`/users`,
                search: `?${queryString.stringify({roles:rowData.role})}`,
            })
        }
    }

    const columns = [
        {
            title: t('thead.role'),
            field: 'role',
        },
        {
            title: t('thead.qtyUsers'),
            field: 'qty',
        },
    ];

    return (
        <Box className={classes.wrapper}>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                    {/* <Typography variant="h1"></Typography> */}
                </Portal>
            )}
            <Box className={`card`}>
                <Box className={classes.head}>
                    <Box className={`${classes.headTitle} card-title card-title--blue`}>
                        {t("roles.mainTitle")}
                    </Box>
                </Box>

                <Box className="table-wrap table-wrap--have-line table-wrap--have-px">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={roles}
                        onRowClick={rowHandlerClick}
                        settingsDarkMode={settingsDarkMode}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default RolesTable

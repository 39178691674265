import React from 'react';
import { makeStyles } from '@material-ui/core';
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles(theme => ({
    panelDock: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '60px 40px 20px',
        margin: 'auto -40px -40px',
        [theme.breakpoints.down('1339')]: {
            padding: '44px 24px 20px',
            margin: 'auto -24px -46px',
        },
        [theme.breakpoints.down('767')]: {
            display: 'block',
            padding: '40px 16px 16px',
            margin: 'auto -16px -24px',
        },
        "&:before" :{
            content: '""',
            position: 'absolute',
            top: '40px',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: '-1',
            background: (props) => props.isDarkModeActive ? 'var(--dark-secondary)' : 'var(--pale-grey)',
            boxShadow: (props) => props.isDarkModeActive ? 'inset 1px 0px 0px #111315' : 'inset 1px 0px 0px var(--very-light-pink-primary)',

            [theme.breakpoints.down('1339')]: {
                top: '24px'
            }
        }
    },

    panelInfo: {
        marginRight: 'auto',
        color: 'var(--battelship-grey)',
        fontSize: '13px',
        fontWeight: '600',
        [theme.breakpoints.down('767')]: {
            margin: '0 0 24px'
        }
    },

    panelContent: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    }
}));

const UnderCardDockInvesthub = (props) => {
    const {children, titleDock, settingsDarkMode, ...restProps} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <div
            className={classes.panelDock}
            {...restProps}
        >

            {titleDock && (
                <div className={`${classes.panelInfo}`}>
                    {titleDock}
                </div>
            )}

            <div className={classes.panelContent}>
                {children}
            </div>
        </div>
    )
}

export default UnderCardDockInvesthub;

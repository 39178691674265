import * as actionTypes from '../../../store/actionTypes'

const initialState = {
    isOpen:true,
    notificationEnabled:false,
    viewPermissions:{}
};

const mainDrawer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.DRAWER_MAIN_TOGGLE :
            return {
                ...state,
                isOpen:!state.isOpen
            }
        case actionTypes.DRAWER_MAIN_VIEW_PERMISSIONS :
            return {
                ...state,
                viewPermissions:action.payload
            }
    }
    return state
};

export default mainDrawer

import React from 'react'
import {useTranslation} from "react-i18next";
import KnowYourBusiness from "../userSection/content/Company/KnowYourBusiness";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const CompanyKybPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef} = props;
    return (
        <UserSectionWrapper>
            <Can I="update" a={"company"} passThrough>
                {(can) => (
                    can ? (
                        <CompanyCountryCheck>
                            <KnowYourBusiness underBarRef={underBarRef}/>
                        </CompanyCountryCheck>
                    ) : <div>{t("company.noPermissionToRead")}</div>
                )}
            </Can>
        </UserSectionWrapper>
    )
}

export default CompanyKybPage

import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import TopComponentInvesthub from '../TopComponentInvesthub';
import {connect} from "react-redux";
import checkClick from "../../../../../helpers/checkClick";
import DialogRejectReason from "../DialogRejectReason";
import Api from "../../../../../services/api";
import {handleErrorMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles, Portal, Typography, Box} from "@material-ui/core";
import { isDarkModeActive } from '../../../../App/App';

const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
}));


const RejectReasonsTable = (props) => {
    const {handleErrorMessage, underBarRef, settingsDarkMode} = props;

    const classes = useStyle({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    const [isFetching, setIsFetching] = useState(false);

    const [rejectReasons, setRejectReasons] = useState([]);

    const [rejectReason, setRejectReason] = useState({});

    const [info, setInfo] = useState({
        fields: ["name"],
        page: 0,
        total: 0,
        pageSize: 0
    });
    const [isMount, setIsMount] = useState(false);

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const fetchItems = async (query) => {

        try {
            setIsLoading(true);
            const response = await service.getRejectReasons(query);
            const {result, info} = response.data;
            setInfo(info);
            setRejectReasons((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }

    }


    useEffect(() => {
        setIsMount(true);
        fetchItems();
    },[]);

    const handleChangePage = async (event, newPage) => {
        await fetchItems({page:newPage});
    }

    const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            setOpen(true);
            setIsFetching(true);
            try {
                const response = await service.getRejectReason(rowData._id);
                setRejectReason(response.data);
            }catch (e) {
                handleErrorMessage(e);
                setOpen(false);
            }finally {
                setIsFetching(false);
            }
        }
    }


    const onExited = () => {
        setRejectReason({});
    }
    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
    ];


    return (
        <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                    {/* <TopComponent setOpen={setOpen}/> */}
                </Portal>
            )}
            <DialogRejectReason
                open={open}
                setOpen={setOpen}
                loading={isFetching}
                rejectReason={rejectReason}
                onExited={onExited}
                fetchItems={fetchItems}
                settingsDarkMode={settingsDarkMode}
            />
            <Box className={`card`}>
                <TopComponentInvesthub setOpen={setOpen} settingsDarkMode={settingsDarkMode} />
                <Box className="table-wrap table-wrap--have-hover table-wrap--have-line table-wrap--have-px">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={rejectReasons}
                        onRowClick={rowHandlerClick}
                        settingsDarkMode={settingsDarkMode}
                    />
                    <Pagination
                        total={info.total}
                        pageSize={info.pageSize}
                        page={info.page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>
            </Box>
        </>
    );
};

export default compose(
    connect(null, {handleErrorMessage})
)(RejectReasonsTable);

import React, {useContext, useEffect, useState} from 'react';
import {withRouter, Switch, Route} from "react-router-dom";
import AccountTransactionsTable from "./AccountTransactionsTable";
import Cards from "./AccountCardsTable";
import Details from "./Details";
import {compose} from "redux";
import {connect} from "react-redux";
import {fetchAccount} from "./actions";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../../Context/Can";
import AccountTabs from "./AccountTabs";
import {
    Portal,
    Typography,
    makeStyles
} from "@material-ui/core";
import useMounted from '../../../../../../hooks/useMounted';
import Api from "../../../../../../services/api";
import { ACCOUNT_TYPES } from "../../../../../../constants/transactions";
import queryString from "query-string";
import { isDarkModeActive } from '../../../../../App/App';

const services = new Api();

const useStyles = makeStyles(theme => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    }
}));


const Account = (props) => {
    const {
        match: {params, url, path},
        // eslint-disable-next-line no-unused-vars
        drawerOuterRef,
        underBarRef,
        name,
        location,
        settingsDarkMode
    } = props;
    const {type, id} = params;
    const {search} = location;
    const queryObject = queryString.parse(search);
    const  tokenType = queryObject?.token;
    const  network = queryObject?.network;

    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [backPath, setBackPath] = useState(null);
    const isMounted = useMounted();
    const [account, setAccount] = useState({accountNumber: null})

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const getImportedWalletBalanceByWalletName = async () => {
        const { data } = await services.getImportedWalletBalanceByWalletName(
            id,
            tokenType,
            network
        );
        if (data) {
            if (data?.wallet) {
                setAccount({
                    balance: {
                        toCurrency: data?.wallet?.currency,
                        sumInCurrency: data?.wallet?.balance,
                    },
                    address: data?.wallet?.address,
                    // network: wallet?.network,
                    // chain: wallet?.chain,
                    type: type,
                });
            }
        }
    };

    const getTanganyWalletBalances = async () => {
            const { data } = await services.getTanganyWalletBalanceByWalletName(id);
            if (data) {
                if (data?.wallet?.tokenBalances?.length) {
                    const wallet = data?.wallet?.tokenBalances?.find((e)=> {
                        return e?.currency === tokenType;
                     })
                     if (wallet) {
                        setAccount({
                            balance: {
                                toCurrency: wallet?.currency,
                                sumInCurrency: wallet?.balance,
                            },
                            address: data?.wallet?.address,
                            // network: wallet?.network,
                            // chain: wallet?.chain,
                            type: type,
                        })
                     } else if (data?.wallet?.currency === tokenType) {
                        setAccount({
                            balance: {
                                toCurrency: data?.wallet?.currency,
                                sumInCurrency: data?.wallet?.balance,
                            },
                            address: data?.wallet?.address,
                            // network: wallet?.network,
                            // chain: wallet?.chain,
                            type: type,
                        })
                     }
                }
            }
    };

    const getProfileBalances = async (profile, token) => {
        try {
            const response = await services.getMoneriumProfileBalances(profile, token)
            if (response?.data?.length) {
                const wallet = response?.data?.find((e)=> {
                   return e?.address === id;
                })
                const currentToken = wallet.balances.find((b) =>  b.currency.toUpperCase() === tokenType);

                if (wallet && currentToken) {
                    setAccount({
                        balance: {
                            toCurrency: currentToken?.currency,
                            sumInCurrency: currentToken?.amount,
                        },
                        address: wallet?.address,
                        network: wallet?.network,
                        chain: wallet?.chain,
                        type: type,
                    })
                }
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    const getAuthContext = async (token) => {
        try {
            const response = await services.getMoneriumAuthContext(token)
            return response.data;
        } catch (error) {
            console.log(error);

            return null;
        }
    };

    useEffect(() => {
        const getAppData = async () => {
            setIsLoading(true);
            if (type === ACCOUNT_TYPES.MONERIUM) {
                    const { data } = await services.getMoneriumAuthToken();
                        const profile = await getAuthContext(
                            data.data.access_token
                        );
                        if (profile) {
                            await getProfileBalances(
                                profile,
                                data.data.access_token
                            );
                        }
            } else if (type === ACCOUNT_TYPES.FIAT) {
                const { data } = await services.getFiatAccountByFiatId(id);
                if (data?.data) {
                    setAccount({
                        balance: {
                            toCurrency: data?.data?.currency,
                            sumInCurrency: data?.data?.balance?.actual,
                            available: data?.data?.balance?.available,
                        },
                        iban: data?.data?.bankDetails?.iban,
                        bankName: data?.data?.bankDetails?.bankName,
                        accountHolderName: data?.data?.bankDetails?.accountHolderName,
                        type: type,
                    });
                }
            } else if (type === ACCOUNT_TYPES.TANGANY) {
                await getTanganyWalletBalances()
            } else if (type === ACCOUNT_TYPES.IMPORTED) {
                await getImportedWalletBalanceByWalletName()
            }

            setIsLoading(false);
        };
        getAppData();
    },[]);

    useEffect(() => {
        if(location.state && location.state.backPath){
            setBackPath(location.state.backPath);
        }
        return () => {
            setBackPath(null);
        }
    },[]);

    return (
        <>
            {/* {isMounted && ( */}
                {/* <Portal container={underBarRef.current}> */}
                    {/* <Typography variant="h1" className={`${classes.pageTitle} h3`}> */}
                        {/* {t("account.mainTitle")} */}
                    {/* </Typography> */}
                {/* </Portal> */}
            {/* )} */}

            {/* <div className="card"> */}
                {/* <AccountTabs url={url} account={account}/> */}
            {/* </div> */}
            <Switch>
                <Route exact path={`${path}/details`} render={
                    () => <Details
                    account={account}
                    companyName={name}
                    isLoading={isLoading}
                    underBarRef={underBarRef}
                    backPath={backPath}
                    settingsDarkMode={settingsDarkMode}
                    accountTabs={
                        <AccountTabs
                            url={url}
                            account={account}
                            settingsDarkMode={settingsDarkMode}
                            isMounted={isMounted}
                            underBarRefCurrent={underBarRef.current}
                        />}
                    />
                }
                />
            </Switch>
        </>
    );
};

const mapStateToProps = ({user}) => {
    const {userData} = user;
    const {company} = userData;

    return{
        name: company.name
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Account)


import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {setMessage, handleErrorMessage} from '../../../../../../Messages/actions'
import Api from "../../../../../../../services/api";
import {fetchUsers} from "../../../actions";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
// import ActionMenu from "../../../../../../ActionMenu";
// import ActionButton from "../../../../../../ActionButton";
import MenuOption from "../../../../../../MenuOption";
import DialogAssignApprover from "../../DialogAssignApprover";
import { ClickAwayListener, Box, makeStyles } from '@material-ui/core';
import PlusButton from '../../../../../../PlusButton';
import {ReactComponent as ArrowDownSVG} from "../../../../../../../assets/arrowDownInvesthub.svg"
import { isDarkModeActive } from '../../../../../../App/App';
const service = new Api();

const useStyles = makeStyles(theme => ({
    menuAction: {
        position: 'relative',
        display: 'inline-block',
        zIndex: '11'
    },
    menuBody: {
        position: 'absolute',
        top: 'calc(100% + 12px)',
        right: '-12px',
        width: '264px',
        zIndex: '11',
        padding: '12px',
        borderRadius: '16px',
        border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
        boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
        background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
        visibility: 'hidden',
        opacity: '0',
        transform: 'translateY(3px)',
        transition: 'all .2s',
        margin: '0',
        '&:before': {
            content: `""`,
            position: 'absolute',
            right: '20px',
            bottom: '100%',
            width: '20px',
            height: '10px',
            background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")  no-repeat 50% 50%/100% auto` : `url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E) no-repeat 50% 50%/100% auto`
        },

        "& li.MuiButtonBase-root": {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '12px',
            borderRadius: '12px',
            transition: 'all .2s',
            cursor: 'pointer',
            background: 'transparent !important',
            justifyContent: 'flex-start',
            "& svg": {
                width: '24px',
                height: '24px',
                marginRight: '12px',
                fill: 'var(--battelship-grey)',
                transition: 'fill .2s',
            },
            "& .MuiTypography-root": {
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '1.6',
                color: 'var(--battelship-grey)',
                transition: 'all .2s',
                fontFamily: `'Inter', sans-serif`,
                letterSpacing: '0'
            },
            "&:hover": {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                "& svg": {
                    fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                },
                "& .MuiTypography-root": {
                    color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                }
            },
            "& .MuiTouchRipple-root": {
                display: 'none'
            },
            "& .MuiListItemText-root": {
                margin: '0'
            }
        }
    },
    menuBodyActive: {
        opacity: '1',
        transform: 'translateY(0)',
        visibility: 'visible'
    },
    customClass: {
        "&.MuiButton-root.btn-small": {
            padding: '0 16px !important',
            lineHeight: '40px',
            borderRadius: '12px !important',
            background: "none",
            width:'initial',
            color: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--dark-secondary)',

            "& .MuiButton-endIcon": {
                display: 'none'
            },

            "&:hover": {
                boxShadow: (props) => props.isDarkModeActive ? 'inset 0 0 0 2px var(--charcoal-primary)' : 'inset 0 0 0 2px var(--cool-grey)'
            }
        },
        "& .MuiButton-label > svg": {
            display: 'inline-block !important',
            marginLeft: '8px',
            position: 'relative',
            top: '2px',
            width: '16px',
            height: '16px',
            fill: (props) => props.isDarkModeActive ? 'var(--battelship-grey) !important' : 'var(--dark-secondary) !important',
        }
    },
    customClassActive: {
        "&.MuiButton-root.btn-small": {
            boxShadow: (props) => props.isDarkModeActive ? 'inset 0 0 0 2px var(--charcoal-primary)' : 'inset 0 0 0 2px var(--cool-grey)',
            background: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
        },
        "& .MuiButton-label > svg": {
            transform: 'rotate(180deg)',
        }
    }
}));

const ActionBtn = (props) => {

    const {t} = useTranslation();
    const {selected, setSelected, handleErrorMessage, setMessage, fetchUsers, location, settingsDarkMode, fromParentClass} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const {search} = location;
    const queryObject = queryString.parse(search);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [delayedIsActive, setDelayedIsActive] = useState(false);
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    useEffect(() => {
        let timeoutId;

        if (isActive) {
            timeoutId = setTimeout(() => {
                setDelayedIsActive(true);
            }, 300);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isActive]);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
        setIsActive(!isActive);
    }

    function handleClose() {
        setAnchorEl(null);
        setIsActive(false);
    }

    const handleAwayClick = () => {
        handleClose();
    };

    const onAssignApprover = async (approver) => {
        await service.assignReporters(approver, {ids: selected});
        await fetchUsers({...queryObject});
        setSelected([]);
    }

    const onHandleAction = async (action) => {

        let serviceAction = null;

        const dataToSend = {userIds: selected};

        switch (action) {
            case "activate" :
                serviceAction = service.actionBatchActivate;
                break;
            default :
                handleErrorMessage({message:`user.noAction`});
                setIsSubmitting(false);
                return;
        }

        try {

            const response = await serviceAction(dataToSend);
            const {data} = response;
            const {modified, doNotModified} = data;
            if(modified  > 0){
                const message = t("user.updatedUsers", {modified:modified, all:modified+doNotModified});
                setMessage(message, 'success');
                setSelected([]);
                await fetchUsers({...queryObject});
            }else{
                handleErrorMessage({message:`user.cantUpdateAny`})
            }
            setIsSubmitting(false);

        }catch (e) {
            setIsSubmitting(false);
            handleErrorMessage(e);
        }
    }

    const onOpenDialog = () => {
        setIsOpenDialog(true);
        handleClose(); // close menu
    }

    const onExportAll = async () => {
        try{
            setIsSubmitting(true);
            await service.exportUsers();
            handleClose();
        }catch (e) {
            handleErrorMessage(e);
        }finally {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleAwayClick}>
                <Box className={`${classes.menuAction} ${fromParentClass}`}>
                    <PlusButton
                        onClickHandler={handleClick}
                        hasDropdown
                        customClass={`${classes.customClass} ${isActive ? classes.customClassActive : null }`}
                        hasDropdownActive={isActive}
                        settingsDarkMode={settingsDarkMode}
                    >
                        {t("user.actions")}
                        {' '}
                        <ArrowDownSVG />
                    </PlusButton>

                    {isActive && (
                        <ul anchorEl={anchorEl} className={`${classes.menuBody} ${delayedIsActive ? classes.menuBodyActive: ''}`}>
                            <MenuOption onClick={onExportAll} className={classes.menuButton}>
                                {t("user.exportAll")}
                            </MenuOption>

                            {selected.length > 0 && (
                                <MenuOption disabled={isSubmitting} onClick={() => onHandleAction("activate")} className={`${classes.menuButton}`}>
                                    {t("formFields.activate")}
                                </MenuOption>
                            )}
                            {selected.length > 0 && (
                                <MenuOption disabled={isSubmitting} onClick={onOpenDialog} className={`${classes.menuButton}`}>
                                    {t("formFields.assignApprover")}
                                </MenuOption>
                            )}
                        </ul>
                    )}
                </Box>
            </ClickAwayListener>

            <DialogAssignApprover
                setOpen={setIsOpenDialog}
                title={t("formFields.assignApprover")}
                open={isOpenDialog}
                setMessage={setMessage}
                handleErrorMessage={handleErrorMessage}
                onAssignApprover={onAssignApprover}
            />
        </>
    );
};

export default compose(
    connect(null, {handleErrorMessage, setMessage, fetchUsers})
)(withRouter(ActionBtn));

import React, {useEffect, useState} from 'react';
import Api from "../../services/api";
import {compose} from "redux";
import {connect} from "react-redux";
import {setMessage, handleErrorMessage} from '../Messages/actions';
import Grid from "@material-ui/core/Grid";
import {getLoggedUser} from "../User/actions";
// import * as loader from "../../assets/animations/loader";
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from "@material-ui/core/Box";

const service = new Api();

const EmailConfirmation = (props) => {
    const {match, history, handleErrorMessage, setMessage} = props;

    const [isLoading, setIsLoading] = useState(false);

    const {hash} = match.params;
    if(!hash){
        history.push("/")
    }

    const checkHash = async () => {
        setIsLoading(true);
        try{
            await service.confirmEmail(hash);

            setIsLoading(false);
            setMessage("emailConfirmSuccess", 'success');
            history.push("/");

        }catch (e) {
            handleErrorMessage(e);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        checkHash();
    },[]);

    return (
        <Grid container>
            <Grid item xs={12}>
              {isLoading && (
                  <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress/>
                  </Box>
              )}
            </Grid>
        </Grid>
    );
};


export default compose(
    connect(null, {handleErrorMessage, setMessage, getLoggedUser})
)(EmailConfirmation);

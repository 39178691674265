import React, {useState} from 'react';
import {Box, FormControl, Grid} from "@material-ui/core";
import CheckBoxField from "../../../../../../formElements/CheckBoxField";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../../services/api";
import {withRouter} from "react-router-dom";
const service = new Api();

const BlockUnblockPermsGroup = (props) => {
    const {setMessage, handleErrorMessage, card, setCard, match} = props;
    const {id} = match.params;

    const [isDisabled, setIsDisabled] = useState(false);
    const {t} = useTranslation();

    const onBlockUnblockPermsGroup = async (value) => {

        setIsDisabled(true);
        try{
            const response = await service.blockUnblockPermsGroupCard(id, {permsGroupIsBlocked: !!value});
            setCard((oldCard) => ({
                ...oldCard,
                ...response.data
            }));
            setMessage("success", 'success');
        }catch (e){
            handleErrorMessage(e);
        }finally {
            setIsDisabled(false);
        }
    }

    return (
        <Grid item xs={12}>
            <Box paddingTop={"20px"}>
                <FormControl>
                    <CheckBoxField
                        disabled={isDisabled}
                        onCheckboxChange={onBlockUnblockPermsGroup}
                        value={!!card.permsGroupIsBlocked}
                        label={t("formFields.dontAllowToChangePermsGroup")}
                        name={"permsGroupIsBlocked"}
                    />
                </FormControl>
            </Box>
        </Grid>
    );
};

export default withRouter(BlockUnblockPermsGroup);

import i18n from "i18next";
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import Api from "./services/api";
const service = new Api();

const initI18n = () => {

    return i18n
        .use(backend)
        .use(initReactI18next)
        .init({
            lng: "en",
            fallbackLng: "en",
            saveMissing: false,
            interpolation: {
                escapeValue: false
            },
            react: {
                wait: true,
                useSuspense: false,
            },
            nsSeparator: '|',
            backend:{
                loadPath: `${service._basicPath}/locales/{{lng}}/{{ns}}.json`,
                crossDomain: true,
            },
        });
}


export default initI18n;

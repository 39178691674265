import React from 'react';
import {ReactComponent as PdfIcon} from "../../../assets/pdf.svg";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    icon: {
        width:"150px",
        maxWidth:"100%",
        padding:"20px 10px 20px 10px",
        '& path':{
            fill: "#3d4259"
        },
        display: 'block'
    }
}));


const PdfPlaceholder = () => {
    const classes = useStyles();

    return <PdfIcon className={classes.icon}/>;
};

export default PdfPlaceholder;

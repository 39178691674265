import React, {useState} from 'react';
import {connect} from "react-redux";
import {Grid, Button, FormControl} from '@material-ui/core';
import {makeStyles} from "@material-ui/core";
import Api from "../../../../../../../services/api";
import {setMessage} from "../../../../../../Messages/actions";
import {fetchUsers} from "../../../actions";
import useForm from "../../../../../../../hooks/useForm";
import InputField from "../../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import getEmailRegex from "../../../../../../../helpers/getEmailRegex";
import Box from "@material-ui/core/Box";
import { isDarkModeActive } from '../../../../../../App/App';
const service = new Api();

const useStyles = makeStyles(theme => ({
        itemForm: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            width: '100%',
            marginBottom: '48px',
            [theme.breakpoints.down('1023')]: {
                display: "block",
                marginBottom: '32px'
            }
        },
        itemSettings: {
            width: '100%',
            "& $itemField": {
                "&:not(:last-child)": {
                    marginBottom: '32px',
                    [theme.breakpoints.down('1023')]: {
                        marginBottom: '24px'
                    }
                }
            },
            "& $itemRow": {
                "&:not(:last-child)": {
                    marginBottom: '32px',
                    [theme.breakpoints.down('1023')]: {
                        marginBottom: '24px'
                    }
                },

                "& $itemField": {
                    flex: '0 0 calc(50% - 16px)',
                    width: 'calc(50% - 16px)',
                    margin: '0 8px',
                    [theme.breakpoints.down('1023')]: {
                        width: '100%',
                        margin: '0'
                    }
                }
            }
        },

        itemField: {},

        itemRow: {
            display: 'flex',
            margin: '0 -8px',
            [theme.breakpoints.down('1023')]: {
                display: 'block',
                margin: '0'
            },
            "& $itemField": {
                [theme.breakpoints.up('1023')]: {
                    marginBottom: '0 !important'
                }
            }
        },
        itemBox: {
            width: '100%',
            height: '48px',
            padding: '0 10px',
            borderRadius: '12px',
            border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid transparent',
            background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
            transition: 'all .2s',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '8px'
        },

        button: {
            marginTop: '32px',
            [theme.breakpoints.down('1023')]: {
                marginTop: '24px'
            }
        }
    }
));

const EmailForm = ({setShowForm, setMessage, fetchUsers, location, settingsDarkMode}) => {
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    const validate = values => {
        const emailRegex = getEmailRegex();
        const errors = {};
        if (!values.email) {
            errors.email = t('errors.required', {field: t("formFields.email")});
        }
        if (!values.firstName) {
            errors.firstName = t('errors.required', {field: t("formFields.firstName")});
        }
        if (!values.lastName) {
            errors.lastName = t('errors.required', {field: t("formFields.lastName")});
        }
        if (values.email && !emailRegex.test(values.email)) {
            errors.email = t('errors.notValid', {field: t("formFields.email")});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    async function submit(){
        setIsLoading(true);
        const {email, firstName, lastName} = values;

        const {search} = location;
        const queryObject = queryString.parse(search);

        try {
            const response = await service.createInvitedUser({email, firstName, lastName});
            setIsLoading(false);
            setMessage(t("emailWasSentTo", {email:response.data.user.email}), 'success');

            setShowForm(false); // hide form, and show Invite uer button
            fetchUsers({...queryObject});

        }catch (e) {
            setServerErrors(e);
            setIsLoading(false);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} noValidate className={classes.itemForm}>
                <Box className={classes.itemSettings}>
                    <FormControl fullWidth error={!!errors.firstName} className={classes.itemField}>
                        <InputField
                            name="firstName"
                            label={t("formFields.firstName")}
                            value={values["firstName"]}
                            onInputChange={(val) => handleChange("firstName", val)}
                            error={errors["firstName"]}
                        />
                    </FormControl>

                    <Box className={classes.itemRow}>
                        <FormControl fullWidth error={!!errors.lastName} className={classes.itemField}>
                            <InputField
                                name="lastName"
                                label={t("formFields.lastName")}
                                value={values["lastName"]}
                                onInputChange={(val) => handleChange("lastName", val)}
                                error={errors["lastName"]}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.email} className={classes.itemField}>
                            <InputField
                                name="email"
                                label={t("formFields.email")}
                                type="email"
                                value={values["email"]}
                                onInputChange={(val) => handleChange("email", val)}
                                error={errors["email"]}
                            />
                        </FormControl>
                    </Box>
                </Box>

                <Button
                    type="submit"
                    className={classes.button}
                    disabled={isLoading}
                >
                    {t("formFields.sendInvitationEmail")}
                </Button>
            </form>
        </>
    );
};



export default connect(
    null,
    {setMessage, fetchUsers}
)(withRouter(EmailForm))




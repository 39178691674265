import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom'
import ExpenseReportTemplatesTable from "./ExpenseReportTemplatesTable";
import AddExpenseReportTemplates from "./AddExpenseReportTemplates";
import ExpenseReportTemplate from "./ExpenseReportTemplate";
import {Can} from "../../../../Context/Can";
import {useTranslation} from "react-i18next";

const ExpenseReportTemplates = (props) => {
    const {t} = useTranslation();

    const {match, underBarRef, settingsDarkMode} = props;
    const {url} = match;

    return (
        <>
            <Switch>
                <Route exact path={url} render={(props) => <ExpenseReportTemplatesTable {...props} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />}/>
                <Route exact path={`${url}/add`} render={(props) => (
                    <Can I="create" a={"expenseReportTemplate"} passThrough>
                        {(can) => (
                            can ?  <AddExpenseReportTemplates {...props} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} /> : <div>{t("expenseReportTemplate.noPermissionToCreate")}</div>
                        )}
                    </Can>
                )}/>
                <Route exact path={`${url}/:id`} render={(props) => (
                    <Can I="update" a={"expenseReportTemplate"} passThrough>
                        {(can) => (
                            can ? <ExpenseReportTemplate {...props} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} /> :  <div>{t("expenseReportTemplate.noPermissionToUpdate")}</div>
                        )}
                    </Can>
                )}/>
            </Switch>
        </>
    );
};

export default withRouter(ExpenseReportTemplates);

import React, {useState} from 'react';
import PlusButtonTitle from '../../../../PlusButtonTitle/PlusButtonTitle';
import DialogFba from "../DialogFba";
import {useTranslation} from "react-i18next";

const AddFundingBankAccountInvesthub = () => {

	const [open, setOpen] = useState(false);
	const {t} = useTranslation();

	return (
		<>
			<PlusButtonTitle onClickHandler={() => setOpen(true)} />
			{open && <DialogFba open={open} setOpen={setOpen} action="create" title={t("company.addNewFabTitle")}/>}
		</>
	);
};

export default AddFundingBankAccountInvesthub;

import React, {useCallback, useEffect, useState} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogPopUp from "../../../../../../../DialogPopUp";
import {makeStyles} from "@material-ui/core";
import useForm from "../../../../../../../../hooks/useForm";
import GetPinForm from "./GetPinForm";
import Api from "../../../../../../../../services/api";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
const service = new Api();

const useStyles = makeStyles({
    contentInner:{
        width: "425px",
        maxWidth:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        paddingTop:"20px",
        paddingBottom:"20px"
    },
    text:{
        textAlign:"center"
    }
});



const DialogPin = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {isOpen, setIsOpen, cardId} = props;
    const [isLoading, setIsLoading] = useState(false);

    const timeoutSeconds = 15;
    const [timer, setTimer] = useState(timeoutSeconds);

    const [funcId, setFuncId] = useState(null); // we need this for clear interval
    const [code, setCode] = useState(null);

    const submit = async () => {
        setIsLoading(true);
        try{
            const response = await service.getCardPinCode(cardId, values);
            setCode(response.data.code);
            const functionId = onInterval();
            setFuncId(functionId);

            setIsLoading(false);
        }catch (e) {
            setServerErrors(e);
            setIsLoading(false);
        }

    }

    const validate = () => {
        const errors = {};
        if(!values["cvv"]){
            errors["cvv"] = t("errors.required", {field: "$t(formFields.cvv)"})
        }
        return errors
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate
    );


    const onInterval = () => {
        return setInterval(() => {
            setTimer((oldVal) => oldVal-1)
        }, 1000)
    };


    const closeHandler = () => {
        clearInterval(funcId);
        setIsOpen(false);
    };



    useEffect(() => {
        if(timer < 1){
            closeHandler();
        }
    },[timer]);


    return (
        <DialogPopUp
            open={isOpen}
            onClose={() => closeHandler()}
            aria-labelledby="form-dialog-title"
            title={t("card.getPinCode")}
        >
            <DialogContent>
                <div className={classes.contentInner}>

                    {(timer <= timeoutSeconds) && code && (
                        <Typography variant="subtitle2" gutterBottom className={classes.text}>
                            {t("card.closeWindowText", {seconds:timer})}
                        </Typography>
                    )}

                    {code ? (
                        <Typography variant="h6" gutterBottom className={classes.text}>
                            {t("card.yourPinIs", {pin:code})}
                        </Typography>
                    ) : (
                        <GetPinForm
                            handleSubmit={handleSubmit}
                            errors={errors}
                            values={values}
                            handleChange={handleChange}
                            isLoading={isLoading}
                        />
                    )}

                </div>
            </DialogContent>
            <DialogActions>
                {code ? (
                    <Button
                        onClick={() => closeHandler()}
                        color="secondary"
                        variant={"contained"}
                        disabled={isLoading}
                    >
                        {t("formFields.close")}
                    </Button>
                ) : (
                    <Button
                        onClick={handleSubmit}
                        color="secondary"
                        variant={"contained"}
                        disabled={isLoading}
                    >
                        {t("formFields.getPin")}
                    </Button>
                )}
            </DialogActions>
        </DialogPopUp>
    );
};

export default DialogPin;

import React from 'react';
import NewCard from "../NewCard";
import Search from "../../../../Bar/Search";
import {makeStyles} from "@material-ui/core";
import FilterButton from "../../../../../FilterButton/FilterButton";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../../Context/Can";

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px',
            flexFlow: 'row wrap'
        },
    },
    searchWrapper: {
        position: 'relative',
        width: '360px',
        marginRight: 'auto',
        [theme.breakpoints.down('1023')]: {
            width: '250px',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 16px',
        },
        "& .MuiFormControl-root": {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            position: 'relative',
            "& button": {
                margin: '0',
                padding: '0',

                "& svg path": {
                    transition: 'fill .2s',
                },

                "&:hover": {
                    backgroundColor: 'transparent',

                    "& svg path": {
                        stroke: 'var(--clear-blue-primary)'
                    }
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            }
        }
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginLeft: 'auto',
        gap: '8px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            flexFlow: 'row-reverse wrap'
        }
    },

    actionWrapper: {
        [theme.breakpoints.down('767')]: {
            flex: '1',
            marginLeft: 'auto',
            display: 'flex',

            "& button": {
                marginLeft: 'auto'
            }
        }
    }
}));


const TopComponent = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {onFilterOpen, isFilterOpen, canShowFilter, settingsDarkMode} = props;

    const canShowFilterBtn = canShowFilter && !isFilterOpen;

    return (
        <div className={classes.head}>
            <div className={classes.searchWrapper}>
                {/* NEED TRANSLATION */}
                <Search type="cards" placeholderText={t('Search by description')} settingsDarkMode={settingsDarkMode} />
            </div>

            <div className={classes.control}>
                <Can I="create" a="card">
                    <NewCard settingsDarkMode={settingsDarkMode} />
                </Can>

                {canShowFilterBtn && <FilterButton onClick={onFilterOpen} settingsDarkMode={settingsDarkMode} />}
            </div>
        </div>
    );
};

export default TopComponent;

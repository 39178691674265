import React, { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isDarkModeActive } from "../../../../App/App";
import useActiveTab from "../../../../../hooks/useActiveTab";
import Api from "../../../../../services/api";
import { handleErrorMessage } from "../../../../Messages/actions";
import checkClick from "../../../../../helpers/checkClick";
import ExpenseStatus from "../../../../ExpenseStatus";
import FormatDate from "../../../../FormatDate";
import ExpenseDashboardStatus from "../../../../ExpenseStatus/ExpenseDashboardStatus";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: "40px",
        position: "relative",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "24px",
        },
        [theme.breakpoints.down("767")]: {
            flexWrap: "wrap",
            paddingBottom: "24px",
            marginBottom: "24px",
        },
    },
    headTitle: {
        position: "relative",
        display: "inline-block",
        paddingLeft: "32px",
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "1.6",
        letterSpacing: "-.02em",
        [theme.breakpoints.down("767")]: {
            fontSize: "18px",
        },
    },

    transactionList: {
        display: "flex",
        flexFlow: "column nowrap",
        gap: "20px",
    },

    transactionCard: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },

    transactionCardLeft: {
        display: "flex",
        flexFlow: "column nowrap",
        flex: "1",
    },
    transactionCardRight: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        flex: "1",
        gap: "4px",
    },
    transactionCardDate: {
        color: "#6F767E",
        fontSize: "14px",
    },
}));

const MyLatestTransactions = (props) => {
    const { settingsDarkMode, history, dateFormat } = props;
    const { t } = useTranslation();
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });
    const activeTab = useActiveTab("dashboard");

    const [isLoading, setIsLoading] = useState(false);
    const [expenses, setExpenses] = useState([]);

    const fetchLastExpenses = async (activeTab) => {
        try {
            setIsLoading(true);
            const response = await service.getDashboardLatest({
                fromTab: activeTab,
            });
            setExpenses(response.data.result);
        } catch (e) {
            console.log(e);
            handleErrorMessage(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (activeTab) {
            fetchLastExpenses(activeTab);
        }
    }, [activeTab]);

    const rowHandlerClick = (rowData) => {
        const wasClicked = checkClick();
        if (wasClicked) {
            history.push({ pathname: `/transactions/${rowData._id}` });
        }
    };

    const getTitle = () => {
        switch (activeTab) {
            case "personal":
                return t("dashboard.expensesLatestTitleMy");
            case "approver":
            case "manager":
                return t("dashboard.expensesLatestTitleTeam");
            default:
                return t("dashboard.expensesLatestTitle");
        }
    };

    const title = getTitle();

    return (
        <Box className="card">
            <Box className={classes.head}>
                <Box
                    className={`${classes.headTitle} card-title card-title--green`}
                >
                    {/* NEED TRANSLATION */}
                    {t(title)}
                </Box>
            </Box>

            <Box className={classes.transactionList}>
                {expenses.map((e) => {
                    let description = e?.merchant || e?.comment;
                    if (!e?.merchant?.length && !e?.comment?.length) {
                        description = e?.vendor?.contactType === "individual" ? (e?.vendor?.firstName + " " + e?.vendor?.lastName) : e?.vendor?.companyName
                    }
                    return (
                        <Box
                            className={classes.transactionCard}
                            onClick={() => {
                                rowHandlerClick(e);
                            }}
                        >
                            <Box className={classes.transactionCardLeft}>
                                <Box className={classes.transactionCardTitle}>
                                    {description || ""}
                                </Box>
                                <Box className={classes.transactionCardDate}>
                                    <FormatDate dateFormat={dateFormat}>
                                        {e?.date}
                                    </FormatDate>
                                </Box>
                            </Box>

                            <Box className={classes.transactionCardRight}>
                                <Box className={classes.transactionCardValue}>
                                    {e?.currency} {e?.amount}
                                </Box>
                                <Box className={classes.transactionCardStatus}>
                                    <ExpenseDashboardStatus status={e?.status} />
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default MyLatestTransactions;

import React, {useCallback, useEffect, useState} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {ReactComponent as Pen} from "../../assets/pen.svg";
import {ReactComponent as CloseThin} from "../../assets/closeThin.svg";

import Fab from "@material-ui/core/Fab";
import {makeStyles} from "@material-ui/core";

import {useTranslation} from "react-i18next";
import AccountCell from "./AccountCell";
import BankAccountPopup from "./BankAccountPopup";
import Box from "@material-ui/core/Box";
import AccountCellDocument from "./AccountCellDocument";


const useStyles = makeStyles(theme => ({
    deleteBtnWrapper:{
        '&[disabled] svg':{
            opacity:"0.5"
        }
    },
    fabButton: {
        boxShadow: "none",
        background: "none",
        width:"20px",
        height:"20px",
        margin:"4px",
        minHeight:"20px",
        "& svg path":{
            stroke:"#1e1e25"
        }
    },
    table: {
        "& th": {
            padding: "12px"
        },
        "& th:first-child": {
            paddingLeft: 0
        },
        "& td": {
            padding: "12px"
        },
        "& td:first-child": {
            paddingLeft: 0
        }
    },
    documentInner:{
        color:"#354052"
    }

}));



const FundingBankAccounts2 = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const [popupIdx, setPopupIdx] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const {handleChange, errors, values, currencies=[], fundingBankAccountsIndexes, setFundingBankAccountsIndexes, idx, isReadOnly, isLoading, setStepFields} = props;

    const onSave = (idx) => {
        if(!fundingBankAccountsIndexes.includes(idx)){
            // add new index if not exist
            setFundingBankAccountsIndexes((rowIdxes) => ([...rowIdxes, idx]));
        }
        setIsOpen(false);
        setPopupIdx(null);
    }
    const onEdit = (idx) => {
        setIsEditMode(true);
        setPopupIdx(idx);
        setIsOpen(true);

    }

    const getNewIndex = () => {
        if(fundingBankAccountsIndexes.length){
            const lastIndex = fundingBankAccountsIndexes[fundingBankAccountsIndexes.length-1]; // get last index value
            return lastIndex+1;
        }else{
           return 0;
        }
    }

    const AddBankAccount = () => {
        setIsEditMode(false);
        setPopupIdx(getNewIndex());
        setIsOpen(true);
    }


    const onDeleteRow = (index) => {
        // remove values

        setFundingBankAccountsIndexes((fundingBankAccountsIndexes) => fundingBankAccountsIndexes.filter((idx) => idx !== index));

        handleChange(`fundingBankAccounts[${index}][bankName]`);
        handleChange(`fundingBankAccounts[${index}][accountName]`);
        handleChange(`fundingBankAccounts[${index}][accountNo]`);
        handleChange(`fundingBankAccounts[${index}][currency]`);
        handleChange(`fundingBankAccounts[${index}][proofOfAccount]`);
        handleChange(`fundingBankAccounts[${index}][_id]`);

    }

    const memoizedCallback = useCallback(handleChange , []);

    const [isOpen, setIsOpen] = useState(false);



    useEffect(()=> {

        const currentFields = [];

        if(fundingBankAccountsIndexes.length > 0){
            fundingBankAccountsIndexes.forEach((index) => {
                currentFields.push(`fundingBankAccounts[${index}][bankName]`);
                currentFields.push(`fundingBankAccounts[${index}][accountName]`);
                currentFields.push(`fundingBankAccounts[${index}][accountNo]`);
                currentFields.push(`fundingBankAccounts[${index}][currency]`);
                currentFields.push(`fundingBankAccounts[${index}][proofOfAccount]`);
                if(values[`fundingBankAccounts[${index}][_id]`]){
                    currentFields.push(`fundingBankAccounts[${index}][_id]`);
                }
            });
        }else{
            currentFields.push(`fundingBankAccounts`);
        }

        setStepFields((fields) => {
            return {
                ...fields,
                [idx]:currentFields
            }
        })

    },[fundingBankAccountsIndexes]);


    return (
        <Box>
            <Box mb={5}>
                {isOpen && (
                    <BankAccountPopup
                        isEditMode={isEditMode}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        outerValues={{
                            bankName:values[`fundingBankAccounts[${popupIdx}][bankName]`],
                            accountName:values[`fundingBankAccounts[${popupIdx}][accountName]`],
                            accountNo:values[`fundingBankAccounts[${popupIdx}][accountNo]`],
                            currency:values[`fundingBankAccounts[${popupIdx}][currency]`],
                            proofOfAccount:values[`fundingBankAccounts[${popupIdx}][proofOfAccount]`],
                        }}
                        index={popupIdx}
                        memoizedCallback={memoizedCallback}
                        currencies={currencies}
                        onSave={onSave}
                    />
                )}

                <Table aria-label="Funding Bank Accounts" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("formFields.bankName")}</TableCell>
                            <TableCell>{t("formFields.accountName")}</TableCell>
                            <TableCell>{t("formFields.accountNo")}</TableCell>
                            <TableCell>{t("formFields.currency")}</TableCell>
                            <TableCell>{t("formFields.proofOfAccount")}</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fundingBankAccountsIndexes.map((index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <AccountCell
                                            error={errors[`fundingBankAccounts[${index}][bankName]`]}
                                            value={values[`fundingBankAccounts[${index}][bankName]`]}
                                            isLoading={isLoading}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <AccountCell
                                            error={errors[`fundingBankAccounts[${index}][accountName]`]}
                                            value={values[`fundingBankAccounts[${index}][accountName]`]}
                                            isLoading={isLoading}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <AccountCell
                                            error={errors[`fundingBankAccounts[${index}][accountNo]`]}
                                            value={values[`fundingBankAccounts[${index}][accountNo]`]}
                                            isLoading={isLoading}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <AccountCell
                                            error={errors[`fundingBankAccounts[${index}][currency]`]}
                                            value={values[`fundingBankAccounts[${index}][currency]`]}
                                            isLoading={isLoading}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box className={classes.documentInner}>
                                            <AccountCellDocument
                                                error={errors[`fundingBankAccounts[${index}][proofOfAccount]`]}
                                                value={values[`fundingBankAccounts[${index}][proofOfAccount]`]}
                                                isLoading={isLoading}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <Fab className={classes.fabButton}
                                             onClick={() => onEdit(index)}
                                        >
                                            <Pen/>
                                        </Fab>
                                        <Fab className={classes.fabButton}
                                             onClick={() => onDeleteRow(index)}
                                        >
                                            <CloseThin/>
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Box>
            <Box>
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={isReadOnly}
                    onClick={() => AddBankAccount()}
                >
                    {t("formFields.addBankAccount")}
                </Button>
            </Box>
        </Box>
    );
};

export default FundingBankAccounts2;

import React, {Suspense, lazy} from "react";
import Skeleton from "react-loading-skeleton";
import {ReactComponent as Rect} from '../../assets/broken.svg'


const getLazyImage = (props) =>
    lazy(
        () =>
            new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = () => {
                    resolve({default: () => <img {...props} /> });
                };
                image.onerror = () => {
                    // image can't be loaded - show svg instead
                    resolve({ default: () => <Rect/>})
                };
                image.src = props.src;
            })
    );

const LazyImageSuspense = (props) => {

    const {skeletonHeight, ...restProps} = props;
    const Image = getLazyImage(restProps);

    return (
        <Suspense fallback={<Skeleton width={"100%"} height={skeletonHeight ? skeletonHeight : "200px"}/>}>
            <Image />
        </Suspense>
    );
}

export default React.memo(LazyImageSuspense);

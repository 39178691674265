import React from 'react';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    fieldSet: {
        paddingBottom:"38px"
    },
    title: {
        marginBottom:"32px"
    },
}));

const Fieldset = (props) => {
    const {children, title} = props;
    const classes = useStyles();
    return (
        <div className={classes.fieldSet}>
            {title && <Typography variant="h2" className={classes.title}>{title}</Typography>}
            {children}
        </div>
    );
};

export default Fieldset;

import React from 'react';
import Box from "@material-ui/core/Box";
import FormatMoney from "../FormatMoney";

const CellCardAmount = (props) => {
    const {cardAmount, cardCurrency} = props;
    return cardAmount ? <><Box component={"span"} marginRight={"5px"}>{cardCurrency}</Box><span><FormatMoney>{cardAmount}</FormatMoney></span></> : ""
};

export default CellCardAmount;

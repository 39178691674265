export const E_CURRENCIES_ALIAS = {
    EURe: 'EUR',
    USDC: 'USD',
    ZCHF: 'CHF',
    XSGD: 'SGD',
    USDT: 'USDT',
    DAI: 'DAI',
    POL: 'POL'
};

export const CURRENCIES_ALIAS = {
    EUR: 'EURe',
    USD: 'USDC',
    CHF: 'ZCHF',
    SGD: 'XSGD',
    POL: 'POL'
};

export const TYPICAL_CURRENCIES = ['EUR', 'USD', 'CHF', 'SGD']

export const COINS = ['POL', 'ETH', 'BTC']
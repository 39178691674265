import React from 'react';
import SelectField from "../formElements/SelectField";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

const SelectTab = (props) => {
    const {value,options, onSelectChange, settingsDarkMode } = props;

    const useStyles = makeStyles(theme => ({
        wrapper: {
            position: 'relative',
            flexShrink: '0',
            width: '195px',
            [theme.breakpoints.down('1023')]: {
                maxWidth: '145px',
                width: '100%'
            },
            [theme.breakpoints.down('767')]: {
                maxWidth: '100%'
            },
            "& .MuiInputBase-root": {
                width: '100%',
            },
        }
    }));


    const classes = useStyles();


    return (
        <Box className={classes.wrapper}>
            <SelectField
                value={value}
                options={options}
                onSelectChange={(val) => onSelectChange(val)}
                hideEmpty
                smallStyle={true}
                settings={settingsDarkMode}
            />
        </Box>
    );
};

export default SelectTab;

import React, {useEffect, useState} from 'react';
import DialogPopUp from "../../../../../DialogPopUp";
import {
    FormControl,
    makeStyles,
    Button,
    Box
} from "@material-ui/core";
import useForm2 from "../../../../../../hooks/useForm2";
import InputField from "../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../services/api";
import DateField from "../../../../../formElements/DateField";
import useExpenseTabsQuery from "../../../../../../hooks/useExpenseTabsQuery";
import RadioFields from "../../../../../formElements/RadioFields";
import ReportCounter from "./ReportCounter";
import moment from "moment-timezone";
import {Can} from "../../../../../../Context/Can";
import CustomReportType from "./CustomReportType";
import ErrorBoundary from "../../../../../ErrorBoundary";
import ScopeFields from "./ScopeFields";
import {useSelector} from "react-redux";
import { isDarkModeActive } from '../../../../../App/App';

const service = new Api();

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        minWidth: '340px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'initial'
        }
    },
    buttonsWrapper: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px'
    },
    button: {
        marginTop: '24px',
        letterSpacing: '0'
    },
    actionButton:{
        background: "#24dac4",
        fontWeight: "600",
        padding: "5px 15px",
        color: "#fff",
        margin:"10px",
        textTransform: "capitalize",
        boxShadow:"none",
        '&:hover':{
            background:"rgba(0, 0, 0, 0.8)"
        }
    },
    form:{
        position: 'relative',
        minWidth: '340px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'initial'
        }
    },
    field: {
        '& label + .MuiInput-formControl': {
            marginTop: '0'
        },
        "&:not(:last-child)": {
            marginBottom: '32px',
            [theme.breakpoints.down('1023')]: {
                marginBottom: '24px'
            }
        }
    },
    itemRadio: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px',
        }
    },
}));

const DialogExport = (props) => {
    const {setOpen, title, open, exportFormat, dateFormat, setMessage, selected, setSelected, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const tabQuery = useExpenseTabsQuery();
    const tabsExpenses = useSelector(state => state.tabs["expenses"]);

    const xlsxReportTypes = [
        {value: "basic", label: t("formFields.xlsxBasic")},
        {value: "full", label: t("formFields.xlsxFull")},
        {value: "custom", label: t("formFields.xlsxCustom")}
    ];

    const withDateRange = (selected.length === 0);


    const submit = async () => {

        try {
            setIsSubmitting(true);
            await service.getExpensesExport(queryForExport);
            setSelected([]);
            setOpen(false);
            setMessage("report.created", "success");
        }catch (e) {
            setServerErrors(e);
        }finally {
            setIsSubmitting(false);
        }

    }

    const validate = (values) => {
        const errors = {};
        const fileNameRegex = /^[\w]+$/;

        if(!values.fileName){
            errors.fileName = t("errors.reportNameIsNotValid", {field:"$t(formFields.fileName)"});
        }else if (!fileNameRegex.test(values.fileName)) {
            errors.fileName = t("errors.reportNameIsNotValid", {field:"$t(formFields.fileName)"});
        }

        if(withDateRange){
            if (!values['date[gte]']) {
                errors['date[gte]'] = t("errors.required", {field:"$t(formFields.dateFrom)"});
            }
            if (!values['date[lte]']) {
                errors['date[lte]'] = t("errors.required", {field:"$t(formFields.dateTo)"});
            }
        }
        if(values["xlsxReportType"] === "custom"){
            if(!values["fields"] || values["fields"].length < 1){
                errors["fields"] = t("errors.notValid");
            }
        }

        if(values["scope"] === "user" && !values["user"]){
            errors['user'] = t("errors.required", {field:"$t(formFields.user)"});
        }
        if(values["scope"] === "branch" && !values["branch"]){
            errors['branch'] = t("errors.required", {field:"$t(formFields.branch)"});
        }
        if(values["scope"] === "group" && !values["group"]){
            errors['group'] = t("errors.required", {field:"$t(formFields.group)"});
        }

        return errors;
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    const queryForExport = {
        ...values,
        ...tabQuery,
        ext:exportFormat
    }

    if(selected.length){
        queryForExport.ids = selected;
    }

    const onExitHandler = () => {
        fillFormValues({});
    }

    const onDateChange = (id, date) => {
        let midnight = undefined;
        if (date && date.isValid()) {
            if (id === 'date[gte]') {
                midnight = moment(date).startOf('day');
            } else {
                midnight = moment(date).endOf('day');
            }
        }
        handleChange(id, midnight);
    }


    useEffect(() => {
        if(exportFormat === "xlsx"){
            // select "basic" by default
            handleChange("xlsxReportType", "basic");
            handleChange("notReported", "false");
        }
    },[exportFormat, open]);

    const {xlsxReportType} = values;

    useEffect(() => {
        if(xlsxReportType !== "custom"){
           // reset fields if not custom
            if(values["fields"]){
                handleChange("fields")
            }
        }
    },[xlsxReportType]);

    const onExportAndMarkAsReported = (e) => {
        handleChange("markAsReported", true);
        handleSubmit(e);
    }

    const onOnlyExport = (e) => {
        handleChange("markAsReported", false);
        handleSubmit(e);
    }

    return (
        <DialogPopUp
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="export-dialog-title"
            aria-describedby="export-dialog-description"
            title={title}
            onExited={onExitHandler}
            settingsDarkMode={settingsDarkMode}
            pageTitleClass={`card-title--red`}
        >
            <Box className={classes.item}>
                <ErrorBoundary>
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <FormControl fullWidth error={!!errors.fileName} className={classes.field}>
                            <InputField
                                label={t("formFields.fileName")}
                                name="fileName"
                                value={values["fileName"]}
                                onInputChange={(val) => handleChange("fileName", val)}
                                error={errors["fileName"]}
                            />
                        </FormControl>

                        {tabsExpenses.active !== "personal" && (
                            // hide for personal
                            <Box className={classes.field}>
                                <ScopeFields
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                />
                            </Box>
                        )}

                        {exportFormat === "xlsx" && (
                            <FormControl fullWidth error={!!errors['xlsxReportType']} className={classes.field}>
                                <RadioFields
                                    options={xlsxReportTypes}
                                    name={"xlsxReportType"}
                                    label={t("formFields.xlsxReportType")}
                                    value={values["xlsxReportType"]}
                                    onRadioChange={(val) => handleChange("xlsxReportType", val)}
                                    error={errors["xlsxReportType"]}
                                    settings={settingsDarkMode}
                                    className={classes.itemRadio}
                                />
                            </FormControl>
                        )}

                        {values["xlsxReportType"] === "custom" && (
                            <Box className={classes.field}>
                                <CustomReportType handleChange={handleChange} fields={values["fields"]} error={errors["fields"]}/>
                            </Box>
                        )}

                        {withDateRange && (
                            <>
                                <FormControl fullWidth error={!!errors['date[gte]']} className={classes.field}>
                                    <DateField
                                        label={t("formFields.dateFrom")}
                                        placeholder={t("formFields.date")}
                                        dateFormat={dateFormat}
                                        onDateChange={(date) => onDateChange("date[gte]", date)}
                                        value={values["date[gte]"]}
                                        error={errors["date[gte]"]}
                                        name="date[gte]"
                                        KeyboardButtonProps={{
                                            'aria-label': 'date from',
                                        }}
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors['date[lte]']} className={classes.field}>
                                    <DateField
                                        label={t("formFields.dateTo")}
                                        placeholder={t("formFields.date")}
                                        dateFormat={dateFormat}
                                        onDateChange={(date) => onDateChange("date[lte]", date)}
                                        value={values["date[lte]"]}
                                        error={errors["date[lte]"]}
                                        name="date[lte]"
                                        KeyboardButtonProps={{
                                            'aria-label': 'date to',
                                        }}
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </FormControl>

                                <FormControl fullWidth className={classes.field}>
                                    <RadioFields
                                        options={[{value:"false", label:t("formFields.allExpenses")}, {value:"true", label:t("formFields.onlyNotReported")}]}
                                        name={"notReported"}
                                        value={values["notReported"]}
                                        onRadioChange={(val) => handleChange("notReported", val)}
                                        error={errors["notReported"]}
                                        settings={settingsDarkMode}
                                        className={classes.itemRadio}
                                    />
                                </FormControl>
                            </>
                        )}
                        <Box className={classes.field}>
                            <ReportCounter queryForExport={queryForExport} settingsDarkMode={settingsDarkMode} />
                        </Box>
                    </form>
                </ErrorBoundary>

                <div className={classes.buttonsWrapper}>
                    <Button
                        onClick={onOnlyExport}
                        disabled={isSubmitting}
                        className={classes.button}
                    >
                        {t("expense.export")}
                    </Button>
                    <Can I="markAsReported" a={"expense"}>
                        <Button
                            onClick={onExportAndMarkAsReported}
                            disabled={isSubmitting}
                            className={`btn-stroke ${classes.button}`}
                        >
                            {t("formFields.markAsReported")}
                        </Button>
                    </Can>
                </div>
            </Box>
        </DialogPopUp>
    );
};

export default DialogExport;

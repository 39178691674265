import React, {useEffect} from 'react';

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import {FormControl, makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useForm2 from "../../../../../../../hooks/useForm2";
import DialogPopUp from "../../../../../../DialogPopUp";
import InputField from "../../../../../../formElements/InputField";
import SelectField from "../../../../../../formElements/SelectField";
import DateField from "../../../../../../formElements/DateField";
import Grid from "@material-ui/core/Grid";
import isDateValid from "../../../../../../../helpers/idValidDate";
import FileUploaderInline from "../../../../../../formElements/FileUploaderInline";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
            content: {
                width: "880px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px"
            },
            formControlWrapper: {
                paddingRight: "10px",
                paddingLeft: "10px"
            },
            fileUploaderWrapper:{
                paddingRight: "10px",
                paddingLeft: "10px",
                "& .MuiListItem-gutters":{
                    paddingLeft:0
                },
                "& .MuiFormLabel-root":{
                    textTransform:"lowercase"
                }
            }
        }
    )
);

const ManuallyPopup = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {isOpen, setIsOpen, outerValues, index, memoizedCallback, onSave, personTypes, countries, dateFormat, isEditMode, proofOfIdentityCategories, proofOfAddressCategories} = props;


    const submit = async () => {
        onSave(index);

        const {personType, firstName, lastName, country, addrl1, addrl2, postalCode, city, idNo, dob, nationality, proofOfIdentity, proofOfIdentityCategory, proofOfAddress, proofOfAddressCategory} = values;
        // change parent state

        memoizedCallback(`officersAndShareholders[${index}][personType]`, personType);

        memoizedCallback(`officersAndShareholders[${index}][firstName]`, firstName);
        memoizedCallback(`officersAndShareholders[${index}][lastName]`, lastName);

        memoizedCallback(`officersAndShareholders[${index}][country]`, country);
        memoizedCallback(`officersAndShareholders[${index}][addrl1]`, addrl1);
        memoizedCallback(`officersAndShareholders[${index}][addrl2]`, addrl2);
        memoizedCallback(`officersAndShareholders[${index}][postalCode]`, postalCode);
        memoizedCallback(`officersAndShareholders[${index}][city]`, city);
        memoizedCallback(`officersAndShareholders[${index}][idNo]`, idNo);
        memoizedCallback(`officersAndShareholders[${index}][dob]`, dob);
        memoizedCallback(`officersAndShareholders[${index}][nationality]`, nationality);
        memoizedCallback(`officersAndShareholders[${index}][proofOfIdentity]`, proofOfIdentity);
        memoizedCallback(`officersAndShareholders[${index}][proofOfIdentityCategory]`, proofOfIdentityCategory);
        memoizedCallback(`officersAndShareholders[${index}][proofOfAddress]`, proofOfAddress);
        memoizedCallback(`officersAndShareholders[${index}][proofOfAddressCategory]`, proofOfAddressCategory);


    }

    const validate = (values) => {

        const errors = {};
        if (!values[`personType`]) {
            errors[`personType`] = t("errors.required", {field: "$t(formFields.personType)"});
        }
        if (!values[`firstName`]) {
            errors[`firstName`] = t("errors.required", {field: "$t(formFields.firstName)"});
        }
        if (!values[`lastName`]) {
            errors[`lastName`] = t("errors.required", {field: "$t(formFields.lastName)"});
        }
        if (!values[`addrl1`]) {
            errors[`addrl1`] = t("errors.required", {field: "$t(formFields.addrl1)"});
        }
        if (!values[`postalCode`]) {
            errors[`postalCode`] = t("errors.required", {field: "$t(formFields.postalCode)"});
        }
        if (!values[`city`]) {
            errors[`city`] = t("errors.required", {field: "$t(formFields.city)"});
        }
        if (!values[`country`]) {
            errors[`country`] = t("errors.required", {field: "$t(formFields.country)"});
        }
        if (!values[`idNo`]) {
            errors[`idNo`] = t("errors.required", {field: "$t(formFields.idNo)"});
        }
        if (!values[`nationality`]) {
            errors[`nationality`] = t("errors.required", {field: "$t(formFields.nationality)"});
        }
        if (!isDateValid(values[`dob`])) {
            errors[`dob`] = t("errors.notValid", {field: "$t(formFields.dob)"});
        }
        if (!values[`proofOfIdentity`]) {
            errors[`proofOfIdentity`] = t("errors.required", {field: "$t(formFields.proofOfIdentity)"});
        }
        if (!values[`proofOfIdentityCategory`]) {
            errors[`proofOfIdentityCategory`] = t("errors.required", {field: "$t(formFields.proofOfIdentityCategory)"});
        }
        if (!values[`proofOfAddress`]) {
            errors[`proofOfAddress`] = t("errors.required", {field: "$t(formFields.proofOfAddress)"});
        }
        if (!values[`proofOfAddressCategory`]) {
            errors[`proofOfAddressCategory`] = t("errors.required", {field: "$t(formFields.proofOfAddressCategory)"});
        }

        return errors;
    }



    const { handleChange, handleSubmit, values, errors, fillFormValues, setServerErrors } = useForm2(
        submit,
        validate
    );


    const onHandleChange = (name, value) => {
        handleChange([name], value)
    }



    useEffect(() => {
        // fill local values
        fillFormValues(outerValues)
    },[]);



    return (
        <DialogPopUp
            open={isOpen}
            onClose={() => setIsOpen(false)}
            title={t("savePersonTitle")}
        >
            <DialogContent className={classes.content}>
                <Grid container spacing={5}>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`personType`]} fullWidth className={classes.formControlWrapper}>
                            <SelectField
                                label={t(`formFields.personType`)}
                                name={`personType`}
                                value={values[`personType`]}
                                error={errors[`personType`]}
                                options={personTypes}
                                onSelectChange={(val) => onHandleChange(`personType`, val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`nationality`]} fullWidth className={classes.formControlWrapper}>
                            <SelectField
                                label={t(`formFields.nationality`)}
                                name={`nationality`}
                                value={values[`nationality`]}
                                error={errors[`nationality`]}
                                options={countries}
                                onSelectChange={(val) => onHandleChange(`nationality`, val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`firstName`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`firstName`}
                                label={t(`formFields.firstName`)}
                                placeholder={t(`formFields.firstName`)}
                                value={values[`firstName`]}
                                onInputChange={(val) => onHandleChange("firstName", val)}
                                error={errors[`firstName`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`lastName`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`lastName`}
                                label={t(`formFields.lastName`)}
                                placeholder={t(`formFields.lastName`)}
                                value={values[`lastName`]}
                                onInputChange={(val) => onHandleChange("lastName", val)}
                                error={errors[`lastName`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`addrl1`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`addrl1`}
                                label={t(`formFields.addrl1`)}
                                placeholder={t(`formFields.addrl1`)}
                                value={values[`addrl1`]}
                                onInputChange={(val) => onHandleChange("addrl1", val)}
                                error={errors[`addrl1`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`addrl2`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`addrl2`}
                                label={`${t("formFields.addrl2")} ${t("formFields.optional")}`}
                                placeholder={t(`formFields.addrl2`)}
                                value={values[`addrl2`]}
                                onInputChange={(val) => onHandleChange("addrl2", val)}
                                error={errors[`addrl2`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`postalCode`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`postalCode`}
                                label={t(`formFields.postCode`)}
                                placeholder={t(`formFields.postCode`)}
                                value={values[`postalCode`]}
                                onInputChange={(val) => onHandleChange("postalCode", val)}
                                error={errors[`postalCode`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`city`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`city`}
                                label={t(`formFields.city`)}
                                placeholder={t(`formFields.city`)}
                                value={values[`city`]}
                                onInputChange={(val) => onHandleChange("city", val)}
                                error={errors[`city`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item sm={6} xs={12}>
                                <FormControl error={!!errors[`country`]} fullWidth className={classes.formControlWrapper}>
                                    <SelectField
                                        label={t(`formFields.country`)}
                                        name={`country`}
                                        value={values[`country`]}
                                        error={errors[`country`]}
                                        options={countries}
                                        onSelectChange={(val) => onHandleChange(`country`, val)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`idNo`]} fullWidth className={classes.formControlWrapper}>
                            <InputField
                                name={`idNo`}
                                label={t(`formFields.idNo`)}
                                placeholder={t(`formFields.idNo`)}
                                value={values[`idNo`]}
                                onInputChange={(val) => onHandleChange("idNo", val)}
                                error={errors[`idNo`]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl fullWidth error={!!errors[`dob`]} className={classes.formControlWrapper}>
                            <DateField
                                placeholder={t("formFields.dob")}
                                label={t("formFields.dob")}
                                dateFormat={dateFormat}
                                onDateChange={(date) => onHandleChange(`dob`, date)}
                                value={values["dob"]}
                                name={`dob`}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`proofOfIdentityCategory`]} fullWidth className={classes.formControlWrapper}>
                            <SelectField
                                label={t("formFields.proofOfIdentityCategory")}
                                name={`proofOfIdentityCategory`}
                                value={values["proofOfIdentityCategory"]}
                                error={errors[`proofOfIdentityCategory`]}
                                options={proofOfIdentityCategories}
                                onSelectChange={(val) => onHandleChange(`proofOfIdentityCategory`, val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box className={classes.fileUploaderWrapper}>
                            <FileUploaderInline
                                value={values[`proofOfIdentity`]}
                                fieldName={"proofOfIdentity"}
                                error={errors[`proofOfIdentity`]}
                                handleChange={onHandleChange}
                                label={t("formFields.proofOfIdentity")}
                            />
                        </Box>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <FormControl error={!!errors[`proofOfAddressCategory`]} fullWidth className={classes.formControlWrapper}>
                            <SelectField
                                label={t("formFields.proofOfAddressCategory")}
                                name={`proofOfAddressCategory`}
                                value={values["proofOfAddressCategory"]}
                                error={errors[`proofOfAddressCategory`]}
                                options={proofOfAddressCategories}
                                onSelectChange={(val) => onHandleChange(`proofOfAddressCategory`, val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box className={classes.fileUploaderWrapper}>
                            <FileUploaderInline
                                value={values[`proofOfAddress`]}
                                fieldName={"proofOfAddress"}
                                error={errors[`proofOfAddress`]}
                                handleChange={onHandleChange}
                                label={t("formFields.proofOfAddress")}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={Object.keys(validate(values)).length > 0}
                >
                    {t("formFields.save")}
                </Button>
            </DialogActions>
        </DialogPopUp>
    );
};

export default ManuallyPopup;

import React from 'react';
import LimitGroup from "../LimitGroup";
import FutureBlocking from "../FutureBlocking";
import FormControl from "@material-ui/core/FormControl";
import InputField from "../../../../../../formElements/InputField";
import SelectField from "../../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import CardToggles from "../CardToggles";
import CardOptionWrapper from "../CardOptionWrapper";
import {Can} from "@casl/react";
import {subject} from "@casl/ability";
import BlockUnblockPermsGroup from "../BlockUnblockPermsGroup";

const CardOptions = (props) => {
    const {t} = useTranslation();

    const {card, handleErrorMessage, setMessage, setCard, ability, decimal, dateFormat, cardIsDeleted, currentUser} = props;
    const {parent:{account={}}={}, user={}, name} = card;
    const canShowEmployee = card.user._id !== currentUser._id;

    return (
        <>
            <Grid container spacing={4}>
                {name && (
                    <Grid item xs={12} sm={6}>
                        <CardOptionWrapper>
                            <FormControl fullWidth>
                                <InputField
                                    name="name"
                                    label={t("formFields.cardName")}
                                    value={name}
                                    margin="normal"
                                    disabled
                                />
                            </FormControl>
                        </CardOptionWrapper>
                    </Grid>
                )}
                {canShowEmployee && (
                    <Grid item xs={12} sm={6}>
                        <CardOptionWrapper>
                            <FormControl fullWidth>
                                <SelectField
                                    disabled
                                    label={t("formFields.responsibleEmployee")}
                                    name={"user"}
                                    value={user._id}
                                    options={[{label:`${user.firstName} ${user.lastName}`, value:user._id ? user._id : ""}]}
                                />
                            </FormControl>
                        </CardOptionWrapper>
                    </Grid>
                )}
                {!cardIsDeleted && (
                    <>
                        <Grid item xs={12}>
                            <LimitGroup
                                currencyOptions={[{label: account.currency, value: account._id ? account._id : ""}]}
                                currencyValue={account._id}
                                decimal={decimal}
                                card={card}
                                setMessage={setMessage}
                                handleErrorMessage={handleErrorMessage}
                                setCard={setCard}
                                ability={ability}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FutureBlocking
                                dateFormat={dateFormat}
                                card={card}
                                handleErrorMessage={handleErrorMessage}
                                setMessage={setMessage}
                                setCard={setCard}
                                ability={ability}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            {!cardIsDeleted && (
                <Grid item xs={12}>
                    <Can I="blockUnblockPermsGroup" this={subject("card", {company:card.company._id, type: card.type})} ability={ability}>
                        <BlockUnblockPermsGroup
                            setMessage={setMessage}
                            handleErrorMessage={handleErrorMessage}
                            card={card}
                            setCard={setCard}
                        />
                    </Can>
                    <CardToggles
                        setMessage={setMessage}
                        handleErrorMessage={handleErrorMessage}
                        card={card}
                        setCard={setCard}
                        ability={ability}
                    />
                </Grid>
            )}
        </>
    );
};

export default CardOptions;

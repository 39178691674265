import React, { useState, useEffect } from "react";
import {
    Box,
    makeStyles,
    FormControl,
} from "@material-ui/core"
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import moment from 'moment-timezone';
import useForm2 from "../../../../../hooks/useForm2";
import { handleErrorMessage } from "../../../../Messages/actions";
import formatMoney from "../../../../../helpers/formatMoney";
import useMounted from "../../../../../hooks/useMounted";
import useActiveTab from "../../../../../hooks/useActiveTab";
import Api from "../../../../../services/api";
import SelectField from "../../../../formElements/SelectField";
import ExpenseChart from "./ExpenseChart";
import { isDarkModeActive } from "../../../../App/App";

const service = new Api();

const getMonths = () => {
    return [
        // past months
        moment().startOf("month").startOf("day").subtract(11, 'months').format(),
        moment().startOf("month").startOf("day").subtract(10, 'months').format(),
        moment().startOf("month").startOf("day").subtract(9, 'months').format(),
        moment().startOf("month").startOf("day").subtract(8, 'months').format(),
        moment().startOf("month").startOf("day").subtract(7, 'months').format(),
        moment().startOf("month").startOf("day").subtract(6, 'months').format(),
        moment().startOf("month").startOf("day").subtract(5, 'months').format(),
        moment().startOf("month").startOf("day").subtract(4, 'months').format(),
        moment().startOf("month").startOf("day").subtract(3, 'months').format(),
        moment().startOf("month").startOf("day").subtract(2, 'months').format(),
        moment().startOf("month").startOf("day").subtract(1, 'months').format(),

        // current month
        moment().startOf("month").startOf("day").format(),
    ];
}

const useStyles = makeStyles(theme => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    headSelect: {
        display: 'flex',
        marginLeft: 'auto',
        minWidth: '164px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            // margin: '0 0 16px'
        }
    }
}))

const ExpenseSpentInvesthub = (props) => {
    const {
        handleErrorMessage,
        decimal,
        // eslint-disable-next-line no-unused-vars
        drawerOuterRef,
        // eslint-disable-next-line no-unused-vars
        dashboardTableMinHeight,
        // eslint-disable-next-line no-unused-vars
        underBarRef,
        settingsDarkMode
    } = props;
    const {t} = useTranslation();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const [expensesByDay, setExpensesByDay] = useState([]);
    const [
        // eslint-disable-next-line no-unused-vars
        expensesByCategory,
        setExpenseByCategory
    ] = useState([]);
    const [currency, setCurrency] = useState("");
    const [
        // eslint-disable-next-line no-unused-vars
        isFilterOpen,
        // eslint-disable-next-line no-unused-vars
        setIsFilterOpen
    ] = useState(false);

    const [totalAmount, setTotalAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const isMounted = useMounted();
    const activeTab = useActiveTab('dashboard');


    const [
        months,
        // eslint-disable-next-line no-unused-vars
        setMonths
    ] = useState(() => {
        const months = getMonths();
        return months.map((date) => ({value: date, label: moment(date).format('MMMM YYYY')}))
    });

    const submit = async  () => {

        setIsLoading(true);

        try{
            const query = {...values, fromTab: activeTab};

            const amountByDayPromise = service.getDashboardAmountsByDay(query);
            const amountByCategoryPromise = service.getDashboardAmountsByCategory(query);

            const [amountByDayResult, amountByCategoryResult] = await Promise.all([
                amountByDayPromise,
                amountByCategoryPromise
            ]);

            setExpensesByDay(amountByDayResult.data.result);
            setTotalAmount(amountByDayResult.data.info.total);
            setCurrency(amountByDayResult.data.info.displayCurrency);

            setExpenseByCategory(amountByCategoryResult.data.result);


        }catch (e) {
            console.log(e);
            handleErrorMessage(e);

            setExpensesByDay([]);
            setTotalAmount(0);
            setCurrency("");

            setExpenseByCategory([]);

        }finally {
            setIsLoading(false);
        }
    }

    const validate = () => ({});

    const {
        handleChange,
        values,
        fillFormValues,
        // eslint-disable-next-line no-unused-vars
        setServerErrors,
        // eslint-disable-next-line no-unused-vars
        handleSubmit
    } = useForm2(
        submit,
        validate
    );

    useEffect(() => {
        const dataForFilter = {
            "date[gte]": moment().startOf("month").startOf("day").format(),
        };
        fillFormValues(dataForFilter);
    },[]);

    useEffect(() => {
        // if date change or tab
        if(values['date[gte]'] && activeTab){
            submit();
        }

    },[values['date[gte]'], activeTab]);

    return (
        <>
            <Box className={classes.head}>
                <Box className={`${classes.headTitle} card-title card-title--blue`}>
                    {/* NEED TRANSLATION */}
                    {t('Expenses spent')}
                    {' '}
                    {isLoading ? '...' : (
                        <>
                            {`${currency} ${formatMoney(totalAmount, decimal)}`}
                        </>
                    )}
                </Box>

                <Box className={classes.headSelect}>
                    <FormControl fullWidth>
                        <SelectField
                            name={"date[gte]"}
                            value={values["date[gte]"]}
                            options={months}
                            onSelectChange={(val) => handleChange("date[gte]", val)}
                            hideEmpty
                            settings={settingsDarkMode}
                            smallStyle={ true }
                            strokeStyle={ true }
                        />
                    </FormControl>
                </Box>
            </Box>

            <ExpenseChart
                settingsDarkMode={settingsDarkMode}
                expenses={expensesByDay}
                currency={currency}
                dateLte={values["date[gte]"]}
                decimal={decimal}
                isLoading={isLoading}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    const {user} = state;
    const {settings} = user;
    const {decimal} = settings.regional;
    return {
        decimal,
    };
}

export default connect(
    mapStateToProps, {handleErrorMessage}
)(ExpenseSpentInvesthub);

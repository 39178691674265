import React, {useEffect, useState} from 'react';
import UserForm from "../../../../../UserForm";
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import {Link, Prompt, withRouter} from "react-router-dom";
import Api from "../../../../../../services/api";
import useForm2 from "../../../../../../hooks/useForm2";
import {makeStyles} from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../../../../../CompanyCountryCheck";
import BackButton from "../../../../../BackButton";
import VolveSnackbar from "../../../../../VolveSnackbar";
import {ReactComponent as Logo} from "../../../../../../assets/logoInvesthub.svg";
import useBeforeUnload from "../../../../../../hooks/useBeforeUnload";
import Grid from "@material-ui/core/Grid";
import {Ability, subject} from "@casl/ability";
import {confirm} from "../../../../../../helpers/confirm";
import {getLoggedUser} from "../../../../../User/actions";
import { isDarkModeActive } from '../../../../../App/App';

const service = new Api();
const useStyles = makeStyles(theme => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },

    infoItem: {
        flexGrow: 1,
        width: "100%"
    },
    snackbar: {
        width: "100%",
        marginBottom: "30px",
        fontSize: "1rem",
        '& svg path': {
            fill: '#fff'
        },
    },
    warning: {
        background: "#ff9800",
    },
    error: {
        background: "#d32f2f",
    },

    link:{
        color: "#24DAC4",
        fontSize: "15px",
        lineHeight: "26px",
        textDecoration:"none",
        "&:hover":{
            textDecoration:"none",
            color: "#1EB3A1",
            background: "none"
        }
    },
}));


const User = (props) => {
    const {
        handleErrorMessage,
        setMessage,
        match,
        history,
        userId,
        getLoggedUser,
        isWhiteLabel,
        // eslint-disable-next-line no-unused-vars
        underBarRef,
        settingsDarkMode
    } = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const {id} = match.params;


    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [isMount, setIsMount] = useState(false);

    const [roles, setRoles] = useState([]);
    const [titles, setTitles] = useState([]);
    const [countries, setCountries] = useState([]);

    const [groups, setGroups] = useState([]);
    const [branches, setBranches] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [user, setUser] = useState({});
    const [ability, setAbility] = useState(new Ability());

    const canUpdateUser = (userId, companyId) => {
        return ability.can("update", subject("user", {_id: userId, company: companyId }));
    }

    const fetchData = async () => {
       try{
           setIsDataFetching(true);
           const userPromise = service.getUsersUser(id);
           const rolesPromise = service.getServiceData({dataFor: "roles", isWhiteLabel});
           const titlesPromise = service.getServiceData({dataFor: "userPrefixes"});
           const countriesPromise = service.getServiceData({dataFor: "countries"});
           const groupsPromise = service.getGroupsAll();
           const branchesPromise = service.getBranchesAll();
           const departmentsPromise = service.geDepartmentsAll();

           const response = await Promise.all([rolesPromise, titlesPromise, countriesPromise, groupsPromise, branchesPromise, departmentsPromise, userPromise]);
           const [rolesResult, titlesResult, countriesResult, groupsResult, branchesResult, departmentsResult, userResult] = response;

           setUser(userResult.data);

           const mappedCountries = countriesResult.data.map((country) => {
               return {
                   label: country.name,
                   value: country["country-code"]
               }
           });

           const mappedGroups = groupsResult.data.groups.map(({_id, name}) => ({label: name, value: _id}));
           const mappedBranches = branchesResult.data.branches.map(({_id, name}) => ({label: name, value: _id}));
           const mappedRoles = rolesResult.data.roles.map(({id, name}) => ({label: name, value: id}));
           const mappedDepartments = departmentsResult.data.departments.map(({_id, name}) => ({label: name, value: _id}));

           setDepartments(mappedDepartments);
           setGroups(mappedGroups);
           setBranches(mappedBranches);
           setCountries(mappedCountries);
           setRoles(mappedRoles);
           setTitles(titlesResult.data);

       }catch (e) {
           console.log(e);
           handleErrorMessage(e);
       } finally {
           setIsDataFetching(false);
       }
    }



    useEffect(() => {
        setIsMount(true);
        fetchData();
    }, []);

    const validate = (values) => {
        return {};
    };

    const {handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues} = useForm2(
        submit,
        validate,
    );

    const onRemoveUser = async (id) => {
        if (await confirm()) {
            try {
                await service.deleteUser(id);
                setMessage("user.deleted", 'success');
                history.push(`/users`)
            }catch (e) {
                handleErrorMessage(e);
            }
        }
    }

    async function submit() {
        try {
            setIsLoading(true);
            await service.updateUser(id, values);
            if(userId === user._id){
                // user updates himself
                getLoggedUser(); // update user state
            }
            setMessage("successfullyUpdated", 'success');
            setValuesWasChanged(false);
            history.push(`/users`);
        } catch (e) {
            setServerErrors(e);
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {

        const fields = ["title", "firstName", "status", "middleName", "lastName", "email", "phone", "usersForApproval", "email", "country", "roles", "group", "branch", "position", "department", "personnelNumber", "comment", "addrl1", "addrl2", "postCode", "city", "country", "walletAddress", "iban"];

        const objectToFill = {};

        if(Object.keys(user)){
            const ability = new Ability(user.rules);
            setAbility(ability);

            fields.forEach((field) => {
                objectToFill[field] = user[field];
            });
            fillFormValues(objectToFill);
        }
    }, [user]);

    const canShowKycMessage = !isDataFetching && user.status === "active";
    const canShowStatusMessage = Object.keys(user).length > 0 && (user.status !== "active");

    const canVerifyKyc = canUpdateUser(user._id, user.company);

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }

    return (
        <div className="card">
            <div className={classes.head}>
                <BackButton />
            </div>

            <>
                <Prompt
                    when={valuesWasChanged}
                    message={t("unsavedChanges")}
                />

                <CompanyCountryCheck noText>
                    {canShowKycMessage && (
                        <>
                            {(!user.kyc && canVerifyKyc) && (
                                <Grid container spacing={5}>
                                    <Grid item xs={12} lg={6}>
                                        <VolveSnackbar
                                            message={<>{t("verifyThisKyc")}</>}
                                            action={<><Link to={`/user-verification/${id}`} className={classes.link}>{t("formFields.verify")}</Link></>}
                                            icon={<Logo width={"27px"} height={"27px"}/>}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {user.kyc === "pending" && (
                                <Grid container spacing={5}>
                                    <Grid item xs={12} lg={6}>
                                        <VolveSnackbar
                                            message={<>{t("kycIsPending")}</>}
                                            icon={<Logo width={"27px"} height={"27px"}/>}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </CompanyCountryCheck>

                {canShowStatusMessage && (
                    <SnackbarContent
                        message={t("user.notActive")}
                        className={`${classes.snackbar} ${classes.warning}`}
                    />
                )}
                <UserForm
                    values={values}
                    errors={errors}
                    roles={roles}
                    titles={titles}
                    countries={countries}
                    groups={groups}
                    departments={departments}
                    branches={branches}
                    isDataFetching={isDataFetching}
                    handleChange={handleChangeWithDetectChanges}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    user={user}
                    match={match}
                    ability={ability}
                    onRemoveUser={onRemoveUser}
                    settingsDarkMode={settingsDarkMode}
                />
            </>
        </div>
    );
};


const mapStateToProps = (state) => {
    const {user} = state;
    const {userData} = user;
    const {isWhiteLabel} = userData.company;
    return {
        isWhiteLabel,
        userId:userData._id
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {handleErrorMessage, setMessage, getLoggedUser}),
)(User)


import React, {useState} from 'react';
// import Greeting from "./Greeting";
import {compose} from "redux";
import {connect} from "react-redux";
import ExpenseSpentInvesthub from './ExpenseSpentInvesthub/ExpenseSpentInvesthub';
import ExpenseCategoryInvesthub from './ExpenseCategoryInvesthub/ExpenseCategoryInvesthub';
import MyLatestTransactions from './MyLatestTransactions/MyLatestTransactions';
import AccountsInfo from "./AccountsInfo";
import {useSelector} from "react-redux";

import ExpensesInfo from "./ExpensesInfo";
import WaitingForApproval from "./WaitingForApproval";
import PersonalToDo from "./PersonalToDo";
import {Can} from "../../../../Context/Can";
import {
    Box,
    makeStyles
} from "@material-ui/core";
import useActiveTab from "../../../../hooks/useActiveTab";
import { isDarkModeActive } from '../../../App/App';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const dashboardTableMinHeight = '320px';

const useStyles = makeStyles(theme => ({
    pageRow: {
        display: 'grid',
        gridAutoColumns: '1fr',
        gridColumnGap: '8px',
        gridRowGap: '8px',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0',
        }
    },

    pageCol: {

    }
}))

const Dashboard = (props) => {
    const {user, underBarRef, drawerOuterRef, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const history = useHistory();

    const activeTab = useActiveTab('dashboard');
    const dateFormat = useSelector(state => {
        const {settings={}} = state.user;
        const {regional={}} = settings;
        return regional.dateFormat
    });
    return (
        <>
            <Can I="read" a="expense">
                <Box className={`card`}>
                    <ExpenseSpentInvesthub drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                </Box>
            </Can>
            <Box className={classes.pageRow}>
                <Box className={classes.pageCol}>

                    <Can I="read" a="expense">
                        <Box className={`card`}>
                            <ExpenseCategoryInvesthub drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                        </Box>

                        <WaitingForApproval dashboardTableMinHeight={dashboardTableMinHeight} settingsDarkMode={settingsDarkMode}/>
                        {activeTab === 'approver' && <Box className={`card`}/> /* show empty block for approver tab to fix block width */}
                    </Can>
                </Box>
                <Box className={classes.pageCol}>
                    <Can I="read" a="expense">
                        <MyLatestTransactions history={history} dateFormat={dateFormat} settingsDarkMode={settingsDarkMode} />
                    </Can>
                    {activeTab === 'personal' && (
                        <PersonalToDo user={user} settingsDarkMode={settingsDarkMode} />
                    )}

                    <Can I="read" a="account">
                        {["auditor", "administrator"].includes(activeTab) && (
                            <AccountsInfo dashboardTableMinHeight={dashboardTableMinHeight} settingsDarkMode={settingsDarkMode} />
                        )}
                    </Can>
                </Box>
            </Box>


            {/*<Greeting user={user}/>*/}
            {/* <Can I="read" a="expense">
                <Box className={`card`} style={{display: 'none !important'}}>
                    <ExpensesInfo drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode}/>
                </Box>
            </Can> */}

        </>
    );
};

const mapStateToProps = ({user}) => {
    return {
        user,
    }
}
export default compose(
    connect(mapStateToProps)
)(Dashboard);

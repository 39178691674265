import React, {useEffect} from 'react';
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import DialogPopUp from "../../../DialogPopUp";
import useForm2 from "../../../../hooks/useForm2";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import InputField from "../../../formElements/InputField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
            content: {
                width: "300px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px",
                marginLeft:"auto",
                marginRight:"auto"
            },
        }
    )
);


const NoReceiptDialog = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {disabled, value, handleChange, setOpen, open} = props;

    useEffect(() => {
        fillFormValues({noReceiptReason:value});
    },[value, open]);

    const submit = () => {
        handleChange("noReceiptReason", values["noReceiptReason"]);
        setOpen(false);
    }

    const validate = () => ({});

    const { handleChange:localHandleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );

    return (
            <DialogPopUp
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="no-reason-title"
                title={t("formFields.noReceiptReason")}
            >
                <DialogContent className={classes.content}>
                    <FormControl fullWidth>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputField
                                        name={"noReceiptReason"}
                                        label={t("formFields.noReceiptReason")}
                                        placeholder={t("formFields.noReceiptReason")}
                                        value={values["noReceiptReason"]}
                                        disabled={disabled}
                                        onInputChange={(val) => localHandleChange("noReceiptReason", val)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} disabled={disabled} color="primary" variant="contained">
                        {t("formFields.save")}
                    </Button>
                    <Button onClick={handleSubmit} disabled={disabled} color="secondary" variant="contained">
                        {t("formFields.cancel")}
                    </Button>
                </DialogActions>
            </DialogPopUp>
    );
};

export default NoReceiptDialog;

import React from 'react';
import {Route, Switch} from "react-router-dom";
import LoginForm from "../../LoginForm";
import RegisterForm from "../../RegisterForm";
import ForgotPasswordForm from "../../ForgotPasswordForm";
import ResetPasswordForm from "../../ResetPasswordForm";
import InvitationForm from "../../InvitationForm/InvitationForm";
import EmailConfirmation from "../../EmailConfirmation";
import SsoLogin from "../../SsoLogin";

const PublicRoutes = (props) => {
    const {settings} = props;
    return (
        <Switch>
            <Route exact path="/" component={(routeProps) => <LoginForm {...routeProps} settings={settings} />}/>
            <Route exact path="/register" component={(routeProps) => <RegisterForm {...routeProps} settings={settings}/>}/>
            <Route exact path="/register-white-label" component={(routesProps) => <RegisterForm {...routesProps} settings={settings} />}/>
            <Route exact path="/forgotpassword" component={(routeProps => <ForgotPasswordForm {...routeProps} settings={settings} />)}/>
            <Route exact path="/resetpassword/:resetToken" component={ResetPasswordForm}/>
            <Route exact path="/invitation/:hash" component={InvitationForm}/>
            <Route exact path="/email-confirmation/:hash" component={EmailConfirmation}/>
            <Route exact path="/sso" component={SsoLogin}/>
            <Route component={(routeProps) => <LoginForm {...routeProps} settings={settings} />} />
        </Switch>
    );
};

export default PublicRoutes;

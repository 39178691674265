import React from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {FormControl, Grid, makeStyles} from "@material-ui/core";
import InputField from "../../InputField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogPopUp from "../../../DialogPopUp";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(theme => ({
            contentInner: {
                width: "300px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px"
            },
            buttonsWrapper: {
                justifyContent: "center",
                flexWrap: "wrap",
                paddingLeft: "45px",
                paddingRight: "45px",
            },
        }
    )
);

const MccDialog = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {values, errors, handleSubmit, handleChange, open, setOpen, fillFormValues} = props;


    const onExited = () => {
        fillFormValues({});
    }

    return (
        <DialogPopUp
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="rejectReason-dialog-title"
            aria-describedby="rejectReason-dialog-description"
            title={t("addMccTitle")}
            onExited={onExited}
        >
            <DialogContent>
                <form onSubmit={handleSubmit} noValidate className={classes.contentInner}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.label}>
                                <InputField
                                    label={t("formFields.mccDesc")}
                                    name="label"
                                    value={values["label"]}
                                    onInputChange={(val) => handleChange("label", val)}
                                    error={errors["label"]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.label}>
                                <InputField
                                    label={t("formFields.mccCode")}
                                    name="value"
                                    value={values["value"]}
                                    onInputChange={(val) => handleChange("value", val)}
                                    error={errors["value"]}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className={classes.buttonsWrapper}>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    {t("formFields.save")}
                </Button>
                <Button onClick={() => setOpen(false)} color="secondary" variant="contained">
                    {t("formFields.cancel")}
                </Button>
            </DialogActions>
        </DialogPopUp>
    );
};

export default MccDialog;

import React,{useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom'
import {ReactComponent as ArrowLeftSVG} from "../../assets/arrowLeftInvesthub.svg"
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => (
    {
        hasIcon: {
            '& svg': {
                marginRight: '8px',
                width: '16px',
                height: '16px'
            }
        }
    }
));


const BackButton = (props) => {
    const {history, location, backUrl} = props;

    const [backLink, setBackLink] = useState(null);

    useEffect(() => {
        // pass state.backPath to navigation for right behavior
        if(backUrl){
            setBackLink(backUrl);
        }else if(location.state && location.state.backPath){
            setBackLink(location.state.backPath);
        }
    },[backUrl, location]);

    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <>
            <Button
                className={`btn-stroke btn-small ${classes.hasIcon}`}
                onClick={() => backLink ? history.push(backLink) : history.goBack()}
            >
                <ArrowLeftSVG />
                {t("formFields.back")}
            </Button>

        </>
    );
}

export default withRouter(BackButton);

import * as actionTypes from '../../../../../../../store/actionTypes'

const initialState = {
	cards: {
		result: [],
		info: {
			fields: ["user", "maskedPAN", "type", "validTo"],
			page: 0,
			total: 0,
			pageSize: 0
		}
	}
}

const userAccountCards = (state=initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case actionTypes.ACCOUNT_CARDS_SUCCESS:
            return {
                ...state,
                cards:{
                    result: payload.info.page > 1 ? [...state.cards.result, ...payload.result] : payload.result,
                    info:{
                        ...action.payload.info,
                    }
                }
            }
        case actionTypes.ACCOUNT_CARDS_FAILURE:
            return {
                ...initialState,
                cards:{
                    result:[]
                }
            }
        case actionTypes.USER_LOGOUT:
        return initialState;
    }
    return state

}

export default userAccountCards

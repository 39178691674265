import React, {useEffect, useState} from 'react';
import DialogPopUp from "../../../../DialogPopUp";
import Button from "@material-ui/core/Button";
import useForm from "../../../../../hooks/useForm";
import FbaForm from "./FbaForm";
import {makeStyles} from "@material-ui/core";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import {updateItems} from "../actions";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../Context/Can";
const service = new Api();

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        maxWidth: '480px'
    },
    button: {
        marginTop: '24px'
    }
}));

const DialogFba = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {open, setOpen, fundingBankAccount, isLoading, title, setMessage, action, updateItems, handleErrorMessage} = props;


    useEffect(() => {
        if(fundingBankAccount){
            const {approved, ...restFundingBankAccount} = fundingBankAccount;
            Object.keys(restFundingBankAccount).forEach((key) => {
                handleChange(key, fundingBankAccount[key]);
            });
        }else{
            cleanForm();
        }
    },[fundingBankAccount]);

    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        (async() => {
            try{
                const response = await service.getServiceData({dataFor:"currencies-fba"});
                const mappedCurrencies = response.data.map(({code, name}) => ({label:name, value:code}));
                setCurrencies(mappedCurrencies)
            }catch (e) {
                console.log(e);
            }
        })();
    },[]);


    const submit = async () => {
        const {_id, ...restValues} = values;
        try{
            if(action === "update"){
               const response = await service.updateCompanyFba(_id, restValues);
                updateItems(response.data);
            }else if(action === "create"){
                const response = await service.createCompanyFba(restValues);
                updateItems(response.data);
            }else{
                handleErrorMessage({message:t('unexpectedAction')})
            }
           setMessage("success", 'success');
           setOpen(false);
           cleanForm();
        }catch (e) {
            setServerErrors(e)
        }
    }

    const validate = () => {
        let errors = {};
        if (!values["bankName"]) {
            errors["bankName"] = t('errors.required', {field: "$t(formFields.bankName)"});
        }
        if (!values["accountName"]) {
            errors["accountName"] = t('errors.required', {field: "$t(formFields.accountName)"});
        }
        if (!values["accountNo"]) {
            errors["accountNo"] = t('errors.required', {field: "$t(formFields.accountNo)"});
        }
        if (!values["currency"]) {
            errors["currency"] = t('errors.required', {field: "$t(formFields.currency)"});
        }

        return errors
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors, cleanForm} = useForm(
        submit,
        validate,
    );

    return (
        <DialogPopUp
            open={open}
            disableBackdropClick
            onClose={() => setOpen(false)}
            aria-labelledby="alert-confirm-title"
            aria-describedby="alert-confirm-description"
            title={title}
        >
            <div className={classes.item}>
                <FbaForm
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    isLoading={isLoading}
                    currencies={currencies}
                />

                {action === 'update' && (
                    <Can I="update" a={"company"}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isLoading}
                            className={classes.button}
                        >
                            {t("formFields.update")}
                        </Button>
                    </Can>
                )}
                {action === 'create' && (
                    <Can I="update" a={"company"}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isLoading}
                            className={`${classes.button}`}
                        >
                            {t("formFields.create")}
                        </Button>
                    </Can>
                )}
            </div>
        </DialogPopUp>
    );
};

export default compose(
    connect(null, {setMessage, updateItems, handleErrorMessage})
)(DialogFba);

import axios from "axios";

const downloadFile  = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(url,{
            responseType: 'blob',
        }).then((resp) => {
            const fileNameRegex = /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/;
            const contentDisposition = resp.headers["content-disposition"];
            let fileName = `file`;
            if(contentDisposition){
                const nameMatches = contentDisposition.match(fileNameRegex);
                if(nameMatches && nameMatches[2]){
                    fileName = nameMatches[2];
                }
            }
            const {data} = resp;
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            resolve();
        }).catch((e) => {
            reject(e)
        });
    });
}

export default downloadFile

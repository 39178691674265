import React, {useState} from 'react';
import ExpensesContext from "../../Context/ExpensesContext";

const ExpensesProvider = (props) => {
    const [expenses, setExpenses] = useState([]);
    return (
        <ExpensesContext.Provider value={[expenses, setExpenses]}>
            {props.children}
        </ExpensesContext.Provider>
    );
};

export default ExpensesProvider;

import React from 'react'
// import {Can} from "../../Context/Can";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import AddVendor from "../userSection/content/TransactionVendor/AddVendor";

const TransactionsMakePaymentPage = (props) => {
    const {
        // eslint-disable-next-line no-unused-vars
        drawerOuterRef,
        // eslint-disable-next-line no-unused-vars
        underBarRef,
        settingsDarkMode} = props;

    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <AddVendor settingsDarkMode={settingsDarkMode} />
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    )
}

export default TransactionsMakePaymentPage

import React, {
    // eslint-disable-next-line no-unused-vars
    useEffect,
    useState
} from 'react';
import {
    FormControl,
    // eslint-disable-next-line no-unused-vars
    Grid,
    makeStyles,
    // eslint-disable-next-line no-unused-vars
    useTheme,
    // eslint-disable-next-line no-unused-vars
    DialogContent
} from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Api from "../../../../../services/api";
import {setMessage, handleErrorMessage} from "../../../../Messages/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import InputField from "../../../../formElements/InputField";
import DialogPopUp from "../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import useForm2 from "../../../../../hooks/useForm2";
// eslint-disable-next-line no-unused-vars
import {Can} from "../../../../../Context/Can";
// eslint-disable-next-line no-unused-vars
import {subject} from "@casl/ability";
import SelectField from "../../../../formElements/SelectField";
const service = new Api();

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        maxWidth: '480px'
    },
    button: {
        marginTop: '24px'
    },

    formInner: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },

    itemFieldFull: {
        flex: '0 0 calc(100% - 8px)',
        width: 'calc(100% - 8px)',
        margin: '0 8px 16px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 8px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    }
}));



const DialogCreateFiatAccount = (props) => {

    const classes = useStyles();
    const {
        setMessage,
        setOpen,
        loading,
        title,
        // eslint-disable-next-line no-unused-vars
        report,
        getFiatRepublicListAccount,
        type,
        isDisableFiat,
        settingsDarkMode
    } = props;

    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValue = {
        currency: '',
        label: '',
        businessId: '',
    }

    const validate = (values) => {
        const errors = {};
        return errors;
    };

    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        setServerErrors,
        // eslint-disable-next-line no-unused-vars
        fillFormValues
    } = useForm2(
        submit,
        validate,
    );

    async function submit(){
        setIsSubmitting(true);
        try {
            console.log('type', type)
            const createFunction =
                type === "user"
                    ? service.createFiatRepublicAccount({
                        ...values,
                        businessId: "biz_x83lr41ojlg7dy5va2",
                    })
                    : service.createCompanyFiatRepublicAccount({
                        ...values,
                        businessId: "biz_x83lr41ojlg7dy5va2",
                    });
            // eslint-disable-next-line no-unused-vars
            const response = await createFunction;
            getFiatRepublicListAccount();
            setMessage("success", "success");
            setOpen(false);
        } catch (e) {
            setServerErrors(e);
        } finally {
            setIsSubmitting(false);
        }
    }


    return (
        <DialogPopUp
            open={true}
            disableBackdropClick
            onClose={() => setOpen(false)}
            aria-labelledby="report-dialog-title"
            aria-describedby="report-dialog-description"
            title={title}
            pageTitleClass={'card-title--red'}
            settingsDarkMode={settingsDarkMode}
        >
            {isDisableFiat ? <div className={classes.item}>{t("errors.enableFiat")}</div>: <div className={classes.item}>
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.form}
                    defaultValue={initialValue}
                >
                    <div className={classes.formInner}>
                        <FormControl
                            fullWidth
                            error={!!errors.name}
                            className={classes.itemFieldFull}
                        >
                            <InputField
                                label={t("formFields.name")}
                                name="label"
                                isLoading={loading}
                                value={values["label"]}
                                onInputChange={(val) =>
                                    handleChange("label", val)
                                }
                                error={errors["label"]}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={!!errors.currency}
                            className={classes.itemFieldFull}
                        >
                            <SelectField
                                label={t("formFields.currency")}
                                name={"currency"}
                                value={values["currency"]}
                                error={errors.currency}
                                options={[{ label: "EUR", value: "eur" }]}
                                onSelectChange={(val) =>
                                    handleChange("currency", val)
                                }
                            />
                        </FormControl>
                    </div>
                </form>

                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting || loading}
                    className={classes.button}
                >
                    {t("formFields.create")}
                </Button>
            </div>}
        </DialogPopUp>
    );
};

export default compose(
    connect(null, {setMessage, handleErrorMessage})
)(DialogCreateFiatAccount);

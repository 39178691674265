import React, {useEffect, useState} from 'react';
import Skeleton from "react-loading-skeleton";
import FormatMoney from "../../FormatMoney/FormatMoney";
import {useTranslation} from "react-i18next";
import {
    makeStyles,
    Box,
    FormLabel
} from "@material-ui/core";
import { isDarkModeActive } from '../../App/App';


const useStyles = makeStyles(theme => ({
    calculatedAmountLabel:{
    },

    currencyWrap: {
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)': 'var(--very-light-pink-primary)',
        border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid transparent',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)': 'var(--dark-secondary)', // Need in light
        borderRadius: '12px',
        width: '100%',
        height: '48px',
        padding: '0 10px',
        paddingLeft: '58px',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '44px',
        transition: 'all .2s',
        position: 'relative',
        fontFamily: `'Inter', sans-serif`,
    },

    currencySymbol: {
        maxHeight: 'initial',
        height: '100%',
        position: 'absolute',
        top: '0px',
        left: '2px',
        bottom: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '46px',
        paddingTop: '0px',
        borderRadius: '10px 0 0 10px',
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)' : 'var(--very-light-pink-primary)',
        margin: 0,
        fontFamily: `'Inter', sans-serif`,
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: (props) => props.isDarkModeActive ? 'var(--battelship-grey)': 'var(--dark-secondary)',
    },
}));

const ReportedAmount = (props) => {
    const {isLoading, reportedExchangeRate, reportedCurrency, amount, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const {t} = useTranslation();
    const [displayedAmount, setDisplayedAmount] = useState("");


    useEffect(() => {
        if(reportedExchangeRate && amount){
            setDisplayedAmount(reportedExchangeRate*amount)
        }else{
            setDisplayedAmount(amount);
        }

    },[reportedExchangeRate, amount]);

    return (
        <>
            <FormLabel className={classes.calculatedAmountLabel}>{t("formFields.amountInReportingCurrency")}</FormLabel>
            {(isLoading) ? (
                <Skeleton height={"47px"} width={"100%"}/>
            ) : (
                <Box className={classes.currencyWrap} >
                    <Box className={classes.currencySymbol}>{reportedCurrency}</Box>
                    <FormatMoney>{displayedAmount}</FormatMoney>
                </Box>
            )}
        </>
    );
};

export default ReportedAmount;

import React from 'react';
import { ReactComponent as Dot} from '../../assets/dot.svg'
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const ExpenseQueueStatus = (props) => {
    const {status} = props;

    let fillColor = null;


    switch (status) {
        case "processing" : fillColor = "#31BC71"; break;
        case "manual" : fillColor = "#F5A623"; break;
        case "notCreated" : fillColor = "red"; break;
        default:  fillColor = "#8A96A0";
    }

    const useStyles = makeStyles(theme => ({
        dot: {
            verticalAlign:"middle",
            "& path":{
                fill: fillColor
            }
        },

    }));

    const classes = useStyles();

    const {t} = useTranslation();

    const statusName = status === "manual" ? "processing" : status; // rename `manual` status to `processing` on frontend
    return (
        <>
            <Dot className={classes.dot}/> <Box component={"span"}>{status ? t(`expenseQueue.${statusName}`): ""}</Box>
        </>
    );
};

export default ExpenseQueueStatus;

import Api from '../../../../services/api'
import * as actionTypes from '../../../../store/actionTypes'
import {handleErrorMessage} from "../../../Messages/actions";

const fetchCards = (dataQuery) => {
	return async dispatch => {
		const {getCards} = new Api();

		try {
			const response = await getCards(dataQuery);
			const {data} = response;
			const {result, info} = data;
			dispatch({type:actionTypes.CARDS_FETCH_SUCCESS, payload:{result, info}});
		}catch (e) {
			dispatch(handleErrorMessage(e));
			dispatch({type:actionTypes.CARDS_FETCH_FAILURE})
		}
	}
};
const cleanCards = () => {
    return async dispatch => {
        dispatch({type: actionTypes.CARDS_CLEAN});
    }
};

export {
	fetchCards,
    cleanCards
}

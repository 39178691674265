import {useContext} from "react";
import ExpensesContext from "../Context/ExpensesContext";

const useExpenses = () => {
    const context = useContext(ExpensesContext);
    if(!context){
        throw new Error("useExpenses must be within the expensesProvider")
    }

    return context
}

export default useExpenses

import {userLogout} from "../User/actions";
import i18n from "i18next";
export const ENQUEUE_MESSAGE = 'ENQUEUE_MESSAGE';
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE';
export const REMOVE_MESSAGE= 'REMOVE_MESSAGE';

const getGpsError = (message) => {
	const regex = /Error ([\d]{4})-([\d]{3})/;
	if(!message){
        return false;
    }
	const matches = message.toString().match(regex);
	if(matches){
		const [fullMatch, key, gpsCode] = matches;
		return i18n.t(`gpsErrors.${key}`, {code:gpsCode})
	}
	return false;
}

export const setMessage = (message, variant) => {

	const gpsError = getGpsError(message);

	const errorMessage = gpsError ? gpsError : i18n.t(message);

	return {
		type: ENQUEUE_MESSAGE,
		notification: {
			message: errorMessage,
			options: {
				variant: variant,
			},
			key: new Date().getTime() + Math.random(),
		},
	};
};

export const handleErrorMessage = (e) => dispatch => {
	let msg = "";
	if(e.response && (e.response.status === 401)){
		// logout user
		dispatch(userLogout());
	}
	if(e.response && e.response.data.msg){
		msg = e.response.data.msg;
	}else{
		msg = e.message
	}
	dispatch(setMessage(msg, 'error'))

}


export const closeMessage = key => ({
	type: CLOSE_MESSAGE,
	dismissAll: !key, // dismiss all if no key has been defined
	key,
});

export const removeMessage = key => ({
	type: REMOVE_MESSAGE,
	key,
});

import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import UserPermissionsForm from "./UserPermissionsForm";

const UserPermissions = (props) => {
    const {match, underBarRef, settingsDarkMode} = props;
    const {path} = match;

    return (
        <>
            <Switch>
                <Route exact path={path} render={
                    () => {
                        return <UserPermissionsForm underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                    }
                }
                />
            </Switch>
        </>
    )
};

export default withRouter(UserPermissions);

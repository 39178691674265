import React, {useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {makeStyles, FormControl, Button} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../Messages/actions";
import ExternalLink from "../ExternalLink";
import useForm2 from "../../hooks/useForm2";
import DialogPopUp from "../DialogPopUp";
// import FileUploaderInline from "../formElements/FileUploaderInline";
import FileUploaderInlineInvesthub from "../formElements/FileUploaderInlineInvesthub/FileUploaderInlineInvesthub"
import Api from "../../services/api";
import MenuOption from "../MenuOption";
import { isDarkModeActive } from '../App/App';
const service = new Api();

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        minWidth: '340px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'initial'
        },
        "& $field": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        }
    },
    button: {
        marginTop: '24px'
    },
    field: {
        '& label + .MuiInput-formControl': {
            marginTop: '0'
        }
    },
    notification: {
        background: (props) => props.isDarkModeActive ? 'var(--dark-primary)': 'var(--very-light-pink-primary)',
        border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid transparent',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)': 'var(--dark-secondary)', // Need in light
        borderRadius: '12px',
        width: '100%',
        height: '48px',
        padding: '0 10px',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '44px',
        transition: 'all .2s',
        position: 'relative',
        fontFamily: `'Inter', sans-serif`,
    },
    mediaText: {
        display: 'block',
        fontWeight: '500',
        color: 'var(--battelship-grey)',

        '& a': {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
            fontWeight: '600',
            transition: 'color .2s',
            textDecoration: 'none'
        },
        '& a:hover': {
            color: 'var(--bright-blue)',
            textDecoration: 'none'
        }
    },

}
    )
);

const ImportFromCsvOption = (props) => {
    const {
        btnLabel,
        popupTitle,
        popupText,
        templatePath,
        onImport,
        bottom,
        // eslint-disable-next-line no-unused-vars
        closeParent,
        settingsDarkMode
    } = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const [open, setOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const submit = async () => {

        try {
            setIsDisabled(true);
            const formData = new FormData();
            formData.append("file", values["file"]);
            await onImport(formData);
            setOpen(false);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e))
        }finally {
            setIsDisabled(false);
        }
    }

    const validate = (values) =>{
        const errors = {};
        if (!values[`file`]) {
            errors[`file`] = t("errors.required", {field:"$t(formFields.file)"});
        }


        return errors;
    };

    const { handleChange, handleSubmit, values, fillFormValues, errors } = useForm2(
        submit,
        validate
    );

    const onOpenDialog = () => {
        // if(typeof closeParent === "function"){
        //     closeParent();
        // }
        setOpen(true)
    }

    const onExited = () => {
        fillFormValues({});
    }

    return (
        <>
            <MenuOption disabled={isDisabled} onClick={onOpenDialog}>
                {btnLabel}
            </MenuOption>
            <DialogPopUp
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="import-title"
                title={popupTitle}
                onExited={onExited}
                pageTitleClass={`card-title--red`}
                settingsDarkMode={settingsDarkMode}
            >
                <div className={classes.item}>
                    <div className={`${classes.field} ${classes.notification}`}>
                        {popupText}
                    </div>

                    <FormControl fullWidth className={classes.field}>
                        <FileUploaderInlineInvesthub
                            value={values[`file`]}
                            fieldName={"file"}
                            error={errors[`file`]}
                            handleChange={handleChange}
                            acceptedDocuments={[".csv"]}
                            buttonLabel={"attachCsv"}
                            settingsDarkMode={settingsDarkMode}
                        />
                    </FormControl>

                    <FormControl fullWidth className={`${classes.mediaText} ${classes.field}`}>
                        {t("importFromFileText1")}
                        {' '}
                        <Trans i18nKey="importFromFileText2">
                            <ExternalLink href={`${service._basicPath}${templatePath}`}/>
                        </Trans>

                        {bottom ? bottom : null}
                    </FormControl>

                    <Button
                        onClick={handleSubmit}
                        disabled={isDisabled}
                        className={`${classes.button} ${classes.buttonLast}`}
                    >
                        {t("formFields.submit")}
                    </Button>
                </div>
            </DialogPopUp>

        </>
    );
};

export default ImportFromCsvOption;

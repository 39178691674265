import React from 'react'
import {withRouter, Route, Switch} from 'react-router-dom'
import SettingsForm from './SettingsForm'
import UserProfile from "./UserProfile";
import {useTranslation} from "react-i18next";
// import TabsContent from "../../../TabsContent";
import HeadContentInvesthub from "./HeadContentInvesthub"

const Settings = (props) => {
    const {match, settings} = props;
    const {t} = useTranslation();
    const {url, path} = match;
    const tabs = [
        {url:url, name:t("user.settings")},
        {url:`${url}/profile`, name:t("user.profile")}
    ];


    return (
        <div className="card">
            {/* <TabsContent routes={tabs}/> */}
            <HeadContentInvesthub routes={tabs} settings={settings} titleColor={'card-title--purple'} />
            <Switch>
                <Route
                    exact
                    path={path}
                    component={
                        () => <SettingsForm settingsDarkMode={settings} />
                    }
                />
                <Route
                    exact
                    path={`${path}/profile`}
                    component={
                        () => <UserProfile settings={settings} />
                    }
                />
                <Route component={() => <div>Page not found</div>}/>
            </Switch>
        </div>
    )
}

export default withRouter(Settings)

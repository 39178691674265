import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormControl, FormGroup, Grid, makeStyles} from "@material-ui/core";
import Api from "../../../../../../../services/api";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import useForm from "../../../../../../../hooks/useForm";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import FormHelperText from "@material-ui/core/FormHelperText";
import Skeleton from "react-loading-skeleton";
import RadioField from "../../../../../../formElements/RadioField";
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";
const service = new Api();


const useStyles = makeStyles(theme => ({
    tableCell:{
        padding:" 1px 0px 1px 0px",
        borderColor: "transparent",
        fontWeight: "200",
        '&.MuiTableCell-head':{
            fontSize: "10px",
            fontWeight: "400",
            letterSpacing: "1.49px",
            textTransform: "uppercase",
            color: "#b4bac6"
        },
    },
    label:{
        cursor:"pointer",
        display: "block",
        padding: " 10px 0"
    },
    noAccountsMessage:{
        color: "#000"
    },
    addAccountLink:{
        color: theme.palette.secondary.main
    },

    contentInner:{
        maxWidth: "380px",
        width:"340px",
        marginLeft:"auto",
        marginRight:"auto",
        paddingBottom:"20px",
        paddingTop:"20px",
    },

    btnWrap: {
        display: 'flex',
        gap: '16px',
        marginTop: '16px'
    }
}));


const LinkAccountStep = (props) => {
    const {handleErrorMessage, setCurrentStep, setCardData, cardData, setTitle, setSelectedCurrency} = props;

    const classes = useStyles();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        setTitle(t("card.linkAccounts"));
        setIsLoading(true);
        (async() => {
            try {
                const accounts = await service.getAccountsAll();
                const mappedAccounts = accounts.data.map((account) => ({label: account.curCode, value: account._id, balance: account.avlBal}))
                setAccounts(mappedAccounts);
                setIsLoading(false);
            }catch (e) {
                console.log(e);
                handleErrorMessage(e);
                setIsLoading(false);
            }
        })();
    },[]);

    useEffect(() => {
        Object.keys(cardData).forEach((key) => {
            handleChange(key, cardData[key])
        });
    },[cardData]);


    const submit = () => {
        setCardData((prevValues) => ({
            ...prevValues,
            ...values
        }));
        setCurrentStep((oldStep) => oldStep+1);
    }

    const validate = () => {
        const errors = {};
        if (!values.account) {
            errors.account =  t('errors.required', {field: "$t(formFields.account)"});
        }
        return errors;
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );
    const onAccountSelect = (value, label) => {
        handleChange('account', value);
        setSelectedCurrency(label);
    };


    let content = '';
    if(isLoading){
        content = (
            <TableRow>
                <TableCell colSpan={3} className={classes.tableCell}>
                    <Skeleton width={"100%"} height={"40px"}/>
                </TableCell>
            </TableRow>
        )
    }else if(accounts.length > 0){
        if(accounts.length === 1 && !values["account"]){
            // if only one account - select them
            onAccountSelect(accounts[0].value, accounts[0].label);
        }
        content = accounts.map((checkbox, index) => {
            return (
                <TableRow key={checkbox.value}>
                    <TableCell className={classes.tableCell}><label className={classes.label} htmlFor={`el-${checkbox.value}`}>{checkbox.label}</label></TableCell>
                    <TableCell className={classes.tableCell}>{checkbox.balance}</TableCell>
                    <TableCell className={classes.tableCell} align="right">
                        <RadioField
                            checked={values.account === checkbox.value}
                            onChange={() => onAccountSelect(checkbox.value, checkbox.label)}
                            value={checkbox.value}
                            name="account"
                            inputProps={{ 'aria-label': 'A' }}
                            id={`el-${checkbox.value}`}
                        />
                    </TableCell>
                </TableRow>
            )
        });

    }else{
        content = (
            <TableRow>
                <TableCell colSpan={3} className={classes.tableCell}>
                    <Box mt={2} mb={2}>
                        <FormHelperText error>{t("errors.noAccounts")}</FormHelperText>
                        <Link to={"/accounts"} className={classes.addAccountLink}>{t("addAccount")}</Link>
                    </Box>
                </TableCell>
            </TableRow>
        )
    }


    return (
        <>
            <div className={classes.contentInner}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.account}>
                                <FormGroup>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableCell}>{t("formFields.currency")}</TableCell>
                                                <TableCell className={classes.tableCell}>{t("formFields.balance")}</TableCell>
                                                <TableCell className={classes.tableCell}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {content}
                                        </TableBody>
                                    </Table>
                                    {errors.account && <FormHelperText id="account-message">{errors['account']}</FormHelperText>}
                                </FormGroup>

                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
                <div className={classes.btnWrap}>
                    <Button
                        onClick={handleSubmit}
                    >
                        {t("formFields.continue")}
                    </Button>

                    <Button
                        onClick={() =>setCurrentStep((oldStep) => oldStep-1)}
                        className={`btn-stroke`}
                    >
                        {t("formFields.back")}
                    </Button>
                </div>
            </div>

        </>
    );
};

export default LinkAccountStep;

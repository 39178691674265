import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { makeStyles, ClickAwayListener, Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import PlusButton from "../../../../PlusButton";
import ActionMenu from "../../../../ActionMenu";
import ActionButton from "../../../../ActionButton";
import MenuOption from "../../../../MenuOption";
import { ReactComponent as PlusIconSVG } from "../../../../../assets/plusCircleInvesthub.svg";
import { isDarkModeActive } from "../../../../App/App";

const useStyles = makeStyles((theme) => ({
    menuAction: {
        position: "relative",
        display: "inline-block",
        zIndex: "11",
    },
    menuBody: {
        position: "absolute",
        top: "calc(100% + 12px)",
        right: "-12px",
        width: "264px",
        zIndex: "11",
        padding: "12px",
        borderRadius: "16px",
        border: (props) =>
            props.isDarkModeActive
                ? "1px solid var(--dark-primary)"
                : "1px solid transparent",
        boxShadow:
            "0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)",
        background: (props) =>
            props.isDarkModeActive ? "var(--almost-black)" : "var(--white)",
        visibility: "hidden",
        opacity: "0",
        transform: "translateY(3px)",
        transition: "all .2s",
        margin: "0",
        "&:before": {
            content: `""`,
            position: "absolute",
            right: "20px",
            bottom: "100%",
            width: "20px",
            height: "10px",
            background: (props) =>
                props.isDarkModeActive
                    ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")  no-repeat 50% 50%/100% auto`
                    : `url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E) no-repeat 50% 50%/100% auto`,
        },

        "& li.MuiButtonBase-root": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: "12px",
            borderRadius: "12px",
            transition: "all .2s",
            cursor: "pointer",
            background: "transparent !important",
            justifyContent: "flex-start",
            "& svg": {
                width: "24px",
                height: "24px",
                marginRight: "12px",
                fill: "var(--battelship-grey)",
                transition: "fill .2s",
            },
            "& .MuiTypography-root": {
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "1.6",
                color: "var(--battelship-grey)",
                transition: "all .2s",
                fontFamily: `'Inter', sans-serif`,
                letterSpacing: "0",
            },
            "&:hover": {
                color: (props) =>
                    props.isDarkModeActive
                        ? "var(--pale-grey)"
                        : "var(--dark-secondary)",
                "& svg": {
                    fill: (props) =>
                        props.isDarkModeActive
                            ? "var(--pale-grey)"
                            : "var(--dark-secondary)",
                },
                "& .MuiTypography-root": {
                    color: (props) =>
                        props.isDarkModeActive
                            ? "var(--pale-grey)"
                            : "var(--dark-secondary)",
                },
            },
            "& .MuiTouchRipple-root": {
                display: "none",
            },
            "& .MuiListItemText-root": {
                margin: "0",
            },
        },
    },
    menuBodyActive: {
        opacity: "1",
        transform: "translateY(0)",
        visibility: "visible",
    },
}));

const ActionBtn = (props) => {
    const { settingsDarkMode, isOpenModal, setIsOpenModal } = props;

    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });
    const [isActive, setIsActive] = useState(false);
    const [delayedIsActive, setDelayedIsActive] = useState(false);

    useEffect(() => {
        let timeoutId;

        if (isActive) {
            timeoutId = setTimeout(() => {
                setDelayedIsActive(true);
            }, 300);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isActive]);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
        setIsActive(!isActive);
    }

    function handleClose() {
        setAnchorEl(null);
        setIsActive(false);
    }
    const handleAwayClick = () => {
        handleClose();
    };

    // eslint-disable-next-line no-unused-vars
    const onOpenDialog = () => {
        setIsOpenDialog(true);
        handleClose(); // close menu
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleAwayClick}>
                <Box className={classes.menuAction}>
                    <PlusButton
                        onClickHandler={handleClick}
                        customClass={classes.customClass}
                        hasDropdown
                        hasDropdownActive={isActive}
                        settingsDarkMode={settingsDarkMode}
                    >
                        <PlusIconSVG />
                    </PlusButton>

                    {isActive && (
                        <ul
                            anchorEl={anchorEl}
                            className={`${classes.menuBody} ${
                                delayedIsActive ? classes.menuBodyActive : ""
                            }`}
                        >
                            <MenuOption
                                className={classes.menuButton}
                                onClick={() => setIsOpenModal(true)}
                            >
                                {/* NEED TRANSLATION */}
                                {t("Create Account")}
                            </MenuOption>
                            <MenuOption className={classes.menuButton}>
                                {/* NEED TRANSLATION */}
                                {t("Import CSV")}
                            </MenuOption>
                        </ul>
                    )}
                </Box>
            </ClickAwayListener>
        </>
    );
};

export default compose()(withRouter(ActionBtn));

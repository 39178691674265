import {useContext} from "react";
import ExpenseQueuesContext from "../Context/ExpenseQueuesContext";


const useExpenseQueues = () => {
    const context = useContext(ExpenseQueuesContext);
    if(!context){
        throw new Error("useExpenseQueues must be within the expenseQueuesProvider")
    }

    return context
}

export default useExpenseQueues

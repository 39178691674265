import React from 'react';
import {Route, Switch} from "react-router-dom";
import ReportsTable from "./ReportsTable";

const Reports = (props) => {
    const {url, underBarRef, settingsDarkMode} = props;

    return (
        <>
            <Switch>
                <Route exact path={url} render={
                    () => {
                        return <ReportsTable underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                    }
                }
                />
            </Switch>
        </>
    );
};

export default Reports;

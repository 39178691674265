import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useForm from "../../../../../../../hooks/useForm";
import Button from "@material-ui/core/Button";
import RadioFields from "../../../../../../formElements/RadioFields";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
    formElement:{
        '& .MuiFormGroup-root':{
            flexDirection:"column"
        }
    },
    contentInner:{
        maxWidth: "380px",
        width:"340px",
        marginLeft:"auto",
        marginRight:"auto",
        paddingBottom:"20px",
        paddingTop:"20px",
    },

    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },

    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'var(--charcoal-primary)'
        }
    },

    btnWrap: {
        display: 'flex',
        gap: '16px',
        marginTop: '16px'
    }
}));
const DeliveryStep = (props) => {
    const {setCurrentStep, setCardData, cardData, setTitle} = props;


    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        Object.keys(cardData).forEach((key) => {
            handleChange(key, cardData[key])
        });
    },[cardData]);

    useEffect(() => {
        setTitle(t("card.delivery"));
        handleChange("delivery", "0");
    },[]);

    const submit = () => {
        setCardData((prevValues) => ({
            ...prevValues,
            ...values
        }));
        setCurrentStep((oldStep) => oldStep+1);
    };

    const validate = () => {
        return {}
    }


    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    const options = [
        {value: "0", label: t("formFields.deliveryStandard")},
        {value: "2", label: t("formFields.deliveryExpress")}
    ];


    return (
        <>
            <div className={classes.contentInner}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.itemSettings}>
                        <FormControl fullWidth error={!!errors["delivery"]} className={`${classes.itemFieldFull} ${classes.formElement}`}>
                            <RadioFields
                                options={options}
                                name={"delivery"}
                                value={values["delivery"]}
                                onRadioChange={(val) => handleChange("delivery", val)}
                                error={errors["delivery"]}
                            />
                        </FormControl>
                    </div>
                </form>

                <div className={classes.btnWrap}>
                    <Button
                        onClick={handleSubmit}
                    >
                        {t("formFields.continue")}
                    </Button>
                    <Button
                        onClick={() =>setCurrentStep((oldStep) => oldStep-1)}
                        className="btn-stroke"
                    >
                        {t("formFields.back")}
                    </Button>
                </div>
            </div>
        </>

    );
};

export default DeliveryStep;

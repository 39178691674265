import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles({
    button: {
        color:"#24DAC4",
        padding:0,
        minWidth: 'auto',
        fontSize: '15px'
    },

});

const DashboardPaneActionButton = (props) => {
    const classes = useStyles();
    return <Button size="small" {...props} className={classes.button}/>;
};

export default DashboardPaneActionButton;

import React from 'react';
import { confirmable } from 'react-confirm';
import Button from "@material-ui/core/Button";
import DialogPopUp from "../DialogPopUp";
import themeConfiguration from "../../shared/themeConfiguration";
import {MuiThemeProvider} from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
            actions: {
                maxWidth: "100%",
                minWidth:'300px',
                paddingTop: "28px",
                paddingBottom: "55px",
                paddingLeft:"20px",
                paddingRight:"20px"
            },
            button: {
                paddingLeft:'30px',
                paddingRight:'30px',
                minWidth: "134px",
                marginLeft:"8px",
                marginRight:"8px"
            },
        }
    )
);


const Confirmation = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {show, proceed, dismiss, cancel, confirmation, yesText, noText, content} = props;

    return (
        <MuiThemeProvider theme={themeConfiguration}>
            <DialogPopUp
                open={show}
                onClose={dismiss}
                title={confirmation ? confirmation : t("areYouSure")}
            >
                {content && (<DialogContent> {content}</DialogContent>)}
                <DialogActions className={classes.actions}>
                    <Button onClick={dismiss} color="secondary" variant="contained" className={classes.button}>
                        {noText ? noText : t("formFields.cancel")}
                    </Button>
                    <Button onClick={proceed} color="primary"  variant="contained" className={classes.button}>
                        {yesText ? yesText : t("formFields.yes")}
                    </Button>
                </DialogActions>
            </DialogPopUp>
        </MuiThemeProvider>
    )

}

export default confirmable(Confirmation);

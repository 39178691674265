import React from 'react';
import InputFieldMemo from "../../formElements/InputFieldMemo";
import {useTranslation} from "react-i18next";

const EmployeeField = (props) => {
    const {employee} = props;

    const {t} = useTranslation();
    const {firstName, lastName} = employee;

    const value = [firstName, lastName].filter((item) => item).join(" ");

    return (
        <InputFieldMemo
            name={"employee"}
            label={t("formFields.employee")}
            placeholder={t("formFields.employee")}
            value={value}
            disabled={true}
        />
    );
};

export default EmployeeField;

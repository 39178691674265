import React from 'react';
import {
    makeStyles,
    MenuItem,
    Tooltip,
    Select,
    FormLabel,
    FormHelperText,
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import {ReactComponent as SelectArrow} from "../../../assets/selectArrow.svg";
import {ReactComponent as InfoSVG} from "../../../assets/infoInvesthub.svg"
import { isDarkModeActive } from '../../App/App';


const SelectField = (props) => {

    const noHandler = () => {
        console.log(`No handler for ${name} select field`);
    };

    const {
        value = "",
        error = "",
        onSelectChange = noHandler,
        options = [],
        label = "",
        name = "",
        disabled = false,
        isLoading,
        alignRight,
        hideEmpty,
        emptyLabel,
        strokeStyle = false,
        smallStyle = false,
        settings,
        helperText,
        ...AdditionalProps
    } = props;

    const useStyles = makeStyles({
        alignRight: {
            justifyContent: "flex-end"
        },
        nativeStyles: {
            marginLeft: "-5px",
            "&.MuiInput-underline:before": {
                left: "5px"
            },
            "& select": {
                paddingLeft: "5px"
            }
        },
        itemComponent: {
            padding: "13px 30px",
            position: "relative",
            fontSize: "15px",
            // color:"#354052",
            "&::after": {
                display: "block",
                left: "30px",
                right: "30px",
                content: "''",
                background: "#EDEFF1",
                height: "1px",
                bottom: 0,
                position: "absolute"
            },
        },
        menuWrapper:{
            background:"#ccc!important"
        },
        menu:{
            '& .MuiPopover-paper':{
                // boxShadow:"2px 2px 54px rgba(0, 0, 0, 0.176355)"
            },
            "& .MuiMenu-list":{
                paddingBottom:"20px",
                paddingTop:"20px"
            }

        },
        labelHasHelper: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            "& svg": {
                position: 'relative',
                display: 'inline-block',
                marginLeft: '4px',
                cursor: 'pointer',
                width: '16px',
                height: '16px',
                fill: (props) => props.isDarkModeActive ? 'var(--cool-grey)' : 'var(--dark-secondary)'
            }
        }
    });

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const {t} = useTranslation();


    const OptionComponent = props.native ? "option" : MenuItem;

    return (
        <>
            {label && (
                <FormLabel component="label" htmlFor={`select-${name}`} className={`${helperText && classes.labelHasHelper}`}>
                    {label}
                    {helperText && (
                        <Tooltip
                            title={t(helperText)}
                        >
                            <InfoSVG />
                        </Tooltip>
                    )}
                </FormLabel>
            )}
            {isLoading ? (<Skeleton width={"100%"} height={"50px"}/>) : (
                <Select
                    value={value}
                    name={name}
                    className={`${props.native ? classes.nativeStyles : null} ${smallStyle ? 'select-small' : null} ${strokeStyle ? 'select-stroke' : null}`}
                    displayEmpty
                    id={`select-${name}`}
                    onChange={(e) => onSelectChange(e.target.value)}
                    disabled={disabled}
                    IconComponent={(props) => {
                        return disabled ? null : <SelectArrow {...props}/>
                    }}
                    MenuProps={{
                        className:`${classes.menu} ${smallStyle ? classes.smallStyleMenu : null}`,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        getContentAnchorEl: null, // This ensures the menu is positioned below the select field
                        style: {
                            maxHeight: "400px", // Set your desired max height here
                        },
                    }}
                    {...AdditionalProps}
                >
                    {!hideEmpty && (<OptionComponent value="" className={!props.native ? classes.itemComponent : null}>{emptyLabel ? emptyLabel : t("formFields.emptyOption")}</OptionComponent>)}
                    {(options && options.length > 0) && (
                        options.map((option) => {
                            return <OptionComponent value={option.value} key={option.value} disabled={!!(option.disabled)} className={!props.native ? classes.itemComponent : null}>{option.label}</OptionComponent>
                        })
                    )}
                </Select>
            )}

            {!!error && <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default SelectField;

import i18n from "i18next";

const getTimeAgo = (date) => {
    if(!date){
        return "";
    }
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
        return i18n.t("year", {count:interval});
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return i18n.t("month", {count:interval});
    }

    interval = Math.floor(seconds / 604800);
    if (interval >= 1) {
        return i18n.t("week", {count:interval});
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        return i18n.t("day", {count:interval});
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        return i18n.t("hour", {count:interval});
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return i18n.t("minute", {count:interval});
    }
    return Math.floor(seconds) + " seconds";
}


export default getTimeAgo

import React from 'react'
import {makeStyles} from "@material-ui/core";
import {ReactComponent as PlusSVG} from '../../assets/plusInvesthub.svg'

const useStyles = makeStyles({
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        border: '2px solid var(--very-light-pink-secondary)',
        transition: 'border-color .2s',
        '& svg': {
            width: '10px',
            height: '10px',
            fill: '--battelship-grey',
            transition: 'fill .2s',
        },
        '&:hover': {
            borderColor: 'var(--dark-secondary)',
        }
    },
    buttonTwo: {
        position: 'relative',
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        background: 'var(--very-light-pink-secondary)',
        userSelect: 'none',
        transition: 'background .2s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& svg": {
            width: '14px',
            height: '14px',
            transition: 'fill .2s',
            fill: 'var(--battelship-grey)',
        }
    },

    buttonThree: {
        position: 'relative',
        width: '40px',
        height: '40px',
        border: 'none',
        borderRadius: '50%',
        background: 'var(--white)',
        transition: 'background .2s',
        "& svg": {
            width: '16px',
            height: '16px',
            transition: 'fill .2s',
            fill: 'var(--battelship-grey)'
        },

        "&:hover svg": {
            fill: 'var(--clear-blue-primary)'
        }
    }
})

const PlusButtonTitle = (props) => {
    const {onClickHandler,variant2=false, variant3=false, ...restProps} = props
    const classes = useStyles()

    return (
        <button
            className={`${variant2 ? classes.buttonTwo : classes.button} ${variant3 ? classes.buttonThree : classes.button}`}
            onClick={onClickHandler}
            {...restProps}
        >
            <PlusSVG />
        </button>
    )
}

export default PlusButtonTitle

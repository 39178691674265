import React, {useEffect, useState} from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core";
import {ReactComponent as SelectArrow} from "../../../assets/selectArrow.svg";
import Skeleton from "react-loading-skeleton";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles({
    label: {
        '&.MuiInputLabel-shrink': {
            transform: "translate(0, 0) scale(1)",
            position: "static"
        },
    },
    helperMsg:{
        wordBreak:"break-word"
    }
});

const MultipleSelectSearch = (props) => {
    const classes = useStyles();
    const {values=[], options=[], inputHandler, label, name="", disabled=false, isLoading, error} = props;

    return (
        <NoSsr>
            {isLoading ? <Skeleton width={"100%"} height={"40px"}/> : (
                <Autocomplete
                    multiple
                    options={options}
                    getOptionLabel={(option) => option ? option.label : ""}
                    value={values.map((id) => options.find((option) => option.value === id))}
                    disabled={disabled}
                    onChange={(event, newValue) => {
                        inputHandler(newValue);
                    }}
                    popupIcon={<SelectArrow/>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label ? label : null}
                            InputLabelProps={{ shrink: true, className:classes.label }}
                        />
                    )}
                />
            )}
            {!!error &&  <FormHelperText error className={classes.helperMsg}>{error}</FormHelperText>}
        </NoSsr>
    );
};

export default MultipleSelectSearch;

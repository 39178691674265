import React, {useEffect, useState} from 'react';
import SelectField from "../../formElements/SelectField";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Api from "../../../services/api";
import {handleErrorMessage} from "../../Messages/actions";
const service = new Api();

const ProjectField = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {isLoading, ...restProps} = props;
    const params = useParams();
    const {id} = params; // expenseId from url
    const [isFetching, setIsFetching] = useState(false);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const promises = [
            service.getProjectsAll(),
            id ?  service.getExpenseProjects(id) : service.getMyProjects()
        ];

        Promise.all(promises).then((response) => {
            const [allProjectsResult, availableProjects] = response;

            const projects = allProjectsResult.data.projects.map(({name, _id}) => {
                const option = {
                    label:name,
                    value:_id
                }

                const isDisabled = !(~availableProjects.data.findIndex(item => item._id === _id));
                if(isDisabled){
                    option.disabled=true
                }

                return option
            });

            setProjects(projects);

        }).catch((e) => {
            console.log(e);
            dispatch(handleErrorMessage(e))
        }).finally(() => {
            setIsFetching(false);
        });

    },[]);


    return (
        <SelectField
            label={t("formFields.project")}
            name={"project"}
            options={projects}
            isLoading={isFetching || isLoading}
            {...restProps}
        />
    );
};

export default ProjectField;

import React from 'react';
import {useTranslation} from "react-i18next";
const blogUri = window._env_.BLOG_URI;


const TermsOfServiceLink = (props) => {
    const {t} = useTranslation();

    return (
        <>

            {/* <a
                href={`${blogUri}/service-terms`}
                rel="nofollow noreferrer noopener"
                target="_blank"
                {...props}
            >
                {t("formFields.termsOfService")}
            </a> */}
        </>
    );
};

export default TermsOfServiceLink;

const canUseCardFunctionality = (country) => {
    const availableCountries = [
        "702",
        "040",
        "056",
        "100",
        "191",
        "196",
        "203",
        "208",
        "233",
        "246",
        "250",
        "276",
        "300",
        "348",
        "372",
        "380",
        "428",
        "440",
        "442",
        "470",
        "528",
        "616",
        "620",
        "642",
        "703",
        "705",
        "724",
        "752",
    ]; // Singapore & European union countries

    return country && availableCountries.includes(country);
};

export default canUseCardFunctionality;

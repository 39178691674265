import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../Messages/actions";
import FilterFormControl from "../../FilterFormControl";
import SelectField from "../../../../formElements/SelectField";
import Api from "../../../../../services/api";
const service = new Api();


const KycStatusSelectField = (props) => {
    const dispatch = useDispatch();

    const [kycStatuses, setKycStatuses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchKycUserStatuses = async () => {
        try{
            setIsLoading(true);
            const userStatusesResponse = await service.getServiceData({dataFor:"kycStatuses"});

            const {kycStatuses} = userStatusesResponse.data;
            const mappedKycStatuses = kycStatuses.map((kyc) => ({label:kyc, value:kyc}));
            setKycStatuses(mappedKycStatuses);

        }catch (e) {
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchKycUserStatuses();
    },[]);

    return (
        <FilterFormControl>
            <SelectField
                {...props}
                options={kycStatuses}
                isLoading={isLoading}
            />
        </FilterFormControl>
    );
};

export default KycStatusSelectField;

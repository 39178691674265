import React, {useEffect, useState} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {FormControl, Grid, makeStyles, useTheme} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Api from "../../../../../services/api";
import {setMessage, handleErrorMessage} from "../../../../Messages/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import InputField from "../../../../formElements/InputField";
import DialogPopUp from "../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import useForm2 from "../../../../../hooks/useForm2";
import {Can} from "../../../../../Context/Can";
import {subject} from "@casl/ability";
const service = new Api();

const useStyles = makeStyles(theme => ({
	buttonsWrapper:{
		justifyContent: "center",
		flexWrap:"wrap",
		paddingLeft: "45px",
		paddingRight: "45px",
	},
	form:{
		width:"300px",
		maxWidth:"100%",
		margin: "0 auto",
        paddingTop:"20px",
        paddingBottom:"20px"
	}
}));



const DialogReport = (props) => {

    const classes = useStyles();
    const {setMessage, setOpen, loading, title, report, updateReportState} = props;
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validate = (values) => {
        const errors = {};

        const fileNameRegex = /^[\w]+$/;

        if(!values.name){
            errors.name = t("errors.reportNameIsNotValid", {field:"$t(formFields.name)"});
        }else if (!fileNameRegex.test(values.name)) {
            errors.name = t("errors.reportNameIsNotValid", {field:"$t(formFields.name)"});
        }

        return errors;
    };

    useEffect(() => {
        if(report){
            // fill form data from parent component
            const {name} = report;
            fillFormValues({name})
        }
    },[report]);


    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    async function submit(){
        setIsSubmitting(true);
        try {
            const {_id} = report;
            const response = await service.updateReport(_id, values);
            updateReportState(response.data);
            setMessage("success", 'success');
            setOpen(false);
        } catch (e) {
            setServerErrors(e);
        } finally {
            setIsSubmitting(false);
        }
    }


    return (
        <DialogPopUp
            open={true}
            onClose={() => setOpen(false)}
            aria-labelledby="report-dialog-title"
            aria-describedby="report-dialog-description"
            title={title}
        >
            <DialogContent>
                <form onSubmit={handleSubmit} noValidate className={classes.form}>
                    <Grid container spacing={5}>
                        <Can I="update" this={subject("report", report)} passThrough>
                            {(can) => (
                                <>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={!!errors.name}>
                                            <InputField
                                                label={t("formFields.name")}
                                                name="name"
                                                isLoading={loading}
                                                disabled={!can}
                                                value={values["name"]}
                                                onInputChange={(val) => handleChange("name", val)}
                                                error={errors["name"]}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </Can>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className={classes.buttonsWrapper}>
                <Can I="update" this={subject("report", report)}>
                    <Button onClick={handleSubmit} disabled={isSubmitting || loading} color="primary" variant="contained">
                        {t("formFields.update")}
                    </Button>
                </Can>
            </DialogActions>
        </DialogPopUp>
    );
};

export default compose(
    connect(null, {setMessage, handleErrorMessage})
)(DialogReport);

import React from 'react';
import {makeStyles, Menu, Popover} from "@material-ui/core";
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles(theme => ({
    menu: {

        "& .MuiPaper-root": {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '0',
            margin: '0',
            boxShadow: 'none',
            borderRadius: '0',
            "&:before":{
                content: `""`,
                position: 'absolute',
                right: '20px',
                bottom: '100%',
                width: '20px',
                height: '10px',
                background: (props) => props.isDarkModeActive ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto` : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto`,
            },

            "& .MuiList-root":{
                boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
                border: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid transparent',
                backgroundColor: (props) => props.isDarkModeActive ? 'var(--almost-black)' : 'var(--white)',
                borderRadius: '16px',
                padding: '12px',

            },
        },

        "& .MuiList-root": {
            "& .MuiButtonBase-root": {

                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '12px',
                borderRadius: '12px',
                transition: 'all .2s',
                cursor: 'pointer',
                "&:hover": {
                    "& .MuiTypography-root": {
                        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)',
                        backgroundColor: (props) => props.isDarkModeActive ? 'transparent' : 'var(--very-light-pink-secondary)'
                    }
                },
                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            },
            "& .MuiTypography-root": {
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '1.6',
                color: 'var(--battelship-grey)',
                fontFamily: `'Inter', sans-serif`,
                letterSpacing: '0'
            }
        }
    },
}));


export default React.forwardRef((props, ref) => {

    const {children, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    return (
        <Menu
            className={classes.menu}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                elevation:1
            }}
            style={{ marginTop: '12px' }}  // Sesuaikan nilai offset sesuai kebutuhan Anda
            {...props}
        >
            {children}
        </Menu>
    )
})

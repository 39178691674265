import React, {useState} from 'react';
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import useForm2 from "../../../../../hooks/useForm2";
import {setMessage} from "../../../../Messages/actions";
import DepartmentForm from "../../../../DepartmentForm";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import Api from "../../../../../services/api";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            marginBottom: '24px'
        },
    },
    item: {
        position: 'relative'
    },
}))

const AddDepartment = (props) => {
    const classes = useStyle();
    const {underBarRef, history} = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validate = (values) => {
        const errors = {};
        const accountingCodeRegex = getAlphaNumericRegex();
        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }

        if (values["accountingCode"] && !accountingCodeRegex.test(values["accountingCode"].toString())) {
            errors.accountingCode = t("errors.notValid", {field:"$t(formFields.accountingCode)"});
        }
        return errors;
    };

    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.createDepartment(values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/departments");
        }catch (e) {
            console.log(e);
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm2(
        submit,
        validate,
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <DepartmentForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                    />

                    <Can I="create" a={"department"}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {t("formFields.create")}
                        </Button>
                    </Can>
                </div>
            </div>
        </>
    );
};

export default AddDepartment;

import React from 'react';
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const TransactionDescription = ({description, fontSize}) => {

	const useStyles = makeStyles(theme => ({
		limit:{
            display: "inline-block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth:"300px",
            textOverflow: "ellipsis",
            fontSize:fontSize ? fontSize : "inherit",
            verticalAlign:"top"
		}
	}));

	const classes = useStyles();

	return description ? (
				<Tooltip title={description}>
					<span className={classes.limit}>{description}</span>
				</Tooltip>
			) : null

};

export default TransactionDescription;

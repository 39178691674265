import React, {useEffect, useState} from 'react';
import DialogPopUp from "../../../../../DialogPopUp";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {FormControl, Grid, makeStyles} from "@material-ui/core";
import useForm2 from "../../../../../../hooks/useForm2";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../services/api";
import Button from "@material-ui/core/Button";
import SelectField from "../../../../../formElements/SelectField";


const service = new Api();

const useStyles = makeStyles(theme => ({
    buttonsWrapper:{
        justifyContent: "center",
        flexWrap:"wrap",
        paddingLeft: "45px",
        paddingRight: "45px",
    },
    actionButton:{
        background: "#24dac4",
        fontWeight: "600",
        padding: "5px 15px",
        color: "#fff",
        margin:"10px",
        textTransform: "capitalize",
        boxShadow:"none",
        '&:hover':{
            background:"rgba(0, 0, 0, 0.8)"
        }
    },
    form:{
        width:"300px",
        maxWidth:"100%",
        margin: "0 auto",
        paddingTop:"20px",
        paddingBottom:"20px"
    }
}));

const DialogAssignApprover = (props) => {
    const {setOpen, title, open, setMessage, onAssignApprover, handleErrorMessage} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [approvers, setApprovers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const approversLength = approvers.length;


    useEffect(() => {
        if(open && !approversLength){
            fetchApprovers();
        }
    },[open, approversLength]);

    const fetchApprovers = async () => {
       try{
           setIsLoading(true);
           const response = await service.getUsersAll({roles:"approver"});
           const mappedUsers = response.data.map((user) => {
               const {_id, firstName, lastName} = user;
               return {value: _id, label: `${firstName} ${lastName}`}
           });
           setApprovers(mappedUsers);
       }catch (e) {
           console.log(e);
           handleErrorMessage(e)
       }finally {
           setIsLoading(false);
       }

    }


    const submit = async () => {

        try {
            setIsSubmitting(true);
            await onAssignApprover(values["user"]);
            setOpen(false);
            setMessage("success", 'success');
        }catch (e) {
            setServerErrors(e);
        }finally {
            setIsSubmitting(false);
        }

    }

    const validate = (values) => {
        const errors = {};
        if(!values.user){
            errors.user = t("errors.required", {field:"$t(formFields.user)"});
        }
        return errors;
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    const onExitHandler = () => {
        fillFormValues({});
    }


    return (
        <DialogPopUp
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="assign-approver-dialog-title"
            aria-describedby="assign-approver-dialog-description"
            title={title}
            onExited={onExitHandler}
        >
            <DialogContent>
                <form onSubmit={handleSubmit} noValidate className={classes.form}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.user}>
                                <SelectField
                                    isLoading={isLoading}
                                    label={t("formFields.user")}
                                    name={"user"}
                                    value={values["user"]}
                                    error={errors["user"]}
                                    options={approvers}
                                    onSelectChange={(val) => handleChange("user", val)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                >
                    {t("formFields.assignApprover")}
                </Button>
            </DialogActions>
        </DialogPopUp>
    );
};

export default DialogAssignApprover;

import React, {useState} from "react";
import ExpenseQueuesContext from "../../Context/ExpenseQueuesContext";

const ExpenseQueuesProvider = (props) => {
    const [expenseQueue, setExpenseQueue] = useState([]);
    return (
        <ExpenseQueuesContext.Provider value={[expenseQueue, setExpenseQueue]}>
            {props.children}
        </ExpenseQueuesContext.Provider>
    )
}

export default ExpenseQueuesProvider

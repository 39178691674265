import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import useMounted from "../../../../../hooks/useMounted";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Modal,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
// import VolveSnackbar from "../../../../VolveSnackbar";
import useForm2 from "../../../../../hooks/useForm2";
import InputFieldMemo from "../../../../formElements/InputFieldMemo";
import { useCallback } from "react";
import Api from "../../../../../services/api";
// import useExpenseValidation from "../../../../../hooks/useExpenseValidation";
import { MESSAGES } from "../../../../../constants/transactions";
import { setMessage } from "../../../../Messages/actions";
import { useDispatch } from "react-redux";
import SelectField from "../../../../formElements/SelectField";
import { ReactComponent as CloseSVG } from "../../../../../assets/closeInvesthub.svg";
import { isDarkModeActive } from "../../../../App/App";

const service = new Api();
const useStyles = makeStyles((theme) => ({
    itemRadio: {
        "& > .MuiFormLabel-root": {
            display: "block",
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0",
        },
        "& .MuiFormControlLabel-root:not(:last-child)": {
            marginRight: "16px",
        },
        "& .MuiFormGroup-root": {
            flexFlow: "row wrap",
        },
    },
    formControlLabel: {
        "& .MuiFormControlLabel-label": {
            fontSize: "15px",
            marginTop: "2px",
            paddingLeft: "2px",
        },
    },
    itemForm: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        [theme.breakpoints.down("1023")]: {
            display: "block",
        },
    },
    itemFormFieldSet: {
        position: "relative",
        width: "100%",
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: "32px",
                [theme.breakpoints.down("1023")]: {
                    marginBottom: "24px",
                },
            },

            "& $itemField": {
                flex: "0 0 calc(50% - 16px)",
                width: "calc(50% - 16px)",
                margin: "0 8px",
                [theme.breakpoints.down("1023")]: {
                    width: "100%",
                    margin: "0",
                },
            },
        },
    },

    itemField: {},

    itemRow: {
        display: "flex",
        margin: "0 -8px",
        [theme.breakpoints.down("1023")]: {
            display: "block",
            margin: "0",
        },
        "& $itemField": {
            [theme.breakpoints.up("1023")]: {
                marginBottom: "0 !important",
            },
        },
    },
    itemFormSubmit: {
        marginTop: "48px",
        [theme.breakpoints.down("1023")]: {
            marginTop: "32px",
        },
    },
}));

const CreateAccountPopup = (props) => {
    const {
        isOpenModal,
        setIsOpenModal,
        types,
        shouldRefresh,
        setShouldRefresh,
        settingsDarkMode,
    } = props;
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });

    const { t } = useTranslation();
    const handleClose = () => {
        setIsOpenModal(false);
    };
    const [msg, setMsg] = useState("");
    const dispatch = useDispatch();
    const validate = (values) => {
        let errors = {};
        if (!values.code) {
            errors.code = t("errors.required", {
                field: t("formFields.code"),
            });
        }
        if (!values.name) {
            errors.name = t("errors.required", {
                field: t("formFields.name"),
            });
        }
        if (!values.type) {
            errors.type = t("errors.required", {
                field: t("formFields.type"),
            });
        }
        if (!values.description) {
            errors.description = t("errors.required", {
                field: t("formFields.description"),
            });
        }
        return errors;
    };
    const submit = async () => {
        try {
            const response = await service.createCompanyXeroAccounts(values);

            if (response) {
                setMsg(MESSAGES.CREATED_SUCCESS);
                dispatch(setMessage(t("success"), "success"));
                setIsOpenModal(false);
                if (!shouldRefresh) {
                    setShouldRefresh(true);
                }
                fillFormValues({}); //reset form
            }
        } catch (err) {
            if (err?.response?.data?.msg) {
                setMsg(err?.response?.data?.msg);
                dispatch(setMessage(t(err?.response?.data?.msg), "error"));
            }
        }
    };

    const { handleChange, handleSubmit, fillFormValues, values, errors, setServerErrors } =
        useForm2(submit, validate);
    const memoizedCallback = useCallback(handleChange, []);

    return (
        <>
            <Modal
                open={isOpenModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="big-modal"
            >
                <Box className={`${classes.modalInner} big-modal__inner`}>
                    <Box className={"big-modal__set"}>
                        <Box className={"big-modal__control"}>
                            <Box
                                type="button"
                                onClick={handleClose}
                                className="big-modal__close"
                            >
                                <CloseSVG />
                            </Box>
                        </Box>

                        <Box className={`big-modal__wrapper`}>
                            <Box className={`big-modal__wrapper-inner`}>
                                <Typography
                                    component="h2"
                                    className={`h4 big-modal__title`}
                                >
                                    Create New Account
                                </Typography>
                                <div className={classes.itemField}>
                                    Create a new account to tag your
                                    transactions accordingly.
                                </div>
                                <Box className={`big-modal__content`}>
                                    <form
                                        onSubmit={handleSubmit}
                                        encType="multipart/form-data"
                                        noValidate
                                        className={classes.itemForm}
                                    >
                                        <Box
                                            className={classes.itemFormFieldSet}
                                        >
                                            <Box
                                                className={classes.itemSettings}
                                            >
                                                <Box
                                                    className={classes.itemRow}
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        error={
                                                            !!errors[
                                                                "firstName"
                                                            ]
                                                        }
                                                        className={
                                                            classes.itemField
                                                        }
                                                    >
                                                        <InputFieldMemo
                                                            name={"code"}
                                                            label={t(
                                                                "formFields.code"
                                                            )}
                                                            value={
                                                                values["code"]
                                                            }
                                                            error={
                                                                errors["code"]
                                                            }
                                                            onInputChange={
                                                                memoizedCallback
                                                            }
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <FormControl
                                                    fullWidth
                                                    error={!!errors["name"]}
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <InputFieldMemo
                                                        name={"name"}
                                                        label={t(
                                                            "formFields.name"
                                                        )}
                                                        value={values["name"]}
                                                        error={errors["name"]}
                                                        onInputChange={
                                                            memoizedCallback
                                                        }
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    fullWidth
                                                    error={!!errors["type"]}
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <SelectField
                                                        label={t(
                                                            "formFields.type"
                                                        )}
                                                        placeholder={t(
                                                            "formFields.type"
                                                        )}
                                                        name={"type"}
                                                        value={values["type"]}
                                                        error={errors["type"]}
                                                        options={types}
                                                        onSelectChange={(val) =>
                                                            handleChange(
                                                                "type",
                                                                val
                                                            )
                                                        }
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    fullWidth
                                                    error={
                                                        !!errors["description"]
                                                    }
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <InputFieldMemo
                                                        name={"description"}
                                                        label={t(
                                                            "formFields.description"
                                                        )}
                                                        value={
                                                            values[
                                                                "description"
                                                            ]
                                                        }
                                                        error={
                                                            errors[
                                                                "description"
                                                            ]
                                                        }
                                                        onInputChange={
                                                            memoizedCallback
                                                        }
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Box>

                                        <Button
                                            type="submit"
                                            className={classes.itemFormSubmit}
                                        >
                                            {/* NEED TRANSLATION */}
                                            {t("Create")}
                                        </Button>
                                        <div
                                            style={{
                                                marginTop: "2rem",
                                                color:
                                                    msg ===
                                                    MESSAGES.CREATED_SUCCESS
                                                        ? "green"
                                                        : "red",
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default withRouter(CreateAccountPopup);

import * as actionTypes from '../../store/actionTypes'
const initialState = {
	isAuthenticated:false,
	userData:{},
	settings:{},
    commonRules:[],
	roles:[]
};
const user = (state=initialState, action) => {
	const {type, payload} = action;
	switch (type) {
		case actionTypes.USER_LOGIN_SUCCESS :
			const {userData, settings, roles, commonRules} = payload;
			return {
				...state,
				isAuthenticated:true,
				userData: userData,
				settings,
                commonRules,
				roles
			};
		case actionTypes.USER_UPDATE :
			return {
				...state,
				userData:payload.userData,
				settings:payload.settings,
				roles:payload.roles
			};
		case actionTypes.USER_UPDATE_DATA :
			return {
				...state,
				userData:{
					...state.userData,
					...payload
				}
			}
		case actionTypes.USER_UPDATE_COMPANY :
			return {
				...state,
				userData:{
					...state.userData,
					company:{
						...state.userData.company,
						...payload
					}
				}
			}
        case actionTypes.USER_UPDATE_COMPANY_ADVANCED_SETTINGS :
            return {
                ...state,
                userData:{
                    ...state.userData,
                    company:{
                        ...state.userData.company,
                        advancedSettings:{
                            ...state.userData.company.advancedSettings,
                            ...payload
                        }
                    }
                }
            }
            case actionTypes.USER_UPDATE_CONFIGURABLE_PERMISSIONS :
            return {
                ...state,
                userData:{
                    ...state.userData,
                    company:{
                        ...state.userData.company,
                        updateConfigurablePermissions:{
                            ...state.userData.company.updateConfigurablePermissions,
                            ...payload
                        }
                    }
                }
            }
		case actionTypes.USER_LOGIN_FAIL :
		case actionTypes.USER_LOGOUT :
			return initialState;
	}
	return state
}

export default user

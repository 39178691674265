import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";
import SelectField from "../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(theme => ({
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 8px)',
        width: 'calc(100% - 8px)',
        margin: '0 8px 16px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 8px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    }

}));

const NewAccountForm = (props) => {
	const {t} = useTranslation();
	const classes = useStyles();

	const {handleSubmit, values, handleChange, errors, isDataFetching, isReadOnly, currencies} = props;


	return (
		<>
			<form onSubmit={handleSubmit} noValidate>
                <div className={classes.itemSettings}>
                    <FormControl fullWidth error={!!errors[`currency`]} className={classes.itemFieldFull}>
                        <SelectField
                            label={t("formFields.currency")}
                            name={"currency"}
                            value={values["currency"]}
                            error={errors["currency"]}
                            options={currencies}
                            onSelectChange={(val) => handleChange("currency", val)}
                            isLoading={isDataFetching}
                        />
                    </FormControl>
                </div>
			</form>
		</>
	);
};

export default NewAccountForm;

import React, {useEffect, useState} from 'react';
import {FormControl, makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useForm2 from "../../../../../hooks/useForm2";
import Api from "../../../../../services/api";
import {setMessage} from "../../../../Messages/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import InputField from "../../../../formElements/InputField";
import DialogPopUp from "../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../Context/Can";
import { isDarkModeActive } from '../../../../App/App';
const service = new Api();

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        minWidth: '340px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'initial'
        }
    },
    buttonWrapper: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px'
    },
    button: {
        marginTop: '24px'
    },
    field: {
        '& label + .MuiInput-formControl': {
            marginTop: '0'
        }
    }
}));



const DialogRejectReason = (props) => {
    const {setMessage, setOpen, loading, rejectReason, open, onExited, fetchItems, settingsDarkMode} = props;
    const {t} = useTranslation();

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }

        return errors;
    };

    useEffect(() => {
        if(Object.keys(rejectReason).length){
            const {name} = rejectReason;
            fillFormValues({name})
        }else{
            fillFormValues({})
        }

    },[rejectReason]);


    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    async function submit(){

        setIsSubmitting(true);
        try {

            const {_id} = rejectReason;
            if(_id){
                await service.updateRejectReason(_id, values);
            }else{
                await service.createRejectReason(values);
            }
            setIsSubmitting(false);
            setMessage("success", 'success');
            setOpen(false);
            await fetchItems();
        } catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const onDeleteAction = async () => {
    setIsSubmitting(true);
    try {
        const {_id} = rejectReason;
            await service.deleteRejectReason(_id);
            setMessage("success", 'success');
            setIsSubmitting(false);
            setOpen(false);
            await fetchItems();
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }

    }

    return (
        <DialogPopUp
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="rejectReason-dialog-title"
            aria-describedby="rejectReason-dialog-description"
            title={rejectReason._id ? t("rejectReason.editTitle") : t("rejectReason.addNew")}
            onExited={onExited}
            pageTitleClass={'card-title--red'}
            settingsDarkMode={settingsDarkMode}
        >
            <div className={classes.item}>
                <form onSubmit={handleSubmit} noValidate >
                    <Can I="update" a={"rejectReason"} passThrough>
                        {(can) => (
                            <FormControl fullWidth error={!!errors.name} className={classes.field}>
                                <InputField
                                    label={t("formFields.name")}
                                    placeholder={t("formFields.name")}
                                    name="name"
                                    value={values["name"]}
                                    onInputChange={(val) => handleChange("name", val)}
                                    error={errors["name"]}
                                    isLoading={loading}
                                    disabled={!can}
                                />
                            </FormControl>
                        )}
                    </Can>
                </form>

                <div className={classes.buttonWrapper}>
                    {rejectReason._id ? (
                        <Can I="update" a={"rejectReason"}>
                            <Button
                                onClick={handleSubmit}
                                disabled={isSubmitting || loading}
                                className={classes.button}
                            >
                                {t("formFields.update")}
                            </Button>
                        </Can>
                    ) : (
                        <Can I="create" a={"rejectReason"}>
                            <Button
                                onClick={handleSubmit}
                                disabled={isSubmitting || loading}
                                className={classes.button}
                            >
                                {t("formFields.create")}
                            </Button>
                        </Can>
                    )}
                    {rejectReason._id && (
                        <Can I="delete" a={"rejectReason"}>
                            <Button
                                onClick={onDeleteAction}
                                disabled={isSubmitting || loading}
                                className={`btn-stroke ${classes.button} ${classes.buttonLast}`}
                            >
                                {t("formFields.delete")}
                            </Button>
                        </Can>
                    )}
                </div>
            </div>
        </DialogPopUp>
    );
};

export default compose(
	connect(null, {setMessage})
)(DialogRejectReason);

import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import getCardIsDisabled from "../../../../../../../helpers/getCardIsDisabled";
import {useTranslation} from "react-i18next";
import {subject} from "@casl/ability";
import ButtonRequestInfo from "../ButtonRequestInfo";

const service = new Api();
const {receiveCardPan} = service;

const ReceivePan = (props) => {

	const {card, handleErrorMessage, setMessage, ability} = props;
	const {_id:cardId} = card;
	const {t} = useTranslation();
	const [isSending, setIsSending] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const onSendPan = async () => {
		try {
			setIsSending(true);
			await receiveCardPan(cardId);
			setIsSending(false);
			setMessage("success", 'success');
		}catch (e) {
			handleErrorMessage(e);
			setIsSending(false);
		}
	};

	useEffect(() => {
		if(card){
			const isCardDisabled = getCardIsDisabled(card.currentStatCode);
			setIsDisabled(isCardDisabled);
		}
	},[card]);

    const canReceivePan = () => {
        if(card.user && card.company){
            const permissionObj = {
                user:card.user.id,
                company:card.company.id,
                type:card.type,
                currentStatCode: card.currentStatCode
            };

            return ability.can("receive:pan", subject("card", permissionObj));
        }
        return false
    }

    const showButton = canReceivePan();

    return showButton ? <ButtonRequestInfo disabled={isDisabled || isSending} onClick={onSendPan}>{t("formFields.receivePan")}</ButtonRequestInfo> : null
};

export default ReceivePan;

import React, {
    // eslint-disable-next-line no-unused-vars
    Fragment,
    // eslint-disable-next-line no-unused-vars
    useEffect,
    useMemo,
    // eslint-disable-next-line no-unused-vars
    useState
} from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import { ReactComponent as ActivitySVG } from "../../../../../../../assets/activityInvesthub.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as PaymentLogo } from "../../../../../../../assets/paymentInvesthub.svg";
import { ReactComponent as ClockLogoSVG } from "../../../../../../../assets/clockInvesthub.svg";
// eslint-disable-next-line no-unused-vars
import { ReactComponent as Logo } from "../../../../../../../assets/logo.svg";
// import {ReactComponent as Logo} from "../../../../../../../assets/logoInvesthub.svg";
import FormatMoney from "../../../../../../FormatMoney";
// eslint-disable-next-line no-unused-vars
import VolveSnackbar from "../../../../../../VolveSnackbar";
import { ACCOUNT_TYPES } from "../../../../../../../constants/transactions";
import { isDarkModeActive } from "../../../../../../App/App";
import formatMoney from "../../../../../../../helpers/formatMoney";

const useStyles = makeStyles((theme) => ({
    totalDetails: {
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        }
    },
    totalTitle: {
        marginBottom: '4px'
    },
    list: {},

    item: {
        display: "flex",
        paddingBottom: "24px",
        alignItems: 'center',
        "&:not(:last-child)": {
            marginBottom: "24px",
            borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        },
    },

    icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "64px",
        height: "64px",
        marginRight: '12px',
        borderRadius: "50%",
        border: (props) => props.isDarkModeActive ? '2px solid var(--dark-primary)' : '2px solid var(--very-light-pink-secondary)',

        "& svg": {
            width: "24px",
            height: "24px",
            fill: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-primary)',
        },
    },

    content: {
        flex: "1",
        fontFamily: `'Inter', sans-serif`,
    },

    contentGrid: {
        display: 'grid',
        gridAutoColumns: '1fr',
        gridColumnGap: '1.5rem',
        gridRowGap: '1.5rem',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        [theme.breakpoints.down('1200')]: {
            gridTemplateColumns: '1fr 1fr 1fr'
        },
        [theme.breakpoints.down('767')]: {
            gridTemplateColumns: '1fr 1fr'
        },
        [theme.breakpoints.down('476')]: {
            gridTemplateColumns: '1fr'
        }
    },

    inner: {
        "& span": {
            lineHeight: "1.33333",
            color: "var(--battelship-grey)",
            display: 'block'
        },
    },
}));

const Details = (props) => {
    const { isLoading, companyName, accountTabs, account, settingsDarkMode } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const { t } = useTranslation();

    const renderDetailsComponent = useMemo(() => {
        if (account?.type === ACCOUNT_TYPES.FIAT) {
            return <div className={`${classes.content} ${classes.contentGrid}`}>
            <div className={classes.inner}>
                {t("account.accountDetailHolder")}
                <span>{companyName}</span>
            </div>

            <div className={classes.inner}>
                {t("account.accountDetailNumber")}
                <span>{account?.iban || ""}</span>
            </div>

            <div className={classes.inner}>
                {t("account.accountDetailBank")}
                <span>{account?.bankName || ""}</span>
            </div>

            <div className={classes.inner}>
                {t("account.accountDetailActualBalance")}
                <span>
                    {account?.balance?.sumInCurrency || 0}{" "}
                    {account?.toCurrency}
                </span>
            </div>

            <div className={classes.inner}>
                {t("account.accountDetailAvailableBalance")}
                <span>
                    {formatMoney(account?.balance?.available || 0)}{" "}
                    {account?.toCurrency}
                </span>
            </div>
        </div>
        } else if (account?.type === ACCOUNT_TYPES.MONERIUM) {
            return       <div className={`${classes.content} ${classes.contentGrid}`}>
                            <div className={classes.inner}>
                                {t("account.accountWalletAddress")}
                                <span>{account?.address}</span>
                            </div>

                            <div className={classes.inner}>
                                {t("account.accountNetwork")}
                                <span>{account?.network || ""}</span>
                            </div>

                            <div className={classes.inner}>
                                {t("account.accountChain")}
                                <span>{account?.chain || ""}</span>
                            </div>

                            <div className={classes.inner}>
                                {t("account.accountDetailAvailableBalance")}
                                <span>
                                    {formatMoney(account?.balance?.sumInCurrency || 0)}
                                </span>
                            </div>

                            <div className={classes.inner}>
                                {t("account.accountCurrency")}
                                <span>
                                    {account?.balance?.toCurrency.toUpperCase()}
                                </span>
                            </div>
                        </div>
        } else if (account?.type === ACCOUNT_TYPES.TANGANY || account?.type === ACCOUNT_TYPES.IMPORTED) {
            return       <div className={classes.content}>
                            <div className={classes.inner}>
                                {t("account.accountWalletAddress")}
                                <span>{account?.address}</span>
                            </div>

                            <div className={classes.inner}>
                                {t("account.accountDetailAvailableBalance")}
                                <span>
                                    {formatMoney(account?.balance?.sumInCurrency || 0)}
                                </span>
                            </div>

                            <div className={classes.inner}>
                                {t("account.accountCurrency")}
                                <span>
                                    {account?.balance?.toCurrency.toUpperCase()}
                                </span>
                            </div>
                        </div>
        }
    }, [account])

    const {accountNumber, balance={}} = account;
    const {toCurrency, sumInCurrency} = balance;

    return isLoading ? (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress />
        </Box>
    ) : (
        <Box className="card">
            {accountTabs}

            <Box className={classes.totalDetails}>
                <Box className={`${classes.totalTitle} h4`}>
                    {account.isLoading ? '...' : (
                        accountNumber && (
                            <>
                                {t('formFields.accountNo')}
                                {' '}
                                {accountNumber}
                            </>
                        )
                    )}
                    {account.isLoading ? '...' : (
                        sumInCurrency && (
                            <>
                                {t('account.totalBalance')}
                                {' '}
                                {toCurrency} <FormatMoney>{sumInCurrency}</FormatMoney>
                            </>
                        )
                    )}
                </Box>
            </Box>

            {/* {accountCreationIsNotFinished && (
            <>
                <VolveSnackbar
                    message={<>{t("account.isNotCreatedCompletely")}</>}
                    icon={<Logo/>}
                />

                <div className="width-full height-24">{' '}</div>
            </>
        )} */}

            <Box className={classes.list}>
                <Box className={classes.item}>
                    <Box className={classes.icon}>
                        <PaymentLogo />
                    </Box>

                    <Box className={classes.content}>
                        {t("account.accountDetailText1")}
                    </Box>
                </Box>

                <Box className={classes.item}>
                    <Box className={classes.icon}>
                        <ClockLogoSVG />
                    </Box>

                    <Box className={classes.content}>
                        {t("account.accountDetailText1")}
                    </Box>
                </Box>

                <Box className={classes.item}>
                    {renderDetailsComponent}
                </Box>
            </Box>
        </Box>
    );
};

export default Details;

import React from 'react';
import Button from "@material-ui/core/Button";
import {ReactComponent as ArrowBottom} from "../../assets/arrowBottom.svg";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    endIcon: {
        width: '10px',
        height: '10px',
        "& path": {
            transition: 'all .2s',
            stroke: 'var(--white)'
        }
    },
}));


const ActionButton = (props) => {
    const {children, customClass, ...restProps} = props;
    const classes = useStyles();

    return (
        <Button
            className={`${classes.button} btn-small`}
            endIcon={<ArrowBottom className={classes.endIcon}/>}
            {...restProps}
        >
            {children}
        </Button>
    );
};

export default ActionButton;

import * as actionTypes from '../../store/actionTypes'
const initialState = {};
const publicSettings = (state=initialState, action) => {
	const {type, payload} = action;
	switch (type) {
		case actionTypes.INIT_SETTING :
			return payload;
	}
	return state
}

export default publicSettings

import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import formatMoney from "../../helpers/formatMoney";



const FormatMoney = (props) => {

	const {children, userSettings} = props;

	const [decimal, setDecimal] = useState(".");

	useEffect(() => {
		if(userSettings.regional && userSettings.regional.decimal){
			setDecimal(userSettings.regional.decimal);
		}
	},[userSettings]);

	return (
		<>
			{formatMoney(children, decimal)}
		</>
	);
};
const mapStateToProps = ({user}) => {
	return {
		userSettings:user.settings
	}
}

export default connect(
	mapStateToProps
)(FormatMoney);

import React, {
    // useState
} from 'react'
import AppBar from "@material-ui/core/AppBar";
import {
    makeStyles,
    useMediaQuery,
    useTheme
    // Button
} from "@material-ui/core";
import TopTabs from '../Bar/TopTabs/TopTabs'
import ButtonMenu from './ButtonMenu';
import {connect} from "react-redux";
import { drawerMainToggle } from '../DrawerMain/actions';
// import { Link } from "react-router-dom";
import { isDarkModeActive } from '../../App/App';

// Inner-Component
import User from './User';
import Notifications from './Notifications';

const isProduction = window._env_.ENVIRONMENT === 'prod';
const isStaging = window._env_.ENVIRONMENT === 'dev';

const redirectUrl = `https://dev-crypto.paladis.com/`
const BarInvesthub = (props) => {
    const theme = useTheme();
    const isDown1023 = useMediaQuery(theme.breakpoints.down('1023'));
    const { isOpen, tenantHost, settings } = props;

    // eslint-disable-next-line no-unused-vars
    const handleNavigate = () => {
        window.location.href = `${process.env.REDIRECT_PALADIS_URL || redirectUrl}`;
    }
    const useStyles = makeStyles({
        headerControl: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            [theme.breakpoints.down('767')]: {
                marginLeft: 'auto'
            }
        },
        headerControlLink: {
            color: 'var(--battelship-grey)',
            transition: 'color .s',
            marginRight: '24px',
            "&:hover": {
                color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--dark-secondary)'
            }
        }
    });
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });

    console.log('tenantHost', tenantHost)
    return (
        <AppBar color="default" className={`${isOpen ? "open" : "not open"}`}>
            {isDown1023 && (<ButtonMenu />)}

            <TopTabs settings={settings} />

            <div className={classes.headerControl}>
                <a href={isProduction ? `https://app.investhub.io/tokenlist` : isStaging ? 'https://dev-app.investhub.io/tokenlist' : 'http://localhost:5001/tokenlist'} className={classes.headerControlLink}>
                    Investhub.io
                </a>
                <Notifications settings={settings} />
                <User settings={settings} />
            </div>
        </AppBar>
    )
}

const mapStateToProps = (state) => {
	const { userData = {} } = state.user;

	const { tenantHost } = userData;
	return {
		tenantHost
	}
}

export default connect(
    mapStateToProps,
    {drawerMainToggle}
)(BarInvesthub)

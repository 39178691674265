import React, {useEffect, useState} from 'react';
import {FormControl, Grid, makeStyles} from "@material-ui/core";
import SelectField from "../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
import formatMoney from "../../../../../../helpers/formatMoney";
import Box from "@material-ui/core/Box";
import ShortInfoItem from "../../../../../ShortInfoItem";

const useStyles = makeStyles(theme => ({
    selectWrapper: {
        marginRight: "50px",
        '& svg':{
            width:"20px",
            height:"10px"
        },
        '& .MuiSelect-selectMenu':{
            fontSize: "25px",
            lineHeight: "38px",
            fontWeight: 300,
        },
        '& .MuiInput-underline:after': {
            display: "none"
        },
        "& .MuiInput-underline::before": {
            display: "none"
        }
    },
    totalLabel: {
        color: "#637280",
        fontSize: "15px"
    },
    totalValue: {
        color: "#354052",
        fontSize:"25px",
        fontWeight:300
    }

}));


const ChartFilter = (props) => {
    const {handleChange, values, months, totalAmount, decimal, currency} = props;
    const {t} = useTranslation();

   const classes = useStyles();

    return (
        <Grid container justify="space-between" component={"form"}>
            <Grid item>
                <FormControl className={classes.selectWrapper}>
                    <SelectField
                        name={"date[gte]"}
                        value={values["date[gte]"]}
                        options={months}
                        onSelectChange={(val) => handleChange("date[gte]", val)}
                        hideEmpty
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <Box paddingTop={"10px"}>
                    <ShortInfoItem label={t("expense.spentInThisPeriod")} value={`${currency} ${formatMoney(totalAmount, decimal)}`}/>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ChartFilter;

const getHighestPriorityRole = (userRoles, allRoles) => {

    const userRolesWithOrder = userRoles.map((role) => {
        return allRoles.find((item) => item.id === role);
    });

    const allOrderNums = userRolesWithOrder.map((role) => role ? role.order : null);
    const theLowerOrder = Math.min(...allOrderNums);
    const result = userRolesWithOrder.find((item) => item && item.order === theLowerOrder);
    return result ? result.id : undefined;
};

export default getHighestPriorityRole

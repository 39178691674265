import React, {useEffect, useState} from 'react';
import KnowYourCustomer from "../userSection/content/KnowYourCustomer";
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


const KYCPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef} = props;
    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        setIsMount(true);
    },[]);

    return (
        <UserSectionWrapper>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <Grid item>
                        <Typography variant="h1">{t("userVerificationTitle")}</Typography>
                    </Grid>
                </Portal>
            )}
            <CompanyCountryCheck>
                <KnowYourCustomer/>
            </CompanyCountryCheck>
        </UserSectionWrapper>

    )

}



export default KYCPage

import React from 'react';
import {makeStyles, Button, Tooltip} from "@material-ui/core";
import {ReactComponent as FilterSVG} from "../../assets/filterInvesthub.svg"
import {useTranslation} from "react-i18next"
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles((theme) => (
    {
        button:{
            padding: '0 !important',
            width: '40px',
            height: '40px',
            background: 'none',
            transition: 'all .2s',
            boxShadow: (props) => props.isDarkModeActive ? '0 0 0 2px var(--dark-primary) inset' : '0 0 0 2px var(--very-light-pink-secondary) inset',
            minWidth: 'initial',
            borderRadius: '8px',
            '& svg': {
                width: '24px',
                height: '24px',
                fill: 'var(--battelship-grey)',
                transition: 'all .2s'
            },

            "&:hover": {
                boxShadow: '0 0 0 2px var(--bright-blue) inset',
                "& svg": {
                    fill: 'white'
                }
            }
        }
    }
));


const FilterButton = (props) => {
    const {settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    return (
        <>
            <Tooltip
                title={t("formFields.filter")}
            >
                <Button className={classes.button} {...props}>
                    <FilterSVG />
                </Button>
            </Tooltip>
        </>
    );
};

export default FilterButton

import React from 'react'
import {makeStyles} from "@material-ui/core"
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../Context/Can";
import AddProjectBtn from './AddProjectBtn'

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            // flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    headTitle: {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '32px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '1.6',
        letterSpacing: '-.02em',
        [theme.breakpoints.down('767')]: {
            fontSize: '18px'
        }
    },
    headControl: {
        display: 'flex',
        marginLeft: 'auto',
    },
}));

const TopComponentInvesthub = (props) => {
    const {onImport, history, settingsDarkMode} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.head}>
            <div className={`${classes.headTitle} card-title card-title--blue`}>
                {t("project.mainTitle")}
            </div>

            <div className={classes.headControl}>
                <Can I="create" a={"project"}>
                    <AddProjectBtn
                        history={history}
                        onImport={onImport}
                        settingsDarkMode={settingsDarkMode}
                    />
                </Can>
            </div>
        </div>
    )
}

export default TopComponentInvesthub

import React, {useEffect, useState} from 'react';
import Api from "../../../../../services/api";
import {withRouter} from "react-router-dom";
import DashboardPane from "../../../../DashboardPane";
import FormatMoney from "../../../../FormatMoney/FormatMoney";
import {useTranslation} from "react-i18next";
import CircularProgress from '@material-ui/core/CircularProgress'
// import * as animationData from "../../../../../assets/animations/list";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {useSelector} from "react-redux";
import formatMoney from "../../../../../helpers/formatMoney";
import {Button} from "@material-ui/core";
import DashboardPaneTopInfo from "../../../../DashboardPane/DashboardPaneTopInfo";
const service = new Api();

const AccountsInfo = (props) => {

    const {history, dashboardTableMinHeight} = props;

    const [isLoading, setIsLoading] = useState(true);

    const [accounts, setAccounts] = useState([]);
    const [info, setInfo] = useState({});

    const decimal = useSelector(state => {
        const {settings={}} = state.user;
        const {regional={}} = settings;
        return regional.decimal
    });

    const {t} = useTranslation();
    useEffect(() => {

        (async() => {
            try {
                const response = await service.getAccounts();
                const {data={}} = response;
                const {result, info} = data;
                setInfo(info);


                setIsLoading(false);
                setAccounts(result);
            }catch (e) {
                setAccounts([]);
                setIsLoading(false);
            }
        })();

    }, []);

    const onAccountClick = (id) => {
        history.push(`/accounts/${id}/details`)
    }
    const columns = [
        {
            title: t('thead.currency'),
            field: 'curCode',
        },
        {
            title: t('thead.avlBal'),
            field: 'avlBal',
            align:'right',
            render: ({avlBal}) => <FormatMoney>{avlBal}</FormatMoney>
        },
        {
            align:'right',
            render: (row) =>  (
                <Button
                    size={"small"}
                    color="primary"
                    style={{paddingLeft:0, paddingRight:0, minWidth:'auto', fontWeight: 400, fontSize: 15}}
                    onClick={() => onAccountClick(row._id)}
                >
                    {t("account.topUp")}
                </Button>
            )
        },
    ];
    const {totalBalance={}} = info;
    const {sumInCurrency, toCurrency} = totalBalance;


    const totalAmount = `${toCurrency ? toCurrency : ""} ${sumInCurrency !== undefined ? formatMoney(sumInCurrency, decimal) : "" }`

    return (
        <DashboardPane
            title={t("dashboard.accountsTitle")}
            action={<DashboardPaneTopInfo label={t('account.totalAmount')} value={totalAmount} />}
        >
            <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={accounts}
                options={{
                    header: false,
                    minBodyHeight: dashboardTableMinHeight,
                }}
                EmptyComponent={() => <CircularProgress />}
            />
        </DashboardPane>

    );
};

export default withRouter(AccountsInfo);

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import useMounted from "../../../../../hooks/useMounted";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Modal,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
// import VolveSnackbar from "../../../../VolveSnackbar";
import useForm2 from "../../../../../hooks/useForm2";
import InputFieldMemo from "../../../../formElements/InputFieldMemo";
import { useCallback } from "react";
import Api from "../../../../../services/api";
// import useExpenseValidation from "../../../../../hooks/useExpenseValidation";
import { MESSAGES } from "../../../../../constants/transactions";
import { setMessage } from "../../../../Messages/actions";
import { useDispatch } from "react-redux";
import SelectField from "../../../../formElements/SelectField";
import {ReactComponent as CloseSVG} from "../../../../../assets/closeInvesthub.svg"
import { isDarkModeActive } from "../../../../App/App";
import CategoryField from "../../../../ExpenseForm/CategoryField";

const service = new Api();
const useStyles = makeStyles(theme => ({
    modalInner: {
        overflow:'scroll',
        height:'100%',
        width: '100%',
        display:'block',
    },
    itemRadio: {
        '& > .MuiFormLabel-root': {
            display: 'block',
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px'
        },
        "& .MuiFormGroup-root": {
            flexFlow: 'row wrap'
        }
    },
    formControlLabel:{
        "& .MuiFormControlLabel-label":{
            fontSize:"15px",
            marginTop:"2px",
            paddingLeft:"2px"
        },
    },
    itemForm: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
    itemFormSubmit: {
        marginTop: '48px',
        [theme.breakpoints.down('1023')]: {
            marginTop: '32px'
        }
    },
}))

const ContactPopup = (props) => {
    const {
        isOpenCreateModal,
        setIsOpenCreateModal,
        countries,
        shouldRefresh,
        setShouldRefresh,
        settingsDarkMode,
    } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const { t } = useTranslation();
    const handleClose = () => {
        setIsOpenCreateModal(false);
    };
    const [msg, setMsg] = useState("");
    const dispatch = useDispatch();
    const validate = (values) => {
        let errors = {};
        if (!values.contactType) {
            errors.contactType = t("errors.required", {
                field: t("formFields.contactType"),
            });
        }
        if (!values.walletAddress && !values.iban) {
            errors.walletAddress = t("errors.notValid", {
                field: t("formFields.walletAddress"),
            });
            errors.iban = t("errors.notValid", {
                field: t("formFields.iban"),
            });
        }
        if (!values.category) {
            errors.category = t("errors.required", {
                field: t("formFields.category"),
            });
        }

        return errors;
    };
    const submit = async () => {
        try {
            const response = await service.createContact(values);

            if (response) {
                setMsg(MESSAGES.CREATED_SUCCESS);
                dispatch(setMessage(t("success"), "success"));
                setIsOpenCreateModal(false);
                if (!shouldRefresh) {
                    setShouldRefresh(true);
                }
            }
            fillFormValues({}); //reset form

        } catch (err) {
            setMsg(MESSAGES.CONTACT_EXIST);
        }
    };

    const { handleChange, handleSubmit, values, errors, fillFormValues, setServerErrors } =
        useForm2(submit, validate);
    const memoizedCallback = useCallback(handleChange, []);

    return (
        <>
            <Modal
                open={isOpenCreateModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="big-modal"
            >
                <Box className={`${classes.modalInner} big-modal__inner`}>
                    <Box className={"big-modal__set"}>
                        <Box className={"big-modal__control"}>
                            <Box
                                type="button"
                                onClick={handleClose}
                                className="big-modal__close"
                            >
                                <CloseSVG />
                            </Box>
                        </Box>

                        <Box className={`big-modal__wrapper`}>
                            <Box className={`big-modal__wrapper-inner`}>
                                <Typography
                                    component="h2"
                                    className={`h4 big-modal__title`}
                                >
                                    Adding Your Contact
                                </Typography>

                                <Box className={`big-modal__content`}>
                                    <form
                                        onSubmit={handleSubmit}
                                        encType="multipart/form-data"
                                        noValidate
                                        className={classes.itemForm}
                                    >
                                        <Box
                                            className={classes.itemFormFieldSet}
                                        >
                                            <Box
                                                className={classes.itemSettings}
                                            >
                                                <FormControl
                                                    fullWidth
                                                    error={
                                                        !!errors["contactType"]
                                                    }
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <Box
                                                        className={
                                                            classes.itemRadio
                                                        }
                                                    >
                                                        <FormLabel>
                                                            Account type
                                                        </FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby="controlled-radio-buttons-group"
                                                            name="contactType"
                                                            values={
                                                                values[
                                                                    "contactType"
                                                                ]
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    "contactType",
                                                                    e.target
                                                                        .value
                                                                );
                                                                handleChange(
                                                                    "companyName",
                                                                    null
                                                                );
                                                                handleChange(
                                                                    "firstName",
                                                                    null
                                                                );
                                                                handleChange(
                                                                    "lastName",
                                                                    null
                                                                );
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                value="individual"
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="Individual"
                                                                className={
                                                                    classes.formControlLabel
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value="company"
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="Company"
                                                                className={
                                                                    classes.formControlLabel
                                                                }
                                                            />
                                                        </RadioGroup>
                                                    </Box>
                                                </FormControl>

                                                {values["contactType"] ===
                                                    "individual" && (
                                                    <Box
                                                        className={
                                                            classes.itemRow
                                                        }
                                                    >
                                                        <FormControl
                                                            fullWidth
                                                            error={
                                                                !!errors[
                                                                    "firstName"
                                                                ]
                                                            }
                                                            className={
                                                                classes.itemField
                                                            }
                                                        >
                                                            <InputFieldMemo
                                                                name={
                                                                    "firstName"
                                                                }
                                                                label={t(
                                                                    "formFields.firstName"
                                                                )}
                                                                value={
                                                                    values[
                                                                        "firstName"
                                                                    ]
                                                                }
                                                                error={
                                                                    errors[
                                                                        "firstName"
                                                                    ]
                                                                }
                                                                onInputChange={
                                                                    memoizedCallback
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormControl
                                                            fullWidth
                                                            error={
                                                                !!errors[
                                                                    "lastName"
                                                                ]
                                                            }
                                                            className={
                                                                classes.itemField
                                                            }
                                                        >
                                                            <InputFieldMemo
                                                                name={
                                                                    "lastName"
                                                                }
                                                                label={t(
                                                                    "formFields.lastName"
                                                                )}
                                                                value={
                                                                    values[
                                                                        "lastName"
                                                                    ]
                                                                }
                                                                error={
                                                                    errors[
                                                                        "lastName"
                                                                    ]
                                                                }
                                                                onInputChange={
                                                                    memoizedCallback
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                )}

                                                <FormControl
                                                    fullWidth
                                                    error={!!errors["addrl1"]}
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <InputFieldMemo
                                                        name={"addrl1"}
                                                        label={t(
                                                            "formFields.addrl1"
                                                        )}
                                                        value={values["addrl1"]}
                                                        error={errors["addrl1"]}
                                                        onInputChange={
                                                            memoizedCallback
                                                        }
                                                    />
                                                </FormControl>

                                                <FormControl
                                                    fullWidth
                                                    error={!!errors["addrl2"]}
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <InputFieldMemo
                                                        name={"addrl2"}
                                                        label={t(
                                                            "formFields.addrl2"
                                                        )}
                                                        value={values["addrl2"]}
                                                        error={errors["addrl2"]}
                                                        onInputChange={
                                                            memoizedCallback
                                                        }
                                                    />
                                                </FormControl>

                                                <Box
                                                    className={classes.itemRow}
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        error={!!errors["city"]}
                                                        className={
                                                            classes.itemField
                                                        }
                                                    >
                                                        <InputFieldMemo
                                                            name={"city"}
                                                            label={t(
                                                                "formFields.city"
                                                            )}
                                                            value={
                                                                values["city"]
                                                            }
                                                            error={
                                                                errors["city"]
                                                            }
                                                            onInputChange={
                                                                memoizedCallback
                                                            }
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={
                                                            !!errors[
                                                                "postalCode"
                                                            ]
                                                        }
                                                        className={
                                                            classes.itemField
                                                        }
                                                    >
                                                        <InputFieldMemo
                                                            name={"postalCode"}
                                                            label={t(
                                                                "formFields.postalCode"
                                                            )}
                                                            value={
                                                                values[
                                                                    "postalCode"
                                                                ]
                                                            }
                                                            error={
                                                                errors[
                                                                    "postalCode"
                                                                ]
                                                            }
                                                            onInputChange={
                                                                memoizedCallback
                                                            }
                                                        />
                                                    </FormControl>
                                                </Box>

                                                <FormControl
                                                    fullWidth
                                                    error={!!errors["country"]}
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <SelectField
                                                        label={t(
                                                            "formFields.country"
                                                        )}
                                                        placeholder={t(
                                                            "formFields.country"
                                                        )}
                                                        name={"country"}
                                                        value={
                                                            values["country"]
                                                        }
                                                        error={
                                                            errors["country"]
                                                        }
                                                        options={countries}
                                                        onSelectChange={(val) =>
                                                            handleChange(
                                                                "country",
                                                                val
                                                            )
                                                        }
                                                    />
                                                </FormControl>

                                                <FormControl
                                                    fullWidth
                                                    error={!!errors["category"]}
                                                    className={
                                                        classes.itemField
                                                    }
                                                >
                                                    <CategoryField
                                                        value={
                                                            values["category"]
                                                        }
                                                        error={
                                                            errors["category"]
                                                        }
                                                        // disabled={checkDisabledField(
                                                        //     "category"
                                                        // )}
                                                        onSelectChange={(val) =>
                                                            handleChange("category", val)
                                                        }
                                                        // isLoading={isLoading}
                                                    />
                                                </FormControl>

                                                {values["contactType"] ===
                                                    "company" && (
                                                    <>
                                                        <FormControl
                                                            fullWidth
                                                            error={
                                                                !!errors[
                                                                    "companyName"
                                                                ]
                                                            }
                                                            className={
                                                                classes.itemField
                                                            }
                                                        >
                                                            <InputFieldMemo
                                                                name={
                                                                    "companyName"
                                                                }
                                                                label={t(
                                                                    "formFields.companyName"
                                                                )}
                                                                value={
                                                                    values[
                                                                        "companyName"
                                                                    ]
                                                                }
                                                                error={
                                                                    errors[
                                                                        "companyName"
                                                                    ]
                                                                }
                                                                onInputChange={
                                                                    memoizedCallback
                                                                }
                                                            />
                                                        </FormControl>
                                                    </>
                                                )}

                                                <Box
                                                    className={classes.itemRow}
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        error={
                                                            !!errors[
                                                                "walletAddress"
                                                            ]
                                                        }
                                                        className={
                                                            classes.itemField
                                                        }
                                                    >
                                                        <InputFieldMemo
                                                            name={
                                                                "walletAddress"
                                                            }
                                                            label={t(
                                                                "formFields.walletAddress"
                                                            )}
                                                            // placeholder={t("formFields.vendor")}
                                                            value={
                                                                values[
                                                                    "walletAddress"
                                                                ]
                                                            }
                                                            error={
                                                                errors[
                                                                    "walletAddress"
                                                                ]
                                                            }
                                                            onInputChange={
                                                                memoizedCallback
                                                            }
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={!!errors["iban"]}
                                                        className={
                                                            classes.itemFIeld
                                                        }
                                                    >
                                                        <InputFieldMemo
                                                            name={"iban"}
                                                            label={t(
                                                                "formFields.iban"
                                                            )}
                                                            // placeholder={t("formFields.vendor")}
                                                            value={
                                                                values["iban"]
                                                            }
                                                            error={
                                                                errors["iban"]
                                                            }
                                                            onInputChange={
                                                                memoizedCallback
                                                            }
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Button
                                            type="submit"
                                            className={classes.itemFormSubmit}
                                        >
                                            {/* NEED TRANSLATION */}
                                            {t("Create")}
                                        </Button>
                                        <div
                                            style={{
                                                marginTop: "2rem",
                                                color:
                                                    msg ===
                                                    MESSAGES.CREATED_SUCCESS
                                                        ? "green"
                                                        : "red",
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default withRouter(ContactPopup);

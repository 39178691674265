import React, {useEffect, useState} from 'react';
import FilterFormControl from "../../FilterFormControl";
import SelectField from "../../../../formElements/SelectField";
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../Messages/actions";
import Api from "../../../../../services/api";
const service = new Api();


const RoleSelectField = (props) => {
    const {...restProps} = props;

    const dispatch = useDispatch();

    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchRoles = async () => {
        try{
            setIsLoading(true);
            const rolesResponse = await service.getServiceData({dataFor:"roles"});
            const {roles} = rolesResponse.data;
            const mappedRoles = roles.map((role) => ({label:role.name, value:role.id}));
            setRoles(mappedRoles);

        }catch (e) {
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchRoles();
    },[]);

    return (
        <FilterFormControl>
            <SelectField
                {...restProps}
                options={roles}
                isLoading={isLoading}
            />
        </FilterFormControl>
    );
};

export default RoleSelectField;

import React from 'react';
import Box from "@material-ui/core/Box";

const CardOptionWrapper = ({children}) => {
    return (
        <Box pr={"30px"}>
            {children}
        </Box>
    );
};

export default CardOptionWrapper;

import React, {useEffect, useState} from 'react';
import useMounted from "../../../../../hooks/useMounted";
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import useForm2 from "../../../../../hooks/useForm2";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useDispatch} from "react-redux";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import DepartmentForm from "../../../../DepartmentForm";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            marginBottom: '24px'
        },
    },
    item: {
        position: 'relative'
    },
    button: {
        marginTop: '24px'
    },
    buttonLast: {
        marginLeft: '16px',
    },
}))

const Department = (props) => {
    const classes = useStyle();
    const {underBarRef, match, history} = props;
    const {params} = match;
    const {id} = params;

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();

    const [department, setDepartment] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const fetchDepartment = async (id) => {
        try {
            setIsLoading(true);
            const response = await service.getDepartment(id);
            setDepartment(response.data);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchDepartment(id);
    },[id]);


    useEffect(() => {
        if(Object.keys(department).length){
            const {name, accountingCode} = department;
            fillFormValues({name, accountingCode})
        }
    },[department]);



    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.updateDepartment(id, values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/departments");
        }catch (e) {
            console.log(e);
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const onDeleteAction = async () => {
        setIsSubmitting(true);
        try {
            await service.deleteDepartment(id);
            dispatch(setMessage("success", 'success'));
            history.push("/settings/departments");

        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const accountingCodeRegex = getAlphaNumericRegex();
        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }

        if (values["accountingCode"] && !accountingCodeRegex.test(values["accountingCode"].toString())) {
            errors.accountingCode = t("errors.notValid", {field:"$t(formFields.accountingCode)"});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <DepartmentForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        loading={loading}
                    />

                    <Can I="update" a={"department"}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting || loading}
                            className={classes.button}
                        >
                            {t("formFields.update")}
                        </Button>
                    </Can>

                    <Can I="delete" a={"department"}>
                        <Button
                            onClick={onDeleteAction}
                            disabled={isSubmitting || loading}
                            className={`btn-stroke ${classes.button} ${classes.buttonLast}`}
                        >
                            {t("formFields.delete")}
                        </Button>
                    </Can>
                </div>
            </div>
        </>
    );
};

export default Department;

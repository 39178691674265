import React, {
    // eslint-disable-next-line no-unused-vars
    useEffect,
    useState,
} from "react";
import {
    FormControl,
    // eslint-disable-next-line no-unused-vars
    Grid,
    makeStyles,
    // eslint-disable-next-line no-unused-vars
    useTheme,
    // eslint-disable-next-line no-unused-vars
    DialogContent,
} from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { compose } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { subject } from "@casl/ability";
import SelectField from "../../../../../../formElements/SelectField";
import useForm2 from "../../../../../../../hooks/useForm2";
import Api from "../../../../../../../services/api";
import DialogPopUp from "../../../../../../DialogPopUp";
import InputField from "../../../../../../formElements/InputField";
import {
    setMessage,
    handleErrorMessage,
} from "../../../../../../Messages/actions";
import { CHAINS } from "../../../../../../../constants/transactions";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    item: {
        position: "relative",
        maxWidth: "480px",
    },
    button: {
        marginTop: "24px",
    },

    formInner: {
        display: "flex",
        flexFlow: "row wrap",
        margin: "0 -8px",
        padding: "0",

        [theme.breakpoints.down("767")]: {
            display: "block",
            margin: "0",
        },
    },

    itemFieldFull: {
        flex: "0 0 calc(100% - 8px)",
        width: "calc(100% - 8px)",
        margin: "0 8px 16px",
        [theme.breakpoints.down("767")]: {
            width: "100%",
            margin: "0 0 8px",
        },
        "& .MuiInputBase-root": {
            marginTop: "0",
        },
        "& > .MuiFormControlLabel-root": {
            marginLeft: "0",
            marginRight: "0",
        },
    },
}));

const DialogImportWallet = (props) => {
    const classes = useStyles();
    const {
        setMessage,
        setOpen,
        loading,
        title,
        // eslint-disable-next-line no-unused-vars
        report,
        getFiatRepublicListAccount,
        type,
        isDisableFiat,
        settingsDarkMode,
    } = props;

    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValue = {
        currency: "",
        label: "",
        businessId: "",
    };

    const validate = (values) => {
        const errors = {};
        return errors;
    };

    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        setServerErrors,
        // eslint-disable-next-line no-unused-vars
        fillFormValues,
    } = useForm2(submit, validate);

    async function submit() {
        setIsSubmitting(true);
        try {
            // eslint-disable-next-line no-unused-vars
            const importFunc = type === 'user' ? service.importUserWallet(values) : service.importCompanyWallet(values);
            const response = await importFunc;
            // getFiatRepublicListAccount();
            setMessage("success", "success");
            setOpen(false);
        } catch (e) {
            setServerErrors(e);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <DialogPopUp
            open={true}
            disableBackdropClick
            onClose={() => setOpen(false)}
            aria-labelledby="report-dialog-title"
            aria-describedby="report-dialog-description"
            title={title}
            pageTitleClass={"card-title--red"}
            settingsDarkMode={settingsDarkMode}
        >
            <div className={classes.item}>
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.form}
                    defaultValue={initialValue}
                >
                    <div className={classes.formInner}>
                        <FormControl
                            fullWidth
                            error={!!errors.name}
                            className={classes.itemFieldFull}
                        >
                            <InputField
                                label={t("formFields.walletName")}
                                name="label"
                                isLoading={loading}
                                value={values["walletName"]}
                                onInputChange={(val) =>
                                    handleChange("walletName", val)
                                }
                                error={errors["walletName"]}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={!!errors.name}
                            className={classes.itemFieldFull}
                        >
                            <InputField
                                label={t("formFields.walletAddress")}
                                name="walletAddress"
                                isLoading={loading}
                                value={values["walletAddress"]}
                                onInputChange={(val) =>
                                    handleChange("walletAddress", val)
                                }
                                error={errors["walletAddress"]}
                            />
                        </FormControl>
                    </div>
                </form>

                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting || loading}
                    className={classes.button}
                >
                    {t("formFields.import")}
                </Button>
            </div>
        </DialogPopUp>
    );
};

export default compose(connect(null, { setMessage, handleErrorMessage }))(
    DialogImportWallet
);

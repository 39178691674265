import React from 'react';
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    text: {
        fontSize:"15px",
        color: "#8A96A0",
        marginBottom:"30px"
    },
}));

const CardHelpText = ({text}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.text}>
            {text}
        </Box>
    );
};

export default CardHelpText;

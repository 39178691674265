import React from 'react';
import {FormControl, FormHelperText, Grid, Select, MenuItem} from "@material-ui/core";
import {Can} from "../../Context/Can";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import SortableMultiSelect from "../formElements/SortableMultiSelect";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import {makeStyles} from "@material-ui/core";
import { isDarkModeActive } from '../App/App';

const useStyles = makeStyles((theme) => ({
    itemForm: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFormFieldSet: {
        position: 'relative',
        width: '100%',
        marginBottom: '48px',
        paddingBottom: '48px',
        borderBottom: (props) => props.isDarkModeActive ? '1px solid var(--dark-primary)' : '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '32px',
            paddingBottom: '32px'
        }
    },
    itemFormFieldSetLast: {
        marginBottom: '0 !important',
        paddingBottom: '0 !important',
        border: 'none !important'
    },
    itemSettings: {
        "& $itemField": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            }
        },
        "& $itemRow": {
            "&:not(:last-child)": {
                marginBottom: '32px',
                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                }
            },

            "& $itemField": {
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                margin: '0 8px',
                [theme.breakpoints.down('1023')]: {
                    width: '100%',
                    margin: '0'
                }
            }
        }
    },

    itemField: {},

    itemRow: {
        display: 'flex',
        margin: '0 -8px',
        [theme.breakpoints.down('1023')]: {
            display: 'block',
            margin: '0'
        },
        "& $itemField": {
            [theme.breakpoints.up('1023')]: {
                marginBottom: '0 !important'
            }
        }
    },
}))

const ExpenseReportTemplateForm = (props) => {
    const {handleSubmit, handleChange, values, errors, loading, settingsDarkMode} = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    return (
        <form onSubmit={handleSubmit} noValidate className={classes.itemForm}>
            <Can I="update" a={"expenseReportTemplate"} passThrough>
                {(can) => (
                    <div className={`${classes.itemFormFieldSet} ${classes.itemFormFieldSetLast}`}>
                        <div className={classes.itemSettings}>
                            <FormControl fullWidth error={!!errors["name"]} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.name")}
                                    placeholder={t("formFields.name")}
                                    name="name"
                                    disabled={!can}
                                    value={values["name"]}
                                    isLoading={loading}
                                    onInputChange={(val) => handleChange("name", val)}
                                    error={errors["name"]}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors["fields"]} className={`${classes.itemField}`}>
                                <ErrorBoundary>
                                    <SortableMultiSelect
                                        value={values["fields"]}
                                        handleChange={handleChange}
                                        error={errors["fields"]}
                                        label={t("formFields.fields")}
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </ErrorBoundary>
                            </FormControl>
                        </div>
                    </div>
                )}
            </Can>
        </form>
    );
};

export default ExpenseReportTemplateForm;

import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../../../../../../Messages/actions";
import Api from "../../../../../../../../services/api";
import {FormControl} from "@material-ui/core";
import SelectField from "../../../../../../../formElements/SelectField";
import {useTranslation} from "react-i18next";
const service = new Api();


const BranchField = (props) => {
    const {handleChange, value, error, isDataFetching, setIsDataFetching} = props;
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const [branches, setBranches] = useState([]);

    const fetchBranches = async () => {
        try {
            setIsDataFetching(true);
            const resp = await service.getBranchesAll();
            const mappedBranches = resp.data.branches.map(({_id, name}) => ({value: _id, label: name}));
            setBranches(mappedBranches);
        } catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsDataFetching(false);
        }
    }

    useEffect(() => {
        fetchBranches();
        return () => {
            // remove value if component unmount
            handleChange("branch")
        }
    },[]);


    return (
        <div>
            <FormControl fullWidth error={!!error}>
                <SelectField
                    isLoading={isDataFetching}
                    label={t("formFields.branch")}
                    placeholder={t("formFields.branch")}
                    name={"branch"}
                    value={value}
                    error={error}
                    options={branches}
                    onSelectChange={(val) => handleChange("branch", val)}
                />
            </FormControl>
        </div>
    );
};

export default BranchField;

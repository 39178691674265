import React, {useEffect, useState} from 'react';
import DashboardPane from "../../../../DashboardPane";
import {useTranslation} from "react-i18next";
// import Typography from "@material-ui/core/Typography";
import Api from "../../../../../services/api";
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage} from "../../../../Messages/actions";
// import * as animationData from "../../../../../assets/animations/list";
import FormatMoney from "../../../../FormatMoney/FormatMoney";
import FormatDate from "../../../../FormatDate";
import {
    makeStyles,
    CircularProgress,
    Box
} from "@material-ui/core";
import getTimeAgo from "../../../../../helpers/getTimeAgo";
// import Pagination from "../../../../Pagination";
// eslint-disable-next-line no-unused-vars
import VolveMaterialTable from "../../../../VolveMaterialTable";
import useActiveTab from "../../../../../hooks/useActiveTab";
import checkClick from "../../../../../helpers/checkClick";
import {withRouter} from "react-router-dom";
import formatMoney from "../../../../../helpers/formatMoney";
import DashboardPaneTopInfo from "../../../../DashboardPane/DashboardPaneTopInfo";
import { isDarkModeActive } from '../../../../App/App';

const service = new Api();

const useStyles = makeStyles(theme => ({
    secondLine: {
        fontSize: 12,
        lineHeight:1
    },
    merchant:{
        fontWeight: '15px'
    },

    expenseCard: {
        display: 'flex',
        flexFow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : 'var(--almost-black)',

        "&:not(:last-child)": {
            marginBottom: '20px'
        }
    },
    expenseCardLeft: {
        flex: '1',
    },
    expenseCardLeftTimeline: {
        display: 'inline-flex',
        gap: '4px',
        fontSize: '14px'
    },
    expenseCardDate: {
        color: (props) => props.isDarkModeActive ? 'var(--battelship-grey)' : 'var(--battelship-grey)'
    },


    yearSubmission: {
        color: "#FF6A55"
    },
    monthSubmission: {
        color: "#FF6A55"
    },
    weekSubmission: {
        color: '#FFD88D',
    },
    daySubmission: {
        color: '#B5E4CA'
    },
    hourSubmission: {
        color: '#B5E4CA'
    },
    minuteSubmission: {
        color: '#B5E4CA'
    }

}));


const WaitingForApproval = (props) => {
    const {t} = useTranslation();
    const {
        handleErrorMessage,
        dateFormat,
        // eslint-disable-next-line no-unused-vars
        dashboardTableMinHeight,
        history,
        decimal,
        settingsDarkMode
    } = props;
    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });

    const [isLoading, setIsLoading] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [info, setInfo] = useState({});

    const activeTab = useActiveTab('dashboard');


    const fetchWaitingForApprovalExpenses = async (queryData) =>  {
        try {
            setIsLoading(true);
            const response = await service.getDashboardWaitingForApproval(queryData);

            const {result, info} = response.data;
            setExpenses(result);
            setInfo(info);
        } catch (e) {
            console.log(e);
            handleErrorMessage(e);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if(activeTab){
            fetchWaitingForApprovalExpenses({fromTab: activeTab})
        }
    },[activeTab]);

    // eslint-disable-next-line no-unused-vars
    const handleChangePage = async (event, newPage) => {
        await fetchWaitingForApprovalExpenses({page:newPage, fromTab: activeTab});
    }

    const getSubmissionClass = (updatedAt) => {
        const timeAgo = getTimeAgo(updatedAt);

        switch (true) {
            case timeAgo.includes("year"):
                return classes.yearSubmission;
            case timeAgo.includes("month"):
                return classes.monthSubmission;
            case timeAgo.includes("week"):
                return classes.weekSubmission;
            case timeAgo.includes("day"):
                return classes.daySubmission;
            case timeAgo.includes("hour"):
                return classes.hourSubmission;
            case timeAgo.includes("minute"):
                return classes.minuteSubmission;
            default:
                return classes.defaultSubmission;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const columns = [
        {
            title: t('thead.expense'),
            render: (row) => {
                const {merchant, updatedAt} = row;
                return (
                    <>
                        <Box className={classes.merchant}>{merchant}</Box>
                        <Box className={`${classes.secondLine}`}>
                            {t("expense.submitted")} {getTimeAgo(new Date(updatedAt))}
                        </Box>
                    </>
                )
            }
        },
        {
            title: t('thead.date'),
            field: 'date',
            render: ({date}) => <FormatDate dateFormat={dateFormat}>{date}</FormatDate>
        },
        {
            title: t('thead.amount'),
            align:'right',
            render: (row) => {
                const {reportedCurrency, calculatedAmount, currency, amount} = row;
                return (
                    <>
                        <Box>{reportedCurrency} <FormatMoney>{calculatedAmount}</FormatMoney></Box>
                        {(reportedCurrency !== currency) && (
                            <Box className={classes.secondLine}>
                                {currency} <FormatMoney>{amount}</FormatMoney>
                            </Box>
                        )}
                    </>
                )
            }
        },
    ]
    const rowHandlerClick = (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push({ pathname:`/transactions/${rowData._id}`});
        }
    }

    const value = `${info.currency ? info.currency : ""} ${info.totalAmount !== undefined ? formatMoney(info.totalAmount, decimal) : ""} `;
    return (
        <DashboardPane
            title={t("expense.waitingForApproval")}
            action={<DashboardPaneTopInfo value={value} />}
            settingsDarkMode={settingsDarkMode}
            headingColorClasses={"card-title--yellow"}
        >
            {isLoading ? (
                <>
                    <CircularProgress />
                </>
            ) : (
                <>
                    {expenses.map((expense, index) => {
                        let description = expense?.merchant || expense?.comment;
                        if (
                            !expense?.merchant?.length &&
                            !expense?.comment?.length
                        ) {
                            description =
                                expense?.vendor?.contactType === "individual"
                                    ? expense?.vendor?.firstName +
                                      " " +
                                      expense?.vendor?.lastName
                                    : expense?.vendor?.companyName;
                        }

                        return (
                            <div
                                key={index}
                                onClick={(e) => rowHandlerClick(e, expense)}
                                className={classes.expenseCard}
                            >
                                <div className={classes.expenseCardLeft}>
                                    <div className={classes.merchant}>
                                        {description}
                                    </div>

                                    <div
                                        className={`${classes.expenseCardLeftTimeline}`}
                                    >
                                        <div
                                            className={`${getSubmissionClass(
                                                new Date(expense.updatedAt)
                                            )}`}
                                        >
                                            {t("expense.submitted")}{" "}
                                            {getTimeAgo(
                                                new Date(expense.updatedAt)
                                            )}
                                        </div>
                                        <div
                                            className={classes.expenseCardDate}
                                        >
                                            <FormatDate dateFormat={dateFormat}>
                                                {expense.date}
                                            </FormatDate>
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.expenseCardRight}>
                                    {expense.reportedCurrency}{" "}
                                    {expense.calculatedAmount}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}

            {/* <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={expenses}
                options={{
                    header: false,
                    minBodyHeight: dashboardTableMinHeight,
                }}
                onRowClick={rowHandlerClick}
                EmptyComponent={() => <CircularProgress />}
            /> */}
            {/* <Pagination
                total={info.total}
                pageSize={info.pageSize}
                page={info.page}
                isLoading={isLoading}
                onChangePage={handleChangePage}
            /> */}
        </DashboardPane>
    );
};


const mapStateToProps = ({user}) => {
    return {
        dateFormat:user.settings.regional.dateFormat,
        decimal:user.settings.regional.decimal
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {handleErrorMessage})
)(WaitingForApproval);

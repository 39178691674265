import React, {useEffect, useState} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {FormControl, Grid, makeStyles, useTheme} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Api from "../../../../../services/api";
import {setMessage, handleErrorMessage} from "../../../../Messages/actions";
import {compose} from "redux";
import {connect, useSelector} from "react-redux";
import InputField from "../../../../formElements/InputField";
import DialogPopUp from "../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import useForm2 from "../../../../../hooks/useForm2";
import {Can} from "../../../../../Context/Can";
import {subject} from "@casl/ability";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { TRANSACTION_STATUS } from "../../../../../constants/transactions";
import SelectField from "../../../../formElements/SelectField";
import { COINS, CURRENCIES_ALIAS, E_CURRENCIES_ALIAS, TYPICAL_CURRENCIES } from "../../../../../constants/currencies-ecurrencies";
const service = new Api();

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        maxWidth: '480px'
    },
    button: {
        marginTop: '24px'
    },

    formInner: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },

    itemFieldFull: {
        flex: '0 0 calc(100% - 8px)',
        width: 'calc(100% - 8px)',
        margin: '0 8px 16px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 8px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    }
}));



const DialogSendWallet = (props) => {
    const classes = useStyles();
    const {setMessage, setOpen, loading, title, companyCurrencies, getTanganyWalletBalances, setCompanyCurrencies} = props;
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [networkOptions, setNetworkOptions] = useState([])
    const history = useHistory()
    const { expenseId, wallet, amount, currency } = queryString.parse(history.location.search)
    const companyCurrency = useSelector(state => {
        const {user} = state;
        if(user && user.userData && user.userData.company && user.userData.company.currency){
            return user.userData.company.currency
        }
        return null
    });
    const [sendingCurrency, setSendingCurrency] = useState(TYPICAL_CURRENCIES.includes(companyCurrency) ? companyCurrency : 'EUR')
    const [sourceBalance, setSourceBalance] = useState(0);

    useEffect(()=> {
        setSourceBalance(companyCurrencies?.find((e)=> e?.label === CURRENCIES_ALIAS[sendingCurrency] )?.value?.balance || 0)
    }, [sendingCurrency, companyCurrencies])

    const initialValue = {
        receivingWallet: wallet,
        amount: 0,
      }

    const validate = (values) => {
        const errors = {};
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    async function submit(){
        setIsSubmitting(true);
        try {
            if (currency && sendingCurrency && !COINS?.includes(sendingCurrency)) {
                // In case transfer token
                const walletTransfer = await service.tanganyTransferToken({
                    ...values,
                    currency: CURRENCIES_ALIAS[sendingCurrency],
                    expenseId: expenseId,
                });
            } else if (COINS?.includes(sendingCurrency)) {
                // In case transfer coin (such as: POL, ETH, BTC)
                const walletTransfer = await service.sendTanganyWallet({
                    ...values,
                    expenseId: expenseId,
                });
            }

            setTimeout(function () {
                getTanganyWalletBalances();
                setMessage("success", 'success');
                setOpen(false);
                history.push('/transactions')
            }, 5000);
        } catch (e) {
            if (e?.response?.data?.msg) {
                setMessage(t(e?.response?.data?.msg), "error");
            } else {
                setServerErrors(e);
            }
        } finally {
            setIsSubmitting(false);
        }
    }
    const getExchangeRate = async (aliasCurrency) => {
        const { data: exchangeData } = await service.getExchangeRate(
            currency,
            aliasCurrency,
            amount
        );
        return exchangeData || { result: 0 };
    }

    const handleChangeCurrency = async (val) => {
        const aliasCurrency = E_CURRENCIES_ALIAS[val] || "EURe";
        setSendingCurrency(aliasCurrency);
        setSourceBalance(companyCurrencies?.find((e)=> e?.label ===val )?.value?.balance || 0)
    }

    useEffect(() => {
        const getAppData = async () => {
            let exchangeData;
            // Flow: current currency -> USD -> POL
            if (sendingCurrency && COINS.includes(sendingCurrency)) {
                const { data: coinPriceResponse } = await service.getCoinPrice(
                    sendingCurrency
                );
                const coinPrice =
                    coinPriceResponse?.coinPrice?.data[sendingCurrency][0]
                        ?.quote?.USD?.price; // POL to USD rate
                if (coinPrice) {
                    // get exchangeData
                    let { data: exchangeDataInUSD } =
                        await service.getExchangeRate("EUR", "USD", amount);
                    // got current currency in USD ;
                    exchangeData = {
                        result: exchangeDataInUSD?.result
                            ? exchangeDataInUSD?.result / coinPrice
                            : 0,
                    };
                }
            } else {
                exchangeData = await getExchangeRate(sendingCurrency);
            }

            if (exchangeData?.result) {
                handleChange("amount", exchangeData?.result?.toFixed(6));
            }
            const response = await service.getServiceData({
                dataFor: "network",
            });
            const mappedNetworks = response.data.map(({ value, name }) => ({
                label: name,
                value,
            }));
            setNetworkOptions(mappedNetworks);
        };
        getAppData();
    }, [sendingCurrency]);

    useEffect(() => {
        console.log(values["network"]);
        const getAppData = async () => {
            const { data } =
                await service.getCompanySelectedTanganyWalletBalances(
                    values["network"]
                );
            if (data?.data?.length) {
                setCompanyCurrencies(
                    data?.data?.map((e) => ({
                        label: e?.currency,
                        value: e,
                    }))
                );
            }
        };
        getAppData();
    }, [values["network"]]);

    useEffect(() => {
        if (expenseId && wallet && amount && currency) {

            fillFormValues({
            amount: amount,
            receivingWallet: wallet,
          })
        }

        // eslint-disable-next-line
      }, [expenseId, wallet, amount])

    return (
        <DialogPopUp
            open={true}
            disableBackdropClick
            onClose={() => setOpen(false)}
            aria-labelledby="report-dialog-title"
            aria-describedby="report-dialog-description"
            title={title}
        >
            <div className={classes.item}>
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.form}
                    defaultValue={initialValue}
                >
                    <div className={classes.formInner}>
                        <FormControl
                            fullWidth
                            error={!!errors["network"]}
                            className={classes.formControl}
                        >
                            <Grid item xs={12}>
                                <div className="w-full">
                                    <div className="field__label">
                                        <SelectField
                                            label={t("formFields.network")}
                                            name={"network"}
                                            value={values["network"]}
                                            error={errors["network"]}
                                            options={networkOptions}
                                            isLoading={loading}
                                            // disabled={checkDisabledField("network")}
                                            onSelectChange={(val) =>
                                                handleChange("network", val)
                                            }
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={!!errors.name}
                            className={classes.itemFieldFull}
                        >
                            <Grid item xs={12}>
                                <div className="w-full">
                                    <div className="field__label">
                                        <SelectField
                                            label={t("Sending Currency")}
                                            value={
                                                CURRENCIES_ALIAS[
                                                    sendingCurrency
                                                ]
                                            }
                                            options={companyCurrencies.map(
                                                (e) => ({
                                                    ...e,
                                                    value: e.value.currency,
                                                })
                                            )}
                                            onSelectChange={(val) => {
                                                handleChangeCurrency(val);
                                            }}
                                            isLoading={loading}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </FormControl>
                        <Grid item xs={12}>
                            <div className="w-full">
                                <div className="field__label">
                                    {sendingCurrency?.toUpperCase()}
                                    {": "}
                                    {sourceBalance}
                                </div>
                            </div>
                        </Grid>
                        <FormControl
                            fullWidth
                            error={!!errors.name}
                            className={classes.itemFieldFull}
                        >
                            <InputField
                                label={t("Receiving Wallet")}
                                name="receivingWallet"
                                isLoading={loading}
                                value={values["receivingWallet"]}
                                onInputChange={(val) =>
                                    handleChange("receivingWallet", val)
                                }
                                error={errors["receivingWallet"]}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={!!errors.currency}
                            className={classes.itemFieldFull}
                        >
                            <InputField
                                label={t("Amount")}
                                name="amount"
                                isLoading={loading}
                                value={values["amount"]}
                                onInputChange={(val) =>
                                    handleChange("amount", val)
                                }
                                error={errors["amount"]}
                            />
                        </FormControl>
                    </div>
                </form>

                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting || loading}
                    className={classes.button}
                >
                    {t("Send")}
                </Button>
            </div>

            <DialogActions className={classes.buttonsWrapper}></DialogActions>
        </DialogPopUp>
    );
};

export default compose(
    connect(null, {setMessage, handleErrorMessage})
)(DialogSendWallet);

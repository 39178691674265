import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux'
import Api from "../../services/api";
import {getLoggedUser} from "../User/actions";
import {
    makeStyles,
    Typography,
    FormControl,
    InputAdornment,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import {handleErrorMessage} from "../Messages/actions";
import useForm from "../../hooks/useForm";
import PhoneField from "../formElements/PhoneField";
import InputField from "../formElements/InputField";
import SelectField from "../formElements/SelectField";
import {useTranslation} from "react-i18next";
import TermsAndConditionsField from "../TermsAndConditionsField";
import { isDarkModeActive } from '../App/App';
import getEmailRegex from "../../helpers/getEmailRegex";
import getPasswordRegex from "../../helpers/getPasswordRegex";
import { ReactComponent as Logo} from '../../assets/iconEvolveWhite.svg'
// import { ReactComponent as LogoPaladis} from "../../assets/paladis.svg"
import { ReactComponent as MailSVG } from "../../assets/mail.svg"
import { ReactComponent as LockSVG } from "../../assets/lock.svg"
import { ReactComponent as UserSVG } from "../../assets/userCircle.svg"
import { ReactComponent as StoreSVG } from "../../assets/store.svg"
import { getExternalFileUrl } from "../../helpers/file"

const service = new Api();

const useStyles = makeStyles(theme => ({
    entry: {
        minHeight: 'calc(var(--vh, 1vh) * 100)',
        // background: 'var(--pale-grey)',
        display: 'flex',
        position: 'relative',
    },
    entryCol: {
        padding: '96px 24px',
    },
    entryColFirst: {
        flexShrink: '0',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        display: 'flex',
        backgroundColor: (props) => props.isDarkModeActive ? 'var(--black)' : 'white',
    },
    entryColSecond: {
        flexGrow: '1',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    entryWrap: {
        maxWidth: '212px'
    },
    entryPreview: {
        maxWidth: '180px',
        margin: '0 auto 40px -32px',
        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entryLogo: {
        zIndex: '2',
        display: 'inline-block',
        position: 'absolute',
        top: '34px',
        left: '24px',

        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100px',
        }
    },
    entryInfo: {
        color: (props) => props.isDarkModeActive ? 'var(--cool-grey)' : 'white',
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '1.23077',
        position: 'absolute',
        top: '40px',
        right: '40px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '80px'
        },
        [theme.breakpoints.down('767')]: {
            // top: '32px',
            right: '24px'
        },
        '& a': {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : '#1A1D1F',
            fontWeight: '600',
            transition: 'color .2s'
        },
        '& a:hover': {
            color: (props) => props.isDarkModeActive ? 'var(--pale-grey)' : '#1A1D1F',
        }
    },
    entryWrapper: {
        maxWidth: '618px'
    },
    entryWrapperTitle: {
        marginBottom: '32px',
    },
    entryForm: {
        display: 'flex',
        margin: '0 -8px',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('767')]: {
            display: 'block',
            margin: '0'
        }
    },
    entryFormField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0'
        },
        '&:not(:last-child)': {
            marginBottom: '16px',
        }
    },
    entryFormSubmit: {
        display: 'block',
        width: 'calc(100% - 16px)',
        flex: '0 0 calc(100% - 16px)',
        margin: '16px 0 8px',
        [theme.breakpoints.down('767')]: {
            margin: '16px 0 0'
        }
    }
}));

const RegisterForm = (props) => {
    const { publicSettings, settings } = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settings) });
    const {handleErrorMessage} = props;

    const [isLoading, setIsLoading] = useState(false);
    const [employeesRangeList, setEmployeesRangeList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const isWhiteLabelRegister = props.match.path === "/register-white-label";

    const {t} = useTranslation();

    const validate = (values) => {
        const passwordRegex = getPasswordRegex();
        const emailRegex = getEmailRegex();
        const errors = {};

        if (!values.email) {
            errors.email =  t('errors.required', {field: t("formFields.email")});
        }
        if (!values.company) {
            errors.company = t('errors.required', {field: t("formFields.company")});
        }
        if (!values.phone) {
            errors.phone = t('errors.required', {field: t("formFields.phone")});
        }
        if (!values.password) {
            errors.password = t('errors.required', {field: t("formFields.password")});
        }
        if(values.password && !values.password.toString().match(passwordRegex)){
            errors.password = t('errors.passwordNotValid');
        }
        if (!values.firstName) {
            errors.firstName = t('errors.required', {field: t("formFields.firstName")});
        }
        if (!values.lastName) {
            errors.lastName = t('errors.required', {field: t("formFields.lastName")});
        }
        if (!values.employeesRange) {
            errors.employeesRange = t('errors.required', {field: t("formFields.employeesRange")});
        }
        if (!values.country) {
            errors.country = t('errors.required', {field: t("formFields.countryOfIncorporation")});
        }
        if (!values.currency) {
            errors.currency = t('errors.required', {field: t("formFields.companyCurrency")});
        }
        if (!values.password_repeat) {
            errors.password_repeat = t('errors.required', {field: t("formFields.repeatPassword")});
        }
        if((values.password_repeat && values.password) && (values.password_repeat !== values.password)){
            errors.password_repeat =  t("errors.passwordsAreDifferent");
        }
        if (!emailRegex.test(values["email"])) {
            errors.email = t('errors.notValid', {field: t("formFields.email")});
        }
        if(!values.termsAndConditions){
            errors.termsAndConditions = t('errors.required', {field: t("formFields.termsOfService")});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.down(1023))

    const getData = async () => {
        setIsDataFetching(true);
        try {
            const countriesPromise = service.getServiceData({dataFor:"countries"});
            const employeesRangePromise = service.getServiceData({dataFor:"employeesRange"});
            const currenciesPromise = service.getServiceData({dataFor:"currencies-all"});

            const responses = await Promise.all([countriesPromise, employeesRangePromise, currenciesPromise]);
            const [countries, employeesRange, currencies] = responses;

            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);

            const mappedCountries = countries.data.map((country) => {
                return {
                    label: country.name,
                    value: country["country-code"]
                }
            });
            const mappedEmployeesRange = employeesRange.data.map(({label, value}) => ({label:label, value:label}));

            setEmployeesRangeList(mappedEmployeesRange);
            setCountries(mappedCountries);
            setIsDataFetching(false);
        }catch (e) {
            handleErrorMessage(e);
            setIsDataFetching(false);
        }
    }

    useEffect(() => {
        getData();
    },[]);


    async function submit(){
        setIsLoading(true);
        try {
            const dataToSend = {...values};
            if(isWhiteLabelRegister){
                dataToSend.isWhiteLabel = true;
            }
            const response = await service.registerUser(dataToSend);
            const {email} = response.data;
            setSuccessMessage( t("registerSuccessText", {email}));
        } catch (e) {
            setServerErrors(e);
            setIsLoading(false);
        }
    }

    if(successMessage){
        return (
            <>
                <Typography variant="h1" gutterBottom className={classes.pageTitle}>
                    {t("registerSuccessTitle")}
                </Typography>
                <Typography variant="subtitle1" className={classes.pageText}>
                    {successMessage}
                </Typography>
            </>
        )
    }

    return (
        <div className={classes.entry}>
            {!onMax1023 && (
                <>
                    {/* Left component */}
                    <div className={`${classes.entryCol} ${classes.entryColFirst}`}>
                        <div className={classes.entryWrap}>
                            <div className={classes.entryPreview}>
                                {
                                    publicSettings && publicSettings.logo_path &&
                                        <img
                                            className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                                            src={getExternalFileUrl(publicSettings.kycHost, publicSettings.logo_path)}
                                            alt="logo"
                                        />
                                }
                                { (!publicSettings || !publicSettings.logo_path) &&
                                    <Logo />
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}


            <div className={`${classes.entryCol} ${classes.entryColSecond}`}>
                {/* Top component. */}
                <div className={classes.entryHead}>
                    {onMax1023 && (
                        <Link to="/" className={classes.entryLogo}>
                            {
                                publicSettings && publicSettings.logo_path &&
                                    <img
                                        className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                                        src={getExternalFileUrl(publicSettings.kycHost, publicSettings.logo_path)}
                                        alt="logo"
                                    />
                            }
                            { (!publicSettings || !publicSettings.logo_path) &&
                                <Logo />
                            }
                        </Link>
                    )}

                    <div className={classes.entryInfo}>
                        {t("hasAccount")}
                        {' '}
                        <Link to={`/`}>
                            {t("logIn")}
                        </Link>
                    </div>
                </div>

                <div className={classes.entryWrapper}>
                    <Typography variant='h1' className={`h2 ${classes.entryWrapperTitle}`}>
                        {t("registerTitle")}
                    </Typography>

                    <form onSubmit={handleSubmit} noValidate className={classes.entryForm}>
                        <FormControl fullWidth error={!!errors.firstName} className={classes.entryFormField}>
                            <InputField
                                name="firstName"
                                placeholder={t("formFields.firstName")}
                                value={values["firstName"]}
                                onInputChange={(val) => handleChange("firstName", val)}
                                error={errors["firstName"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><UserSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.lastName} className={classes.entryFormField}>
                            <InputField
                                name="lastName"
                                placeholder={t("formFields.lastName")}
                                value={values["lastName"]}
                                onInputChange={(val) => handleChange("lastName", val)}
                                error={errors["lastName"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><UserSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.email} className={classes.entryFormField}>
                            <InputField
                                name="email"
                                placeholder={t("formFields.email")}
                                type="email"
                                value={values["email"]}
                                onInputChange={(val) => handleChange("email", val)}
                                error={errors["email"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><MailSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.phone} className={classes.entryFormField}>
                            <PhoneField
                                name="phone"
                                type="text"
                                error={errors.phone}
                                value={values["phone"]}
                                settings={settings}
                                onChangeHandler={(val) => handleChange("phone", val)}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.company} className={classes.entryFormField}>
                            <InputField
                                name="company"
                                placeholder={t("formFields.company")}
                                value={values["company"]}
                                onInputChange={(val) => handleChange("company", val)}
                                error={errors["company"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><StoreSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.currency} className={classes.entryFormField}>
                            <SelectField
                                placeholder={t("formFields.companyCurrency")}
                                name={"currency"}
                                value={values["currency"]}
                                error={errors["currency"]}
                                options={currencies}
                                onSelectChange={(val) => handleChange('currency', val)}
                                settings={settings}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.employeesRange} className={classes.entryFormField}>
                            <SelectField
                                placeholder={t("formFields.employeesRange")}
                                name={"employeesRange"}
                                value={values["employeesRange"]}
                                error={errors["employeesRange"]}
                                options={employeesRangeList}
                                onSelectChange={(val) => handleChange('employeesRange', val)}
                                settings={settings}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.country} className={classes.entryFormField}>
                            <SelectField
                                placeholder={t("formFields.countryOfIncorporation")}
                                name={"country"}
                                value={values["country"]}
                                error={errors["country"]}
                                options={countries}
                                onSelectChange={(val) => handleChange("country", val)}
                                settings={settings}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.password} className={classes.entryFormField}>
                            <InputField
                                name="password"
                                placeholder={t("formFields.password")}
                                type="password"
                                value={values["password"]}
                                onInputChange={(val) => handleChange("password", val)}
                                error={errors["password"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LockSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.password_repeat} className={classes.entryFormField}>
                            <InputField
                                name="password_repeat"
                                placeholder={t("formFields.repeatPassword")}
                                type="password"
                                value={values["password_repeat"]}
                                onInputChange={(val) => handleChange("password_repeat", val)}
                                error={errors["password_repeat"]}
                                hasIcon={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LockSVG /></InputAdornment>
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.termsAndConditions} className={`${classes.entryFormField} ${classes.entryTermsAndCondition}`}>
                            <TermsAndConditionsField values={values} errors={errors} handleChange={handleChange}/>
                        </FormControl>

                        <FormControl fullWidth className={`${classes.entryFormSubmit}`}>
                            <Button
                                type="submit"
                                disabled={isLoading}
                            >
                                {t("formFields.register")}
                            </Button>
                        </FormControl>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({publicSettings}) => {
    return { publicSettings }
}

export default connect(
    mapStateToProps,
    {getLoggedUser, handleErrorMessage}
)(RegisterForm)

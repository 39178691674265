import React from 'react';

const UserSectionWrapper = (props) => {
    return (
        <>
            {props.children}
        </>
    );
};

export default UserSectionWrapper;

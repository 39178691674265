import React, { useState } from "react";
// import {ReactComponent as ExpensePhoto} from "../../../../../assets/expensePhoto.svg";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Button } from "@material-ui/core";
// import Box from "@material-ui/core/Box";
import Api from "../../../../../services/api";
import { handleErrorMessage, setMessage } from "../../../../Messages/actions";
import { useDispatch } from "react-redux";
import compressImage from "../../../../../helpers/compressImage";
// import MenuOption from "../../../../MenuOption/MenuOption";
// import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as PhotoSVG } from "../../../../../assets/photosInvesthub.svg";
import useExpenseQueues from "../../../../../hooks/useExpenseQueues";
import { isDarkModeActive } from "../../../../App/App";
const service = new Api();

const useStyles = makeStyles((theme) => ({
    button: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "12px",
        borderRadius: "12px",
        transition: "all .2s",
        cursor: "pointer",
        background: "transparent !important",
        justifyContent: "flex-start",
        fontSize: "15px",
        fontWeight: "600",
        lineHeight: "1.6",
        color: "var(--battelship-grey)",
        fontFamily: `'Inter', sans-serif`,
        letterSpacing: "0",
        "& svg": {
            width: "24px",
            height: "24px",
            marginRight: "12px",
            fill: "var(--battelship-grey)",
            transition: "fill .2s",
        },
        "& .MuiTypography-root": {
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "1.6",
            color: "var(--battelship-grey)",
            transition: "all .2s",
            fontFamily: `'Inter', sans-serif`,
            letterSpacing: "0",
        },
        "&:hover": {
            color: (props) =>
                props.isDarkModeActive
                    ? "var(--pale-grey)"
                    : "var(--dark-secondary)",
            "& svg": {
                fill: (props) =>
                    props.isDarkModeActive
                        ? "var(--pale-grey)"
                        : "var(--dark-secondary)",
            },
            "& .MuiTypography-root": {
                color: (props) =>
                    props.isDarkModeActive
                        ? "var(--pale-grey)"
                        : "var(--dark-secondary)",
            },
        },
        "& .MuiTouchRipple-root": {
            display: "none",
        },
    },
    menuItem: {
        padding: 0,
        "& .MuiListItem-button": {
            width: "100%",
        },
    },
}));

const UploadImage = (props) => {
    const { handleClose, classExtend, settingsDarkMode } = props;
    const { t } = useTranslation();
    const classes = useStyles({
        isDarkModeActive: isDarkModeActive(settingsDarkMode),
    });
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const [, setExpensesQueue] = useExpenseQueues();

    const acceptedDocuments = ".png,.jpeg,.jpg,.pdf";

    const handleUpload = async (e, type) => {
        let i = 1;
        const length = e?.target?.files?.length || 0;
        if (e.target.files && e.target.files[0] && e.target.files?.length) {
            try {
                setIsDisabled(true);
                for await (const uploadFile of e.target.files) {
                    const formData = new FormData();

                    const inputFile = uploadFile;

                    const file =
                        inputFile.type === "application/pdf"
                            ? inputFile
                            : await compressImage(inputFile); // compress only image

                    formData.append("receipt", file);
                    formData.append("paymentType", type);
                    const createdExpenseQueue =
                        await service.createExpenseQueue(formData);
                    dispatch(
                        setMessage(
                            t("success") + `\nProcess:${i}/${length}`,
                            "success"
                        )
                    );

                    i++;

                    setExpensesQueue((items) => {
                        return [createdExpenseQueue.data, ...items];
                    });
                }
                handleClose();
            } catch (e) {
                console.log(e);
                dispatch(
                    handleErrorMessage({ message: t("errors.cantHandleFile") })
                );
            } finally {
                setIsDisabled(false);
            }
        }
    };

    return (
        <Box className={classExtend}>
            <Button
                disabled={isDisabled}
                component="label"
                className={classes.button}
                disableTouchRipple={true}
            >
                <PhotoSVG />
                {t("expense.uploadExpenseImage")}
                <input
                    type="file"
                    accept={acceptedDocuments}
                    onChange={(e)=>handleUpload(e, 'Expense')}
                    style={{ display: "none" }}
                    multiple
                />
            </Button>
            <Button
                disabled={isDisabled}
                component="label"
                className={classes.button}
                disableTouchRipple={true}
            >
                <PhotoSVG />
                {t("expense.uploadRequestPaymentImage")}
                <input
                    type="file"
                    accept={acceptedDocuments}
                    onChange={(e)=>handleUpload(e, 'Request Payment')}
                    style={{ display: "none" }}
                    multiple
                />
            </Button>
        </Box>
    );
};

export default UploadImage;

import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: "none",
        color:"#24dac4",
        cursor:"pointer",
        "&:hover": {
            textDecoration: "underline",
        }
    }
}));

const ExternalLink = (props) => {
    const {children, ...restProps} = props;
    const classes = useStyles();

    return <a rel="nofollow noopener" className={classes.link} {...restProps}>{children}</a>
};

export default ExternalLink;

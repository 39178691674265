import React from "react";
import ExpensesView from "./ExpensesView";
import { withRouter, Switch, Route, matchPath } from "react-router-dom";
import Expense from "./Expense";
import AddExpense from "./Expense/AddExpense";
import { compose } from "redux";
import BatchEdit from "./Expense/BatchEdit";
import BatchAdd from "./Expense/BatchAdd";
import { useTranslation } from "react-i18next";
import SelectTab from "../../../SelectTab";
import { Can } from "../../../../Context/Can";
import ExpenseQueuesProvider from "../../../ExpenseQueuesProvider";
import ExpensesProvider from "../../../ExpensesProvider";
import UnderCardDockInvesthub from "../../../UnderCardDockInvesthub/UnderCardDockInvesthub";
import TransactionsMakePaymentPage from "../../../pages/TransactionsAddVendorPage";
import { useSelector } from "react-redux";

const Expenses = (props) => {
    const { match, location, drawerOuterRef, underBarRef, settingsDarkMode } = props;
    const { t } = useTranslation();
    const roles = useSelector(state => {
        const {user} = state;
            return user?.roles;
    });

    const matchedPath = matchPath(location.pathname, {
        path: "/transactions/:tab",
        exact: true,
        strict: false,
    });
    const currentTab = matchedPath ? matchedPath.params.tab : "";
    const types = [
        { id: "to-submit", name: t("expense.toSubmitTab") },
        { id: "todo", name: t("expense.toDoTab") },
        { id: "to-pay", name: t("expense.toPayTab"), isOnlyAdmin: true },
        // { id: "incoming-transactions", name: t("expense.inTransactionsTab") },
        // { id: "outgoing-transactions", name: t("expense.outTransactionsTab") },
        { id: "executed-transactions", name: t("expense.executedTransactionsTab")},
        { id: "all", name: t("expense.allTab") },
    ];
    const isNormalUser = roles?.length === 1 && roles[0] === "personal";
    const tabOptions = types
        .filter((e) => (isNormalUser ? !e?.isOnlyAdmin : true))
        .map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    const checkPermission = () => {
        const type = types.find((e) => e.id === currentTab);
        if (type) {
            return isNormalUser ? !type?.isOnlyAdmin : true;
        }
        return false;
    };

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[`${match.path}/todo`, `${match.path}/to-submit`, `${match.path}/all`, `${match.path}/to-pay`, `${match.path}/executed-transactions`]}
                    render={(props) => {
                        const isEnoughPermission = checkPermission();
                        if (!isEnoughPermission) {
                            return (
                                <div>
                                    {t("expense.noPermissionToRead")}
                                </div>
                            )
                        }
                        const onSelectChange = (val) => {
                            props.history.push(`${match.url}/${val}`);
                        };
                        return (
                            <>
                                <ExpenseQueuesProvider>
                                    <ExpensesProvider>
                                        <ExpensesView
                                            type={currentTab}
                                            drawerOuterRef={drawerOuterRef}
                                            underBarRef={underBarRef}
                                            settingsDarkMode={settingsDarkMode}
                                            tabOptions={tabOptions}
                                            onSelectChange={(val) =>
                                                onSelectChange(val)
                                            }
                                        />
                                    </ExpensesProvider>
                                </ExpenseQueuesProvider>

                                {/* NEED TRANSLATION */}
                                {/* <UnderCardDockInvesthub
                                    titleDock={t("Page Inner")}
                                    settingsDarkMode={settingsDarkMode}
                                >
                                    <SelectTab
                                        value={currentTab}
                                        options={tabOptions}
                                        onSelectChange={(val) =>
                                            onSelectChange(val)
                                        }
                                        settingsDarkMode={settingsDarkMode}
                                    />
                                </UnderCardDockInvesthub> */}
                            </>
                        );
                    }}
                />

                <Route
                    path={`${match.path}/add`}
                    render={() => {
                        return (
                            <Can I="create" a={"expense"} passThrough>
                                {(can) =>
                                    can ? (
                                        <AddExpense
                                            history={props.history}
                                            underBarRef={underBarRef}
                                            settingsDarkMode={settingsDarkMode}
                                        />
                                    ) : (
                                        <div>
                                            {t("expense.noPermissionToAdd")}
                                        </div>
                                    )
                                }
                            </Can>
                        );
                    }}
                />
                <Route
                    path={`${match.path}/batchEdit`}
                    render={() => {
                        return (
                            <Can I="update" a={"expense"} passThrough>
                                {(can) =>
                                    can ? (
                                        <BatchEdit underBarRef={underBarRef} />
                                    ) : (
                                        <div>
                                            {t("expense.noPermissionToUpdate")}
                                        </div>
                                    )
                                }
                            </Can>
                        );
                    }}
                />
                <Route
                    path={`${match.path}/batchAdd`}
                    render={() => {
                        return (
                            <Can I="create" a={"expense"} passThrough>
                                {(can) =>
                                    can ? (
                                        <BatchAdd underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                                    ) : (
                                        <div>
                                            {t("expense.noPermissionToCreate")}
                                        </div>
                                    )
                                }
                            </Can>
                        );
                    }}
                />
                <Route
                    path={`${match.path}/add-vendor`}
                    render={() => {
                        return (
                            <Can I="create" a={"expense"} passThrough>
                                {(can) =>
                                    can ? (
                                        <BatchAdd underBarRef={underBarRef} />
                                    ) : (
                                        <div>
                                            {t("expense.noPermissionToCreate")}
                                        </div>
                                    )
                                }
                            </Can>
                        );
                    }}
                />
                <Route
                    path={`${match.path}/make-payment`}
                    render={() => {
                        return (
                            <Can I="create" a={"expense"} passThrough>
                                {(can) =>
                                    can ? (
                                        <TransactionsMakePaymentPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />
                                    ) : (
                                        <div>
                                            {t("expense.noPermissionToCreate")}
                                        </div>
                                    )
                                }
                            </Can>
                        );
                    }}
                />

                <Route
                    path={`${match.path}/:id`}
                    render={() => {
                        return <Expense underBarRef={underBarRef} settingsDarkMode={settingsDarkMode} />;
                    }}
                />
            </Switch>
        </>
    );
};

export default compose(withRouter)(Expenses);

import React, {memo} from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CheckboxEnabled} from "../../../assets/checkboxEnabled.svg";
import {ReactComponent as CheckboxDisabled} from "../../../assets/checkboxDisabled.svg";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles({
    checkbox: {
        "&.Mui-disabled .MuiIconButton-label": {
            opacity: .5
        }
    },
    iconError: {
        "& rect":{
            stroke:"#f44336",
        }
    },
    checkedIconError: {
        "& rect": {
            stroke: "#f44336",
            fill: "#f44336"
        }
    },
});

const CheckBoxFieldMemo = (props) => {
    const {error, ...restProps} = props;
    const classes = useStyles();

    return (
        <Checkbox
            className={classes.checkbox}
            {...restProps}
            checkedIcon={<Tooltip arrow title={error ? error : ""} placement="top" open={!!error}><CheckboxEnabled className={error ? classes.checkedIconError : null}/></Tooltip>}
            icon={<Tooltip arrow title={error ? error : ""} placement="top" open={!!error}><CheckboxDisabled className={error ? classes.iconError : null}/></Tooltip>}
        />
    )
};

export default memo(CheckBoxFieldMemo);

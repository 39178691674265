import React, {useState} from 'react';
import {ReactComponent as ReceiptIcon} from "../../../../../../../../assets/receipt.svg";
import IconButton from "@material-ui/core/IconButton";
import openBlobFromUrl from "../../../../../../../../helpers/openBlobFromUrl";
import {makeStyles} from "@material-ui/core";
import {ReactComponent as CloseThin} from "../../../../../../../../assets/closeThin.svg";
import {Can} from "../../../../../../../../Context/Can";
import {subject} from "@casl/ability";
import {handleErrorMessage, setMessage} from "../../../../../../../Messages/actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
            deleteIcon: {
                "& path": {
                    stroke: "#2c2c36"
                }
            },
            disabled: {
                opacity: .5
            }
        }
    )
);


const ReceiptButtons = (props) => {
    const {expenseQueue, onDeleteReceipt} = props;
    const {receipt, _id} = expenseQueue;

    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const onGetReceipt = async () => {
        try {
            setIsLoading(true);
            await openBlobFromUrl(receipt)

        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    const onDelete = async () => {
        try{
            setIsLoading(true);
            await onDeleteReceipt(_id);
            dispatch(setMessage("success", 'success'));
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }



    return (
        <>
            <Can I="delete" this={subject("expenseQueue", expenseQueue)}>
                <IconButton disabled={isLoading} className={isLoading ? classes.disabled : ""} onClick={onDelete}><CloseThin className={classes.deleteIcon}/></IconButton>
            </Can>
            <IconButton disabled={isLoading} className={isLoading ? classes.disabled : ""}  onClick={onGetReceipt}><ReceiptIcon/></IconButton>
        </>

    );
};

export default ReceiptButtons;

import React, {useState} from 'react';
import {setMessage} from "../../../../Messages/actions";
import useForm2 from "../../../../../hooks/useForm2";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import BackButton from "../../../../BackButton";
import {Can} from "../../../../../Context/Can";
import Api from "../../../../../services/api";
import BranchForm from "../../../../BranchForm";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import {makeStyles, Typography, Button, Portal} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: '24px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '16px'
        }
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: '40px',
        position: 'relative',
        marginBottom: '32px',
        [theme.breakpoints.down('767')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            paddingBottom: '24px',
            marginBottom: '24px'
        },
    },
    item: {
        position: 'relative'
    },
    buttonSubmit: {
    }
}))

const AddBranch = (props) => {
    const {underBarRef, history, currencies, countries, settingsDarkMode} = props;

    const classes = useStyle();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.createBranch(values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/branches");
        }catch (e) {
            console.log(e);
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }


    const validate = (values) => {
        const regex = getAlphaNumericRegex();

        const errors = {};

        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }
        if (!values.currency) {
            errors.currency = t("errors.required", {field:"$t(formFields.currency)"});
        }
        if (values["branchId"] && !regex.test(values["branchId"].toString())) {
            errors.branchId = t("errors.notValid", {field:"$t(formFields.branchId)"});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm2(
        submit,
        validate,
    );

    const actionButtons = (
        <>
            <Can I="create" a={"group"}>
                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className={classes.buttonSubmit}
                >
                    {t("formFields.create")}
                </Button>
            </Can>
        </>
    )

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography variant="h1" className={`${classes.pageTitle} h3`}>
                        {t("settingsTitle")}
                    </Typography>
                </Portal>
            )}
            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <BranchForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        currencies={currencies}
                        countries={countries}
                        settingsDarkMode={settingsDarkMode}
                        actionButtons={actionButtons}
                    />
                </div>
            </div>
        </>
    );
};

export default AddBranch;

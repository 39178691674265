import { useState, useEffect } from "react";
import {handleErrorMessage} from "../components/Messages/actions";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

const useForm2 = (callback, validate) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleChange = (name, value) => {
        setValues(values => {
                const oldValues = {...values};

                if (value !== undefined) {
                    return {
                        ...oldValues,
                        [name]: value
                    }
                }else{
                    // if no value - delete property
                    delete oldValues[name];
                    return {
                        ...oldValues,
                    }
                }
            }
        );
        setErrors((errors) => {
            const oldErrors = {...errors};
            delete oldErrors[name];
            return {
                ...oldErrors,
            }

        });
    };

    const handleSubmit = event => {
        event.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    const fillFormValues = (data) => {
        setValues(data)
    }

    const setServerErrors = (e) => {
        if(e.response && e.response.data && e.response.data.errors){
            const serverErrors = Object.keys(e.response.data.errors).reduce((errors, field) => {
                errors[field] = t(e.response.data.errors[field]);
                return errors
            },{});

            setErrors(errors => (
                {
                    // ...errors,
                    ...serverErrors
                }
            ));
        }else {
            dispatch(handleErrorMessage(e));
        }
    };
    const resetErrors = () => {
        setErrors({});
    }



    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
        setIsSubmitting(false);

    }, [errors]);

    return {
        handleChange,
        handleSubmit,
        values,
        errors,
        fillFormValues,
        setServerErrors,
        resetErrors
    };
};

export default useForm2;

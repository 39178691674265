import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import useForm from "../../../../hooks/useForm";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import Api from "../../../../services/api";
import formatMoney from "../../../../helpers/formatMoney";
import SearchInput from "./SearchInput";
import SearchAutocomplete from "./SearchAutocomplete";
import {useSelector} from "react-redux";
import { isDarkModeActive } from '../../../App/App';
const service = new Api();

const useStyles = makeStyles((theme) => (
    {}
));

const Search = (props) => {
    const {location, history, type, placeholderText, placeholderTextExpense, settingsDarkMode} = props;

    const classes = useStyles({ isDarkModeActive: isDarkModeActive(settingsDarkMode) });
    const {t} = useTranslation();

    const {search} = location;
    const query = queryString.parse(search);
    const tabsExpenses = useSelector(state => state.tabs["transactions"]);
    const decimal = useSelector(state => {
        const {settings={}} = state.user;
        const {regional={}} = settings;
        return regional.decimal
    });

    const getFieldsFromOption = (option) => {
        const {merchant, reportedCurrency, status, calculatedAmount} = option;
        return [merchant, t(`expense.${status}`), `${reportedCurrency} ${formatMoney(calculatedAmount, decimal)}`]
    }


    const validate = () => {
        return {}
    };

    const submit = () => {
        const {searchValue} = values;

        history.push({
            search: `?${queryString.stringify({...query, searchValue: searchValue ? searchValue : undefined})}`,
        });
    };

    useEffect(() => {
        handleChange("searchValue", query.searchValue ? query.searchValue : undefined);
    },[search]);


    const {handleChange, handleSubmit, values, errors, setServerErrors} = useForm(
        submit,
        validate,
    );

    const onOptionClick = (e, newVal) => {
        if(newVal && newVal._id){
            history.push(`/transactions/${newVal._id}`);
        }
    }

    return (

        <form action="#" onSubmit={handleSubmit} className={classes.form}>
            {type === "expenses" ? (
                <SearchAutocomplete
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    inputValue={values["searchValue"]}
                    role={tabsExpenses.active}
                    getOptions={service.searchExpense}
                    onOptionClick={onOptionClick}
                    getOptionPlaceholder={placeholderTextExpense}
                    renderOption={(option) => {
                        const fields = getFieldsFromOption(option);
                        return <div>{fields.filter((item) => item).map((item, idx) => <div key={idx}>{item}</div>)}</div>;
                    }}
                    getOptionLabel={(option) => {
                        // is used for fill value in input
                        if (typeof option === 'string') {
                            return option;
                        }
                        const fields = getFieldsFromOption(option);
                        return fields.filter((item) => item).join(", ");
                    }}
                    settingsDarkMode={settingsDarkMode}
                />
            ) : (
                <SearchInput settingsDarkMode={settingsDarkMode} handleSubmit={handleSubmit} onChange={(val) => handleChange("searchValue", val.target.value)} value={values["searchValue"]} searchInputPlaceholder={placeholderText} />
            )}
        </form>
    )
}

export default withRouter(Search);

import React from 'react';
import InputField from "../formElements/InputField";
import MultipleSelectSearch from "../formElements/MultipleSelectSearch";
import CheckBoxField from "../formElements/CheckBoxField";
import {Can} from "../../Context/Can";
import {useTranslation} from "react-i18next";
import {makeStyles, FormControl} from "@material-ui/core";
import ToggleFieldWrapper from '../formElements/ToggleFieldWrapper/ToggleFieldWrapper';
import ToggleCheckboxField from '../formElements/ToggleCheckboxField/ToggleCheckboxField';

const useStyle = makeStyles((theme) => ({
    itemFields: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0',
        },

        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
    },
}))

const ProjectForm = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();

    const {handleChange, handleSubmit, values, groups, errors, loading, settingsDarkMode} = props;

    const onGroupChange = (values) => {
        const vals = values ? values.filter((value) => value).map((item) => item.value) : [];
        handleChange("group", vals);
    }

    return (
        <form onSubmit={handleSubmit} noValidate>
            <div className={classes.itemFields}>
                <Can I="update" a={"project"} passThrough>
                    {(can) => (
                        <>
                            <FormControl fullWidth error={!!errors.name} className={`${classes.itemField} ${classes.itemFieldFull}`}>
                                <InputField
                                    label={t("formFields.name")}
                                    placeholder={t("formFields.name")}
                                    name="name"
                                    isLoading={loading}
                                    disabled={!can}
                                    value={values["name"]}
                                    onInputChange={(val) => handleChange("name", val)}
                                    error={errors["name"]}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors.accountingCode} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.accountingCode")}
                                    placeholder={t("formFields.accountingCode")}
                                    name="accountingCode"
                                    isLoading={loading}
                                    disabled={!can}
                                    value={values["accountingCode"]}
                                    onInputChange={(val) => handleChange("accountingCode", val)}
                                    error={errors["accountingCode"]}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors.group} className={classes.itemField}>
                                <MultipleSelectSearch
                                    options={groups}
                                    isLoading={loading}
                                    values={values["group"]}
                                    label={t("formFields.group")}
                                    disabled={!can}
                                    error={errors.group}
                                    name={"group"}
                                    inputHandler={onGroupChange}
                                />
                            </FormControl>

                            <div className={`${classes.itemField} ${classes.itemFieldFull}`}>
                                <ToggleFieldWrapper settings={settingsDarkMode}>
                                    <ToggleCheckboxField
                                        additionalClass="toggle-item"
                                        errorField={!!errors.active}
                                        onCheckboxChange={(val) => handleChange("active", !!val)}
                                        value={values["active"]}
                                        label={t("formFields.active")}
                                        name={"active"}
                                        error={errors["active"]}
                                        disabled={!can}
                                        settings={settingsDarkMode}
                                    />
                                </ToggleFieldWrapper>
                            </div>
                        </>
                    )}
                </Can>
            </div>
        </form>
    );
};

export default ProjectForm;

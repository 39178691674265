import * as propsTypes from '../../../../../../store/actionTypes'
import Api from "../../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
const service = new Api();

const fetchAccount = (accountId) => async dispatch => {
	dispatch({type:propsTypes.ACCOUNT_REQUEST});

	try {
		const result = await service.getAccount(accountId);
		const {data} = result;
		dispatch({type:propsTypes.ACCOUNT_SUCCESS, payload:data.account});
	}catch (e) {
		dispatch(handleErrorMessage(e));
		dispatch({type:propsTypes.ACCOUNT_FAILURE});
	}
}


export {
	fetchAccount,
};

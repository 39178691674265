import React from 'react';
import {FormControl} from "@material-ui/core";
import {Can} from "../../Context/Can";
import InputField from "../formElements/InputField";
import MultipleSelectSearch from "../formElements/MultipleSelectSearch";
import {useTranslation} from "react-i18next";
import CheckBoxField from "../formElements/CheckBoxField";
import FormLabel from "@material-ui/core/FormLabel";
import NumericInput from "../formElements/NumericInput";
import isNumeric from "../../helpers/isNumeric";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    itemFields: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0',
        },
    },
    itemFieldCheckbox: {
        "& .MuiFormControlLabel-root": {
            marginLeft: '0',
            marginRight: '0'
        }
    }
}))

const TaxRateForm = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();
    const {values, handleSubmit, errors, isDataFetching, loading, handleChange, countries, decimal, categories} = props;

    const onCountriesChange = (values) => {
        const vals = values ? values.filter((value) => value).map((item) => item.value) : [];
        handleChange("countries", vals);
    }
    const onCategoriesChange = (values) => {
        const vals = values ? values.filter((value) => value).map((item) => item.value) : [];
        handleChange("categories", vals);
    }

    return (
        <form onSubmit={handleSubmit} noValidate>
            <div className={classes.itemFields}>
                <Can I="update" a={"taxRate"} passThrough>
                    {(can) => (
                        <>
                            <FormControl fullWidth error={!!errors.name} className={classes.itemField}>
                                <InputField
                                    label={t("formFields.name")}
                                    name="name"
                                    disabled={!can}
                                    isLoading={isDataFetching || loading}
                                    value={values["name"]}
                                    onInputChange={(val) => handleChange("name", val)}
                                    error={errors["name"]}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors.taxRate} className={classes.itemField}>
                                <NumericInput
                                    label={<>{t("formFields.taxRate")} (%)</>}
                                    decimal={decimal}
                                    name={"taxRate"}
                                    disabled={!can}
                                    maskProps={{scale:1}}
                                    onInputChange={(name, value) => {
                                        handleChange(name, value)
                                    }}
                                    value={isNumeric(values["taxRate"]) ? values["taxRate"].toString() : ""}
                                    error={errors.taxRate}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors.countries} className={classes.itemField}>
                                <FormLabel>{t("formFields.defaultCountries")}</FormLabel>
                                <label style={{display:"none"}}>fake label</label> {/*this will overcome chrome autofill*/}
                                <MultipleSelectSearch
                                    options={countries}
                                    isLoading={isDataFetching || loading}
                                    values={values["countries"]}

                                    disabled={!can}
                                    name={"countries"}
                                    error={errors.countries}
                                    inputHandler={onCountriesChange}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors.categories} className={classes.itemField}>
                                <MultipleSelectSearch
                                    options={categories}
                                    isLoading={isDataFetching || loading}
                                    values={values["categories"]}
                                    label={t("formFields.defaultCategories")}
                                    disabled={!can}
                                    error={errors.categories}
                                    name={"categories"}
                                    inputHandler={onCategoriesChange}
                                />
                            </FormControl>

                            <FormControl fullWidth error={!!errors.active} className={`${classes.itemField} ${classes.itemFieldCheckbox}`}>
                                <CheckBoxField
                                    onCheckboxChange={(val) => handleChange("active", !!val)}
                                    value={values["active"]}
                                    label={t("formFields.active")}
                                    name={"active"}
                                    error={errors["active"]}
                                    disabled={!can}
                                />
                            </FormControl>
                        </>
                    )}
                </Can>
            </div>
        </form>
    );
};

export default TaxRateForm;

import axios from 'axios';
import downloadFile from "../helpers/downloadFile";
const queryString = require('query-string');

axios.interceptors.response.use(
    response => { return response; },
    error => {
        // needs for getting error response in blob request
        if (
            error.response &&
            error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') != -1
        )
        {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => {
                    error.response.data = JSON.parse(reader.result);
                    resolve(Promise.reject(error));
                };

                reader.onerror = () => {
                    reject(error);
                };

                reader.readAsText(error.response.data);
            });
        };

        return Promise.reject(error);
    }
);


class Api{
	_basicPath = window._env_.API_URL;

	getPublicSetting = async () => {
		const url = `${this._basicPath}/api/settings/public-setting`;
		return await axios.get(url);
	}

	loginUser = async (data) => {
		const url = `${this._basicPath}/api/auth`;
		return await axios.post(url, data);
	};

	forgotPassword = async (email) => {
		const authData = {email};
		const url = `${this._basicPath}/api/auth/forgotpassword`;
		return await axios.post(url, authData);
	};
	resetPassword = async (password, resetToken) => {
		const authData = {password, resetToken};
		const url = `${this._basicPath}/api/auth/resetpassword`;
		return await axios.post(url, authData);
	};
	confirmEmail = async (hash) => {
		const url = `${this._basicPath}/api/users/email-confirmation/${hash}`;
		return await axios.get(url);
	};

	getCurrentUser = async () => {
		const url = `${this._basicPath}/api/users/me`;
		return await axios.get(url);
	}
	registerUser = async (values) => {
		const url = `${this._basicPath}/api/users`;

		return await axios.post(url, values);
	};

	updateCurrentUser = async (values) => {
		const url = `${this._basicPath}/api/users/me`;
		return await axios.post(url, values);
	};

	updateUser = async (userId, values) => {
		const url = `${this._basicPath}/api/users/${userId}`;
		return await axios.post(url, values);
	};
    exportUsers = async () => {
        const url = `${this._basicPath}/api/users/export`;
        await downloadFile(url);
    }
	deleteUser = async (userId) => {
		const url = `${this._basicPath}/api/users/${userId}`;
		return await axios.delete(url);
	}

	generateWallet = async () => {
		const url = `${this._basicPath}/api/users/generate-wallet`;
		return await axios.post(url);
	};

	importUserWallet = async (data) => {
		const url = `${this._basicPath}/api/users/import-wallet`;
		return await axios.post(url, data);
	};

	generateCompanyWallet = async () => {
		const url = `${this._basicPath}/api/company/generate-wallet`;
		return await axios.post(url);
	};

	importCompanyWallet = async (data) => {
		const url = `${this._basicPath}/api/company/import-wallet`;
		return await axios.post(url, data);
	};

	getUserImportedWalletBalances = async () => {
		const url = `${this._basicPath}/api/users/get-imported-wallet-balances`;
		return await axios.get(url);
	};

	getTanganyWalletBalances = async () => {
		const url = `${this._basicPath}/api/users/get-tangany-wallet-balances`;
		return await axios.get(url);
	};

	getCompanyTanganyWalletBalances = async () => {
		const url = `${this._basicPath}/api/company/get-tangany-wallet-balances`;
		return await axios.get(url);
	};

	getCompanyImportedWalletBalances = async () => {
		const url = `${this._basicPath}/api/company/get-imported-wallet-balances`;
		return await axios.get(url);
	};

	getCompanyImportedWallets = async () => {
		const url = `${this._basicPath}/api/company/get-imported-wallets`;
		return await axios.get(url);
	};

	getCompanySelectedTanganyWalletBalances = async (network) => {
		let url = `${this._basicPath}/api/company/get-company-selected-tangany-wallet-balances`;
		if (network) {
			url = url + `?network=${network}`
		}
		return await axios.get(url);
	};

	sendTanganyWallet = async (values) => {
		const url = `${this._basicPath}/api/users/send-tangany-wallet`;
		return await axios.post(url, values);
	};

	tanganyTransferToken = async (values) => {
		const url = `${this._basicPath}/api/users/tangany-transfer-token`;
		return await axios.post(url, values);
	};

	getExpenseHistory = async (expenseId, data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/expenses/${expenseId}/history?${query}`;
		return await axios.get(url);
	};
	getExpenses = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/expenses?${query}`;
		return await axios.get(url);
	};
    searchExpense = async (values) => {
        const query = queryString.stringify(values);
        const url = `${this._basicPath}/api/expenses/search?${query}`;
        return await axios.get(url);
    }
    getExpensesExport = async (data) => {
        const query = queryString.stringify(data, {arrayFormat: 'comma'}); // use comma because in query string express converts long array into object
        const url = `${this._basicPath}/api/expenses/export?${query}`;
        await downloadFile(url);
    };
    getExpensesExportCount = async (data) => {
        const query = queryString.stringify(data, {arrayFormat: 'index'});
        const url = `${this._basicPath}/api/expenses/export/count?${query}`;
        return await axios.get(url);
    };
    expenseAction = async (expenseId, action, paidWith, metamaskData) => {
        const url = `${this._basicPath}/api/expenses/${expenseId}/action`;
        return await axios.post(url, {action, paidWith, metamaskData});
    }
    rejectExpense = async (id, values) => {
        const url = `${this._basicPath}/api/expenses/${id}/reject`;
        return await axios.post(url, values);
    }
    getExchangeRate = async (fromCurrency, toCurrency, amount=1, date) => {
        const url = `${this._basicPath}/api/expenses/exchange`;
        return await axios.post(url, {fromCurrency, toCurrency, amount, date});
    }
	getExpense = async (cardId) => {
		const url = `${this._basicPath}/api/expenses/${cardId}`;
		return await axios.get(url);
	}
    getExpenseProjects = async (id) => {
        const url = `${this._basicPath}/api/expenses/${id}/projects`;
        return await axios.get(url);
    }
    getExpenseCategories = async (id) => {
        const url = `${this._basicPath}/api/expenses/${id}/categories`;
        return await axios.get(url);
    }
    // getGroupedExpensesByUser = async (status) => {
    //     const query = queryString.stringify({status:status});
    //     const url = `${this._basicPath}/api/expenses/users?${query}`;
    //     return await axios.get(url);
    // }
    getDashboardAmountsByDay = async (filterData) => {
        const query = queryString.stringify(filterData);
        const url = `${this._basicPath}/api/expenses/dashboard-amount-by-day?${query}`;
        return await axios.get(url);
    }
    getDashboardLatest = async (filterData) => {
        const query = queryString.stringify(filterData);
        const url = `${this._basicPath}/api/expenses/dashboard-latest?${query}`;
        return await axios.get(url);
    }
    getDashboardWaitingForApproval = async (filterData) => {
        const query = queryString.stringify(filterData);
        const url = `${this._basicPath}/api/expenses/dashboard-waiting-for-approval?${query}`;
        return await axios.get(url);
    }
    getDashboardAmountsByCategory = async (filterData) => {
        const query = queryString.stringify(filterData);
        const url = `${this._basicPath}/api/expenses/dashboard-amount-by-category?${query}`;
        return await axios.get(url);
    }
	updateExpense = async (expenseId, data) => {
		const url = `${this._basicPath}/api/expenses/${expenseId}`;
		return await axios.post(url, data);
	}
    addBatchExpense = async (data) => {
        const url = `${this._basicPath}/api/expenses/batch-add`;
        return await axios.post(url, data);
    }
    updateBatchExpense = async (data) => {
        const url = `${this._basicPath}/api/expenses/batch-edit`;
        return await axios.post(url, data);
    }
    submitBatchExpense = async (data) => {
        const url = `${this._basicPath}/api/expenses/batch-submit`;
        return await axios.post(url, data);
    }
	deleteBatchExpense = async (data) => {
		const url = `${this._basicPath}/api/expenses/batch-delete`;
		return await axios.post(url, data);
	}
	rejectBatchExpense = async (data) => {
		const url = `${this._basicPath}/api/expenses/batch-reject`;
		return await axios.post(url, data);
	}
	actionBatchExpense = async (data) => {
		const url = `${this._basicPath}/api/expenses/batch-action`;
		return await axios.post(url, data);
	}
	deleteExpense = async (expenseId) => {
		const url = `${this._basicPath}/api/expenses/${expenseId}`;
		return await axios.delete(url);
	}
	createExpense = async (data) => {
		const url = `${this._basicPath}/api/expenses`;
		return await axios.post(url, data);
	}
	disableCard = async (cardId, data) => {
		const url = `${this._basicPath}/api/cards/${cardId}/disable`;
		return await axios.post(url, data);
	}
	futureBlockCard = async (cardId, data) => {
		const url = `${this._basicPath}/api/cards/${cardId}/future-block`;
		return await axios.post(url, data);
	}
	uploadCardImage = async (cardId, data) => {
		const url = `${this._basicPath}/api/cards/${cardId}/image`;
		return await axios.post(url, data);
	}

    getCards = async (data) => {
        const query = queryString.stringify(data, {skipEmptyString: false});
        const url = `${this._basicPath}/api/cards?${query}`;
        return await axios.get(url);
    }
    getCardsList = async (role) => {
        let query =  queryString.stringify(role ? {fromTab:role} : null);
        const url = `${this._basicPath}/api/cards/all/?${query}`;
        return await axios.get(url);
    }
    getCardsForExpense = async (id) => {
        const url = `${this._basicPath}/api/expenses/${id}/cards`;
        return await axios.get(url);
    }

	getTransaction = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/transactions?${query}`;
		return await axios.get(url);
	}

	getCard = async (cardId) => {
		const url = `${this._basicPath}/api/cards/${cardId}`;
		return await axios.get(url);
	}
	getCardShortInfo = async (cardId) => {
		const url = `${this._basicPath}/api/cards/${cardId}/short-info`;
		return await axios.get(url);
	}
	deleteCard = async (cardId) => {
		const url = `${this._basicPath}/api/cards/${cardId}`;
		return await axios.delete(url);
	}
	blockUnblockPermsGroupCard = async (cardId, values) => {
		const url = `${this._basicPath}/api/cards/${cardId}/block-unblock-perms-group`;
		return await axios.post(url, values);
	}
	blockUnblockCard = async (cardId, action) => {
		const url = `${this._basicPath}/api/cards/${cardId}/block`;
		return await axios.post(url, {action});
	}
	blockCardPermanently = async (cardId, values) => {
		const url = `${this._basicPath}/api/cards/${cardId}/block-permanently`;
		return await axios.post(url, values);
	}
	cardSetMonthLimit = async (cardId, values) => {
		const url = `${this._basicPath}/api/cards/${cardId}/month-limit`;
		return await axios.post(url, values);
	}

	activateCard = async (cardId, data) => {
		const url = `${this._basicPath}/api/cards/${cardId}/activate`;
		return await axios.post(url, data);
	}

	getCardPinCode = async (cardId, values) => {
		const url = `${this._basicPath}/api/cards/${cardId}/pin`;
		return await axios.post(url, values);
	}
	receiveCardPan = async (cardId) => {
		const url = `${this._basicPath}/api/cards/${cardId}/pan`;
		return await axios.post(url);
	}

	getCardHiddenInfo = async (cardId) => {
		const url = `${this._basicPath}/api/cards/${cardId}/hiddenInfo`;
		return await axios.get(url);
	}

	getCardTransactions = async (cardId, data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/cards/${cardId}/transactions?${query}`;
		return await axios.get(url);
	}

	createCard = async (data) => {
		const url = `${this._basicPath}/api/cards`;
		return await axios.post(url, data);
	}

	getUsers = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/users?${query}`;
		return await axios.get(url);
	}
    importUsers = async (data) => {
        const url = `${this._basicPath}/api/users/import`;
        return await axios.post(url, data);
    }
    getUsersAll = async (data) => {
        const query = queryString.stringify(data);
        const url = `${this._basicPath}/api/users/all?${query}`;
        return await axios.get(url);
    }
    assignReporters = async (id, data) => {
        const url = `${this._basicPath}/api/users/${id}/assign-reporters`;
        return await axios.post(url, data);
    }
	getUsersUser = async (userId) => {
		const url = `${this._basicPath}/api/users/${userId}`;
		return await axios.get(url);
	}
	actionBatchActivate= async (data) => {
		const url = `${this._basicPath}/api/users/batch-activate`;
		return await axios.post(url, data);
	}
	getGroupedRoles = async () => {
		const url = `${this._basicPath}/api/users/roles`;
		return await axios.get(url);
	}

	createInvitedUser = async (values) => {
		const url = `${this._basicPath}/api/invitation/add`;
		return await axios.post(url, values);
	};
	resendInvitedUser = async (userId) => {
		const url = `${this._basicPath}/api/invitation/resend`;
		return await axios.post(url, {userId});
	}

	getInvitedUser = async (hash) => {
		const url = `${this._basicPath}/api/invitation/${hash}`;
		return await axios.get(url);
	}
	updateInvitedUser = async (values) => {
		const url = `${this._basicPath}/api/invitation`;
		return await axios.post(url, values);
	}
	createAccount = async (values) => {
		const url = `${this._basicPath}/api/accounts`;
		return await axios.post(url, values);
	}
	getAccounts = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/accounts?${query}`;
		return await axios.get(url);
	}
    getAccountStatement = async (id, dataQuery) => {
        const query = queryString.stringify(dataQuery);
        const url = `${this._basicPath}/api/account-transactions/${id}/get-statement?${query}`;
        await downloadFile(url);
    }

    downloadKybDocument = async () => {
        const url = `${this._basicPath}/api/company/kyb-document`;
        await downloadFile(url);
    }

    kybForwardForSignature = async (values) => {
        const url = `${this._basicPath}/api/company/kyb-forward-for-signature`;
        return await axios.post(url, values);
    }

	getFiatRepublicListAccount = async () => {
		const url = `${this._basicPath}/api/users/get-fiat-republic-list-account`;
		return await axios.get(url);
	}

	createFiatRepublicAccount = async (values) => {
		const url = `${this._basicPath}/api/users/add-fiat-account`;
		return await axios.post(url, values);
	}

	createCompanyFiatRepublicAccount = async (values) => {
		const url = `${this._basicPath}/api/company/add-fiat-account`;
		return await axios.post(url, values);
	}

	getCompanyFiatRepublicListAccount = async () => {
		const url = `${this._basicPath}/api/company/get-fiat-republic-list-account`;
		return await axios.get(url);
	}

	getMoneriumAuthToken = async () => {
		const url = `${this._basicPath}/api/company/get-monerium-auth-token`;
		return await axios.get(url);
	}

	getMoneriumAuthContext = async (token) => {
		const url = `${this._basicPath}/api/users/monerium-auth-context?token=${token}`;
		return await axios.get(url);
	}

	getMoneriumProfileBalances = async (profile, token) => {
		const query = queryString.stringify({profile, token}, {skipEmptyString: false});
		const url = `${this._basicPath}/api/users/monerium-profile-balances?${query}`;
		return await axios.get(url);
	}

	getMoneriumProfileAccounts = async (profile, token) => {
		const query = queryString.stringify({profile, token}, {skipEmptyString: false});
		const url = `${this._basicPath}/api/users/monerium-profile-accounts?${query}`;
		return await axios.get(url);
	}

	placeMoneriumOrders = async (data, token, expenseId, senderAddress, currency) => {
		const url = `${this._basicPath}/api/users/place-monerium-orders`;
		return await axios.post(url, {data, token, expenseId, senderAddress, currency});
	}

	getMoneriumTransactionsHistory = async (id, token) => {
		const query = queryString.stringify({id, token}, {skipEmptyString: false});
		const url = `${this._basicPath}/api/users/monerium-transactions-history?${query}`;
		return await axios.get(url);
	}

	getAccountsAll = async () => {
		const url = `${this._basicPath}/api/accounts/all`;
		return await axios.get(url);
	}
	getAccount = async (id) => {
		const url = `${this._basicPath}/api/accounts/${id}`;
		return await axios.get(url);
	}

	getAccountCards = async (accountId, data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/accounts/${accountId}/cards?${query}`;
		return await axios.get(url);
	}
	getAccountTransactions = async (accountId, data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/account-transactions/${accountId}?${query}`;
		return await axios.get(url);
	}

	getServiceData = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/service-data?${query}`;
		return await axios.get(url);
	}

	createProject = async (values) => {
		const url = `${this._basicPath}/api/projects`;
		return await axios.post(url, values);
	}

	getProjects = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/projects?${query}`;
		return await axios.get(url);
	}
	getMyProjects = async () => {
		const url = `${this._basicPath}/api/projects/my`;
		return await axios.get(url);
	}
    getMyCategories = async () => {
        const url = `${this._basicPath}/api/categories/my`;
        return await axios.get(url);
    }
	getProjectsAll = async () => {
		const url = `${this._basicPath}/api/projects/all`;
		return await axios.get(url);
	}
	getProject = async (id) => {
		const url = `${this._basicPath}/api/projects/${id}`;
		return await axios.get(url);
	}
	updateProject = async (id, values) => {
		const url = `${this._basicPath}/api/projects/${id}`;
		return await axios.post(url, values);
	}
	deleteProject = async (id) => {
		const url = `${this._basicPath}/api/projects/${id}`;
		return await axios.delete(url);
	}
    importProject = async (data) => {
        const url = `${this._basicPath}/api/projects/import`;
        return await axios.post(url, data);
    }

	createGroup = async (values) => {
		const url = `${this._basicPath}/api/groups`;
		return await axios.post(url, values);
	}

	getGroups = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/groups?${query}`;
		return await axios.get(url);
	}
	getGroupsAll = async () => {
		const url = `${this._basicPath}/api/groups/all`;
		return await axios.get(url);
	}
    importGroups = async (data) => {
        const url = `${this._basicPath}/api/groups/import`;
        return await axios.post(url, data);
    }
	getGroup = async (id) => {
		const url = `${this._basicPath}/api/groups/${id}`;
		return await axios.get(url);
	}
	updateGroup = async (id, values) => {
		const url = `${this._basicPath}/api/groups/${id}`;
		return await axios.post(url, values);
	}
	deleteGroup = async (id) => {
		const url = `${this._basicPath}/api/groups/${id}`;
		return await axios.delete(url);
	}
    createDepartment = async (values) => {
        const url = `${this._basicPath}/api/departments`;
        return await axios.post(url, values);
    }
    getDepartments = async (data) => {
        const query = queryString.stringify(data);
        const url = `${this._basicPath}/api/departments?${query}`;
        return await axios.get(url);
    }
    geDepartmentsAll = async () => {
        const url = `${this._basicPath}/api/departments/all`;
        return await axios.get(url);
    }
    getDepartment = async (id) => {
        const url = `${this._basicPath}/api/departments/${id}`;
        return await axios.get(url);
    }
    updateDepartment = async (id, values) => {
        const url = `${this._basicPath}/api/departments/${id}`;
        return await axios.post(url, values);
    }
    deleteDepartment = async (id) => {
        const url = `${this._basicPath}/api/departments/${id}`;
        return await axios.delete(url);
    }
	createBranch = async (values) => {
		const url = `${this._basicPath}/api/branches`;
		return await axios.post(url, values);
	}
	getBranches = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/branches?${query}`;
		return await axios.get(url);
	}
	getBranchesAll = async () => {
		const url = `${this._basicPath}/api/branches/all`;
		return await axios.get(url);
	}
	getBranch = async (id) => {
		const url = `${this._basicPath}/api/branches/${id}`;
		return await axios.get(url);
	}
	updateBranch = async (id, values) => {
		const url = `${this._basicPath}/api/branches/${id}`;
		return await axios.post(url, values);
	}
    importBranches = async (data) => {
        const url = `${this._basicPath}/api/branches/import`;
        return await axios.post(url, data);
    }
	createCategory = async (values) => {
		const url = `${this._basicPath}/api/categories`;
		return await axios.post(url, values);
	}
	getCategories = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/categories?${query}`;
		return await axios.get(url);
	}
	getCategoriesAll = async () => {
		const url = `${this._basicPath}/api/categories/all`;
		return await axios.get(url);
	}
    importCategories = async (data) => {
        const url = `${this._basicPath}/api/categories/import`;
        return await axios.post(url, data);
    }
	getCategory = async (id) => {
		const url = `${this._basicPath}/api/categories/${id}`;
		return await axios.get(url);
	}
	updateCategory = async (id, values) => {
		const url = `${this._basicPath}/api/categories/${id}`;
		return await axios.post(url, values);
	}
    updateBatchCategories = async (data) => {
        const url = `${this._basicPath}/api/categories/batch-edit`;
        return await axios.post(url, data);
    }
    disableBatchCategories = async (data) => {
        const url = `${this._basicPath}/api/categories/batch-disable`;
        return await axios.post(url, data);
    }
	submitKycForm = async (userId, data) => {
		const url = `${this._basicPath}/api/users/${userId}/kyc`;
		return await axios.post(url, data);
	}
	getCompany = async (withOwner) => {
		const url = `${this._basicPath}/api/company?${withOwner? 'with-owner=true':''}`;
		return await axios.get(url);
	}
	getCompanyFbas = async () => {
		const url = `${this._basicPath}/api/company/funding-bank-accounts`;
		return await axios.get(url);
	}
	updateCompanyFba = async (id, data) => {
		const url = `${this._basicPath}/api/company/funding-bank-accounts/${id}`;
		return await axios.post(url, data);
	}
	createCompanyFba = async (data) => {
		const url = `${this._basicPath}/api/company/funding-bank-accounts/`;
		return await axios.post(url, data);
	}
	getCompanyFba = async (id) => {
		const url = `${this._basicPath}/api/company/funding-bank-accounts/${id}`;
		return await axios.get(url);
	}
	updateCompany = async (data) => {
		const url = `${this._basicPath}/api/company`;
		return await axios.post(url, data);
	}
    updateCompanyAdvancedSettings = async (data) => {
        const url = `${this._basicPath}/api/company/advanced-settings`;
        return await axios.post(url, data);
    }
    updateCompanyXeroSettings = async (data) => {
        const url = `${this._basicPath}/api/company/xero`;
        return await axios.post(url, data);
    }
    updateCompanyXeroAccountSettings = async (data) => {
        const url = `${this._basicPath}/api/company/xero-account-settings`;
        return await axios.post(url, data);
    }
	createCompanyXeroAccounts = async (data) => {
        const url = `${this._basicPath}/api/company/xero-account`;
        return await axios.post(url, data);
    }
	getCompanyXeroAccounts = async (data) => {
        const url = `${this._basicPath}/api/company/xero-accounts`;
        return await axios.get(url, data);
    }
	updateCompanyPermissions = async (data) => {
        const url = `${this._basicPath}/api/company/permissions`;
        return await axios.post(url, data);
    }
	submitKybForm = async (data, stepName) => {
		let query;
		if(stepName){
			query = queryString.stringify({stepName});
		}
		const url = `${this._basicPath}/api/company/kyb?${query ? query : ""}`;
		return await axios.post(url, data);
	}
	getNotifications = async () => {
		const url = `${this._basicPath}/api/users/me/notifications`;
		return await axios.get(url);
	}
	deleteNotification = async (id) => {
		const url = `${this._basicPath}/api/users/me/notifications/${id}`;
		return await axios.delete(url);
	}
    deleteNotificationAll = async () => {
        const url = `${this._basicPath}/api/users/me/notifications`;
        return await axios.delete(url);
    }
    getTaxRate = async (id) => {
        const url = `${this._basicPath}/api/tax-rates/${id}`;
        return await axios.get(url);
    }
    getTaxRates = async (data) => {
        const query = queryString.stringify(data);
        const url = `${this._basicPath}/api/tax-rates?${query}`;
        return await axios.get(url);
    }
    createTaxRate = async (values) => {
        const url = `${this._basicPath}/api/tax-rates`;
        return await axios.post(url, values);
    }
    updateTaxRate = async (id, values) => {
        const url = `${this._basicPath}/api/tax-rates/${id}`;
        return await axios.post(url, values);
    }
    deleteTaxRate = async (id) => {
        const url = `${this._basicPath}/api/tax-rates/${id}`;
        return await axios.delete(url);
    }
    importTaxRate = async (data) => {
        const url = `${this._basicPath}/api/tax-rates/import`;
        return await axios.post(url, data);
    }
    getReports = async (data) => {
        const query = queryString.stringify(data);
        const url = `${this._basicPath}/api/reports?${query}`;
        return await axios.get(url);
    }
    getReport = async (id) => {
        const url = `${this._basicPath}/api/reports/${id}`;
        return await axios.get(url);
    }
    updateReport = async (id, values) => {
        const url = `${this._basicPath}/api/reports/${id}`;
        return await axios.post(url, values);
    }
    deleteReport = async (id) => {
        const url = `${this._basicPath}/api/reports/${id}`;
        return await axios.delete(url);
    }
    createExpenseQueue = async (data) => {
        const url = `${this._basicPath}/api/expenses-queue`;
        return await axios.post(url, data);
    }
    getExpensesQueue = async () => {
        const url = `${this._basicPath}/api/expenses-queue`;
        return await axios.get(url);
    }

    deleteExpenseQueue = async (id) => {
        const url = `${this._basicPath}/api/expenses-queue/${id}`;
        return await axios.delete(url);
    }
    createRejectReason = async (data) => {
        const url = `${this._basicPath}/api/reject-reasons`;
        return await axios.post(url, data);
    }
    getRejectReasons = async (data) => {
        const query = queryString.stringify(data);
        const url = `${this._basicPath}/api/reject-reasons?${query}`;
        return await axios.get(url);
    }
    getRejectReasonsAll = async () => {
        const url = `${this._basicPath}/api/reject-reasons/all`;
        return await axios.get(url);
    }
    getRejectReason = async (id) => {
        const url = `${this._basicPath}/api/reject-reasons/${id}`;
        return await axios.get(url);
    }
    updateRejectReason = async (id, values) => {
        const url = `${this._basicPath}/api/reject-reasons/${id}`;
        return await axios.post(url, values);
    }
    deleteRejectReason = async (id) => {
        const url = `${this._basicPath}/api/reject-reasons/${id}`;
        return await axios.delete(url);
    }
    createExpenseReportTemplate = async (values) => {
        const url = `${this._basicPath}/api/expense-report-templates`;
        return await axios.post(url, values);
    }
    getExpenseReportTemplates = async (data) => {
        const query = queryString.stringify(data);
        const url = `${this._basicPath}/api/expense-report-templates?${query}`;
        return await axios.get(url);
    }
    updateExpenseReportTemplates = async (id, values) => {
        const url = `${this._basicPath}/api/expense-report-templates/${id}`;
        return await axios.post(url, values);
    }
    getExpenseReportTemplate = async (id) => {
        const url = `${this._basicPath}/api/expense-report-templates/${id}`;
        return await axios.get(url);
    }
    getExpenseReportTemplateAll = async (id) => {
        const url = `${this._basicPath}/api/expense-report-templates/all`;
        return await axios.get(url);
    }
    deleteExpenseReportTemplate = async (id) => {
        const url = `${this._basicPath}/api/expense-report-templates/${id}`;
        return await axios.delete(url);
    }
	createContact = async (data) => {
		const url = `${this._basicPath}/api/contacts`;
		return await axios.post(url, data);
	}
	getContacts = async (data) => {
		const query = queryString.stringify(data);
		const url = `${this._basicPath}/api/contacts?${query}`;
		return await axios.get(url);
	}
	createFiatPayment = async (data) => {
		const url = `${this._basicPath}/api/contacts/fiat-transfer`;
		return await axios.post(url, data);
	}
	getFiatPayeeById = async (fiatId) => {
		const url = `${this._basicPath}/api/users/get-fiat-republic-payee/${fiatId}`;
		return await axios.get(url);
	}
	getFiatAccountByFiatId = async (fiatId) => {
		const url = `${this._basicPath}/api/users/get-fiat-republic-by-fiat-id/${fiatId}`;
		return await axios.get(url);
	}
	getTanganyWalletBalanceByWalletName = async (walletName) => {
		const url = `${this._basicPath}/api/users/get-tangany-balances-by-wallet-name/${walletName}`;
		return await axios.get(url);
	}

	getImportedWalletBalanceByWalletName = async (walletAddress, token, network) => {
		let url = `${this._basicPath}/api/users/get-imported-wallet-balances-by-wallet-address/${walletAddress}`;
		if (network && token) {
			url = url + `?network=${network}&token=${token}`
		}

		return await axios.get(url);
	}

	getCoinPrice = async (symbol) => {
		let url = `${this._basicPath}/api/currency/get-coin-price`;
		if (symbol) {
			url += `?symbol=${symbol}`
		}
		return await axios.get(url);
	}

	syncCompanyTransaction = async () => {
		let url = `${this._basicPath}/api/company/sync-company-incoming-transactions`;
		return await axios.post(url);
	}
}
export default Api

import Api from '../../../../services/api'
import * as actionTypes from '../../../../store/actionTypes'
import {handleErrorMessage} from "../../../Messages/actions";

const fetchUsers = (objReq) => {

    return async dispatch => {
        const {getUsers} = new Api();

        try {
            const response = await getUsers(objReq);
            const {data} = response;
            const {result, info} = data;
            dispatch({type:actionTypes.USERS_FETCH_SUCCESS, payload:{result, info}});
        }catch (e) {
            dispatch(handleErrorMessage(e));
            dispatch({type:actionTypes.USERS_FETCH_FAILURE})
        }

    }
};


export {
    fetchUsers,
}

import * as actionTypes from "../../../../store/actionTypes";

const initialState = {
    accounts: {
        result: [],
        info: {
            fields: ["currency", "avlBal", "linkedCards"],
            page: 0,
            total: 0,
            pageSize: 0
        }
    }
}

const userAccounts = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNTS_FETCH_SUCCESS :
            return {
                ...state,
                accounts:{
                    result: action.payload.info.page > 1 ? [...state.accounts.result, ...action.payload.result] : action.payload.result,
                    info:{
                        ...action.payload.info
                    }
                }
            }
        case actionTypes.ACCOUNTS_FETCH_FAILURE :
            return {
                ...state,
                accounts:{
                    result:[]
                }
            }
        case actionTypes.USER_LOGOUT:
            return initialState;
    }
    return state
}

export default userAccounts

